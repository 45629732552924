import React from "react";
import { Color, Frame, Inline, Skeleton, Stack } from "@ableco/baseline";
import { SSRSuspense } from "coreql";

import { Main } from "../layout/layout";
import PageLayout, { Fallback as PageFallback } from "../layout/page-layout";
import { NavItem as Tab } from "../tab.js";

function getNavItems() {
  const items = [];

  items.push(
    {
      href: "/weekly_questions/scheduled",
      label: "Schedule",
      prefetch: false,
    },
    {
      href: `/weekly_questions`,
      label: "Reports",
      prefetch: false,
    },
  );

  return items;
}

export default function WeeklyQuestionLayout({ activeLabelTab, children }) {
  const navItems = getNavItems();

  const fallback = (
    <Frame className="px-8 w-full">
      <Frame className="border-b border-neutral-300">
        <Skeleton
          height={32}
          width={200}
          color="neutral-400"
          className="mb-4"
          alt="Weekly Questions"
        />
        <Inline as="nav" alignment="start">
          {[...Array.from({ length: 2 })].map((_, i) => (
            <Skeleton
              key={i}
              height={16}
              width={93}
              className="mx-4 my-4"
              alt="Page Link"
              color={Color.Neutral400}
            />
          ))}
        </Inline>
      </Frame>
    </Frame>
  );

  return (
    <Main>
      <SSRSuspense
        fallback={
          <>
            <PageFallback />
            {fallback}
          </>
        }
      >
        <PageLayout title="Weekly Questions">
          <Inline
            as="nav"
            alignment="start"
            className="w-full border-b border-neutral-300 mt-2"
          >
            {navItems.map((item) => (
              <Tab
                key={item.href}
                {...item}
                isActive={item.label.toLowerCase() === activeLabelTab}
              />
            ))}
          </Inline>
          <Stack>{children}</Stack>
        </PageLayout>
      </SSRSuspense>
    </Main>
  );
}
