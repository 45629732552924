/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from "react";
import { Cell, Grid, Inline, Stack, Touchable } from "@ableco/baseline";

import { BodyBaselineSemibold } from "./design-system/typography-components";
import { api, denormalize } from "coreql";
import { useCoreToast } from "./toast/toast";

import clsx from "clsx";
import { useWeeklyReportTemperature } from "./weekly-report/temperature-section";

const TEMPERATURE_VALUES = {
  1: {
    color: "alert-light",
    message: "Happens to all of us. Anything you need?",
  },
  2: {
    color: "warning-darker",
    message: "Is there anything blocking you that you’d like addressed?",
  },
  3: {
    color: "warning-base",
    message: "Anything to add?",
  },
  4: {
    color: "success-light",
    message: "Smooth and steady! Anything to add?",
  },
  5: {
    color: "success-base",
    message: "What led to you feeling so good?",
  },
};

const TEMPERATURE_RATINGS = ["1", "2", "3", "4", "5"];

function TemperatureRatePicker({ weeklyReportId, onChange, onShowAnswers }) {
  const {
    mutate,
    data: { temperature },
  } = useWeeklyReportTemperature(weeklyReportId);
  const [currentTemperature, setCurrentTemperature] = useState(
    temperature?.data.value,
  );

  const message = TEMPERATURE_VALUES[currentTemperature]?.message;

  const { errorToast } = useCoreToast();

  async function handleTemperatureChange(rating) {
    setCurrentTemperature(rating);
    try {
      const response = await api.weeklyReportAnswers
        .find(temperature?.id)
        .upsert(
          { data: { value: rating } },
          { answerType: "temperature", weeklyReportId },
          [{ name: "weeklyReport", resource: "weekly-report" }],
        );

      await mutate(
        async (weeklyReport) => ({
          ...weeklyReport,
          temperature: denormalize(response, "weeklyReportAnswers"),
        }),
        false,
      );
    } catch {
      errorToast("Could not update your temperature rating, contact the team.");
      setCurrentTemperature(currentTemperature);
    }
    onChange();
  }

  return (
    <>
      <Inline
        distribution="center"
        aria-label="How did you feel at work this week?"
      >
        <BodyBaselineSemibold>Awful</BodyBaselineSemibold>
        <Grid role="group" col={5} gap={3} className="justify-center mx-4">
          {TEMPERATURE_RATINGS.map((rating) => (
            <TemperatureRadioBtn
              key={rating}
              rating={rating}
              color={TEMPERATURE_VALUES[rating].color}
              active={currentTemperature === rating}
              onChange={() => handleTemperatureChange(rating)}
            />
          ))}
        </Grid>
        <BodyBaselineSemibold>Amazing</BodyBaselineSemibold>
      </Inline>
      {currentTemperature && (
        <Inline distribution="center">
          <Touchable
            type="button"
            onClick={onShowAnswers}
            className={"block text-primary-base hover:text-primary-light"}
          >
            {message}
          </Touchable>
        </Inline>
      )}
    </>
  );
}

function TemperatureRadioBtn({ rating, color, active, onChange }) {
  return (
    <Cell key={rating}>
      <Stack
        as="label"
        distribution="center"
        aria-label={`temperature-rating-${rating}`}
        className={clsx(
          "block text-center border-neutral-500 text-xl transition-colors duration-300 ease-in-out",
          {
            [`bg-${color} text-white border-${color}`]: active,
            [`hover:border-${color} hover:bg-${color} hover:bg-opacity-25 hover:text-${color} cursor-pointer`]:
              !active,
          },
        )}
        style={{ height: 50, width: 50, borderWidth: 3 }}
        corners="full"
      >
        <input
          type="radio"
          name="temperature-picker"
          value={rating}
          onChange={onChange}
          checked={active}
          className="appearance-none"
        />
        {rating}
      </Stack>
    </Cell>
  );
}

export default TemperatureRatePicker;
