import { denormalize, ClientError, useCollection } from "coreql";
import { wrap } from "../components/weekly-report/utils";

export default function useDirectReportsOf(managerId) {
  const { data } = useCollection("users", {
    filters: { managerId, active: true },
    fields: {
      users: ["fullName", "avatarUrl"],
    },
  });

  if (data.error) throw new ClientError(data.error);

  let directReports = denormalize(data, "users");

  return wrap(directReports);
}
