// Modules
import React from "react";
import PropTypes from "prop-types";
// Styles
import { Cell, Color, Grid, Stack } from "@ableco/baseline";
import { LabelText } from "../../../components/reports/utils";

Milestones.propTypes = {
  discoveryStart: PropTypes.string,
  discoveryEnd: PropTypes.string,
  definitionStart: PropTypes.string,
  definitionEnd: PropTypes.string,
  developmentStart: PropTypes.string,
  developmentEnd: PropTypes.string,
  updateProject: PropTypes.func.isRequired,
};

function DateInput({ defaultValue, onChange }) {
  return (
    <input
      className="border border-neutral-300 text-neutral-700 text-sm"
      defaultValue={defaultValue}
      onChange={onChange}
      type="date"
    />
  );
}

function Milestones({
  discoveryStart,
  discoveryEnd,
  definitionStart,
  definitionEnd,
  developmentStart,
  developmentEnd,
  updateProject,
}) {
  return (
    <Grid p={[4, 2]} col={2} gap={4} bg={Color.Neutral200}>
      <Cell>
        <Stack>
          <LabelText>Discovery Start Date</LabelText>
          <DateInput
            defaultValue={discoveryStart}
            onChange={(event) =>
              updateProject({ discoveryStart: event.currentTarget.value })
            }
          />
        </Stack>
      </Cell>
      <Cell>
        <Stack>
          <LabelText>Discovery End Date</LabelText>
          <DateInput
            defaultValue={discoveryEnd}
            onChange={(event) =>
              updateProject({ discoveryEnd: event.currentTarget.value })
            }
          />
        </Stack>
      </Cell>
      <Cell>
        <Stack>
          <LabelText>Definition Start Date</LabelText>
          <DateInput
            defaultValue={definitionStart}
            onChange={(event) =>
              updateProject({ definitionStart: event.currentTarget.value })
            }
          />
        </Stack>
      </Cell>

      <Cell>
        <Stack>
          <LabelText>Definition End Date</LabelText>
          <DateInput
            defaultValue={definitionEnd}
            onChange={(event) =>
              updateProject({ definitionEnd: event.currentTarget.value })
            }
          />
        </Stack>
      </Cell>

      <Cell>
        <Stack>
          <LabelText>Development Start Date</LabelText>
          <DateInput
            defaultValue={developmentStart}
            onChange={(event) =>
              updateProject({ developmentStart: event.currentTarget.value })
            }
          />
        </Stack>
      </Cell>

      <Cell>
        <Stack>
          <LabelText>Development End Date</LabelText>
          <DateInput
            defaultValue={developmentEnd}
            onChange={(event) =>
              updateProject({ developmentEnd: event.currentTarget.value })
            }
          />
        </Stack>
      </Cell>
    </Grid>
  );
}

export default Milestones;
