function getDateLongMonth(date) {
  return date.toLocaleString("en-us", { month: "long", timeZone: "UTC" });
}

function formatDateRange(date1, date2) {
  // Allows you to pass strings like "2019-11-07"
  // with no side effects if you pass dates.
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  const d1 = startDate.getUTCDate();
  const m1 = getDateLongMonth(startDate);
  const y1 = startDate.getUTCFullYear();

  const d2 = endDate.getUTCDate();
  const m2 = getDateLongMonth(endDate);
  const y2 = endDate.getUTCFullYear();

  return y1 !== y2
    ? `${m1} ${d1}, ${y1} - ${m2} ${d2}, ${y2}`
    : `${m1} ${d1} - ${m2} ${d2}, ${y2}`;
}

export default formatDateRange;
