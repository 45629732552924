function storage(isTemporal) {
  return isTemporal ? sessionStorage : localStorage;
}

export function has(key, { isTemporal = false } = {}) {
  return !!storage(isTemporal).getItem(key);
}

export function read(key, { isTemporal = false } = {}) {
  const value = storage(isTemporal).getItem(key);
  if (value === null) return;
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

export function create(key, value, { isTemporal = false } = {}) {
  const stringified = typeof value !== "string" ? JSON.stringify(value) : value;
  storage(isTemporal).setItem(key, stringified);
}

export function destroy(key, { isTemporal = false } = {}) {
  storage(isTemporal).removeItem(key);
}
