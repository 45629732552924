import React, { useContext, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import {
  format,
  isWithinInterval,
  compareAsc,
  isSameDay,
  parseISO,
} from "date-fns";

import api from "../../services/api";
import TimelineContext from "./timeline-context";
import useTooltip from "../../hooks/use-tooltip";
import UnassignedPopover from "./unassigned-popover";

import { colorPalette } from "./../../components/style-guide/colors";
import {
  fonts,
  fontSizes,
  fontWeights,
} from "./../../components/style-guide/typography";
import CommentIconSvg from "../icons/comment";

const contentUnassignedColors = {
  full: colorPalette.bluegrey50,
  partial: colorPalette.bluegrey30,
};

const contentColors = {
  unassigned: {
    overallocated: contentUnassignedColors,
    withincapacity: contentUnassignedColors,
  },
  assigned: {
    overallocated: {
      full: colorPalette.warning,
      partial: colorPalette.red400,
    },
    withincapacity: {
      full: colorPalette.babyBlue600,
      partial: colorPalette.babyBlue60,
    },
  },
};

const Timeline = styled.div`
  flex: 0 1 40px;
  display: flex;
  overflow: hidden;
`;

const SlotBackground = React.memo(styled.div`
  flex: 1 1 0;
  padding: 4px 0;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
  transition: all 0.3s;
`);

const EmptySlot = React.memo(styled.div`
  flex: 1 1 0;
  padding: 4px 0;
`);

const SlotContent = React.memo(styled.div`
  height: 100%;
  max-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;
  font-family: ${fonts.sansSerif};
  font-size: ${fontSizes.smallX}px;
  font-weight: ${fontWeights.regular};
  border-top: 1px solid
    ${(props) => contentColors[props.contentType][props.allocationLoad].full};
  border-bottom: 1px solid
    ${(props) => contentColors[props.contentType][props.allocationLoad].full};
  background-color: ${(props) =>
    contentColors[props.contentType][props.allocationLoad][
      props.contentTypeDetail
    ]};
  ${({ beginningBlock, contentType, allocationLoad }) =>
    beginningBlock &&
    css`
      border-left: 1px solid ${contentColors[contentType][allocationLoad].full};
      margin-left: 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding-right: 5px;
    `}
  ${({ endingBlock, contentType, allocationLoad }) =>
    endingBlock &&
    css`
      border-right: 1px solid ${contentColors[contentType][allocationLoad].full};
      margin-right: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding-left: 5px;
    `}
  ${({ showBackfillBorder }) =>
    showBackfillBorder &&
    css`
      border-top: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important;
    `}
  ${({ showBackfillBorder, isBackfillStart }) =>
    showBackfillBorder &&
    isBackfillStart &&
    css`
      border-left: 1px solid #000 !important;
    `}
  ${({ showBackfillBorder, isBackfillEnd }) =>
    showBackfillBorder &&
    isBackfillEnd &&
    css`
      border-right: 1px solid #000 !important;
    `}
    transition: all 0.3s;
`);

const HoursWrapper = styled.div`
  margin-left: auto;
  margin-right: 50%;
  transform: translateX(50%);
`;

function defineShape(date, assignments) {
  const index = assignments.findIndex((assignment) =>
    isSameDay(date, assignment.attributes.periodStart),
  );
  const shape = {};

  if (index === 0) {
    shape.beginningBlock = true;
  }
  if (index === assignments.length - 1) {
    shape.endingBlock = true;
  }
  return shape;
}

const CommentIconContainer = styled.div`
  margin-left: ${({ beginningBlock }) => (beginningBlock ? "8px" : "6px")};
  margin-right: auto;

  display: flex;
  svg {
    path {
      fill: ${colorPalette.white};
    }
  }
`;
function CommentIcon({ beginningBlock }) {
  return (
    <CommentIconContainer title="Has note" beginningBlock={beginningBlock}>
      <CommentIconSvg />
    </CommentIconContainer>
  );
}

function defineShapeBench(assignments, weeks, index) {
  const shape = {};

  const hasEndingPrevCell =
    index === 0 || defineShape(weeks[index - 1], assignments).endingBlock;

  const hasEndingNextCell =
    index === weeks.length ||
    defineShape(weeks[index + 1], assignments).beginningBlock;

  const prevAssigment = assignments.find((assignment) =>
    isSameDay(assignment.attributes.periodStart, weeks[index - 1]),
  );

  const nextAssigment = assignments.find((assignment) =>
    isSameDay(assignment.attributes.periodStart, weeks[index + 1]),
  );

  shape.beginningBlock = prevAssigment
    ? prevAssigment.attributes.hours === 40
    : hasEndingPrevCell;

  shape.endingBlock = nextAssigment
    ? nextAssigment.attributes.hours === 40
    : hasEndingNextCell;

  return shape;
}

function defineSlotProps(
  headerWeek,
  assignments,
  assignedUser,
  assignment,
  isBench,
) {
  const { beginningBlock, endingBlock } = defineShape(headerWeek, assignments);
  const contentType = !isBench && !!assignedUser ? "assigned" : "unassigned";

  const allocationLoad =
    assignedUser &&
    assignedUser.attributes.ocupation &&
    assignedUser.attributes.ocupation[headerWeek] &&
    assignedUser.attributes.ocupation[headerWeek].type === "overallocated"
      ? "overallocated"
      : "withincapacity";

  const contentTypeDetail =
    assignment.attributes.hours < 40 ? "partial" : "full";

  return {
    beginningBlock,
    endingBlock,
    contentType,
    contentTypeDetail,
    allocationLoad,
  };
}

function calculateHours(
  contentTypeDetail,
  currentAssignment,
  isBench,
  assignments,
  headerWeek,
) {
  if (contentTypeDetail === "full") return null;

  if (isBench) return 40 - calculateAllocationForWeek(assignments, headerWeek);

  return currentAssignment.attributes.hours;
}

function getAllAssignmentsForHeaderWeek(assignments, headerWeek) {
  return assignments.filter((assignment) =>
    isSameDay(assignment.attributes.periodStart, headerWeek),
  );
}

function calculateAllocationForWeek(assignments, headerWeek) {
  const weekAssignments = getAllAssignmentsForHeaderWeek(
    assignments,
    headerWeek,
  );

  return weekAssignments.reduce(
    (total, assignment) => total + assignment.attributes.hours,
    0,
  );
}

function MemberGraph({
  assignments,
  projectStart,
  projectEnd,
  isBench = false,
  projectSeat,
}) {
  const { weeks, data, revalidateProductsAssignments, editPermission } =
    useContext(TimelineContext);
  const [Tooltip, triggerProps, { setVisibility }] = useTooltip();
  const firstAssignment = assignments[0];
  let assignedUser = null;
  if (firstAssignment.attributes.assigneeType === "User") {
    assignedUser = data.users[firstAssignment.attributes.assigneeId];
  }

  const parsedAssignments = useMemo(
    () =>
      assignments.map((assignment) => ({
        ...assignment,
        attributes: {
          ...assignment.attributes,
          periodStart: parseISO(assignment.attributes.periodStart),
        },
      })),
    [assignments],
  );

  const sortedAssignments = parsedAssignments.sort((a, b) =>
    compareAsc(a.attributes.periodStart, b.attributes.periodStart),
  );

  const [currentSlot, setCurrentSlot] = useState({});
  const [showBorder, setShowBorder] = useState(false);
  const [assignmentNote, setAssignmentNote] = useState(
    firstAssignment.attributes.assignmentNote || "",
  );
  const weekDates = new Set(weeks.map((el) => format(el, "yyyy-MM-dd")));
  const visibleSlots = parsedAssignments.filter((el) =>
    weekDates.has(format(el.attributes.periodStart, "yyyy-MM-dd")),
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping
  async function handleUnassignAssignments() {
    try {
      await Promise.all(
        assignments.map(async (assignment) =>
          api.productAssignments.find(assignment.id).update({
            assigneeType: null,
            assigneeId: null,
          }),
        ),
      );
      setVisibility(false);
      revalidateProductsAssignments();
    } catch {
      alert("Something went wrong!");
    }
  }

  async function handleAddNote(values) {
    const { note } = values;
    const previousNote = assignmentNote;
    try {
      setAssignmentNote(note);
      const assignmentsNoteId = assignments[0].relationships.assignmentsNote
        .data
        ? assignments[0].relationships.assignmentsNote.data.id
        : null;

      if (assignmentsNoteId) {
        await api.assignmentsNotes.find(assignmentsNoteId).update({
          note: note.trim(),
        });
      } else {
        const rowId = assignments[0].attributes.rowId;
        await api.assignmentsNotes.create({ note, rowId });
      }

      revalidateProductsAssignments();
      return true;
    } catch {
      setAssignmentNote(previousNote);
      alert("Something went wrong!");
      return false;
    }
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  async function handlePartiallyUnassignAssignments(
    newAssignments,
    deletedAssignmentsIds,
  ) {
    try {
      await Promise.all(
        newAssignments.map((el) => api.productAssignments.create(el)),
      );
      await Promise.all(
        deletedAssignmentsIds.map((el) =>
          api.productAssignments.find(el).destroy(),
        ),
      );
      revalidateProductsAssignments();
      setVisibility(false);
    } catch {
      alert("Something went wrong!");
    }
  }

  function handlePartiallyUnassignButtonHover() {
    setShowBorder(!showBorder);
  }

  let noteIconAlreadyDisplayed = false;

  return (
    <>
      <Timeline>
        {weeks.map((headerWeek, headerWeekIndex) => {
          const withinProject = isWithinInterval(headerWeek, {
            start: projectStart,
            end: projectEnd,
          });

          if (!withinProject) {
            return null;
          }

          const currentAssignment = parsedAssignments.find((assignment) =>
            isSameDay(assignment.attributes.periodStart, headerWeek),
          );

          if (!currentAssignment && isBench) {
            const { beginningBlock, endingBlock } = defineShapeBench(
              sortedAssignments,
              weeks,
              headerWeekIndex,
            );

            return (
              <SlotBackground key={headerWeek} data-testid="member-graph-slot">
                <SlotContent
                  contentType={"unassigned"}
                  contentTypeDetail={"full"}
                  allocationLoad={"withincapacity"}
                  data-testid="member-graph-slot-content"
                  beginningBlock={beginningBlock}
                  endingBlock={endingBlock}
                />
              </SlotBackground>
            );
          }

          if (
            !currentAssignment ||
            (isBench &&
              calculateAllocationForWeek(parsedAssignments, headerWeek) >= 40)
          ) {
            return (
              <EmptySlot
                key={headerWeek}
                data-testid="member-graph-empty-slot"
              />
            );
          }

          const slotProps = defineSlotProps(
            headerWeek,
            sortedAssignments,
            assignedUser,
            currentAssignment,
            isBench,
          );

          const showNoteIcon =
            editPermission &&
            !isBench &&
            !noteIconAlreadyDisplayed &&
            assignmentNote;

          if (showNoteIcon) {
            noteIconAlreadyDisplayed = true;
          }
          return (
            <SlotBackground
              key={currentAssignment.id}
              data-testid="member-graph-slot"
              onClick={
                isBench
                  ? null
                  : (e) => {
                      setCurrentSlot({
                        headerWeek: headerWeek,
                        allocationLoad: slotProps.allocationLoad,
                      });
                      triggerProps.onClick(e);
                    }
              }
            >
              <SlotContent
                contentType={slotProps.contentType}
                contentTypeDetail={slotProps.contentTypeDetail}
                allocationLoad={slotProps.allocationLoad}
                data-testid="member-graph-slot-content"
                beginningBlock={slotProps.beginningBlock}
                endingBlock={slotProps.endingBlock}
                isBackfillStart={
                  currentSlot.headerWeek
                    ? currentSlot.headerWeek.getTime() === headerWeek.getTime()
                    : false
                }
                isBackfillEnd={
                  currentSlot.headerWeek
                    ? format(headerWeek, "yyyy-MM-dd") ===
                      format(
                        visibleSlots[visibleSlots.length - 1].attributes
                          .periodStart,
                        "yyyy-MM-dd",
                      )
                    : false
                }
                showBackfillBorder={
                  showBorder &&
                  (currentSlot.headerWeek
                    ? currentSlot.headerWeek.getTime() <= headerWeek.getTime()
                    : false)
                }
              >
                {showNoteIcon && (
                  <CommentIcon beginningBlock={slotProps.beginningBlock} />
                )}
                <HoursWrapper>
                  {calculateHours(
                    slotProps.contentTypeDetail,
                    currentAssignment,
                    isBench,
                    parsedAssignments,
                    headerWeek,
                  )}
                </HoursWrapper>
              </SlotContent>
            </SlotBackground>
          );
        })}
      </Timeline>
      <Tooltip>
        <UnassignedPopover
          assignments={parsedAssignments}
          assignedUser={assignedUser}
          notes={assignmentNote}
          handleAddNote={handleAddNote}
          handleUnassignAssignments={handleUnassignAssignments}
          handlePartiallyUnassignButtonHover={
            handlePartiallyUnassignButtonHover
          }
          onPartiallyUnassignAssignments={handlePartiallyUnassignAssignments}
          editPermission={editPermission}
          allocationLoad={currentSlot.allocationLoad}
          headerWeek={currentSlot.headerWeek}
          projectSeat={projectSeat}
        />
      </Tooltip>
    </>
  );
}
export default MemberGraph;
