/* eslint-disable unicorn/no-null */
import React, { useCallback, useEffect } from "react";
import { TaskRelatedResource } from "../task-related-resource";
import ToggleHelper from "../toggle-helper";
import { MarkdownEditor } from "../markdown/markdown-editor";
import { MarkdownViewer } from "../markdown/markdown-viewer";
import { AddUser, Drag, Trash } from "@baseline/icons";
import * as Dropdown from "../dropdown";
import noop from "../../utils/noop";
import { Color, Frame, Inline, Stack, Text, Touchable } from "@ableco/baseline";
import { api } from "coreql";
import { isEmpty } from "lodash";
import UserListItem from "./user-list-item";
import Checkbox from "../data-entry/checkbox";
import useAutosavetextInput from "../../hooks/use-autosave-text-input";
import SavedState from "./saved-state";
import checkEmptyMDText from "../../utils/markdown/check-empty-text";
import { useCoreToast } from "../toast/toast";
import { AnimatePresence, motion } from "framer-motion";
import { BodySmall } from "../design-system/typography-components";
import clsx from "clsx";
import { CoreTooltip } from "../core-tooltip";
import { AvatarXS } from "../design-system/avatar";

function Task({
  id,
  label,
  placeholder,
  initialText,
  completed,
  kind,
  relatedResourceType,
  relatedResource,
  requesterId,
  requestedUser,
  currentUser,
  editedById,
  disabledActions = {},
  assignedTo = null,
  createdAt = null,
  onEdit = noop,
  onDelete = noop,
  onChange = noop,
  deleteItemFromInternalState = noop,
  updatedAt,
  dragHandleProps,
  disabled = false,
}) {
  const [text, setText] = React.useState(initialText || "");
  const [itemId, setItemId] = React.useState(id);
  const [showAnswer, setShowAnswer] = React.useState(false);
  const [showMarkdown, setShowMarkdown] = React.useState(
    checkEmptyMDText(text),
  );
  const [focus, setFocus] = React.useState(false);
  const [createdBy, setCreatedBy] = React.useState(getUserName(requesterId));
  const [editedBy, setEditedBy] = React.useState(getUserName(editedById));
  const [updatedDate, setUpdatedDate] = React.useState(updatedAt);
  const [createdAtDate, setcreatedAtDate] = React.useState(createdAt);
  const [checked, setChecked] = React.useState(completed);
  const [showUsersDropdown, setShowUsersDropdown] = React.useState(false);
  const [assignedToId, setAssignedToId] = React.useState(
    assignedTo?.id?.toString() || null,
  );
  const [dataIsLoading, setDataIsLoading] = React.useState(false);
  const [dataloadError, setDataLoadError] = React.useState(false);
  const [dataSavedAt, setDataSavedAt] = React.useState(null);
  const [dataHasUnsavedChanges, setDataHasUnsavedChanges] =
    React.useState(false);

  useEffect(() => void setText(initialText), [initialText]);
  useEffect(() => void setChecked(completed), [completed]);
  useEffect(() => void setEditedBy(getUserName(editedById)), [editedById]);
  useEffect(() => void setAssignedToId(assignedTo?.id || null), [assignedTo]);
  function getUserName(id) {
    if (!id) return null;
    return Number.parseInt(id) === Number.parseInt(currentUser.id)
      ? "you"
      : requestedUser.preferredName;
  }

  const editorRef = React.useRef(null);
  const handleChangeTask = useCallback((event) => {
    const newText = event.target.value;
    setText(newText);
  }, []);

  async function handleBlur() {
    setFocus(false);
    if (checkEmptyMDText(text)) {
      setShowMarkdown(true);
    } else {
      setText("");
      if (!isNew(itemId)) {
        deleteMe();
      } else {
        setDataHasUnsavedChanges(false);
      }
    }
  }

  async function toggleCheck() {
    if (!text) return;
    setChecked(!checked);
    if (typeof onEdit === "function") {
      await api.oneOnOneItems.find(itemId).update({ completed: !checked });
      onEdit(itemId, { completed: !checked });
    }
  }

  function isNew(id) {
    return id.includes("newtask:");
  }

  const handleSaveTask = useCallback(
    async (event) => {
      const { value } = event.target;
      const empty = isEmpty(value.trim());

      if (empty) {
        try {
          setDataIsLoading(true);
          setDataLoadError(false);
          if (itemId && !isNew(itemId)) {
            const { data: dataStatus } = await api.oneOnOneItems
              .find(itemId)
              .read();

            if (dataStatus && dataStatus.id !== null) {
              deleteItemFromStack();
              await api.oneOnOneItems.find(itemId).destroy();

              setItemId("newtask:");
              setDataHasUnsavedChanges(false);
              setDataSavedAt(null);
              setDataIsLoading(false);
            }
          } else {
            setDataIsLoading(false);
          }
        } catch {
          errorToast("We couldn't delete your Item, contact the team.");
        }
        return;
      }
      try {
        setDataIsLoading(true);
        setDataLoadError(false);
        if (isNew(itemId)) {
          const { data } = await api.oneOnOneItems.create(
            {
              text: value,
              kind: kind,
              completed: false,
              requesterId: currentUser.id,
              requestedId: requestedUser.id,
            },
            [
              { name: "requester", resource: "user" },
              { name: "requested", resource: "user" },
            ],
            { fields: { oneOnOneItems: ["createdAt"] } },
          );
          setcreatedAtDate(data.attributes.createdAt);
          setItemId(data.id);
        } else {
          await api.oneOnOneItems.find(itemId).update({ text: value });
          onEdit(itemId, { text: value, lastEditedById: currentUser.id });
        }
        setDataHasUnsavedChanges(false);
        setDataSavedAt(Date.now());
        setDataIsLoading(false);
      } catch {
        setDataIsLoading(false);
        setDataLoadError(true);
      }
    },
    [itemId, kind, currentUser.id, requestedUser.id],
  );

  function deleteMe() {
    onDelete(itemId);
  }

  function deleteItemFromStack() {
    deleteItemFromInternalState(itemId);
  }

  function showParticipantsDropDown() {
    if (disabled) return;
    setShowUsersDropdown(true);
  }

  const autosaveTaskProps = useAutosavetextInput({
    onSave: handleSaveTask,
    onChange: useCallback(
      (event) => {
        const newValue = event.target.value;
        onChange(id, { text: newValue });
        setDataHasUnsavedChanges(text !== event.target.value);
        handleChangeTask(event);
      },
      [handleChangeTask, text],
    ),
  });

  const { successToast, errorToast } = useCoreToast();

  async function assignActionItem(user) {
    const newAssignedId = user.id === assignedToId ? null : user.id;
    function sendRequest() {
      return api.oneOnOneItems.find(itemId).update.relation("assignee", {
        type: "users",
        id: newAssignedId,
      });
    }
    try {
      await sendRequest();
      onEdit(itemId, { assignee: { id: newAssignedId } });
      successToast("Item assigned");
    } catch {
      errorToast(
        "There was problem assigning this item. Please contact the team.",
      );
    }

    setAssignedToId(newAssignedId);
    setShowUsersDropdown(false);
  }
  let StatusBar;
  if (updatedDate) {
    StatusBar = (
      <>
        <BodySmall color={Color.Neutral600} data-testid="status-bar">
          {editedBy ? `Edited by ${editedBy}` : `Added by ${createdBy}`}
          {" • "}
          {
            <time
              dateTime={updatedDate.toISOString()}
              title={updatedDate.toISOString()}
            >
              {updatedDate.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })}
            </time>
          }
          {relatedResource && (
            <Inline className="inline-flex">
              {" • "}
              <ToggleHelper
                showElement={showAnswer}
                setShowElement={setShowAnswer}
                message="Answer"
              />
            </Inline>
          )}
        </BodySmall>
      </>
    );
  }

  React.useEffect(() => {
    if (!showMarkdown && text !== "") {
      editorRef.current.focus();
      setFocus(true);
    }
  }, [showMarkdown, text]);

  React.useEffect(() => {
    if (focus === false && !isNew(itemId)) {
      if (!checkEmptyMDText(text)) {
        if (!isNew(itemId)) {
          deleteMe();
        }
        return;
      }

      if (checked || initialText === text) return;
      if (typeof onEdit !== "function") return;

      const data = { text: text, updatedAt: new Date() };
      setUpdatedDate(new Date());

      if (createdBy) {
        setEditedBy(getUserName(currentUser.id));
        data.lastEditedById = currentUser.id;
      } else {
        setCreatedBy(getUserName(currentUser.id));
        setEditedBy(null);
        data.lastEditedById = null;
        data.requesterId = currentUser.id;
        data.requestedId = requestedUser.id;
        data.assignee = null;
        data.createdAt = createdAtDate;
      }
      if (id !== itemId) {
        data.id = itemId;
      }
      onEdit(id, data);
      setShowMarkdown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, itemId]);

  const isActionItem = label.toLowerCase() === "action item";

  return (
    <Frame
      data-id={id}
      data-testid={`task-${id}`}
      className="relative group box-border items-start py-2 leading-normal hover:bg-neutral-100 transition-colors duration-300 ease-in-out"
    >
      <Frame className="mx-3">
        <Inline alignment="start" role="group">
          <Frame aria-label="drag" className="w-6" {...dragHandleProps}>
            <Drag
              className={`opacity-0 w-5 ${
                text &&
                updatedDate &&
                !disabled &&
                "group-hover:opacity-100 transition-opacity duration-300"
              }`}
            />
          </Frame>
          <Checkbox
            padding={0}
            aria-label="checkbox"
            className="mr-0 h-6"
            border={null}
            checked={checked}
            onChange={toggleCheck}
            disabled={text === "" || disabled}
          />
          <Stack data-cy="task-body" className="flex-1 overflow-auto">
            {showMarkdown ? (
              <MarkdownViewer
                label={label}
                value={text}
                onClick={() => {
                  if (!checked && !disabled) {
                    setShowMarkdown(false);
                    setFocus(true);
                  }
                }}
                completed={checked}
                style={{
                  color:
                    disabled || checked
                      ? "rgba(0, 0, 0, 0.25)"
                      : "rgba(0, 0, 0, 0.65)",
                  textDecoration: checked
                    ? "line-through solid rgba(0, 0, 0, 0.25)"
                    : null,
                }}
              />
            ) : (
              <Stack space={1}>
                <MarkdownEditor
                  label={label}
                  text={text}
                  placeholder={placeholder}
                  onBlur={handleBlur}
                  p={0}
                  type="multiline"
                  autosize={40}
                  iconContainerCls="my-1 absolute bottom-0 right-0"
                  markdownIconCls="h-4 w-4"
                  className="border-none focus:border-none focus:shadow-none group-hover:bg-neutral-100 transition-colors duration-300 ease-in-out"
                  readOnly={checked || disabled}
                  onRef={(ref) => {
                    editorRef.current = ref.current;
                  }}
                  {...autosaveTaskProps}
                />
                <SavedState
                  hasUnsavedChanges={dataHasUnsavedChanges}
                  isLoading={dataIsLoading}
                  savedAt={dataSavedAt}
                  loadError={dataloadError}
                  name={label}
                  className="mb-2"
                />
              </Stack>
            )}
            {StatusBar}
          </Stack>
          {text && updatedDate && (
            <Inline className="h-6 ml-2" space={2}>
              <Touchable
                onClick={(event) => {
                  event.preventDefault();
                  deleteMe();
                }}
                type="button"
                style={{
                  visibility: (disabled || disabledActions.delete) && "hidden",
                }}
                className="opacity-0 bg-transparent group-focus-within:block group-hover:opacity-100 transition-opacity duration-300"
                aria-label="Delete"
              >
                <Trash
                  style={{ marginLeft: "-1px" }}
                  id={id}
                  className="w-4 h-4 text-neutral-600 hover:text-neutral-800 transition-colors duration-300 ease-in-out"
                />
              </Touchable>

              {isActionItem && (
                <CoreTooltip
                  data-testid="tooltip-assign-user"
                  label={
                    assignedToId === null
                      ? "Assign action item"
                      : `Assigned to ${
                          assignedToId === currentUser.id
                            ? "you"
                            : requestedUser.preferredName
                        }`
                  }
                >
                  <Touchable
                    data-testid="btn-assign-user"
                    onClick={(event) => {
                      event.preventDefault();
                      showParticipantsDropDown();
                    }}
                    disabled={disabled && assignedToId === null}
                    className={clsx(assignedToId && "w-5")}
                  >
                    {assignedToId !== null ? (
                      <AvatarXS
                        url={
                          assignedToId.toString() === currentUser.id.toString()
                            ? currentUser.avatarUrl
                            : requestedUser.avatarUrl
                        }
                        name={
                          assignedToId.toString() === currentUser.id.toString()
                            ? currentUser.preferredName
                            : requestedUser.preferredName
                        }
                        userId={
                          assignedToId.toString() === currentUser.id.toString()
                            ? currentUser.preferredName
                            : requestedUser.preferredName
                        }
                      />
                    ) : (
                      !disabled && (
                        <AddUser className="opacity-0 group-hover:opacity-100 w-4 h-4 text-neutral-600 hover:text-neutral-800 transition-all duration-300 ease-in-out" />
                      )
                    )}
                  </Touchable>
                </CoreTooltip>
              )}
            </Inline>
          )}
          {isActionItem && showUsersDropdown && (
            <Dropdown.List
              data-testid="assign-user-dropdown"
              isVisible={showUsersDropdown}
              className="absolute right-0"
              onMouseLeave={(_) => setShowUsersDropdown(false)}
            >
              <Text style={{ paddingLeft: "1.1rem" }}>Assign to:</Text>
              <UserListItem
                isLink={false}
                userData={currentUser}
                handleAssignActionItem={assignActionItem}
                isCurrentUser
                isAssigned={assignedToId === currentUser.id}
              />
              <UserListItem
                isLink={false}
                userData={requestedUser}
                handleAssignActionItem={assignActionItem}
                isAssigned={assignedToId === requestedUser.id?.toString()}
              />
            </Dropdown.List>
          )}
        </Inline>
        <AnimatePresence>
          {showAnswer && relatedResource && (
            <Frame
              as={motion.ul}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0.1, height: 0 },
              }}
              transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <TaskRelatedResource
                relatedResourceType={relatedResourceType}
                relatedResource={relatedResource}
                currentUser={currentUser}
              />
            </Frame>
          )}
        </AnimatePresence>
      </Frame>
    </Frame>
  );
}

export default Task;
