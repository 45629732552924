import React from "react";
import { useCollection, useResource } from "coreql";
import Select from "../select";
import { uniq } from "lodash";
import { wrap } from "../../weekly-report/utils";

export function combineResponsibilities({
  projectRoleResponsibilities = [],
  roleResponsibilities = [],
  disciplineLeadersResponsibilities = [],
  disciplineResponsibilities = [],
  departmentLeadersResponsibilities = [],
  departmentResponsibilities = [],
  individualContributorsResponsibilities = [],
  managersResponsibilities = [],
  commonResponsibilities = [],
}) {
  function getResponsibilitiesFrom(assignedResponsibilities) {
    return wrap(assignedResponsibilities).flatMap(
      ({ responsibilities }) => responsibilities,
    );
  }

  return uniq(
    [].concat(
      getResponsibilitiesFrom(projectRoleResponsibilities),
      getResponsibilitiesFrom(departmentLeadersResponsibilities),
      getResponsibilitiesFrom(disciplineLeadersResponsibilities),
      roleResponsibilities,
      disciplineResponsibilities,
      departmentResponsibilities,
      individualContributorsResponsibilities,
      managersResponsibilities,
      commonResponsibilities,
    ),
  );
}

export function useUser(id) {
  return useResource(
    "users",
    id,
    {
      fields: {
        users: [
          "id",
          "preferredName",
          "projectRoleResponsibilities",
          "roleResponsibilities",
          "disciplineResponsibilities",
          "disciplineLeadersResponsibilities",
          "departmentLeadersResponsibilities",
          "departmentResponsibilities",
          "individualContributorsResponsibilities",
          "managersResponsibilities",
          "commonResponsibilities",
        ],
      },
    },
    "denormalized",
  );
}

export function useResponsibilities({ userId, options = {} } = {}) {
  const { data: user } = useUser(userId);
  return useCollection(
    "responsibilities",
    {
      filters: { id: combineResponsibilities(user) },
      fields: { responsibilities: ["id", "title"] },
      options,
    },
    "denormalized",
  );
}

export default function ResponsibilitySelect({ userId, ...props }) {
  const { data: responsibilities } = useResponsibilities({ userId });
  return (
    <Select
      options={responsibilities.map(({ id, title }) => ({
        value: id,
        label: title,
      }))}
      testId="responsibility"
      {...props}
    />
  );
}
