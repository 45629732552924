import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ReferenceLine,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
import {
  Frame,
  Text,
  TextSize,
  TextLeading,
  TextTracking,
  Color,
  Corners,
  Inline,
} from "@ableco/baseline";

import { toUSD } from "../../utils/currency";

const BAR_SIZE = 10;
const BAR_GAP = 4;
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const legends = [
  { color: Color.Neutral500, label: "Revenue" },
  { color: Color.Warning, label: "Cost" },
  { color: Color.Success, label: "Profit" },
  { color: Color.Alert, label: "Loss" },
];

function CustomLegend() {
  return (
    <Inline as="ul" space={6} className="mt-3 ml-16 items-center">
      {legends.map((legend) => (
        <Text
          as="li"
          key={`item-${legend.label}`}
          color={Color.Neutral700}
          size={TextSize.XS}
          leading={TextLeading.Snug}
          className="flex items-center"
        >
          <Frame
            as="span"
            className="inline-block mr-1 w-3 h-3 rounded-sm"
            bg={legend.color}
          />
          {legend.label}
        </Text>
      ))}
      <Text
        as="li"
        color={Color.Neutral600}
        size={TextSize.XS}
        leading={TextLeading.Snug}
        tracking={TextTracking.Wider}
        className="ml-auto inline-block"
      >
        Note: Chart data is not cumulative
      </Text>
    </Inline>
  );
}

function CustomTooltip({ active, payload }) {
  // This check is needed becuase of a possible bug with rechartsy
  // https://github.com/recharts/recharts/issues/2394#issuecomment-760807555
  if (active && payload) {
    return (
      <Frame bg={Color.Neutral800} p={[1, 2]} corners={Corners.MediumRounded}>
        {payload.map((metric, index) => {
          const { value, dataKey } = metric;
          const amount = toUSD(Math.abs(value));
          let label = dataKey[0].toUpperCase() + dataKey.slice(1);
          if (dataKey === "income") {
            label = value < 0 ? "Loss" : "Profit";
          }
          return (
            <Text
              as="li"
              key={index}
              color={Color.White}
              size={TextSize.SM}
              leading={TextLeading.Snug}
            >
              {label}: {amount}
            </Text>
          );
        })}
      </Frame>
    );
  }
  return null;
}

function tickToDollarsFormatter(tick) {
  const roundedTick = Math.round(tick / 1000);
  return tick < 0 ? `-$${Math.abs(roundedTick)}K` : `$${roundedTick}K`;
}

function StackedBarChart({ metrics: { costByMonth, revenueByMonth } }) {
  const data = months.map((month, index) => {
    const cost = Number.parseFloat(costByMonth[index + 1].toFixed(2));
    const revenue = Number.parseFloat(revenueByMonth[index + 1].toFixed(2));
    return {
      name: month,
      cost: -cost,
      revenue,
      income: revenue - cost,
    };
  });

  return (
    <BarChart
      width={600}
      height={300}
      data={data}
      margin={{ top: 5, right: 5, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        tick={{
          stroke: "#8c8c8c",
          strokeWidth: 0.1,
          fontSize: 14,
          fill: "#8c8c8c",
        }}
        tickLine={{ stroke: "#8c8c8c" }}
        axisLine={{ stroke: "#8c8c8c" }}
      />
      <YAxis
        interval="preserveStartEnd"
        domain={["dataMin - 5000", "dataMax + 5000"]}
        tickFormatter={tickToDollarsFormatter}
        tick={{
          stroke: "#8c8c8c",
          strokeWidth: 0.1,
          fontSize: 14,
          fill: "#8c8c8c",
        }}
        tickLine={{ stroke: "#8c8c8c" }}
        axisLine={{ stroke: "#8c8c8c" }}
      />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: "#e6f7ff" }} />
      <Legend content={<CustomLegend />} />
      <ReferenceLine y={0} stroke="#8c8c8c" />
      <Bar
        dataKey="revenue"
        fill="#bfbfbf"
        barSize={BAR_SIZE}
        transform={`translate(${BAR_SIZE + BAR_GAP}, 0)`}
      />
      <Bar dataKey="cost" fill="#faad14" barSize={BAR_SIZE} t />
      <Bar
        dataKey="income"
        fill="#cf1322"
        barSize={BAR_SIZE}
        transform={`translate(${-(BAR_SIZE + BAR_GAP)}, 0)`}
      >
        {data.map((entry) => {
          const color = entry.income > 0 ? "#52c41a" : "#cf1322";
          return <Cell key={entry.income} fill={color} />;
        })}
      </Bar>
    </BarChart>
  );
}

export default StackedBarChart;
