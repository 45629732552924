import { Avatar, Frame } from "@ableco/baseline";
import { ErrorBoundary, SSRSuspense } from "coreql";
import React from "react";
import { CoreTooltip } from "../core-tooltip";

export function AvatarFallback({ name, className }) {
  return (
    <Avatar
      aria-label="avatar"
      className={className}
      source="https://able.bamboohr.com/employees/photos/"
      name={name}
    />
  );
}

export function AvatarXXL({ className, ...props }) {
  return (
    <UserAvatar
      className={`w-32 h-32 ${className}`}
      ErrorFallback={AvatarFallbackXXL}
      {...props}
    />
  );
}

export function AvatarXL({ className, ...props }) {
  return (
    <UserAvatar
      className={`w-16 h-16 ${className}`}
      ErrorFallback={AvatarFallbackXL}
      {...props}
    />
  );
}

export function AvatarLarge({ className, ...props }) {
  return (
    <UserAvatar
      className={`w-12 h-12 ${className}`}
      ErrorFallback={AvatarFallbackLarge}
      {...props}
    />
  );
}

export function AvatarMedium({ className, ...props }) {
  return (
    <UserAvatar
      className={`w-10 h-10 ${className}`}
      ErrorFallback={AvatarFallbackMedium}
      {...props}
    />
  );
}

export function AvatarSmall({ className, ...props }) {
  return (
    <UserAvatar
      className={`w-8 h-8 ${className}`}
      ErrorFallback={AvatarFallbackSmall}
      {...props}
    />
  );
}

export function AvatarXS({ className, ...props }) {
  return (
    <UserAvatar
      className={`w-6 h-6 ${className}`}
      ErrorFallback={AvatarFallbackXS}
      {...props}
    />
  );
}

function AvatarFallbackXS({ className, name }) {
  return <AvatarFallback name={name} className={`w-6 h-6 ${className}`} />;
}

export function AvatarFallbackSmall({ className, name }) {
  return <AvatarFallback name={name} className={`w-8 h-8 ${className}`} />;
}

export function AvatarFallbackMedium({ className, name }) {
  return <AvatarFallback name={name} className={`w-10 h-10 ${className}`} />;
}

function AvatarFallbackLarge({ className, name }) {
  return <AvatarFallback name={name} className={`w-12 h-12 ${className}`} />;
}

function AvatarFallbackXL({ className, name }) {
  return <AvatarFallback name={name} className={`w-16 h-16 ${className}`} />;
}

function AvatarFallbackXXL({ className, name }) {
  return <AvatarFallback name={name} className={`w-32 h-32 ${className}`} />;
}

export default function UserAvatar({
  url,
  name,
  userId,
  className,
  withTooltip = false,
  ErrorFallback,
}) {
  function StyledErrorFallback() {
    return <ErrorFallback className={className} name={name} />;
  }
  return (
    <Frame
      as="figure"
      className="flex-shrink-0"
      data-testid={`avatar:${userId}`}
    >
      <ErrorBoundary fallback={StyledErrorFallback}>
        <SSRSuspense fallback={<StyledErrorFallback />}>
          {withTooltip ? (
            <CoreTooltip label={name}>
              <Avatar className={className} source={url} name={name} suspense />
            </CoreTooltip>
          ) : (
            <Avatar className={className} source={url} name={name} suspense />
          )}
        </SSRSuspense>
      </ErrorBoundary>
    </Frame>
  );
}
