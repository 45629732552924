/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";

function createAsyncQueue(options = {}) {
  let pending = Promise.resolve();
  let queue = [];
  async function run() {
    try {
      await pending;
    } finally {
      if (queue.length === 0) return;
      const { callback, params } = queue.shift();
      return callback(...params);
    }
  }

  function addToQueue(action) {
    if (options.queueFn) options.queueFn(action, queue);
    else queue.push(action);
    return (pending = run());
  }

  function updateQueue(updateFn) {
    queue = updateFn(queue);
  }
  return { addToQueue, updateQueue };
}

export function useAsyncQueue(options) {
  return useMemo(() => createAsyncQueue(options), []);
}

function useAutosavetextInput({ onSave, onChange }) {
  const { addToQueue, updateQueue } = useAsyncQueue({
    queueFn: (action, queue) => {
      if (queue.length === 0) queue.push(action);
    },
  });

  const fnRef = useRef(onSave);

  const debauncedSave = useCallback(
    debounce(
      (event) =>
        addToQueue({
          callback: fnRef.current,
          params: [event],
        }),
      750,
    ),
    [],
  );

  /* Update our queue callbacks after onSave function changes, allows state dependent onSave functions */
  useEffect(() => {
    fnRef.current = onSave;
    updateQueue((queue) =>
      queue.map(({ params }) => ({ params, callback: onSave })),
    );
  }, [onSave]);

  const autosaveOnChange = useCallback(
    (event) => {
      onChange(event);
      event.persist();
      debauncedSave(event);
    },
    [debauncedSave, onChange],
  );

  return { onChange: autosaveOnChange };
}

export default useAutosavetextInput;
