import React from "react";
import { Stack } from "@ableco/baseline";
import ReadOnlyObjective from "./read-only-objective";
import produce from "immer";
import { useCollection, denormalize } from "coreql";
import { wrap } from "./utils";
import { useSWRSuspense } from "swr";

export function useObjectiveStatusUpdates(weeklyReportId) {
  return useCollection("objective-status-updates", {
    fields: {
      objectiveStatusUpdates: [
        "status",
        "objective",
        "objectiveId",
        "oneOnOneItem",
        "weeklyReportId",
        "periodStart",
      ],
      objectives: ["title", "objectiveStatusUpdates", "ancestorsName"],
      oneOnOneItems: ["text"],
    },
    filters: { weeklyReportId: weeklyReportId },
    included: ["objective", "objective.objectiveStatusUpdates", "OneOnOneItem"],
  });
}

function useKeyResultStatusUpdates(weeklyReportId) {
  return useCollection("key-result-status-updates", {
    fields: {
      keyResults: [
        "title",
        "dueDate",
        "start",
        "target",
        "kind",
        "keyResultStatusUpdates",
        "objectiveId",
        "decreasing",
      ],
      keyResultStatusUpdates: ["status", "weeklyReportId", "periodStart"],
    },
    filters: {
      weeklyReportId: weeklyReportId,
    },
    included: ["keyResult", "keyResult.keyResultStatusUpdates"],
  });
}

function ReadOnlyObjectives({
  weeklyId,
  currentUserId,
  managerId,
  weeklyUser,
}) {
  const [
    objectiveStatusUpdatesResponse,
    keyResultStatusUpdatesResponse,
    objectivesStatusUpdatesRefetch,
  ] = useSWRSuspense(() => {
    const {
      data: objectiveStatusUpdatesResponse,
      mutate: objectivesStatusUpdatesRefetch,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useObjectiveStatusUpdates(weeklyId);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data: keyResultStatusUpdatesResponse } =
      useKeyResultStatusUpdates(weeklyId);

    return [
      objectiveStatusUpdatesResponse,
      keyResultStatusUpdatesResponse,
      objectivesStatusUpdatesRefetch,
    ];
  });

  const objectives = wrap(
    denormalize(objectiveStatusUpdatesResponse, "objectives"),
  );
  const keyResults = wrap(
    denormalize(keyResultStatusUpdatesResponse, "keyResults"),
  );

  const objectivesWithKeyResults = produce(objectives, (draftObjectives) => {
    for (const objective of draftObjectives) {
      objective.keyResults = keyResults.filter(
        (keyResult) => String(keyResult.objectiveId) === String(objective.id),
      );
    }
  });

  return (
    <Stack space={10}>
      {objectivesWithKeyResults.map((objective) => (
        <ReadOnlyObjective
          key={objective.id.toString()}
          objective={objective}
          journalId={weeklyId}
          refetch={objectivesStatusUpdatesRefetch}
          userId={currentUserId}
          managerId={managerId}
          weeklyUser={weeklyUser}
        />
      ))}
    </Stack>
  );
}

export default ReadOnlyObjectives;
