export default class Resource {
  constructor(api) {
    this._api = api;
  }

  _get(path, params, options = {}) {
    const pathWithParams = this._buildPath(path, params);
    return this._request("GET", pathWithParams, {}, options);
  }

  _post(path, data, options = {}) {
    return this._request("POST", path, data, options);
  }

  _put(path, data, options = {}) {
    return this._request("PUT", path, data, options);
  }

  _delete(path, params, options = {}) {
    const pathWithParams = this._buildPath(path, params);
    return this._request("DELETE", pathWithParams, {}, options);
  }

  _buildPath(path, params = {}) {
    if (Object.keys(params).length > 0) {
      path = `${path}?${this._objectToURLFormattedParams(params)}`;
    }

    return path;
  }

  _objectToURLFormattedParams(params) {
    const encodedParams = Object.entries(params).map(
      ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
    );
    return encodedParams.join("&");
  }

  _buildUrl(path) {
    return `${this._api.host}${this._api.basePath}/${path}`;
  }

  _defaultHeaders() {
    return {
      Authorization: `Bearer token=${this._api.token}`,
      "Content-Type": "application/json",
    };
  }

  _handleSuccessResponse(response, data) {
    return {
      status: response.status,
      data: data,
    };
  }

  _handleErrorResponse(response, data) {
    return Promise.reject({
      status: response.status,
      data: data,
    });
  }

  _request(method, path, data, options = {}) {
    const url = this._buildUrl(path);

    const headers = { ...this._defaultHeaders(), ...options.headers };

    let requestOptions = { method, headers };

    if (Object.keys(data).length > 0) {
      requestOptions.body = JSON.stringify(data);
    }

    const self = this;

    return fetch(url, requestOptions).then((response) => {
      const contentType = response.headers.get("Content-Type").toLowerCase();

      return contentType && contentType.includes("application/json")
        ? response
            .json()
            .then((data) =>
              response.ok
                ? self._handleSuccessResponse(response, data)
                : self._handleErrorResponse(response, data),
            )
        : self._handleErrorResponse(response, {
            error: `Invalid content type: ${contentType}`,
          });
    });
  }
}
