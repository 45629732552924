// Modules
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
// Reducers
import entities from "./entities";
import errors from "./errors";
import periods from "./periods";
import ui from "./ui";

export default (history) =>
  combineReducers({
    entities,
    errors,
    periods,
    router: connectRouter(history),
    ui,
  });
