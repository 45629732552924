/* eslint-disable func-style */
// Modules
import { connect } from "react-redux";
import { get } from "lodash";
// Constants
import RowTypes from "../../constants/RowTypes";
// Selectors
import { isDetailVisible } from "../../selectors/visibleDetailSelectors";
// Selectors
import * as productRevenue from "../../selectors/productRevenueSelectors";
import * as productAllocations from "../../selectors/productAllocationSelectors";
import * as productAssignments from "../../selectors/productAssignmentSelectors";
import * as productInvestments from "../../selectors/productInvestmentSelectors";
// Actions
import toggleDetailVisibility from "../../actions/toggleDetailVisibility";
// Components
import LabelRows from "../../components/LabelRows";
import ValueRows from "../../components/ValueRows";

const types = {
  revenue: {
    estimated: {
      currency: true,
      getSummary: productRevenue.getEstimatedRevenue,
      // Revenue doesn't contain roles or users associated with it, so just
      // return an empty array for these expected functions.
      getByRole: () => [],
      getByUser: () => [],
      name: "Estimated",
      rowIdPrefix: RowTypes.REVENUE_ESTIMATED,
    },
    actual: {
      currency: true,
      getSummary: productRevenue.getActualRevenue,
      // Revenue doesn't contain roles or users associated with it, so just
      // return an empty array for these expected functions.
      getByRole: () => [],
      getByUser: () => [],
      name: "Actual",
      rowIdPrefix: RowTypes.REVENUE_ACTUAL,
    },
    dev_est_act: {
      currency: true,
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productRevenue.getEstimatedRevenue,
          productRevenue.getActualRevenue,
          state,
          products,
        ),
      // Revenue doesn't contain roles or users associated with it, so just
      // return an empty array for these expected functions.
      getByRole: () => [],
      getByUser: () => [],
      name: "Δ Estimated & Actual",
      rowIdPrefix: RowTypes.REVENUE_DEVIATION_ESTIMATED_ACTUAL,
    },
  },

  cost: {
    actual: {
      currency: true,
      getSummary: productInvestments.getActualCost,
      getByRole: productInvestments.getActualCostByRole,
      getByUser: productInvestments.getActualCostByUser,
      name: "Actual",
      rowIdPrefix: RowTypes.COST_ACTUAL,
    },
    budgeted: {
      currency: true,
      getSummary: productAllocations.getBudgetedCost,
      getByRole: productAllocations.getBudgetedCostByRole,
      getByUser: productAllocations.getBudgetedCostByUser,
      name: "Budgeted",
      rowIdPrefix: RowTypes.COST_BUDGETED,
    },
    estimated: {
      currency: true,
      getSummary: productAssignments.getEstimatedCost,
      getByRole: productAssignments.getEstimatedCostByRole,
      getByUser: productAssignments.getEstimatedCostByUser,
      name: "Estimated",
      rowIdPrefix: RowTypes.COST_ESTIMATED,
    },
    dev_bud_est: {
      currency: true,
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productAllocations.getBudgetedCost,
          productAssignments.getEstimatedCost,
          state,
          products,
        ),
      getByRole: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedCostByRole,
          productAssignments.getEstimatedCostByRole,
          state,
          products,
        ),
      getByUser: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedCostByUser,
          productAssignments.getEstimatedCostByUser,
          state,
          products,
        ),
      name: "Δ Budgeted & Estimated",
      rowIdPrefix: RowTypes.COST_DEVIATION_BUDGETED_ESTIMATED,
    },
    dev_bud_act: {
      currency: true,
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productAllocations.getBudgetedCost,
          productInvestments.getActualCost,
          state,
          products,
        ),
      getByRole: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedCostByRole,
          productInvestments.getActualCostByRole,
          state,
          products,
        ),
      getByUser: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedCostByUser,
          productInvestments.getActualCostByUser,
          state,
          products,
        ),
      name: "Δ Budgeted & Actual",
      rowIdPrefix: RowTypes.COST_DEVIATION_BUDGETED_ACTUAL,
    },
    dev_est_act: {
      currency: true,
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productAssignments.getEstimatedCost,
          productInvestments.getActualCost,
          state,
          products,
        ),
      getByRole: (state, products) =>
        generateDeviationDetailRows(
          productAssignments.getEstimatedCostByRole,
          productInvestments.getActualCostByRole,
          state,
          products,
        ),
      getByUser: (state, products) =>
        generateDeviationDetailRows(
          productAssignments.getEstimatedCostByUser,
          productInvestments.getActualCostByUser,
          state,
          products,
        ),
      name: "Δ Estimated & Actual",
      rowIdPrefix: RowTypes.COST_DEVIATION_ESTIMATED_ACTUAL,
    },
  },

  headcount: {
    actual: {
      getSummary: productInvestments.getActualHeadcount,
      getByRole: productInvestments.getActualHeadcountByRole,
      getByUser: productInvestments.getActualHeadcountByUser,
      name: "Actual",
      rowIdPrefix: RowTypes.HEADCOUNT_ACTUAL,
    },
    budgeted: {
      getSummary: productAllocations.getBudgetedHeadcount,
      getByRole: productAllocations.getBudgetedHeadcountByRole,
      getByUser: productAllocations.getBudgetedHeadcountByUser,
      name: "Budgeted",
      rowIdPrefix: RowTypes.HEADCOUNT_BUDGETED,
    },
    estimated: {
      getSummary: productAssignments.getEstimatedHeadcount,
      getByRole: productAssignments.getEstimatedHeadcountByRole,
      getByUser: productAssignments.getEstimatedHeadcountByUser,
      name: "Estimated",
      rowIdPrefix: RowTypes.HEADCOUNT_ESTIMATED,
    },
    dev_bud_est: {
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productAllocations.getBudgetedHeadcount,
          productAssignments.getEstimatedHeadcount,
          state,
          products,
        ),
      getByRole: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedHeadcountByRole,
          productAssignments.getEstimatedHeadcountByRole,
          state,
          products,
        ),
      getByUser: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedHeadcountByUser,
          productAssignments.getEstimatedHeadcountByUser,
          state,
          products,
        ),
      name: "Δ Budgeted & Estimated",
      rowIdPrefix: RowTypes.HEADCOUNT_DEVIATION_BUDGETED_ESTIMATED,
    },
    dev_bud_act: {
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productAllocations.getBudgetedHeadcount,
          productInvestments.getActualHeadcount,
          state,
          products,
        ),
      getByRole: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedHeadcountByRole,
          productInvestments.getActualHeadcountByRole,
          state,
          products,
        ),
      getByUser: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedHeadcountByUser,
          productInvestments.getActualHeadcountByUser,
          state,
          products,
        ),
      name: "Δ Budgeted & Actual",
      rowIdPrefix: RowTypes.HEADCOUNT_DEVIATION_BUDGETED_ACTUAL,
    },
    dev_est_act: {
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productAssignments.getEstimatedHeadcount,
          productInvestments.getActualHeadcount,
          state,
          products,
        ),
      getByRole: (state, products) =>
        generateDeviationDetailRows(
          productAssignments.getEstimatedHeadcountByRole,
          productInvestments.getActualHeadcountByRole,
          state,
          products,
        ),
      getByUser: (state, products) =>
        generateDeviationDetailRows(
          productAssignments.getEstimatedHeadcountByUser,
          productInvestments.getActualHeadcountByUser,
          state,
          products,
        ),
      name: "Δ Estimated & Actual",
      rowIdPrefix: RowTypes.HEADCOUNT_DEVIATION_ESTIMATED_ACTUAL,
    },
  },

  hours: {
    actual: {
      getSummary: productInvestments.getActualHours,
      getByRole: productInvestments.getActualHoursByRole,
      getByUser: productInvestments.getActualHoursByUser,
      name: "Actual",
      rowIdPrefix: RowTypes.HOURS_ACTUAL,
    },
    budgeted: {
      getSummary: productAllocations.getBudgetedHours,
      getByRole: productAllocations.getBudgetedHoursByRole,
      getByUser: productAllocations.getBudgetedHoursByUser,
      name: "Budgeted",
      rowIdPrefix: RowTypes.HOURS_BUDGETED,
    },
    estimated: {
      getSummary: productAssignments.getEstimatedHours,
      getByRole: productAssignments.getEstimatedHoursByRole,
      getByUser: productAssignments.getEstimatedHoursByUser,
      name: "Estimated",
      rowIdPrefix: RowTypes.HOURS_ESTIMATED,
    },
    dev_bud_est: {
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productAllocations.getBudgetedHours,
          productAssignments.getEstimatedHours,
          state,
          products,
        ),
      getByRole: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedHoursByRole,
          productAssignments.getEstimatedHoursByRole,
          state,
          products,
        ),
      getByUser: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedHoursByUser,
          productAssignments.getEstimatedHoursByUser,
          state,
          products,
        ),
      name: "Δ Budgeted & Estimated",
      rowIdPrefix: RowTypes.HOURS_DEVIATION_BUDGETED_ESTIMATED,
    },
    dev_bud_act: {
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productAllocations.getBudgetedHours,
          productInvestments.getActualHours,
          state,
          products,
        ),
      getByRole: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedHoursByRole,
          productInvestments.getActualHoursByRole,
          state,
          products,
        ),
      getByUser: (state, products) =>
        generateDeviationDetailRows(
          productAllocations.getBudgetedHoursByUser,
          productInvestments.getActualHoursByUser,
          state,
          products,
        ),
      name: "Δ Budgeted & Actual",
      rowIdPrefix: RowTypes.HOURS_DEVIATION_BUDGETED_ACTUAL,
    },
    dev_est_act: {
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productAssignments.getEstimatedHours,
          productInvestments.getActualHours,
          state,
          products,
        ),
      getByRole: (state, products) =>
        generateDeviationDetailRows(
          productAssignments.getEstimatedHoursByRole,
          productInvestments.getActualHoursByRole,
          state,
          products,
        ),
      getByUser: (state, products) =>
        generateDeviationDetailRows(
          productAssignments.getEstimatedHoursByUser,
          productInvestments.getActualHoursByUser,
          state,
          products,
        ),
      name: "Δ Estimated & Actual",
      rowIdPrefix: RowTypes.HOURS_DEVIATION_ESTIMATED_ACTUAL,
    },
  },
  gross: {
    estimated: {
      currency: true,
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productRevenue.getEstimatedRevenue,
          productAssignments.getEstimatedCost,
          state,
          products,
        ),
      // Revenue doesn't contain roles or users associated with it, so just
      // return an empty array for these expected functions.
      getByRole: () => [],
      getByUser: () => [],
      name: "Estimated",
      rowIdPrefix: RowTypes.GROSS_ESTIMATED,
    },
    actual: {
      currency: true,
      getSummary: (state, products) =>
        generateDeviationSummaryRows(
          productRevenue.getActualRevenue,
          productInvestments.getActualCost,
          state,
          products,
        ),
      // Revenue doesn't contain roles or users associated with it, so just
      // return an empty array for these expected functions.
      getByRole: () => [],
      getByUser: () => [],
      name: "Actual",
      rowIdPrefix: RowTypes.GROSS_ACTUAL,
    },
    dev_est_act: {
      currency: true,
      getSummary: (state, products) =>
        generateCombinedDeviationSummaryRows(
          [
            productRevenue.getEstimatedRevenue,
            productAssignments.getEstimatedCost,
          ],
          [productRevenue.getActualRevenue, productInvestments.getActualCost],
          state,
          products,
        ),
      // Revenue doesn't contain roles or users associated with it, so just
      // return an empty array for these expected functions.
      getByRole: () => [],
      getByUser: () => [],
      name: "Δ Estimated & Actual",
      rowIdPrefix: RowTypes.GROSS_DEVIATION_ESTIMATED_ACTUAL,
    },
  },
};

const generateDeviationSummaryRows = (selector1, selector2, state, product) => {
  const values1 = selector1(state, product);
  const values2 = selector2(state, product);

  return values1.reduce((deviationValues, value1, index) => {
    deviationValues.push(value1 - values2[index]);
    return deviationValues;
  }, []);
};

const generateDeviationDetailRows = (selector1, selector2, state, product) => {
  const rows1 = selector1(state, product);
  const rows2 = selector2(state, product);

  return removeEmptyRows(
    rows1.reduce((deviationRows, row, index) => {
      deviationRows.push(
        Object.assign({}, row, {
          values: row.values.map(
            (value, n) => value - (rows2[index] ? rows2[index].values[n] : 0),
          ),
        }),
      );
      return deviationRows;
    }, []),
  );
};

const generateCombinedDeviationSummaryRows = (
  selector1,
  selector2,
  state,
  product,
) => {
  const deviation1 = generateDeviationSummaryRows(
    selector1[0],
    selector1[1],
    state,
    product,
  );

  const deviation2 = generateDeviationSummaryRows(
    selector2[0],
    selector2[1],
    state,
    product,
  );

  return deviation1.reduce((deviationValues, value1, index) => {
    deviationValues.push(value1 - deviation2[index]);
    return deviationValues;
  }, []);
};

const generateDetailRows = (
  detailBy,
  getByRole,
  getByUser,
  state,
  products,
) => {
  let rows = [];

  if (detailBy === "role") {
    rows = getByRole(state, products);
  } else if (detailBy === "user") {
    rows = getByUser(state, products);
  }

  return removeEmptyRows(rows);
};

const removeEmptyRows = (rows) =>
  rows.filter((row) => row.values.filter((v) => v !== 0).length);

const getRowId = ({ filter, product }) =>
  `${get(types, filter).rowIdPrefix}_${product ? product.id : "SUMMARY"}`;

const mapStateToProps = (state, ownProps) => {
  const { detailBy } = state.ui.filters;
  const { filter, products, product } = ownProps;
  const typeProps = get(types, filter);

  const summaryValues = typeProps.getSummary(state, products || [product]);

  const rowId = getRowId(ownProps);

  const showDetail = isDetailVisible(state, rowId);

  let detailRows = [];
  if (showDetail) {
    detailRows = generateDetailRows(
      detailBy,
      typeProps.getByRole,
      typeProps.getByUser,
      state,
      products || [product],
    );
  }

  return {
    currency: typeProps.currency,
    detailRows,
    name: typeProps.name,
    summaryValues,
    dataTestId: filter && product ? `${filter}-${product.id}` : "",
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleDetailVisibility: () =>
    dispatch(toggleDetailVisibility(getRowId(ownProps))),
});

const labelRows = connect(mapStateToProps, mapDispatchToProps)(LabelRows);

const valueRows = connect(mapStateToProps, mapDispatchToProps)(ValueRows);

export default {
  Labels: labelRows,
  Values: valueRows,
};
