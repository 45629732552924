import React from "react";
import {
  Color,
  Corners,
  Flex,
  Frame,
  Stack,
  Text,
  TextLeading,
  TextSize,
  TextTransform,
  TextWeight,
} from "@ableco/baseline";
import { useResource } from "coreql";
import { CaretDown, CaretUp } from "@baseline/icons";

function getDifferenceColor(difference) {
  if (difference > 0) return Color.Success;
  if (difference === 0) return Color.Neutral800;
  return Color.Alert;
}

function getIcon(difference) {
  const style = {
    marginLeft: "3px",
    marginTop: "1.8px",
    width: "15px",
  };
  if (difference > 0) return <CaretUp style={style} />;
  if (difference < 0) return <CaretDown style={style} />;
  return null;
}

function Metric({ title, metricNumber, average }) {
  const difference = Number.parseInt(metricNumber) - Number.parseInt(average);
  const percentage = Math.round((difference / average) * 100);

  return (
    <Frame>
      <Text
        as={"p"}
        color={Color.Neutral800}
        size={TextSize.SM}
        weight={TextWeight.Normal}
        style={{ lineHeight: "20px" }}
      >
        {title}
      </Text>
      <Text
        as={"h4"}
        color={Color.Neutral800}
        size={TextSize.XL2}
        weight={TextWeight.SemiBold}
        className="inline-flex"
        style={{ lineHeight: "32px", marginRight: "4px" }}
      >
        {metricNumber}
      </Text>
      <Text
        as="div"
        color={getDifferenceColor(difference)}
        size={TextSize.SM}
        className="inline-flex items-center leading-normal"
      >
        {`${difference > 0 ? "+" : ""}${difference} (${percentage}%)`}{" "}
        {getIcon(difference)}
      </Text>
      <Frame>
        <Text
          size={TextSize.XS}
          color={Color.Neutral700}
          weight={TextWeight.Normal}
          leading={TextLeading.None}
        >
          Average of {average}
        </Text>
      </Frame>
    </Frame>
  );
}

function DashboardFeedback() {
  const { data } = useResource("feedback-messages", "dashboard");
  const { reportDate, previousWeek, past12Weeks } = data;
  return (
    <>
      <Flex
        className="w-full"
        distribution="between"
        alignment="center"
        style={{ marginBottom: "12px", width: "100%" }}
      >
        <Text
          as="h2"
          leading={TextLeading.Loose}
          weight={TextWeight.Bold}
          color={Color.Neutral800}
          size={TextSize.XS}
          transform={TextTransform.Upper}
        >
          Feedback
        </Text>
        <Text
          leading={TextLeading.None}
          color={Color.Neutral800}
          size={TextSize.XS}
          className="text-right"
        >
          Week of {reportDate}
        </Text>
      </Flex>

      <Frame
        className="flex justify-between items-center w-full"
        style={{ marginBottom: "30px" }}
      >
        <Stack
          space={3}
          bg={Color.White}
          border={Color.Neutral400}
          corners={Corners.MediumRounded}
          p={6}
          style={{ width: "321px" }}
        >
          <Text
            as="h3"
            color={Color.Neutral800}
            size={TextSize.Base}
            weight={TextWeight.Medium}
            leading={TextLeading.Normal}
            className="mb-3"
          >
            Feedback messages
          </Text>
          <Frame className="flex justify-between" style={{ marginTop: "0" }}>
            <Metric
              title="Sent"
              metricNumber={previousWeek.sentCount}
              average={past12Weeks.sentAverage}
            />
            <Metric
              title="Senders"
              metricNumber={previousWeek.senderCount}
              average={past12Weeks.senderAverage}
            />
          </Frame>
        </Stack>
      </Frame>
    </>
  );
}

export default DashboardFeedback;
