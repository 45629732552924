// Modules
import { get, isEqual, map, uniq, uniqWith, values } from "lodash";
// Constants
import ActionTypes from "../../../core-redux/constants/ActionTypes";

export default (resourcesState = {}, action) => {
  let shouldPerformMap =
    action.type === ActionTypes.API.LIST_SUCCESS &&
    (action.responseResourceType === "products" ||
      action.responseResourceType === "users" ||
      action.responseResourceType === "placeholders" ||
      action.responseResourceType === "productAllocations" ||
      action.responseResourceType === "productAssignments" ||
      action.responseResourceType === "productInvestments" ||
      action.responseResourceType === "productRevenues");

  return shouldPerformMap
    ? {
        ...resourcesState,
        productAllocations: addProductAllocationMaps(resourcesState),
        productAssignments: addProductAssignmentMaps(resourcesState),
        productInvestments: addProductInvestmentMaps(resourcesState),
        productRevenues: addProductRevenueMaps(resourcesState),
      }
    : resourcesState;
};

const addProductAllocationMaps = (state) => {
  const productIds = state.products.ids;
  const productAllocations = values(state.productAllocations.byId);

  return {
    ...state.productAllocations,
    byProduct: getByProduct(productIds, productAllocations),
    byProductAndRole: getByProductAndAssigneeRole(
      productIds,
      productAllocations,
      state.users.byId,
      state.placeholders.byId,
    ),
    byProductAndAssignee: getByProductAndAssignee(
      productIds,
      productAllocations,
    ),
  };
};

const addProductAssignmentMaps = (state) => {
  const productIds = state.products.ids;
  const productAssignments = values(state.productAssignments.byId);

  return {
    ...state.productAssignments,
    byProduct: getByProduct(productIds, productAssignments),
    byProductAndRole: getByProductAndAssigneeRole(
      productIds,
      productAssignments,
      state.users.byId,
      state.placeholders.byId,
    ),
    byProductAndAssignee: getByProductAndAssignee(
      productIds,
      productAssignments,
    ),
  };
};

const addProductInvestmentMaps = (state) => {
  const productIds = state.products.ids;
  const productInvestments = values(state.productInvestments.byId);

  return {
    ...state.productInvestments,
    byProduct: getByProduct(productIds, productInvestments),
    byProductAndRole: getByProductAndRole(
      productIds,
      productInvestments,
      state.users.byId,
      "userId",
    ),
    byProductAndUser: getByProductAndUser(
      productIds,
      productInvestments,
      "userId",
    ),
  };
};

const addProductRevenueMaps = (state) => {
  const productIds = state.products.ids;
  const productRevenues = values(state.productRevenues.byId);

  return {
    ...state.productRevenues,
    byProduct: getByProduct(productIds, productRevenues),
  };
};

const getByProduct = (productIds, entities) =>
  productIds.reduce((productIdMap, productId) => {
    const entitiesForProduct = entities.filter(
      (entity) => entity.productId === productId,
    );

    return getPeriodStartMap(productIdMap, productId, entitiesForProduct);
  }, {});

const getByProductAndRole = (productIds, entities, usersById, userIdSelector) =>
  productIds.reduce((productIdMap, productId) => {
    const entitiesForProduct = entities.filter(
      (entity) => entity.productId === productId,
    );
    const roleIds = uniq(
      entitiesForProduct
        .map((entity) => get(usersById[get(entity, userIdSelector)], "roleId"))
        .filter((entity) => entity),
    );

    return {
      ...productIdMap,
      [productId]: roleIds.reduce((roleIdMap, roleId) => {
        const entitiesForRole = entitiesForProduct.filter(
          (entity) =>
            get(usersById[get(entity, userIdSelector)], "roleId") === roleId,
        );

        return getPeriodStartMap(roleIdMap, roleId, entitiesForRole);
      }, {}),
    };
  }, {});

const getByProductAndAssigneeRole = (
  productIds,
  entities,
  usersById,
  placeholdersById,
) =>
  productIds.reduce((productIdMap, productId) => {
    const entitiesForProduct = entities.filter(
      (entity) => entity.productId === productId,
    );
    const roleIds = uniq(
      entitiesForProduct
        .map((entity) => {
          if (entity.assigneeType === "User") {
            return usersById[entity.assigneeId].roleId;
          } else if (entity.assigneeType === "Placeholder") {
            return placeholdersById[entity.assigneeId].roleId;
          }
        })
        .filter((entity) => entity),
    );

    return {
      ...productIdMap,
      [productId]: roleIds.reduce((roleIdMap, roleId) => {
        const entitiesForRole = entitiesForProduct.filter((entity) => {
          if (entity.assigneeType === "User") {
            return usersById[entity.assigneeId].roleId === roleId;
          } else if (entity.assigneeType === "Placeholder") {
            return placeholdersById[entity.assigneeId].roleId === roleId;
          }
        });

        return getPeriodStartMap(roleIdMap, roleId, entitiesForRole);
      }, {}),
    };
  }, {});

const getByProductAndUser = (productIds, entities, userIdSelector) =>
  productIds.reduce((productIdMap, productId) => {
    const entitiesForProduct = entities.filter(
      (entity) => entity.productId === productId,
    );
    const userIds = uniq(map(entitiesForProduct, userIdSelector));

    return {
      ...productIdMap,
      [productId]: userIds.reduce((userIdMap, userId) => {
        const entitiesForUser = entitiesForProduct.filter(
          (entity) => get(entity, userIdSelector) === userId,
        );

        return getPeriodStartMap(userIdMap, userId, entitiesForUser);
      }, {}),
    };
  }, {});

const getByProductAndAssignee = (productIds, entities) =>
  productIds.reduce((productIdMap, productId) => {
    const entitiesForProduct = entities.filter(
      (entity) => entity.productId === productId,
    );
    const assigneeKeys = uniqWith(
      entitiesForProduct.map((entity) => ({
        assigneeType: entity.assigneeType,
        assigneeId: entity.assigneeId,
      })),
      isEqual,
    );

    return {
      ...productIdMap,
      [productId]: assigneeKeys.reduce((assigneeKeyMap, assigneeKey) => {
        const entitiesForAssignee = entitiesForProduct.filter(
          (entity) =>
            entity.assigneeType === assigneeKey.assigneeType &&
            entity.assigneeId === assigneeKey.assigneeId,
        );

        return getPeriodStartMap(
          assigneeKeyMap,
          `${assigneeKey.assigneeType}.${assigneeKey.assigneeId}`,
          entitiesForAssignee,
        );
      }, {}),
    };
  }, {});

const getPeriodStartMap = (collection, id, entities) => {
  const periodStarts = uniq(map(entities, "periodStart"));

  return {
    ...collection,
    [id]: periodStarts.reduce(
      (periodStartsMap, periodStart) => ({
        ...periodStartsMap,
        [periodStart]: entities
          .filter((entity) => entity.periodStart === periodStart)
          .map((entity) => entity.id),
      }),
      {},
    ),
  };
};
