import PropTypes, {
  arrayOf,
  bool,
  instanceOf,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";

const expectation = shape({
  id: number.isRequired,
  responsibilityId: number.isRequired,
  title: string.isRequired,
  active: bool.isRequired,
  archivedAt: string,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});

const period = shape({
  title: string.isRequired,
  start: instanceOf(Date).isRequired,
  end: instanceOf(Date).isRequired,
  periodStarts: arrayOf(PropTypes.string).isRequired,
});

const placeholder = shape({
  id: number.isRequired,
  name: string.isRequired,
  roleId: number.isRequired,
});

const product = shape({
  id: number.isRequired,
  name: string.isRequired,
  activatedAt: string.isRequired,
  archivedAt: string,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});

const productAllocation = shape({
  id: number.isRequired,
  productId: number.isRequired,
  assigneeId: number.isRequired,
  assigneeType: string.isRequired,
  periodStart: string.isRequired,
  hours: number.isRequired,
  cost: number,
});

const productAssignment = shape({
  id: number.isRequired,
  assigneeType: string.isRequired,
  assigneeId: number.isRequired,
  productId: number.isRequired,
  projectRoleId: number.isRequired,
  note: string.isRequired,
  periodStart: string.isRequired,
  timeAllocation: number.isRequired,
  hours: number.isRequired,
  cost: number,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});

const productInvestment = shape({
  id: number.isRequired,
  productId: number.isRequired,
  projectRoleId: number.isRequired,
  roleId: number.isRequired,
  userId: number.isRequired,
  periodStart: string.isRequired,
  hours: number.isRequired,
  cost: number,
});

const productRevenue = shape({
  id: number.isRequired,
  productId: number.isRequired,
  periodStart: string.isRequired,
  actual: number.isRequired,
  estimated: number.isRequired,
});

const projectAllocation = shape({
  id: oneOfType([number, string]).isRequired,
  projectId: number.isRequired,
  projectRoleIds: arrayOf(number).isRequired,
  assigneeType: string.isRequired,
  assigneeId: number.isRequired,
  periodStart: string.isRequired,
  hours: number.isRequired,
});

const projectRole = shape({
  id: number.isRequired,
  name: string.isRequired,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});

const project = shape({
  id: number.isRequired,
  productId: number.isRequired,
  name: string.isRequired,
  productName: string.isRequired,
  discoveryStart: string,
  discoveryEnd: string,
  definitionStart: string,
  definitionEnd: string,
  developmentStart: string,
  developmentEnd: string,
  activatedAt: string.isRequired,
  archivedAt: string,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});

const responsibility = shape({
  id: oneOfType([number, string]),
  type: string.isRequired,
  roleId: number,
  projectRoleId: number,
  title: string.isRequired,
  active: bool.isRequired,
  activeExpectationsCount: number.isRequired,
  archivedAt: string,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});

const role = shape({
  id: number.isRequired,
  title: string.isRequired,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});

const user = shape({
  id: number.isRequired,
  avatarUrl: string,
  fullName: string,
  firstName: string,
  lastName: string,
  preferredName: string,
  // CEO doesn't have a manager.
  managerId: number,
  roleId: number,
  title: string,
  pay_rate_per_hour: number,
});

const AppTypes = {
  expectation,
  period,
  placeholder,
  product,
  productAllocation,
  productAssignment,
  productInvestment,
  productRevenue,
  project,
  projectAllocation,
  projectRole,
  responsibility,
  role,
  user,
};

export default AppTypes;
