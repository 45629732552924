import { Stack, Center, Color, Frame } from "@ableco/baseline";
import React from "react";
import { ErrorBoundary, SSRSuspense, useCollection } from "coreql";
import { FeedbackMessageContainerLayout } from "./feedback-message-content";
import { FeedbackRequestButton } from "../weekly-report/feedback/feedback-received";
import { FeedbackMessagesFallback } from "./feedback-message-fallback";
import { isMessageUnreadByCurrentUser } from "./feedback-messages-utils";
import {
  BodyBaseline,
  BodyBaselineSemibold,
} from "../design-system/typography-components";
import { useParams } from "react-router";

export function useReceivedFeedbackMessages(userId) {
  return useCollection(
    "feedback-messages",
    {
      filters: {
        active: true,
        draft: false,
        receiverId: userId,
      },
      fields: {
        feedbackMessages: [
          "id",
          "submittedAt",
          "sender",
          "readByReceiver",
          "readByManager",
          "feedbackRequest",
        ],
        users: ["fullName", "avatarUrl", "id", "preferredName", "managerId"],
      },
      included: ["sender", "feedbackRequest"],
      sort: ["ASC submittedAt"],
    },
    "denormalized",
  );
}

export function DefaultMessageContent({ numberUnreadMessages, userId }) {
  return (
    <Center className="h-full">
      <Stack space={2}>
        {numberUnreadMessages ? (
          <BodyBaseline
            color={Color.Neutral700}
            className="text-center"
            data-testid="unreadfeedbackreceived"
          >
            <BodyBaselineSemibold color={Color.Primary}>
              {numberUnreadMessages}{" "}
            </BodyBaselineSemibold>
            unread feedback messages.
          </BodyBaseline>
        ) : (
          <BodyBaseline
            color={Color.Neutral700}
            className="text-center"
            data-testid="unreadfeedbackreceived"
          >
            You've read all the feedback!
          </BodyBaseline>
        )}
        <Frame className="text-center">
          <FeedbackRequestButton ownerId={userId} />
        </Frame>
      </Stack>
    </Center>
  );
}

export function FeedbackMessagesReceivedContainer({
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
}) {
  const { userId } = useParams();

  const { data: receivedMessages } = useReceivedFeedbackMessages(userId);

  const numberUnreadMessages = receivedMessages.filter((message) =>
    isMessageUnreadByCurrentUser(
      isCurrentUserDirectManager,
      isCurrentUserInboxOwner,
      message.readByManager,
      message.readByReceiver,
    ),
  ).length;

  const defaultMessageContent = (
    <DefaultMessageContent
      numberUnreadMessages={numberUnreadMessages}
      userId={userId}
    />
  );

  return (
    <FeedbackMessageContainerLayout
      textNoFeedback={"You don't have feedback yet."}
      typeMessages="received"
      feedbackMessages={receivedMessages}
      defaultMessageContent={defaultMessageContent}
      isCurrentUserDirectManager={isCurrentUserDirectManager}
      isCurrentUserInboxOwner={isCurrentUserInboxOwner}
    />
  );
}

export default function FeedbackMessagesReceived({
  setActiveLabelTab,
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
}) {
  setActiveLabelTab("received");

  return (
    <ErrorBoundary fallback={FeedbackMessagesFallback}>
      <SSRSuspense fallback={<FeedbackMessagesFallback />}>
        <FeedbackMessagesReceivedContainer
          isCurrentUserDirectManager={isCurrentUserDirectManager}
          isCurrentUserInboxOwner={isCurrentUserInboxOwner}
        />
      </SSRSuspense>
    </ErrorBoundary>
  );
}
