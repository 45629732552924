import React from "react";
import { Color, Frame, Grid } from "@ableco/baseline";
import {
  BodyBaseline,
  BodyBaselineSemibold,
} from "../design-system/typography-components";

import { wrap } from "../weekly-report/utils";
import AnimatedBodyCells from "./animated-body-cells";
import CapabilitiesBadges from "./capabilities-badges";

function CapabilyTableRow({
  capability,
  levels,
  indexValues,
  showControls,
  oneColumn,
  isEven = false,
  userBadges,
  isCurrentUserDirectManager,
  metObservableBehaviours,
  userId,
  mutateUser,
  renderMetRequirement,
}) {
  const [currentIndex, direction] = indexValues;

  const observableBehaviours = wrap(capability.observableBehaviours);

  return (
    <Grid
      role="row"
      col={oneColumn ? 2 : 3}
      bg={isEven ? Color.Neutral100 : Color.Transparent}
    >
      <Frame role="cell" className="w-full" p={4} className="row-span-2">
        <BodyBaselineSemibold color={Color.Neutral800}>
          {capability.name}
        </BodyBaselineSemibold>
      </Frame>
      <AnimatedBodyCells
        levels={levels}
        direction={direction}
        currentIndex={currentIndex}
        showControls={showControls}
        observableBehaviours={observableBehaviours}
        paddingBottom={0}
      >
        {({ observableBehaviour }) => (
          <BodyBaseline color={Color.Neutral700}>
            {observableBehaviour.description}
          </BodyBaseline>
        )}
      </AnimatedBodyCells>
      <AnimatedBodyCells
        levels={levels}
        direction={direction}
        capability={capability}
        currentIndex={currentIndex}
        showControls={showControls}
        observableBehaviours={observableBehaviours}
      >
        {({ observableBehaviour }) => (
          <CapabilitiesBadges
            observableBehaviour={observableBehaviour}
            userBadges={userBadges}
            isCurrentUserDirectManager={isCurrentUserDirectManager}
            userId={userId}
            mutateUser={mutateUser}
            renderMetRequirement={renderMetRequirement}
            metObservableBehaviour={metObservableBehaviours.find(
              (metObservableBehaviour) =>
                metObservableBehaviour.observableBehaviourId ==
                Number.parseInt(observableBehaviour.id),
            )}
          />
        )}
      </AnimatedBodyCells>
    </Grid>
  );
}

export default function CapabilitiesTableBody({
  skill,
  levels,
  indexValues,
  showControls,
  oneColumn,
  isLast = false,
  fromRoles = false,
  userBadges,
  isCurrentUserDirectManager,
  renderMetRequirement,
  metObservableBehaviours,
  userId,
  mutateUser,
}) {
  return (
    <Frame p={[0, 0, isLast && fromRoles ? 0 : 4, 0]}>
      <Frame
        role="table"
        className={`border border-neutral-300 rounded w-${oneColumn ? 2 : 3}/3`}
      >
        {skill.capabilities.map((capability, index) => (
          <CapabilyTableRow
            key={`capability-row-${capability.id}`}
            capability={capability}
            levels={levels}
            isEven={index % 2 === 1}
            indexValues={indexValues}
            showControls={showControls}
            oneColumn={oneColumn}
            userBadges={userBadges}
            isCurrentUserDirectManager={isCurrentUserDirectManager}
            renderMetRequirement={renderMetRequirement}
            metObservableBehaviours={metObservableBehaviours}
            userId={userId}
            mutateUser={mutateUser}
          />
        ))}
      </Frame>
    </Frame>
  );
}
