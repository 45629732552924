// Modules
import { values } from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// Constants
import ActionTypes from "budget/constants/ActionTypes";
// Actions
import * as API from "core-redux/actions/api";
import updateProjectAllocations from "budget/actions/updateProjectAllocations";
// Components
import Sheet from "./Sheet";

class SheetContainer extends PureComponent {
  static propTypes = {
    fetchPlaceholders: PropTypes.func.isRequired,
    fetchProjectAllocations: PropTypes.func.isRequired,
    fetchProjectRoles: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    generateGrid: PropTypes.func.isRequired,
    grid: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ),
      ).isRequired,
      periods: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.bootstrapData();
  }

  bootstrapData() {
    const {
      fetchPlaceholders,
      fetchProjectAllocations,
      fetchProjectRoles,
      fetchUsers,
    } = this.props;

    Promise.all([
      fetchPlaceholders(),
      fetchProjectAllocations(),
      fetchProjectRoles(),
      fetchUsers(),
    ]).then(() => this.setState({ isLoading: false }));
  }

  render() {
    const {
      generateGrid,
      grid,
      handleCellsChanged,
      placeholders,
      projectRoles,
      users,
    } = this.props;
    const { isLoading } = this.state;

    return (
      <Sheet
        isLoading={isLoading}
        generateGrid={generateGrid}
        grid={grid}
        handleCellsChanged={handleCellsChanged}
        placeholders={placeholders}
        projectRoles={projectRoles}
        users={users}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { grid } = state;
  const placeholders = values(state.entities.placeholders.byId);
  const projectRoles = values(state.entities.projectRoles.byId);
  const users = values(state.entities.users.byId);

  return {
    grid,
    placeholders,
    projectRoles,
    users,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPlaceholders: async () =>
    await dispatch(API.list("placeholders", { includeArchived: true })),

  fetchProjectAllocations: async () =>
    await dispatch(
      API.list("projectAllocations", { projectId: ownProps.project.id }),
    ),

  fetchProjectRoles: async () => await dispatch(API.list("projectRoles")),

  fetchUsers: async () =>
    await dispatch(API.list("users", { includeArchived: true })),

  generateGrid: () =>
    dispatch((dispatch, getState) => {
      const { project } = ownProps;

      return dispatch({
        projectId: project.id,
        entities: getState().entities,
        type: ActionTypes.GENERATE_GRID,
      });
    }),

  handleCellsChanged: (updatedCols, newCols) => {
    dispatch(updateProjectAllocations(updatedCols, newCols));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SheetContainer);
