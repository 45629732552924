/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Frame, Stack } from "@ableco/baseline";
import { SSRSuspense, useResource } from "coreql";
import { noop, uniqueId } from "lodash";
import AutosaveTextInput, {
  LoadingStateIcon,
} from "../data-entry/autosave-text-input";
import { TitleSemibold, Title } from "../design-system/typography-components";
import { MarkdownEditor } from "../markdown/markdown-editor";
import Achievements from "./achievements-section";
import NextWeekPlans from "./plans-section";
import AchievedPlans from "./achieved-plans-section";

export function useAccomplishments(weeklyReportId) {
  return useResource(
    "weekly-reports",
    weeklyReportId,
    {
      included: ["user", "user.manager"],
      fields: {
        users: [
          "id",
          "fullName",
          "avatarUrl",
          "managerId",
          "manager",
          "preferredName",
        ],
        "weekly-reports": ["achievedPlans", "achievements", "plans", "user"],
      },
    },
    "denormalized",
  );
}

function Accomplishments({ weeklyReportId, setSavedAt }) {
  function onUpdate() {
    setSavedAt(new Date());
  }

  return (
    <Stack as="section" className="mt-12" space={6}>
      <Title as="h2">Accomplishments</Title>
      <Frame as="section">
        <TitleSemibold as="h3" className="mb-5">
          Mark things you accomplished this week
        </TitleSemibold>
        <SSRSuspense fallback={"Loading achieved plans..."}>
          <AchievedPlans weeklyReportId={weeklyReportId} onUpdate={onUpdate} />
        </SSRSuspense>
        <Achievements weeklyReportId={weeklyReportId} onUpdate={onUpdate} />
      </Frame>
      <NextWeekPlans weeklyReportId={weeklyReportId} onUpdate={onUpdate} />
    </Stack>
  );
}

/**
 * Returns an array of answers that can be used to render a stable list of inputs,
 * updates the internal list when passed dataset changes.
 * @param {Array<any>} dataSet
 * @param {object} config
 * @param {string} [config.identifier="id"]
 */
export function useAnswersGroup(dataSet = [], { identifier = "id" } = {}) {
  const addUniqueKey = useCallback(
    (item) => ({ ...item, __key: uniqueId("groupanswer:") }),
    [],
  );

  const { current: answerIds } = useRef(new Set());
  const [answers, setAnswers] = useState([addUniqueKey({})]);

  // update answers if passed dataset changes
  useEffect(() => {
    const hasStaleAnswers = dataSet.some(
      (freshAnswer) => !answerIds.has(String(freshAnswer[identifier])),
    );
    if (!hasStaleAnswers && dataSet.length === answerIds.size) return;

    answerIds.clear();
    setAnswers(
      dataSet
        .map((item) => {
          answerIds.add(String(item[identifier]));
          return addUniqueKey(item);
        })
        .concat(addUniqueKey({})),
    );
  }, [dataSet, addUniqueKey, answerIds, identifier]);

  function addAnswer(newAnswer = {}) {
    setAnswers((answers) => answers.concat(addUniqueKey(newAnswer)));
    if (newAnswer[identifier]) answerIds.add(String(newAnswer[identifier]));
  }

  function updateAnswer(staleAnswer, updateFn) {
    function updateMatching(answer) {
      if (answer.__key !== staleAnswer.__key) return answer;

      const updatedAnswer = updateFn(answer);

      answerIds.delete(String(answer[identifier]));
      if (updatedAnswer[identifier])
        answerIds.add(String(updatedAnswer[identifier]));

      return updatedAnswer;
    }
    setAnswers((answers) => answers.map(updateMatching));
  }

  function removeAnswer(answerToRemove) {
    function selectNotMatching(answer) {
      return answer.__key !== answerToRemove.__key;
    }

    answerIds.delete(String(answerToRemove[identifier]));
    setAnswers((answers) => answers.filter(selectNotMatching));
  }

  return { answers, addAnswer, updateAnswer, removeAnswer };
}

export function Answer({
  id,
  answerText,
  autoFocus,
  autoSaveProps,
  handleBlur = noop,
}) {
  return (
    <AutosaveTextInput
      value={answerText}
      resourceId={id}
      getSaveStatus={LoadingStateIcon}
      {...autoSaveProps}
    >
      {(text, { onChange }, statusText) => (
        <Stack className="relative">
          <MarkdownEditor
            className="text-sm"
            placeholder="Add answer"
            text={text}
            onChange={onChange}
            autoFocus={autoFocus}
            onBlur={handleBlur}
          />
          <Frame className="absolute left-100 bottom-0 mb-2 ml-2">
            {statusText}
          </Frame>
        </Stack>
      )}
    </AutosaveTextInput>
  );
}

export default Accomplishments;
