import React, { useState } from "react";
import styled from "styled-components";
import Datepicker from "react-datepicker";
import { isValid, format, parseISO } from "date-fns";
import hasFieldErrors from "../../utils/has-field-errors";
// Styles
import "react-datepicker/dist/react-datepicker.css";

const DatepickerElement = styled.div`
  width: 150px;
  margin-right: 20px;

  input {
    font-size: 14px;
    color: #3d5186;
    font-family: "ProximaNova Regular", sans-serif;
  }

  ${({ hasErrors }) =>
    hasErrors &&
    `
      & input {
        border-color: red;
      }
    `}
`;

const Error = styled.div`
  font-size: 14px;
  color: red;
`;

function DateField({
  placeholder = "Select a date",
  minDate,
  maxDate,
  field: { name, value, onChange },
  form: { touched = {}, errors = {} } = {},
}) {
  const [startDate, setStartDate] = useState(value);
  const fieldErrors = hasFieldErrors(name, touched, errors);

  function handleDateChange(date) {
    if (isValid(date)) {
      const formatted = format(date, "yyyy-MM-dd");
      onChange({ target: { name, value: parseISO(formatted) } }); // strips time from date
    }
    setStartDate(date);
  }

  return (
    <DatepickerElement hasErrors={fieldErrors}>
      <Datepicker
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholder}
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="MM/dd/yyyy"
      />
      {fieldErrors && <Error>{fieldErrors}</Error>}
    </DatepickerElement>
  );
}

export default DateField;
