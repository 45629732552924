import { Color } from "@ableco/baseline";
import React from "react";
import { BodySmall } from "../../../components/design-system/typography-components";

export function ValueText({ children, ...props }) {
  return (
    <BodySmall color={Color.Neutral700} {...props}>
      {children}
    </BodySmall>
  );
}
