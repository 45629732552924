import React from "react";
import CoreLink from "../core-link";
import { Inline, Text, Color, Touchable } from "@ableco/baseline";
import { CaretLeftOutline } from "@baseline/icons";
import { useHistory } from "react-router";

export default function BackButton({ text, href, p, fallbackHref }) {
  const history = useHistory();

  function BackButtonContent() {
    return (
      <Inline alignment="center" space={2} p={p}>
        <Text color={Color.Neutral600}>
          <CaretLeftOutline className="w-4 h-4" color={Color.Neutral200} />
        </Text>
        <Text color={Color.Neutral600} size="sm">
          {text}
        </Text>
      </Inline>
    );
  }

  if (href) {
    return (
      <CoreLink
        href={href}
        className="cursor-pointer z-10 hover:no-underline"
        aria-label={text}
      >
        <BackButtonContent />
      </CoreLink>
    );
  }
  if (history.length <= 1 && fallbackHref) {
    return (
      <CoreLink
        href={fallbackHref}
        className="cursor-pointer z-10 hover:no-underline"
        aria-label={text}
      >
        <BackButtonContent />
      </CoreLink>
    );
  }
  return (
    <Touchable onClick={() => history.goBack()} aria-label={text}>
      <BackButtonContent />
    </Touchable>
  );
}
