// Modules
import React from "react";
import clsx from "clsx";
// Styles
import Styles from "utilization/components/Table/Table.module.scss";
import { LabelText } from "./LabelText";

function LabelRows({ name, detailRows, toggleDetailVisibility }) {
  const nameContainerProps = {
    className: clsx(Styles.column, Styles.clickable),
    onClick: toggleDetailVisibility,
  };

  return (
    <>
      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div {...nameContainerProps}>
          <LabelText>{name}</LabelText>
        </div>
        <div className={Styles.spacer} />
      </div>

      <DetailRows rows={detailRows} />
    </>
  );
}

function DetailRows({ rows }) {
  return rows.map((row) => (
    <div key={row.id} className={Styles.row}>
      <div className={Styles.spacer} />
      <div className={Styles.spacer} />
      <div className={Styles.spacer} />
      <div className={Styles.spacer} />
      <div className={Styles.column}>
        <LabelText>{row.name}</LabelText>
      </div>
    </div>
  ));
}

export default LabelRows;
