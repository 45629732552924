// Modules
import React from "react";
import PropTypes from "prop-types";
// Constants
import AppTypes from "../../../core-redux/constants/AppTypes";
// Components
import Rows from "../../../utilization/components/Rows";
// Styles
import Styles from "../../../utilization/components/Table/Table.module.scss";
import { BodySmall } from "../../../components/design-system/typography-components";
import { Color } from "@ableco/baseline";

SummaryValueRows.propTypes = {
  periods: PropTypes.arrayOf(AppTypes.period).isRequired,
  products: PropTypes.arrayOf(AppTypes.product).isRequired,
};
function SummaryValueRows({ periods, products }) {
  return (
    <>
      <EmptyRow />
      <EmptyRow />
      <Rows.Values filter="revenue.estimated" products={products} />
      <Rows.Values filter="revenue.actual" products={products} />
      <Rows.Values filter="revenue.dev_est_act" products={products} />

      <EmptyRow />
      <TodoRow periods={periods} />
      <Rows.Values filter="headcount.budgeted" products={products} />
      <Rows.Values filter="headcount.estimated" products={products} />
      <Rows.Values filter="headcount.actual" products={products} />
      <TodoRow periods={periods} />
      <TodoRow periods={periods} />
      <TodoRow periods={periods} />
      <Rows.Values filter="headcount.dev_bud_est" products={products} />
      <Rows.Values filter="headcount.dev_bud_act" products={products} />
      <Rows.Values filter="headcount.dev_est_act" products={products} />

      <EmptyRow />
      <TodoRow periods={periods} />
      <Rows.Values filter="hours.budgeted" products={products} />
      <Rows.Values filter="hours.estimated" products={products} />
      <Rows.Values filter="hours.actual" products={products} />
      <TodoRow periods={periods} />
      <TodoRow periods={periods} />
      <TodoRow periods={periods} />
      <Rows.Values filter="hours.dev_bud_est" products={products} />
      <Rows.Values filter="hours.dev_bud_act" products={products} />
      <Rows.Values filter="hours.dev_est_act" products={products} />

      <EmptyRow />
      <TodoRow periods={periods} />
      <Rows.Values filter="cost.budgeted" products={products} />
      <Rows.Values filter="cost.estimated" products={products} />
      <Rows.Values filter="cost.actual" products={products} />
      <TodoRow periods={periods} />
      <TodoRow periods={periods} />
      <TodoRow periods={periods} />
      <Rows.Values filter="cost.dev_bud_est" products={products} />
      <Rows.Values filter="cost.dev_bud_act" products={products} />
      <Rows.Values filter="cost.dev_est_act" products={products} />

      <EmptyRow />
      <Rows.Values filter="gross.estimated" products={products} />
      <Rows.Values filter="gross.actual" products={products} />
      <Rows.Values filter="gross.dev_est_act" products={products} />
    </>
  );
}

function EmptyRow() {
  return <div className={Styles.row} />;
}

function TodoRow({ periods }) {
  return (
    <div className={Styles.row}>
      {periods.map((period, i) => (
        <div key={i} className={Styles.columnTODO}>
          <BodySmall color={Color.Neutral500}>TODO</BodySmall>
        </div>
      ))}
    </div>
  );
}

export default SummaryValueRows;
