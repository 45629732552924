import React from "react";
import cn from "clsx";

import {
  Color,
  FlexAlignment,
  FlexDistribution,
  Frame,
  Inline,
  noop,
} from "@ableco/baseline";

import CoreLink from "./core-link";
import Menu from "./layout/menu";
import useMachine from "../hooks/use-machine";
import { BodyBaseline } from "./design-system/typography-components";
import { useLocation } from "react-router";
import { isFunction } from "formik";

export function NavItem({
  href,
  as = href,
  label,
  isActive,
  prepare = noop,
  children,
  icon,
  iconPosition = "left",
  dataTestId,
  disabled,
  isExternalLink,
}) {
  const [{ status }, dispatch] = useMachine(
    {
      menuOpen: () => ({ status: "menuOpen" }),
      willClose: () => ({ status: "willClose" }),
      closed: () => ({ status: "closed" }),
    },
    { status: "closed" },
  );

  const location = useLocation();

  let active = isFunction(isActive) ? !!isActive(location) : isActive;

  React.useEffect(() => {
    if (status === "willClose") {
      const timer = setTimeout(() => dispatch({ type: "closed" }), 100);
      return () => clearTimeout(timer);
    }
  });

  return (
    <Frame
      className="mr-4 relative"
      data-testid={dataTestId}
      onMouseEnter={() => {
        if (children) dispatch({ type: "menuOpen" });
      }}
      onMouseLeave={() => {
        if (children) dispatch({ type: "willClose" });
      }}
    >
      <CoreLink
        href={href}
        as={as}
        prepare={prepare}
        color={
          active
            ? [Color.Primary, Color.PrimaryLight]
            : [Color.Neutral700, Color.Neutral800]
        }
        className="transition-colors duration-300 hover:no-underline group"
        role="link"
        disabled={disabled}
        isExternalLink={isExternalLink}
      >
        <Inline
          className={cn(
            "h-12 w-full relative select-none border-transparent border-b-2 cursor-pointer transition-colors duration-300 ease-in-out",
            active &&
              "border-primary-base group-hover:border-primary-light text-primary-base group-hover:text-primary-light",
            !active && "text-neutral-700 group-hover:text-neutral-800",
          )}
          distribution={FlexDistribution.Start}
          space={2}
          p={[0, 4]}
        >
          {iconPosition === "left" && icon && (
            <Inline
              alignment={FlexAlignment.Center}
              distribution={FlexDistribution.Center}
              className="h-4 w-4"
            >
              {icon}
            </Inline>
          )}
          <BodyBaseline
            className={cn(
              "transition-colors duration-300 ease-in-out",
              !active && "text-neutral-700 group-hover:text-neutral-800",
              active && "text-primary-base group-hover:text-primary-light",
            )}
          >
            {label}
          </BodyBaseline>
          {iconPosition === "right" && icon && (
            <Inline
              alignment={FlexAlignment.Center}
              distribution={FlexDistribution.Center}
              className="h-4 w-4 transition-colors duration-300 ease-in-out"
            >
              {icon}
            </Inline>
          )}
        </Inline>
      </CoreLink>
      {children && (status === "willClose" || status === "menuOpen") ? (
        <Menu
          className="absolute z-10"
          style={{
            left: 0,
            top: "calc(100% + 4px)",
          }}
        >
          {children}
        </Menu>
      ) : null}
    </Frame>
  );
}
