import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Accordion from "../../accordion";
import LevelBadgesTable from "./level-badges-table";

export default function OtherBadges({
  otherBadges,
  assessmentsByBadgeId,
  userId,
  isDirectReport,
  isDirectReportOrSelf,
}) {
  const [badges, setBadges] = useState(otherBadges);

  useEffect(() => {
    setBadges(otherBadges);
  }, [otherBadges]);

  if (isEmpty(badges)) return null;
  return (
    <Accordion
      state="none"
      variant="small"
      title="Other Badges"
      label="Other badges"
    >
      <LevelBadgesTable
        badges={badges}
        badgeAssessments={assessmentsByBadgeId}
        userId={userId}
        isDirectReport={isDirectReport}
        isDirectReportOrSelf={isDirectReportOrSelf}
        dataTestId="Other Badges"
        onRemove={(id) =>
          setBadges((badges) =>
            badges.filter(({ id: badgeId }) => badgeId != id),
          )
        }
        allowRemove
      />
    </Accordion>
  );
}
