import * as React from "react";
import { ErrorBoundary, SSRSuspense } from "coreql";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { SWRConfig } from "swr";
import useCurrentUser, { FallbackLogout } from "../hooks/use-current-user";
import Layout, { Main } from "./layout/layout";
import NavigationHeader from "./navigation/navigation-header";
import Sidebar from "./navigation/sidebar";
import {
  DashboardHome as Dashboard,
  PeopleDirectory,
  Products,
  Objectives,
  KeyResults,
  ObjectiveDetail,
  WeeklyReportForm,
  UserResponsibilities,
  UserInformation,
  FeedbackMessagesInbox,
  TeamsJournalHistory,
  MyJournalHistory,
  WeeklyQuestions,
  WeeklyQuestion,
  HighlightsPage,
  TeamHighlightsPage,
  AllocationsForm,
  AllocationsTimeline,
  EditAllocation,
  DesignSystem,
  Roles,
  OneOnOne,
  OneOnOneHistory,
  OneOnOneGroup,
  Role,
  FeedbackResponse,
  UserCapabilities,
  WeeklyReportReadOnly,
  Revenue,
  Utilization,
  Budgets,
  BudgetPage,
  ReportsPage,
  ReportPage,
  Tokens,
  UserBadges,
  WeeklyQuestionsSchedule,
} from "./pages";
import checkPermission from "../utils/check-permission";
import NewWeeklyReport from "./weekly-report/new-weekly-report";
import { WeeklyReportFromFallback } from "./weekly-report/weekly-report-form";
import { ToastProvider } from "@ableco/baseline";
import { CoreToastContainer } from "./toast/toast";
import BadgeDetailPage from "./users/badges/badges-detail";
import BadgeListPage from "./badge_list";

function handleUnhandledError(error) {
  console.error(error);
}

function UnhandledErrorFallback() {
  return (
    <>
      Something really weird happened while loading Core, contact the team in{" "}
      <a href="https://app.slack.com/client/T027P7MLU/CGS04KJ82">#core</a>!
    </>
  );
}

export default function CoreApp({ children }) {
  const { data: currentUser } = useCurrentUser();
  return (
    <Router>
      <ErrorBoundary
        onError={handleUnhandledError}
        fallback={UnhandledErrorFallback}
      >
        <SWRConfig value={{ errorRetryCount: 5 }}>
          <ErrorBoundary fallback={FallbackLogout}>
            <Layout>
              <ToastProvider>
                <CoreToastContainer />
                <Sidebar />
                <NavigationHeader />
                <Main>
                  {children}
                  <ErrorBoundary fallback={UnhandledErrorFallback}>
                    <SSRSuspense>
                      <Switch>
                        <Route path="/dashboard">
                          <Dashboard />
                        </Route>
                        <Route path="/weekly_reports">
                          <Switch>
                            <Route path="/weekly_reports/my_journal">
                              <MyJournalHistory />
                            </Route>
                            <Route path="/weekly_reports/teams_journal">
                              <TeamsJournalHistory />
                            </Route>
                            <Route path="/weekly_reports/new">
                              <NewWeeklyReport />
                            </Route>
                          </Switch>
                        </Route>
                        <Route path="/weekly_questions">
                          <Switch>
                            <Route path="/weekly_questions/scheduled">
                              <WeeklyQuestionsSchedule />
                            </Route>
                            <Route path="/weekly_questions/:questionId">
                              <WeeklyQuestion />
                            </Route>
                            <Route exact path="/weekly_questions">
                              <WeeklyQuestions />
                            </Route>
                          </Switch>
                        </Route>
                        <Route path="/v2_weekly_reports/:weeklyReportId/edit">
                          <SSRSuspense fallback={<WeeklyReportFromFallback />}>
                            <WeeklyReportForm />
                          </SSRSuspense>
                        </Route>
                        <Route path="/v2_weekly_reports/:weeklyReportId">
                          <SSRSuspense fallback={<WeeklyReportFromFallback />}>
                            <WeeklyReportReadOnly />
                          </SSRSuspense>
                        </Route>
                        <Route path="/1-on-1/:user1/:user2">
                          <Switch>
                            <Route path="/1-on-1/:user1/:user2/history">
                              <OneOnOneHistory />
                            </Route>
                            <Route>
                              <OneOnOne />
                            </Route>
                          </Switch>
                        </Route>
                        <Route path="/1-on-1/:id">
                          <OneOnOneGroup />
                        </Route>
                        <Route path="/my-core">
                          <Switch>
                            <Route path="/my-core/highlights">
                              <HighlightsPage />
                            </Route>
                          </Switch>
                        </Route>
                        <Route path="/my-team">
                          <Switch>
                            <Route path="/my-team/:id/highlights">
                              <TeamHighlightsPage />
                            </Route>
                          </Switch>
                        </Route>
                        <Route exact path="/products">
                          <Products />
                        </Route>
                        <Route path="/products/:productId/projects/:projectId/budget">
                          <BudgetPage />
                        </Route>
                        <Route path="/staffing">
                          <Switch>
                            <Route path="/staffing/new">
                              <AllocationsForm />
                            </Route>
                            <Route path="/staffing/:id/edit">
                              {({ match }) => (
                                <EditAllocation
                                  allocationId={match.params.id}
                                />
                              )}
                            </Route>
                            <Route>
                              <AllocationsTimeline
                                currentDate={new Date()}
                                permission={
                                  checkPermission(
                                    currentUser,
                                    "manage_product_assignments",
                                  ) ||
                                  checkPermission(
                                    currentUser.role,
                                    "manage_product_assignments",
                                  )
                                }
                              />
                            </Route>
                          </Switch>
                        </Route>
                        <Route path={["/users", "/people"]}>
                          <Switch>
                            <Route path="/users/:id">
                              <Switch>
                                <Route path="/users/:id/capabilities">
                                  <UserCapabilities />
                                </Route>
                                <Route path="/users/:id/badges/:badgeId">
                                  <BadgeDetailPage />
                                </Route>
                                <Route path="/users/:id/badges">
                                  <UserBadges />
                                </Route>
                                <Route path="/users/:id/information">
                                  <UserInformation />
                                </Route>
                                <Route path="/users/:userId/feedback_messages">
                                  <FeedbackMessagesInbox />
                                </Route>
                                <Route path="/users/:userId/tokens">
                                  <Tokens />
                                </Route>
                                <Route>
                                  <UserResponsibilities />
                                </Route>
                              </Switch>
                            </Route>
                            <Route>
                              <PeopleDirectory />
                            </Route>
                          </Switch>
                        </Route>
                        <Route path="/objectives">
                          <Switch>
                            <Route path="/objectives/key-results">
                              <KeyResults />
                            </Route>
                            <Route path="/objectives/:id">
                              {({ match }) => (
                                <ObjectiveDetail
                                  objectiveId={match.params.id}
                                />
                              )}
                            </Route>
                            <Route>
                              <Objectives />
                            </Route>
                          </Switch>
                        </Route>
                        <Route path="/design">
                          <DesignSystem />
                        </Route>
                        <Route path="/badges">
                          <Switch>
                            <Route path="/badges/:badgeId">
                              <BadgeDetailPage />
                            </Route>
                            <Route>
                              <BadgeListPage />
                            </Route>
                          </Switch>
                        </Route>
                        <Route path="/roles">
                          <Switch>
                            <Route path="/roles/:type/:title-:id">
                              <Role />
                            </Route>
                            <Route>
                              <Roles />
                            </Route>
                          </Switch>
                        </Route>
                        <Route path="/feedback/response/:id">
                          {({ match }) => (
                            <FeedbackResponse
                              feedbackRequestId={match.params.id}
                            />
                          )}
                        </Route>
                        <Route path="/reports">
                          <Switch>
                            <Route path="/reports" exact>
                              <ReportsPage />
                            </Route>
                            <Route path="/reports/revenue">
                              <Revenue />
                            </Route>
                            <Route path="/reports/utilization">
                              <Utilization />
                            </Route>
                            <Route path="/reports/budgets">
                              <SSRSuspense>
                                <Budgets />
                              </SSRSuspense>
                            </Route>
                            <Route path="/reports/:reportId">
                              <ReportPage />
                            </Route>
                          </Switch>
                        </Route>
                      </Switch>
                    </SSRSuspense>
                  </ErrorBoundary>
                </Main>
              </ToastProvider>
            </Layout>
          </ErrorBoundary>
        </SWRConfig>
      </ErrorBoundary>
    </Router>
  );
}
