// Constants
const DEFAULT_HOST = location.origin;
const DEFAULT_BASE_PATH = "/api";

// Resources
import Placeholder from "./resources/Placeholder";
import Product from "./resources/Product";
import ProductAllocation from "./resources/ProductAllocation";
import ProductAssignment from "./resources/ProductAssignment";
import ProductInvestment from "./resources/ProductInvestment";
import ProductRevenue from "./resources/ProductRevenue";
import Project from "./resources/Project";
import ProjectAllocation from "./resources/ProjectAllocation";
import ProjectRole from "./resources/ProjectRole";
import Role from "./resources/Role";
import User from "./resources/User";

const RESOURCES = {
  placeholders: Placeholder,
  productAllocations: ProductAllocation,
  productAssignments: ProductAssignment,
  productInvestments: ProductInvestment,
  productRevenues: ProductRevenue,
  products: Product,
  projectAllocations: ProjectAllocation,
  projectRoles: ProjectRole,
  projects: Project,
  roles: Role,
  users: User,
};

function Core(token, options = {}) {
  this._api = {
    basePath: DEFAULT_BASE_PATH,
    token,
    ...Object.assign({}, this._defaultOptions(), options),
  };

  this._initResources();
}

Core.prototype = {
  _defaultOptions: function () {
    return {
      host: DEFAULT_HOST,
    };
  },

  _initResources: function () {
    for (const name in RESOURCES) {
      this[name] = new RESOURCES[name](this._api);
    }
  },
};

export default Core;
