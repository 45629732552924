import React from "react";

export default function (props) {
  return (
    <svg width="14px" height="14px" viewBox="0 0 14 14" {...props}>
      <defs>
        <path
          d="M15 3.75H5c-.688 0-1.244.563-1.244 1.25L3.75 16.25l2.5-2.5H15c.688 0 1.25-.563 1.25-1.25V5c0-.688-.563-1.25-1.25-1.25zM6.25 8.125h7.5v1.25h-7.5v-1.25zm5 3.125h-5V10h5v1.25zm2.5-3.75h-7.5V6.25h7.5V7.5z"
          id={`a-comment${props.id}`}
        />
      </defs>
      <g
        transform="translate(-1133 -584) translate(509 535) translate(621 46)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <mask id={`b-comment${props.id}`} fill="#fff">
          <use xlinkHref={`#a-comment${props.id}`} />
        </mask>
        <use
          fill="#000"
          fillRule="nonzero"
          xlinkHref={`#a-comment${props.id}`}
        />
        <g mask={`url(#b-comment${props.id})`} fill="#C2C9D4">
          <path d="M0 0H20V20H0z" />
        </g>
      </g>
    </svg>
  );
}
