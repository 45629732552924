import {
  Center,
  Color,
  Frame,
  Inline,
  Skeleton,
  Stack,
  Text,
} from "@ableco/baseline";
import { SSRSuspense } from "coreql";
import React from "react";
import { Main } from "../layout/layout";
import UserLayout, {
  Fallback as UserLayoutFallback,
} from "../layout/user-layout";
import { ArrowLeft, FeedbackReceived, Users } from "@baseline/icons";
import { NavItem as Tab } from "../tab.js";

export function BulletReply() {
  return (
    <Center
      className="w-4 h-4 rounded-full mx-1 flex-shrink-0 absolute -ml-1 -mt-1"
      bg={Color.Neutral500}
      border={Color.Neutral100}
    >
      <Text color={Color.White}>
        <ArrowLeft className="w-3 h-3" />
      </Text>
    </Center>
  );
}

function getNavItems(userId) {
  const items = [];

  items.push(
    {
      href: `/users/${userId}/feedback_messages/received`,
      label: "Received",
      prefetch: false,
      icon: <FeedbackReceived className="w-4 h-4" />,
    },
    {
      href: `/users/${userId}/feedback_messages/sent`,
      label: "Sent",
      prefetch: false,
      icon: <Users className="w-4 h-4" />,
    },
  );

  return items;
}

export default function FeedbackMessagesLayout({
  children,
  userId,
  activeLabelTab,
}) {
  const navItems = getNavItems(userId);

  const fallback = (
    <Frame className="px-8 w-full">
      <Frame className="border-b border-neutral-300">
        <Skeleton
          height={32}
          width={200}
          color="neutral-400"
          className="mb-4"
          alt="Feedback Messages"
        />
        <Inline as="nav" alignment="start">
          {[...Array.from({ length: 2 })].map((_, i) => (
            <Skeleton
              key={i}
              height={16}
              width={93}
              className="mx-4 my-4"
              alt="Journals Link"
              color={Color.Neutral400}
            />
          ))}
        </Inline>
      </Frame>
    </Frame>
  );

  return (
    <Main>
      <SSRSuspense
        fallback={
          <>
            <UserLayoutFallback />
            {fallback}
          </>
        }
      >
        <UserLayout id={userId}>
          <Stack className="px-8 w-full">
            <Text as="h4" size="2xl" weight="semibold">
              Feedback Messages
            </Text>
            <Inline
              as="nav"
              alignment="start"
              className="w-full border-b border-neutral-300 mt-2"
            >
              {navItems.map((item) => (
                <Tab
                  key={item.href}
                  {...item}
                  isActive={item.label.toLowerCase() === activeLabelTab}
                />
              ))}
            </Inline>
            {children}
          </Stack>
        </UserLayout>
      </SSRSuspense>
    </Main>
  );
}
