import { Frame, Inline, Stack, Text, Color } from "@ableco/baseline";
import { Check, CheckCircleOutline } from "@baseline/icons";
import { api, denormalize, useCollection } from "coreql";
import { format } from "date-fns";
import { compact, isEmpty, noop } from "lodash";
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useQueuedCallback } from "../../../hooks/use-queued-callback";
import { TertiaryButton } from "../../buttons/buttons";
import AutosaveTextInput, {
  LoadingStateIcon,
} from "../../data-entry/autosave-text-input";
import Select from "../../data-entry/select";
import { TitleSemibold } from "../../design-system/typography-components";
import { MarkdownEditor } from "../../markdown/markdown-editor";
import { MarkdownViewer } from "../../markdown/markdown-viewer";
import Tag from "../../tag";
import { AwardModal } from "./award-badge-modal";

const NOTES_PLACEHOLDER =
  "Include updates on the progress or evidence for the achievements";

export function getTogglerOptions({ manager = false, remove = false }) {
  const defaultOpts = [
    { label: "Pending", value: "pending" },
    { label: "In Progress", value: "in_progress" },
  ];

  const managerOptions = [
    { label: "Waived", value: "waived" },
    { label: "Awarded", value: "awarded" },
  ];

  const removeOpt = { label: "Remove", value: "remove" };

  return compact(
    defaultOpts.concat(manager && managerOptions, remove && removeOpt),
  );
}

export function AwardBadgeStatus({ status, date, fromBadgeList = false }) {
  const passDate = format(new Date(date), "d-MMM-yyyy");
  if (status === "awarded")
    return (
      <Tag
        type="default"
        text={fromBadgeList ? passDate : `Awarded: ${passDate}`}
        LeftIcon={() => (
          <Text color={Color.Success}>
            <CheckCircleOutline className="w-4 h-4" />
          </Text>
        )}
      />
    );

  /* Waived */
  return (
    <Tag
      LeftIcon={Check}
      text={fromBadgeList ? "Waived" : `Waived: ${passDate}`}
    />
  );
}

export function BadgeStatusSelect({ badgeId, onChange = noop, ...props }) {
  /* Modal control */
  const [modalState, setModalState] = useState({
    visible: false,
    newStatus: "awarded",
    onClose: () => setModalState({ visible: false }),
  });

  function handleStatusChange(newStatus) {
    if (newStatus == "awarded" || newStatus == "waived") {
      setModalState({
        visible: true,
        newStatus,
        /* after modal closes... */
        onClose: ({ success, date }) => {
          if (success) {
            onChange({ status: newStatus, date });
          }
          setModalState({ visible: false });
        },
      });
    } else {
      onChange({ status: newStatus });
    }
  }
  return (
    <>
      {modalState.visible && (
        <AwardModal
          badgeId={badgeId}
          newStatus={modalState.newStatus}
          onClose={modalState.onClose}
        />
      )}
      <Select
        aria-label="Select status"
        onChange={handleStatusChange}
        {...props}
      />
    </>
  );
}

export function BadgeStatus({
  badgeId,
  userId,
  editable,
  showManagerOptions = false,
}) {
  const [showEditor, toggleEditor] = useReducer((state) => !state, false);

  const {
    data: [badgeStatus],
    mutate,
  } = useCollection(
    "user-badges",
    {
      filters: {
        badge: badgeId,
        user: userId,
      },
    },
    "denormalized",
  );

  const { data: trackerBadges } = useCollection(
    "badges",
    {
      fields: { badges: ["id"] },
      filters: {
        id: badgeId,
        for_user_tracker: userId,
      },
    },
    "denormalized",
  );

  const isTrackerBadge = trackerBadges.length > 0;

  const [notes, setNotes] = useState(badgeStatus?.notes ?? "");
  const [status, setStatus] = useState(badgeStatus?.status ?? "pending");
  const editorRef = useRef(null);

  /** Update after refetch  */
  useEffect(() => {
    if (badgeStatus) {
      setNotes(badgeStatus?.notes);
      setStatus(badgeStatus?.status);
    }
  }, [badgeStatus]);

  /* Focus editor on show */
  useEffect(() => {
    if (showEditor && editorRef.current !== null) {
      /** @type {HTMLTextAreaElement} */
      const textarea = editorRef.current;
      textarea.focus();

      /* Set cursor at end */
      const len = textarea.value.length;
      textarea.setSelectionRange(len, len);
    }
  }, [showEditor]);

  /* Upsert badge status */
  const updateBadgeStatus = useQueuedCallback(async (statusUpdate) => {
    const response = await api.userBadges
      .find(badgeStatus?.id)
      .upsert(statusUpdate, {
        badgeId,
        userId,
      });

    await mutate(() => denormalize(response, "userBadges", true), false);
    return response.data.id;
  });

  async function updateBadgeNotes(id, notes) {
    const response = await api.userBadges.find(id).upsert(
      { notes, status },
      {
        badgeId,
        userId,
      },
    );
    await mutate(() => denormalize(response, "userBadges", true), false);
    return response.data.id;
  }

  const availableOptions = useMemo(
    () =>
      getTogglerOptions({
        manager: showManagerOptions,
        remove: !isTrackerBadge,
      }),
    [showManagerOptions, isTrackerBadge],
  );

  const waived = badgeStatus?.status === "waived";
  const awarded = badgeStatus?.status === "awarded";
  const assessmentComplete = waived || awarded;

  function handleStatusUpdate({ status: newStatus, date }) {
    if (newStatus === "remove") {
      api.userBadges
        .find(badgeStatus?.id)
        .destroy()
        .then(() => mutate(() => [], false));
    } else {
      setStatus(newStatus);
      updateBadgeStatus({ status: newStatus, awardedAt: date });
    }
  }

  if (!isTrackerBadge && isEmpty(badgeStatus)) {
    return (
      <Inline alignment="start">
        <TertiaryButton
          onClick={() => {
            updateBadgeStatus({ status: "pending" }).then((id) => {
              mutate(() => [{ id, notes: "", status: "pending" }], false);
            });
          }}
          text={`Add to ${showManagerOptions ? "my report" : "my"} badges`}
        />
      </Inline>
    );
  }

  return (
    <>
      <Stack space={2}>
        <TitleSemibold as="h3" color={Color.Neutral700}>
          Status
        </TitleSemibold>
        {assessmentComplete ? (
          <Inline space={2}>
            <AwardBadgeStatus
              status={badgeStatus?.status}
              date={badgeStatus?.awardedAt}
            />
          </Inline>
        ) : (
          <BadgeStatusSelect
            badgeId={badgeId}
            options={availableOptions}
            className="w-48"
            aria-label="Select status"
            disabled={!editable}
            value={status}
            onChange={handleStatusUpdate}
          />
        )}
      </Stack>
      {editable && (
        <Stack space={2}>
          <TitleSemibold as="h3" color={Color.Neutral700}>
            Notes
          </TitleSemibold>
          <Frame className="w-full text-neutral-700 transition-all duration-300 ease-in-out">
            {showEditor ? (
              <AutosaveTextInput
                resourceId={badgeStatus?.id}
                value={notes}
                onChange={setNotes}
                onSave={updateBadgeNotes}
                getSaveStatus={LoadingStateIcon}
              >
                {(text, saveProps, saveStatus) => (
                  <Frame className="relative">
                    <MarkdownEditor
                      type="multiline"
                      autosize={112}
                      text={text}
                      placeholder={NOTES_PLACEHOLDER}
                      onRef={(ref) => (editorRef.current = ref.current)}
                      onBlur={toggleEditor}
                      {...saveProps}
                    />
                    <Frame className="absolute left-100 bottom-0 mb-2 ml-2">
                      {saveStatus}
                    </Frame>
                  </Frame>
                )}
              </AutosaveTextInput>
            ) : (
              <MarkdownViewer
                className="hover:bg-neutral-200 hover:cursor-pointer p-2 -mx-2 transition-colors duration-300 ease-in-ou"
                value={notes || NOTES_PLACEHOLDER}
                onClick={toggleEditor}
              />
            )}
          </Frame>
        </Stack>
      )}
    </>
  );
}
