import {
  setDay,
  eachDayOfInterval,
  isMonday,
  isSunday,
  compareAsc,
} from "date-fns";

function getMondays(rangeStart, rangeEnd) {
  if (!rangeStart || !rangeEnd) return [];
  const [start, end] = [rangeStart, rangeEnd].sort(compareAsc);
  const days = eachDayOfInterval({ start, end });
  const firstDay = days[0];

  // Add a Monday at the begin just in case it don't be in the first week
  if (!isSunday(firstDay) && !isMonday(firstDay)) {
    days.unshift(setDay(start.setHours(0, 0, 0, 0), 1));
  }

  const mondays = days.filter((day) => isMonday(day));

  return mondays;
}

export default getMondays;
