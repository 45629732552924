import React, { useContext, useState } from "react";
import { isWithinInterval, parseISO } from "date-fns";
import styled, { keyframes } from "styled-components";

import TimelineContext from "./timeline-context";
import GenericTimeline from "./generic-timeline";

import BenchTimelineInfo from "./bench-timeline-info";
import { sortCriteriaGenerator } from "../../utils/sort-criteria";
import { getRoleAcronym } from "../../utils/string-initials";

function getProductsAssignmentsByUser(productAssignments = {}, userId) {
  return Object.values(productAssignments).filter(
    (productAssignment) => productAssignment.attributes.assigneeId == userId,
  );
}

function checkUserHasProductAssigmentsOnBench(
  productAssignmentsInRange,
  weeks,
) {
  // if the user has fewer Assignments products than weeks of the timeline is on the bench
  return productAssignmentsInRange.length < weeks.length;
}

function getUsersOnBench(users, weeks) {
  return Object.values(users)
    .filter((user) => {
      if (
        !user.attributes.ocupation ||
        Object.keys(user.attributes.ocupation).length === 0
      ) {
        return true;
      }

      const productAssignmentsInRange = Object.keys(
        user.attributes.ocupation,
      ).filter((productDate) =>
        isWithinInterval(parseISO(productDate), {
          start: weeks[0],
          end: weeks[weeks.length - 1],
        }),
      );

      const hasWeekInBench = productAssignmentsInRange.some(
        (week) => user.attributes.ocupation[week].type === "bench",
      );

      if (hasWeekInBench) return true;

      return checkUserHasProductAssigmentsOnBench(
        productAssignmentsInRange,
        weeks,
      );
    })
    .sort(sortCriteriaGenerator((item) => item.attributes.fullName));
}

const fadeOut = keyframes`
  from {
    height: "auto";
    opacity: 1;
  }

  to {
    opacity: 0;
    height: 0;
    overflow: "hidden";
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
    overflow: "hidden";
  }

  to {
    height: "auto";
    opacity: 1;
  }
`;

const Fade = styled.div`
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  animation: ${(props) => (props.isOpen ? fadeIn : fadeOut)} forwards 0.3s
    ease-in;
  transition: visibility 1s linear;
  overflow: hidden;
`;

function BenchTimeline() {
  const [showBench, setShowBench] = useState(true);

  const { data, weeks } = useContext(TimelineContext);
  const usersOnBench = getUsersOnBench(data.users, weeks).filter(
    (user) => user.attributes.active && user.attributes.assignable,
  );

  if (usersOnBench.length < 2) {
    return null;
  }

  function toggleShow() {
    setShowBench(!showBench);
  }

  const members = usersOnBench.map((user) => {
    const projectRole = getRoleAcronym(user.attributes.role);
    return {
      id: user.id,
      userName: user.attributes.fullName,
      isReal: true,
      projectRole,
    };
  });
  const assignmentGroups = usersOnBench.map((user) => {
    const assignments = getProductsAssignmentsByUser(
      data.productAssignments,
      user.id,
    );
    const hasAssignments = assignments.length > 0;

    const rowId = hasAssignments ? assignments[0].attributes.rowId : null;

    return {
      assignments,
      rowId,
      isFullRow: !hasAssignments,
    };
  });

  return (
    <>
      <BenchTimelineInfo toggleShow={toggleShow} showBench={showBench} />
      <Fade isOpen={showBench} data-testid="fade-bench">
        <GenericTimeline
          members={members}
          assignmentGroups={assignmentGroups}
          type={"first"}
          projectStart={weeks[0]}
          projectEnd={weeks[weeks.length - 1]}
          isBench
        />
      </Fade>
    </>
  );
}

export default BenchTimeline;
