import React, { useMemo } from "react";
import { Editor, Preview } from "pulse-editor";
import styled from "styled-components";
import { identity, omit } from "lodash";
import markdown from "markdown-it";

const StyledPreview = styled.div`
  overflow-wrap: break-word;
  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 0.5rem;
    padding: 0;
    font-weight: 600;
    cursor: text;
  }

  h1 tt,
  h1 code {
    font-size: inherit;
  }

  h2 tt,
  h2 code {
    font-size: inherit;
  }

  h3 tt,
  h3 code {
    font-size: inherit;
  }

  h4 tt,
  h4 code {
    font-size: inherit;
  }

  h5 tt,
  h5 code {
    font-size: inherit;
  }

  h6 tt,
  h6 code {
    font-size: inherit;
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  li,
  table,
  pre {
    margin: 1rem 0;
  }

  > h2:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  > h1:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  > h1:first-child + h2 {
    margin-top: 0;
    padding-top: 0;
  }
  > h3:first-child,
  > h4:first-child,
  > h5:first-child,
  > h6:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  a:first-child h1,
  a:first-child h2,
  a:first-child h3,
  a:first-child h4,
  a:first-child h5,
  a:first-child h6 {
    margin-top: 0;
    padding-top: 0;
  }

  h1 p,
  h2 p,
  h3 p,
  h4 p,
  h5 p,
  h6 p {
    margin-top: 0;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 1.25;
  }
  h2 {
    font-size: 1.25rem;
    line-height: 1.375;
  }
  h3 {
    font-size: 1.125rem;
    line-height: 1.375;
  }
  h4 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  h5 {
    font-size: 0.875rem;
    line-height: 1.375;
  }
  h6 {
    font-size: 0.75rem;
    line-height: 1.375;
  }

  code {
    padding: 0 0.25rem;
    color: #0050b3;
    background-color: #f5f5f5;
    border-radius: 0.25rem;
    white-space: nowrap;
    border: 1px solid #d9d9d9;
  }

  pre code {
    padding: 0;
    white-space: pre;
    border: none;
    background: transparent;
  }

  pre {
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    overflow: auto;
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }

  a {
    color: #096dd9;
    text-decoration: none;
    transition-property: color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }
  a:hover,
  a:focus {
    color: #1890ff;
  }
  a:active,
  a:hover {
    outline: 0;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  ul :first-child,
  ol :first-child {
    margin-top: 0;
  }

  ul :last-child,
  ol :last-child {
    margin-bottom: 0;
  }

  ol li {
    list-style: decimal;
  }

  ol li:first-child,
  ul li:first-child {
    margin-top: 0;
  }

  ul li {
    list-style: disc;
  }

  blockquote {
    border-left: 0.25rem solid #d9d9d9;
    padding: 0 1rem;
    color: #8c8c8c;
  }
  blockquote > :first-child {
    margin-top: 0;
  }
  blockquote > :last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 600;
  }

  p {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
  }
  mark {
    background-color: #fff5cd;
  }
`;

function parseUnderlinedText(value) {
  const regex = /(__)[\d\sA-Za-z]+(__)/g;
  return value?.replace(regex, (match) => match.replace(/__/g, "++")) || "";
}

function MarkdownViewer({ value, onClick, style, label, className }) {
  return (
    <Editor
      key={value}
      aria-label={label}
      name="main-editor"
      onClick={onClick}
      defaultValue={parseUnderlinedText(value)}
      style={style}
      className={className}
      data-cy="markdown-viewer"
      data-testid="markdown-viewer"
    >
      <StyledPreview as={Preview} />
    </Editor>
  );
}

function getDefaultParser() {
  return new markdown({
    html: false,
    breaks: true,
    linkify: true,
    xhtmlOut: true,
    typographer: true,
    langPrefix: "language-",
  });
}

export function MarkdownWrapper(props) {
  const {
    parser: _parser,
    beforeRender = identity,
    content,
    ...rest
  } = omit(props, "children");

  const parser = useMemo(
    () => beforeRender(_parser || getDefaultParser()),
    [_parser],
  );

  const html = useMemo(() => parser.render(content), [content, parser]);
  return (
    <StyledPreview
      aria-label="Markdown Content"
      dangerouslySetInnerHTML={{ __html: html }}
      {...rest}
    />
  );
}

export { MarkdownViewer };
