import React from "react";
import { Color, Frame } from "@ableco/baseline";
import { api } from "coreql";

import { BodySmall } from "../design-system/typography-components";
import { CheckboxWithLabel } from "../data-entry/checkbox-with-label";

function MetRequirementsCheckbox({
  userId,
  observableBehaviourId,
  metObservableBehaviour,
  mutateUser,
}) {
  const [disabled, setDisabled] = React.useState(false);

  async function handleToggle() {
    setDisabled(true);
    await api.metObservableBehaviours.find(metObservableBehaviour?.id).upsert(
      {
        requirementsMetAt: metObservableBehaviour?.requirementsMetAt
          ? null
          : new Date(),
      },
      { userId, observableBehaviourId },
      [
        { name: "user", resource: "user" },
        { name: "observableBehaviour", resource: "observableBehaviour" },
      ],
    );

    await mutateUser();
    setDisabled(false);
  }

  return (
    <Frame
      className="mt-4 w-40"
      data-testid={`observableBehaviour ${observableBehaviourId} - containerCheckboxRequirementMet`}
    >
      <CheckboxWithLabel
        onChange={handleToggle}
        checked={metObservableBehaviour?.requirementsMetAt}
        checkboxColor="green"
        disabled={disabled}
      >
        <BodySmall color={Color.Neutral700}>Requirements met</BodySmall>
      </CheckboxWithLabel>
    </Frame>
  );
}

export default function MetRequirements({
  isCurrentUserDirectManager,
  userId,
  observableBehaviourId,
  metObservableBehaviour,
  mutateUser,
}) {
  if (isCurrentUserDirectManager) {
    return (
      <MetRequirementsCheckbox
        userId={userId}
        observableBehaviourId={observableBehaviourId}
        metObservableBehaviour={metObservableBehaviour}
        mutateUser={mutateUser}
      />
    );
  }

  return metObservableBehaviour?.requirementsMetAt ? null : (
    <Frame
      className="mt-4"
      data-testid={`observableBehaviour ${observableBehaviourId} - requirementMetUncompleted`}
    >
      <BodySmall color={Color.Neutral700}>
        Badge requirements not yet completed
      </BodySmall>
    </Frame>
  );
}
