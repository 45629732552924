import Resource from "./../Resource";

export default class User extends Resource {
  expectations(id, params = {}) {
    return this._get(`users/${id}/expectations`, params);
  }

  list(params = {}) {
    return this._get("users", params);
  }

  responsibilities(id, params = {}) {
    return this._get(`users/${id}/responsibilities`, params);
  }

  retrieve(id, params = {}) {
    return this._get(`users/${id}`, params);
  }
}
