// Modules
import { connect } from "react-redux";
// Actions
import * as API from "core-redux/actions/api";
// Components
import Milestones from "./Milestones";

const mapStateToProps = (state, ownProps) => {
  const {
    discoveryStart,
    discoveryEnd,
    definitionStart,
    definitionEnd,
    developmentStart,
    developmentEnd,
  } = ownProps.project;

  return {
    discoveryStart,
    discoveryEnd,
    definitionStart,
    definitionEnd,
    developmentStart,
    developmentEnd,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { project } = ownProps;

  return {
    updateProject: (updatedAttributes) =>
      dispatch(API.update("projects", project.id, updatedAttributes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Milestones);
