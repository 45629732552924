// Modules
import { snakeCase } from "lodash";
// Constants
import ActionTypes from "./../constants/ActionTypes";
// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "CALL_API";
const REQUEST = "API_REQUEST";
const SUCCESS = "API_SUCCESS";
const FAILURE = "API_FAILURE";

// Fetches an API response.
function callApi(
  client,
  resource,
  method,
  objectId = null,
  params = {},
  data = {},
) {
  const options = constructApiOptions(method, objectId, params, data);

  return client[resource][method](...options);
}

// Returns Array of options to be passed to the API method.
function constructApiOptions(method, objectId, params, data) {
  const options = objectId ? [objectId] : [];

  if (Object.keys(params).length > 0) {
    options.push(params);
  }

  if (Object.keys(data).length > 0) {
    options.push(data);
  }

  return options;
}

// Keep track of our transactions for playing back failed optimistic updates.
// Note: Not using this yet. It would be great to implement something that
// would play back the state after a failed request.
let nextTransactionID = 0;

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (coreApiClient) => (_store) => (next) => (action) => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const { resource, method, objectId, params, payload } = callAPI;

  if (typeof resource !== "string") {
    throw new TypeError("Specify a string for `resource`.");
  }

  if (typeof method !== "string") {
    throw new TypeError("Specify a string for `method`.");
  }

  let { typeName, responseResourceType } = callAPI;
  typeName = typeName;
  if (typeof typeName !== "string") {
    typeName = `${snakeCase(method).toUpperCase()}`;
  }

  if (typeof responseResourceType !== "string") {
    responseResourceType = `${resource}`;
  }

  const apiRequestType = ActionTypes.API[`${typeName}_REQUEST`];
  const apiSuccessType = ActionTypes.API[`${typeName}_SUCCESS`];
  const apiFailureType = ActionTypes.API[`${typeName}_FAILURE`];

  const transactionID = nextTransactionID++;

  const id = objectId || `temp_${transactionID}`;

  function actionWith(data) {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  }

  // Request action
  next(
    actionWith({
      type: apiRequestType,
      apiTransaction: { type: REQUEST, id: transactionID },
      responseResourceType,
      resource,
      payload: Object.assign({}, { id: id }, payload),
    }),
  );

  // Call the API and return the success or failure actions.
  return callApi(coreApiClient, resource, method, objectId, params, payload)
    .then((response) => {
      next(
        actionWith({
          type: apiSuccessType,
          apiTransaction: { type: SUCCESS, id: transactionID },
          responseResourceType,
          resource,
          payload: response.data,
          id,
        }),
      );

      return response;
    })
    .catch((error) => {
      next(
        actionWith({
          type: apiFailureType,
          apiTransaction: { type: FAILURE, id: transactionID },
          responseResourceType,
          resource,
          id,
          error,
        }),
      );

      return error;
    });
};
