import React, { Suspense, useState } from "react";
import styled from "styled-components";
import ProductDropdown from "./product-dropdown";
import ProjectDropdown from "./project-dropdown";
import BillableDropdown, {
  optionsByKey as billabeOptions,
} from "./billable-dropdown";
import UserDropdown from "./user-dropdown";
import StatusDropdown, {
  optionsByKey as statusOptions,
} from "./status-dropdown";
import { Formik } from "formik";
import * as yup from "yup";
import api from "../../services/api";
import { Paragraph, FormSection, FormSectionButtons } from "./allocation-form";
import { PrimaryButton, TertiaryButton } from "../buttons/buttons";

const Container = styled.div`
  margin-top: 17px;
`;

const EditButton = styled.p`
  display: inline-block;
  color: #fc6072;
  font-size: 12px;
  font-weight: bold;
  line-height: 28px;
  text-transform: uppercase;
  cursor: pointer;
`;

const ValidationSchema = yup.object().shape({
  attributes: yup.object().shape({
    billable: yup.string().required("Select an option"),
    userId: yup.string(),
    projectStatus: yup.string().required("Select a status"),
  }),
});

function Form({ initialValues, onSubmit, onCancel, product }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit}>
          <FormSection>
            <Suspense fallback={null}>
              <ProductDropdown
                label={<Paragraph>Select your product</Paragraph>}
                disabled
              />
            </Suspense>
          </FormSection>
          <FormSection>
            <Suspense fallback={null}>
              <ProjectDropdown
                product={product}
                label={<Paragraph>Name the project</Paragraph>}
                onlyUnallocated={false}
                onlyActive={false}
                disabled
              />
            </Suspense>
          </FormSection>
          <FormSection>
            <BillableDropdown
              label={<Paragraph>Is this project billable?</Paragraph>}
            />
          </FormSection>
          <FormSection>
            <Suspense fallback={null}>
              <UserDropdown
                showOnlyProjectResponsibles={true}
                fieldName="attributes.userId"
                label={
                  <Paragraph>Who is responsible for this project?</Paragraph>
                }
              />
            </Suspense>
          </FormSection>
          <FormSection>
            <StatusDropdown label={<Paragraph>Current status</Paragraph>} />
          </FormSection>

          <FormSectionButtons>
            <TertiaryButton text="Cancel" onClick={onCancel} />
            <PrimaryButton
              text="Update Project"
              disabled={isSubmitting || !isValid}
            />
          </FormSectionButtons>
        </form>
      )}
    </Formik>
  );
}

const InfoContainer = styled.div`
  color: #3d5186;
  font-weight: $font-regular;
  font-size: 18px;
  text-transform: capitalize;
  line-height: 24px;
  margin-bottom: 8px;
`;

function Info({ project, product, responsible, onEdit }) {
  const status = statusOptions[project.data.attributes.projectStatus];
  const billable = billabeOptions[project.data.attributes.billable];

  return (
    <>
      <InfoContainer>
        <div>{project.data.attributes.name}</div>
        <div>{product.attributes.name}</div>
        <div>{billable && billable.content}</div>
        <div>{responsible && responsible.attributes.fullName}</div>
        <div>{status && status.content}</div>
      </InfoContainer>
      <EditButton onClick={onEdit}>edit</EditButton>
    </>
  );
}

function updateProject(values) {
  return api.projects.find(values.projectId).update({
    billable: values.billable,
    projectStatus: values.projectStatus,
    userId: values.userId || null,
  });
}

function ProjectInfo({ project, revalidate }) {
  const [isEditing, setIsEditing] = useState(false);

  const product = project.included.find(
    (resource) => resource.type === "products",
  );
  const responsible = project.included.find(
    (resource) => resource.type === "users",
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping
  async function handleSubmit(values, { setSubmitting }) {
    try {
      await updateProject(values.attributes);
      await revalidate();
      setIsEditing(false);
    } catch {
      alert("Ops! There was an error");
    } finally {
      setSubmitting(false);
    }
  }

  const initialValues = {
    attributes: {
      productId: project.data.relationships.product.data.id || "",
      projectId: project.data.id || "",
      billable: project.data.attributes.billable || "",
      userId: (project.data.attributes.userId || "").toString(),
      projectStatus: project.data.attributes.projectStatus || "",
    },
  };

  return (
    <Container>
      {isEditing ? (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onCancel={() => setIsEditing(false)}
          product={product}
        />
      ) : (
        <Info
          project={project}
          product={product}
          responsible={responsible}
          onEdit={() => setIsEditing(true)}
        />
      )}
    </Container>
  );
}

export default ProjectInfo;
