import React from "react";
import { Container, FakeGrid, WrapInfo, Title } from "./bench-timeline-info";

function ProjectSectionTitle() {
  return (
    <Container>
      <WrapInfo>
        <Title>Projects</Title>
      </WrapInfo>
      <FakeGrid />
    </Container>
  );
}

export default ProjectSectionTitle;
