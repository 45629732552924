import React from "react";
import { noop } from "lodash";
import { Inline } from "@ableco/baseline";
import { Caption } from "./design-system/typography-components";

function Hint({ text, action, onAction = noop }) {
  return (
    <Inline
      bg="primary-lighter"
      p={2}
      distribution="between"
      corners="small"
      className="mb-6"
    >
      <Caption color="primary-darker">{text}</Caption>
      <Caption
        color="primary-darker"
        weight="semibold"
        onClick={onAction}
        className="cursor-pointer"
      >
        {action}
      </Caption>
    </Inline>
  );
}

export default Hint;
