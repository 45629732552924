import React from "react";
import styled from "styled-components";
import { colorPalette } from "./style-guide/colors";
import { network } from "../services/network";
import useSWR, { trigger } from "swr";
import noop from "lodash/noop";
import PageLayout from "./layout/page-layout";
import { PrimaryButton, SecondaryButton } from "./buttons/buttons";

const Legend = styled.p`
  margin: 1rem 0;
`;

const CopyLegend = styled(Legend)`
  text-align: center;
`;

const Token = styled.code`
  background-color: ${colorPalette.bluegrey30};
  border-radius: 0.25rem;
  padding: 0.75rem 5rem;
  user-select: all;
`;

const TokenButton = styled.button`
  display: block;
  margin: 2rem auto;
  position: relative;
  &:focus {
    outline: none;
  }
`;

const TokenName = styled.span``;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

const THead = styled.thead`
  background: ${colorPalette.bluegrey30};
  tr td:first-of-type {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  tr td:last-of-type {
    border-radius: 0 0.25rem 0.25rem 0;
  }
`;

const Row = styled.tr`
  width: 100%;
`;

const Cell = styled.td`
  padding: 0.5rem;
`;

const NameCell = styled(Cell)``;

const ExpireCell = styled(Cell)`
  width: 150px;
`;

const ButtonCell = styled(Cell)`
  width: 140px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Copied = styled.em`
  position: absolute;
  left: calc(100% + 1rem);
`;

function TokensTable({ onDelete = noop }) {
  const { data, revalidate } = useSWR("/api/devices", network, {
    suspense: true,
  });

  async function expire(id, isActive) {
    // if it's active we ask for special confirmation warning it
    // will be logged out
    // if it's not the active token we ask for normal confirmation
    const confirmMessage = isActive
      ? "Are your sure you want to delete this token? This will log you out."
      : "Are your sure you want to delete this token?";
    if (!confirm(confirmMessage)) {
      return;
    }

    // delete the token (this actually expire it)
    await network(`/api/devices/${id}`, { method: "DELETE" });

    // if it was not the active token we revalidate the fetch to update the data
    if (!isActive) revalidate() && onDelete(id);
    // if it was the active token we reload the page and
    // let Rails redirect to the login
    if (isActive) location.reload();
  }

  return (
    <Table>
      <THead>
        <Row>
          <NameCell>Name</NameCell>
          <ExpireCell>Expires At</ExpireCell>
          <ButtonCell>Delete Token</ButtonCell>
        </Row>
      </THead>
      <tbody>
        {data.map((device) => (
          <Row key={device.id}>
            <NameCell>
              {device.isActive ? (
                <>
                  <strong>
                    <TokenName>{device.name}</TokenName>
                  </strong>{" "}
                  <em>(current)</em>
                </>
              ) : (
                <TokenName>{device.name}</TokenName>
              )}
            </NameCell>
            <ExpireCell>
              <time dateTime={device.expiresAt}>
                {new Intl.DateTimeFormat("en", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(new Date(device.expiresAt))}
              </time>
            </ExpireCell>
            <ButtonCell>
              <SecondaryButton
                text="Expire"
                onClick={() => expire(device.id, device.isActive)}
              ></SecondaryButton>
            </ButtonCell>
          </Row>
        ))}
      </tbody>
    </Table>
  );
}

function Tokens() {
  const [device, setDevice] = React.useState(null);
  const [status, setStatus] = React.useState("idle");

  // Create the new token
  React.useEffect(() => {
    if (status === "creating") {
      const name = prompt("Enter a name for your new token.", "Testing Token");
      if (name === null) return setStatus("idle");
      const controller = new AbortController();
      network("/api/devices", {
        method: "POST",
        body: JSON.stringify({ name }),
        signal: controller.signal,
      })
        .then(
          (data) => {
            setStatus("idle");
            setDevice(data);
          },
          () => setStatus("idle"),
        )
        .finally(() => trigger("/api/devices"));
      return () => controller.abort();
    }
  }, [status, device]);

  // Copy to clipboard the token
  React.useEffect(() => {
    if (status === "copying") {
      navigator.clipboard
        .writeText(device.token)
        .then(() => setStatus("copied"));
    }
  }, [status, device]);

  // Reset to idle after copying
  React.useEffect(() => {
    if (status === "copied") {
      const timer = setTimeout(() => setStatus("idle"), 3000);
      return () => clearTimeout(timer);
    }
  }, [status]);

  return (
    <PageLayout title="Tokens">
      <Header>
        <div>
          <Legend>
            These tokens allow other apps to control your whole account. Be
            careful!
          </Legend>
        </div>
        <PrimaryButton
          text="Create new Token"
          onClick={() => setStatus("creating")}
          disabled={status === "creating"}
        />
      </Header>
      {device && (
        <>
          <CopyLegend>
            Please copy your new token and store it in a safe place.
            <br />
            <strong>For security reasons we cannot show it again.</strong>
          </CopyLegend>
          <div>
            <TokenButton onClick={() => setStatus("copying")}>
              <Token>{device.token}</Token>
              {status === "copied" && <Copied>Copied!</Copied>}
            </TokenButton>
          </div>
        </>
      )}
      <React.Suspense fallback={<Legend>Loading tokens...</Legend>}>
        <TokensTable
          onDelete={(id) => {
            if (!device) return;
            if (id === device.id) return setDevice(null);
          }}
        />
      </React.Suspense>
    </PageLayout>
  );
}

export default Tokens;
