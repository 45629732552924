import React, { useMemo } from "react";
import { useCollection } from "coreql";
import {
  isEmpty,
  property,
  sortBy,
  uniq,
  flatten,
  takeRightWhile,
} from "lodash";
import { Frame, Stack, Text } from "@ableco/baseline";
import { wrap } from "../../weekly-report/utils";
import GeneralManagerBadges from "./general-manager-badges";
import OtherBadges from "./other-badges";
import useSortedBadges, { isBadgeAwarded } from "./sort-badges-by-status";
import BadgesGroup from "./badges-group";

function filterBadgesByLevelRole(badges, level, roleId) {
  return badges.filter((badge) => {
    const behaviours = wrap(badge.observableBehaviours).filter(
      (observableBehaviour) => {
        const rolesId = wrap(observableBehaviour.roles).map((role) => role.id);

        return (
          (rolesId.length > 0 ? rolesId.includes(roleId) : true) &&
          observableBehaviour.level.level === level
        );
      },
    );
    return behaviours.length > 0;
  });
}

function useGroupBadgesByLevelId(badges, levels, roleId) {
  return useMemo(
    () =>
      levels.map((level) => ({
        level: level,
        badges: filterBadgesByLevelRole(badges, level.level, roleId),
      })),
    [badges, levels, roleId],
  );
}

function useFilterBadgeGrouped(badgesGrouped, userLevel, assessmentsByBadgeId) {
  return useMemo(() => {
    const filtered = badgesGrouped
      .filter(({ badges }) => badges.length > 0)
      .filter((badgesGrouped) =>
        previousLevelWithAwardedBadge(
          badgesGrouped,
          userLevel,
          assessmentsByBadgeId,
        ),
      );

    return upperLevelBadges(filtered, userLevel, assessmentsByBadgeId);
  }, [badgesGrouped, userLevel, assessmentsByBadgeId]);
}

function useBadgesUsed(filterBadgeGroupByLevel) {
  return useMemo(
    () => uniq(flatten(filterBadgeGroupByLevel.map(({ badges }) => badges))),
    [filterBadgeGroupByLevel],
  );
}

function previousLevelWithAwardedBadge(
  { level, badges },
  userLevel,
  assessmentsByBadgeId,
) {
  return level.level < userLevel.level
    ? badges
        .map((badge) => assessmentsByBadgeId[badge.id])
        .some((assestment) => assestment?.status == "awarded")
    : true;
}

function upperLevelBadges(badgesGrouped, userLevel, assessmentsByBadgeId) {
  return takeRightWhile(
    badgesGrouped,
    ({ level }) =>
      (userLevel.code == "IC1"
        ? level.level == 1
        : level.level <= userLevel.level + 1) ||
      levelBadgesCompleted(
        badgesGrouped.find(
          ({ level: previousLevel }) => previousLevel.level == level.level - 1,
        )?.badges,
        assessmentsByBadgeId,
      ),
  );
}

function levelBadgesCompleted(badges, assessmentsByBadgeId) {
  return badges?.every((badge) =>
    isBadgeAwarded(assessmentsByBadgeId[badge.id]),
  );
}

export default function UserBadgeSections({
  assessmentsByBadgeId,
  showManagerBadges,
  user,
  userRole,
  showRoleBadges,
  isDirectReport,
  isDirectReportOrSelf,
}) {
  const hasManagerLevel = user.level?.kind === "manager";

  const requestFilters =
    hasManagerLevel && userRole.title !== "Software Engineering Manager"
      ? {
          "observableBehaviours.capability.roles.id": [userRole.id, null],
        }
      : {
          "observableBehaviours.capability.roles.id": userRole.id,
        };

  const userRoleLevels = sortBy(wrap(userRole.levels), ["level"]).reverse();

  const { data: badgesData } = useCollection("badges", {
    fields: {
      badges: ["name", "kind", "archivedAt", "observableBehaviours"],
      observableBehaviours: ["level", "roles"],
      roles: ["id"],
      level: ["level", "code", "name"],
    },
    filters: {
      ...requestFilters,
    },
    included: [
      "observableBehaviours",
      "observableBehaviours.level",
      "observableBehaviours.roles",
    ],
  });

  const sortedBadges = useSortedBadges(badgesData, assessmentsByBadgeId, false);

  const badgesGroupByLevel = useGroupBadgesByLevelId(
    sortedBadges,
    userRoleLevels,
    userRole.id,
  );

  const filterBadgeGroupByLevel = useFilterBadgeGrouped(
    badgesGroupByLevel,
    user.level,
    assessmentsByBadgeId,
  );

  const badgesUsed = useBadgesUsed(filterBadgeGroupByLevel);

  const managerLevelM1 = useCollection(
    "levels",
    {
      fields: {
        levels: ["level", "kind", "code"],
      },
      filters: { code: "M1" },
    },
    "denormalized",
  ).data;

  const MAX_MANAGER_LEVEL_FOR_IC = managerLevelM1[0]?.level || 0;

  const { data: managerBadgesResponse } = useCollection("badges", {
    fields: {
      badges: ["name", "kind", "archivedAt"],
    },
    filters: {
      "observableBehaviours.capability.kind": "manager",
      "observableBehaviours.level.level": MAX_MANAGER_LEVEL_FOR_IC,
    },
  });

  const managerBadges = useSortedBadges(
    managerBadgesResponse,
    assessmentsByBadgeId,
    !showManagerBadges,
  );

  const otherUserBadges = useMemo(() => {
    const currentTrackerBadges = [
      ...badgesUsed,
      ...(showManagerBadges ? managerBadges : []),
    ];

    return wrap(user.userBadges).filter(
      ({ badgeId }) =>
        !currentTrackerBadges.find((badge) => badge.id == badgeId),
    );
  }, [user, badgesUsed, showManagerBadges, managerBadges]);

  const { data: otherBadgesData } = useCollection("badges", {
    fields: {
      badges: ["name", "kind", "archivedAt"],
    },
    filters: {
      id: otherUserBadges.map(property("badgeId")),
    },
  });

  const otherBadges = useSortedBadges(
    otherBadgesData,
    assessmentsByBadgeId,
    false,
  );

  const emptyList =
    isEmpty(filterBadgeGroupByLevel) &&
    isEmpty(managerBadges) &&
    isEmpty(otherBadges);

  return (
    <>
      {showRoleBadges && !emptyList ? (
        <Stack className="max-w-page">
          {filterBadgeGroupByLevel.map(({ level, badges }, index) => (
            <BadgesGroup
              key={level.id}
              roleTitle={userRole.title}
              level={level}
              badges={badges}
              assessmentsByBadgeId={assessmentsByBadgeId}
              userId={user.id}
              isDirectReport={isDirectReport}
              isDirectReportOrSelf={isDirectReportOrSelf}
              isFirst={index === 0}
              isCurrentLevel={user.level.id === level.id}
            />
          ))}

          {showManagerBadges && (
            <GeneralManagerBadges
              userId={user.id}
              managerBadges={managerBadges}
              managerBadgeStatus={assessmentsByBadgeId}
              isDirectReport={isDirectReport}
              isDirectReportOrSelf={isDirectReportOrSelf}
            />
          )}
          <OtherBadges
            otherBadges={otherBadges}
            assessmentsByBadgeId={assessmentsByBadgeId}
            userId={user.id}
            isDirectReport={isDirectReport}
            isDirectReportOrSelf={isDirectReportOrSelf}
          />
        </Stack>
      ) : (
        <Frame p={[8, 0]}>
          <Text color="neutral-800" as="p">
            There are no badges created for this role
          </Text>
        </Frame>
      )}
    </>
  );
}
