import { has, noop } from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Emitter from "events";
import { ActionCableContext } from "../providers/action-cable";

/** @type {React.Context<{subscription: Subscription, connected: boolean}>} */
export const OneOnOneChannelContext = createContext({
  subscription: null,
  connected: false,
});

export function OneOnOneChannelProvider({
  requestedId,
  children,
  onConnectionChange = noop,
}) {
  /** @type {import("@rails/actioncable").Consumer} */
  const consumer = useContext(ActionCableContext);
  const [subscription, setSubscription] = useState(null);
  const [connected, setConnected] = useState(false);

  if (!consumer) throw new Error("Must not be used outside cable provider");

  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel: "OneOnOneChannel", requested: requestedId },
      {
        initialized() {
          this.listeners = new Set();
        },
        connected() {
          this.confirmed = true;
          this.received({ type: "connect" });
        },
        disconnected() {
          this.received({ type: "disconnect" });
          this.confirmed = false;
        },
        received(data) {
          for (const handler of this.listeners) {
            handler(data);
          }
        },
      },
    );

    setSubscription(subscription);

    subscription.listeners.add(({ type }) => {
      switch (true) {
        case type === "connect":
          return setConnected(true);
        case type === "disconnect":
          return setConnected(false);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(
    () => void onConnectionChange(connected),
    [connected, onConnectionChange],
  );

  const connection = useMemo(
    () => ({ subscription, connected }),
    [subscription, connected],
  );

  return (
    <OneOnOneChannelContext.Provider value={connection}>
      {children}
    </OneOnOneChannelContext.Provider>
  );
}

/** @typedef {import("@rails/actioncable").Subscription} Subscription */
class SubscriptionChannel extends Emitter {
  constructor() {
    super();
    /** @type {Subscription}  */
    this.subscription = null;
  }

  get connected() {
    return this.subscription?.confirmed;
  }

  /** @type {Subscription["send"]}*/
  send(data) {
    return this.subscription?.send(data);
  }

  /** @type {Subscription["perform"]}*/
  perform(action, data = {}) {
    return this.subscription?.perform(action, data);
  }

  onConnected(fn) {
    if (this.connected) fn(this);
    else this.once("connect", () => fn(this));
  }
}

/**
 *
 * @param {(emitter: SubscriptionChannel) => void} initialize
 * Setup listeners here, other listeners will have to be manually removed.
 */
export function useOneOnOneSubscription(initialize = () => {}) {
  const { subscription, connected } = useContext(OneOnOneChannelContext);
  const [channel] = useState(() => new SubscriptionChannel());

  useEffect(() => {
    if (subscription === null) return;
    channel.subscription = subscription;

    function forwardEvent(data) {
      if (has(data, "type")) channel.emit(data.type, data);
      channel.emit("message", data); // generic
    }

    subscription.listeners.add(forwardEvent);

    initialize(channel);

    return () => subscription.listeners.delete(forwardEvent);
  }, [subscription]);

  return { subscription: channel, connected };
}
