import React from "react";
import {
  Stack,
  Inline,
  Color,
  Text,
  Touchable,
  FlexAlignment,
  FlexDistribution,
} from "@ableco/baseline";
import { Edit } from "@baseline/icons";
import TextInput from "../text-input";
import { api } from "coreql";
import {
  BodyBaseline,
  BodySmall,
} from "../design-system/typography-components";
import { PrimaryButton, TertiaryButton } from "../buttons/buttons";

function RenameOneOnOne({ oneOnOneItemId, oneOnOneItemText = "", refetch }) {
  const [text, setTextState] = React.useState("");
  const [isInputVisible, setIsInputVisible] = React.useState(false);

  async function updateOneOnOneItem(id) {
    return await api.oneOnOneItems.find(id).update({ text: text });
  }

  function handleChange(event) {
    const newText = event.target.value;
    setTextState(newText);
  }

  return (
    <Stack p={[4, 6]} bg={Color.Neutral100}>
      <Stack space={4}>
        <Stack space={2}>
          <Inline
            alignment={FlexAlignment.Center}
            space={4}
            distribution={FlexDistribution.Between}
            className="w-full group  rounded transition-all duration-300 ease-in-out"
          >
            <BodyBaseline color={Color.Neutral700}>
              {`Added to 1-on-1 as a talking point: ${oneOnOneItemText}`}
            </BodyBaseline>
            {!isInputVisible && (
              <Touchable
                data-testid="edit-one-on-one"
                onClick={() => setIsInputVisible(true)}
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
              >
                <Text color={Color.Neutral600}>
                  <Edit
                    data-testid="edit-icon"
                    className="w-5 h-5 text-neutral-600"
                  />
                </Text>
              </Touchable>
            )}
          </Inline>

          {isInputVisible && (
            <Stack space={4}>
              <Inline space={1}>
                <BodySmall
                  as="label"
                  color={Color.Neutral800}
                  htmlFor={`rename-talking-point:${oneOnOneItemId}`}
                >
                  Rename this talking point{" "}
                </BodySmall>
                <BodySmall color={Color.Neutral600}>(Optional)</BodySmall>
              </Inline>

              <TextInput
                id={`rename-talking-point:${oneOnOneItemId}`}
                autofocus={true}
                value={text}
                className="text-sm"
                onChange={handleChange}
                dataTestId={`rename-talking-point:${oneOnOneItemId}`}
                onBlur={() => {
                  if (!text) setIsInputVisible(false);
                }}
              />

              {text.trim() && (
                <Inline space={4} distribution="end">
                  <TertiaryButton
                    onClick={() => {
                      setTextState("");
                      setIsInputVisible(false);
                    }}
                    text="Cancel"
                  />
                  <PrimaryButton
                    onClick={() =>
                      updateOneOnOneItem(oneOnOneItemId).then(() => {
                        refetch();
                        setTextState("");
                        setIsInputVisible(false);
                      })
                    }
                    text="Rename"
                  />
                </Inline>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default RenameOneOnOne;
