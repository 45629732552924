import React from "react";
import { Inline } from "@ableco/baseline";

import { wrap } from "../weekly-report/utils";
import BadgeTag from "./badge-tag";
import MetRequirements from "./met-requirements";

function putUserBadgeInBadge(badge, userBadges) {
  const userBadge = UserBadgeForBadge(userBadges, badge);
  badge.userBadge = userBadge;
  return badge;
}

function UserBadgeForBadge(userBadges, badge) {
  return userBadges.find(
    (userBadge) => userBadge.badgeId == Number.parseInt(badge.id),
  );
}

const regex = new RegExp("untitled", "i");

function conditionFilterBadge(badge) {
  return (
    !regex.test(badge.name) &&
    (!badge.archivedAt ||
      ["awarded", "waived"].includes(badge?.userBadge?.status))
  );
}

export default function CapabilitiesBadges({
  observableBehaviour,
  userBadges = [],
  isCurrentUserDirectManager,
  renderMetRequirement,
  metObservableBehaviour,
  mutateUser,
  userId,
}) {
  const badges = wrap(observableBehaviour.badges)
    .map((badge) => putUserBadgeInBadge(badge, userBadges))
    .filter(conditionFilterBadge);

  return (
    <>
      <Inline
        data-testid={`observableBehaviour ${observableBehaviour.id} - badges`}
        wrap
      >
        {badges.map((badge) => (
          <BadgeTag
            key={`tag-badge-${badge.id}`}
            badge={badge}
            userId={userId}
            userBadge={userBadges.find(
              (userBadge) => userBadge.badgeId == Number.parseInt(badge.id),
            )}
          />
        ))}
      </Inline>
      {renderMetRequirement && badges.length > 1 && (
        <MetRequirements
          isCurrentUserDirectManager={isCurrentUserDirectManager}
          userId={userId}
          metObservableBehaviour={metObservableBehaviour}
          observableBehaviourId={observableBehaviour.id}
          mutateUser={mutateUser}
        />
      )}
    </>
  );
}
