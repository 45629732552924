import * as Sentry from "@sentry/browser";

const SENTRY_DSN = process.env.SENTRY_DSN;

if (SENTRY_DSN !== "__DISABLED__") {
  Sentry.init({ dsn: SENTRY_DSN });
}

export function raise(error, currentUser) {
  if (SENTRY_DSN !== "__DISABLED__") return;

  Sentry.configureScope((scope) => {
    scope.setTag(`core-client`, true);
    scope.setExtra(`url`, location.pathname);
    scope.setUser({ id: currentUser.id, email: currentUser.email });
    if (error.data) scope.setExtra("user", error.data);
  });

  Sentry.captureException(error);
}
