import React, { useMemo, useState } from "react";
import { Cell, Grid, Stack } from "@ableco/baseline";
import clsx from "clsx";
import {
  BodyBaseline,
  BodyBaselineSemibold,
  BodySmall,
} from "../design-system/typography-components";
import { noop } from "lodash";

function CustomRadioButton({
  name,
  value,
  label = value,
  selected,
  disabled,
  handleChange,
}) {
  const TextComponent = selected ? BodyBaselineSemibold : BodyBaseline;
  const controlCls = useMemo(
    () =>
      clsx(
        "block justify-center py-2 -ml-px first:ml-0 hover:z-10 first:rounded-l last:rounded-r transition-colors duration-300 ease-in-out border border-neutral-400",
        {
          "border-none": selected,
          "cursor-not-allowed": disabled,
          "bg-neutral-500 text-neutral-700": selected && disabled,
          "text-neutral-500": !selected && disabled,
          "bg-primary-base text-white": selected && !disabled,
          "text-neutral-700 hover:border-primary-light hover:text-primary-light cursor-pointer":
            !selected && !disabled,
        },
      ),
    [disabled, selected],
  );
  return (
    <label className={controlCls}>
      <input
        type="radio"
        value={value}
        name={name}
        disabled={disabled}
        className="appearance-none"
        onChange={handleChange}
        checked={selected}
        data-testid={`rating-scale-option-${value}`}
      />
      <TextComponent color={false}>{label}</TextComponent>
    </label>
  );
}

export function SegmentedControl({
  name,
  values = [],
  onChange = noop,
  defaultValue = false,
  disabled = false,
}) {
  const [radioSelected, setRadioSelected] = useState(defaultValue);
  const controlSize = values.length;
  const isSelected = (value) => radioSelected === value;
  function handleOptionChange(value) {
    setRadioSelected(value);
    onChange(value);
  }

  return (
    <Stack space={2}>
      <Grid
        className="text-center"
        direction="horizontal"
        distribution="center"
        alignment="stretch"
        col={controlSize}
      >
        {values.map(({ label, value }, i) => (
          <CustomRadioButton
            key={i}
            name={name}
            label={label}
            value={value}
            disabled={disabled}
            handleChange={() => handleOptionChange(value)}
            selected={isSelected(value)}
          />
        ))}
      </Grid>
      <Grid
        className="text-center"
        direction="horizontal"
        distribution="center"
        alignment="center"
        col={controlSize}
      >
        {values.map(({ extLabel }, i) => (
          <Cell className="leading-none" key={i}>
            <BodySmall color="neutral-700">{extLabel}</BodySmall>
          </Cell>
        ))}
      </Grid>
    </Stack>
  );
}
