import React from "react";
import { FormSectionButtons } from "../allocation-form";
import { Formik } from "formik";
import * as yup from "yup";
import { useProjectAllocations } from "./data-hooks";
import { getProjectStart, getProjectEnd } from "../project-timeline";
import mapValues from "lodash/mapValues";
import { compareAsc, isSameDay, parseISO } from "date-fns";
import getMondays from "../../../utils/get-mondays";
import AllocationsGrid from "../allocations-grid/allocations-grid";
import { api } from "coreql";
import { PrimaryButton, TertiaryButton } from "../../buttons/buttons";

function deleteAllocations(formStaffValues, originalAllocationsIds) {
  const existingAllocationsToKeep = formStaffValues.reduce((acc, member) => {
    // assignments with a valid id (exists in DB) and hours greater than 0
    const allocationIds = member.weeks
      .filter((week) => week.id && week.hours > 0)
      .map((week) => week.id);
    acc.push(...allocationIds);
    return acc;
  }, []);

  const allocationsToDelete = originalAllocationsIds.filter(
    (id) => !existingAllocationsToKeep.includes(id),
  );

  return Promise.all(
    allocationsToDelete.map((id) => api.projectAllocations.find(id).destroy()),
  );
}

function isBudgetUpdate(originalBudget, member, newAllocation) {
  const originalMember = originalBudget.attributes.staff.find((member) =>
    member.weeks.some((week) => week.id == newAllocation.id),
  );

  if (!originalMember) return false;

  const originalAllocation = originalMember.weeks.find(
    (week) => week.id == newAllocation.id,
  );

  return (
    String(member.userId) != String(originalMember.userId) ||
    String(member.projectRoleId) != String(originalMember.projectRoleId) ||
    String(newAllocation.hours) != String(originalAllocation.hours)
  );
}

async function updateProjectAllocations(values, allocationsIds, budget) {
  const {
    id: projectId,
    attributes: { staff },
  } = values;

  // delete project allocations
  await deleteAllocations(staff, allocationsIds);

  const promiseArray = [];

  for (const member of staff) {
    const validWeeks = member.weeks.filter((week) => week.hours !== 0);
    const assigneeType = member.userId ? "User" : null;
    const assigneeId = member.userId || null;
    for (const week of validWeeks) {
      const allocation = {
        periodStart: week.date,
        hours: week.hours,
        projectRoleId: member.projectRoleId,
        assigneeId,
        assigneeType,
        projectId,
      };
      if (!week.id) {
        promiseArray.push(api.projectAllocations.create(allocation));
      } else if (
        isBudgetUpdate(budget, member, {
          ...allocation,
          id: week.id,
        })
      ) {
        promiseArray.push(
          api.projectAllocations.find(week.id).update(allocation),
        );
      }
    }
  }

  await Promise.all(promiseArray);
}

async function handleSubmit(
  values,
  setSubmitting,
  budget,
  allocationsIds,
  setInEditMode,
  revalidateFormData,
) {
  try {
    await updateProjectAllocations(values, allocationsIds, budget);
  } catch {
    alert("Ops! There was an error");
  } finally {
    await revalidateFormData();
    setInEditMode(false);
    setSubmitting(false);
  }
}

function parseStaffWeeks(allocations, weeks) {
  return weeks.map((headerWeek) => {
    const currentAllocation = allocations.find((allocation) =>
      isSameDay(parseISO(allocation.attributes.periodStart), headerWeek),
    );
    return {
      id: currentAllocation ? currentAllocation.id : null,
      date: headerWeek,
      hours: currentAllocation ? currentAllocation.attributes.hours : 0,
    };
  });
}

function buildBudgetAllocationsByRow(allocations) {
  const allocationsByRow = allocations.reduce((acc, allocation) => {
    const key = `${allocation.attributes.assigneeType}.${allocation.attributes.assigneeId}.${allocation.attributes.projectRoleIds}`;
    if (!acc[key]) acc[key] = [];
    acc[key].push(allocation);
    return acc;
  }, {});

  return mapValues(allocationsByRow, (assignments) =>
    assignments.sort((a, b) =>
      compareAsc(
        parseISO(a.attributes.periodStart),
        parseISO(b.attributes.periodStart),
      ),
    ),
  );
}

function buildBudgetRows(allocations, project) {
  const budget = {
    id: project.id,
    type: "allocations",
    attributes: {
      productId: project.relationships.product.data.id,
      estimateStartedAt: getProjectStart(project, allocations),
      estimateEndedAt: getProjectEnd(project, allocations),
      staff: [],
    },
  };

  const allocationsByRow = buildBudgetAllocationsByRow(allocations);

  const weeks = getMondays(
    budget.attributes.estimateStartedAt,
    budget.attributes.estimateEndedAt,
  );

  budget.attributes.staff = Object.entries(allocationsByRow).map(
    ([key, allocations]) => ({
      projectRoleId: String(allocations[0].attributes.projectRoleIds[0]),
      projectRoleIds: allocations[0].attributes.projectRoleIds.map((el) =>
        String(el),
      ),
      userId: String(allocations[0].attributes.assigneeId || ""),
      weeks: parseStaffWeeks(allocations, weeks),
      rowId: key,
      rowKey: key,
    }),
  );

  return budget;
}

function formatBudget({ data }, project) {
  const budgetRows = buildBudgetRows(data, project);

  return budgetRows;
}

const BudgetSchema = yup.object().shape({
  type: yup.string().required(),
  attributes: yup.object().shape({
    staff: yup
      .array()
      .min(1)
      .of(
        yup.object().shape({
          projectRoleId: yup.string().required("Role required!"),
          userId: yup.string().required("User required"),
          weeks: yup
            .array()
            .compact((week) => week.hours === 0)
            .min(1, "Budget rows need at least 1 week with more than 0 hours"),
        }),
      ),
  }),
});

// Memoizing the buttons to avoid having to click them twice.
// Otherwise the inputs' onBlur handlers trigger a validation and a form rerender
// which eliminate the original button and the first click event is lost.
// https://github.com/jaredpalmer/formik/issues/1332#issuecomment-463938746
// https://github.com/facebook/react/issues/4210
const UpdateButton = React.memo(({ isValid, isSubmitting }) => (
  <PrimaryButton
    text="Update Budget"
    disabled={!isValid || isSubmitting}
    data-testid="update-budget-button"
  />
));

// Memoizing the buttons to avoid having to click them twice.
// Otherwise the inputs' onBlur handlers trigger a validation and a form rerender
// which eliminate the original button and the first click event is lost.
// https://github.com/jaredpalmer/formik/issues/1332#issuecomment-463938746
// https://github.com/facebook/react/issues/4210
const CancelButton = React.memo(({ resetForm, setInEditMode }) => (
  <TertiaryButton
    data-testid="cancel-edit-budget-button"
    onClick={() => {
      resetForm();
      setInEditMode(false);
    }}
    text="Cancel"
  />
));

export default function BudgetForm({
  allocationId,
  project,
  inEditMode,
  setInEditMode,
}) {
  const { data: allocationsData, mutate: revalidateFormData } =
    useProjectAllocations(allocationId);
  const budget = formatBudget(allocationsData, project);
  const allocationsIds = allocationsData.data.map((el) => el.id);

  return (
    <Formik
      initialValues={budget}
      enableReinitialize={true}
      validationSchema={BudgetSchema}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(
          values,
          setSubmitting,
          budget,
          allocationsIds,
          setInEditMode,
          revalidateFormData,
        )
      }
    >
      {(properties) => {
        const {
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
          values,
          isValid,
          resetForm,
          errors,
          touched,
        } = properties;
        return (
          <form onSubmit={handleSubmit} data-testid="edit-budget-form">
            <AllocationsGrid
              start={values.attributes.estimateStartedAt}
              end={values.attributes.estimateEndedAt}
              staff={values.attributes.staff}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              inEditMode={inEditMode}
              type="budget"
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
            />
            {inEditMode && (
              <FormSectionButtons>
                <CancelButton
                  resetForm={resetForm}
                  setInEditMode={setInEditMode}
                />
                <UpdateButton isValid={isValid} isSubmitting={isSubmitting} />
              </FormSectionButtons>
            )}
          </form>
        );
      }}
    </Formik>
  );
}
