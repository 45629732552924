import React, { useEffect } from "react";
import { Box, Text } from "@ableco/baseline";
import { useParams, useHistory, useLocation } from "react-router";
import { ErrorBoundary, SSRSuspense, useResource, useCollection } from "coreql";
import { isValid, isMonday, parseISO, format } from "date-fns";

import { useCoreToast } from "../toast/toast";
import { wrap } from "../weekly-report/utils";
import {
  BodyBaseline,
  TitleSemibold,
} from "../design-system/typography-components";
import WeeklyQuestionLayout from "./weekly-question-layout";
import { WeeklyQuestionFallback } from "./weekly-question-fallback";
import {
  dateOrDefault,
  scheduledWeeksIncludeDate,
  formatWeekPeriod,
  answersOfDate,
  averageScore,
} from "./weekly-questions-utils";
import {
  WeeklyTextQuestionTable,
  WeeklyScaleQuestionTable,
} from "./weekly-question-table";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import { groupBy } from "lodash";

function parseAnswerGroups(answers) {
  const options = ["1", "2", "3", "4", "5"];
  const groupedAnswers = groupBy(answers, (answer) => answer.data.text);
  const vals = Object.entries(groupedAnswers).map(([key, arrayAnswers]) => ({
    field: key,
    val: arrayAnswers.length,
  }));
  for (const element of options) {
    if (vals.find((el) => el.field == element) === undefined) {
      vals.push({ field: element, val: 0 });
    }
  }
  return vals.sort(
    (a, b) => Number.parseInt(a.field) - Number.parseInt(b.field),
  );
}

function Chart({ data, labels }) {
  return (
    <Box as="div" className="w-1/2 max-w-md" data-testid="chartContainer">
      <ResponsiveContainer width={"100%"} height={350}>
        <BarChart
          width={400}
          height={350}
          data={data}
          margin={{ top: 20, right: 15, left: 0, bottom: 20 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="field" padding={{ left: 20, right: 20 }}>
            <Label
              value={labels[1]}
              position="insideBottomLeft"
              offset={-15}
              style={{
                fill: "rgb(89, 89, 89)",
              }}
            />
            <Label
              value={labels[5]}
              position="insideBottomRight"
              offset={-15}
              style={{
                fill: "rgb(89, 89, 89)",
              }}
            />
          </XAxis>
          <YAxis type="number" domain={[0, "dataMax"]} allowDecimals={false} />
          <Tooltip />
          <Bar dataKey="val" fill="#1890FF" name="Entries" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function WeeklyQuestion() {
  const { questionId } = useParams();
  let query = useQuery();

  const history = useHistory();
  const { errorToast } = useCoreToast();

  const week = query.get("week");

  useEffect(() => {
    if (week !== null) {
      const date = parseISO(query.get("week"));

      if (!isValid(date) || !isMonday(date)) {
        history.replace("/weekly_questions");
        errorToast("Invalid scheduled date");
      }
    }
  }, []);

  return (
    <WeeklyQuestionLayout activeLabelTab="reports">
      <ErrorBoundary fallback={WeeklyQuestionFallback}>
        <SSRSuspense fallback={<WeeklyQuestionFallback />}>
          <GroupWeeks questionId={questionId} week={week} />
        </SSRSuspense>
      </ErrorBoundary>
    </WeeklyQuestionLayout>
  );
}

function GroupWeeks({ questionId, week = null }) {
  const { data: question } = useResource(
    "weekly-report-questions",
    questionId,
    {
      fields: {
        weeklyReportQuestions: [
          "text",
          "questionType",
          "ratingScale",
          "scheduledWeeks",
          "weeklyReportAnswers",
        ],
        weeklyReportQuestionScheduledWeeks: ["scheduledDate"],
        weeklyReportAnswers: ["data", "weeklyReport"],
        weeklyReports: ["submitted", "periodStart", "user"],
        users: ["locationId", "roleId"],
      },
      included: [
        "scheduledWeeks",
        "weeklyReportAnswers",
        "weeklyReportAnswers.weeklyReport",
        "weeklyReportAnswers.weeklyReport.user",
      ],
    },
    "denormalized",
  );

  const { data: roles } = useCollection(
    "roles",
    {
      fields: {
        roles: ["disciplineTitle", "departmentTitle"],
      },
    },
    "denormalized",
  );

  const { data: locations } = useCollection(
    "locations",
    {
      fields: {
        locations: ["name"],
      },
    },
    "denormalized",
  );

  const history = useHistory();

  const { errorToast } = useCoreToast();

  const scheduledWeeks = wrap(question.scheduledWeeks);

  const date = dateOrDefault(scheduledWeeks, week);

  useEffect(() => {
    if (!isValid(date) || !scheduledWeeksIncludeDate(scheduledWeeks, date)) {
      history.replace("/weekly_questions");
      errorToast("Question wasn't scheduled for this date");
    }
  }, []);

  const answers = answersOfDate(question, date);
  const hasAnswers = answers.length === 0;
  return (
    <>
      <Text
        as="h1"
        size="2xl"
        weight="semibold"
        color="neutral-700"
        className="mb-8"
      >
        {question.text}
      </Text>
      {hasAnswers ? (
        <>
          <TitleSemibold className="mb-2">
            {formatWeekPeriod(date)}
          </TitleSemibold>
          <BodyBaseline>There are no entries yet</BodyBaseline>
        </>
      ) : (
        <ShowQuestionsAnswersInfo
          ratingLabel={question.ratingScale}
          date={date}
          answers={answers}
          questionType={question.questionType}
          roles={roles}
          locations={locations}
          questionText={question.text}
        />
      )}
    </>
  );
}

function AvarageAnswer({ answers }) {
  const averageAnswerPoints = averageScore(answers);
  const isWarningAnswer = averageAnswerPoints > 2.5 && averageAnswerPoints <= 4;
  let color = "success-base";

  if (averageAnswerPoints <= 2.5) {
    color = "alert-light";
  } else if (isWarningAnswer) {
    color = "warning-light";
  }

  return (
    <Box
      as="div"
      className={`my-6 w-20 h-20 bg-${color} rounded-full flex flex-col items-center justify-center`}
      data-testid="circleAverage"
    >
      <Box
        as="span"
        className="font-semibold text-2xl text-white text-opacity-85"
      >
        {averageAnswerPoints}
      </Box>
      <Box
        as="span"
        className="font-semibold text-sm text-black text-opacity-45"
      >
        Average
      </Box>
    </Box>
  );
}
function getSubmittedDateFormated(answer) {
  return format(parseISO(answer.weeklyReport.submitted), "yyyy-MM-dd");
}
function parseAnswersToCsv(answers, roles, locations, questionText) {
  const questionRow = [questionText, , , , , ,];
  const labels = [
    "week",
    "role",
    "department",
    "location",
    "submitted",
    "answer",
    "additional_information",
  ];
  return [
    questionRow,
    [, , , , , ,],
    labels,
    ...answers.map((answer) => {
      const week = answer.weeklyReport.periodStart;
      const role = roles.find(
        (role) => Number.parseInt(role.id) === answer.weeklyReport.user.roleId,
      );
      const location = locations.find(
        (location) =>
          Number.parseInt(location.id) === answer.weeklyReport.user.locationId,
      );
      const submitted = getSubmittedDateFormated(answer);
      return [
        week,
        role.disciplineTitle,
        role.departmentTitle,
        location.name,
        submitted,
        answer.data.text,
        answer.data.additionalInformationText,
      ];
    }),
  ];
}
function DownloadCsvLink({ answers, roles, locations, questionText }) {
  const week = answers[0].weeklyReport.periodStart;
  const rows = parseAnswersToCsv(answers, roles, locations, questionText);
  const formatRows = rows.map((element) =>
    element.map((el) => `"${el ? el : ""}"`),
  );
  const encodeURI = encodeURIComponent(
    formatRows.map((e) => e.join(",")).join("\n"),
  );
  const csvContent = "data:text/csv;charset=utf-8," + encodeURI;
  return (
    <Box
      id="csvLink"
      as="a"
      className="text-primary-base hover:text-primary-light transition-color duration-300 ease-in-out no-underline cursor-pointer"
      href={csvContent}
      download={`${week}.csv`}
    >
      Download CSV
    </Box>
  );
}

function ShowQuestionsAnswersInfo({
  answers,
  questionType,
  roles,
  locations,
  date,
  ratingLabel,
  questionText,
}) {
  const countEntries = answers.length;
  const labels = ratingLabel == null ? [] : ratingLabel;
  return (
    <>
      <Box as="div" className="flex flex-wrap gap-16 mb-12">
        {questionType !== "text" ? (
          <Chart data={parseAnswerGroups(answers)} labels={labels} />
        ) : null}
        <Box as="div" className="flex flex-col">
          <TitleSemibold className="mb-2">
            {formatWeekPeriod(date)}
          </TitleSemibold>
          <BodyBaseline>
            {countEntries} {countEntries > 1 ? "entries" : "entry"}
          </BodyBaseline>
          {questionType !== "text" ? <AvarageAnswer answers={answers} /> : null}
          <DownloadCsvLink
            answers={answers}
            roles={roles}
            locations={locations}
            questionText={questionText}
          />
        </Box>
      </Box>
      {questionType == "text" ? (
        <WeeklyTextQuestionTable
          answers={answers}
          roles={roles}
          locations={locations}
        />
      ) : null}
      {questionType == "rating_scale" ? (
        <WeeklyScaleQuestionTable
          answers={answers}
          roles={roles}
          locations={locations}
        />
      ) : null}
    </>
  );
}
