export function getShortTimeZone(value) {
  switch (value) {
    case "Mountain Time (US & Canada)":
      return "MST";
    case "Lima":
      return "PET";
    case "Eastern Time (US & Canada)":
      return "ET";
    case "Osaka":
      return "JST";
    case "Pacific Time (US & Canada)":
      return "PT";
  }
}

export function getStandardTimeZone(value) {
  switch (value) {
    case "Mountain Time (US & Canada)":
      return "America/Denver";
    case "Lima":
      return "America/Lima";
    case "Eastern Time (US & Canada)":
      return "America/New_York";
    case "Osaka":
      return "Asia/Tokyo";
    case "Pacific Time (US & Canada)":
      return "America/Los_Angeles";
  }
}
