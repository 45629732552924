import { useEffect } from "react";

/** @type {HTMLTextAreaElement} */

function useAutoFocusEnd(showMarkdown, text, editorRef) {
  return useEffect(() => {
    const editor = editorRef.current;

    if (!showMarkdown && text !== "") {
      editor.setSelectionRange(text.length, text.length);
      editor.focus();
    }
  }, [showMarkdown]);
}

export default useAutoFocusEnd;
