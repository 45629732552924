import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router";
import { WeeklyReportFromFallback } from "./weekly-report-form";

function NewWeeklyReport() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const location = useLocation();

  useEffect(() => {
    async function newWeeklyReport() {
      const response = await fetch(`/weekly_reports/new${location.search}`);
      const url = new URL(response.url);
      setRedirectUrl(`${url.pathname}${url.search}`);
    }

    newWeeklyReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }
  return <WeeklyReportFromFallback />;
}

export default NewWeeklyReport;
