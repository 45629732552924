// Modules
import { flatten, get, sumBy } from "lodash";

export const allEstimated = (productIds, periods, entities) =>
  periods.map((period) =>
    Math.round(
      sumBy(
        flatten(
          period.periodStarts.map((periodStart) =>
            flatten(
              productIds.map((productId) => {
                const entityIds = get(
                  entities.byProduct,
                  [productId, periodStart],
                  [],
                );

                return entityIds.map((entityId) => entities.byId[entityId]);
              }),
            ),
          ),
        ),
        "estimated",
      ) / 100,
    ),
  );

export const allActual = (productIds, periods, entities) =>
  periods.map((period) =>
    Math.round(
      sumBy(
        flatten(
          period.periodStarts.map((periodStart) =>
            flatten(
              productIds.map((productId) => {
                const entityIds = get(
                  entities.byProduct,
                  [productId, periodStart],
                  [],
                );

                return entityIds.map((entityId) => entities.byId[entityId]);
              }),
            ),
          ),
        ),
        "actual",
      ) / 100,
    ),
  );
