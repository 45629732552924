import { useMemo } from "react";
import { groupBy, property, sortBy, orderBy } from "lodash";
import { denormalize } from "coreql";

/* eslint-disable camelcase */
/* exported for testing */
export function sortBadgesByStatus(badges, assessmentsByBadgeId) {
  const {
    pending = [],
    in_progress = [],
    waived = [],
    awarded = [],
  } = groupBy(
    /* Sort all alphabetically */
    sortBy(badges, property("name")),
    ({ id }) => assessmentsByBadgeId[id]?.status || "pending",
  );

  /* Sort awarded by award date desc */
  const awardedByDate = orderBy(
    awarded,
    ({ id }) => new Date(assessmentsByBadgeId[id].awardedAt),
    ["desc"],
  );

  return [...in_progress, ...pending, ...waived, ...awardedByDate];
}

export function isBadgeAwarded({ status } = {}) {
  return ["awarded", "waived"].includes(status);
}

export default function useSortedBadges(
  rawBadges,
  assessmentsByBadgeId,
  returnEmptyArray = false,
) {
  return useMemo(() => {
    if (returnEmptyArray) {
      return [];
    } else {
      const badges = denormalize(rawBadges, "badges", true)
        .filter(({ name }) => name !== "Untitled")
        /* filter archived badges that don't have an assessment and not awarded */
        .filter(
          (badge) =>
            !badge.archivedAt ||
            (badge.archivedAt &&
              isBadgeAwarded(assessmentsByBadgeId[badge.id])),
        );
      return sortBadgesByStatus(badges, assessmentsByBadgeId);
    }
  }, [rawBadges, assessmentsByBadgeId, returnEmptyArray]);
}
