import React from "react";
import { useCollection } from "coreql";
import { Color, Frame, Inline, Stack } from "@ableco/baseline";
import {
  BodySmall,
  BodySmallSemibold,
  Heading,
  TitleSmall,
} from "./design-system/typography-components";
import { wrap } from "./weekly-report/utils";
import CheerCard from "./cheer-card";
import CheersEmpty from "../images/cheers_empty.gif";
import CheersSentSuccess1 from "../images/cheers_sent_success_1.gif";
import CheersSentSuccess2 from "../images/cheers_sent_success_2.gif";
import CheersSentSuccess3 from "../images/cheers_sent_success_3.gif";
import CheersSentSuccess4 from "../images/cheers_sent_success_4.gif";
import CheersSentSuccess5 from "../images/cheers_sent_success_5.gif";

const successGifs = [
  CheersSentSuccess1,
  CheersSentSuccess2,
  CheersSentSuccess3,
  CheersSentSuccess4,
  CheersSentSuccess5,
];

function randomBG(number) {
  return Math.floor(Math.random() * number);
}

function CheersMessage({ hasCheers, gif }) {
  if (hasCheers) {
    return (
      <Inline className="border rounded bg-neutral-200 border-neutral-400">
        <Frame
          className="bg-neutral-600 mr-8 rounded-l h-40 w-64 max-w-xs bg-center bg-cover"
          style={{ backgroundImage: `url(${gif})` }}
        />
        <Frame>
          <Heading color={Color.Neutral800} as="h2">
            Cheers to you!
          </Heading>
          <BodySmall color={Color.Neutral700} className="pr-3" as="p">
            Make someone else’s day by going to the{" "}
            <a
              href="https://ableco.slack.com/archives/C72B4C8JJ"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <BodySmallSemibold>#cheers</BodySmallSemibold>
            </a>{" "}
            channel in{" "}
            <a
              href="https://ableco.slack.com/archives/C72B4C8JJ"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <BodySmallSemibold>Slack</BodySmallSemibold>
            </a>{" "}
            and appreciating them for all their awesomeness!{" "}
            <span role="img" aria-label="muscle">
              💪
            </span>
          </BodySmall>
        </Frame>
      </Inline>
    );
  }
  return (
    <Inline className="border rounded bg-neutral-200 border-neutral-400">
      <Frame
        className="bg-neutral-600 mr-8 rounded-l h-40 w-64 max-w-xs bg-center bg-cover"
        style={{ backgroundImage: `url(${gif})` }}
      />
      <Frame>
        <Heading color={Color.Neutral800} as="h2">
          Think! Think! Think!
        </Heading>
        <BodySmall color={Color.Neutral700} className="pr-3" as="p">
          Spread honeyed words to appreciate the busy bees in your team{" "}
          <span role="img" aria-label="bee">
            🐝
          </span>{" "}
          Go to the{" "}
          <a
            href="https://ableco.slack.com/archives/C72B4C8JJ"
            style={{ textDecoration: "none" }}
          >
            <strong>#cheers</strong>
          </a>{" "}
          channel on{" "}
          <a
            href="https://ableco.slack.com/archives/C72B4C8JJ"
            style={{ textDecoration: "none" }}
          >
            <strong>Slack</strong>
          </a>{" "}
          and write a thank you note!
        </BodySmall>
      </Frame>
    </Inline>
  );
}

function CheersSentContent({
  hasCheers,
  cheers,
  isCurrentUserTheOwner,
  weeklyReportUserId,
}) {
  const {
    author: { preferredName: weeklyReportUserName },
  } = cheers[0];
  const whoSentCheersName = isCurrentUserTheOwner
    ? "You"
    : weeklyReportUserName;
  const mentions = cheers.map((cheer) => cheer.mentionNames);
  const mentionsTotal = new Set(mentions.flat()).size;
  const pluralizeMentions = mentionsTotal > 1 ? "people" : "person";
  const gif = React.useMemo(
    () => successGifs[randomBG(successGifs.length)],
    [],
  );
  return (
    <>
      <TitleSmall color={Color.Neutral600}>CHEERS</TitleSmall>
      <BodySmall color={Color.Neutral600} as="p" className="mb-2">
        {`${whoSentCheersName} cheered ${mentionsTotal} ${pluralizeMentions} this week`}
      </BodySmall>
      {isCurrentUserTheOwner && (
        <CheersMessage hasCheers={hasCheers} gif={gif} />
      )}
      <Stack space={4} className="py-5">
        {cheers.map((cheer) => (
          <CheerCard
            key={cheer.id}
            id={cheer.id}
            createdAt={cheer.createdAt}
            sender={cheer.author.preferredName}
            senderAvatarUrl={cheer.author.avatarUrl}
            to={cheer.mentionNames}
            slackUrl={cheer.link}
            cheerContent={cheer.content}
            userId={weeklyReportUserId}
            highlightingEnabled={false}
            cheerMentions={wrap(cheer.cheerMentions)}
          />
        ))}
      </Stack>
    </>
  );
}

function CheersSentEmpty({ hasCheers }) {
  return (
    <>
      <TitleSmall color={Color.Neutral600}>CHEERS</TitleSmall>
      <BodySmall color={Color.Neutral600} as="p" className="mb-2">
        You didn't send any cheers :(
      </BodySmall>
      <CheersMessage hasCheers={hasCheers} gif={CheersEmpty} />
    </>
  );
}

const cheerSentConfig = {
  included: ["author", "cheerMentions"],
  fields: {
    cheers: [
      "authorAvatar",
      "author",
      "mentionNames",
      "content",
      "link",
      "createdAt",
      "weeklyReportId",
      "cheerMentions",
    ],
    users: ["avatarUrl", "preferredName"],
  },
};

export function useAllCheersSent(
  weeklyReportId,
  weeklyReportUserId,
  weeklySubmitted,
) {
  const filters = weeklySubmitted
    ? {
        weekly_report: [weeklyReportId, weeklyReportUserId], // Cheers with WeeklyReport
      }
    : {
        weekly_report: [false, weeklyReportUserId], // Cheers without WeeklyReport
      };

  const { data: cheers } = useCollection(
    "cheers",
    {
      filters,
      ...cheerSentConfig,
    },
    "denormalized",
  );
  return cheers;
}

function CheersSent({
  weeklyReportId,
  weeklyReportUser,
  currentUserId,
  weeklySubmitted,
}) {
  const allCheers = useAllCheersSent(
    weeklyReportId,
    weeklyReportUser.id,
    weeklySubmitted,
  );
  const hasCheers = allCheers.length > 0;
  const isCurrentUserTheOwner =
    weeklyReportUser.id.toString() === currentUserId.toString();

  if (!hasCheers && !isCurrentUserTheOwner) return null;

  if (weeklySubmitted && !hasCheers) return null;

  if (!hasCheers) {
    return (
      <Frame className="mt-2">
        <CheersSentEmpty hasCheers={hasCheers} />
      </Frame>
    );
  }

  return (
    <Frame className="w-full mt-10">
      <CheersSentContent
        hasCheers={hasCheers}
        cheers={allCheers}
        isCurrentUserTheOwner={isCurrentUserTheOwner}
        weeklyReportUserId={weeklyReportUser.id}
      />
    </Frame>
  );
}

export default CheersSent;
