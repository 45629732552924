// Constants
import ActionTypes from "core-redux/constants/ActionTypes";

export default (state = false, action) => {
  switch (action.type) {
    case ActionTypes.API.ARCHIVE_FAILURE:
    case ActionTypes.API.CREATE_FAILURE:
    case ActionTypes.API.DESTROY_FAILURE:
    case ActionTypes.API.LIST_FAILURE:
    case ActionTypes.API.RETRIEVE_FAILURE:
    case ActionTypes.API.UPDATE_FAILURE:
      return action.error.status === 404;
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};
