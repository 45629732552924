import React from "react";
import { useCollection } from "coreql";
import { Color, Frame, Stack } from "@ableco/baseline";
import CheerCard from "./cheer-card";
import { wrap } from "./weekly-report/utils";
import { BodySmall, TitleSmall } from "./design-system/typography-components";

const cheerReceivedConfig = {
  included: [
    "author",
    "cheerMentions",
    "cheerMentions.feedbackHighlights",
    "cheerMentions.feedbackHighlights.skills",
    "cheerMentions.feedbackHighlights.responsibility",
  ],
  fields: {
    cheers: [
      "content",
      "createdAt",
      "author",
      "mentionPreferredNames",
      "link",
      "cheerMentions",
    ],
    users: ["avatarUrl", "fullName"],
    cheerMentions: ["weeklyReportId", "userId", "feedbackHighlights"],
    responsibilities: ["title"],
    feedbackHighlights: [
      "start",
      "end",
      "feedbackType",
      "responsibility",
      "skills",
      "note",
    ],
    skills: ["name"],
  },
};

export function useAllCheersReceived(
  weeklyReportId,
  weeklyReportUserId,
  weeklySubmitted,
) {
  const filters = weeklySubmitted
    ? {
        weekly_report: [weeklyReportId, weeklyReportUserId, "mentions"], // Cheers with WeeklyReport
      }
    : {
        weekly_report: [false, weeklyReportUserId, "mentions"], // Cheers without WeeklyReport
      };

  return useCollection(
    "cheers",
    {
      filters,
      ...cheerReceivedConfig,
    },
    "denormalized",
  );
}

function CheersReceived({
  weeklyReportId,
  weeklyReportUser,
  currentUserId,
  weeklySubmitted,
}) {
  const { data: allCheers, mutate } = useAllCheersReceived(
    weeklyReportId,
    weeklyReportUser.id,
    weeklySubmitted,
  );
  if (allCheers.length === 0) return null;
  const managerId = weeklyReportUser.managerId;
  const currentUserIsManager =
    managerId.toString() === currentUserId.toString();

  return (
    <Frame className="w-full mt-10">
      <Stack space={1} className="mb-4">
        <TitleSmall color={Color.Neutral600}>CHEERS</TitleSmall>
        {weeklyReportUser.id.toString() === currentUserId.toString() && (
          <BodySmall color={Color.Neutral600} as="p">
            These messages are public. You can send them through the{" "}
            <span>#cheers</span> channel in <span>Slack</span>
          </BodySmall>
        )}
      </Stack>
      <Stack space={4}>
        {allCheers.map((cheer) => (
          <CheerCard
            key={cheer.id}
            id={cheer.id}
            createdAt={cheer.createdAt}
            sender={cheer.author.fullName}
            senderAvatarUrl={cheer.author.avatarUrl}
            cheerMentions={wrap(cheer.cheerMentions)}
            to={cheer.mentionPreferredNames}
            slackUrl={cheer.link}
            cheerContent={cheer.content}
            userId={weeklyReportUser.id}
            highlightingEnabled={currentUserIsManager}
            refetch={mutate}
          />
        ))}
      </Stack>
    </Frame>
  );
}

export default CheersReceived;
