import React, { useEffect, useMemo, useRef, useState } from "react";
import { debounce, has, partition } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import noop from "../../utils/noop";
import { denormalize } from "coreql";
import { Stack } from "@ableco/baseline";
import Task from "./task";
import { useOneOnOneSubscription } from "../../channels/one_on_one";

/**
 *
 * @param {T} value
 * @returns {() => T}
 * @template T
 */
function useLazyReference(value) {
  const ref = useRef(value);
  ref.current = value;
  return useMemo(() => () => ref.current, []);
}

function TaskGroup({
  label,
  placeholder,
  items = [],
  handlers,
  requesterId,
  requestedUser,
  currentUser,
  kind,
  disabled = false,
  onUpdate = noop,
  onDelete = noop,
  deleteItemFromInternalState = noop,
  buildOnDragEnd = () => {},
}) {
  const [dragState, setDragState] = useState({
    disabled: false,
    inProgress: false,
    itemId: null,
    timestamp: 0,
  });

  function updateDragState(newState) {
    return setDragState((prev) => ({ ...prev, ...newState }));
  }

  /** @type {{current: import("react-beautiful-dnd").SensorAPI}} */
  const dragSensors = useRef(null);
  const getHandlers = useLazyReference(handlers);
  const getDragState = useLazyReference(dragState);

  const { subscription } = useOneOnOneSubscription((channel) => {
    function shouldHandleEvent(event) {
      return event.source !== currentUser.id && event.kind === kind;
    }

    channel.on("delete.task", (event) => {
      if (shouldHandleEvent(event)) {
        getHandlers().delete(event.sourceItem);
      }
    });

    channel.on("insert.task", (event) => {
      if (shouldHandleEvent(event)) {
        getHandlers().addItem("newtask:", event.data);
      }
    });

    channel.on("edit.task", (event) => {
      if (shouldHandleEvent(event)) {
        getHandlers().update(event.sourceItem, {
          ...event.data,
          id: event.sourceItem,
        });
      }
    });

    const deferEnableDrag = debounce(
      () => updateDragState({ disabled: false, itemId: null }),
      1500,
      { trailing: true },
    );

    channel.on("drag.task", (event) => {
      const dragState = getDragState();
      if (
        shouldHandleEvent(event) &&
        (!dragState.inProgress ||
          (dragState.inProgress && event.data.timestamp < dragState.timestamp))
      ) {
        dragSensors.current.tryReleaseLock();
        updateDragState({ disabled: true, itemId: event.sourceItem });
        deferEnableDrag();
      }
    });

    channel.on("reorder.task", (event) => {
      if (shouldHandleEvent(event)) {
        getHandlers().updateOrder({
          id: event.sourceItem,
          destination: event.data.destination,
        });
      }
    });
  });

  function dispatchRealtimeUpdate({ sourceItem = null, data = {}, type }) {
    subscription.send({
      type,
      source: currentUser.id,
      sourceItem,
      kind,
      data,
    });
  }

  useEffect(() => {
    if (!dragState.inProgress) return;

    function dispatchDragInProgress() {
      dispatchRealtimeUpdate({
        type: "drag.task",
        sourceItem: dragState.itemId,
        data: { timestamp: dragState.timestamp },
      });
    }

    const timerId = setInterval(dispatchDragInProgress, 500);

    return () => clearInterval(timerId);
  }, [dragState.inProgress]);
  if (items.length === 0) return null;

  let [newItems, savedItems] = partition(items, (item) => item.text === "");
  return (
    <Stack>
      <DragDropContext
        onDragStart={(state) =>
          setDragState({
            inProgress: true,
            itemId: state.draggableId,
            timestamp: Date.now(),
          })
        }
        sensors={[
          (sensor) => {
            dragSensors.current = sensor;
          },
        ]}
        onDragEnd={(result) => {
          updateDragState({ inProgress: false, itemId: null });
          buildOnDragEnd((disabled) => updateDragState({ disabled }))(result);
          if (
            result.destination &&
            result.source.index !== result.destination.index
          )
            dispatchRealtimeUpdate({
              type: "reorder.task",
              sourceItem: result.draggableId,
              data: {
                source: result.source.index,
                destination: result.destination.index,
              },
            });
        }}
      >
        {savedItems.length > 0 && (
          <Droppable droppableId="list">
            {(provided, snapshot) => (
              <div
                data-testid={`tasks-${kind}`}
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={snapshot.isDraggingOver ? "pointer-events-none" : ""}
              >
                {savedItems.map((item, index) => {
                  let relatedResource = null;
                  if (item.relatedResource) {
                    let type = item.relatedResource?.data?.type;
                    relatedResource = denormalize(item.relatedResource, type);
                  }
                  return (
                    <Draggable
                      draggableId={item.id}
                      index={index}
                      key={item.id}
                      isDragDisabled={dragState.disabled}
                    >
                      {(provided) => (
                        <div
                          data-drag-disabled={dragState.disabled}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Task
                            disabled={disabled}
                            key={item.id}
                            id={item.id}
                            label={label}
                            placeholder={placeholder}
                            kind={kind}
                            disabledActions={{
                              delete: dragState.itemId === item.id,
                            }}
                            relatedResource={relatedResource}
                            requestedUser={requestedUser}
                            currentUser={currentUser}
                            onEdit={(id, data) => {
                              dispatchRealtimeUpdate({
                                sourceItem: id,
                                type: "edit.task",
                                data,
                              });
                              return onUpdate(id, data);
                            }}
                            onDelete={(id) => {
                              dispatchRealtimeUpdate({
                                sourceItem: id,
                                type: "delete.task",
                              });
                              return onDelete(id);
                            }}
                            deleteItemFromInternalState={(id) => {
                              dispatchRealtimeUpdate({
                                sourceItem: id,
                                type: "delete.task",
                              });
                              return deleteItemFromInternalState(id);
                            }}
                            initialText={item.text || ""}
                            assignedTo={item.assignee}
                            relatedResourceType={item.relatedResourceType}
                            requesterId={item.requesterId}
                            completed={item.completed || false}
                            editedById={item.lastEditedById}
                            createdAt={item.createdAt}
                            updatedAt={
                              item.updatedAt ? new Date(item.updatedAt) : null
                            }
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        {newItems.map((item) => (
          <Task
            key={item.id}
            id={item.id}
            label={label}
            placeholder={placeholder}
            kind={kind}
            requestedUser={requestedUser}
            requesterId={requesterId}
            currentUser={currentUser}
            onEdit={(id, data) => {
              /* prevent unsaved items from being sent */
              has(data, "id") &&
                dispatchRealtimeUpdate({
                  type: "insert.task",
                  data,
                });

              return onUpdate(id, data);
            }}
            onDelete={onDelete}
            deleteItemFromInternalState={deleteItemFromInternalState}
            initialText={""}
            completed={false}
            editedById={null}
            updatedAt={null}
          />
        ))}
      </DragDropContext>
    </Stack>
  );
}

export default TaskGroup;
