import React from "react";
import { Frame, Inline, Text } from "@ableco/baseline";
import { AvatarXL } from "../design-system/avatar";

function UserInformation({ title, subtitle }) {
  return (
    <Frame>
      <Text
        size="3xl"
        weight="extrabold"
        as="h2"
        leading="relaxed"
        color="neutral-800"
      >
        {title}
      </Text>
      <Text
        as="h3"
        size="lg"
        weight="normal"
        leading="tight"
        color="neutral-600"
      >
        {subtitle}
      </Text>
    </Frame>
  );
}

function UserAvatar({ url, name }) {
  return (
    <Frame as="figure" className="mr-4">
      <AvatarXL url={url} name={name} />
    </Frame>
  );
}

export default function FullPageHeader({ user, title, subtitle }) {
  return (
    <Inline className="w-full ml-4">
      <UserAvatar url={user.avatarUrl} name={user.fullName} />
      <UserInformation title={title} subtitle={subtitle} />
    </Inline>
  );
}
