import { useHotkeys } from "react-hotkeys-hook";

function useMarkdownHotkeys(
  hotkeys,
  {
    mark,
    enable,
    editor,
    onProcessHandler,
    afterProcessHandler,
    cursorPositionHandler,
  },
) {
  return useHotkeys(
    hotkeys,
    (event) => {
      if (enable) {
        const selStart = editor.selectionStart;
        const selEnd = editor.selectionEnd;
        const value = editor.value;

        const selectedText = value.slice(selStart, selEnd);
        const processedText = onProcessHandler(selectedText, selStart, selEnd);

        const newValue = [
          value.slice(0, selStart),
          processedText,
          value.slice(selEnd, value.length),
        ].join("");

        afterProcessHandler(newValue);

        const cursorPosition = cursorPositionHandler
          ? cursorPositionHandler(selStart, selEnd)
          : selStart + mark?.length;

        editor.setSelectionRange(cursorPosition, cursorPosition);
      }
      event.preventDefault();
    },
    {
      enableOnTags: ["TEXTAREA", "INPUT"],
    },
    [enable],
  );
}

export function useListMarkdownHotkeys(hotkeys, { listHandler, ...options }) {
  return useMarkdownHotkeys(hotkeys, {
    onProcessHandler: (selectedText, selStart) => {
      const textLines = selectedText.split("\n");
      let newLines = textLines.map(listHandler).join("\n");
      if (selStart > 0) {
        newLines = "\n" + newLines;
      }
      return newLines;
    },
    ...options,
  });
}

export function useWrappedMarkdownHotkeys(hotkeys, { ...options }) {
  return useMarkdownHotkeys(hotkeys, {
    onProcessHandler: (selectedText) =>
      options.mark + selectedText + options.mark,
    ...options,
  });
}
