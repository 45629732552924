import styled from "styled-components";
import { colorPalette } from "./colors";
import { fontWeights } from "./typography";

export const BaseAnchor = styled.a`
  color: ${colorPalette.pink500};
  font-weight: ${fontWeights.bold};

  transition: all 0.3s;
  text-decoration: none;

  &:focus,
  &:hover {
    color: ${colorPalette.pink300};
    text-decoration: underline;
  }

  &:active {
    color: ${colorPalette.pink900};
    text-decoration: underline;
  }
`;

export const AnchorButton = styled.button`
  border: none;
  color: ${colorPalette.pink500};
  font-weight: ${fontWeights.bold};

  transition: all 0.3s;
  text-decoration: none;

  &:focus,
  &:hover {
    color: ${colorPalette.pink300};
    text-decoration: underline;
  }

  &:active {
    color: ${colorPalette.pink900};
    text-decoration: underline;
  }

  &:disabled {
    color: ${colorPalette.bluegrey50};
    background: none;
  }
`;
