import React from "react";
import { TextArea } from "../text-input";
import {
  Flex,
  Stack,
  Frame,
  Inline,
  Touchable,
  useOnClickOutside,
  Grid,
  Cell,
  Tooltip as BaselineTooltip,
} from "@ableco/baseline";
import { Close, Markdown } from "@baseline/icons";
import useTooltip from "../../hooks/use-tooltip";
import {
  useListMarkdownHotkeys,
  useWrappedMarkdownHotkeys,
} from "./useMarkdownHotkeys";
import {
  BodySmall,
  BodyBaselineSemibold,
  Caption,
} from "../design-system/typography-components";
import { AnimatePresence } from "framer-motion";

function FormatButton({ onClick, className, showTooltip = true }) {
  return (
    <BaselineTooltip
      p={[1, 2]}
      bg="neutral-800"
      label={
        <BodySmall color="neutral-400">
          {showTooltip ? "Show formatting" : null}
        </BodySmall>
      }
    >
      <Touchable
        tabIndex={-1}
        data-testid="format-button"
        className="text-neutral-600 hover:text-neutral-800 focus:text-neutral-800 "
        onClick={onClick}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
      >
        <Stack>
          <Markdown className={className || "h-4 w-4"} />
        </Stack>
      </Touchable>
    </BaselineTooltip>
  );
}

function FormatPopup({ onClick }) {
  const singleLine = <Frame as="hr" className="text-black text-opacity-25" />;
  const popupItemTextColor = "neutral-700";
  return (
    <Stack p={4}>
      <Flex distribution="between">
        <Frame p={[0, 2, 2, 0]}>
          <BodyBaselineSemibold>Formatting options</BodyBaselineSemibold>
        </Frame>
        <Touchable onClick={onClick}>
          <Close
            className="w-6 h-6 opacity-45 absolute"
            style={{ top: "5px", right: "5px" }}
          />
        </Touchable>
      </Flex>
      <Stack>
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <Caption color="neutral-800">Style</Caption>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <Caption color="neutral-800">Markdown</Caption>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <Caption color="neutral-800">Shortcuts</Caption>
          </Cell>
        </Grid>
        {singleLine}
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <BodySmall weight="bold" color={popupItemTextColor}>
              Bold
            </BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <BodySmall color={popupItemTextColor}>**Text**</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <BodySmall color={popupItemTextColor}>⌘ B</BodySmall>
          </Cell>
        </Grid>
        {singleLine}
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <BodySmall italic={true} color={popupItemTextColor}>
              Italic
            </BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <BodySmall color={popupItemTextColor}>_Text_</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <BodySmall color={popupItemTextColor}>⌘ I</BodySmall>
          </Cell>
        </Grid>
        {singleLine}
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <BodySmall color={popupItemTextColor}>
              <u>Underline</u>
            </BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <BodySmall color={popupItemTextColor}>__Text__</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <BodySmall color={popupItemTextColor}>⌘ U</BodySmall>
          </Cell>
        </Grid>
        {singleLine}
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <BodySmall color={popupItemTextColor}>
              <s>Strikethrough</s>
            </BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <BodySmall color={popupItemTextColor}>~~Text~~</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <BodySmall color={popupItemTextColor}>⌘ ⇧ X</BodySmall>
          </Cell>
        </Grid>
        {singleLine}
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <Frame
              style={{
                backgroundColor: "#f9f2f4",
                width: "35px",
              }}
              corners="small"
            >
              <BodySmall style={{ color: "#c7254e" }}>Code</BodySmall>
            </Frame>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <BodySmall color={popupItemTextColor}>`Text`</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <BodySmall color={popupItemTextColor}>⌘ J</BodySmall>
          </Cell>
        </Grid>
        {singleLine}
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <Frame
              style={{ background: "#f5f5f5", width: "76px" }}
              corners="small"
            >
              <BodySmall color={popupItemTextColor}>Code block</BodySmall>
            </Frame>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <BodySmall color={popupItemTextColor}>```Text```</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <BodySmall color={popupItemTextColor}>⌘ ⇧ J</BodySmall>
          </Cell>
        </Grid>
        {singleLine}
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <BodySmall color={popupItemTextColor}>• Bulleted list</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <BodySmall color={popupItemTextColor}>- Text</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <BodySmall color={popupItemTextColor}>⌘ ⇧ B</BodySmall>
          </Cell>
        </Grid>
        {singleLine}
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <BodySmall color={popupItemTextColor}>1. Numbered list</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <BodySmall color={popupItemTextColor}>1. Text</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <BodySmall color={popupItemTextColor}>⌘ ⇧ N</BodySmall>
          </Cell>
        </Grid>
        {singleLine}
        <Grid col={10}>
          <Cell p={[2, 0]} start={1} end={6}>
            <BodySmall style={{ color: "#428bca" }}>Link</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={6} end={9}>
            <BodySmall color={popupItemTextColor}>[Text](url)</BodySmall>
          </Cell>
          <Cell p={[2, 0]} start={9} end={11}>
            <BodySmall color={popupItemTextColor}>⌘ K</BodySmall>
          </Cell>
        </Grid>
      </Stack>
    </Stack>
  );
}

function MarkdownEditor({
  showTooltip = true,
  popupRigth = 150,
  icons = null,
  iconContainerCls,
  markdownIconCls,
  text,
  ...props
}) {
  const [editorFocused, setEditorFocused] = React.useState(false);
  const $popupRef = React.useRef(null);

  const [textState, setTextState] = React.useState(text);
  const $editorRef = React.useRef(null);

  React.useEffect(() => {
    if (text !== textState) {
      setTextState(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  function handleChange(event) {
    if (props.onChange) {
      props.onChange(event);
    }
    setTextState(event.target.value);
  }

  const options = {
    enable: editorFocused,
    editor: $editorRef.current,
    afterProcessHandler: (newValue) => {
      /**
       * We set React internal's _valueTracker to an old value
       * to trigger a custom change event on the field.
       * See:
       * https://github.com/facebook/react/issues/12344
       * https://github.com/facebook/react/issues/10135
       *
       * @type {HTMLInputElement}
       **/
      const input = $editorRef.current;
      const previousValue = input.value;

      input.value = newValue;
      // set react internal tracker to an outdated value so our event triggers
      input._valueTracker.setValue(previousValue);

      input.dispatchEvent(new Event("change", { bubbles: true }));
    },
  };

  useListMarkdownHotkeys("command+shift+l", {
    listHandler: (line, index) => `${index + 1}. ${line}`,
    cursorPositionHandler: (selStart) =>
      selStart > 0 ? selStart + 4 : selStart + 3,
    ...options,
  });

  useListMarkdownHotkeys("command+shift+o", {
    listHandler: (line) => `- ${line}`,
    cursorPositionHandler: (selStart) =>
      selStart > 0 ? selStart + 3 : selStart + 2,
    ...options,
  });

  useWrappedMarkdownHotkeys("command+i", { mark: "*", ...options });

  useWrappedMarkdownHotkeys("command+b", { mark: "**", ...options });

  useWrappedMarkdownHotkeys("command+u", { mark: "__", ...options });

  useWrappedMarkdownHotkeys("command+shift+x", { mark: "~~", ...options });

  useWrappedMarkdownHotkeys("command+j", { mark: "`", ...options });

  useWrappedMarkdownHotkeys("command+shift+j", {
    mark: "```",
    onProcessHandler: (selectedText, selStart) => {
      let processedValue = "```" + selectedText + "```";
      if (selStart > 0) {
        processedValue = "\n" + processedValue;
      }
      return processedValue;
    },
    cursorPositionHandler: (selStart) =>
      selStart > 0 ? selStart + 4 : selStart + 3,
    ...options,
  });

  useWrappedMarkdownHotkeys("command+k", {
    onProcessHandler: (selectedText) => {
      let processedValue = "[" + selectedText + "]()";
      return processedValue;
    },
    cursorPositionHandler: (selStart, selEnd) => {
      const isThereSelection = selEnd - selStart > 0;
      return isThereSelection ? selEnd + 3 : selStart + 1;
    },
    ...options,
  });

  const [Tooltip, , { open, close, isVisible }] = useTooltip();
  const tooltipHeight = 473;

  useOnClickOutside($popupRef, close);

  function handleClick(event) {
    event.preventDefault();
    const rect = event.target.getBoundingClientRect();
    const topValue =
      window.scrollY + rect.y + tooltipHeight > document.body.scrollHeight
        ? window.scrollY + rect.bottom - tooltipHeight
        : window.scrollY + rect.y;
    open({
      left: rect.x ? rect.x - popupRigth : 0,
      top: rect.y ? topValue - tooltipHeight - 8 : 0,
      height: tooltipHeight,
    });
  }

  return (
    <>
      <Inline className="w-full relative group">
        <TextArea
          autosize={40}
          {...props}
          style={Object.assign({ paddingRight: "60px" }, props.style || {})}
          value={textState}
          label={props.label}
          onChange={handleChange}
          onBlur={(event) => {
            if (props.onBlur) {
              props.onBlur(event);
            }
            setEditorFocused(false);
          }}
          onFocus={(event) => {
            setEditorFocused(true);
            if (props.onFocus) {
              props.onFocus(event);
            }
          }}
          onRef={(ref) => {
            $editorRef.current = ref.current;
            if (props.onRef) {
              props.onRef(ref);
            }
          }}
        />
        <Inline
          space={1}
          className={iconContainerCls || "absolute bottom-0 right-0 mr-2 my-3"}
        >
          {editorFocused && (
            <FormatButton
              className={markdownIconCls}
              showTooltip={showTooltip}
              onClick={handleClick}
            />
          )}
          {icons}
        </Inline>
      </Inline>
      <AnimatePresence>
        {isVisible && (
          <Tooltip
            p={0}
            withArrow={false}
            innerRef={$popupRef}
            style={{
              width: "330px",
            }}
            data-testid="format-popup"
          >
            <FormatPopup onClick={close} />
          </Tooltip>
        )}
      </AnimatePresence>
    </>
  );
}

export { MarkdownEditor };
