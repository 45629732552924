import React from "react";
import { Text, Stack, Frame } from "@ableco/baseline";
import { ErrorBoundary, SSRSuspense, useCollection } from "coreql";
import { range } from "lodash";

import CoreLink from "../core-link";
import { weekStart, formatWithDashes } from "../weekly-report/history/utils";
import {
  weeksRange,
  weeksInGroup,
  groupQuestionsByWeeks,
  formatWeekPeriod,
} from "./weekly-questions-utils";

import WeeklyQuestionLayout from "./weekly-question-layout";
import {
  BodySmall,
  TitleSemibold,
} from "../design-system/typography-components";
import { WeeklyQuestionsFallback } from "./weekly-question-fallback";

import WeeklyQuestionsTable from "./weekly-questions-table";

export default function WeeklyQuestions() {
  const [groupCount, setGroupCount] = React.useState(1);

  function addGroup() {
    setGroupCount((value) => value + 1);
  }

  const currentWeeklyStart = weekStart(new Date());

  return (
    <WeeklyQuestionLayout activeLabelTab="reports">
      <Text as="h1" size="2xl" weight="semibold" className="mb-6">
        Reports
      </Text>
      <Text className="mb-4">
        Review or download the answers. Note: The reports do not show private
        answers.
      </Text>
      <CoreLink
        href="/admin/weekly_report_questions"
        isExternalLink={true}
        className="self-start"
      >
        <BodySmall
          color={["primary", "primary-light"]}
          className="transition-color duration-300 ease-in-out no-underline"
        >
          Edit questions
        </BodySmall>
      </CoreLink>
      <Stack space={8} className="mt-10">
        {range(groupCount).map((index) => (
          <ErrorBoundary key={index} fallback={WeeklyQuestionsFallback}>
            <SSRSuspense fallback={<WeeklyQuestionsFallback />}>
              <GroupWeeks
                index={index}
                last={index + 1 == groupCount}
                addGroup={addGroup}
                currentWeeklyStart={currentWeeklyStart}
              />
            </SSRSuspense>
          </ErrorBoundary>
        ))}
      </Stack>
    </WeeklyQuestionLayout>
  );
}

function GroupWeeks({ index, last, addGroup, currentWeeklyStart }) {
  const { scheduledSince, scheduledUntil } = weeksRange(
    currentWeeklyStart,
    index,
  );

  const { data: questions } = useCollection(
    "weekly-report-questions",
    {
      fields: {
        weeklyReportQuestion: [
          "text",
          "optional",
          "questionType",
          "scheduledWeeks",
          "weeklyReportAnswers",
          "questionable",
        ],
        weeklyReportQuestionScheduledWeeks: ["scheduledDate"],
        weeklyReportAnswers: ["weeklyReport"],
        weeklyReports: ["submitted", "periodStart"],
        departments: ["title"],
        customGroups: ["name"],
      },
      filters: {
        scheduledSince: formatWithDashes(scheduledSince),
        scheduledUntil: formatWithDashes(scheduledUntil),
        active: "true",
      },
      included: [
        "scheduledWeeks",
        "weeklyReportAnswers",
        "weeklyReportAnswers.weeklyReport",
        "questionable",
      ],
    },
    "denormalized",
  );

  const weeks = weeksInGroup(scheduledUntil);

  const questionGrouped = groupQuestionsByWeeks(questions, weeks);

  return (
    <>
      {weeks.map((week) => (
        <WeekContent
          key={formatWithDashes(week)}
          weeksMonday={week}
          questions={questionGrouped[formatWithDashes(week)]}
        />
      ))}
      {last ? (
        <BodySmall
          as="button"
          onClick={addGroup}
          color={["primary", "primary-light"]}
          className="transition-color duration-300 ease-in-out no-underline self-start"
        >
          See more weeks
        </BodySmall>
      ) : null}
    </>
  );
}

function WeekContent({ weeksMonday, questions }) {
  const period = formatWeekPeriod(weeksMonday);
  return (
    <Stack data-testid={`week-${formatWithDashes(weeksMonday)}`}>
      <TitleSemibold className="mb-2 text-neutral-700">{period}</TitleSemibold>
      <Frame className="ml-6">
        <WeeklyQuestionsTable questions={questions} weeksMonday={weeksMonday} />
      </Frame>
    </Stack>
  );
}
