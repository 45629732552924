// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Constants
import AppTypes from "core-redux/constants/AppTypes";
// Components
import Rows from "utilization/components/Rows";
// Styles
import Styles from "utilization/components/Table/Table.module.scss";

class ProductValueRows extends PureComponent {
  static propTypes = {
    product: AppTypes.product.isRequired,
  };

  render() {
    const { product } = this.props;

    return (
      <>
        <EmptyRow />
        <EmptyRow />
        <Rows.Values filter="revenue.estimated" product={product} />
        <Rows.Values filter="revenue.actual" product={product} />
        <Rows.Values filter="revenue.dev_est_act" product={product} />
        <EmptyRow />
        <Rows.Values filter="headcount.budgeted" product={product} />
        <Rows.Values filter="headcount.estimated" product={product} />
        <Rows.Values filter="headcount.actual" product={product} />
        <Rows.Values filter="headcount.dev_bud_est" product={product} />
        <Rows.Values filter="headcount.dev_bud_act" product={product} />
        <Rows.Values filter="headcount.dev_est_act" product={product} />
        <EmptyRow />
        <Rows.Values filter="hours.budgeted" product={product} />
        <Rows.Values filter="hours.estimated" product={product} />
        <Rows.Values filter="hours.actual" product={product} />
        <Rows.Values filter="hours.dev_bud_est" product={product} />
        <Rows.Values filter="hours.dev_bud_act" product={product} />
        <Rows.Values filter="hours.dev_est_act" product={product} />
        <EmptyRow />
        <Rows.Values filter="cost.budgeted" product={product} />
        <Rows.Values filter="cost.estimated" product={product} />
        <Rows.Values filter="cost.actual" product={product} />
        <Rows.Values filter="cost.dev_bud_est" product={product} />
        <Rows.Values filter="cost.dev_bud_act" product={product} />
        <Rows.Values filter="cost.dev_est_act" product={product} />
        <EmptyRow />
        <Rows.Values filter="gross.estimated" product={product} />
        <Rows.Values filter="gross.actual" product={product} />
        <Rows.Values filter="gross.dev_est_act" product={product} />
      </>
    );
  }
}

const EmptyRow = () => <div className={Styles.row} />;

export default ProductValueRows;
