// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { endOfWeek, format, isSameDay, startOfWeek } from "date-fns";
// Components
import DatePicker from "react-datepicker";
// Styles
import "react-datepicker/dist/react-datepicker.css";
import Styles from "./Controls.module.scss";

class Controls extends PureComponent {
  static propTypes = {
    detailBy: PropTypes.string.isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    periodIncrement: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    updateDetailBy: PropTypes.func.isRequired,
    updateEndDate: PropTypes.func.isRequired,
    updatePeriodIncrement: PropTypes.func.isRequired,
    updateStartDate: PropTypes.func.isRequired,
  };

  render() {
    const { detailBy, periodIncrement, updateDetailBy, updatePeriodIncrement } =
      this.props;

    return (
      <>
        <div className={Styles.controlGroup}>
          <div className="btn-group btn-group-sm">
            <label className="btn btn-sm btn-primary">
              <input type="radio" name="options" checked readOnly /> Product
            </label>
            <label className="btn btn-sm" onClick={() => alert("Coming soon!")}>
              <input type="radio" name="options" readOnly disabled /> Project
            </label>
          </div>
        </div>

        <div className={Styles.controlGroup}>
          <div className="btn-group btn-group-sm">
            <label
              className={clsx("btn", "btn-sm", {
                "btn-primary": detailBy === "role",
              })}
            >
              <input
                type="radio"
                name="detailBy"
                value="role"
                defaultChecked={detailBy === "role"}
                onChange={updateDetailBy}
              />{" "}
              Role
            </label>
            <label
              className={clsx("btn", "btn-sm", {
                "btn-primary": detailBy === "user",
              })}
            >
              <input
                type="radio"
                name="detailBy"
                value="user"
                defaultChecked={detailBy === "user"}
                onChange={updateDetailBy}
              />{" "}
              Team Member
            </label>
          </div>
        </div>

        <div className={Styles.controlGroup}>
          <StartDatePicker {...this.props} />
          ⇌
          <EndDatePicker {...this.props} />
        </div>

        <div className={Styles.controlGroup}>
          <div className="btn-group btn-group-sm">
            <label
              className={clsx("btn", "btn-sm", {
                "btn-primary": periodIncrement === "week",
              })}
            >
              <input
                type="radio"
                name="periodIncrement"
                value="week"
                defaultChecked={periodIncrement === "week"}
                onChange={updatePeriodIncrement}
              />
              Week
            </label>
            <label
              className={clsx("btn", "btn-sm", {
                "btn-primary": periodIncrement === "month",
              })}
            >
              <input
                type="radio"
                name="periodIncrement"
                value="month"
                defaultChecked={periodIncrement === "month"}
                onChange={updatePeriodIncrement}
              />
              Month
            </label>
            <label
              className={clsx("btn", "btn-sm", {
                "btn-primary": periodIncrement === "year",
              })}
            >
              <input
                type="radio"
                name="periodIncrement"
                value="year"
                defaultChecked={periodIncrement === "year"}
                onChange={updatePeriodIncrement}
              />
              Year
            </label>
          </div>
        </div>

        <div
          className={Styles.controlGroup}
          onClick={() => alert("Coming soon!")}
        >
          <input type="text" value="All Products" disabled />
        </div>
      </>
    );
  }
}

const EndDatePicker = (props) => (
  <WrappedDatePicker
    filter={endOfWeek}
    onChange={props.updateEndDate}
    selected={props.endDate}
    {...props}
  />
);

const StartDatePicker = (props) => (
  <WrappedDatePicker
    filter={startOfWeek}
    onChange={props.updateStartDate}
    selected={props.startDate}
    {...props}
  />
);

const WrappedDatePicker = (props) => {
  const { endDate, filter, onChange, periodIncrement, selected, startDate } =
    props;

  switch (periodIncrement) {
    case "month":
      return (
        <DatePicker
          dateFormat="MMMM yyyy"
          endDate={endDate}
          onChange={onChange}
          selected={selected}
          selectsStart
          showMonthYearPicker
          startDate={startDate}
        />
      );
    case "week":
      return (
        <DatePicker
          dateFormat="MMM d, yyyy"
          endDate={endDate}
          filterDate={(date) =>
            isSameDay(date, filter(date, { weekStartsOn: 1 }))
          }
          onChange={onChange}
          selected={selected}
          selectsStart
          startDate={startDate}
        />
      );
    case "year":
      return (
        <input
          type="number"
          min={2013}
          max={2099}
          onChange={(e) => onChange(e.target.value)}
          step="1"
          value={format(selected, "yyyy")}
        />
      );
  }
};

export default Controls;
