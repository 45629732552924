// Modules
import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import createRootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import apiMiddleware from "core-redux/middleware/api";
import Core from "core";

const initialState = window.STATE_FROM_SERVER;

const coreClient = new Core(window.API_TOKEN);

export const history = createBrowserHistory();

const middleware = [
  thunkMiddleware,
  apiMiddleware(coreClient),
  routerMiddleware(history),
];

const middlewareEnhancer = applyMiddleware(...middleware);

const composeEnhancers = composeWithDevTools({
  trace: true,
});

const store = createStore(
  createRootReducer(history),
  initialState,
  composeEnhancers(middlewareEnhancer),
);

export default store;
