// Modules
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import createRootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import coreApiMiddleware from "core-redux/middleware/api";
import coreEntitiesMiddleware from "core-redux/middleware/entities";
import Core from "core";
import { matchPath } from "react-router";

const initialState = {
  projectId: Number(
    matchPath(
      window.location.pathname,
      "/products/:productId/projects/:projectId/budget",
    )?.params.projectId,
  ),
};

const coreClient = new Core(window.API_TOKEN);

const middleware = [
  thunkMiddleware,
  coreApiMiddleware(coreClient),
  coreEntitiesMiddleware,
];

const middlewareEnhancer = applyMiddleware(...middleware);

const composeEnhancers = composeWithDevTools({
  trace: true,
});

const store = createStore(
  createRootReducer(history),
  initialState,
  composeEnhancers(middlewareEnhancer),
);

export default store;
