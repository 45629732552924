import React from "react";
import { Inline } from "@ableco/baseline";
import { CaretDown, CaretUp } from "@baseline/icons";

function toggleMessage(showElement, message) {
  return showElement ? `Hide ${message}` : `Show ${message}`;
}

function ToggleHelper({ showElement, setShowElement, message }) {
  function toggleAnswer() {
    setShowElement((showElement) => !showElement);
  }

  return (
    <Inline onClick={toggleAnswer} className="cursor-pointer">
      {toggleMessage(showElement, message)}
      {showElement ? (
        <CaretUp className="w-3 h-3 ml-2" />
      ) : (
        <CaretDown className="w-3 h-3 ml-2" />
      )}
    </Inline>
  );
}

export default ToggleHelper;
