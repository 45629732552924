import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import { render } from "react-dom";
import { ErrorBoundary, SSRSuspense } from "coreql";
import getData from "../utils/get-data";
import CoreApp from "../components/_app";
import { SidebarFallback } from "../components/navigation/sidebar";
import "../css/tailwind.css";

function renderComponent(rootId, Component, options = {}) {
  const defaultOptions = {
    dataId: null,
    props: {},
    errorFallback: () => "There was an error",
    suspenseFallback: () => "Loading...",
  };

  const finalOptions = Object.assign({}, defaultOptions, options);

  const root = document.querySelector(rootId);

  if (root !== null) {
    const data = getData(finalOptions.dataId) || {};

    render(
      <React.StrictMode>
        <ErrorBoundary fallback={finalOptions.errorFallback}>
          <SSRSuspense fallback={<finalOptions.suspenseFallback />}>
            <Component {...data} {...finalOptions.props} />
          </SSRSuspense>
        </ErrorBoundary>
      </React.StrictMode>,
      root,
    );
  }
}

renderComponent("#core-app", CoreApp, {
  errorFallback: () => "There was an error loading Core",
  suspenseFallback: SidebarFallback,
});
