import React from "react";
import { PrimaryButton } from "../buttons/buttons";
import { Inline, Color } from "@ableco/baseline";
import { BodyBaseline } from "../design-system/typography-components";
import { api } from "coreql";
import { setMilliseconds, format } from "date-fns";
import { useHistory } from "react-router";
import { useCoreToast } from "../toast/toast";
import { useOneOnOneSubscription } from "../../channels/one_on_one";

function dateWithoutMilliseconds(date) {
  return setMilliseconds(date, 0);
}

function OneOnOneFormFooter({
  savedAt,
  disabled,
  requesterId,
  requestedId,
  refreshCacheAfterEnd,
}) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { errorToast } = useCoreToast();

  const history = useHistory();

  const { subscription } = useOneOnOneSubscription((channel) => {
    channel.on("ending", ({ source }) => {
      if (source === requestedId) setIsSubmitting(true);
    });

    channel.on("ended", ({ id, source }) => {
      if (String(source) === requestedId) history.push(`/1-on-1/${id}`);
    });
  });

  let formattedSavedAt = "Never";
  if (savedAt) {
    formattedSavedAt = format(savedAt, "MMMM d, yyyy - hh:mm aa");
  }

  function endOneOnOne() {
    subscription.send({ type: "ending", source: requesterId });
    api.pastOneOnOnes
      .create(
        {
          endedOn: dateWithoutMilliseconds(new Date()),
          endedById: requesterId,
          participantsId: [requesterId, requestedId],
        },
        [
          { name: "endedBy", resource: "user" },
          { name: "participants", resource: "user" },
        ],
      )
      .then(
        ({ data: pastOneOnOne }) => {
          refreshCacheAfterEnd();
          history.push(`/1-on-1/${pastOneOnOne.id}`);
        },
        () => {
          errorToast("We couldn't end you 1-on-1. Contact the team.");
          setIsSubmitting(false);
        },
      );
    setIsSubmitting(true);
  }

  return (
    <Inline className="w-full mt-5" p={[0, 0, 3, 0]} distribution="between">
      <BodyBaseline color={Color.Neutral600}>
        Draft Saved: {formattedSavedAt}
      </BodyBaseline>
      <PrimaryButton
        disabled={!!disabled || isSubmitting}
        text={isSubmitting ? "Ending..." : "End 1-on-1"}
        onClick={endOneOnOne}
      />
    </Inline>
  );
}

export default OneOnOneFormFooter;
