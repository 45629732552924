import React from "react";
import { Text, noop } from "@ableco/baseline";
import { NavLink } from "react-router-dom";

// This was copied from the core-client repo to facilitate the migration of core-client components.
// It was a link wrapper would implement link prefetching with Next.js.
// We removed the next js prefetching and kept the rest.
export default function CoreNavlink({
  href,
  prefetch,
  prepare = noop,
  onMouseEnter = noop,
  disabled = false,
  isExternalLink,
  isActive,
  activeClassName,
  ...props
}) {
  function handleMouseEnter(event) {
    const persistedEvent = event.persist();
    prepare(persistedEvent);
    onMouseEnter(persistedEvent);
  }

  return isExternalLink ? (
    <Text as="a" href={href} onMouseEnter={handleMouseEnter} {...props} />
  ) : (
    <NavLink
      to={href}
      onMouseEnter={handleMouseEnter}
      onClick={(e) => disabled && e.preventDefault()}
      isActive={isActive}
      activeClassName={activeClassName}
      {...props}
    />
  );
}
