import React from "react";
import {
  Inline,
  Stack,
  Text,
  Color,
  Frame,
  Flex,
  useOnClickOutside,
  Touchable,
  noop,
  Skeleton,
  TextWeight,
} from "@ableco/baseline";
import {
  CaretLeftOutline,
  CaretDownOutline,
  CheckCircleOutline,
} from "@baseline/icons";
import Accordion from "../../components/accordion";
import { useResource, api, ClientError } from "coreql";
import CoreLink from "../core-link";
import Menu, { MenuItem } from "../layout/menu";
import { KeyResultContent } from "../key-result-status-indicator";
import { wrap } from "../weekly-report/utils";
import {
  addDays,
  format,
  addQuarters,
  setDay,
  compareDesc,
  addWeeks,
} from "date-fns";
import {
  Heading,
  Title,
  TitleSemibold,
} from "../../components/design-system/typography-components";
import { MarkdownEditor } from "../markdown/markdown-editor";
import { MarkdownViewer } from "../markdown/markdown-viewer";
import BackButton from "../layout/back-button";
import { PrimaryButton } from "../buttons/buttons";
import { useCoreToast } from "../toast/toast";
import { BodyBaseline } from "../design-system/typography-components";
import PageLayout from "../layout/page-layout";
import isKeyResultComplete from "../../utils/is-key-result-complete";
import AutosaveTextInput, {
  LoadingStateIcon,
} from "../data-entry/autosave-text-input";
import checkEmptyMDText from "../../utils/markdown/check-empty-text";
import useAutoFocusEnd from "../../hooks/use-autofocus-end";
import { CoreTooltip } from "../core-tooltip";
import { AvatarXS } from "../design-system/avatar";

function ObjectiveDetailDropdown({
  items,
  onSelect = noop,
  defaultItem = null,
  id,
}) {
  const $ref = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(defaultItem);
  const closeDropdown = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  if (defaultItem && !items.some((e) => e.value === defaultItem.toString())) {
    items.unshift({ id: -1, value: defaultItem });
  }

  useOnClickOutside($ref, closeDropdown);
  return (
    <Frame className="relative" innerRef={$ref}>
      <Inline>
        <Touchable
          className="focus:outline-none border-solid border rounded w-2/5 h-10 px-3  border-neutral-400"
          onClick={() => setIsOpen((current) => !current)}
          id={id}
        >
          <Flex distribution="between">
            <Text
              color={value ? "neutral-700" : "neutral-600"}
              className="hover:text-neutral-700"
            >
              {value ? value : "Select from 0 to 10"}
            </Text>
            <Text>
              <CaretDownOutline className="ml-2 w-4 h-4 inline" />
            </Text>
          </Flex>
        </Touchable>
      </Inline>

      {isOpen && (
        <Menu className="absolute select-none rounded z-10 top-1" width="w-1/5">
          {items.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                setValue(option.value);
                onSelect(option);
                closeDropdown();
              }}
              textWeight={option.value == value ? "medium" : "normal"}
            >
              {option.value}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Frame>
  );
}

function useObjectiveRequest(objectiveId) {
  const { data: objective, mutate } = useResource(
    "objectives",
    objectiveId,
    {
      included: [
        "owner",
        "department",
        "currentStatusUpdate",
        "keyResults",
        "objectiveStatusUpdates",
        "keyResults.currentStatusUpdate",
      ],
      fields: {
        objectives: [
          "id",
          "title",
          "scoringCriteria",
          "plan",
          "score",
          "evidences",
          "successes",
          "challenges",
          "learnings",
          "owner",
          "department",
          "quarter",
          "currentStatusUpdate",
          "keyResults",
          "objectiveStatusUpdates",
          "closedAt",
          "updatedAt",
          "createdAt",
          "parentObjectiveId",
        ],
        department: ["id", "title"],
        feedbackMessages: [
          "id",
          "body",
          "updatedAt",
          "submittedAt",
          "promptAnswer",
        ],
        objectiveStatusUpdate: ["id", "status"],
        keyResults: [
          "id",
          "title",
          "dueDate",
          "start",
          "target",
          "kind",
          "currentStatusUpdate",
          "decreasing",
        ],
        users: ["fullName", "avatarUrl", "id", "preferredName"],
      },
    },
    "denormalized",
  );

  if (objective === null) {
    throw new ClientError("Unauthorized", { error: "Unauthorized" });
  }

  const parentObjectives = useParentObjectivesRequest(objective, []);

  return [objective, mutate, parentObjectives.reverse()];
}

function useParentObjectivesRequest(objective, array) {
  if (objective.parentObjectiveId) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useParentObjectiveDataRequest(objective.parentObjectiveId, array);
  }

  return array;
}

function useParentObjectiveDataRequest(parentObjectiveId, array) {
  const { data } = useResource(
    "objectives",
    parentObjectiveId,
    {
      fields: {
        objectives: ["id", "title", "parentObjectiveId"],
      },
    },
    "denormalized",
  );

  return useParentObjectivesRequest(data, [...array, data]);
}

function MarkdownText({ text }) {
  return (
    <Frame className="text-neutral-700">
      <MarkdownViewer value={text} style={{ width: "768px" }} />
    </Frame>
  );
}

function ObjectiveDetailScoringText({ label, text }) {
  return (
    <Stack space={2}>
      <TitleSemibold color={Color.Neutral800}>{label}</TitleSemibold>
      <MarkdownText text={text} />
    </Stack>
  );
}

async function editObjective(objectiveId, setIsClosed, errorToast) {
  try {
    // eslint-disable-next-line unicorn/no-array-callback-reference
    await api.objectives.find(objectiveId).update({ closedAt: null });
    setIsClosed(false);
  } catch {
    errorToast(
      "We had a problem editing your objective, please contact the team.",
    );
  }
}

function ObjectiveFooter({ closedAt, updatedAt, children }) {
  const footerDate = format(
    new Date(closedAt || updatedAt),
    "MMMM dd, yyyy, hh:mm a",
  );
  return (
    <Inline distribution="between" alignment="center">
      <BodyBaseline color={Color.Neutral600}>
        {!!closedAt ? `Closed on: ` : `Draft saved: `}
        {footerDate}
      </BodyBaseline>
      {children}
    </Inline>
  );
}

function ObjectiveStatusReadOnly({ objectiveId, setIsClosed, errorToast }) {
  const [request] = useObjectiveRequest(objectiveId);

  return (
    <Stack space={6}>
      <Stack space={6} p={[0, 6]}>
        <ObjectiveDetailScoringText
          label="Scoring Evidence"
          text={request.evidences}
        />
        <ObjectiveDetailScoringText
          label="Successes"
          text={request.successes}
        />
        <ObjectiveDetailScoringText
          label="Challenges"
          text={request.challenges}
        />
        <ObjectiveDetailScoringText
          label="Learnings & Recommended Next Steps"
          text={request.learnings}
        />
      </Stack>
      <Frame
        className="-ml-8 -mr-20 h-20 border-t border-solid border-neutral-300"
        p={[5, 10, 0, 10]}
      >
        <ObjectiveFooter
          closedAt={request?.closedAt}
          updatedAt={request?.updatedAt}
        >
          <PrimaryButton
            text="Edit"
            onClick={async () =>
              await editObjective(objectiveId, setIsClosed, errorToast)
            }
          />
        </ObjectiveFooter>
      </Frame>
    </Stack>
  );
}

function ObjectiveDetailTextInput({
  objectiveId,
  onSave,
  label,
  setValue,
  value,
}) {
  const [showMarkdown, setShowMarkdown] = React.useState(() =>
    checkEmptyMDText(value || ""),
  );
  const editorRef = React.createRef();

  function hideMarkdownViewer() {
    return setShowMarkdown(false);
  }

  async function handleObjectivesBlur() {
    setShowMarkdown(checkEmptyMDText(value));
  }

  useAutoFocusEnd(showMarkdown, value, editorRef);

  return (
    <ObjectiveDetailFormLabel label={label}>
      <AutosaveTextInput
        resourceId={objectiveId}
        value={value}
        onSave={(_, text) => onSave(text)}
        onChange={(text) => setValue(text)}
        getSaveStatus={LoadingStateIcon}
      >
        {(text, autoSaveProps, status) =>
          !showMarkdown ? (
            <Frame className="relative">
              <MarkdownEditor
                id={`${label.toLowerCase().split(" ").join("-")}-id`}
                className="rounded py-2 px-4 text-neutral-700"
                // Remove this min-height when baseline fix this bug https://github.com/ableco/baseline/issues/670
                style={{ minHeight: "5.5rem" }}
                type="multiline"
                border="neutral-400"
                autosize={88}
                text={text}
                popupRigth={250}
                onBlur={handleObjectivesBlur}
                onRef={(ref) => (editorRef.current = ref.current)}
                placeholder={`Add ${label}`}
                autofocus
                {...autoSaveProps}
              />
              <Frame className="absolute left-100 bottom-0 mb-2 ml-2">
                {status}
              </Frame>
            </Frame>
          ) : (
            <MarkdownViewer
              className="hover:bg-neutral-200 hover:cursor-pointer p-2 -mx-2 transition-colors text-neutral-700 duration-300 ease-in-out"
              value={text}
              onClick={hideMarkdownViewer}
            />
          )
        }
      </AutosaveTextInput>
    </ObjectiveDetailFormLabel>
  );
}

function isFieldEmpty(value) {
  return value === null || value.trim() === "";
}

function ObjectiveStatusForm({ objectiveId, setIsClosed }) {
  const [request, mutate] = useObjectiveRequest(objectiveId);

  const [score, setScore] = React.useState(request.score);
  const [evidences, setEvidences] = React.useState(request.evidences || "");
  const [successes, setSuccesses] = React.useState(request.successes || "");
  const [challenges, setChallenges] = React.useState(request.challenges || "");
  const [learnings, setLearnings] = React.useState(request.learnings || "");

  async function updateObjective(data) {
    const {
      data: { id },
    } =
      // eslint-disable-next-line unicorn/no-array-callback-reference
      await api.objectives.find(request.id).update(data);

    mutate();

    return id;
  }

  function handleScoreChange(item) {
    setScore(item.value);
    updateObjective({ score: item.value });
  }

  function handleObjectiveClose(event) {
    event.preventDefault();
    setIsClosed(true);
    updateObjective({
      closedAt: new Date(),
    });
  }

  const uncomplete =
    score === null ||
    isFieldEmpty(evidences) ||
    isFieldEmpty(successes) ||
    isFieldEmpty(challenges) ||
    isFieldEmpty(learnings);

  return (
    <Stack space={6}>
      <Stack space={6} p={[0, 6]}>
        <ObjectiveDetailFormLabel label="Score" weight={TextWeight.SemiBold}>
          <ObjectiveDetailDropdown
            id="score-id"
            items={[...Array.from({ length: 11 }).keys()].map((item) => ({
              value: item.toString(),
            }))}
            placeholder="Select from 0 to 10"
            defaultItem={score}
            onSelect={handleScoreChange}
          />
        </ObjectiveDetailFormLabel>
        <ObjectiveDetailTextInput
          objectiveId={request.id}
          onSave={(text) => updateObjective({ evidences: text })}
          label="Scoring Evidence"
          setValue={setEvidences}
          value={evidences}
        />
        <ObjectiveDetailTextInput
          objectiveId={request.id}
          onSave={(text) => updateObjective({ successes: text })}
          label="Successes"
          setValue={setSuccesses}
          value={successes}
        />
        <ObjectiveDetailTextInput
          objectiveId={request.id}
          onSave={(text) => updateObjective({ challenges: text })}
          label="Challenges"
          setValue={setChallenges}
          value={challenges}
        />
        <ObjectiveDetailTextInput
          objectiveId={request.id}
          onSave={(text) => updateObjective({ learnings: text })}
          label="Learnings & Recommended Next Steps"
          setValue={setLearnings}
          value={learnings}
        />
      </Stack>
      <Frame
        className="-ml-8 -mr-20 h-20 border-t border-solid border-neutral-300"
        p={[5, 10, 0, 10]}
      >
        <ObjectiveFooter
          closedAt={request?.closedAt}
          updatedAt={request?.updatedAt}
        >
          <PrimaryButton
            text="Close Objective"
            onClick={handleObjectiveClose}
            disabled={uncomplete}
          />
        </ObjectiveFooter>
      </Frame>
    </Stack>
  );
}

function ObjectiveDetailFormLabel({ children, label, ...props }) {
  return (
    <Stack space={2}>
      <BodyBaseline
        color={Color.Neutral800}
        as="label"
        htmlFor={`${label.toLowerCase().split(" ").join("-")}-id`}
        {...props}
      >
        {label}
      </BodyBaseline>
      <Frame>{children}</Frame>
    </Stack>
  );
}

function ObjectiveDetailSubtitle({ owner, department }) {
  return (
    <Flex className="w-full" direction="vertical">
      <Inline space={2}>
        <AvatarXS url={owner.avatarUrl} name={owner.fullName} />
        <Title color={Color.Neutral700}>
          {owner.fullName} | {department.title}
        </Title>
      </Inline>
    </Flex>
  );
}

const ON_TRACK = "on_track";
const BEHIND = "behind";
const AT_RISK = "at_risk";
const CLOSED = "closed";
const DEFAULT = "default";
const SCORED = "scored";
const objectiveStatus = {
  [ON_TRACK]: { text: "On track", title: "Status", color: "success" },
  [BEHIND]: { text: "Behind", title: "Status", color: "warning" },
  [AT_RISK]: { text: "At risk", title: "Status", color: "alert" },
  [SCORED]: { text: "", title: "Score", color: "neutral-400" },
  [CLOSED]: { text: "Closed", color: "neutral-500" },
  [DEFAULT]: { text: "", color: "neutral-500" },
};
function ObjectiveStatusCircle({ status, score }) {
  objectiveStatus[SCORED].text = status === "scored" ? score.toString() : "";
  if (status === "scored") {
    if (score >= 0 && score <= 3) {
      objectiveStatus[status].color = "alert";
    } else if (score >= 4 && score <= 6) {
      objectiveStatus[status].color = "warning";
    } else {
      objectiveStatus[status].color = "success";
    }
  }
  return (
    <Flex
      direction="vertical"
      alignment="center"
      distribution="center"
      className="border rounded-full self-start h-16 w-16 flex-shrink-0"
      border={objectiveStatus[status].color}
    >
      <Text size="xs" color="neutral-700" className="h-4">
        {objectiveStatus[status].title}
      </Text>
      <Text
        size={status === "scored" ? "2xl" : "xs"}
        weight={status === "scored" ? "extrabold" : "bold"}
        className="text-center"
      >
        {objectiveStatus[status].text}
      </Text>
    </Flex>
  );
}

function ObjectiveKeyResultInline({
  status,
  target,
  title,
  dueDate,
  kind,
  start,
  decreasing,
}) {
  return (
    <Flex className="items-start space-x-2">
      {isKeyResultComplete(
        decreasing,
        status ? status.status : start,
        target,
      ) ? (
        <Text color="success" className="my-1">
          <CheckCircleOutline className="h-4 w-4" />
        </Text>
      ) : (
        <Frame className="my-1 h-4 w-4 border-solid border-neutral-500 border rounded-full flex-shrink-0" />
      )}

      <BodyBaseline color={Color.Neutral700} className="w-full">
        {title}
      </BodyBaseline>
      <Text color="neutral-700" className="w-40 text-left">
        by{" "}
        {new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
          timeZone: "UTC",
        }).format(new Date(dueDate))}
      </Text>
      <Text color="neutral-700" className="w-48 text-right">
        <KeyResultContent
          kind={kind}
          status={status ? status.status : start}
          target={target}
          decreasing={decreasing}
        />
      </Text>
    </Flex>
  );
}

function ObjectiveDetailDescriptionTitle({ title, children }) {
  return (
    <div>
      <Frame className="w-full mb-6">
        <Heading color={Color.Neutral800}>{title}</Heading>
      </Frame>
      {children && <Frame p={[0, 6]}>{children}</Frame>}
    </div>
  );
}

function getNextFriday(date) {
  return addDays(date, ((5 + 7 - date.getDay()) % 7) + 7);
}

function getAllFridaysInQuarter(quarter) {
  const quarterDate = new Date(quarter.split("-"));
  const nextQuarter = addQuarters(quarterDate, 1);
  let fridayIterator = getNextFriday(quarterDate);
  const fridaysInQuarter = [];
  while (compareDesc(fridayIterator, nextQuarter) === 1) {
    fridaysInQuarter.push(fridayIterator);
    fridayIterator = addWeeks(fridayIterator, 1);
  }
  return fridaysInQuarter;
}

function insertLineBetweenStatus(statusTimelineData) {
  const timelineComponents = [];
  for (const [index, value] of Object.values(statusTimelineData).entries()) {
    if (index !== 0) {
      timelineComponents.push({ content: "line", status: "default" });
    }
    timelineComponents.push({ ...value });
  }
  return timelineComponents;
}

function ObjectiveDetailStatusTimeline({
  quarter,
  objectiveStatusUpdates,
  isClosed,
}) {
  const statusTimelineData = {};
  const fridaysInQuarter = getAllFridaysInQuarter(quarter);
  for (const [index, fridayIterator] of fridaysInQuarter.entries()) {
    const currentMonday = setDay(fridayIterator, 1);
    statusTimelineData[format(currentMonday, "yyyy-MM-dd")] = {
      content: `Week ${index + 1} (${format(currentMonday, "MMM d")} - ${format(
        addDays(fridayIterator, 2),
        "MMM d",
      )})`,
      status: isClosed ? "closed" : "default",
    };
  }
  for (const objectiveStatusUpdate of wrap(objectiveStatusUpdates)) {
    const statusBullet = statusTimelineData[objectiveStatusUpdate.periodStart];
    if (statusBullet) statusBullet.status = objectiveStatusUpdate.status;
  }
  const timelineComponents = insertLineBetweenStatus(statusTimelineData);
  return (
    <Flex className="h-full" alignment="center" distribution="between">
      {timelineComponents.map((statusUpdate, index) => {
        const content = statusUpdate.content;
        if (content === "line")
          return (
            <Frame
              style={{
                borderRadius: "1px",
                height: "2px",
                width: "44px",
              }}
              bg="neutral-400"
              key={index}
            />
          );
        const status = statusUpdate.status;
        return (
          <CoreTooltip
            key={index}
            label={
              (status !== DEFAULT ? objectiveStatus[status].text + " - " : "") +
              content
            }
          >
            <Frame
              className="h-4 w-4 rounded-full flex-shrink-0"
              border={objectiveStatus[status].color}
              bg={objectiveStatus[status].color}
              data-testid={`timeline:marker:${index}`}
            />
          </CoreTooltip>
        );
      })}
    </Flex>
  );
}

function LastChildObjectiveItem({ objective }) {
  return (
    <Frame className="py-1 w-full pl-6 overflow-hidden group hover:bg-neutral-100 rounded transition-colors duration-300 ease-in-out">
      <Inline>
        <BodyBaseline color={Color.Neutral700}>{objective.title}</BodyBaseline>
      </Inline>
    </Frame>
  );
}

function ParentObjectiveAccordionContent({
  parentObjectives,
  objective,
  isOpen,
}) {
  return (
    <Frame>
      <ParentObjectiveAccordion
        parentObjectives={parentObjectives}
        objective={objective}
        isOpen={isOpen}
      />
    </Frame>
  );
}

function ParentObjectiveAccordion({ parentObjectives, objective, isOpen }) {
  if (parentObjectives.length > 0) {
    const parentObjective = parentObjectives[0];
    return (
      <Accordion
        title={parentObjective.title}
        variant="objectiveAccordion"
        state="none"
        classname={`w-full mb-2 outline-none overflow-hidden group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out`}
        isOpen={isOpen}
        p={[0, 0]}
        arrowIconClassName="h-5 pt-1 mr-2"
      >
        <ParentObjectiveAccordionContent
          parentObjectives={parentObjectives.slice(1)}
          objective={objective}
          isOpen={isOpen}
        />
      </Accordion>
    );
  }

  return <LastChildObjectiveItem objective={objective} />;
}

export default function ObjectiveDetailPage({ objectiveId }) {
  const [request, _, parentObjectives] = useObjectiveRequest(objectiveId);
  const { errorToast } = useCoreToast();
  const [isClosed, setIsClosed] = React.useState(Boolean(request?.closedAt));

  const objectiveSubtitle = (
    <ObjectiveDetailSubtitle
      owner={request.owner}
      department={request.department}
    />
  );

  const headerStatusCircle = (request.currentStatusUpdate ||
    request.closedAt) && (
    <ObjectiveStatusCircle
      status={request.closedAt ? "scored" : request.currentStatusUpdate.status}
      score={request.score}
    />
  );
  return (
    <PageLayout
      title={request.title}
      subTitle={objectiveSubtitle}
      backButton={<BackButton text="Objectives" fallbackHref="/objectives" />}
      customCircle={headerStatusCircle}
    >
      <Frame p={[0, 12, 0, 0]}>
        <Stack space={10}>
          <ObjectiveDetailDescriptionTitle title="Key Results">
            <Stack space={4}>
              {wrap(request.keyResults)
                .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
                .map(
                  ({
                    id,
                    currentStatusUpdate,
                    target,
                    title,
                    dueDate,
                    kind,
                    start,
                    decreasing,
                  }) => (
                    <ObjectiveKeyResultInline
                      key={id}
                      status={currentStatusUpdate}
                      target={target}
                      title={title}
                      dueDate={dueDate}
                      kind={kind}
                      start={start}
                      decreasing={decreasing}
                    />
                  ),
                )}
            </Stack>
          </ObjectiveDetailDescriptionTitle>
          {parentObjectives.length > 0 ? (
            <ObjectiveDetailDescriptionTitle title="Parent Objective">
              <ParentObjectiveAccordion
                parentObjectives={parentObjectives}
                objective={request}
                isOpen={true}
              />
            </ObjectiveDetailDescriptionTitle>
          ) : null}
          {request.scoringCriteria ? (
            <ObjectiveDetailDescriptionTitle title="Scoring Criteria">
              <MarkdownText text={request.scoringCriteria} />
            </ObjectiveDetailDescriptionTitle>
          ) : null}

          {request.plan ? (
            <ObjectiveDetailDescriptionTitle title="Plan to Achieve Key Results">
              <MarkdownText text={request.plan} />
            </ObjectiveDetailDescriptionTitle>
          ) : null}

          <ObjectiveDetailDescriptionTitle title="Status Timeline">
            <ObjectiveDetailStatusTimeline
              isClosed={isClosed}
              objectiveStatusUpdates={request.objectiveStatusUpdates}
              quarter={request.quarter}
            />
          </ObjectiveDetailDescriptionTitle>
          <ObjectiveDetailDescriptionTitle title="OKR Scoring" />
        </Stack>
        {isClosed ? (
          <ObjectiveStatusReadOnly
            objectiveId={objectiveId}
            setIsClosed={setIsClosed}
            errorToast={errorToast}
          />
        ) : (
          <ObjectiveStatusForm
            objectiveId={objectiveId}
            setIsClosed={setIsClosed}
            errorToast={errorToast}
          />
        )}
      </Frame>
    </PageLayout>
  );
}

export function Fallback() {
  return (
    <Frame>
      <Frame p={[0, 10, 2, 10]}>
        <CoreLink
          href={`/objectives`}
          className="absolute cursor-pointer z-10 hover:no-underline"
          style={{
            top: 20,
            left: 240,
          }}
        >
          <Flex alignment="center">
            <Text color="neutral-700">
              <CaretLeftOutline className="w-6 h-6" color="neutral-200" />
            </Text>
            <Text color="neutral-600" size="sm">
              Objectives
            </Text>
          </Flex>
        </CoreLink>
        <Inline space={4}>
          <Skeleton
            color="neutral-300"
            corners="full"
            size={48}
            alt="Objective status"
          />
          <Flex className="w-full" direction="vertical">
            <Skeleton
              color="neutral-300 mt-4 mb-2"
              corners="full"
              height={28}
              width="100%"
              alt="Objective title"
            />
            <Inline space={2}>
              <Skeleton
                color="neutral-300"
                corners="full"
                size={24}
                alt="User avatar"
              />
              <Skeleton
                color="neutral-300"
                corners="full"
                height={28}
                width="400px"
                alt="Objective owner and departmente"
              />
            </Inline>
          </Flex>
        </Inline>
        <Frame
          className="w-full border-b h-24 border-neutral-300"
          p={[12, 0, 0, 0]}
        >
          <Skeleton
            color="neutral-300"
            corners="full"
            height={28}
            width="350px"
            alt="Description title"
          />
        </Frame>
        <Frame p={[0, 0, 0, 6]}>
          <Frame p={[6, 0, 2, 0]}>
            <Skeleton
              color="neutral-300"
              corners="full"
              height={28}
              width="100%"
              alt="Description detail"
            />
          </Frame>
        </Frame>
      </Frame>
    </Frame>
  );
}
