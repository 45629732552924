// Modules
import React from "react";
// Components
import Rows from "utilization/components/Rows";
// Styles
import Styles from "utilization/components/Table/Table.module.scss";
import { LabelText } from "../LabelRows/LabelText";

function ProductLabelRows({ product }) {
  return (
    <>
      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>{product.name}</LabelText>
        </div>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
      </div>
      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Revenue</LabelText>
        </div>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
      </div>
      <Rows.Labels filter="revenue.estimated" product={product} />
      <Rows.Labels filter="revenue.actual" product={product} />
      <Rows.Labels filter="revenue.dev_est_act" product={product} />
      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Headcount</LabelText>
        </div>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
      </div>
      <Rows.Labels filter="headcount.budgeted" product={product} />
      <Rows.Labels filter="headcount.estimated" product={product} />
      <Rows.Labels filter="headcount.actual" product={product} />
      <Rows.Labels filter="headcount.dev_bud_est" product={product} />
      <Rows.Labels filter="headcount.dev_bud_act" product={product} />
      <Rows.Labels filter="headcount.dev_est_act" product={product} />
      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Person Hours</LabelText>
        </div>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
      </div>
      <Rows.Labels filter="hours.budgeted" product={product} />
      <Rows.Labels filter="hours.estimated" product={product} />
      <Rows.Labels filter="hours.actual" product={product} />
      <Rows.Labels filter="hours.dev_bud_est" product={product} />
      <Rows.Labels filter="hours.dev_bud_act" product={product} />
      <Rows.Labels filter="hours.dev_est_act" product={product} />
      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Labor Cost</LabelText>
        </div>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
      </div>
      <Rows.Labels filter="cost.budgeted" product={product} />
      <Rows.Labels filter="cost.estimated" product={product} />
      <Rows.Labels filter="cost.actual" product={product} />
      <Rows.Labels filter="cost.dev_bud_est" product={product} />
      <Rows.Labels filter="cost.dev_bud_act" product={product} />
      <Rows.Labels filter="cost.dev_est_act" product={product} />
      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Gross Margin</LabelText>
        </div>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
      </div>
      <Rows.Labels filter="gross.estimated" product={product} />
      <Rows.Labels filter="gross.actual" product={product} />
      <Rows.Labels filter="gross.dev_est_act" product={product} />
    </>
  );
}

export default ProductLabelRows;
