import React from "react";
import LinkifyIt from "linkify-it";
import replaceEmojis from "./replace-emojis";
import ReactDOMServer from "react-dom/server";
import ReactHtmlParser from "react-html-parser";
import { wrap } from "../components/weekly-report/utils";

const linkify = new LinkifyIt();

function matcher(text) {
  return linkify.match(text);
}

function defaultComponentWrapper(href, text, key) {
  return (
    <a href={href} key={key} className="break-all">
      {text}
    </a>
  );
}
function getStringLinkifyEmoji(linkifyedText, componentWrapper) {
  // eslint-disable-next-line unicorn/no-reduce
  return linkifyedText.reduce((acc, cur, idx) => {
    if (typeof cur === "string") {
      return acc + cur;
    } else {
      const { href, children } = cur.props;
      const formattedLink = ReactDOMServer.renderToStaticMarkup(
        componentWrapper(href, children, idx),
      );
      return acc + formattedLink;
    }
  }, "");
}

export function LinkifyEmojis({
  showEmojis,
  emojis,
  value,
  componentWrapper = defaultComponentWrapper,
}) {
  const children = showEmojis ? replaceEmojis(emojis, value) : value;
  const {
    props: { children: linkifyedText },
  } = Linkify({ children });

  return (
    <span key={value}>
      {ReactHtmlParser(
        getStringLinkifyEmoji(wrap(linkifyedText), componentWrapper),
      )}
    </span>
  );
}

function Linkify({ children, componentWrapper = defaultComponentWrapper }) {
  function parseString(string) {
    if (string === "") return string;

    const matches = matcher(string);
    if (!matches) return string;

    const elements = [];
    let lastIndex = 0;
    for (const [i, match] of matches.entries()) {
      if (match.index > lastIndex) {
        elements.push(string.slice(lastIndex, match.index));
      }

      const wrappedMatch = componentWrapper(match.url, match.text, i);
      elements.push(wrappedMatch);

      lastIndex = match.lastIndex;
    }
    if (string.length > lastIndex) {
      elements.push(string.slice(lastIndex));
    }

    return elements.length === 1 ? elements[0] : elements;
  }

  function parse(children, key = 0) {
    if (typeof children === "string") {
      return parseString(children);
    } else if (
      React.isValidElement(children) &&
      children.type !== "a" &&
      children.type !== "button"
    ) {
      return React.cloneElement(
        children,
        { key: key },
        parse(children.props.children),
      );
    } else if (Array.isArray(children)) {
      return children.map((child, i) => parse(child, i));
    }

    return children;
  }

  return <>{parse(children)}</>;
}

export default Linkify;
