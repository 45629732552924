import React from "react";
import styled from "styled-components";

const SectionTitle = styled.h3`
  font-weight: $font-bold;
  color: #3d5186;
  font-size: 20px;
  line-height: 26px;
`;

export default (props) => <SectionTitle {...props} />;
