import React, { useMemo } from "react";
import {
  Calendar,
  DoubleTrackPreviousOutline,
  DoubleTrackNextOutline,
  CaretLeftOutline,
  CaretRightOutline,
} from "@baseline/icons";
import { Flex, Inline, Text, Touchable } from "@ableco/baseline";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import { BodySmall } from "../../design-system/typography-components";
import { isFunction } from "lodash";
import "./daterange-picker.css";

function Header(props) {
  const {
    date,
    decreaseYear,
    decreaseMonth,
    increaseYear,
    increaseMonth,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  } = props;
  return (
    <Inline className="w-full" p={[2, 3]} distribution="between">
      <Inline>
        <Touchable
          type="button"
          onClick={decreaseYear}
          disabled={prevYearButtonDisabled}
        >
          <DoubleTrackPreviousOutline className="w-4 h-4 opacity-50" />
        </Touchable>
        <Touchable
          type="button"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <CaretLeftOutline className="w-4 h-4 opacity-50" />
        </Touchable>
      </Inline>
      <BodySmall>{format(date, "MMM yyyy")}</BodySmall>
      <Inline>
        <Touchable
          type="button"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <CaretRightOutline className="w-4 h-4 opacity-50" />
        </Touchable>
        <Touchable
          type="button"
          onClick={increaseYear}
          disabled={nextYearButtonDisabled}
        >
          <DoubleTrackNextOutline className="w-4 h-4 opacity-50" />
        </Touchable>
      </Inline>
    </Inline>
  );
}

/**
 *
 * @typedef {object} CoreDatePickerRenderProps
 * @property {(visible: boolean)=> void} toggleCalendar
 * @property {(date: Date)=> void} forwardToDate
 */

/**
 *
 * @param {import("react-datepicker").ReactDatePickerProps & {children: (actions: CoreDatePickerRenderProps)=>React.ReactNode}} props
 */
export function CoreDatePicker({ children, ...props }) {
  const $ref = React.useRef();

  const actions = useMemo(() => {
    function getCalendarPopover() {
      return $ref.current;
    }

    function toggleCalendar(visible) {
      return getCalendarPopover().setOpen(visible);
    }

    function forwardToDate(date) {
      const popup = getCalendarPopover();
      return popup.calendar.instanceRef.setState({ date });
    }
    return { toggleCalendar, forwardToDate };
  }, [$ref]);
  return (
    <Flex
      className="w-full text-neutral-800 border rounded border-neutral-400 hover:border-primary-light focus-within:shadow-outline focus-within:border-primary-base transition-all duration-300 ease-in-out core-date-picker"
      p={[2, 3]}
      direction="horizontal"
      alignment="center"
      distribution="between"
    >
      <ReactDatePicker
        ref={(calendar) => ($ref.current = calendar)}
        renderCustomHeader={Header}
        placeholderText="Select date"
        popperPlacement="bottom-start"
        popperModifiers={{
          arrow: {
            enabled: true,
            /* for some reason this fixes arrow position */
            fn: (arrow) => arrow,
          },
          offset: {
            enabled: true,
            offset: "-12px, 2px",
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: "viewport",
          },
        }}
        dateFormat="dd-MMM-yyyy"
        {...props}
      >
        {isFunction(children) && <div>{children(actions)}</div>}
      </ReactDatePicker>
      <Text color="neutral-500">
        <Calendar className="w-6 h-6" />
      </Text>
    </Flex>
  );
}
