import React from "react";
import { Field } from "formik";
import FormDropdown from "../../components/form-dropdown";
import useSWR from "swr";
import { network } from "../../services/network";

function format(items) {
  return items.map((item) => ({
    value: item.id,
    content: item.attributes.fullName,
    meta: item,
  }));
}

function alphabetically(a, b) {
  const previous = a.attributes.fullName.toLowerCase().trim();
  const next = b.attributes.fullName.toLowerCase().trim();
  if (previous < next) return -1;
  if (previous > next) return 1;
  return 0;
}

function UserDropdown({ fieldName, label, showOnlyProjectResponsibles }) {
  const filter =
    showOnlyProjectResponsibles === true
      ? "users?filter[projectResponsible]"
      : "users";

  const {
    data: { data: users },
  } = useSWR(filter, network, { suspense: true });

  return (
    <Field
      name={fieldName}
      component={FormDropdown}
      options={format(users.sort(alphabetically))}
      label={label}
    />
  );
}

export default UserDropdown;
