// Modules
import { createSelector } from "reselect";
// Selectors
import * as RevenueSelectors from "./revenueSelectors";

const getProductIds = (_state, products) =>
  products.map((product) => product.id);

const getPeriods = (state) => state.periods;

const getProductRevenues = (state) => state.entities.productRevenues;

export const getEstimatedRevenue = createSelector(
  [getProductIds, getPeriods, getProductRevenues],
  RevenueSelectors.allEstimated,
);

export const getActualRevenue = createSelector(
  [getProductIds, getPeriods, getProductRevenues],
  RevenueSelectors.allActual,
);
