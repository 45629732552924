// Modules
import { createSelector } from "reselect";
// Selectors
import * as CostSelectors from "utilization/selectors/costSelectors";
import * as HeadcountSelectors from "utilization/selectors/headcountSelectors";
import * as HoursSelectors from "utilization/selectors/hoursSelectors";

const getProductIds = (_state, products) =>
  products.map((product) => product.id);

const getPeriods = (state) => state.periods;

const getRoles = (state) => state.entities.roles;

const getUsers = (state) => state.entities.users;

const getProductInvestments = (state) => state.entities.productInvestments;

const getUserComparator = () => (arrVal, othVal) =>
  arrVal.userId === othVal.userId;

export const getActualCost = createSelector(
  [getProductIds, getPeriods, getProductInvestments],
  CostSelectors.all,
);

export const getActualCostByRole = createSelector(
  [getRoles, getProductIds, getPeriods, getProductInvestments],
  CostSelectors.byRole,
);

export const getActualCostByUser = createSelector(
  [getUsers, getProductIds, getPeriods, getProductInvestments],
  CostSelectors.byUser,
);

export const getActualHeadcount = createSelector(
  [getProductIds, getPeriods, getProductInvestments, getUserComparator],
  HeadcountSelectors.all,
);

export const getActualHeadcountByRole = createSelector(
  [
    getRoles,
    getProductIds,
    getPeriods,
    getProductInvestments,
    getUserComparator,
  ],
  HeadcountSelectors.byRole,
);

export const getActualHeadcountByUser = createSelector(
  [
    getUsers,
    getProductIds,
    getPeriods,
    getProductInvestments,
    getUserComparator,
  ],
  HeadcountSelectors.byUser,
);

export const getActualHours = createSelector(
  [getProductIds, getPeriods, getProductInvestments],
  HoursSelectors.all,
);

export const getActualHoursByRole = createSelector(
  [getRoles, getProductIds, getPeriods, getProductInvestments],
  HoursSelectors.byRole,
);

export const getActualHoursByUser = createSelector(
  [getUsers, getProductIds, getPeriods, getProductInvestments],
  HoursSelectors.byUser,
);
