// Modules
import {
  addWeeks,
  differenceInCalendarWeeks,
  endOfWeek,
  format,
  max,
  min,
  parseISO,
  startOfWeek,
} from "date-fns";
import { compact, flatten, isEqual, sortBy, uniq, values } from "lodash";
import produce from "immer";
// Constants
import ActionTypes from "budget/constants/ActionTypes";
import CoreActionTypes from "core-redux/constants/ActionTypes";

const generateWeeks = (startDate, endDate) => {
  const numWeeks =
    differenceInCalendarWeeks(endDate, startDate, { weekStartsOn: 1 }) + 1;

  return [...new Array(numWeeks)].map((i, x) => {
    const week = addWeeks(startDate, x);
    const periodStart = startOfWeek(week, { weekStartsOn: 1 });

    return periodStart;
  });
};

const getPeriodsFromProjectMilestones = (project) => {
  const {
    discoveryStart,
    discoveryEnd,
    definitionStart,
    definitionEnd,
    developmentStart,
    developmentEnd,
  } = project;
  const dates = compact([
    discoveryStart,
    discoveryEnd,
    definitionStart,
    definitionEnd,
    developmentStart,
    developmentEnd,
  ]);

  if (dates.length === 0) {
    return [];
  }

  const dateArray = dates.map((date) => parseISO(date));
  const earliestDate = min(dateArray);
  const latestDate = max(dateArray);

  return generateWeeks(earliestDate, latestDate);
};

const getPeriodsFromProjectAllocations = (projectAllocations) => {
  if (projectAllocations.length === 0) {
    const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const endOfThisWeek = endOfWeek(new Date(), { weekStartsOn: 1 });
    return generateWeeks(startOfThisWeek, endOfThisWeek);
  }

  projectAllocations = sortBy(projectAllocations, "periodStart");

  const startDate = parseISO(projectAllocations[0].periodStart);
  const endDate = parseISO(
    projectAllocations[projectAllocations.length - 1].periodStart,
  );

  return generateWeeks(startDate, endDate);
};

const getAssigneeKeys = (projectAllocations) =>
  uniq(
    projectAllocations.map(
      (projectAllocation) =>
        `${projectAllocation.assigneeType}.${projectAllocation.assigneeId}`,
    ),
  );

const getProjectAllocationsByAssignee = (assigneeKeys, projectAllocations) =>
  assigneeKeys.reduce((assigneeKeyMap, assigneeKey) => {
    const [assigneeType, assigneeId] = assigneeKey.split(".");
    assigneeKeyMap[assigneeKey] = projectAllocations.filter(
      (projectAllocation) =>
        projectAllocation.assigneeType === assigneeType &&
        projectAllocation.assigneeId === Number.parseInt(assigneeId),
    );
    return assigneeKeyMap;
  }, {});

const generateProjectAllocationRows = (
  placeholders,
  projectRoles,
  users,
  projectAllocationsByAssignee,
  periods,
) =>
  Object.keys(projectAllocationsByAssignee).map((assigneeKey) => {
    const projectAllocations = projectAllocationsByAssignee[assigneeKey];
    const [assigneeType, assigneeId] = assigneeKey.split(".");

    const rowProjectRoles = uniq(
      flatten(
        projectAllocations.map((projectAllocation) =>
          projectAllocation.projectRoleIds.map((projectRoleId) =>
            projectRoles.find(
              (projectRole) => projectRole.id === projectRoleId,
            ),
          ),
        ),
      ),
    );

    let assignee;
    assignee =
      assigneeType === "User"
        ? users.find((user) => user.id === Number.parseInt(assigneeId))
        : placeholders.find(
            (placeholder) => placeholder.id === Number.parseInt(assigneeId),
          );

    const projectRolesCell = {
      className: "project-roles",
      value:
        rowProjectRoles.length > 0
          ? rowProjectRoles.map((projectRole) => projectRole.name).join(", ")
          : null,
    };

    const assigneeCell = {
      className: "assignee",
      value: assignee ? assignee.fullName || assignee.name : null,
    };

    const hoursCells = periods.map((period) => {
      const projectAllocation = projectAllocations.find(
        (projectAllocation) =>
          projectAllocation.assigneeId === assignee.id &&
          projectAllocation.assigneeType === assigneeType &&
          projectAllocation.periodStart === format(period, "yyyy-MM-dd"),
      );

      return {
        ...projectAllocation,
        value: projectAllocation ? projectAllocation.hours : null,
      };
    });

    return [projectRolesCell, assigneeCell, ...hoursCells];
  });

const generateNewAllocationRow = (periods) => [
  { className: "project-roles", value: null },
  { className: "assignee", value: null },
  ...periods.map(() => ({ value: null })),
];

const generateGrid = (state, action) => {
  const placeholders = values(action.entities.placeholders.byId);
  const projectAllocations = values(action.entities.projectAllocations.byId);
  const projectRoles = values(action.entities.projectRoles.byId);
  const users = values(action.entities.users.byId);
  const { projectId } = action;
  const project = action.entities.projects.byId[projectId];

  const periods = sortBy(
    getPeriodsFromProjectMilestones(project)
      .concat(getPeriodsFromProjectAllocations(projectAllocations))
      .filter(
        (date, i, self) =>
          self.findIndex((date2) => date2.getTime() === date.getTime()) === i,
      ),
  );

  const assigneeKeys = getAssigneeKeys(projectAllocations);
  const projectAllocationsByAssignee = getProjectAllocationsByAssignee(
    assigneeKeys,
    projectAllocations,
  );

  const projectAllocationRows = generateProjectAllocationRows(
    placeholders,
    projectRoles,
    users,
    projectAllocationsByAssignee,
    periods,
  );

  const newAllocationRow = generateNewAllocationRow(periods);

  return {
    periods,
    data: [...projectAllocationRows, newAllocationRow],
  };
};

const updateGrid = (state, action) => {
  const data = produce(action.data, (draft) => {
    const lastRowCells = draft[draft.length - 1];
    const lastRowValues = lastRowCells.filter((cell) => cell.value);
    if (lastRowValues.length > 0) {
      draft.push(generateNewAllocationRow(action.periods));
    }
  });

  return {
    ...state,
    data,
  };
};

const updateWithProjectAllocations = (state, action) =>
  produce(state, (draft) => {
    const { projectId } = action;
    const { periods } = state;
    const placeholders = values(action.entities.placeholders.byId);
    const projectAllocations = values(action.entities.projectAllocations.byId);
    const projectRoles = values(action.entities.projectRoles.byId);
    const users = values(action.entities.users.byId);

    for (const [row, cells] of draft.data.entries()) {
      let projectRoleIds = [];
      if (cells[0].value) {
        const projectRoleNames = cells[0].value.split(", ");
        projectRoleIds = projectRoles
          .filter((projectRole) => projectRoleNames.includes(projectRole.name))
          .map((projectRole) => projectRole.id);
      }

      let assignee, assigneeType;
      assignee = users.find((user) => user.fullName === cells[1].value);
      if (assignee) {
        assigneeType = "User";
      } else {
        assignee = placeholders.find(
          (placeholder) => placeholder.name === cells[1].value,
        );
        if (assignee) {
          assigneeType = "Placeholder";
        }
      }

      for (const [col, cell] of cells.entries()) {
        if (col < 2) {
          return true;
        }

        if (projectRoleIds.length > 0 && assignee) {
          const periodStart = format(periods[col - 2], "yyyy-MM-dd");
          const projectAllocation = projectAllocations.find(
            (projectAllocation) =>
              projectAllocation.projectId === projectId &&
              isEqual(projectAllocation.projectRoleIds, projectRoleIds) &&
              projectAllocation.assigneeId === assignee.id &&
              projectAllocation.assigneeType === assigneeType &&
              projectAllocation.periodStart === periodStart,
          );

          if (projectAllocation) {
            draft.data[row][col] = {
              ...cell,
              error: false,
              ...projectAllocation,
            };
          } else {
            draft.data[row][col] = {
              ...cell,
              error: cell["value"] !== null,
              id: null,
            };
          }
        } else {
          draft.data[row][col] = {
            ...cell,
            error: cell["value"] !== null,
            id: null,
          };
        }
      }
    }
  });

const handlers = {
  [ActionTypes.GENERATE_GRID]: generateGrid,
  [ActionTypes.UPDATE_GRID]: updateGrid,
  [CoreActionTypes.ENTITIES.PROJECT_ALLOCATIONS_SUCCESS]:
    updateWithProjectAllocations,
  [CoreActionTypes.ENTITIES.PROJECT_ALLOCATIONS_FAILURE]:
    updateWithProjectAllocations,
  [CoreActionTypes.ENTITIES.PROJECTS_SUCCESS]: generateGrid,
};

export default (state = { data: [], periods: [] }, action) => {
  const handler = handlers[action.type];
  if (handler) {
    state = handler(state, action);
  }

  return state;
};
