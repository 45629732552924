export default {
  REVENUE_ESTIMATED: "REVENUE_ESTIMATED",
  REVENUE_ACTUAL: "REVENUE_ACTUAL",
  REVENUE_DEVIATION_ESTIMATED_ACTUAL: "REVENUE_DEVIATION_ESTIMATED_ACTUAL",

  COST_ACTUAL: "COST_ACTUAL",
  COST_BUDGETED: "COST_BUDGETED",
  COST_DEVIATION_BUDGETED_ACTUAL: "COST_DEVIATION_BUDGETED_ACTUAL",
  COST_DEVIATION_BUDGETED_ESTIMATED: "COST_DEVIATION_BUDGETED_ESTIMATED",
  COST_DEVIATION_ESTIMATED_ACTUAL: "COST_DEVIATION_ESTIMATED_ACTUAL",
  COST_ESTIMATED: "COST_ESTIMATED",

  HEADCOUNT_ACTUAL: "HEADCOUNT_ACTUAL",
  HEADCOUNT_BUDGETED: "HEADCOUNT_BUDGETED",
  HEADCOUNT_DEVIATION_BUDGETED_ACTUAL: "HEADCOUNT_DEVIATION_BUDGETED_ACTUAL",
  HEADCOUNT_DEVIATION_BUDGETED_ESTIMATED:
    "HEADCOUNT_DEVIATION_BUDGETED_ESTIMATED",
  HEADCOUNT_DEVIATION_ESTIMATED_ACTUAL: "HEADCOUNT_DEVIATION_ESTIMATED_ACTUAL",
  HEADCOUNT_ESTIMATED: "HEADCOUNT_ESTIMATED",

  HOURS_ACTUAL: "HOURS_ACTUAL",
  HOURS_BUDGETED: "HOURS_BUDGETED",
  HOURS_DEVIATION_BUDGETED_ACTUAL: "HOURS_DEVIATION_BUDGETED_ACTUAL",
  HOURS_DEVIATION_BUDGETED_ESTIMATED: "HOURS_DEVIATION_BUDGETED_ESTIMATED",
  HOURS_DEVIATION_ESTIMATED_ACTUAL: "HOURS_DEVIATION_ESTIMATED_ACTUAL",
  HOURS_ESTIMATED: "HOURS_ESTIMATED",
  GROSS_ESTIMATED: "GROSS_ESTIMATED",
  GROSS_ACTUAL: "GROSS_ACTUAL",
  GROSS_DEVIATION_ESTIMATED_ACTUAL: "GROSS_DEVIATION_ESTIMATED_ACTUAL",
};
