import React from "react";
import { Frame } from "@ableco/baseline";
import { startCase, toLower } from "lodash";
import { CoreTooltip } from "../core-tooltip";

export const STATUS = {
  disabled: { color: "neutral-500", title: "all tracks" },
  at_risk: { color: "alert", title: "at risk" },
  behind: { color: "warning", title: "behind" },
  on_track: { color: "success", title: "on track" },
};

export function scoreToStatus(score) {
  if (score <= 3) return "at_risk";
  if (score <= 6) return "behind";
  return "on_track";
}

export function getObjectiveStatus(objective) {
  return objective.closedAt
    ? scoreToStatus(objective.score)
    : objective.currentStatusUpdate?.status;
}

export function StatusIcon({ status, className, tooltip = false }) {
  if (!status || status == "default") return null;

  const statusIconContent = (
    <Frame
      bg={STATUS[status]?.color || "transparent"}
      border={STATUS[status]?.color || "transparent"}
      corners="full"
      className={className || "h-4 w-4"}
    />
  );

  if (tooltip)
    return (
      <CoreTooltip label={startCase(toLower(STATUS[status].title))}>
        {statusIconContent}
      </CoreTooltip>
    );

  return statusIconContent;
}
