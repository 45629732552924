import styled from "styled-components";
import { colorPalette } from "./colors";

export const fonts = {
  sansSerif: "Inter",
};

export const fontWeights = {
  regular: 400,
  mediumWeight: 500,
  semiBold: 600,
  bold: 700,
};

export const fontSizes = {
  smallXX: 11,
  smallX: 12,
  small: 14,
  medium: 16,
  large: 18,
  largeX: 20,
  largeXX: 24,
  largeXXX: 30,
};

export const HeadingLargeXXXBold = styled.h2`
  font-family: "${fonts.sansSerif}", sans-serif;
  font-size: ${fontSizes.largeXXX}px;
  font-weight: ${fontWeights.bold};
  line-height: 36px;
`;

export const HeadingLargeXBold = styled.h2`
  font-family: "${fonts.sansSerif}", sans-serif;
  font-size: ${fontSizes.largeX}px;
  font-weight: ${fontWeights.bold};
  line-height: 26px;
`;

export const HeadingLargeXXBold = styled.h2`
  font-family: "${fonts.sansSerif}", sans-serif;
  font-size: ${fontSizes.largeXX}px;
  font-weight: ${fontWeights.bold};
  line-height: 36px;
`;

export const HeadingMediumBold = styled.h2`
  font-family: "${fonts.sansSerif}", sans-serif;
  font-size: ${fontSizes.medium}px;
  font-weight: ${fontWeights.bold};
  line-height: 24px;
`;

export const BodyTextSmallBold = styled.p`
  font-family: "${fonts.sansSerif}", sans-serif;
  font-size: ${fontSizes.small}px;
  font-weight: ${fontWeights.bold};
  line-height: 24px;
`;

export const BodyTextSmall90 = styled.p`
  color: ${colorPalette.bluegrey90};
  font-family: "${fonts.sansSerif}", sans-serif;
  font-size: ${fontSizes.small}px;
  font-weight: ${fontWeights.regular};
  line-height: 21px;
`;

export const BodyTextSmallX90 = styled.p`
  color: ${colorPalette.bluegrey90};
  font-family: "${fonts.sansSerif}", sans-serif;
  font-size: ${fontSizes.smallX}px;
  font-weight: ${fontWeights.regular};
  line-height: 16px;
`;

export const BodyTextSmall500 = styled.p`
  color: ${colorPalette.neutral800};
  font-family: "${fonts.sansSerif}", sans-serif;
  font-size: ${fontSizes.small}px;
  font-weight: ${fontWeights.regular};
  line-height: 21px;
`;

export const AnchorTextSmallX500 = styled.a`
  color: ${colorPalette.deepBlue500};
  font-family: "${fonts.sansSerif}", sans-serif;
  font-size: ${fontSizes.smallX}px;
  font-weight: ${fontWeights.regular};
  line-height: 18px;
`;
