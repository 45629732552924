import React from "react";
import { Frame, Inline, Skeleton, Stack } from "@ableco/baseline";
import { CaretDownOutline } from "@baseline/icons";
import { api, useResource } from "coreql";
import { matchPath } from "react-router";
import useCurrentUser from "../../hooks/use-current-user";
import { NavItem as Tab } from "../tab";
import { MenuItem } from "./menu";
import { BulletUnread } from "../feedback-messages/feedback-message-content";
import PageLayout from "../layout/page-layout";

// Exported for testing purposes
export const UserProfileContext = React.createContext(null);

export function readUserProfile(id) {
  // eslint-disable-next-line unicorn/no-array-callback-reference
  return api.users.find(id).read({
    included: ["role"],
    fields: {
      roles: [
        "title",
        "pluralTitle",
        "disciplineTitle",
        "disciplineId",
        "departmentTitle",
        "departmentId",
      ],
    },
  });
}

/**
 * Return the first path data that matches the given routes
 * @param {...import("react-router").RouteProps} paths
 * @returns {ReturnType<typeof import("react-router").matchPath>}
 */
export function currentPathMatches(location, ...paths) {
  function matchCurrentPath(path) {
    return matchPath(location.pathname, path);
  }

  return paths
    .map((path) => matchCurrentPath(path))
    .find((match) => match !== null);
}

export function getNavItems(currentUser, userProfile) {
  const isSameUser = Number(currentUser.id) === Number(userProfile.id);
  const isManagerOfUser = userProfile.reportsToMe;
  const hasManager = Boolean(userProfile.managerId);
  const badgeTitle = isManagerOfUser ? "Badges" : "My Badges";

  const items = [];
  items.push({
    href: `/users/${isSameUser ? currentUser.id : userProfile.id}`,
    isActive: (location) =>
      currentPathMatches(
        location,
        { path: "/users/:id", exact: true },
        { path: "/users/:id/capabilities" },
      ),

    label: isSameUser ? "My Role" : "Role",
    icon: <CaretDownOutline className="w-4 h-4" />,
    iconPosition: "right",
    children: (
      <>
        <MenuItem href={`/users/${userProfile.id}`}>Responsibilities</MenuItem>
        {(isManagerOfUser || isSameUser) && (
          <MenuItem href={`/users/${userProfile.id}/capabilities`}>
            Capabilities
          </MenuItem>
        )}
      </>
    ),
  });

  if (isSameUser) {
    items.push({
      href: "/weekly_reports/my_journal",
      label: "Weekly Journal",
      isActive: (location) =>
        currentPathMatches(location, { path: "/weekly_reports" }),
      prefetch: false,
    });
  }

  if (isSameUser && hasManager) {
    items.push({
      href: `/1-on-1/${userProfile.id}/${userProfile.managerId}`,
      label: "1-on-1 with Manager",
      isActive: (location) =>
        currentPathMatches(location, { path: "/1-on-1/:user1/:user2" }),
      prefetch: false,
    });
  }

  if (!isSameUser) {
    items.push({
      href: `/1-on-1/${userProfile.id}/${currentUser.id}`,
      label: "1-on-1",
      isActive: (location) =>
        currentPathMatches(location, { path: "/1-on-1/:user1/:user2" }),
      prefetch: false,
    });
  }

  if (isManagerOfUser || isSameUser) {
    items.push({
      href: `/users/${
        isSameUser ? currentUser.id : userProfile.id
      }/feedback_messages/received`,
      isActive: (location) =>
        currentPathMatches(
          location,
          { path: "/my-core/highlights" },
          { path: "/my-team/:id/highlights" },
          { path: "/users/:id/feedback_messages" },
          { path: "/users/:id/badges" },
        ),
      label:
        isSameUser && currentUser.hasUnreadFeedback ? (
          <Inline data-testid="grow-menu">
            <BulletUnread
              className="mr-2"
              data-testid="unread-feedback-grow-menu"
            />
            <p>Growth</p>
          </Inline>
        ) : (
          "Growth"
        ),
      icon: <CaretDownOutline className="w-4 h-4" />,
      iconPosition: "right",
      children: (
        <>
          <MenuItem
            href={`/users/${userProfile.id}/feedback_messages/received`}
          >
            <Inline>
              <span className="mr-2">Feedback</span>
              {isSameUser && currentUser.hasUnreadFeedback && (
                <BulletUnread
                  className="mr-2"
                  data-testid="unread-feedback-submenu-feedback"
                />
              )}
            </Inline>
          </MenuItem>
          {(isManagerOfUser || isSameUser) && (
            <MenuItem
              href={
                isSameUser
                  ? "/my-core/highlights"
                  : `/my-team/${userProfile.id}/highlights`
              }
            >
              Highlights
            </MenuItem>
          )}
          <MenuItem href={`/users/${userProfile.id}/badges`}>
            {badgeTitle}
          </MenuItem>
        </>
      ),
    });
  }

  items.push({
    href: `/users/${userProfile.id}/information`,
    label: "Information",
    isActive: (location) =>
      currentPathMatches(location, {
        path: "/users/:id/information",
        exact: true,
      }),
    prefetch: false,
  });

  return items;
}

export default function UserLayout({ id, children }) {
  const { data: currentUser } = useCurrentUser();
  const user = useResource(
    "users",
    id,
    {
      included: ["role"],
      fields: {
        roles: [
          "title",
          "pluralTitle",
          "disciplineTitle",
          "disciplineId",
          "departmentTitle",
          "departmentId",
        ],
      },
    },
    "denormalized",
  ).data;

  const navItems = getNavItems(currentUser, user);

  return (
    <UserProfileContext.Provider value={user}>
      <PageLayout
        title={user.fullName}
        subTitle={user?.role?.title}
        avatarUser={user}
      >
        <Inline
          as="nav"
          aria-label="User profile"
          alignment="start"
          className="mb-10 border-b border-neutral-300 -mx-8"
          p={[0, 0, 0, 8]}
        >
          {navItems.map((item) => (
            <Tab key={item.href} {...item} />
          ))}
        </Inline>
        {children}
      </PageLayout>
    </UserProfileContext.Provider>
  );
}

export function useUserProfile() {
  return React.useContext(UserProfileContext);
}

export function Fallback({ children }) {
  return (
    <>
      <Frame>
        <Stack
          alignment="start"
          bg="white"
          corners="small"
          p={[4, 4, 0, 8]}
          className="w-full mb-10 border-b border-neutral-300"
        >
          <Inline className="w-full ml-4">
            <Skeleton
              size={64}
              color="neutral-400"
              alt="User avatar"
              corners="full"
              className="mr-4"
              animated
            />
            <Stack>
              <Skeleton
                color="neutral-400"
                height={30}
                width={300}
                alt="User full name"
                animated
                className="mb-4 mt-1"
              />
              <Skeleton
                color="neutral-400"
                animated
                height={18}
                width={150}
                alt="User role"
              />
            </Stack>
          </Inline>
          <Inline as="nav" className="pt-4" alignment="start">
            {Array.from({ length: 5 }, (_, index) => (
              <Skeleton
                key={index}
                height={20}
                width={100}
                className="mx-4 mt-4 mb-3"
                alt="Profile link"
                color="neutral-400"
                animated
              />
            ))}
          </Inline>
        </Stack>
      </Frame>
      {children}
    </>
  );
}
