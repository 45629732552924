export const colorPalette = {
  babyblue20: "#f9fbfe",
  bluegrey10: "#fafbfc",
  bluegrey20: "#f5f7f8",
  bluegrey30: "#eceef2",
  bluegrey40: "#e0e3e9",
  bluegrey50: "#c2c9d4",
  bluegrey90: "#8b98ae",

  deepBlue10: "#fafbfc",
  deepBlue20: "#f5f6f9",
  deepBlue30: "#ebedf3",
  deepBlue40: "#dee2eb",
  deepBlue50: "#c0c6d8",
  deepBlue60: "#b1b9ce",
  deepBlue70: "#a4adc6",
  deepBlue80: "#95a0bd",
  deepBlue90: "#8693b4",
  deepBlue100: "#7785aa",
  deepBlue200: "#6877a1",
  deepBlue300: "#596a98",
  deepBlue400: "#4c5f90",
  deepBlue500: "#3d5186",
  deepBlue600: "#30467e",
  deepBlue700: "#1f3673",
  deepBlue800: "#10296a",
  deepBlue900: "#031d62",

  babyBlue600: "#79b2e1",
  babyBlue60: "#cde2f4",

  red400: "#fcbebe",

  pink10: "#fffcfc",
  pink20: "#fffcfc",
  pink30: "#fff1f3",
  pink40: "#ffe8eb",
  pink50: "#fed2d7",
  pink60: "#fec8ce",
  pink70: "#febec6",
  pink80: "#feb4bd",
  pink90: "#fea9b4",
  pink100: "#fd9ea9",
  pink200: "#fd94a0",
  pink300: "#fd8997",
  pink400: "#fd808f",
  pink500: "#fd7586",
  pink600: "#fd6c7e",
  pink700: "#fc6072",
  pink800: "#fc5569",
  pink900: "#fc4c61",

  success: "#4fdc34",
  successMild: "#9bdc34",
  highlight: "#ffe15f",
  amber: "#dc9834",
  warning: "#f85d5d",
  white: "#fff",
  dark: "#333333",
  dim: "#666666",
  black: "#000",

  placeholder: "#8b98ae",

  //New palette

  $neutral100: "rgba(0, 0, 0, 0.02)", // table header
  $neutral200: "rgba(0, 0, 0, 0.04)", // backgrounds
  $neutral300: "rgba(0, 0, 0, 0.09)", // dividers
  $neutral400: "rgba(0, 0, 0, 0.15)", // borders
  $neutral500: "rgba(0, 0, 0, 0.25)", // disabled
  $neutral600: "rgba(0, 0, 0, 0.45)", // subdued
  $neutral700: "rgba(0, 0, 0, 0.65)", // baseline
  $neutral800: "rgba(0, 0, 0, 0.85)", // title

  $light100: "rgba(255, 255, 255, 0.06)", // table header
  $light200: "rgba(255, 255, 255, 0.1)", // background
  $light300: "rgba(255, 255, 255, 0.15)", // dividers
  $light400: "rgba(255, 255, 255, 0.3)", // borders
  $light500: "rgba(255, 255, 255, 0.45)", // disabled
  $light600: "rgba(255, 255, 255, 0.65)", // subdued
  $light700: "rgba(255, 255, 255, 0.85)", // baseline

  $primaryDark: "#002766",
  $primaryBase: "#096dd9",
  $primaryLight: "#1890ff",
  $primaryLighter: "#e6f7ff",
};
