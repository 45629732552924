import { endOfWeek, format, isBefore, startOfWeek, subWeeks } from "date-fns";
import { useLocation } from "react-router";

export function getArrayOfDates(untilDate, sinceDate) {
  const arrayOfDates = [];
  let dateIterator = new Date(untilDate);
  while (!isBefore(dateIterator, sinceDate)) {
    arrayOfDates.push(dateIterator);
    dateIterator = subWeeks(dateIterator, 1);
  }
  return arrayOfDates;
}

export function getPastWeekPeriods(startDate, nWeeks) {
  function getWeekBoundaries(date) {
    return [
      startOfWeek(date, { weekStartsOn: 1 }),
      endOfWeek(date, { weekStartsOn: 1 }),
    ];
  }
  const endDate = subWeeks(startDate, nWeeks - 1);
  return getArrayOfDates(startDate, endDate).map(getWeekBoundaries);
}

export function formatDateShort(date) {
  return format(date, "MMM d");
}

export function formatJournalPeriod(periodStart) {
  const periodEnd = endOfWeek(periodStart, {
    weekStartsOn: 1,
  });
  return `${formatDateShort(periodStart)} - ${formatDateShort(periodEnd)}`;
}

export function formatWithDashes(currentPeriod) {
  return format(currentPeriod, "yyyy-MM-dd");
}

export function formatLong(date) {
  return format(date, "MMM d, yyyy");
}

export function weekStart(date) {
  return startOfWeek(date, { weekStartsOn: 1 });
}

export function setParamsToJournalUrl(url, date, nWeeks) {
  return `${url}?reportsSince=${formatWithDashes(
    subWeeks(date, nWeeks - 1),
  )}&reportsUntil=${formatWithDashes(date)}`;
}

export function useReferrer() {
  const query = new URLSearchParams(useLocation().search);
  return query.get("referrer");
}
