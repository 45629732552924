import {
  differenceInCalendarDays,
  format,
  isToday,
  isYesterday,
} from "date-fns";

/**
 *
 * @param {Date} date
 */
export function formatSubmittedDateWithTime(date) {
  const formattedTime = format(date, "h:mmaaaa");

  if (isToday(date)) return `Today at ${formattedTime}`;
  else if (isYesterday(date)) return `Yesterday at ${formattedTime}`;
  else if (differenceInCalendarDays(new Date(), date) <= 7) {
    return `${differenceInCalendarDays(
      new Date(),
      date,
    )} days ago at ${formattedTime}`;
  } else return format(date, "MMM d, yyyy");
}

export function formatSubmittedDateWithoutTime(date) {
  if (isToday(date)) return "Today";
  else if (isYesterday(date)) return `Yesterday`;
  else if (differenceInCalendarDays(new Date(), date) <= 7) {
    return `${differenceInCalendarDays(new Date(), date)} days ago`;
  } else return format(date, "MMM d, yyyy");
}
