import React from "react";
import {
  Color,
  Corners,
  FlexAlignment,
  FlexDistribution,
  Inline,
  promiseResolutions,
  Shadow,
  Stack,
  Text,
  TextLeading,
  Toast,
  Touchable,
  useToast,
  useToasts,
} from "@ableco/baseline";
import {
  CheckCircleFilled,
  Close,
  CloseCircleFilled,
  WarningFilled,
} from "@baseline/icons";
import { AnimatePresence, motion } from "framer-motion";

function SuccessIcon() {
  return (
    <Text color="success">
      <CheckCircleFilled className="w-6 h-6" />
    </Text>
  );
}

function WarningIcon() {
  return (
    <Text color="warning">
      <WarningFilled className="w-6 h-6" />
    </Text>
  );
}
function ErrorIcon() {
  return (
    <Text color="alert">
      <CloseCircleFilled className="w-6 h-6" />
    </Text>
  );
}

export function ErrorToast(props) {
  return <CoreToast {...props} Icon={ErrorIcon} />;
}

export function WarningToast(props) {
  return <CoreToast {...props} Icon={WarningIcon} />;
}

export function SuccessToast(props) {
  return <CoreToast {...props} Icon={SuccessIcon} />;
}

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

// CoreToastContainer is identical to Baseline's ToastContainer but replaces the Toast List Fragment with
// AnimatePresence to allow for exit animations
export function CoreToastContainer(props) {
  const [toasts, setToasts] = useToasts();

  function removeToast({ id, action, actionClicked }) {
    if (action) {
      const resolve = promiseResolutions.get(id);
      if (resolve) {
        promiseResolutions.delete(id);
        resolve(actionClicked);
      }
    }

    setToasts((currentToast) =>
      currentToast.filter((toast) => toast.id !== id),
    );
  }

  return (
    <Stack p={4} space={4} className="fixed bottom-0 left-0 z-10">
      <AnimatePresence>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            {...toast}
            as={motion.div}
            bg="white"
            shadow={Shadow.Medium}
            corners={Corners.MediumRounded}
            style={{ width: 320 }}
            time={5000}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            {...props}
            removeToast={removeToast}
          />
        ))}
      </AnimatePresence>
    </Stack>
  );
}

function CoreToast({ label, close, Icon }) {
  return (
    <Inline
      p={1}
      space={1}
      distribution={FlexDistribution.Between}
      alignment={FlexAlignment.Start}
      className="w-full"
    >
      <Inline space={4} className="py-3 pl-3" alignment={FlexAlignment.Start}>
        <Icon />
        <Text color={Color.Neutral700} leading={TextLeading.Normal}>
          {label}
        </Text>
      </Inline>
      <Touchable
        onClick={(event) => {
          event.preventDefault();
          close();
        }}
        title="Close"
        data-testid="toast-close"
      >
        <Text color={Color.Neutral600}>
          <Close className="w-4 h-4" />
        </Text>
      </Touchable>
    </Inline>
  );
}

export function useCoreToast() {
  const toast = useToast();

  function successToast(message = "Success!") {
    toast({
      render: ({ closeToast }) => (
        <SuccessToast label={message} close={closeToast} />
      ),
    });
  }

  function errorToast(message = "Error!") {
    toast({
      render: ({ closeToast }) => (
        <ErrorToast label={message} close={closeToast} />
      ),
    });
  }

  function warningToast(message = "Warning!") {
    toast({
      render: ({ closeToast }) => (
        <WarningToast label={message} close={closeToast} />
      ),
    });
  }

  return {
    successToast,
    warningToast,
    errorToast,
  };
}
