import { Color } from "@ableco/baseline";
import React from "react";
import {
  BodySmall,
  BodySmallSemibold,
} from "../design-system/typography-components";
export function LabelText(props) {
  return <BodySmallSemibold color={Color.Neutral800} {...props} />;
}

export function ValueText(props) {
  return <BodySmall color={Color.Neutral700} {...props} />;
}
