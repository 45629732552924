import React from "react";
import { formatDistanceToNow, differenceInHours, format } from "date-fns";
import { useCollection } from "coreql";
import { Color, Frame, Inline, Stack, Table, Text } from "@ableco/baseline";
import PageLayout from "./layout/page-layout";
import {
  BodyBaselineSemibold,
  BodySmall,
  BodySmallSemibold,
  Heading,
} from "../components/design-system/typography-components";
import Select from "./data-entry/select";
import { SecondaryButton } from "./buttons/buttons";
import { CheckCircleOutline, Download, WarningFilled } from "@baseline/icons";
import { wrap } from "./weekly-report/utils";
import upload from "./icons/upload";

function CellText(props) {
  return <BodySmall color={Color.Neutral700} {...props} />;
}

function RevenueTable({ revenues }) {
  const formattedRevenues = revenues.map((revenue) => ({
    periodStart: <CellText>{revenue.periodStart}</CellText>,
    estimated: <CellText>{(revenue.estimated / 100).toFixed(2)}</CellText>,
    actual: <CellText>{(revenue.actual / 100).toFixed(2)}</CellText>,
  }));
  return (
    <Frame
      style={{
        height: 500,
        overflowY: "scroll",
        display: "inline-block",
      }}
    >
      <Table
        columns={[
          { label: "Period", name: "periodStart" },
          { label: "Estimated", name: "estimated" },
          { label: "Actual", name: "actual" },
        ]}
        data={formattedRevenues}
        className="border border-neutral-400"
        data-testid={`revenue-table`}
        style={{ marginTop: "unset" }}
        th={{
          p: [3, 6],
          bg: "neutral-100",
          className: "text-neutral-600  border-b border-neutral-400",
        }}
        td={{
          p: [4, 6],
          bg: "white",
          className: "text-neutral-700 border-b border-neutral-400",
        }}
      />
    </Frame>
  );
}

function invalidFile(file) {
  if (file.type !== "text/csv") {
    return "Incorrect format, please upload a .csv file";
  }
  if (file.size > 10000000) {
    return "Please upload something smaller than <10MB>";
  }
}

async function onUpload(
  event,
  projectId,
  setUploadMessage,
  setUploadLabel,
  setInputValue,
  setDownloadMessage,
  setProjectRevenues,
) {
  const file = event.target.files[0];
  const errorMessage = invalidFile(file);
  if (errorMessage) {
    setUploadMessage({
      type: "error",
      content: errorMessage,
    });
    return;
  }
  setUploadLabel("Loading");
  setUploadMessage({
    type: "neutral",
    content: file.name,
  });

  try {
    const data = await uploadFile(file, projectId);
    setUploadMessage({
      type: "success",
      content: `${data.length} weeks successfully uploaded`,
    });
    updateDownloadMessage(projectId, setDownloadMessage);
    setProjectRevenues(data);
  } catch (error) {
    setUploadMessage({
      type: "error",
      content: error.message,
    });
  }

  setUploadLabel("Upload Changes");
  setInputValue("");
}

async function uploadFile(file, projectId) {
  const data = new FormData();
  data.append("file", file);
  const response = await fetch(
    `/api/v2/projects/${projectId}/project-revenues/import`,
    {
      method: "POST",
      body: data,
    },
  );
  return await response.json();
}

function onProjectSelection(value, setProjectId, setDownloadMessage) {
  const id = value;
  setProjectId(id);

  updateDownloadMessage(id, setDownloadMessage);
}

async function updateDownloadMessage(projectId, setDownloadMessage) {
  try {
    const response = await fetch(
      `/api/v2/projects/${projectId}/project-revenues/last_record`,
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
        },
      },
    );
    const data = await response.json();
    const lastUpdateDate = new Date(data.createdAt);
    const currentDate = new Date();
    if (differenceInHours(currentDate, lastUpdateDate) > 24) {
      const formatedDate = format(lastUpdateDate, "MMM d, yyyy, h:mma");
      setDownloadMessage(`Last update on ${formatedDate}`);
    } else {
      const distance = formatDistanceToNow(lastUpdateDate, currentDate);
      setDownloadMessage(`Updated ${distance} ago`);
    }
  } catch {
    setDownloadMessage("New Template");
  }
}

//Sub-components
function Message({ type = "neutral", content = "" }) {
  switch (type) {
    case "error":
      return (
        <Inline space={2}>
          <Text color={Color.Alert}>
            <WarningFilled className="w-5 h-5" />
          </Text>
          <BodySmallSemibold color={Color.Alert}>{content}</BodySmallSemibold>
        </Inline>
      );
    case "success":
      return (
        <Inline space={2}>
          <Text color={Color.Success}>
            <CheckCircleOutline className="w-5 h-5" />
          </Text>
          <BodySmallSemibold color={Color.Success}>{content}</BodySmallSemibold>
        </Inline>
      );
    default:
      return (
        <BodySmallSemibold color={Color.Neutral700}>
          {content}
        </BodySmallSemibold>
      );
  }
}

function ProductSection({ productId, productName, projects }) {
  const [projectId, setProjectId] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [downloadMessage, setDownloadMessage] = React.useState("");
  const [uploadLabel, setUploadLabel] = React.useState("Upload Changes");
  const [uploadMessage, setUploadMessage] = React.useState({
    type: "neutral",
    content: "CSV format, 10MB file size limit",
  });
  const [projectRevenues, setProjectRevenues] = React.useState([]);
  const fileInputRef = React.useRef(null);

  return (
    <Stack space={2} p={4} bg={Color.Neutral200}>
      <Heading color={Color.Neutral800}>{productName}</Heading>
      <Inline alignment="start">
        <Frame className="w-5/12">
          <Inline space={4}>
            <BodyBaselineSemibold
              as="label"
              color={Color.Neutral800}
              htmlFor={`product-${productId}-select`}
            >
              Select a project:
            </BodyBaselineSemibold>
            <Select
              value={projectId}
              name={`product-${productId}-projects`}
              id={`product-${productId}-select`}
              options={projects.map((project) => ({
                label: project.name,
                value: project.id,
              }))}
              onChange={(value) => {
                onProjectSelection(value, setProjectId, setDownloadMessage);
              }}
              placeholder="Select one..."
            />
          </Inline>
        </Frame>
        {projectId && (
          <Frame>
            <BodyBaselineSemibold color={Color.Neutral800}>
              View or make updates:
            </BodyBaselineSemibold>
            <Stack space={4}>
              <Inline space={8}>
                <SecondaryButton
                  text="Download Report"
                  small
                  LeftIcon={Download}
                  onClick={() =>
                    (window.location.href = `/api/v2/projects/${projectId}/project-revenues.csv`)
                  }
                />
                <Message type="neutral" content={downloadMessage} />
              </Inline>
              <Inline space={8}>
                <SecondaryButton
                  text={uploadLabel}
                  small
                  LeftIcon={upload}
                  onClick={() => fileInputRef.current.click()}
                  as="label"
                  htmlFor={`${productName}-upload`}
                />

                <input
                  type="file"
                  name={`${productName}-upload`}
                  aria-label={`${productName}-upload`}
                  accept=".csv"
                  onChange={(event) =>
                    onUpload(
                      event,
                      projectId,
                      setUploadMessage,
                      setUploadLabel,
                      setInputValue,
                      setDownloadMessage,
                      setProjectRevenues,
                    )
                  }
                  multiple={false}
                  className="hidden"
                  value={inputValue}
                  ref={fileInputRef}
                />
                <Message
                  type={uploadMessage.type}
                  content={uploadMessage.content}
                />
              </Inline>
            </Stack>
          </Frame>
        )}
      </Inline>
      {projectRevenues.length > 0 && (
        <RevenueTable revenues={projectRevenues} />
      )}
    </Stack>
  );
}

function Revenue() {
  const { data: products } = useCollection(
    "products",
    {
      filters: {
        active: true,
      },
      included: ["projects"],
    },
    "denormalized",
  );

  return (
    <PageLayout title="Revenue Reports">
      <Stack space={8}>
        {products.map((productData) => (
          <ProductSection
            key={productData.id}
            productId={productData.id}
            productName={productData.name}
            projects={wrap(productData.projects)}
          />
        ))}
      </Stack>
    </PageLayout>
  );
}

export default Revenue;
