import React, { useState, useEffect, useCallback } from "react";
import styled, { css } from "styled-components";
import cloneDeep from "lodash/cloneDeep";
import { Field } from "formik";
import {
  format,
  setDay,
  startOfWeek,
  isAfter,
  isBefore,
  isSameDay,
  isMonday,
  addWeeks,
} from "date-fns";
import GridDropdown from "../grid-dropdown";
import getMondays from "../../../utils/get-mondays";
import filterWeeks from "../../../utils/filter-weeks";
import CloseIcon from "../../icons/close";
import { useCollection } from "coreql";
import keyBy from "lodash/keyBy";
import get from "lodash/get";
import uniq from "lodash/uniq";
import { colorPalette } from "../../style-guide/colors";
import { fontWeights } from "../../style-guide/typography";
import { v4 as uuid } from "uuid";
import { EllipsisSpinner } from "../../style-guide/ellipsis-spinner";
import useTooltip from "../../../hooks/use-tooltip";
import DatePicker from "react-datepicker";
import { AnchorButton } from "../../style-guide/anchor";
import * as _ from "lodash";

const Container = styled.div`
  font-size: 12px;
  overflow: visible;
  display: flex;
  position: relative;

  ${({ inEditMode }) =>
    inEditMode &&
    css`
      ${StaffInformation} {
        border-bottom: 1px solid ${colorPalette.bluegrey40};
      }
      ${StaffInformationCell} {
        position: relative;

        &:first-child {
          border-right: 1px solid ${colorPalette.bluegrey40};
        }

        &.with-error {
          border: 1px solid ${colorPalette.warning};
        }
      }
      ${RowHours} {
        border-bottom: 1px solid ${colorPalette.bluegrey40};
      }

      ${Hour} {
        border-right: 1px solid ${colorPalette.bluegrey40};
      }

      ${Column} {
        border-top: 1px solid ${colorPalette.deepBlue500};
        border-left: 1px solid ${colorPalette.deepBlue500};
        border-bottom: 1px solid ${colorPalette.deepBlue500};
      }
      ${HoursPanel} {
        border-top: 1px solid ${colorPalette.deepBlue500};
        border-right: 1px solid ${colorPalette.deepBlue500};
        border-bottom: 1px solid ${colorPalette.deepBlue500};
      }
    `}
`;

const Staff = styled.div`
  flex: 0 0 340px;
  padding-top: 18px;
`;

const Planning = styled.div`
  overflow-x: auto;
  max-width: 438px;

  ${({ mondays }) => {
    if (!mondays) return `width: 111px`;
    return `width: ${61 + mondays * 50}px`;
  }}
`;

const Column = styled.div`
  border: 1px solid ${colorPalette.bluegrey40};
`;

const Headers = styled.div`
  color: ${colorPalette.bluegrey90};
  font-family: "ProximaNova Semibold", sans-serif;
  font-size: 12px;
`;

const HeadersWeeks = styled(Headers)`
  display: flex;
`;

const HeaderWeek = styled.div`
  flex: 0 0 50px;
  text-align: center;
`;

const HeaderCurrentWeek = styled(HeaderWeek)`
  color: ${colorPalette.pink500};
`;

const HeaderTotal = styled.div`
  flex: 0 0 60px;
  text-align: center;
`;

const StaffInformation = styled.div`
  display: flex;
  height: 30px;
`;

const StaffInformationCell = styled.div`
  display: flex;
  flex: 1 1 50%;
  align-items: stretch;
  white-space: nowrap;
`;

const RowHours = styled.div`
  height: 30px;
  display: flex;
  &.with-error {
    border: 1px solid ${colorPalette.warning};
  }
`;

const AddButton = styled.button`
  width: 100%;
  height: 30px;
  border: 0;
  text-align: left;
  padding-left: 10px;
  cursor: pointer;
  color: ${colorPalette.pink500};
  font-size: inherit;
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
`;

const LastRowSpacing = styled.div`
  width: 100%;
  height: 30px;
`;

const Slot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GridInput = styled.input`
  &[type="text"] {
    border: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    &:focus {
      outline: 0;
      border: 1px solid ${colorPalette.deepBlue500};
      border-radius: 0;
      box-shadow: none;
    }
  }
`;

const Hour = styled(Slot)`
  flex: 0 0 50px;
  text-align: center;
`;

const TotalRowHours = styled(Slot)`
  flex: auto;
  background-color: ${colorPalette.bluegrey40};
  color: ${colorPalette.deepBlue500};
  font-weight: ${fontWeights.bold};
`;

const TotalColumnHours = styled(Slot)`
  flex: 0 0 50px;
  background-color: ${colorPalette.bluegrey40};
  color: ${colorPalette.deepBlue500};
  font-weight: ${fontWeights.bold};
`;

const HoursPanel = styled.div`
  border-top: 1px solid ${colorPalette.bluegrey40};
  border-bottom: 1px solid ${colorPalette.bluegrey40};
  border-right: 1px solid ${colorPalette.bluegrey40};
  ${({ mondays }) => {
    if (!mondays) return `width: 111px`;
    return `width: ${61 + mondays * 50}px`;
  }}
`;

const GridDelete = styled.div`
  display: flex;
  flex-direction: column;
  align-self: self-start;
  margin-top: 24px;
`;

const DeleteIconWrapEmpty = styled.div`
  min-width: 30px;
  height: 30px;
`;

const DeleteIconWrap = styled(DeleteIconWrapEmpty)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(61, 81, 134, 0.6);
  &:hover {
    cursor: pointer;
    color: #3d5186;
  }
`;

function LoadingOverlay() {
  return (
    <Overlay>
      <EllipsisSpinner fontSize={"12px"} />
    </Overlay>
  );
}

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
  opacity: 0.5;
  background-color: ${colorPalette.bluegrey10};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CopyUntilPopover = styled.div`
  input {
    width: 120px;
    margin-left: 10px;
  }
`;

const DatePickerRow = styled.div`
  display: flex;
  align-items: center;
`;
const CopyButton = styled(AnchorButton)``;
const CopyButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

function CopyUntilDialog({ minDate, maxDate, handleCopy }) {
  const [selectedDate, setSelectedDate] = useState();
  return (
    <CopyUntilPopover>
      <DatePickerRow>
        Copy until
        <DatePicker
          data-testid="copy-until-date-select"
          placeholderText="Select a date"
          filterDate={isMonday} // Filter dates or  Include dates
          minDate={minDate}
          maxDate={maxDate}
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MM/dd/yyyy"
          showDisabledMonthNavigation
          openToDate={minDate}
        />
      </DatePickerRow>
      <CopyButtonWrapper>
        <CopyButton
          data-testid="copy-until-button"
          onClick={() => handleCopy(selectedDate)}
        >
          Copy
        </CopyButton>
      </CopyButtonWrapper>
    </CopyUntilPopover>
  );
}

function getHoursByUser(staffItem) {
  const totalHours = _.reduce(
    staffItem.weeks,
    (acc, cur) => acc + cur.hours,
    0,
  );
  return totalHours;
}

function alphabetically(a, b, keyContent) {
  const previous = a.attributes[keyContent].toLowerCase().trim();
  const next = b.attributes[keyContent].toLowerCase().trim();
  if (previous < next) return -1;
  if (previous > next) return 1;
  return 0;
}

function formatData(items, keyContent) {
  return items
    .sort((a, b) => alphabetically(a, b, keyContent))
    .map((item) => ({
      value: item.id,
      content: item.attributes[keyContent],
      meta: item,
    }));
}

const CellText = styled.div`
  display: flex;
  padding-left: 10px;
  font-size: 14px;
  align-items: center;
  max-width: 160px;
  overflow-x: auto;
  overflow-y: hidden;
`;

function ReadOnlyProjectRole({ staffItem, rolesById }) {
  return staffItem.projectRoleIds.length > 0 ? (
    <CellText>
      {staffItem.projectRoleIds
        .map((id) => rolesById[id].attributes.name)
        .join(", ")}
    </CellText>
  ) : (
    <CellText>No role found</CellText>
  );
}

function ReadOnlyStaffItem({ staffItem, usersById }) {
  return staffItem.userId ? (
    <CellText>{usersById[staffItem.userId].attributes.fullName}</CellText>
  ) : (
    <CellText>No user found</CellText>
  );
}

function hasHoursError(index, errors, touched) {
  if (!get(touched, `attributes.staff[${index}].weeks`)) {
    return false;
  }

  if (get(errors, `attributes.staff[${index}].weeks`)) {
    return true;
  }

  return false;
}

function hasRoleError(index, errors, touched) {
  if (!get(touched, `attributes.staff[${index}]`)) {
    return false;
  }

  if (get(errors, `attributes.staff[${index}].projectRoleId`)) {
    return true;
  }
  return false;
}

function hasUserError(index, errors, touched) {
  if (!get(touched, `attributes.staff[${index}]`)) {
    return false;
  }

  if (get(errors, `attributes.staff[${index}].userId`)) {
    return true;
  }
  return false;
}

function AllocationsGrid({
  start,
  end,
  staff,
  setFieldValue,
  setFieldTouched,
  onDeletedRow = () => {},
  inEditMode = true,
  type,
  errors,
  touched,
  isSubmitting,
}) {
  const [mondays, setMondays] = useState([]);
  const [estimationIndex, setEstimationIndex] = useState(null);
  const [activeHourCell, setActiveHourCell] = useState({
    week: Date.now(),
    node: null,
    rowKey: null,
  });

  const {
    data: { data: allProjectRoles },
  } = useCollection("project-roles", {
    fields: { projectRoles: ["name", "active"] },
  });

  const staffRoles = uniq(staff.flatMap((el) => el.projectRoleIds));
  const rolesToDisplay = allProjectRoles.filter(
    (role) => role.attributes.active || staffRoles.includes(role.id),
  );

  const rolesById = keyBy(rolesToDisplay, "id");

  const {
    data: { data: allUsers },
  } = useCollection("users", {
    fields: { users: ["fullName", "assignable", "active"] },
  });

  // eslint-disable-next-line unicorn/prefer-set-has
  const staffIds = staff.map((el) => el.userId);

  const usersToDisplay = allUsers.filter(
    (user) =>
      (user.attributes.active && user.attributes.assignable) ||
      staffIds.includes(user.id),
  );

  const usersById = keyBy(usersToDisplay, "id");

  const headerCurrentWeekRef = useCallback(
    (node) => {
      if (node !== null) {
        node.scrollIntoView({ block: "nearest", inline: "start" });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [start, end],
  );

  useEffect(() => setMondays(getMondays(start, end)), [start, end]);

  useEffect(
    () => {
      const newStaff = staff.map((staffItem) => ({
        ...staffItem,
        weeks: filterWeeks(staffItem.weeks, start, end),
      }));
      setFieldValue("attributes.staff", newStaff);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [start, end],
  );

  const [Tooltip, triggerProps] = useTooltip();

  function handleNewRole() {
    const newStaff = [
      ...staff,
      {
        projectRoleId: "",
        projectRoleIds: [],
        userId: "",
        weeks: filterWeeks([], start, end),
        rowKey: uuid(),
      },
    ];
    setFieldValue("attributes.staff", newStaff);
  }

  function handleActiveCell(index) {
    if (staff.length > 1) {
      setEstimationIndex(index);
    }
  }

  function handleDeleteRow() {
    const newStaff = staff.filter((_, index) => index != estimationIndex);
    const rowId = staff[estimationIndex].rowId || null;
    setFieldValue("attributes.staff", newStaff);
    onDeletedRow(rowId);
    setEstimationIndex(null);
  }

  function handleHoursChange(event, staffIndex, weekIndex) {
    const currentValue = Number(event.target.value);
    if (!Number.isInteger(currentValue)) return;
    if (currentValue > 40) return;
    const newStaff = cloneDeep(staff);
    newStaff[staffIndex].weeks[weekIndex].hours = currentValue;
    setFieldValue("attributes.staff", newStaff);
  }

  function handleHoursBlur(staffIndex, weekIndex) {
    setFieldTouched(
      `attributes.staff[${staffIndex}].weeks[${weekIndex}]`,
      true,
    );
  }

  function totalHoursByWeek(index) {
    return _.reduce(staff, (acc, cur) => acc + cur.weeks[index].hours, 0);
  }

  function totalHours() {
    return _.reduce(
      staff,
      (acc, cur) => {
        const subtotal = _.reduce(
          cur.weeks,
          (accWeek, currentWeek) => accWeek + currentWeek.hours,
          0,
        );
        return acc + subtotal;
      },
      0,
    );
  }

  function handleCopyHours(endWeek) {
    const {
      week: { date: startWeek },
      node,
      rowKey,
    } = activeHourCell;
    const staffIndex = staff.findIndex(
      (staffItem) => staffItem.rowKey === rowKey,
    );
    const newStaff = cloneDeep(staff);

    const valueToCopy = node.value;

    const updatedWeeksIndexes = [];
    for (const [weekIndex, week] of newStaff[staffIndex].weeks.entries()) {
      if (
        (isAfter(week.date, startWeek) && isBefore(week.date, endWeek)) ||
        isSameDay(week.date, endWeek)
      ) {
        newStaff[staffIndex].weeks[weekIndex].hours = Number(valueToCopy);
        updatedWeeksIndexes.push[weekIndex];
      }
    }

    setFieldValue("attributes.staff", newStaff);

    for (const weekIndex of updatedWeeksIndexes)
      setFieldTouched(
        `attributes.staff[${staffIndex}].weeks[${weekIndex}]`,
        true,
      );
  }

  const currentDate = new Date();
  const mondayOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });

  return (
    <Container
      inEditMode={inEditMode}
      onMouseLeave={() => setEstimationIndex(null)}
    >
      {isSubmitting && <LoadingOverlay />}
      <Staff>
        <Column>
          {staff.map((staffItem, index) => (
            <StaffInformation
              key={`${index}-${staffItem.rowKey}`}
              onMouseEnter={() => handleActiveCell(index)}
            >
              <StaffInformationCell
                className={
                  hasRoleError(index, errors, touched) ? "with-error" : ""
                }
                data-testid={`role-cell-${index}`}
              >
                {inEditMode ? (
                  <Field
                    component={GridDropdown}
                    name={`attributes.staff[${index}].projectRoleId`}
                    options={formatData(rolesToDisplay, "name")}
                    keyContent="name"
                    placeholder="Select a project role *"
                    data-testid={`role-select-${index}`}
                    disabled={isSubmitting}
                  />
                ) : (
                  <ReadOnlyProjectRole
                    staffItem={staffItem}
                    rolesById={rolesById}
                  />
                )}
              </StaffInformationCell>
              <StaffInformationCell
                className={
                  hasUserError(index, errors, touched) ? "with-error" : ""
                }
              >
                {inEditMode ? (
                  <Field
                    component={GridDropdown}
                    name={`attributes.staff[${index}].userId`}
                    options={formatData(usersToDisplay, "fullName")}
                    keyContent="fullName"
                    placeholder="Select a user (optional)"
                    disabled={isSubmitting}
                  />
                ) : (
                  <ReadOnlyStaffItem
                    staffItem={staffItem}
                    usersById={usersById}
                  />
                )}
              </StaffInformationCell>
            </StaffInformation>
          ))}

          {inEditMode ? (
            <AddButton type="button" onClick={handleNewRole}>
              Add role
            </AddButton>
          ) : (
            <LastRowSpacing />
          )}
        </Column>
      </Staff>
      <Planning mondays={mondays.length}>
        <HeadersWeeks>
          {mondays.length > 0 ? (
            mondays.map((week, indexWeek) =>
              week.getTime() === mondayOfCurrentWeek.getTime() ? (
                <HeaderCurrentWeek key={indexWeek} ref={headerCurrentWeekRef}>
                  {format(week, "MM/dd")}
                </HeaderCurrentWeek>
              ) : (
                <HeaderWeek key={indexWeek}>{format(week, "MM/dd")}</HeaderWeek>
              ),
            )
          ) : (
            <HeaderWeek>{format(setDay(Date.now(), 1), "MM/dd")}</HeaderWeek>
          )}
          <HeaderTotal>Total</HeaderTotal>
        </HeadersWeeks>

        <HoursPanel mondays={mondays.length}>
          {staff.map((staffItem, staffIndex) => (
            <RowHours
              key={`${staffIndex}-${staffItem.rowKey}`}
              data-testid={`${type}-hours-row-${staffIndex}`}
              className={
                hasHoursError(staffIndex, errors, touched) ? "with-error" : ""
              }
            >
              {staffItem.weeks &&
                staffItem.weeks.map((week, weekIndex) => (
                  <Hour
                    key={weekIndex}
                    aria-label={`Hours of row ${staffIndex} for the week ${format(
                      week.date,
                      "MM/dd",
                    )}`}
                    id={`${type}-hour-${weekIndex}-${staffIndex}`}
                    onMouseEnter={() => handleActiveCell(staffIndex)}
                  >
                    {inEditMode ? (
                      <GridInput
                        type="text"
                        value={week.hours || 0}
                        onChange={(event) =>
                          handleHoursChange(event, staffIndex, weekIndex)
                        }
                        onBlur={() => handleHoursBlur(staffIndex, weekIndex)}
                        aria-labelledby={`${type}-hour-${weekIndex}-${staffIndex}`}
                        data-testid={`${type}-hour-input-row-${staffIndex}-column-${weekIndex}`}
                        disabled={isSubmitting}
                        onFocus={(e) => {
                          setActiveHourCell({
                            rowKey: staffItem.rowKey,
                            week,
                            node: e.target,
                          });
                          triggerProps.onClick(e);
                        }}
                      />
                    ) : (
                      week.hours || 0
                    )}
                  </Hour>
                ))}
              <TotalRowHours onMouseEnter={() => handleActiveCell(staffIndex)}>
                {getHoursByUser(staffItem)}
              </TotalRowHours>
            </RowHours>
          ))}
          <RowHours>
            {mondays.length > 0 ? (
              mondays.map((_, index) => (
                <TotalColumnHours key={index}>
                  {totalHoursByWeek(index)}
                </TotalColumnHours>
              ))
            ) : (
              <TotalColumnHours>0</TotalColumnHours>
            )}
            <TotalRowHours>{totalHours()}</TotalRowHours>
          </RowHours>
        </HoursPanel>
      </Planning>
      <GridDelete>
        {staff.map((_, index) => {
          if (estimationIndex != index)
            return <DeleteIconWrapEmpty key={index} />;

          return (
            <DeleteIconWrap
              onClick={handleDeleteRow}
              data-testid={`${type}-remove-row-button`}
              key={index}
            >
              <CloseIcon />
            </DeleteIconWrap>
          );
        })}
      </GridDelete>
      <Tooltip style={{ minWidth: "0" }}>
        <CopyUntilDialog
          handleCopy={handleCopyHours}
          minDate={addWeeks(activeHourCell.week.date, 1)}
          maxDate={mondays[mondays.length - 1]}
        />
      </Tooltip>
    </Container>
  );
}

export default AllocationsGrid;
