import React from "react";
import PageLayout from "../layout/page-layout";
import { useLocation, useParams } from "react-router";
import ReactHtmlParser from "react-html-parser";
import { reportPageContainer } from "./report-page.module.scss";

async function loadReport(pathname, setReportHtml) {
  const response = await fetch(pathname);
  const { reportHtml } = await response.json();
  setReportHtml(reportHtml);
}

export default function ReportsPage() {
  const { reportId } = useParams();
  const { pathname } = useLocation();
  const [reportHtml, setReportHtml] = React.useState("");

  React.useEffect(() => {
    loadReport(pathname, setReportHtml);
  }, [pathname]);

  return (
    <PageLayout title={`Report ${reportId.slice(0, 4)}-${reportId.slice(4)}`}>
      <div className={reportPageContainer}>{ReactHtmlParser(reportHtml)}</div>
    </PageLayout>
  );
}
