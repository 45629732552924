import React from "react";
import { Field } from "formik";
import { useCollection } from "coreql";
import FormDropdown from "../form-dropdown";

function format(items) {
  return items.map((item) => ({
    value: item.id,
    content: item.attributes.name,
    meta: item,
  }));
}

function ProductDropdown({ onSelect, onReset, label, ...rest }) {
  const {
    data: { data: products },
  } = useCollection("products", {
    filters: { active: true },
    fields: {
      products: ["name", "projects"],
    },
    included: ["projects"],
  });

  return (
    <Field
      name="attributes.productId"
      component={FormDropdown}
      options={format(products)}
      onSelect={onSelect}
      onReset={onReset}
      label={label}
      {...rest}
    />
  );
}

export default ProductDropdown;
