import React from "react";
import { Frame } from "@ableco/baseline";
import clsx from "clsx";

export default function WeeklyQuestionCell({
  firstRow = false,
  lastRow = false,
  firstColumn = false,
  lastColumn = false,
  evenRow = false,
  children,
  className,
  ...props
}) {
  return (
    <Frame
      className={clsx(
        "border-neutral-300",
        firstRow && " border-t",
        lastRow && " border-b",
        firstColumn && " border-l",
        lastColumn && " border-r",
        firstRow && firstColumn && "rounded-tl",
        firstRow && lastColumn && "rounded-tr",
        lastRow && firstColumn && "rounded-bl",
        lastRow && lastColumn && "rounded-br",
        evenRow && "bg-neutral-200",
        className,
      )}
      p={4}
      {...props}
    >
      {children}
    </Frame>
  );
}
