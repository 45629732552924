import React from "react";

export default function LogoIcon(props) {
  return (
    <svg width="355px" height="115px" viewBox="0 0 355 115" {...props}>
      <defs>
        <path
          d="M57.5 0C25.744 0 0 25.743 0 57.5 0 89.256 25.744 115 57.5 115S115 89.256 115 57.5C115 25.743 89.256 0 57.5 0zM19.18 58.5c0-28.581 19.714-51.75 44.035-51.75 24.32 0 44.035 23.169 44.035 51.75 0 10.69-22.017 16.034-44.035 16.034-22.018 0-44.035-5.344-44.035-16.034z"
          id="logo-a"
        />
        <linearGradient
          x1="15.5637754%"
          y1="50%"
          x2="100%"
          y2="50%"
          id="logo-b"
        >
          <stop stopColor="#FD7586" offset="0%" />
          <stop stopColor="#FFCEA4" offset="100%" />
        </linearGradient>
        <path
          d="M8.196 6.228C3.615 10.729.645 17.099.645 24.764c0 10.58 43.583 10.58 43.583 0 0-7.518-2.821-13.618-7.236-18.035C32.895 2.632 27.514.53 22.16.53 17.113.531 12.091 2.399 8.196 6.228"
          id="logo-d"
        />
        <linearGradient
          x1="28.7462127%"
          y1="56.7401229%"
          x2="124.763131%"
          y2="33.2263591%"
          id="logo-e"
        >
          <stop stopColor="#FF7585" offset="0%" />
          <stop stopColor="#FFB89E" offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <mask id="logo-c" fill="#fff">
          <use xlinkHref="#logo-a" />
        </mask>
        <path
          d="M57.5 0C25.744 0 0 25.743 0 57.5 0 89.256 25.744 115 57.5 115S115 89.256 115 57.5C115 25.743 89.256 0 57.5 0zM19.18 58.5c0-28.581 19.714-51.75 44.035-51.75 24.32 0 44.035 23.169 44.035 51.75 0 10.69-22.017 16.034-44.035 16.034-22.018 0-44.035-5.344-44.035-16.034z"
          fill="url(#logo-b)"
          mask="url(#logo-c)"
        />
        <g transform="translate(44 29)">
          <mask id="logo-f" fill="#fff">
            <use xlinkHref="#logo-d" />
          </mask>
          <path
            d="M8.196 6.228C3.615 10.729.645 17.099.645 24.764c0 10.58 43.583 10.58 43.583 0 0-7.518-2.821-13.618-7.236-18.035C32.895 2.632 27.514.53 22.16.53 17.113.531 12.091 2.399 8.196 6.228"
            fill="url(#logo-e)"
            mask="url(#logo-f)"
          />
        </g>
        <path
          d="M166.843 86C150.168 86 138 74.195 138 57.444 138 40.804 150.168 29 166.843 29c11.154 0 17.915 4.834 21.52 9.781l-9.352 8.77c-2.591-3.823-6.534-5.847-11.492-5.847-8.675 0-14.76 6.296-14.76 15.74 0 9.444 6.085 15.852 14.76 15.852 4.958 0 8.901-2.249 11.492-5.959l9.352 8.77C184.758 81.053 177.997 86 166.843 86zm56.11 0c-17.915 0-28.731-13.154-28.731-28.556 0-15.403 10.816-28.444 28.73-28.444 18.027 0 28.843 13.041 28.843 28.444C251.795 72.846 240.98 86 222.952 86zm0-12.704c9.013 0 13.97-7.308 13.97-15.852 0-8.432-4.957-15.74-13.97-15.74-8.902 0-13.859 7.308-13.859 15.74 0 8.544 4.957 15.852 13.858 15.852zm53.855 11.355h-14.309V30.349h14.309v7.308c3.943-4.722 10.59-8.657 17.35-8.657v13.94c-1.013-.224-2.252-.336-3.943-.336-4.732 0-11.041 2.698-13.407 6.183v35.864zM328.636 86c-16.563 0-29.069-11.13-29.069-28.556 0-15.74 11.718-28.444 28.167-28.444C344.071 29 355 41.142 355 58.793v3.373h-40.448c1.014 6.633 6.422 12.142 15.66 12.142 4.62 0 10.93-1.912 14.422-5.284l6.423 9.443C345.648 83.414 337.086 86 328.636 86zm12.618-33.728c-.45-5.171-4.056-11.58-13.52-11.58-8.9 0-12.731 6.184-13.295 11.58h26.815z"
          fill="#F8F8F8"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
