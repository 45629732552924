import React from "react";
import { Color, Text, Touchable } from "@ableco/baseline";
import { isEmpty } from "lodash";
import { Info } from "@baseline/icons";
import { LEVEL_FIELDS } from "./capabilities-table-utils";

export default function LevelInfoButton({ level, onClick }) {
  const hasContent = LEVEL_FIELDS.some(({ key }) => !isEmpty(level[key]));

  if (!hasContent) return null;
  return (
    <Touchable onClick={onClick} className="group border-0 focus:outline-none">
      <Text
        color={Color.Neutral600}
        data-testid={`level-info-${level.id}`}
        className="inline-block align-top"
      >
        <Info
          title={`Level Info`}
          className="w-4 h-6 hover:text-neutral-800 transition-all duration-300 ease-in-out"
        />
      </Text>{" "}
    </Touchable>
  );
}
