import React from "react";
import { Inline, Stack, Text } from "@ableco/baseline";
import { CaretDown, CaretUp } from "@baseline/icons";

export default function SortHeader({ column, header }) {
  const colorAsc =
    column.isSorted && !column.isSortedDesc ? "neutral-800" : "neutral-600";
  const colorDes =
    column.isSorted && column.isSortedDesc ? "neutral-800" : "neutral-600";
  const headerColor = column.isSorted ? "neutral-800" : "neutral-600";
  return (
    <Inline>
      <Text
        color={headerColor}
        leading="normal"
        size="sm"
        whitespace="no-wrap"
        className="hover:text-neutral-800 select-none"
      >
        {header}
      </Text>
      <Stack p={[0, 2]}>
        <Text color={colorAsc}>
          <CaretUp className="w-2 h-2 " />
        </Text>
        <Text color={colorDes}>
          <CaretDown className="w-2 h-2" />
        </Text>
      </Stack>
    </Inline>
  );
}
