import { makeTitlesDictionaryFrom } from "../components/highlights/highlights";
import { compact, keyBy, mapValues, uniq } from "lodash";
import { useMemo } from "react";
import { useCollection } from "coreql";
import { useSWRSuspense } from "swr";
import { wrap } from "../components/weekly-report/utils";

function mapCollectionDataToFieldValue(data, field) {
  return wrap(data).map((item) => item[field]);
}

function useDisciplinesTitles(userProfile) {
  const allDisciplines = useMemo(
    () =>
      uniq(
        compact([
          userProfile.role?.disciplineId,
          ...mapCollectionDataToFieldValue(
            userProfile.disciplineLeadersResponsibilities,
            "discipline",
          ),
        ]),
      ),
    [userProfile],
  );

  const { data } = useCollection(
    "disciplines",
    { filters: { id: allDisciplines }, fields: { disciplines: ["title"] } },
    "denormalized",
  );

  return data;
}

function useProjectRoleTitles(userProfile) {
  const allProjectRoles = mapCollectionDataToFieldValue(
    userProfile.projectRoleResponsibilities,
    "role",
  );

  const { data } = useCollection(
    "project-roles",
    { filters: { id: allProjectRoles }, fields: { projectRole: ["name"] } },
    "denormalized",
  );

  return data;
}

function useDepartmentTitles(userProfile) {
  const allDepartments = mapCollectionDataToFieldValue(
    userProfile.departmentLeadersResponsibilities,
    "department",
  );
  const { data } = useCollection(
    "departments",
    { filters: { id: allDepartments }, fields: { department: ["title"] } },
    "denormalized",
  );

  return data;
}

function useUserResponsibilityGroups({ userProfile, responsibilities }) {
  const [projectRoleTitles, disciplineTitles, departmentTitles] =
    useSWRSuspense(() => [
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useProjectRoleTitles(userProfile),
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDisciplinesTitles(userProfile),
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDepartmentTitles(userProfile),
    ]);

  const responsibilitiesGroups = useMemo(() => {
    const [projectRoles, disciplines, departments] = [
      projectRoleTitles,
      disciplineTitles,
      departmentTitles,
    ].map(makeTitlesDictionaryFrom);

    const {
      projectRoleResponsibilities,
      roleResponsibilities,
      disciplineLeadersResponsibilities,
      disciplineResponsibilities,
      departmentLeadersResponsibilities,
      departmentResponsibilities,
      individualContributorsResponsibilities,
      managersResponsibilities,
      commonResponsibilities,
    } = mapValues(userProfile, wrap);
    const groups = [];

    if (projectRoleResponsibilities.length > 0)
      groups.push(
        ...projectRoleResponsibilities.map(({ role, responsibilities }) => ({
          groupTitle: `Responsibilities unique for ${projectRoles[role]}`,
          associatedTypeId: role,
          responsibilities,
        })),
      );

    if (userProfile.role)
      groups.push({
        groupTitle: `Responsibilities unique to ${userProfile.role.pluralTitle}`,
        // for some reason this one comes back as a string
        associatedTypeId: Number(userProfile.role.id),
        responsibilities: roleResponsibilities,
      });

    if (disciplineLeadersResponsibilities.length > 0)
      groups.push(
        ...disciplineLeadersResponsibilities.map(
          ({ discipline, responsibilities }) => ({
            groupTitle: `Responsibilities unique for the ${disciplines[discipline]} discipline leader`,
            associatedTypeId: discipline,
            responsibilities,
          }),
        ),
      );

    if (userProfile.role)
      groups.push({
        responsibilities: disciplineResponsibilities,
        associatedTypeId: userProfile.role.disciplineId,
        groupTitle: `Responsibilities for all members of ${userProfile.role.disciplineTitle} discipline`,
      });

    if (departmentLeadersResponsibilities.length > 0)
      groups.push(
        ...departmentLeadersResponsibilities.map(
          ({ department, responsibilities }) => ({
            groupTitle: `Responsibilities unique for the ${departments[department]} department leader`,
            associatedTypeId: department,
            responsibilities,
          }),
        ),
      );

    if (userProfile.role)
      groups.push({
        groupTitle: `Responsibilities for all members of ${userProfile.role.departmentTitle} department`,
        associatedTypeId: userProfile.role.departmentId,
        responsibilities: departmentResponsibilities,
      });

    if (!userProfile.isManager)
      groups.push({
        groupTitle: "Responsibilities for all Individual Contributors",
        responsibilities: individualContributorsResponsibilities,
      });

    if (userProfile.isManager)
      groups.push({
        groupTitle: "Responsibilities for all Managers",
        responsibilities: managersResponsibilities,
      });

    if (commonResponsibilities.length > 0)
      groups.push({
        groupTitle: "Responsibilities common to all Able employees",
        responsibilities: commonResponsibilities,
      });

    const responsibilitiesById = keyBy(responsibilities, "id");

    function selectNonEmptyGroups({ responsibilities }) {
      return responsibilities.length > 0;
    }

    function linkResponsibilities({ responsibilities, ...rest }) {
      return {
        responsibilities: responsibilities.map(
          (id) => responsibilitiesById[id],
        ),
        ...rest,
      };
    }

    return groups.filter(selectNonEmptyGroups).map(linkResponsibilities);
  }, [
    userProfile,
    responsibilities,
    projectRoleTitles,
    disciplineTitles,
    departmentTitles,
  ]);

  return responsibilitiesGroups;
}

export default useUserResponsibilityGroups;
