// Modules
import { connect } from "react-redux";
// Components
import App from "./App";

const mapStateToProps = (state, _ownProps) => {
  const { resourceNotFound } = state.errors;
  const triggerDefaultRedirect = !state.ui.filters.valid;

  return {
    resourceNotFound,
    triggerDefaultRedirect,
  };
};

export default connect(mapStateToProps, {})(App);
