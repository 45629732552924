// Middleware
import { CALL_API } from "core-redux/middleware/api";

export const batchCreate = (resource, attributes = {}) => {
  const payload = {};
  payload[`${resource}`] = attributes;

  return {
    [CALL_API]: {
      resource,
      method: "batchCreate",
      payload,
    },
  };
};

export const batchDestroy = (resource, ids) => ({
  [CALL_API]: {
    resource,
    method: "batchDestroy",
    payload: {
      ids,
    },
  },
});

export const batchUpdate = (resource, attributes = {}) => {
  const payload = {};
  payload[`${resource}`] = attributes;

  return {
    [CALL_API]: {
      resource,
      method: "batchUpdate",
      payload,
    },
  };
};

export const list = (resource, params = {}) => ({
  [CALL_API]: {
    resource,
    method: "list",
    params,
  },
});

export const retrieve = (resource, id, params = {}) => ({
  [CALL_API]: {
    resource,
    method: "retrieve",
    objectId: id,
    params,
  },
});

export const create = (resource, attributes = {}) => ({
  [CALL_API]: {
    resource,
    method: "create",
    payload: attributes,
  },
});

export const update = (resource, id, attributes = {}) => ({
  [CALL_API]: {
    resource,
    method: "update",
    objectId: id,
    payload: attributes,
  },
});

export const archive = (resource, id) => ({
  [CALL_API]: {
    resource,
    method: "archive",
    objectId: id,
    payload: { archivedAt: true },
  },
});

export const destroy = (resource, id, params = {}) => ({
  [CALL_API]: {
    resource,
    method: "destroy",
    objectId: id,
    params,
  },
});
