import React from "react";
import { capitalize } from "lodash";
import Tag from "../tag";
import clsx from "clsx";

export default function HighlightTypeTag({ selectedFeedbackType }) {
  const feedbackType = {
    win: {
      type: "success",
      className: "w-10",
    },
    opportunity: {
      type: "opportunity",
      className: "w-24",
    },
    general: {
      type: "general",
      className: "w-20",
    },
  };

  return (
    <Tag
      text={capitalize(selectedFeedbackType)}
      className={clsx(
        "mb-1 mr-1",
        feedbackType[selectedFeedbackType].className,
      )}
      type={feedbackType[selectedFeedbackType].type}
    />
  );
}
