import React from "react";

import { api, denormalize, useCollection } from "coreql";
import { Frame, Stack, Color } from "@ableco/baseline";

import {
  TitleSmall,
  BodyBaseline,
  BodyBaselineSemibold,
  BodySmall,
} from "../design-system/typography-components";
import { TextArea } from "../text-input";
import AutosaveTextInput, {
  LoadingStateIcon,
} from "../data-entry/autosave-text-input";

function useCompanyFeedback(weeklyReportId) {
  const {
    data: [companyFeedback],
    mutate,
  } = useCollection(
    "weekly-report-answers",
    {
      filters: {
        weeklyReport: weeklyReportId,
        answerType: "feedback_company",
      },
    },
    "denormalized",
  );
  return [companyFeedback, mutate];
}

function CompanyFeedbackSection({
  weeklyReportId,
  setIsCompanyFeedbackGiven,
  dataTestId,
}) {
  const [companyFeedback, mutate] = useCompanyFeedback(weeklyReportId);
  const [text, setText] = React.useState(companyFeedback?.data?.text || "");

  async function handleSave(id, inputText) {
    if (inputText.trim()) {
      const response = await api.weeklyReportAnswers.find(id).upsert(
        {
          data: { text: inputText.trim() },
        },
        {
          weeklyReportId,
          answerType: "feedback_company",
        },
        [{ name: "weeklyReport", resource: "weekly-report" }],
      );

      await mutate(
        async () => denormalize(response, "weeklyReportAnswers", true),
        false,
      );

      return response.data.id;
    } else {
      await api.weeklyReportAnswers.find(id).destroy();
    }
  }

  React.useEffect(() => {
    if (text) {
      setIsCompanyFeedbackGiven(true);
    }
  }, [text, setIsCompanyFeedbackGiven]);

  return (
    <Frame className="mt-10 mb-6" data-testid={dataTestId}>
      <TitleSmall
        as="h3"
        className="mb-4"
        color={Color.Neutral600}
        id="feedback-for-able"
      >
        Feedback for Able
      </TitleSmall>
      <BodyBaselineSemibold as="h4" className="mb-2" color={Color.Neutral800}>
        How can we improve?{" "}
        <BodyBaseline color={Color.Neutral600}>(Optional)</BodyBaseline>
      </BodyBaselineSemibold>
      <AutosaveTextInput
        resourceId={companyFeedback?.id}
        value={text}
        onChange={(text) => setText(text)}
        onSave={handleSave}
        getSaveStatus={LoadingStateIcon}
      >
        {(text, autoSaveProps, status) => (
          <>
            <Stack className="relative">
              <TextArea
                labelledby="feedback-for-able"
                placeholder="Please share your ideas to improve your team, your role, or the organization. Or just take a moment to share the latest wins or challenges that you have noticed and think should be highlighted."
                {...autoSaveProps}
                value={text}
              />
              <Frame className="absolute left-100 bottom-0 mb-2 ml-2">
                {status}
              </Frame>
            </Stack>
          </>
        )}
      </AutosaveTextInput>
      <BodySmall color={Color.Neutral600}>
        This feedback will only be visible to the CEO.
      </BodySmall>
    </Frame>
  );
}

export default CompanyFeedbackSection;
