import React, { useCallback } from "react";
import { Text, Stack, Color, Frame, Corners, Inline } from "@ableco/baseline";
import PromptQuestionType from "./prompt-question-type";
import { Info } from "@baseline/icons";
import { api } from "coreql";
import { FeedbackCardHeader } from "./feedback-message-card";
import {
  BodyBaseline,
  BodyBaselineSemibold,
  BodySmall,
} from "../design-system/typography-components";
import { formatDistanceToNow } from "date-fns";
import useAutosavetextInput from "../../hooks/use-autosave-text-input";
import { CheckboxWithLabel } from "../data-entry/checkbox-with-label";
import Linkify from "../../utils/linkify";
import { TextArea } from "../text-input";
import { CoreTooltip } from "../core-tooltip";
import { useCoreToast } from "../toast/toast";

const BINARY = "binary";
const SCALE = "scale";

function replaceName(string, value) {
  return string.replace(/\[name]/gi, value);
}

function SavedState({ loadError, isLoading, savedAt, hasUnsavedChanges }) {
  function SavedText({ children }) {
    return <BodySmall color={Color.Neutral700}>{children}</BodySmall>;
  }
  if (loadError) {
    return <SavedText>Not saved</SavedText>;
  }

  if (isLoading) {
    return <SavedText>Saving...</SavedText>;
  }

  if (hasUnsavedChanges) {
    return <SavedText>Unsaved changes</SavedText>;
  }

  return savedAt ? (
    <SavedText>Saved {formatDistanceToNow(savedAt)} ago</SavedText>
  ) : (
    <SavedText>Never saved</SavedText>
  );
}

function FeedbackPrompt({
  feedbackRequest,
  handlePromptChange,
  value,
  disabled,
}) {
  return (
    <Stack space={2} className="mb-2">
      <BodyBaselineSemibold>
        {replaceName(
          feedbackRequest.feedbackPrompt.prompt,
          feedbackRequest.sender.preferredName,
        )}
        <CoreTooltip
          title="Related responsibility:"
          label={feedbackRequest.feedbackPrompt.responsibility.title}
        >
          <Text>
            <Info
              as="span"
              title="Some text"
              className="w-4 h-4 mb-1 align-bottom text-neutral-600 hover:text-black inline-block"
            />
          </Text>
        </CoreTooltip>
      </BodyBaselineSemibold>
      <PromptQuestionType
        requestFeedback={feedbackRequest}
        handleChange={handlePromptChange}
        promptAnswer={value}
        type={feedbackRequest.feedbackPrompt?.promptType}
        scale={feedbackRequest.feedbackPrompt?.scaleType}
        disabled={disabled}
      />
    </Stack>
  );
}

function FeedbackRequestForm({
  onDismiss,
  feedbackRequest,
  feedbackResponse,
  refreshData,
  feedbackDismissed,
  weeklyReportDateStart,
  source,
}) {
  const [bodyMessage, setBodyMessage] = React.useState(
    feedbackResponse?.body || "",
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadError, setLoadError] = React.useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const [savedAt, setSavedAt] = React.useState(() =>
    feedbackResponse?.updatedAt ? new Date(feedbackResponse?.updatedAt) : null,
  );
  const [savedBody, setSavedBody] = React.useState(bodyMessage);
  const [responseId, setResponseId] = React.useState(feedbackResponse?.id);
  const [promptAnswer, setPromptAnswer] = React.useState(
    feedbackResponse?.promptAnswer,
  );
  const [requestDismissed, setRequestDismissed] =
    React.useState(feedbackDismissed);
  const receiverId = feedbackRequest.senderId;
  const senderId = feedbackRequest.receiverId;
  const feedbackRequestId = feedbackRequest.id;
  const requestedByManager =
    feedbackRequest.author &&
    String(feedbackRequest.author.id) !== String(feedbackRequest.sender.id);

  const handleChange = useCallback((inputValue) => {
    setBodyMessage(inputValue);
  }, []);

  const handleSave = useCallback(
    async (event) => {
      try {
        const text = event.target.value;
        setIsLoading(true);
        setLoadError(false);
        const { data } = await api.feedbackMessages.find(responseId).upsert(
          { body: text.trim(), source },
          {
            receiverId,
            senderId,
            feedbackRequestId,
          },
        );
        refreshData();
        setResponseId(data.id);
        setSavedBody(data.attributes.body);
        setIsLoading(false);
        setHasUnsavedChanges(false);
        setSavedAt(Date.now());
      } catch {
        setIsLoading(false);
        setLoadError(true);
      }
    },
    [responseId, receiverId, senderId, feedbackRequestId, refreshData, source],
  );

  const autosaveInputProps = useAutosavetextInput({
    onSave: handleSave,
    onChange: useCallback(
      (event) => {
        setHasUnsavedChanges(savedBody !== event.target.value);
        handleChange(event.target.value, feedbackResponse);
      },
      [handleChange, feedbackResponse, savedBody],
    ),
  });

  async function handlePromptChange(newValue) {
    setPromptAnswer(newValue);
    setHasUnsavedChanges(true);
    try {
      setIsLoading(true);
      setLoadError(false);
      const { data } = await api.feedbackMessages.find(responseId).upsert(
        { promptAnswer: newValue, source },
        {
          receiverId,
          senderId,
          feedbackRequestId,
        },
      );
      refreshData();
      setResponseId(data.id);
      setIsLoading(false);
      setHasUnsavedChanges(false);
      setSavedAt(Date.now());
    } catch {
      setIsLoading(false);
      setLoadError(true);
    }
  }

  function getInvestmentTime() {
    const promptType = feedbackRequest.feedbackPrompt?.promptType;
    if (promptType === BINARY) return "Less than 5 minutes";
    if (promptType === SCALE) return "5 minutes";
    return "10 minutes";
  }

  React.useEffect(() => {
    onDismiss(feedbackRequestId, requestDismissed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestDismissed]);

  const { successToast, errorToast } = useCoreToast();
  const feedbackDeferral = feedbackRequest.feedbackRequestDeferral;
  const [isDeferred, setIsDeferred] = React.useState(
    feedbackDeferral &&
      new Date(feedbackDeferral.deferredAt) >= new Date(weeklyReportDateStart),
  );

  async function toogleRequestDeferral() {
    const currentStatus = isDeferred;
    try {
      if (isDeferred) {
        setIsDeferred(false);
        await api.feedbackRequestDeferrals.find(feedbackDeferral.id).destroy();
        await refreshData();
        successToast("Removed feedback request deferred");
      } else {
        setIsDeferred(true);
        await api.feedbackRequestDeferrals.create({
          deferredAt: weeklyReportDateStart,
          feedbackRequestId: feedbackRequest.id,
        });
        await refreshData();
        successToast("Feedback Request deferred to next week");
      }
    } catch {
      setIsDeferred(currentStatus);
      errorToast("Something went wrong with the deferral");
    }
  }
  return (
    <Frame className="relative">
      {requestedByManager && (
        <Frame className="mb-2">
          <BodyBaseline color={Color.Neutral700}>
            Feedback requested by {feedbackRequest.author?.fullName}
          </BodyBaseline>
        </Frame>
      )}
      <Stack
        border={Color.Neutral300}
        corners={Corners.MediumRounded}
        space={6}
        className="pb-5"
      >
        <FeedbackCardHeader
          feedbackRequestId={feedbackRequestId}
          headerUser={feedbackRequest.sender}
          displayRole={true}
          isDeferred={isDeferred}
          feedbackDeferral={feedbackDeferral}
          toogleRequestDeferral={toogleRequestDeferral}
        />
        <Frame p={[0, 6]}>
          {feedbackRequest.body?.trim() && (
            <Frame className="border-l-4 border-neutral-400 mb-6" p={[0, 4]}>
              <BodyBaseline
                as="blockquote"
                color={Color.Neutral600}
                data-testid="request-body-blockquote"
                wordBreak="words"
              >
                <Linkify>{feedbackRequest.body}</Linkify>
              </BodyBaseline>
            </Frame>
          )}
          {feedbackRequest.feedbackPrompt && (
            <FeedbackPrompt
              feedbackRequest={feedbackRequest}
              handlePromptChange={handlePromptChange}
              disabled={requestDismissed || isDeferred}
              value={promptAnswer}
            />
          )}
          <Stack space={2}>
            <TextArea
              value={bodyMessage}
              disabled={requestDismissed || isDeferred}
              data-testid={`input-response-${feedbackRequestId}`}
              placeholder="Please describe the situation (where/when), the observable behavior (don't assume or be subjective), and the impact (how it affected you or others)"
              aria-label="Write a feedback message"
              {...autosaveInputProps}
            />
            <Inline distribution="between">
              <BodySmall color={Color.Neutral700}>
                Recommended time investment: {getInvestmentTime()}
              </BodySmall>
              <SavedState
                hasUnsavedChanges={hasUnsavedChanges}
                isLoading={isLoading}
                savedAt={savedAt}
                loadError={loadError}
              />
            </Inline>
          </Stack>
          <Frame className="mt-6">
            <CheckboxWithLabel
              checked={requestDismissed}
              onChange={() => setRequestDismissed((prev) => !prev)}
              data-testid={`checkbox-${feedbackRequestId}`}
            >
              <BodyBaseline color={Color.Neutral700}>
                I do not have any data to respond to this feedback request.
              </BodyBaseline>
            </CheckboxWithLabel>
          </Frame>
        </Frame>
      </Stack>
    </Frame>
  );
}

export default FeedbackRequestForm;
