// Modules
import { combineReducers } from "redux";
import produce from "immer";
import uniq from "lodash/uniq";
// Constants
import ActionTypes from "core-redux/constants/ActionTypes";
import ResourceTypes from "core-redux/constants/ResourceTypes";

export default combineReducers(
  ResourceTypes.reduce(
    (objectsMap, resource) => ({
      ...objectsMap,
      [resource]: createResourceReducer(resource),
    }),
    {},
  ),
);

function createResourceReducer(resource) {
  return (state = { byId: {}, ids: [] }, action) => {
    if (action.responseResourceType !== resource) {
      return state;
    }

    switch (action.type) {
      case ActionTypes.API.CREATE_REQUEST:
      case ActionTypes.API.DESTROY_FAILURE:
        return produce(state, (draft) => {
          draft.ids = uniq(draft.ids.concat(action.payload.id));
          draft.byId[action.payload.id] = action.payload;
        });

      case ActionTypes.API.CREATE_SUCCESS:
        return produce(state, (draft) => {
          // Remove the temporary item.
          draft.ids.splice(draft.ids.indexOf(action.id), 1);
          delete draft.byId[action.id];

          draft.ids = uniq(draft.ids.concat(action.payload.id));
          draft.byId[action.payload.id] = action.payload;
        });

      case ActionTypes.API.CREATE_FAILURE:
        return produce(state, (draft) => {
          // Remove the temporary item.
          draft.ids.splice(draft.ids.indexOf(action.id), 1);
          delete draft.byId[action.id];
        });

      case ActionTypes.API.RETRIEVE_SUCCESS:
      case ActionTypes.API.UPDATE_REQUEST:
      case ActionTypes.API.UPDATE_SUCCESS:
        return produce(state, (draft) => {
          draft.ids = uniq(draft.ids.concat(action.payload.id));
          draft.byId[action.payload.id] = Object.assign(
            {},
            { ...draft.byId[action.payload.id] },
            { ...action.payload },
          );
        });

      case ActionTypes.API.BATCH_UPDATE_REQUEST:
        return produce(state, (draft) => {
          draft.ids = uniq(draft.ids.concat(Object.keys(action.payload)));
          draft.byId = {
            ...draft.byId,
            ...Object.keys(action.payload).reduce(
              (objectsMap, itemId) => ({
                ...objectsMap,
                [itemId]: action.payload[itemId],
              }),
              {},
            ),
          };
        });

      case ActionTypes.API.BATCH_CREATE_SUCCESS:
      case ActionTypes.API.BATCH_UPDATE_SUCCESS:
      case ActionTypes.API.LIST_SUCCESS:
        return produce(state, (draft) => {
          draft.ids = uniq(
            draft.ids.concat(getIdsFromCollection(action.payload)),
          );
          draft.byId = {
            ...draft.byId,
            ...getMapFromCollection(action.payload),
          };
        });

      case ActionTypes.API.ARCHIVE_REQUEST:
      case ActionTypes.API.ARCHIVE_SUCCESS:
      case ActionTypes.API.DESTROY_REQUEST:
        return produce(state, (draft) => {
          draft.ids.splice(draft.ids.indexOf(action.payload.id), 1);
          delete draft.byId[action.payload.id];
        });

      // TODO: Address failure cases.
      case ActionTypes.API.BATCH_UPDATE_FAILURE:
      default:
        return state;
    }
  };
}

function getIdsFromCollection(collection) {
  return collection.map((item) => item.id);
}

function getMapFromCollection(collection) {
  return collection.reduce(
    (objectsMap, item) => ({ ...objectsMap, [item.id]: item }),
    {},
  );
}
