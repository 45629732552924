import {
  Frame,
  Inline,
  Stack,
  Text,
  Color,
  Skeleton,
  Corners,
} from "@ableco/baseline";
import { useResource, SSRSuspense, ErrorBoundary } from "coreql";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import useCurrentUser from "../../../hooks/use-current-user";
import { Heading } from "../../design-system/typography-components";
import UserLayout, {
  Fallback as UserLayoutFallback,
} from "../../layout/user-layout";
import { wrap } from "../../weekly-report/utils";

import UserBadgeSections from "./user-badge-sections";

function BaseSkeleton(props) {
  return (
    <Skeleton
      color={Color.Neutral400}
      corners={Corners.LargeRounded}
      animated
      {...props}
    />
  );
}

function UserBadgesFallback() {
  return (
    <>
      <UserLayoutFallback />
      <Stack p={[0, 0, 8, 0]} space={8} as="article">
        <Stack space={2}>
          <Frame p={[0, 8]}>
            <Skeleton
              color={Color.Neutral400}
              corners={Corners.LargeRounded}
              animated
              width="280px"
              height="25px"
            />
          </Frame>
        </Stack>
        <Frame bg={Color.White} p={[0, 8]}>
          <Stack space={4} p={[4, 0]}>
            <BaseSkeleton width="180px" height="20px" />
            <Inline>
              <Frame className="w-1/3">
                <BaseSkeleton width="120px" height="15px" />
              </Frame>
            </Inline>
          </Stack>
        </Frame>
        <Frame p={[0, 8]}>
          <Frame className={`border border-neutral-300 rounded`}></Frame>
        </Frame>
      </Stack>
    </>
  );
}

export default function UserBadges() {
  const { id: userId } = useParams();
  const { data: user } = useResource(
    "users",
    userId,
    {
      fields: {
        users: ["isManager", "level", "role", "userBadges", "managerId"],
        levels: ["level", "kind", "name", "code"],
        roles: ["title", "kind", "levels"],
        userBadges: ["badgeId", "status", "awardedAt"],
      },
      included: ["level", "role", "userBadges", "role.levels"],
    },
    "denormalized",
  );

  const assessmentsByBadgeId = useMemo(
    () => keyBy(wrap(user.userBadges), "badgeId"),
    [user],
  );

  const hasRole = !!user?.role?.id;
  const hasLevel = !!user?.level?.id;
  const userRole = user?.role;
  const showRoleBadges = hasRole && hasLevel;
  const hasICLevel = hasLevel && user.level.kind === "individual_contributor";
  const showManagerBadges = user.isManager && hasICLevel;

  const { data: currentUser } = useCurrentUser();

  const isDirectReport = currentUser.id == user.managerId;
  const isDirectReportOrSelf = isDirectReport || currentUser.id === user.id;

  return (
    <ErrorBoundary fallback={UserBadgesFallback}>
      <SSRSuspense fallback={<UserBadgesFallback />}>
        <UserLayout id={userId}>
          <Stack>
            <Heading as="h1">
              {currentUser.id === user.id ? "My Badges" : "Badges"}
            </Heading>
            {showRoleBadges || showManagerBadges ? (
              <UserBadgeSections
                assessmentsByBadgeId={assessmentsByBadgeId}
                showManagerBadges={showManagerBadges}
                user={user}
                userRole={userRole}
                showRoleBadges={showRoleBadges}
                isDirectReport={isDirectReport}
                isDirectReportOrSelf={isDirectReportOrSelf}
              />
            ) : (
              <Frame p={[8, 0]}>
                <Text color="neutral-800" as="p">
                  There are no badges created for this role
                </Text>
              </Frame>
            )}
          </Stack>
        </UserLayout>
      </SSRSuspense>
    </ErrorBoundary>
  );
}
