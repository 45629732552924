import React, { useEffect } from "react";
import {
  Center,
  Color,
  Frame,
  Grid,
  Inline,
  Stack,
  Text,
  Touchable,
  Flex,
  FlexDistribution,
  Corners,
  Cell as CellBaseline,
} from "@ableco/baseline";
import { useParams } from "react-router";
import {
  BodyBaseline,
  BodySmall,
  BodySmallSemibold,
} from "../design-system/typography-components";
import { useHistory } from "react-router-dom";
import { parseISO } from "date-fns";
import { ArrowLeft } from "@baseline/icons";
import { denormalize } from "coreql";
import { findIndex } from "lodash";

import { formatSubmittedDateWithoutTime } from "../../utils/format-submitted-date";
import { FeedbackMessageCard } from "../feedback/feedback-message-card";

import useCurrentUser from "../../hooks/use-current-user";
import {
  updateMessageReadByManager,
  updateMessageReadByReceiver,
  isMessageUnreadByCurrentUser,
} from "./feedback-messages-utils";
import { AvatarMedium } from "../design-system/avatar";

export function BulletUnread({ className, ...props }) {
  return (
    <Frame
      className={`w-2 h-2 flex-shrink-0 ${className}`}
      corners={Corners.FullRounded}
      bg={Color.Primary}
      {...props}
    />
  );
}

export function BulletReply({ id }) {
  return (
    <Center
      className="w-4 h-4 rounded-full mx-1 flex-shrink-0 absolute -ml-1 -mt-1"
      bg={Color.Neutral500}
      border={Color.Neutral100}
      data-testid="feedbackitem-requestreply"
      data-feedbackid={id}
    >
      <Text color={Color.White}>
        <ArrowLeft className="w-3 h-3" />
      </Text>
    </Center>
  );
}

function ItemMessage({
  id,
  submittedAt,
  typeMessage,
  message,
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
  isSelected,
  selectMessage,
}) {
  const notification =
    typeMessage === "received" &&
    isMessageUnreadByCurrentUser(
      isCurrentUserDirectManager,
      isCurrentUserInboxOwner,
      message.readByManager,
      message.readByReceiver,
    );

  const avatarUrl =
    typeMessage === "received"
      ? message.sender.avatarUrl
      : message.receiver.avatarUrl;

  const fullName =
    typeMessage === "received"
      ? message.sender.fullName
      : message.receiver.fullName;

  const isRequestReply = !!message.feedbackRequest;

  return (
    <Touchable
      className="p-5 text-left border-b border-neutral-300 transition-all duration-300 ease-in-out"
      bg={isSelected ? "primary-lighter" : "white"}
      onClick={() => selectMessage(id)}
      data-testid={`feedback-${id}`}
    >
      <Inline space={3}>
        <Flex className="w-12 relative">
          {isRequestReply && <BulletReply id={id} />}
          <AvatarMedium url={avatarUrl} name={fullName} className={"ml-0"} />
        </Flex>
        <Inline distribution={FlexDistribution.Between} className="w-full">
          <Stack>
            <BodySmall data-testid="feedbackitem-fullname">
              {fullName}
            </BodySmall>
            <BodySmallSemibold data-testid="feedbackitem-submitteddate">
              {formatSubmittedDateWithoutTime(submittedAt)}
            </BodySmallSemibold>
          </Stack>
          {notification && (
            <Center>
              <BulletUnread
                className="self-center mx-1"
                data-testid={`notification-bullet-${id}`}
              />
            </Center>
          )}
        </Inline>
      </Inline>
    </Touchable>
  );
}

function ListOfMessages({
  messages,
  typeMessages,
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
  messageSelected,
}) {
  let history = useHistory();

  let { userId } = useParams();

  function handleClickMessage(message) {
    history.push(`/users/${userId}/feedback_messages/${message.id}`);
  }

  return (
    <Stack>
      {messages
        .map((message) => (
          <ItemMessage
            key={message.id}
            id={message.id}
            message={
              messageSelected?.id == message.id ? messageSelected : message
            }
            submittedAt={parseISO(message.submittedAt)}
            isCurrentUserDirectManager={isCurrentUserDirectManager}
            isCurrentUserInboxOwner={isCurrentUserInboxOwner}
            typeMessage={typeMessages}
            isSelected={messageSelected?.id == message.id}
            isRequestReply={!!message.feedbackRequest}
            selectMessage={() => handleClickMessage(message)}
          />
        ))
        .reverse()}
    </Stack>
  );
}

function MessageContent({
  message,
  typeMessage,
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
  mutateSelectedMessage,
  mutateAllMessage,
  unreadMessagesCount,
}) {
  let { data: currentUser, mutate: mutateCurrentUser } = useCurrentUser();
  useEffect(() => {
    if (typeMessage !== "received") {
      return;
    }

    if (isCurrentUserDirectManager && !message.readByManager) {
      updateMessageReadByManager(message.id)
        .then((response) => denormalize(response, "feedbackMessages"))
        .then(({ readByManager }) => {
          mutateSelectedMessage(async (cache) => ({
            ...cache,
            readByManager: readByManager,
          }));

          mutateAllMessage(async (messagesCached) => {
            const index = findIndex(messagesCached, ["id", message.id]);
            const message = messagesCached[index];

            return [
              ...messagesCached.slice(0, index),
              { ...message, readByManager: readByManager },
              ...messagesCached.slice(index + 1),
            ];
          });
        });
    } else if (isCurrentUserInboxOwner && !message.readByReceiver) {
      updateMessageReadByReceiver(message.id)
        .then((response) => denormalize(response, "feedbackMessages"))
        .then(({ readByReceiver }) => {
          mutateSelectedMessage(async (messagesCached) => ({
            ...messagesCached,
            readByReceiver: readByReceiver,
          }));

          mutateAllMessage(async (messagesCached) => {
            const index = findIndex(messagesCached, ["id", message.id]);
            const message = messagesCached[index];

            return [
              ...messagesCached.slice(0, index),
              { ...message, readByReceiver: readByReceiver },
              ...messagesCached.slice(index + 1),
            ];
          });

          if (unreadMessagesCount === 1) {
            mutateCurrentUser(async (currentUserCached) => ({
              ...currentUserCached,
              hasUnreadFeedback: false,
            }));
          }
        });
    }
  }, [
    typeMessage,
    isCurrentUserDirectManager,
    isCurrentUserInboxOwner,
    message,
    mutateSelectedMessage,
    mutateAllMessage,
    mutateCurrentUser,
    unreadMessagesCount,
  ]);

  return (
    <Frame className="pb-8">
      <FeedbackMessageCard
        key={message.id}
        request={message.feedbackRequest}
        feedbackPrompt={message.feedbackRequest?.feedbackPrompt}
        headerUser={message.sender}
        response={message}
        isLikeable={isCurrentUserInboxOwner && typeMessage === "received"}
        isReviewable={false}
        addToOneOnOneEnabled={false}
        currentUser={currentUser}
        displaySubmittedTime={true}
        highlightingEnabled={
          typeMessage === "received" && isCurrentUserDirectManager
        }
        showReceiverActions={typeMessage === "sent"}
        refetch={mutateSelectedMessage}
        displayRequestBodyAuthorLabel={true}
        showResponsibilityInfoTooltip={true}
        displayCoachingTip={typeMessage === "sent"}
        addCoachingTipEnabled={
          typeMessage === "sent" && isCurrentUserDirectManager
        }
        borderEnable={false}
      />
    </Frame>
  );
}

function getUnreadMessageCount(
  feedbackMessages,
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
) {
  return feedbackMessages.filter((message) =>
    isMessageUnreadByCurrentUser(
      isCurrentUserDirectManager,
      isCurrentUserInboxOwner,
      message.readByManager,
      message.readByReceiver,
    ),
  ).length;
}

export function FeedbackMessageContainerLayout({
  textNoFeedback,
  typeMessages,
  feedbackMessages,
  defaultMessageContent,
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
  messageSelected,
  mutateSelectedMessage,
  mutateAllMessage,
}) {
  return (
    <Frame className="pb-4">
      <Grid
        border={Color.Neutral300}
        className="divide-x divide-neutral-300 mt-10 rounded"
        col={12}
      >
        <CellBaseline
          start={1}
          end={5}
          className="md:col-end-4 overflow-y-auto"
          style={{ height: "35rem" }}
        >
          {feedbackMessages.length > 0 ? (
            <ListOfMessages
              messages={feedbackMessages}
              typeMessages={typeMessages}
              isCurrentUserDirectManager={isCurrentUserDirectManager}
              isCurrentUserInboxOwner={isCurrentUserInboxOwner}
              messageSelected={messageSelected}
            />
          ) : (
            <Center className="h-full">
              <BodyBaseline color={Color.Neutral700} className="text-center">
                {textNoFeedback}
              </BodyBaseline>
            </Center>
          )}
        </CellBaseline>
        <CellBaseline
          start={5}
          end={13}
          p={2}
          className="md:col-start-4"
          style={{ height: "35rem" }}
        >
          {messageSelected ? (
            <MessageContent
              key={messageSelected.id}
              message={messageSelected}
              typeMessage={typeMessages}
              isCurrentUserDirectManager={isCurrentUserDirectManager}
              isCurrentUserInboxOwner={isCurrentUserInboxOwner}
              mutateSelectedMessage={mutateSelectedMessage}
              mutateAllMessage={mutateAllMessage}
              unreadMessagesCount={
                typeMessages == "received"
                  ? getUnreadMessageCount(
                      feedbackMessages,
                      isCurrentUserDirectManager,
                      isCurrentUserInboxOwner,
                    )
                  : null
              }
            />
          ) : (
            defaultMessageContent
          )}
        </CellBaseline>
      </Grid>
    </Frame>
  );
}
