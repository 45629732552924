// Modules
import clsx from "clsx";
import { format } from "date-fns";
import { sum, sumBy } from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactDataSheet from "react-datasheet";
import Select from "react-select";
// Constants
import AppTypes from "core-redux/constants/AppTypes";
// Styles
import "./styles/Sheet.scss";
import { LabelText, ValueText } from "../../../components/reports/utils";

class Sheet extends PureComponent {
  static propTypes = {
    generateGrid: PropTypes.func.isRequired,
    grid: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ),
      ).isRequired,
      periods: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    }).isRequired,
    handleCellsChanged: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    placeholders: PropTypes.arrayOf(AppTypes.placeholder.isRequired).isRequired,
    projectRoles: PropTypes.arrayOf(AppTypes.projectRole.isRequired).isRequired,
    users: PropTypes.arrayOf(AppTypes.user.isRequired).isRequired,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.props.generateGrid();
    }
  }

  cellRenderer = (props) => {
    const containerProps = {
      onMouseDown: props.onMouseDown,
      onMouseOver: props.onMouseOver,
      onDoubleClick: props.onDoubleClick,
      className: clsx(props.className, { error: props.cell.error }),
    };

    return (
      <div {...containerProps}>
        <ValueText>{props.children}</ValueText>
      </div>
    );
  };

  getDataEditor = (props) => {
    const { placeholders, projectRoles, users } = this.props;

    switch (props.col) {
      case 0:
        return <ProjectRoleSelector projectRoles={projectRoles} {...props} />;
      case 1:
        return (
          <AssigneeSelector
            placeholders={placeholders}
            users={users}
            {...props}
          />
        );
      default:
        return <HourEditor {...props} />;
    }
  };

  rowRenderer = (props) => {
    const { data } = this.props.grid;
    const totalHours = sumBy(data[props.row], (cell) =>
      Number.isInteger(Number.parseInt(cell.value))
        ? Number.parseInt(cell.value)
        : 0,
    );

    return (
      <div className="table-row">
        {props.children}
        <div className="cell total">
          <span className="value-viewer">
            <ValueText>{totalHours.toLocaleString()}</ValueText>
          </span>
        </div>
      </div>
    );
  };

  sheetRenderer = (props) => {
    const { periods, data } = this.props.grid;
    const summary = periods.map((_, col) =>
      sumBy(data, (row) => Number.parseInt(row[col + 2].value) || 0),
    );

    return (
      <div className={props.className}>
        <div className="header">
          <div className="table-row">
            <div className="cell project-roles">
              <LabelText>Project Role(s)</LabelText>
            </div>
            <div className="cell assignee">
              <LabelText>Assignee</LabelText>
            </div>
            {periods.map((period, i) => (
              <div className="cell" key={i}>
                <LabelText>{format(period, "MM/dd")}</LabelText>
              </div>
            ))}
            <div className="cell total">
              <LabelText>Total Hours</LabelText>
            </div>
          </div>
        </div>

        <div className="body">{props.children}</div>

        <div className="footer">
          <div className="table-row">
            <div className="cell project-roles" />
            <div className="cell assignee" />
            {summary.map((value, i) => (
              <div className="cell" key={i}>
                <LabelText>{`${value}`.toLocaleString()}</LabelText>
              </div>
            ))}
            <div className="cell total">
              <LabelText>{sum(summary).toLocaleString()}</LabelText>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isLoading, grid, handleCellsChanged } = this.props;

    if (isLoading) {
      return "Loading...";
    }

    return (
      <ReactDataSheet
        cellRenderer={this.cellRenderer}
        data={grid.data}
        dataEditor={this.getDataEditor}
        onCellsChanged={handleCellsChanged}
        overflow="nowrap"
        rowRenderer={this.rowRenderer}
        sheetRenderer={this.sheetRenderer}
        valueRenderer={(cell) => cell.value}
      />
    );
  }
}

class HourEditor extends PureComponent {
  handleChange = (event) => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { value } = this.props;

    return (
      <input
        autoFocus
        value={value}
        onChange={this.handleChange}
        onKeyDown={this.props.onKeyDown}
        type="number"
      />
    );
  }
}

class ProjectRoleSelector extends PureComponent {
  handleChange = (changes) => {
    const { onChange } = this.props;
    const value = changes.map((change) => change.value).join(", ");
    onChange(value);
  };

  render() {
    const { projectRoles, value } = this.props;

    const options = projectRoles
      .filter((projectRole) => projectRole.active)
      .map((projectRole) => ({
        label: projectRole.name,
        value: projectRole.name,
      }));

    let valueAsOptions = [];
    if (value !== "") {
      valueAsOptions = value.split(", ").map((value) => ({
        label: value,
        value,
      }));
    }

    return (
      <Select
        autoFocus
        isMulti
        onChange={this.handleChange}
        openMenuOnFocus
        options={options}
        value={valueAsOptions}
      />
    );
  }
}

function getTheme(theme) {
  return {
    ...theme,
    borderRadius: 0,
    spacing: { baseUnit: 2, controlHeight: 20, menuGutter: 4 },
  };
}

class AssigneeSelector extends PureComponent {
  handleChange = ({ value }) => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const { placeholders, users, value } = this.props;

    const customStyles = {
      placeholder: (provided) => ({
        ...provided,
        top: "64%",
      }),
    };

    const options = [
      {
        label: "Team Members",
        options: users.map((user) => ({
          label: user.fullName,
          value: user.fullName,
        })),
      },
      {
        label: "Placeholders",
        options: placeholders.map((placeholder) => ({
          label: placeholder.name,
          value: placeholder.name,
        })),
      },
    ];

    return (
      <Select
        autoFocus
        className={"react-select-container"}
        classNamePrefix={"react-select"}
        onChange={this.handleChange}
        onMenuClose={this.props.onCommit}
        openMenuOnFocus
        options={options}
        styles={customStyles}
        theme={getTheme}
        value={value}
      />
    );
  }
}

export default Sheet;
