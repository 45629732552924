import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Inline, Color, Text, Stack, Frame } from "@ableco/baseline";
import { BodySmall } from "../design-system/typography-components";
import useAutoSaveTextInput from "../../hooks/use-autosave-text-input";
import { formatDistanceToNow, isValid, parseISO } from "date-fns";
import { isString, noop } from "lodash";
import { Check, Spinner, WarningOutline } from "@baseline/icons";
import { motion } from "framer-motion";
import { TextArea } from "../text-input";
import { CoreTooltip } from "../core-tooltip";

function getSaveState({ error, loading, lastSave, stale }) {
  if (error) {
    return "Not saved";
  }

  if (loading) {
    return "Saving...";
  }

  if (stale) {
    return "Has unsaved changes";
  }

  return lastSave ? `Saved ${formatDistanceToNow(lastSave)} ago` : " ";
}

function TooltipWrap({ label, children }) {
  return (
    <CoreTooltip label={label}>
      <Stack role="status" aria-label={label} className="w-6 h-6">
        {children}
      </Stack>
    </CoreTooltip>
  );
}

export function LoadingStateIcon({ error, loading, lastSave }) {
  if (error)
    return (
      <TooltipWrap label="Not saved">
        <Text color="alert">
          <WarningOutline className="h-6 w-6" />
        </Text>
      </TooltipWrap>
    );
  else if (loading)
    return (
      <TooltipWrap label="Loading">
        <Text color="neutral-600">
          <Spinner className="animate-spin" />
        </Text>
      </TooltipWrap>
    );
  else if (lastSave)
    return (
      <Frame
        as={motion.div}
        animate={{
          opacity: 0,
          transitionEnd: { display: "none" },
          transition: { duration: 0.3, delay: 5 },
        }}
      >
        <TooltipWrap label="Saved">
          <Text color="success">
            <Check />
          </Text>
        </TooltipWrap>
      </Frame>
    );

  return null;
}

function AutosaveTextInput({
  resourceId,
  value = "",
  onChange = noop,
  onSave,
  LeftComponent = null,
  RightComponent = null,
  statusPosition = "right",
  getSaveStatus = getSaveState,
  lastSavedAt = null,
  ...props
}) {
  const [dataId, setDataId] = useState(resourceId);
  const [text, setText] = useState(value);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [savedAt, setSavedAt] = useState(() => {
    const date = isString(lastSavedAt) ? parseISO(lastSavedAt) : lastSavedAt;
    return isValid(date) ? date : null;
  });

  let distribution = "between";
  if (!LeftComponent && !RightComponent) {
    distribution = statusPosition === "right" ? "end" : "start";
  }

  const handleChangeValue = useCallback(
    (text) => {
      setText(text);
      onChange(text);
    },
    [onChange],
  );

  const handleChange = useCallback(
    (event) => {
      setHasUnsavedChanges(event.target.value !== text);
      handleChangeValue(event.target.value);
    },
    [handleChangeValue, text],
  );

  const mounted = useRef(true);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  const handleSave = useCallback(
    async (event) => {
      const newValue = event.target.value;
      try {
        if (mounted.current) {
          setIsLoading(true);
          setLoadError(false);
        }
        // We need to handle possible errors on this callback
        // in case the input unmounts before displaying
        // feedback to the user
        // await new Promise(r => setTimeout(r, 3000));
        const newCurrentId = await onSave(dataId, newValue);
        if (!mounted.current) return;
        setDataId(newCurrentId);
        setIsLoading(false);
        setHasUnsavedChanges(false);
        setSavedAt(Date.now());
      } catch (error) {
        if (process.env.NODE_ENV === "development") console.error(error);
        setIsLoading(false);
        setLoadError(true);
      }
    },
    [dataId, onSave],
  );

  const autoSaveProps = useAutoSaveTextInput({
    onSave: handleSave,
    onChange: handleChange,
  });

  const saveStatus = getSaveStatus({
    stale: hasUnsavedChanges,
    loading: isLoading,
    error: loadError,
    lastSave: savedAt,
  });

  const passRenderProps = typeof props.children === "function";
  if (passRenderProps) return props.children(text, autoSaveProps, saveStatus);

  return (
    <>
      <TextArea value={text} {...autoSaveProps} {...props} />
      <Inline distribution={distribution}>
        {LeftComponent}
        <BodySmall color={Color.Neutral600}>{saveStatus}</BodySmall>
        {RightComponent}
      </Inline>
    </>
  );
}

export default AutosaveTextInput;
