import React from "react";
import FocusLock from "react-focus-lock";
import {
  Frame,
  Stack,
  Inline,
  Center,
  Text,
  Color,
  Touchable,
  TextSize,
  Inset,
  noop,
  FlexDistribution,
  Portal,
  Modal,
  useOnClickOutside,
  useOnEscapePress,
  FlexAlignment,
  useLockBodyScroll,
  TextWeight,
} from "@ableco/baseline";
import { Close, FeedbackReceived } from "@baseline/icons";
import { api, useCollection, useResource } from "coreql";
import { useId } from "@reach/auto-id";
import Select from "./data-entry/select";
import ResponsibilitySelect, {
  useResponsibilities,
} from "./data-entry/select/responsibility-select";
import { CoreBackdrop } from "./backdrop/backdrop";
import { motion } from "framer-motion";
import { useCoreToast } from "./toast/toast";
import { PrimaryButton, TertiaryButton } from "./buttons/buttons";
import { BodyBaseline } from "./design-system/typography-components";
import { TextArea } from "./text-input";

function useUser(id) {
  return useResource(
    "users",
    id,
    {
      fields: {
        users: [
          "id",
          "preferredName",
          "projectRoleResponsibilities",
          "roleResponsibilities",
          "disciplineResponsibilities",
          "disciplineLeadersResponsibilities",
          "departmentLeadersResponsibilities",
          "departmentResponsibilities",
          "individualContributorsResponsibilities",
          "managersResponsibilities",
          "commonResponsibilities",
        ],
      },
    },
    "denormalized",
  );
}

function useCurrentUser() {
  return useResource(
    "users",
    "current",
    { fields: { users: ["id"] } },
    "denormalized",
  );
}

function useUsers({ options = {} } = {}) {
  return useCollection(
    "users",
    {
      filters: { active: true },
      fields: {
        users: ["id", "fullName"],
      },
      options,
    },
    "denormalized",
  );
}

function usePrompts({ responsibilityId, options = {} } = {}) {
  return useCollection(
    "feedback-prompts",
    {
      filters: {
        active: true,
        responsibilityId,
      },
      fields: {
        feedbackPrompts: ["id", "prompt", "scaleType", "promptType"],
      },
      options,
    },
    "denormalized",
  );
}

function replaceName(string, value) {
  return string.replace(/\[name]/gi, value);
}

function PromptType({ type, scale }) {
  if (type === "text") {
    return <SubHelperText>Text</SubHelperText>;
  }
  if (type === "binary") {
    return <SubHelperText>Yes/No</SubHelperText>;
  }

  if (scale === "performance") {
    return <SubHelperText>Needs Improvement -{">"} Perfect</SubHelperText>;
  }
  if (scale === "agreement") {
    return (
      <SubHelperText>Strongly Disagree -{">"} Strongly Agree</SubHelperText>
    );
  }
  if (scale === "frequency") {
    return <SubHelperText>Never -{">"} Always</SubHelperText>;
  }

  return null;
}

/**
 * @param {Object} props
 * @param {{ id: string, prompt: string, promptType: string, scaleType: string }} props.prompt
 * @param {string} props.checked
 * @param {string} props.userId
 * @param {Function} props.onChange
 * @returns
 */
function PromptCheck({ prompt, checked, onChange, userId }) {
  const id = useId();
  const { data: currentUser } = useUser(userId);
  return (
    <Inline alignment={FlexAlignment.Baseline} space={2}>
      <Text
        as="input"
        type="radio"
        name="prompt"
        id={id}
        value={prompt.id}
        checked={prompt.id === checked}
        onChange={onChange}
      />
      <Stack as="label" htmlFor={id} space={1}>
        <Text color={Color.Neutral700}>
          {replaceName(prompt.prompt, currentUser.preferredName)}
        </Text>
        <PromptType type={prompt.promptType} scale={prompt.scaleType} />
      </Stack>
    </Inline>
  );
}

function Prompts({
  responsibilityId,
  checked,
  onChange,
  userId,
  enableSimple,
  setPromptContentCount,
}) {
  const { data: prompts } = usePrompts({ responsibilityId });
  setPromptContentCount(prompts.length);
  return prompts.length > 0 ? (
    <Stack space={4}>
      {prompts.map((prompt) => (
        <PromptCheck
          checked={checked}
          onChange={onChange}
          key={prompt.id}
          prompt={prompt}
          userId={userId}
        />
      ))}
    </Stack>
  ) : (
    <Inline space={1}>
      <BodyBaseline italic={true} color={Color.Neutral700}>
        There are no prompts created for this responsibility.
      </BodyBaseline>
      <Touchable
        type="button"
        onClick={enableSimple}
        border={Color.Transparent}
        className="text-left outline-none"
      >
        <BodyBaseline
          color={[Color.Primary, Color.PrimaryLight]}
          italic={true}
          className="transition-colors duration-300 ease-in-out"
        >
          Write a custom prompt for this feedback request.
        </BodyBaseline>
      </Touchable>
    </Inline>
  );
}

function RecipientSelect(props) {
  const { data: users = [] } = useUsers();
  return (
    <Select
      options={users.map((user) => ({
        value: user.id.toString(),
        label: user.fullName,
      }))}
      testId="recipients"
      {...props}
    />
  );
}

function HelperText(props) {
  return <Text as="p" color={Color.Neutral600} {...props} />;
}

function SubHelperText(props) {
  return <Text as="p" color={Color.Neutral600} size={TextSize.SM} {...props} />;
}

function SwitchLinkButton({
  isSimple,
  promptContentCount,
  hasResponsibility,
  enableSimple,
  enableComplex,
}) {
  if (isSimple) {
    return (
      <Touchable
        type="button"
        onClick={enableComplex}
        border={Color.Transparent}
        className="text-left outline-none"
      >
        <Text
          color={[Color.Primary, Color.PrimaryLight]}
          className="transition-colors duration-300 ease-in-out"
        >
          I want to see feedback prompts
        </Text>
      </Touchable>
    );
  }

  return promptContentCount > 0 || hasResponsibility === false ? (
    <Touchable
      type="button"
      onClick={enableSimple}
      border={Color.Transparent}
      className="text-left outline-none"
    >
      <Text
        color={[Color.Primary, Color.PrimaryLight]}
        className="transition-colors duration-300 ease-in-out"
      >
        I prefer to write my own prompt
      </Text>
    </Touchable>
  ) : null;
}

export default function FeedbackRequestPrompt({
  onClose = noop,
  ownerId = "current",
}) {
  const { data: journalOwner } = useUser(ownerId);
  const { data: currentUser } = useCurrentUser();
  useUsers({ options: { suspense: false } });
  useResponsibilities({ userId: ownerId, options: { suspense: false } });
  const $modal = React.useRef();

  useLockBodyScroll();

  const [recipients, setRecipients] = React.useState([]);
  const [responsibility, setResponsibility] = React.useState(null);
  const [promptContentCount, setPromptContentCount] = React.useState(0);
  const [prompt, setPrompt] = React.useState(null);
  const [body, setBody] = React.useState("");
  const [isSimple, setIsSimple] = React.useState("");
  const { successToast, errorToast } = useCoreToast();

  const hasRecipients = recipients.length > 0;
  const hasResponsibility = !!responsibility;
  const hasPrompt = !!prompt;
  const hasBody = body.trim().length > 0;

  const isEnabled = isSimple
    ? hasRecipients && hasBody
    : hasRecipients && hasResponsibility && hasPrompt;

  const handleSubmit = React.useCallback(
    /**
     * @param {React.FormEvent<HTMLFormElement>} event
     */
    async (event) => {
      event.preventDefault();
      if (!isEnabled) return;

      const senderId = ownerId.toString();
      const authorId = currentUser.id.toString();

      // Close the modal before sending requests
      // Otherwise the modal will remain open on slow connections
      onClose();

      try {
        await Promise.all(
          recipients.map((receiverId) =>
            api.feedbackRequests.create(
              {
                receiverId,
                senderId,
                body,
                ...(isSimple ? {} : { feedbackPromptId: prompt }),
                ...(Number(senderId) === Number(authorId) ? {} : { authorId }),
              },
              isSimple
                ? []
                : [{ name: "feedbackPrompt", resource: "feedback-prompt" }],
            ),
          ),
        );
        successToast(
          `We have sent your feedback ${
            recipients.length > 1 ? "requests" : "request"
          }!`,
        );
      } catch {
        errorToast("We couldn't send your request. Contact the team.");
      }
    },
    [
      isEnabled,
      ownerId,
      currentUser.id,
      onClose,
      recipients,
      successToast,
      body,
      isSimple,
      prompt,
      errorToast,
    ],
  );

  const handleResponsibilityChange = React.useCallback(
    /**
     * @param {number} responsibilityId
     */
    (responsibilityId) => {
      setPrompt(null);
      setResponsibility(responsibilityId);
    },
    [],
  );

  const handlePromptChange = React.useCallback(
    /**
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    (event) => {
      setPrompt(event.target.value);
    },
    [setPrompt],
  );

  const handleBodyChange = React.useCallback(
    /**
     * @param {React.ChangeEvent<HTMLTextAreaElement>} event
     */
    (event) => setBody(event.target.value),
    [setBody],
  );

  const enableSimple = React.useCallback(
    () => setIsSimple(true),
    [setIsSimple],
  );

  const enableComplex = React.useCallback(
    () => setIsSimple(false),
    [setIsSimple],
  );

  const handleCloseValidation = React.useCallback(
    (event) => {
      event.preventDefault();
      const copy = "Are you sure you want to cancel the Feedback Request?";
      if (!hasRecipients && !hasBody && !hasResponsibility && !hasPrompt) {
        return onClose();
      }
      if (confirm(copy)) {
        return onClose();
      }
      return;
    },
    [hasBody, hasPrompt, hasRecipients, hasResponsibility, onClose],
  );

  useOnClickOutside($modal, handleCloseValidation);
  useOnEscapePress(handleCloseValidation);

  const bodyInputDisabled = promptContentCount < 1 && !isSimple;

  return (
    <Portal>
      <CoreBackdrop>
        <Center style={{ height: "100%" }}>
          <Modal
            as={motion.div}
            initial={{ scale: 0.85, y: 20 }}
            animate={{ scale: 1, x: 0, y: 0 }}
            exit={{ scale: 0.85, y: 20 }}
            transition={{
              type: "spring",
              stiffness: 800,
              damping: 35,
            }}
            innerRef={$modal}
            p={0}
            style={{
              maxWidth: 960,
              width: "100%",
              maxHeight: "100vh",
              overflow: "auto",
            }}
          >
            <FocusLock>
              <Stack
                as="form"
                className="relative"
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
              >
                {/* Close button */}
                <Inset variant="absolute" position="top right">
                  <Touchable
                    onClick={handleCloseValidation}
                    p={2}
                    className="focus:outline-none border-none"
                  >
                    <Text color={Color.White}>
                      <Close style={{ width: 24, height: 24 }} />
                    </Text>
                  </Touchable>
                </Inset>
                {/* Header */}
                <Inline
                  as="header"
                  bg={Color.Primary}
                  p={6}
                  space={2}
                  alignment={FlexAlignment.Start}
                >
                  <Text color={Color.White}>
                    <FeedbackReceived />
                  </Text>
                  <Stack space={2}>
                    <Text as="h2" color={Color.White} size={TextSize.XL2}>
                      Feedback Request
                    </Text>
                    <Text as="p" color={Color.White}>
                      Request feedback for {journalOwner.preferredName}
                    </Text>
                  </Stack>
                </Inline>
                {/* Inputs */}
                <Frame p={[0, 10]}>
                  <Stack space={4} p={[0, 4]}>
                    <Stack space={6}>
                      {/* Recipients */}
                      <Stack space={4} p={[6, 6, 0, 6]}>
                        <Stack space={2}>
                          <Text
                            weight={TextWeight.SemiBold}
                            size={TextSize.LG}
                            color={Color.Neutral800}
                          >
                            Recipients
                          </Text>
                          <HelperText as="label" htmlFor="recipients">
                            Select people that can provide feedback for{" "}
                            {journalOwner.preferredName}
                          </HelperText>
                        </Stack>
                        <RecipientSelect
                          id="recipients"
                          multi
                          placeholder="Search by name"
                          value={recipients}
                          onChange={setRecipients}
                        />
                      </Stack>
                      {/* Prompts */}
                      <Stack space={2}>
                        <Frame p={[0, 6]}>
                          <Text
                            as="h3"
                            weight={TextWeight.SemiBold}
                            size={TextSize.LG}
                            color={Color.Neutral800}
                          >
                            Prompt
                          </Text>
                        </Frame>
                        <Stack
                          space={6}
                          p={[2, 6, 4, 6]}
                          bg={isSimple ? Color.Transparent : Color.Neutral100}
                        >
                          {!isSimple && (
                            <Stack space={4}>
                              <Stack space={2}>
                                <Text
                                  color={Color.Neutral800}
                                  weight={TextWeight.SemiBold}
                                >
                                  Feedback Prompts
                                </Text>
                                <HelperText as="label" htmlFor="prompts">
                                  To see the list of prompts, please select a
                                  responsibility first
                                </HelperText>
                              </Stack>
                              <ResponsibilitySelect
                                userId={ownerId}
                                id="prompts"
                                placeholder="Select a responsibility"
                                value={responsibility}
                                onChange={handleResponsibilityChange}
                              />

                              <React.Suspense
                                fallback={<Text>Loading prompts...</Text>}
                              >
                                {responsibility && (
                                  <Prompts
                                    responsibilityId={responsibility}
                                    checked={prompt}
                                    onChange={handlePromptChange}
                                    userId={ownerId}
                                    enableSimple={enableSimple}
                                    setPromptContentCount={
                                      setPromptContentCount
                                    }
                                  />
                                )}
                              </React.Suspense>
                            </Stack>
                          )}
                          {/* Personal Message or custom Prompt */}
                          <Stack space={4}>
                            <Stack space={2}>
                              {!isSimple && (
                                <Text
                                  color={Color.Neutral800}
                                  weight={TextWeight.SemiBold}
                                >
                                  Personal Message (optional)
                                </Text>
                              )}
                              <HelperText as="label" htmlFor="message">
                                {isSimple ? (
                                  <>
                                    Use the space below to capture your feedback
                                    request.
                                  </>
                                ) : (
                                  <>
                                    Use this space to clarify your request or to
                                    add any additional context
                                  </>
                                )}
                              </HelperText>
                            </Stack>
                            <TextArea
                              id="message"
                              bg={
                                bodyInputDisabled
                                  ? Color.Neutral100
                                  : Color.White
                              }
                              value={body}
                              disabled={bodyInputDisabled}
                              onChange={handleBodyChange}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Frame>
                {/* Switch link button */}
                <Frame p={[4, 10, 6, 10]}>
                  <Frame p={[0, 4]}>
                    <SwitchLinkButton
                      isSimple={isSimple}
                      promptContentCount={promptContentCount}
                      hasResponsibility={hasResponsibility}
                      enableSimple={enableSimple}
                      enableComplex={enableComplex}
                    />
                  </Frame>
                </Frame>
                {/* Footer */}
                <Inline
                  as="footer"
                  space={4}
                  distribution={FlexDistribution.End}
                  p={[6, 10, 5, 10]}
                  className="border-neutral-300"
                  style={{ borderTopWidth: "1px", borderTopStyle: "solid" }}
                >
                  <TertiaryButton
                    text="Cancel"
                    onClick={handleCloseValidation}
                  />
                  <PrimaryButton text="Send Request" disabled={!isEnabled} />
                </Inline>
              </Stack>
            </FocusLock>
          </Modal>
        </Center>
      </CoreBackdrop>
    </Portal>
  );
}
