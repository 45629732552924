import React from "react";
import { Frame, Inline } from "@ableco/baseline";
import { CommentOutline, Forward } from "@baseline/icons";
import clsx from "clsx";
import { SSRSuspense, useCollection } from "coreql";
import CommentForm from "../comment-form";
import { MarkdownViewer } from "../markdown/markdown-viewer";
import { CheckboxWithLabel } from "../data-entry/checkbox-with-label";
import { getPrivateCommentReceiverName } from "./how-this-week-went/utils";
import { CoreTooltip } from "../core-tooltip";

function AchievedPlan({
  checked,
  text,
  planId,
  reportee,
  handleToggle,
  currentUser,
  weeklyReportId,
  handleAddNextWeek,
}) {
  const {
    data: { data: comments },
    revalidate,
  } = useCollection("comments", {
    filters: {
      commentable_type: ["WeeklyReportAnswer"],
      commentable_id: [planId],
    },
    fields: {
      comments: ["private", "avatarUser", "userFullname", "body", "userId"],
    },
  });

  const [showComments, setShowComments] = React.useState(comments.length > 0);

  function handleClickCommentIcon(event) {
    event.preventDefault();
    setShowComments((visible) => !visible);
  }

  return (
    <Frame as="li">
      <Inline distribution="between" className="group">
        <CheckboxWithLabel
          checked={checked}
          onChange={handleToggle}
          lineThrough={true}
        >
          <MarkdownViewer value={text} />
        </CheckboxWithLabel>
        <AchievedPlanActions
          handleAddNextWeek={handleAddNextWeek}
          handleComment={handleClickCommentIcon}
        />
      </Inline>
      <SSRSuspense fallback={null}>
        <CommentForm
          weeklyReportId={weeklyReportId}
          senderId={reportee.id}
          senderAvatar={reportee.avatarUrl}
          receiverName={getPrivateCommentReceiverName(reportee, currentUser)}
          currentUserId={currentUser.id}
          answerId={planId}
          showAddComment={showComments}
          setShowAddComment={setShowComments}
          comments={comments}
          revalidate={revalidate}
        />
      </SSRSuspense>
    </Frame>
  );
}

function AchievedPlanActions({ handleAddNextWeek, handleComment }) {
  function blurOnMouseLeave({ currentTarget: target }) {
    target.blur();
  }
  return (
    <Frame
      className={clsx(
        "opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity duration-300 ease-in-out",
      )}
    >
      <Inline space={4}>
        <CoreTooltip label="Add to next week">
          <Inline
            as="button"
            type="button"
            aria-label="Add to next week"
            onClick={handleAddNextWeek}
            data-cy="add-next-week"
            onMouseLeave={blurOnMouseLeave}
            className="opacity-45 hover:opacity-100 focus:opacity-100 focus:outline-none  transition-opacity duration-300 ease-in-out"
          >
            <Forward style={{ fill: "none" }} />
          </Inline>
        </CoreTooltip>
        <CoreTooltip label="Add Comment">
          <Inline
            as="button"
            type="button"
            aria-label="Add Comment"
            data-cy="add-comment"
            onClick={handleComment}
            onMouseLeave={blurOnMouseLeave}
            className="opacity-45 hover:opacity-100 focus:opacity-100 focus:outline-none  transition-opacity duration-300 ease-in-out"
          >
            <CommentOutline />
          </Inline>
        </CoreTooltip>
      </Inline>
    </Frame>
  );
}

export default AchievedPlan;
