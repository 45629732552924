import React from "react";
import { Stack, Inline, Color, Frame } from "@ableco/baseline";
import { api } from "coreql";
import { useParams } from "react-router";
import { isEmpty } from "lodash";

import {
  BodySmall,
  TitleSemibold,
} from "../design-system/typography-components";
import { MarkdownViewer } from "../markdown/markdown-viewer";
import AutosaveTextInput, {
  LoadingStateIcon,
} from "../data-entry/autosave-text-input";
import { MarkdownEditor } from "../markdown/markdown-editor";
import { useCoreToast } from "../toast/toast";

export default function WeeklyReportGeneralFeedback({
  weeklyReport,
  currentUserIsDirectManager,
  weeklyReportMutate,
}) {
  const { weeklyReportGeneralFeedback, reviewed } = weeklyReport;

  return currentUserIsDirectManager && reviewed === null ? (
    <GeneralFeedbackEditMode
      weeklyReportGeneralFeedback={weeklyReportGeneralFeedback}
      weeklyReportMutate={weeklyReportMutate}
    />
  ) : (
    <GeneralFeedbackReadMode
      weeklyReportGeneralFeedback={weeklyReportGeneralFeedback}
      reviewed={reviewed}
    />
  );
}

function GeneralFeedbackEditMode({
  weeklyReportGeneralFeedback,
  weeklyReportMutate,
}) {
  const { errorToast } = useCoreToast();
  const { weeklyReportId } = useParams();

  async function onSave(id, text) {
    if (isEmpty(text.trim())) {
      if (id) {
        try {
          await api.weeklyReportGeneralFeedbacks.find(id).destroy();
          weeklyReportMutate();
          return null;
        } catch {
          errorToast("We could not delete your answer, contact the team.");
          return (unchagedState) => unchagedState; // resourceId keep unchanged
        }
      }
    } else {
      // update or create
      try {
        const {
          data: { id: newId },
        } = await api.weeklyReportGeneralFeedbacks.find(id).upsert(
          {
            body: text,
          },
          { weeklyReportId: weeklyReportId },
          [{ name: "weeklyReport", resource: "weekly-report" }],
        );
        weeklyReportMutate();
        return newId;
      } catch {
        errorToast(
          "We couldn't update your general feedback, contact the team.",
        );
        return (unchagedState) => unchagedState; // resourceId keep unchanged
      }
    }
  }

  return (
    <Stack className="pr-20 ml-6">
      <Header optional={true} />
      <AutosaveTextInput
        getSaveStatus={LoadingStateIcon}
        resourceId={weeklyReportGeneralFeedback?.id}
        value={weeklyReportGeneralFeedback?.body}
        onSave={onSave}
      >
        {(text, autoSaveProps, status) => (
          <Frame className="relative">
            <MarkdownEditor
              id="id"
              placeholder="Was there something that wasn’t covered or they didn’t mention in their reports? If so, this space is to allow you to capture anything that was missed or just give some kind words of encouragement."
              iconContainerCls="absolute bottom-0 right-0 mr-2 my-3"
              style={{ minHeight: "7.5rem" }}
              autosize={88}
              type="multiline"
              border="neutral-400"
              text={text}
              data-testid="general-feedback-input"
              {...autoSaveProps}
            />
            <Frame className="absolute left-100 bottom-0 mb-2 ml-2">
              {status}
            </Frame>
          </Frame>
        )}
      </AutosaveTextInput>
    </Stack>
  );
}

function GeneralFeedbackReadMode({ weeklyReportGeneralFeedback, reviewed }) {
  if ([undefined, ""].includes(weeklyReportGeneralFeedback?.body)) return null;
  if (reviewed === null) return null;

  return (
    <Stack className="pr-20 ml-4">
      <Header optional={false} />
      <Stack className="flex-1 overflow-auto ml-8">
        <MarkdownViewer value={weeklyReportGeneralFeedback.body} />
      </Stack>
    </Stack>
  );
}

function Header({ optional = false }) {
  return (
    <Inline className="mb-6">
      <TitleSemibold as="h2">
        Anything else you'd like to share with your direct report?
      </TitleSemibold>
      {optional ? (
        <BodySmall className="ml-1" color={Color.Neutral600}>
          (Optional)
        </BodySmall>
      ) : null}
    </Inline>
  );
}
