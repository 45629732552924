// Modules
import {
  addMonths,
  addWeeks,
  addYears,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isSameMonth,
  isSameYear,
  isValid,
  parseISO,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import queryString from "query-string";
// Constants
import { LOCATION_CHANGE } from "connected-react-router";

const generateMonths = (props) => {
  const { endDate, startDate } = props;
  const numMonths = differenceInCalendarMonths(endDate, startDate) + 1;

  return [...new Array(numMonths)].map((i, x) => {
    const month = addMonths(startDate, x);
    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(month);
    const numWeeks =
      differenceInCalendarWeeks(monthEnd, monthStart, { weekStartsOn: 1 }) + 1;

    return {
      title: format(month, "MMM ''yy"),
      start: monthStart,
      end: monthEnd,
      periodStarts: [...new Array(numWeeks)]
        .map((i, x) => {
          const week = addWeeks(monthStart, x);
          const start = isSameMonth(
            startOfWeek(week, { weekStartsOn: 1 }),
            monthStart,
          )
            ? startOfWeek(week, { weekStartsOn: 1 })
            : null;

          if (start) {
            return format(start, "yyyy-MM-dd");
          }
        })
        .filter((periodStart) => periodStart),
    };
  });
};

const generateWeeks = (props) => {
  const { endDate, startDate } = props;
  const numWeeks =
    differenceInCalendarWeeks(endDate, startDate, { weekStartsOn: 1 }) + 1;

  return [...new Array(numWeeks)].map((i, x) => {
    const week = addWeeks(startDate, x);
    const start = startOfWeek(week, { weekStartsOn: 1 });
    const end = endOfWeek(week, { weekStartsOn: 1 });

    return {
      title: format(start, "MMM d"),
      start,
      end,
      periodStarts: [format(start, "yyyy-MM-dd")],
    };
  });
};

const generateYears = (props) => {
  const { endDate, startDate } = props;
  const numYears = differenceInCalendarYears(endDate, startDate) + 1;

  return [...new Array(numYears)].map((i, x) => {
    const year = addYears(startDate, x);
    const yearStart = startOfYear(year);
    const yearEnd = endOfYear(year);
    const numWeeks =
      differenceInCalendarWeeks(yearEnd, yearStart, { weekStartsOn: 1 }) + 1;

    return {
      title: format(year, "yyyy"),
      start: yearStart,
      end: yearEnd,
      periodStarts: [...new Array(numWeeks)]
        .map((i, x) => {
          const week = addWeeks(yearStart, x);
          const start = isSameYear(
            startOfWeek(week, { weekStartsOn: 1 }),
            yearStart,
          )
            ? startOfWeek(week, { weekStartsOn: 1 })
            : null;

          if (start) {
            return format(start, "yyyy-MM-dd");
          }
        })
        .filter((periodStart) => periodStart),
    };
  });
};

const generatePeriods = (props) => {
  const { periodIncrement } = props;
  const endDate = parseISO(props.endDate);
  const startDate = parseISO(props.startDate);

  if (!isValid(endDate) || !isValid(startDate)) return [];

  const propsWithDates = {
    ...props,
    endDate,
    startDate,
  };

  if (periodIncrement === "month") {
    return generateMonths(propsWithDates);
  } else if (periodIncrement === "week") {
    return generateWeeks(propsWithDates);
  } else if (periodIncrement === "year") {
    return generateYears(propsWithDates);
  } else {
    return [];
  }
};

export default (state = [], action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const searchProps = queryString.parse(action.payload.location.search);
      return generatePeriods(searchProps);
    default:
      return state;
  }
};
