import React from "react";
import { Stack } from "@ableco/baseline";
import { api as apiCoreQl, denormalize } from "coreql";
import useCurrentUser from "../../hooks/use-current-user";
import { wrap } from "./utils";
import AchievedPlan from "./achieved-plan";
import { useAccomplishments } from "./accomplishments";

function AchievedPlans({ weeklyReportId, onUpdate }) {
  const { data: currentUser } = useCurrentUser();

  const { data: weeklyReportAccomplishments, mutate } =
    useAccomplishments(weeklyReportId);
  const { user: reportee } = weeklyReportAccomplishments;
  const achievedPlans = wrap(weeklyReportAccomplishments.achievedPlans);

  async function toggleAchievedPlan(achievedPlan, checked) {
    const { id: achievedPlanId, answerableType, answerableId } = achievedPlan;
    const data = await apiCoreQl.weeklyReportAnswers
      .find(achievedPlanId)
      .upsert(
        { data: { value: checked } },
        {
          answerType: "achieved_plan",
          weeklyReportId,
          answerableId,
          answerableType,
        },
        [{ name: "weeklyReport", resource: "weekly-report" }],
      );

    function updateAchievedPlan(plan) {
      if (plan.answerableId !== answerableId) return plan;
      return { ...plan, ...denormalize(data, "weeklyReportAnswers") };
    }

    await mutate(async (weeklyReport) => {
      const achievedPlans = wrap(weeklyReport.achievedPlans).map(
        updateAchievedPlan,
      );
      return {
        ...weeklyReport,
        achievedPlans,
      };
    }, false);

    onUpdate();
  }

  async function handleAddNextWeek({ data }) {
    const newPlan = await apiCoreQl.weeklyReportAnswers.create(
      {
        data,
        answerType: "plan",
        weeklyReportId,
      },
      [{ name: "weeklyReport", resource: "weekly-report" }],
    );

    await mutate(async (weeklyReport) => {
      const plans = [
        ...wrap(weeklyReport.plans),
        denormalize(newPlan, "weeklyReportAnswers"),
      ];

      return {
        ...weeklyReport,
        plans,
      };
    }, false);
    onUpdate();
  }

  if (achievedPlans.length === 0) return null;

  return (
    <Stack
      as="ul"
      aria-label="This week accomplishments"
      space={4}
      className="mb-8"
    >
      {achievedPlans.map((achievedPlan) => (
        <AchievedPlan
          weeklyReportId={weeklyReportId}
          key={achievedPlan.answerableId}
          planId={achievedPlan.answerableId}
          checked={achievedPlan.data.value}
          text={achievedPlan.text}
          reportee={reportee}
          handleToggle={({ target }) =>
            toggleAchievedPlan(achievedPlan, target.checked)
          }
          currentUser={currentUser}
          handleAddNextWeek={() =>
            handleAddNextWeek({ data: { text: achievedPlan.text } })
          }
        />
      ))}
    </Stack>
  );
}

export default AchievedPlans;
