// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import className from "clsx";
// Constants
import AppTypes from "core-redux/constants/AppTypes";
// Components
import ProductLabelRows from "utilization/components/ProductLabelRows";
import ProductValueRows from "utilization/components/ProductValueRows";
import SummaryLabelRows from "utilization/components/SummaryLabelRows";
import SummaryValueRows from "utilization/components/SummaryValueRows";
// Styles
import Styles from "./Table.module.scss";
import { LabelText } from "../LabelRows/LabelText";

class Table extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    periods: PropTypes.arrayOf(AppTypes.period).isRequired,
    products: PropTypes.arrayOf(AppTypes.product).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      scrollLeft: 0,
      scrollTop: 0,
    };
  }

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.currentTarget;
    this.setState({ scrollLeft, scrollTop });
  };

  render() {
    const { isLoading, periods, products } = this.props;
    const { scrollLeft, scrollTop } = this.state;

    const containerProps = {
      className: className(Styles.table, { [Styles.loading]: isLoading }),
      onScroll: this.handleScroll,
    };

    return (
      <div {...containerProps}>
        {isLoading ? <LoadingIndicator /> : null}
        <div className={Styles.sidebar} style={{ left: `${scrollLeft}px` }}>
          <div className={Styles.header} style={{ top: `${scrollTop}px` }}>
            <div className={Styles.row}>
              <div className={Styles.spacer} />
              <div className={Styles.spacer} />
              <div className={Styles.spacer} />
              <div className={Styles.spacer} />
              <div className={Styles.column} />
            </div>
          </div>

          <div className={Styles.row}>
            <div className={Styles.column}>Products</div>
          </div>

          {products.map((product) => (
            <ProductLabelRows key={product.id} product={product} />
          ))}

          <SummaryLabelRows products={products} />
        </div>

        <div className={Styles.main}>
          <div className={Styles.header} style={{ top: `${scrollTop}px` }}>
            <div className={Styles.row}>
              {periods.map((period, i) => (
                <div key={i} className={Styles.columnWithValue}>
                  <LabelText>{period.title}</LabelText>
                </div>
              ))}
            </div>
          </div>

          <div className={Styles.body}>
            <div className={Styles.row} />
            {products.map((product) => (
              <ProductValueRows key={product.id} product={product} />
            ))}
            <SummaryValueRows products={products} />
          </div>
        </div>
      </div>
    );
  }
}

function LoadingIndicator() {
  return (
    <div className={Styles.loadingContainer}>
      <div className={Styles.inner}>
        <h3>Loading...</h3>
      </div>
    </div>
  );
}

export default Table;
