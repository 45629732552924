import React from "react";
import { Grid, Frame } from "@ableco/baseline";

import CoreLink from "../core-link";
import {
  BodyBaselineSemibold,
  BodyBaseline,
  BodySmall,
} from "../design-system/typography-components";
import WeeklyQuestionCell from "./weekly-question-cell";
import { formatWithDashes } from "../weekly-report/history/utils";
import {
  audienceText,
  countWeeklyReportAnswers,
} from "./weekly-questions-utils";
import { Stack } from "../design-system/base";

export default function WeeklyQuestionsTable({ questions, weeksMonday }) {
  const questionsCount = questions.length;

  if (questionsCount === 0) {
    return (
      <Frame className="mt-4">
        <BodyBaseline>No reports avaliable for this week</BodyBaseline>
      </Frame>
    );
  }

  return (
    <Grid col={6}>
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Audience
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-4" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Question
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Entries
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      {questions.map((question, index) => (
        <React.Fragment key={question.id}>
          <WeeklyQuestionCell
            firstRow={index == 0}
            lastRow={index + 1 == questionsCount}
            firstColumn={true}
            evenRow={index % 2 == 1}
            className="col-span-1"
            data-testid={`question-${question.id}-audience`}
          >
            <BodyBaseline color="neutral-700">
              {audienceText(question.questionable)}
            </BodyBaseline>
          </WeeklyQuestionCell>
          <WeeklyQuestionCell
            firstRow={index == 0}
            lastRow={index + 1 == questionsCount}
            evenRow={index % 2 == 1}
            className="col-span-4"
            data-testid={`question-${question.id}-info`}
          >
            <Stack>
              <CoreLink
                href={`/weekly_questions/${question.id}?week=${formatWithDashes(
                  weeksMonday,
                )}`}
              >
                <BodyBaseline
                  color={["primary", "primary-light"]}
                  className="transition-color duration-300 ease-in-out no-underline break-words"
                >
                  {question.text}
                </BodyBaseline>
              </CoreLink>
              <BodySmall color="neutral-600">
                {question.optional ? "Optional" : "Required"} •{" "}
                {question.questionType === "text" ? "Text" : "Rating Scale"}
              </BodySmall>
            </Stack>
          </WeeklyQuestionCell>
          <WeeklyQuestionCell
            firstRow={index == 0}
            lastRow={index + 1 == questionsCount}
            lastColumn={true}
            evenRow={index % 2 == 1}
            className="col-span-1"
            data-testid={`question-${question.id}-entries`}
          >
            <BodyBaseline color="neutral-700">
              {countWeeklyReportAnswers(
                question.weeklyReportAnswers,
                weeksMonday,
              )}
            </BodyBaseline>
          </WeeklyQuestionCell>
        </React.Fragment>
      ))}
    </Grid>
  );
}
