import React, { useCallback, useLayoutEffect, useRef } from "react";
import { Frame, Inline, Stack } from "@ableco/baseline";
import { MarkdownViewer } from "../markdown/markdown-viewer";
import { MarkdownEditor } from "../markdown/markdown-editor";
import { api as apiCoreql } from "coreql";
import { isEmpty } from "lodash";
import { useCoreToast } from "../toast/toast";
import useAutosavetextInput from "../../hooks/use-autosave-text-input";
import checkEmptyMDText from "../../utils/markdown/check-empty-text";
import { LoadingStateIcon } from "../data-entry/autosave-text-input";
import { BodySmall } from "../design-system/typography-components";
import useAutoFocusEnd from "../../hooks/use-autofocus-end";

export default function Note({
  data,
  placeholder,
  kind,
  onUpdate,
  requestedId,
  requesterId,
  mutate,
}) {
  const [text, setText] = React.useState(() => data?.text ?? "");
  const [dataId, setDataId] = React.useState(data?.id);
  const [showMarkdown, setShowMarkdown] = React.useState(() =>
    checkEmptyMDText(text),
  );
  const [dataIsLoading, setDataIsLoading] = React.useState(false);
  const [dataloadError, setDataLoadError] = React.useState(false);
  const [dataSavedAt, setDataSavedAt] = React.useState(null);

  const editorRef = React.createRef();
  const { errorToast } = useCoreToast();

  const handleSaveNote = useCallback(
    async (event) => {
      if (isEmpty(event.target.value.trim())) {
        try {
          if (dataId) {
            await apiCoreql.oneOnOneItems.find(dataId).destroy();

            setDataId(null);
            setDataIsLoading(false);
            setDataSavedAt(Date.now());
            mutate();
            onUpdate();
          }
        } catch {
          errorToast("We couldn't delete your Note, contact the team.");
        }
        return;
      }

      try {
        const dataText = event.target.value;
        setDataIsLoading(true);
        setDataLoadError(false);
        const { data } = await apiCoreql.oneOnOneItems.find(dataId).upsert(
          {
            text: dataText,
            kind: kind,
          },
          { requesterId, requestedId },
        );
        setDataId(data.id);
        setDataIsLoading(false);
        setDataSavedAt(Date.now());
        mutate();
        onUpdate();
      } catch {
        setDataIsLoading(false);
        setDataLoadError(true);
      }
    },
    [dataId, requesterId, requestedId],
  );

  const autosaveNotesProps = useAutosavetextInput({
    onSave: handleSaveNote,
    onChange: useCallback((event) => setText(event.target.value), []),
  });

  async function handleNotesBlur() {
    setShowMarkdown(checkEmptyMDText(text));
    setDataSavedAt(null);
  }

  useAutoFocusEnd(showMarkdown, text, editorRef);

  function hideMarkdownViewer() {
    return setShowMarkdown(false);
  }

  const $wrapperRef = useRef(null);

  /* fixes scrollbar autosize bug */
  useLayoutEffect(() => {
    const $wrapper = $wrapperRef.current;
    if ($wrapper) {
      $wrapper.style.height = "auto";
      $wrapper.style.height = $wrapper.scrollHeight + "px";
    }
  }, [text, showMarkdown]);

  return (
    <Stack innerRef={$wrapperRef} p={6} className="text-neutral-700 w-full">
      {showMarkdown ? (
        <Stack space={4}>
          <MarkdownViewer
            className="hover:bg-neutral-200 hover:cursor-pointer p-2 -mx-2 transition-colors duration-300 ease-in-out"
            value={text}
            onClick={hideMarkdownViewer}
          />
          <Inline className="select-none" onClick={hideMarkdownViewer}>
            <BodySmall color="neutral-600">Add notes</BodySmall>
          </Inline>
        </Stack>
      ) : (
        <Stack className="relative">
          <MarkdownEditor
            text={text}
            placeholder={placeholder}
            onBlur={handleNotesBlur}
            onRef={(ref) => (editorRef.current = ref.current)}
            {...autosaveNotesProps}
            type="multiline"
            autosize={88}
          />
          <Frame className="absolute left-100 bottom-0 mb-2 ml-2">
            <LoadingStateIcon
              loading={dataIsLoading}
              error={dataloadError}
              lastSave={dataSavedAt}
            />
          </Frame>
        </Stack>
      )}
    </Stack>
  );
}
