import React, { useRef, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as _ from "lodash";
import Downshift from "downshift";
import hasFieldErrors from "../utils/has-field-errors";
import { matchInitials } from "../utils/string-initials";

const Error = styled.div`
  font-size: 14px;
  color: red;
`;

function BaseDropdown({
  placeholder = "Select...",
  options,
  filterItems,
  onSelect = _.noop,
  onReset = _.noop,
  disabled = false,
  searchable = true,
  field: { name, value, onChange, onBlur },
  form: { setFieldTouched, touched = {}, errors = {} } = {},
  styles = {},
  label,
}) {
  const currentOption = options.find((option) => option.value === value);

  const fieldErrors = hasFieldErrors(name, touched, errors);
  const inputReference = useRef();

  const Item = useMemo(() => styled(styles.item)``, [styles.item]);
  const Dropdown = useMemo(() => styled(styles.dropdown)``, [styles.dropdown]);
  const List = useMemo(() => styled(styles.list)``, [styles.list]);
  const Container = useMemo(
    () => styled(styles.container)``,
    [styles.container],
  );
  const WrapDropdown = useMemo(
    () => styled(styles.wrapDropdown)``,
    [styles.wrapDropdown],
  );
  const Close = useMemo(() => styled(styles.close)``, [styles.close]);

  function handleChange(selection) {
    if (!selection) return;
    onChange({ target: { name, value: selection.value } });
    onSelect(selection);
  }

  function handleReset() {
    onChange({ target: { name, value: "" } });
    inputReference.current.focus();
    onReset();
  }

  return (
    <Downshift
      onChange={handleChange}
      itemToString={(item) => (item ? item.content : "")}
      initialSelectedItem={currentOption}
      initialInputValue={currentOption && currentOption.content}
    >
      {({
        inputValue,
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        getLabelProps,
        highlightedIndex,
        reset,
        isOpen,
        openMenu,
        clearSelection,
      }) => {
        const filteredOptions = options.filter((item) => {
          if (!searchable) {
            return item;
          }
          return filterItems
            ? filterItems(inputValue, item)
            : !inputValue ||
                item.content.toLowerCase().includes(inputValue.toLowerCase()) ||
                matchInitials(item.content, inputValue);
        });
        return (
          <Container
            {...getRootProps({
              referenceKey: "innerRef",
            })}
          >
            {label && React.cloneElement(label, getLabelProps())}
            <WrapDropdown isOpen={isOpen}>
              <Dropdown
                ref={inputReference}
                {...getInputProps({
                  isOpen,
                  disabled,
                  readOnly: !searchable,
                  placeholder: placeholder,
                  hasErrors: fieldErrors,
                  onFocus: () => openMenu(),
                  onBlur: (event) => {
                    onBlur(event);
                    setFieldTouched(name, true);
                    reset();
                  },
                })}
              />
              {inputValue && !disabled && (
                <Close
                  data-testid="dropdown-clear"
                  onClick={() => clearSelection(handleReset)}
                >
                  x
                </Close>
              )}
            </WrapDropdown>
            {fieldErrors && <Error>{fieldErrors}</Error>}
            {isOpen && filteredOptions.length > 0 ? (
              <List {...getMenuProps({ isOpen })}>
                {filteredOptions.map((item, index) => (
                  <Item
                    {...getItemProps({
                      key: item.value,
                      highlighted: highlightedIndex == index,
                      index,
                      item,
                    })}
                  >
                    {item.content}
                  </Item>
                ))}
              </List>
            ) : null}
          </Container>
        );
      }}
    </Downshift>
  );
}

BaseDropdown.propTypes = {
  placeholder: PropTypes.string,
  filterItems: PropTypes.func,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      content: PropTypes.node.isRequired,
    }),
  ),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }),
  styles: PropTypes.objectOf(
    PropTypes.shape({
      render: PropTypes.func,
    }),
  ),
  label: PropTypes.element,
};

export default BaseDropdown;
