import React from "react";
import { FlexAlignment, FlexDistribution, Stack } from "@ableco/baseline";
import LogoIcon from "../icons/logo-icon";
import CoreLink from "../core-link";

export default function Logo() {
  return (
    <Stack
      as="figure"
      alignment={FlexAlignment.Start}
      distribution={FlexDistribution.Center}
      className="h-8 select-none"
      shadow="none"
    >
      <CoreLink href="/dashboard">
        <LogoIcon
          className="w-24 h-8"
          title="Core Logo"
          aria-label="Core Logo"
        />
      </CoreLink>
    </Stack>
  );
}
