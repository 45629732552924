import React from "react";
import { Frame, Inline } from "@ableco/baseline";
import cn from "clsx";

export default function Layout({ className, ...props }) {
  return (
    <Frame
      className={cn("relative", className)}
      style={{
        display: "grid",
        gridTemplateAreas: '"nav header" "nav main"',
        gridTemplateColumns: "240px 1fr",
        gridTemplateRows: "0 1fr",
        minHeight: "100vh",
        height: "100%",
      }}
      {...props}
    />
  );
}

export function Nav({ style = {}, ...props }) {
  return <Frame as="nav" style={{ ...style, gridArea: "nav" }} {...props} />;
}

export function Header({ style = {}, className, ...props }) {
  return (
    <Inline
      as="header"
      distribution="end"
      alignment="center"
      p={[0, 5, 0, 8]}
      style={{ ...style, height: "64px" }}
      className={className}
      {...props}
    />
  );
}

export function Main({ style = {}, ...props }) {
  return <Frame as="main" style={{ ...style, gridArea: "main" }} {...props} />;
}
