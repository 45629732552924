import { useResource } from "coreql";

export function useWeeklyReportData(weeklyReportId) {
  return useResource(
    "weekly-reports",
    weeklyReportId,
    {
      included: ["user"],
      fields: {
        weeklyReports: [
          "user",
          "periodStart",
          "periodEnd",
          "reviewed",
          "submitted",
        ],
        users: ["managerId", "preferredName", "avatarUrl"],
      },
    },
    "denormalized",
  );
}

export function useWeeklyReportWithGeneralFeedbackData(weeklyReportId) {
  return useResource(
    "weekly-reports",
    weeklyReportId,
    {
      included: ["user", "weeklyReportGeneralFeedback"],
      fields: {
        weeklyReports: [
          "user",
          "periodStart",
          "periodEnd",
          "reviewed",
          "submitted",
          "weeklyReportGeneralFeedback",
        ],
        users: ["managerId", "preferredName", "avatarUrl"],
      },
    },
    "denormalized",
  );
}
