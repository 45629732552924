import React from "react";
import {
  AccordionContent,
  AccordionGroup,
  AccordionTitle,
  Frame,
  Inline,
  Text,
} from "@ableco/baseline";
import { CheckBold, CloseBold, Lightbulb } from "@baseline/icons";
import CaretToggle from "../../components/caret-toggle";
import Drawer from "../../components/drawer";

function H3(props) {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <AccordionTitle
      p={[2, 0]}
      className="border-0 focus:outline-none"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {({ isOpen }) => (
        <Inline space={2}>
          <CaretToggle isOpen={isOpen} isHovered={isHovered} />
          <Text as="h3" leading="normal" weight="semibold" {...props} />
        </Inline>
      )}
    </AccordionTitle>
  );
}

function OL(props) {
  return (
    <Frame as="ol" p={[0, 0, 4, 6]}>
      <Text {...props} />
    </Frame>
  );
}

function LiCheck(props) {
  return (
    <Inline as="li" alignment="start" p={[2, 0]} space={2}>
      <Frame p={[1, 0, 0, 0]}>
        <CheckBold className="w-4 h-4 flex-none text-success-base" />
      </Frame>
      <Text color="neutral-800" leading="normal" {...props}></Text>
    </Inline>
  );
}

function LiClose(props) {
  return (
    <Inline as="li" alignment="start" p={[2, 0]} space={2}>
      <Frame p={[1, 0, 0, 0]}>
        <CloseBold className="w-4 h-4 flex-none text-alert-base" />
      </Frame>
      <Text color="neutral-800" leading="normal" {...props}></Text>
    </Inline>
  );
}

export function OneOnOneTipsDrawer({ onClose, ...props }) {
  return (
    <Drawer
      title="1-on-1"
      subtitle="Tips and Best Practices"
      onClose={onClose}
      icon={<Lightbulb color="white" className="w-6 h-6" />}
      {...props}
    >
      <ReflectionTips />
    </Drawer>
  );
}

export default function ReflectionTips() {
  return (
    <>
      <AccordionGroup>
        <H3>Scheduling</H3>
        <AccordionContent>
          <OL>
            <LiCheck>
              Meet with your reports weekly so they understand you are committed
              to them
            </LiCheck>
            <LiCheck>
              Schedule your 1-on-1 meeting as a recurring event each week with
              the same length of time (30-60min)
            </LiCheck>
            <LiCheck>
              Occasionally ask your direct reports if the current 1-on-1 date
              and time still works for them
            </LiCheck>
            <LiClose>
              Don’t cancel 1-on-1s with your reports, the act of canceling
              communicates to your reports that something else is more important
              to you than their success
            </LiClose>
            <LiClose>
              If you need to reschedule a 1-on-1 with your report, do not
              reschedule for the following week, try to find time this week
            </LiClose>
            <LiClose>
              Don’t move 1-on-1 meetings with your direct reports in favor of
              other priorities. Keep them on the same day and time of the week,
              this allows you and your reports to be well prepared for all
              1-on-1s
            </LiClose>
            <LiClose>
              Don’t schedule meetings during lunchtime or outside of your direct
              reports’ working hours
            </LiClose>
          </OL>
        </AccordionContent>
      </AccordionGroup>

      <AccordionGroup>
        <H3>Talking Points</H3>
        <AccordionContent>
          <OL>
            <LiCheck>
              Contribute to the meeting agenda by adding talking points
            </LiCheck>
            <LiCheck>
              Keep in mind that the primary goal of the 1-on-1s is to build
              trust and relationships with your reports, understand their
              strengths and weaknesses, and identify opportunities for help; the
              agenda needs to serve this goal
            </LiCheck>
            <LiCheck>
              Always start with your direct reports’ talking points. This action
              communicates to them that you want to hear about the points that
              are most important to them, and you are here to listen and help
            </LiCheck>
            <LiCheck>
              Start 1-on-1s with the same question that serves as a marker that
              it’s their turn to talk, for example: How is it going? How is
              life? What is on your mind?
            </LiCheck>
            <LiCheck>
              Listen actively, ask clarifying questions, confirm your
              understanding
            </LiCheck>
            <LiCheck>
              Always ask Anything else? prior to shifting to your talking points
            </LiCheck>
            <LiCheck>
              Use 1-on-1s to give timely and actionable feedback as well as to
              discuss recently sent and received feedback
            </LiCheck>
            <LiClose>Don’t start the agenda with your talking points</LiClose>
            <LiClose>
              Don’t start with the question about the project status until it’s
              your turn
            </LiClose>
            <LiClose>
              Don’t make them feel that your talking points are more important
              than theirs, even if they mostly talk about their family or their
              weekend, remember, you are here to build trust and establish a
              relationship and sometimes that means focusing more on personal
              lives of your reports
            </LiClose>
          </OL>
        </AccordionContent>
      </AccordionGroup>

      <AccordionGroup>
        <H3>Time Management</H3>
        <AccordionContent>
          <OL>
            <LiCheck>
              Start and end your meetings on time or earlier (if you cover all
              talking points)
            </LiCheck>
            <LiCheck>
              Evenly split the time between your report’s talking points and
              yours
            </LiCheck>
            <LiClose>
              Don’t take over their time and talking points with your priorities
            </LiClose>
            <LiClose>Don’t multitask during the 1-on-1s</LiClose>
          </OL>
        </AccordionContent>
      </AccordionGroup>

      <AccordionGroup>
        <H3>Follow Up</H3>
        <AccordionContent>
          <OL>
            <LiCheck>Clearly capture action items and next steps</LiCheck>
            <LiCheck>
              Confirm that you and your direct report have a shared
              understanding of each action item, deliverables, deadlines, and
              next steps
            </LiCheck>
            <LiCheck>
              Follow up on each action item and next steps during the week or
              during your next 1-on-1 at the latest (wait for your turn!)
            </LiCheck>
            <LiCheck>
              Ask for feedback on your management skills, 1-on-1 format, and
              discussion points. For example: Is there anything I should be
              doing for you that I am not doing?” or “Anything I should be doing
              better or more often?"
            </LiCheck>
            <LiClose>
              Don’t leave action items and next steps hanging unattended
            </LiClose>
            <LiClose>
              Don’t schedule follow up discussions without first confirming with
              your direct report that they will be prepared to contribute
            </LiClose>
          </OL>
        </AccordionContent>
      </AccordionGroup>
    </>
  );
}
