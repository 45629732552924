import React, { useState } from "react";
import { Flex, Frame, Inline } from "@ableco/baseline";
import { CheckBold } from "@baseline/icons";
import { noop } from "lodash";
import clsx from "clsx";

const checkboxColors = {
  blue: {
    classBackgroundColor: "bg-primary-base",
    classbackgroundColorHover: "bg-primary-light",
  },
  green: {
    classBackgroundColor: "bg-success-base",
    classbackgroundColorHover: "bg-success-light",
  },
};

/**
 *
 * @param {JSX.IntrinsicElements["input"]} props
 */
export function CheckboxWithLabel({
  checked: initialChecked,
  children,
  disabled,
  lineThrough = false,
  onChange = noop,
  checkboxColor = "blue",
  ...checkBoxProps
}) {
  const size = "w-4 h-4";
  const [checked, setChecked] = useState(!!initialChecked);
  function onToggle(event) {
    setChecked((p) => !p);
    onChange(event);
  }

  const classBackgroundColor =
    checkboxColors[checkboxColor].classBackgroundColor;

  const classbackgroundColorHover =
    checkboxColors[checkboxColor].classbackgroundColorHover;

  return (
    <Inline
      as="label"
      alignment="start"
      className={clsx("group", {
        "hover:cursor-pointer": !disabled,
        "hover:cursor-not-allowed": disabled,
      })}
    >
      <Flex
        className={clsx(
          size,
          "mt-1 mr-2 relative focus-within:shadow-outline transition-colors duration-300 ease-in-out",
          {
            "border border-neutral-400 text-transparent": !checked,
            "text-white": checked,
            [`${classBackgroundColor} group-hover:${classbackgroundColorHover}`]:
              checked && !disabled,
            "bg-neutral-400": !checked && disabled,
            "bg-neutral-500 text-opacity-50": checked && disabled,
          },
        )}
        corners="small"
        alignment="center"
        distribution="center"
      >
        <CheckBold width="75%" height="75%" className="absolute" />
        <input
          type="checkbox"
          className={`appearance-none ${size} focus:outline-none cursor-pointer`}
          disabled={disabled}
          checked={checked}
          onChange={onToggle}
          {...checkBoxProps}
        />
      </Flex>
      <Frame
        className={clsx("transition-colors duration-300 ease-in-out", {
          "line-through text-neutral-500": checked && lineThrough,
        })}
      >
        {children}
      </Frame>
    </Inline>
  );
}
