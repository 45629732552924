import React, { useCallback, useState } from "react";
import {
  Color,
  Corners,
  Frame,
  Inline,
  Stack,
  Text,
  TextSize,
} from "@ableco/baseline";
import { api, SSRSuspense, useCollection, useResource } from "coreql";
import Select from "../data-entry/select";
import Radio from "../data-entry/radio";
import { combineResponsibilities } from "../data-entry/select/responsibility-select";
import SkillSelect from "../data-entry/select/skill-select";
import { useCoreToast } from "../toast/toast";
import { PrimaryButton, TertiaryButton } from "../buttons/buttons";
import {
  BodySmall,
  BodySmallSemibold,
  TitleSemibold,
} from "../design-system/typography-components";
import Tag from "../tag";
import HighlightTypeTag from "../highlights/highlight-type-tag";
import HighlightUserLayout from "../highlights/highlight-user-layout";
import { capitalize } from "lodash";
import clsx from "clsx";
import { TextArea } from "../text-input";

function useUserWithReponsibilities(id) {
  return useResource("users", id, {}, "denormalized").data;
}

function SelectFallback() {
  return (
    <Frame
      border={Color.Neutral400}
      corners={Corners.MediumRounded}
      p={[1, 4]}
      className="w-full h-8"
    >
      <Text color={Color.Neutral700} size={TextSize.SM}>
        Loading...
      </Text>
    </Frame>
  );
}

function ResponsibilityFilter({ filteredResponsibilities, ...props }) {
  return (
    <Select
      options={filteredResponsibilities.map(({ id, title }) => ({
        value: id,
        label: title,
      }))}
      testId="responsibilities-select"
      className="w-full h-8 text-sm"
      {...props}
    />
  );
}

function ResponsibilitySelect({
  onChange,
  selectedResponsibilityId,
  receiverId,
  responsibilityId,
}) {
  const feedbackReceiver = useUserWithReponsibilities(receiverId);

  const { data: responsibilities } = useCollection(
    "responsibilities",
    {
      filters: {
        id: combineResponsibilities(feedbackReceiver).concat(
          responsibilityId || [],
        ),
      },
    },
    "denormalized",
  );

  const filteredResponsibilities = responsibilities.map((responsibility) =>
    responsibility.archivedAt
      ? { ...responsibility, title: responsibility.title + "- Archived" }
      : responsibility,
  );
  return (
    <Frame>
      <Inline>
        <ResponsibilityFilter
          id="responsibilities"
          placeholder="Select a Responsibility"
          value={
            selectedResponsibilityId ? selectedResponsibilityId.toString() : ""
          }
          filteredResponsibilities={filteredResponsibilities}
          onChange={onChange}
          data-testid="responsibilities"
        />
      </Inline>
    </Frame>
  );
}

function Label({ children, ...props }) {
  return (
    <BodySmall as="label" color={Color.Neutral800} {...props}>
      {children}
    </BodySmall>
  );
}

function OptionalLabel() {
  return <BodySmall color={Color.Neutral600}>(Optional)</BodySmall>;
}

function TypesSection({
  readOnly,
  selectedFeedbackType,
  handleFeedbackTypeChange,
}) {
  return (
    <Stack space={2}>
      <Label color={Color.Neutral700}>Type of highlight</Label>
      {readOnly ? (
        <HighlightTypeTag selectedFeedbackType={selectedFeedbackType} />
      ) : (
        <Stack space={2} className="w-full">
          {["Win", "Opportunity", "General"].map((label) => (
            <Radio
              key={label}
              label={label}
              id={label.toLowerCase()}
              checked={selectedFeedbackType === label.toLowerCase()}
              onChange={handleFeedbackTypeChange}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}

function TagSection({
  readOnly,
  skills,
  selectedSkillIds,
  setSelectedSkillIds,
}) {
  return (
    <Stack space={2}>
      <Inline space={1}>
        <Label htmlFor="skills" color={Color.Neutral700}>
          Tags
        </Label>
        {!readOnly && <OptionalLabel />}
      </Inline>
      {readOnly ? (
        <Inline wrap>
          {skills.map((skill) => (
            <Tag key={skill.id} text={skill.name} className="mb-1 mr-1" />
          ))}
        </Inline>
      ) : (
        <SSRSuspense fallback={<SelectFallback />}>
          <SkillSelect
            id="skills"
            multi
            placeholder="Search for a tag"
            value={selectedSkillIds}
            onChange={setSelectedSkillIds}
            initialSkills={skills}
          />
        </SSRSuspense>
      )}
    </Stack>
  );
}

function ResponsibilitySection({
  readOnly,
  responsibility,
  selectedResponsibilityId,
  handleResponsibilityChange,
  receiverId,
  responsibilityId,
}) {
  return (
    <Stack space={2}>
      <Inline space={1}>
        <Label htmlFor="responsibilities" color={Color.Neutral700}>
          Responsibility
        </Label>
        {!readOnly && <OptionalLabel />}
      </Inline>

      {readOnly ? (
        <BodySmallSemibold color={Color.Neutral700}>
          {responsibility?.title}
        </BodySmallSemibold>
      ) : (
        <SSRSuspense fallback={<SelectFallback />}>
          <ResponsibilitySelect
            id="responsibilities"
            selectedResponsibilityId={selectedResponsibilityId}
            onChange={handleResponsibilityChange}
            receiverId={receiverId}
            responsibilityId={responsibilityId}
          />
        </SSRSuspense>
      )}
    </Stack>
  );
}

function NotesSection({ readOnly, selectedNote, handleNoteChange }) {
  return (
    <Stack space={2}>
      <Inline space={1}>
        <Label htmlFor="note" color={Color.Neutral700}>
          Manager's notes
        </Label>
        {!readOnly && <OptionalLabel />}
      </Inline>
      {readOnly ? (
        <Frame p={[0, 5]}>
          <BodySmall color={Color.Neutral700}>{selectedNote}</BodySmall>
        </Frame>
      ) : (
        <TextArea
          autosize={115}
          id="note"
          value={selectedNote}
          onChange={handleNoteChange}
        />
      )}
    </Stack>
  );
}

function ContentSection({ content, start, end }) {
  return (
    <Inline p={0}>
      <Text
        as="p"
        size="base"
        leading="snug"
        decoration="line-thorough"
        wordBreak="all"
        whitespace="pre-wrap"
        style={{
          hyphens: "auto",
          color: "rgb(0,0,0,0.85)",
          letterspacing: "-0.1px",
        }}
      >
        {content?.slice(0, start)}
        <mark style={{ background: "rgba(255, 225, 95, 0.5)" }}>
          {content?.slice(start, end)}
        </mark>
        {content?.slice(end)}
      </Text>
    </Inline>
  );
}

export default function HighlightsPopover({
  highlightId,
  feedbackMessage,
  feedbackType,
  receiverId,
  responsibilityId,
  responsibility,
  skills,
  note,
  close,
  refetch,
  highlightedText,
  highlightStart,
  highlightEnd,
  relatedId,
  readOnly,
  source,
  user,
  userLayout = false,
  showDeleteButton = true,
  invertOrder = false,
}) {
  const [selectedFeedbackType, setSelectedFeedbackType] =
    useState(feedbackType);
  const [selectedResponsibilityId, setSelectedResponsibilityId] =
    React.useState(responsibilityId);
  const [selectedSkillIds, setSelectedSkillIds] = useState(() =>
    skills?.map(({ id }) => id || []),
  );

  const [selectedNote, setSelectedNote] = useState(note || "");
  const { successToast, errorToast } = useCoreToast();

  const handleFeedbackTypeChange = useCallback(
    (event) => setSelectedFeedbackType(event.target.id.toLowerCase()),
    [setSelectedFeedbackType],
  );

  const handleResponsibilityChange = useCallback(
    (event) => setSelectedResponsibilityId(event),
    [setSelectedResponsibilityId],
  );

  const handleNoteChange = useCallback(
    (event) => setSelectedNote(event.target.value),
    [setSelectedNote],
  );

  const handleCancel = useCallback(
    async (event) => {
      event.preventDefault();
      close();
    },
    [close],
  );

  const handleDelete = useCallback(
    async (event) => {
      event.preventDefault();
      if (confirm("Are you sure you want to delete this highlight?")) {
        try {
          await api.feedbackHighlights.find(highlightId).destroy();
          refetch();
          close();
          successToast("Your highlight has been deleted!");
        } catch {
          errorToast("We couldn't delete your highlight. Contact the team.");
        }
      }
    },
    [close, errorToast, highlightId, refetch, successToast],
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const trimmedNote = selectedNote.trim();
        const highlightExists = Boolean(highlightId);
        if (highlightExists) {
          const updateAttributes = {
            feedbackType: selectedFeedbackType,
            responsibilityId: selectedResponsibilityId,
            note: trimmedNote,
          };

          if (highlightStart || highlightEnd) {
            updateAttributes.start = highlightStart;
            updateAttributes.end = highlightEnd;
          }
          if (highlightedText != "") {
            updateAttributes.highlightedText = highlightedText;
          }

          await api.feedbackHighlights
            .find(highlightId)
            .update(updateAttributes);
          await api.feedbackHighlights.find(highlightId).update.relation(
            "skills",
            selectedSkillIds.map((id) => ({
              type: "skills",
              id,
            })),
          );
        } else {
          let highlightData = {
            feedbackType: selectedFeedbackType,
            responsibilityId: selectedResponsibilityId,
            note: trimmedNote,
            highlightedText,
            start: highlightStart,
            end: highlightEnd,
            source: source,
          };
          if (source === "cheer") {
            highlightData["cheerMentionId"] = relatedId;
          } else {
            highlightData["feedbackMessageId"] = relatedId;
          }
          const { data: newHighlight } = await api.feedbackHighlights.create(
            highlightData,
            selectedSkillIds.map((id) => ({
              resource: "skills",
              id,
            })),
          );

          await api.feedbackHighlights.find(newHighlight.id).update.relation(
            "skills",
            selectedSkillIds.map((id) => ({
              type: "skills",
              id,
            })),
          );
        }
        refetch();
        close();

        successToast(
          `Your highlight has been ${highlightExists ? "updated" : "created"}!`,
        );
      } catch {
        errorToast("We couldn't save your highlight. Contact the team.");
      }
    },
    [
      selectedNote,
      highlightId,
      refetch,
      close,
      successToast,
      selectedFeedbackType,
      selectedResponsibilityId,
      highlightStart,
      highlightEnd,
      highlightedText,
      selectedSkillIds,
      source,
      relatedId,
      errorToast,
    ],
  );

  function PopoverTitle() {
    function TitleText({ children }) {
      return <TitleSemibold color={Color.Neutral800}>{children}</TitleSemibold>;
    }
    if (readOnly) {
      return <TitleText>Highlight</TitleText>;
    }
    if (highlightId) {
      return <TitleText>Edit highlight</TitleText>;
    }

    return <TitleText>Add highlight</TitleText>;
  }

  const isSaveEnabled = Boolean(selectedFeedbackType);
  const showTagsSection = !readOnly || skills?.length > 0;
  const showResponsibilitySection = !readOnly || selectedResponsibilityId;
  const showNotesSection = !readOnly || selectedNote;

  const leftSide = (
    <Stack space={6} className="w-1/3">
      <TypesSection
        readOnly={readOnly}
        selectedFeedbackType={selectedFeedbackType}
        handleFeedbackTypeChange={handleFeedbackTypeChange}
      />
      {showTagsSection && (
        <TagSection
          readOnly={readOnly}
          skills={skills}
          selectedSkillIds={selectedSkillIds}
          setSelectedSkillIds={setSelectedSkillIds}
        />
      )}
    </Stack>
  );

  const rightSide = (
    <Stack space={6} className="w-2/3">
      {feedbackMessage && (
        <Stack space={3}>
          <ContentSection
            content={feedbackMessage.content || feedbackMessage.body}
            start={highlightStart}
            end={highlightEnd}
          />
          <Tag
            text={capitalize(source)}
            className={clsx("mb-1 mr-1", source === "cheer" ? "w-16" : "w-20")}
          />
        </Stack>
      )}
      {showResponsibilitySection && (
        <ResponsibilitySection
          readOnly={readOnly}
          responsibility={responsibility}
          handleResponsibilityChange={handleResponsibilityChange}
          receiverId={receiverId}
          responsibilityId={selectedResponsibilityId}
          selectedResponsibilityId={selectedResponsibilityId}
        />
      )}

      {showNotesSection && (
        <NotesSection
          readOnly={readOnly}
          handleNoteChange={handleNoteChange}
          selectedNote={selectedNote}
        />
      )}
    </Stack>
  );

  return (
    <Stack
      as="form"
      space={8}
      p={[6, 6, 6, 6]}
      data-testid={`highlight:form:${highlightId ? "edit" : "add"}`}
    >
      <Stack space={4}>
        {userLayout ? (
          <HighlightUserLayout
            user={user}
            date={feedbackMessage.submittedAt || feedbackMessage.createdAt}
          />
        ) : (
          <Stack>
            <Frame p={0}>
              <PopoverTitle />
            </Frame>
            <Frame className="border-t border-solid border-neutral-300 -mr-6 -ml-6" />
          </Stack>
        )}
        {invertOrder ? (
          <Inline alignment="top" p={0} space={8}>
            {rightSide}
            {leftSide}
          </Inline>
        ) : (
          <Inline alignment="top" p={0} space={8}>
            {leftSide}
            {rightSide}
          </Inline>
        )}
      </Stack>
      {!readOnly ? (
        <Inline p={0} distribution="between">
          {highlightId && showDeleteButton ? (
            <TertiaryButton onClick={handleDelete} text="Delete" />
          ) : (
            <TertiaryButton onClick={handleCancel} text="Cancel" />
          )}
          <PrimaryButton
            onClick={handleSubmit}
            disabled={!isSaveEnabled}
            text="Save"
          />
        </Inline>
      ) : (
        <Inline p={0} distribution="between">
          <TertiaryButton onClick={handleCancel} text="Cancel" />
        </Inline>
      )}
    </Stack>
  );
}
