import React from "react";
import formatDateRange from "../utils/formatDateRange";
import { format } from "date-fns";
import {
  ObjectiveStatusDot,
  KeyResultContent,
} from "./weekly-report/read-only-objective";
import { Text, Stack, Frame } from "@ableco/baseline";
import { MarkdownViewer } from "./markdown/markdown-viewer";
import { FeedbackMessageCard } from "./feedback/feedback-message-card";
import CoreLink from "./core-link";
import isKeyResultComplete from "../utils/is-key-result-complete";
import { Info } from "@baseline/icons";
import { wrap } from "./weekly-report/utils";
import { CoreTooltip } from "./core-tooltip";

function WeeklyReportAnswerResource({ relatedResource }) {
  return (
    <Stack
      space={1}
      className="border-l-4 border-neutral-400 ml-10 mt-1"
      p={[1, 4]}
    >
      <CoreLink
        href={`/v2_weekly_reports/${relatedResource.weeklyReportId}?since=${relatedResource.weeklyReport.periodStart}`}
        className="cursor-pointer hover:no-underline"
      >
        <Text color="neutral-600" size="xs">
          Answer from{" "}
          {formatDateRange(
            new Date(relatedResource.weeklyReport.periodStart),
            new Date(relatedResource.weeklyReport.periodEnd),
          )}
        </Text>
      </CoreLink>
      <MarkdownViewer
        value={relatedResource.data.text || `${relatedResource.data.value}/5`}
      />
    </Stack>
  );
}

function WeeklyReportFeedbackResource({ relatedResource, currentUser }) {
  return (
    <div data-feedback-id={relatedResource.id}>
      <Stack
        space={1}
        className="border-l-4 border-neutral-400 ml-10 mt-1"
        p={[1, 4]}
      >
        <CoreLink
          href={`/users/${currentUser.id}/feedback_messages/${relatedResource.id}`}
          className="cursor-pointer hover:no-underline"
        >
          <Text color="neutral-600" size="xs">
            Go to feedback message
          </Text>
        </CoreLink>
        <FeedbackMessageCard
          feedbackPrompt={relatedResource.feedbackPrompt}
          currentUser={currentUser}
          headerUser={relatedResource.sender}
          receiver={relatedResource.receiver}
          request={relatedResource.feedbackRequest}
          response={relatedResource}
          showResponsibilityInfoTooltip={true}
          displayRequestBodyAuthorLabel={true}
          bg="transparent"
        />
      </Stack>
    </div>
  );
}

function OneOnOneKeyResult({ keyResultStatusUpdate }) {
  let keyResult = keyResultStatusUpdate.keyResult;
  const isComplete = isKeyResultComplete(
    keyResult.decreasing,
    keyResultStatusUpdate.status,
    keyResult.target,
  );
  return (
    <Stack space={2}>
      <Stack space={1}>
        <Text color="neutral-800" size="sm">
          {keyResult.title}
        </Text>
        <Text color="neutral-600" size="sm">
          {format(new Date(keyResult.dueDate.split("-")), "MMM dd")}{" "}
        </Text>
      </Stack>
      <Text color="neutral-800" size="sm">
        <KeyResultContent
          kind={keyResult.type}
          status={keyResultStatusUpdate.status}
          target={keyResult.target}
          isComplete={isComplete}
        />
      </Text>
    </Stack>
  );
}

function ObjectiveResource({ relatedResource }) {
  return (
    <div data-objective-id={relatedResource.id}>
      <Stack
        space={1}
        className="border-l-4 border-neutral-400 ml-10 mt-1"
        p={[1, 4]}
      >
        <CoreLink
          href={`/v2_weekly_reports/${relatedResource.weeklyReport.id}?since=${relatedResource.weeklyReport.periodStart}`}
          className="cursor-pointer hover:no-underline"
        >
          <Text color="neutral-600" size="xs">
            {formatDateRange(
              new Date(relatedResource.weeklyReport.periodStart),
              new Date(relatedResource.weeklyReport.periodEnd),
            )}
          </Text>
        </CoreLink>
        <Stack space={4}>
          <Stack space={2}>
            <Text color="neutral-800" size="base" weight="semibold">
              {relatedResource.objective.title}
              {relatedResource.objective.ancestorsName && (
                <Frame className="ml-2 inline-block absolute">
                  <CoreTooltip
                    title="Parent OKR:"
                    label={relatedResource.objective.ancestorsName}
                  >
                    <Frame data-testid="parent-objective-info">
                      <Info className="w-4 h-4 opacity-45 hover:opacity-100 focus:opacity-100 transition-opacity duration-300 ease-in-out" />
                    </Frame>
                  </CoreTooltip>
                </Frame>
              )}
            </Text>
            <ObjectiveStatusDot status={relatedResource.status} />
          </Stack>
          <Stack space={6}>
            {wrap(relatedResource.weeklyReport.keyResultStatusUpdate).map(
              (keyResultStatus) => (
                <OneOnOneKeyResult
                  key={keyResultStatus.keyResult.id}
                  keyResultStatusUpdate={keyResultStatus}
                />
              ),
            )}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}

function TaskRelatedResource({
  relatedResourceType,
  relatedResource,
  currentUser,
}) {
  if (relatedResourceType === "WeeklyReportAnswer") {
    return <WeeklyReportAnswerResource relatedResource={relatedResource} />;
  }

  if (relatedResourceType === "FeedbackMessage") {
    return (
      <WeeklyReportFeedbackResource
        relatedResource={relatedResource}
        currentUser={currentUser}
      />
    );
  }

  if (relatedResourceType === "ObjectiveStatusUpdate") {
    return <ObjectiveResource relatedResource={relatedResource} />;
  }
  return null;
}

export { TaskRelatedResource };
