import React from "react";
import { api, denormalize } from "coreql";
import { Frame, Grid } from "@ableco/baseline";
import {
  UserFolder,
  Suitcase,
  Projects,
  Calendar,
  Home,
  BoxChecked,
  Chart,
  Users,
  Growth,
  Gear,
} from "@baseline/icons";
import checkPermission from "../../utils/check-permission";
import useCurrentUser from "../../hooks/use-current-user";
import { MenuItem, MenuLine } from "../layout/menu";
import useDirectReportsOf from "../../hooks/use-direct-reports-of";
import CoreLink from "../core-link";
import NavItem from "./nav-item";
import { currentPathMatches } from "../layout/user-layout";
import { useLocation } from "react-router";
import { AvatarSmall } from "../design-system/avatar";

function renderNavItems(items) {
  return items.map((item) => (
    <NavItem {...item} key={item.label} testId={item.label} />
  ));
}

function disableRedirect(e) {
  const { linkDisabled } = this.state;
  if (linkDisabled) e.preventDefault();
}

async function IsMyWeeklyReport(currentUserId, setIsAtOwnWeeklyReport) {
  let weeklyReportId = window.location.pathname.split("/")[2];
  // eslint-disable-next-line unicorn/no-array-callback-reference
  const weeklyReportData = await api.weeklyReports.find(weeklyReportId).read({
    fields: { "weekly-reports": ["userId"] },
  });
  const report = denormalize(weeklyReportData, "weeklyReports");
  setIsAtOwnWeeklyReport(report.userId == currentUserId);
}

const navigationTop = [
  {
    href: "/dashboard",
    label: "Home",
    icon: <Home className="w-4 h-4" />,
    isActive: (location) =>
      currentPathMatches(location, { path: "/dashboard" }),
  },
];

const navigationBottom = [
  {
    href: "/objectives",
    label: "Objectives",
    icon: <BoxChecked className="w-4 h-4" />,
    isActive: (location) =>
      currentPathMatches(location, { path: "/objectives" }),
  },
  {
    disabled: true,
    label: "HWL&G",
    icon: <Growth className="w-4 h-4" />,
    isActive: (location) =>
      currentPathMatches(location, { path: "/roles" }, { path: "/badges" }),
    children: (
      <>
        <MenuItem href="/roles">Roles at Able</MenuItem>
        <MenuItem href="/badges">Badges</MenuItem>
      </>
    ),
  },
  {
    href: "/projects",
    disabled: true,
    label: "Projects",
    isActive: (location) =>
      currentPathMatches(
        location,
        { path: "/products" },
        { path: "/staffing" },
      ),
    icon: <Projects className="w-4 h-4" />,
    children: (
      <>
        <MenuItem href="/products" prefetch={false}>
          Products
        </MenuItem>

        <MenuItem href="/staffing" prefetch={false}>
          Staffing
        </MenuItem>
      </>
    ),
  },
  {
    href: "/careers",
    label: "Careers",
    icon: <Suitcase className="w-4 h-4" />,
    isExternalLink: true,
  },
];

export function NavigationTop() {
  const location = useLocation();
  const { data: currentUser } = useCurrentUser();
  const directReports = useDirectReportsOf(currentUser.id);
  const directReportsIds = directReports.map((directReport) => directReport.id);

  const weeklyJournalHref = currentUser.hasPendingJournal
    ? "/weekly_reports/new"
    : "/weekly_reports/my_journal";
  const oneOnOneHref = `/1-on-1/${currentUser.managerId}/${currentUser.id}`;

  const [isAtOwnWeeklyReport, setIsAtOwnWeeklyReport] = React.useState(false);

  React.useEffect(() => {
    // This is first check is necesseary even though is repeated below
    // It prevents quickly deselecting and selecting when navigating to from /weekly_reports/new
    if (
      currentPathMatches(location, {
        path: "/weekly_reports/new",
      }) ||
      currentPathMatches(location, {
        path: "/v2_weekly_reports/:id/edit",
      })
    ) {
      setIsAtOwnWeeklyReport(true);
      return;
    }

    if (
      !currentPathMatches(location, {
        path: "/v2_weekly_reports",
      })
    ) {
      setIsAtOwnWeeklyReport(false);
      return;
    }
    IsMyWeeklyReport(currentUser.id, setIsAtOwnWeeklyReport);
  }, [currentUser.id, location]);

  const currentUserNavigationTop = [
    ...navigationTop,
    {
      href: "my-core",
      disabled: true,
      label: "My Core",
      onClick: disableRedirect,
      isActive: (location) =>
        currentPathMatches(
          location,
          { path: "/weekly_reports/new" },
          { path: "/weekly_reports/my_journal" },
          { path: `/1-on-1/${currentUser.managerId}` },
          { path: `/1-on-1/${currentUser.id}` },
          { path: "/my-core" },
          { path: `/users/${currentUser.id}` },
        ) || isAtOwnWeeklyReport,
      icon: <Calendar className="w-4 h-4" />,
      children: (
        <>
          <MenuItem href={weeklyJournalHref} prefetch={false}>
            Weekly Journals
          </MenuItem>
          {currentUser.managerId && (
            <MenuItem href={oneOnOneHref} prefetch={false}>
              1-on-1 with Manager
            </MenuItem>
          )}
          <MenuItem
            href="/my-core/highlights"
            disabled
            isActive={(location) =>
              currentPathMatches(
                location,
                { path: "users/:id/feedback_messages/" },
                { path: "my-core/highlights" },
                { path: "users/:id/badges" },
              )
            }
            menu={
              <>
                <MenuItem
                  href={`/users/${currentUser.id}/feedback_messages/received`}
                  prefetch={false}
                >
                  Feedback
                </MenuItem>
                <MenuItem href="/my-core/highlights">Highlights</MenuItem>
                <MenuItem href={`/users/${currentUser.id}/badges`}>
                  My Badges
                </MenuItem>
              </>
            }
          >
            Growth
          </MenuItem>
          <MenuItem
            href={`/users/${currentUser.id}`}
            disabled
            isActive={(location) =>
              currentPathMatches(
                location,
                { path: "users/:id" },
                { path: "users/:id/capabilities" },
              )
            }
            menu={
              <>
                <MenuItem href={`/users/${currentUser.id}`}>
                  Responsibilities
                </MenuItem>
                <MenuItem href={`/users/${currentUser.id}/capabilities`}>
                  Capabilities
                </MenuItem>
              </>
            }
          >
            My Role
          </MenuItem>
        </>
      ),
    },
    {
      href: "/my-team",
      disabled: true,
      label: "My Team",
      isActive: (location) =>
        currentPathMatches(
          location,
          { path: "/weekly_reports/teams_journal" },
          { path: "/my-team" },
          ...directReportsIds.map((id) => ({ path: `/users/${id}` })),
          ...directReportsIds.map((id) => ({ path: `/1-on-1/${id}` })),
        ) ||
        (currentPathMatches(location, { path: "/v2_weekly_reports" }) &&
          !isAtOwnWeeklyReport),
      icon: <UserFolder className="w-4 h-4" />,
      renderIf: ({ currentUser }) => currentUser?.isManager,
      children: (
        <>
          <MenuItem href="/weekly_reports/teams_journal" prefetch={false}>
            Team Journals
          </MenuItem>
          <MenuLine />
          <Grid col={4} p={[2, 4, 2, 4]} gap={4}>
            {directReports.map((user) => (
              <CoreLink href={`/my-team/${user.id}/highlights`} key={user.id}>
                <AvatarSmall
                  url={user.avatarUrl}
                  name={user.fullName}
                  title={user.fullName}
                  userId={user.id}
                  withTooltip
                />
              </CoreLink>
            ))}
          </Grid>
        </>
      ),
    },
  ];

  return (
    <Frame>
      {renderNavItems(
        currentUserNavigationTop
          .filter((item) =>
            item.renderIf ? item.renderIf({ currentUser }) : true,
          )
          .map((item) =>
            typeof item.children === "function"
              ? { ...item, children: item.children({ currentUser }) }
              : item,
          ),
      )}
    </Frame>
  );
}

export function NavigationBottom() {
  const { data: currentUser } = useCurrentUser();
  const directReports = useDirectReportsOf(currentUser.id);
  const directReportsIds = directReports.map((directReport) => directReport.id);

  const canManageUsers =
    checkPermission(currentUser, "manage_users") ||
    checkPermission(currentUser.role, "manage_users");
  const canViewFinances =
    checkPermission(currentUser, "view_finances") ||
    checkPermission(currentUser.role, "view_finances");
  const canViewUtilization =
    checkPermission(currentUser, "view_utilization_reports") ||
    checkPermission(currentUser.role, "view_utilization_reports");
  const canViewBudget =
    checkPermission(currentUser, "view_budget_reports") ||
    checkPermission(currentUser.role, "view_budget_reports");
  const canViewRevenue =
    checkPermission(currentUser, "view_revenue_reports") ||
    checkPermission(currentUser.role, "view_revenue_reports");

  const canManageQuestions =
    checkPermission(currentUser, "manage_weekly_report_questions") ||
    checkPermission(currentUser.role, "manage_weekly_report_questions");

  const currentUserNavigationBottom = [
    {
      href: "/people",
      label: "People",
      disabled: true,
      isActive: (location) =>
        currentPathMatches(
          location,
          { path: "/people" },
          { path: `/users/:id` },
          { path: "/1-on-1/:id" },
        ) &&
        !currentPathMatches(
          location,
          ...directReportsIds.map((id) => ({ path: `/users/${id}` })),
          ...directReportsIds.map((id) => ({ path: `/1-on-1/${id}` })),
          { path: `/1-on-1/${currentUser.managerId}` },
          { path: `/1-on-1/${currentUser.id}` },
          { path: `/users/${currentUser.id}` },
        ),
      icon: <Users className="w-4 h-4" />,
      children: (
        <>
          <MenuItem href="/people">Directory</MenuItem>
          {canManageUsers && (
            <>
              <MenuLine />
              <MenuItem
                href="/admin/users"
                prefetch={false}
                isExternalLink={true}
              >
                User Management
              </MenuItem>
            </>
          )}
        </>
      ),
    },
    ...navigationBottom,
    {
      href: "/reports?by=month",
      disabled: true,
      label: "Financials",
      isActive: (location) =>
        currentPathMatches(location, { path: "/reports" }),
      icon: <Chart className="w-4 h-4" />,
      renderIf: ({ currentUser }) => {
        if (!currentUser) {
          return false;
        }

        if (
          canViewFinances ||
          canViewUtilization ||
          canViewBudget ||
          canViewRevenue
        ) {
          return true;
        }
      },
      children: (
        <>
          <MenuItem href="/reports?by=month" prefetch={false}>
            Monthly Reports
          </MenuItem>
          <MenuItem href="/reports?by=week" prefetch={false}>
            Weekly Reports
          </MenuItem>
          {(canViewFinances || canViewUtilization) && (
            <MenuItem
              href="/reports/utilization"
              prefetch={false}
              isExternalLink={true}
            >
              Utilization
            </MenuItem>
          )}
          {(canViewFinances || canViewBudget) && (
            <MenuItem
              href="/reports/budgets"
              prefetch={false}
              isExternalLink={true}
            >
              Budgets
            </MenuItem>
          )}
          {(canViewFinances || canViewRevenue) && (
            <MenuItem href="/reports/revenue" prefetch={false}>
              Revenue
            </MenuItem>
          )}
        </>
      ),
    },
    {
      label: "Configuration",
      isActive: (location) =>
        currentPathMatches(location, { path: "/weekly_questions/" }),
      icon: <Gear className="w-4 h-4" />,
      renderIf: ({ currentUser }) => {
        if (!currentUser) {
          return false;
        }

        if (canManageQuestions) {
          return true;
        }
      },
      children: (
        <>
          {canManageQuestions && (
            <MenuItem href="/weekly_questions/scheduled">
              Weekly Questions
            </MenuItem>
          )}
        </>
      ),
    },
  ];

  return (
    <Frame>
      {renderNavItems(
        currentUserNavigationBottom
          .filter((item) =>
            item.renderIf ? item.renderIf({ currentUser }) : true,
          )
          .map((item) =>
            typeof item.children === "function"
              ? { ...item, children: item.children({ currentUser }) }
              : item,
          ),
      )}
    </Frame>
  );
}
