import React from "react";
import noop from "../utils/noop";
import {
  AccordionGroup,
  AccordionTitle,
  Inline,
  Frame,
  Text,
  useAccordionValue,
  FlexDistribution,
  Color,
  Stack,
  Flex,
  FlexAlignment,
  FlexDirection,
} from "@ableco/baseline";
import {
  CaretRight,
  CheckCircleFilled,
  CompanyObjective,
  CircleProgressFilled,
  CircleOutline,
} from "@baseline/icons";
import { motion, AnimatePresence } from "framer-motion";
import {
  BodyBaseline,
  Heading,
  TitleSemibold,
  BodySmall,
} from "./design-system/typography-components";
import CoreLink from "./core-link";
import { AvatarMedium } from "./design-system/avatar";
import { CoreTooltip } from "./core-tooltip";

function AccordionIcon({ isOpen, isEmpty = false }) {
  return (
    <Frame as="i" className="mr-2" corners="full">
      <Text color={isEmpty ? "neutral-500" : "neutral-800"} className="mt-1">
        <Text srOnly>{isOpen ? "Close Accordion" : "Open Accordion"}</Text>
        <Frame
          as={motion.div}
          initial={{ rotate: 0 }}
          animate={isOpen ? { rotate: 90 } : { rotate: 0 }}
          transition={{ duration: 0.25, type: "spring", ease: "easeIn" }}
        >
          <CaretRight
            className="w-4 h-4"
            color={isEmpty ? "neutral-500" : "base"}
          />
        </Frame>
      </Text>
    </Frame>
  );
}

function accordionContentSpacing(variant) {
  if (variant === "small") return [0, 5, 8, 6];
  if (variant === "objectiveAccordion") return [0, 0, 0, 6];
  return [8, 20];
}

function AccordionBody({ children, variant }) {
  const isOpen = useAccordionValue();

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <Frame
          as={motion.div}
          p={accordionContentSpacing(variant)}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: {
              opacity: 1,
              height: "auto",
              transitionEnd: {
                overflowY: "visible",
              },
            },
            collapsed: { opacity: 0.3, height: 0, overflowY: "hidden" },
          }}
          transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {children}
        </Frame>
      )}
    </AnimatePresence>
  );
}

function AccordionStateIcon({ state = "empty" }) {
  switch (state) {
    case "ready":
      return (
        <CheckCircleFilled
          className="w-6 h-6 text-success-light"
          data-state={state}
          data-testid="state-marker"
        />
      );
    case "progress":
      return (
        <CircleProgressFilled
          className="w-6 h-6 text-primary-dark"
          data-state={state}
          data-testid="state-marker"
        />
      );
    default:
      return (
        <CircleOutline
          className="w-6 h-6 text-neutral-500"
          data-state={state}
          data-testid="state-marker"
        />
      );
  }
}

function ObjectiveAccordionTitle({
  title,
  department,
  companyObjective,
  href,
}) {
  const content = (
    <Flex direction={FlexDirection.Vertical}>
      <BodyBaseline className="text-left" color={Color.Neutral700}>
        {title}
      </BodyBaseline>

      <Inline>
        {companyObjective && (
          <CoreTooltip label="Company Objective">
            <Frame>
              <CompanyObjective className="h-3 w-3 mr-1 hover:opacity-100 focus:opacity-100 transition-all duration-300 ease-in-out" />
            </Frame>
          </CoreTooltip>
        )}
        {department && (
          <BodySmall color={Color.Neutral600}>{department}</BodySmall>
        )}
      </Inline>
    </Flex>
  );

  if (href) {
    return (
      <CoreLink className="hover:no-underline" href={href}>
        {content}
      </CoreLink>
    );
  }

  return content;
}

function AccordionTitleText({
  variant,
  title,
  department,
  companyObjective,
  href,
}) {
  if (variant === "small")
    return (
      <Stack>
        <TitleSemibold color={Color.Neutral800}>{title}</TitleSemibold>
      </Stack>
    );

  if (variant === "objectiveAccordion")
    return (
      <ObjectiveAccordionTitle
        title={title}
        department={department}
        companyObjective={companyObjective}
        href={href}
      />
    );

  return (
    <Heading color={Color.Neutral800} as="h2">
      {title}
    </Heading>
  );
}

function AccordionContainerContent({
  isOpen,
  userIcon,
  userName,
  variant,
  title,
  department,
  companyObjective,
  href,
  objectiveStatus,
  state,
  stateLabel,
  p,
  arrowIconClassName,
}) {
  if (variant === "objectiveAccordion")
    return (
      <Flex
        className="w-full"
        distribution={FlexDistribution.Between}
        alignment={FlexAlignment.Center}
      >
        <Inline space={2}>
          <Frame className={arrowIconClassName ? arrowIconClassName : "h-5"}>
            <AccordionIcon isOpen={isOpen} />
          </Frame>

          {userIcon && (
            <AvatarMedium url={userIcon} name={userName} withTooltip />
          )}

          <AccordionTitleText
            variant={variant}
            title={title}
            department={department}
            companyObjective={companyObjective}
            href={href}
          />
        </Inline>
        {objectiveStatus}
      </Flex>
    );

  return (
    <Inline distribution={FlexDistribution.Between} className="w-full">
      <Inline className="text-left" p={p ? p : [4, 0]}>
        <AccordionIcon isOpen={isOpen} />
        <AccordionTitleText variant={variant} title={title} />
      </Inline>
      <Inline space={2}>
        {stateLabel && (
          <BodyBaseline color={Color.Neutral700}>{stateLabel}</BodyBaseline>
        )}
        {state != "none" && <AccordionStateIcon state={state} />}
      </Inline>
    </Inline>
  );
}

export default function Accordion({
  title,
  children,
  state,
  stateLabel,
  label,
  onActivate = noop,
  variant,
  userIcon,
  userName,
  department,
  companyObjective = false,
  objectiveStatus,
  href = "",
  classname = "w-full",
  isOpen,
  p,
  arrowIconClassName = "",
}) {
  return (
    <AccordionGroup isOpen={isOpen}>
      <AccordionTitle
        direction="horizontal"
        alignment="start"
        className={classname}
        aria-label={label}
        onClick={onActivate}
      >
        {({ isOpen }) => (
          <AccordionContainerContent
            isOpen={isOpen}
            userIcon={userIcon}
            userName={userName}
            variant={variant}
            title={title}
            department={department}
            companyObjective={companyObjective}
            href={href}
            objectiveStatus={objectiveStatus}
            state={state}
            stateLabel={stateLabel}
            p={p}
            arrowIconClassName={arrowIconClassName}
          />
        )}
      </AccordionTitle>
      <AccordionBody children={children} variant={variant} />
    </AccordionGroup>
  );
}
