import React from "react";
import { useUserProfile } from "../layout/user-layout";
import { api, useCollection, denormalize } from "coreql";
import useCurrentUser from "../../hooks/use-current-user";
import {
  Frame,
  Stack,
  Inline,
  Text,
  Touchable,
  useOnClickOutside,
  useOnEscapePress,
  Skeleton,
  Grid,
  noop,
  Color,
} from "@ableco/baseline";
import { Edit, EllipsisVertical, Delete, Lock } from "@baseline/icons";
import { useId } from "@reach/auto-id";
import useToggle from "../../hooks/use-toggle";
import Menu, { MenuItem } from "../layout/menu";
import useMachine from "../../hooks/use-machine";
import { ReflectionTipsDrawer } from "./tips-reflections.js";
import { AnimatePresence } from "framer-motion";
import { wrap } from "../weekly-report/utils";
import { Heading } from "../design-system/typography-components";
import { PrimaryButton, TertiaryButton, TipsButton } from "../buttons/buttons";
import { MarkdownEditor } from "../markdown/markdown-editor";
import { MarkdownViewer } from "../markdown/markdown-viewer";

function getReflectionsOptions({ receiverId }) {
  return {
    included: ["sender"],
    sort: ["-id"],
    fields: {
      users: ["fullName"],
      reflections: ["sender", "body", "createdAt", "updatedAt", "isPrivate"],
    },
    filters: { active: true, receiverId },
  };
}

export function readReflections(receiverId) {
  return api.reflections.read(getReflectionsOptions({ receiverId }));
}

function useReflections({ receiverId }) {
  return wrap(
    denormalize(
      useCollection("reflections", getReflectionsOptions({ receiverId })).data,
      "reflections",
    ),
  );
}

function isSubmitButtonDisabled(
  isSubmitting,
  isPrivateChanged,
  valueChanged,
  isValueEmpty,
) {
  if (isSubmitting || isValueEmpty) return true;
  const hasChanges = isPrivateChanged || valueChanged;
  return !hasChanges;
}

function ReflectionForm({
  initialValue = "",
  editId = null,
  onClose = noop,
  initialIsPrivate = false,
  isPrivateEnabled = true,
}) {
  const { data: currentUser } = useCurrentUser();
  const userProfile = useUserProfile();
  const [value, setValue] = React.useState(initialValue);
  const [isSubmitting, toggle] = useToggle(false);
  const [isPrivate, setIsPrivate] = React.useState(initialIsPrivate);
  const id = useId(editId || Date.now());

  const isPrivateChanged = isPrivate !== initialIsPrivate;
  const valueChanged = value.trim() !== initialValue.trim();
  const isEdited = editId || valueChanged;

  useOnEscapePress(
    React.useCallback(() => {
      setValue("");
      onClose();
    }, [onClose]),
  );

  async function handleSubmit(event) {
    event.preventDefault();
    if (!isPrivateChanged && !valueChanged) return;
    // set it as submitting
    toggle();
    // create or update the reflection
    await api.reflections
      .find(editId)
      .upsert(
        { body: value, isPrivate },
        { senderId: currentUser.id, receiverId: userProfile.id },
      );
    // revalidate data of the list
    // don't await since we don't care when this finish
    await readReflections(userProfile.id);
    // let parent know we finished to close the form
    setValue("");
    setIsPrivate(false);
    toggle();
    onClose();
  }

  return (
    <Frame as="form" p={editId ? [4, 0] : [6, 4]} onSubmit={handleSubmit}>
      <MarkdownEditor
        placeholder="Use this space to capture your thoughts and reflections on the recent feedback, successes and opportunities."
        text={value}
        onChange={(event) => setValue(event.target.value)}
        disabled={isSubmitting}
        type="multiline"
        autosize={88}
        popupRigth={250}
        style={{ paddingRight: "16px" }}
      />
      {isEdited && (
        <Inline p={[2, 0, 0, 0]}>
          {isPrivateEnabled && (
            <>
              <Frame
                as="input"
                type="checkbox"
                id={id}
                className="mr-2 inline-block text-xs"
                checked={isPrivate}
                onChange={() => setIsPrivate((current) => !current)}
              />
              <Text
                as="label"
                htmlFor={id}
                size="sm"
                color="neutral-600"
                className="select-none"
              >
                Only visible to you
              </Text>
            </>
          )}
          <Inline className="ml-auto">
            <TertiaryButton
              text="Cancel"
              small
              className="mr-2"
              onClick={() => {
                setValue("");
                setIsPrivate(false);
                onClose();
              }}
            />
            <PrimaryButton
              text="Save"
              small
              disabled={isSubmitButtonDisabled(
                isSubmitting,
                isPrivateChanged,
                valueChanged,
                value.trim() === "",
              )}
            />
          </Inline>
        </Inline>
      )}
    </Frame>
  );
}

function ReflectionMenu({ onClose, id, onEdit = noop, onError = noop }) {
  const $menu = React.useRef();
  useOnClickOutside($menu, onClose);
  const userProfile = useUserProfile();

  async function handleDeleteClick() {
    if (!confirm("Are you sure you want to delete this reflection?")) {
      return onClose();
    }
    try {
      await api.reflections.find(id).destroy();
      readReflections(userProfile.id);
      onClose();
    } catch (error) {
      onError(error);
    }
  }

  return (
    <Menu
      innerRef={$menu}
      className="absolute top-0 right-0 z-10 w-32"
      shadow="md"
    >
      <Touchable onClick={onEdit} className="w-full">
        <MenuItem>
          <Inline>
            <Edit className="h-4 w-4 mr-4" />
            Edit
          </Inline>
        </MenuItem>
      </Touchable>
      <Touchable onClick={handleDeleteClick} className="w-full">
        <MenuItem>
          <Inline>
            <Delete className="h-4 w-4 mr-4" />
            Delete
          </Inline>
        </MenuItem>
      </Touchable>
    </Menu>
  );
}

function Reflection({
  id,
  body,
  sender,
  currentUser,
  createdAt,
  updatedAt,
  isPrivate,
}) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [{ state, error }, dispatch] = useMachine(
    {
      menuOpen: () => ({ state: "menuOpen" }),
      error: ({ message }) => ({ state: "error", error: message }),
      edit: () => ({ state: "edit" }),
      idle: () => ({ state: "idle" }),
    },
    { state: "idle" },
  );

  const isSameUser = currentUser.id === sender.id;
  const fullName = isSameUser ? "you" : sender.fullName;
  const dateString =
    updatedAt !== createdAt ? `Updated by ${fullName}` : `Added by ${fullName}`;

  const handleDeleteError = React.useCallback(() => {
    dispatch({
      type: "error",
      message: "Deletes are only allowed for the reflections you wrote.",
    });
  }, [dispatch]);

  // Clear the error and went back to idle after 2s
  React.useEffect(() => {
    if (state !== "error") return;
    const timer = setTimeout(() => dispatch({ type: "idle" }), 2000);
    return () => clearTimeout(timer);
  }, [state, dispatch]);

  return (
    <>
      <Frame
        as="article"
        p={[0, 0, 6, 0]}
        className="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
          if (state === "menuOpen") dispatch({ type: "idle" });
        }}
      >
        {state === "edit" ? (
          <ReflectionForm
            initialValue={body}
            editId={id}
            onClose={() => dispatch({ type: "idle" })}
            initialIsPrivate={isPrivate}
            isPrivateEnabled={isPrivate}
          />
        ) : (
          <>
            {isHovered && sender.id === currentUser.id && (
              <Frame className="absolute top-0 right-0">
                <Touchable
                  bg="info"
                  onClick={(event) => {
                    if (state === "menuOpen") {
                      dispatch({ type: "idle" });
                    } else {
                      dispatch({ type: "menuOpen" });
                    }
                    event.currentTarget.blur();
                  }}
                >
                  <Text color="neutral-800">
                    <EllipsisVertical />
                    <Text srOnly>Reflection Menu</Text>
                  </Text>
                </Touchable>
                {state === "menuOpen" && (
                  <ReflectionMenu
                    onClose={() => dispatch({ type: "idle" })}
                    id={id}
                    onEdit={() => dispatch({ type: "edit" })}
                    onError={handleDeleteError}
                  />
                )}
              </Frame>
            )}

            <Frame p={[0, 6, 2, 0]}>
              <MarkdownViewer
                value={body}
                className={isPrivate ? "text-neutral-600" : "text-neutral-800"}
              />
            </Frame>

            <Text as="div" color="neutral-600" leading="normal">
              <Inline>
                {isPrivate ? (
                  <>
                    <Lock className="w-4 h-4 mr-2" /> Only visible to you
                  </>
                ) : (
                  dateString
                )}{" "}
                -{" "}
                {new Intl.DateTimeFormat("en-US", {
                  month: "2-digit",
                  year: "numeric",
                  day: "2-digit",
                }).format(
                  new Date(updatedAt === createdAt ? createdAt : updatedAt),
                )}
              </Inline>
            </Text>

            {state === "error" && (
              <Frame p={[1, 0]}>
                <Text as="p" color="alert" size="sm" leading="normal">
                  {error}
                </Text>
              </Frame>
            )}
          </>
        )}
      </Frame>
    </>
  );
}

export default function Reflections() {
  const { data: currentUser } = useCurrentUser();
  const userProfile = useUserProfile();
  const reflections = useReflections({ receiverId: userProfile.id });
  const hasReflections = reflections.length > 0;
  const isDirectManager = userProfile.managerId === Number(currentUser.id);
  const isMine = Number(userProfile.id) === Number(currentUser.id);

  return (
    <Frame as="section" bg="white" corners="small" className="w-full h-full">
      <Header />
      {(isDirectManager || isMine) && <ReflectionForm />}

      <Frame as="section" p={[4, 4, 4, 4]}>
        {!hasReflections ? (
          <Frame as="article">
            <Text color="neutral-800">
              {currentUser.id == userProfile.id
                ? "You do not have any reflections yet. They will appear here once you or your manager add them."
                : "You do not have any reflections yet. They will appear here once you or your direct report add them."}
            </Text>
          </Frame>
        ) : (
          reflections.map((reflection) => (
            <Reflection
              key={reflection.id}
              {...reflection}
              currentUser={currentUser}
            />
          ))
        )}
      </Frame>
    </Frame>
  );
}

function Header() {
  const [showModal, setShowModal] = React.useState(false);
  const handleClose = React.useCallback(
    () => setShowModal(false),
    [setShowModal],
  );

  return (
    <Inline className="mb-4" alignment="baseline" space={2}>
      <Heading color={Color.Neutral800}>Reflections</Heading>
      <TipsButton onClick={() => setShowModal(true)} />
      <AnimatePresence>
        {showModal && <ReflectionTipsDrawer onClose={handleClose} />}
      </AnimatePresence>
    </Inline>
  );
}

export function Fallback() {
  return (
    <Stack alignment="start" bg="white" p={[5, 4]} className="w-full">
      <Skeleton
        height={16}
        width={144}
        color="neutral-400"
        alt="Reflections section"
      />
      <Skeleton
        alt="Form to create new reflection"
        height={90}
        width="100%"
        color="white"
        border="neutral-400"
        className="mt-10 mb-6"
      />
      <Grid row={4} gap={6} className="w-full">
        {Array.from({ length: 4 }, (_, index) => (
          <Grid row={3} gap={4} key={index} className="w-full">
            <Skeleton
              alt="Reflection body"
              color="neutral-400"
              height={8}
              width="100%"
            />
            <Skeleton
              alt="Reflection body"
              color="neutral-400"
              height={8}
              width="100%"
            />
            <Skeleton
              alt="Reflection author and date"
              color="neutral-400"
              height={8}
              width="60%"
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
