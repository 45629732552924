import React, { useRef, useCallback, useEffect } from "react";
import { Color, Frame, Inline, Stack, TextWhitespace } from "@ableco/baseline";
import {
  BodySmall,
  TitleSemibold,
} from "../design-system/typography-components";

export default function CapabilitiesTableHeader({
  skill,
  isFirst,
  setSkillName,
}) {
  const ref = useRef(null);
  const handleScroll = useCallback(() => {
    if (isFirst) setSkillName(skill);
    else if (ref.current && ref.current.getBoundingClientRect().top <= 70) {
      setSkillName(skill);
    }
  }, [skill, setSkillName, isFirst]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (isFirst) setSkillName(skill);
    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, [handleScroll, isFirst, setSkillName, skill]);

  if (isFirst) return null;
  return (
    <Frame bg={Color.White} innerRef={ref}>
      <Stack space={4} p={[4, 0]}>
        <TitleSemibold whitespace={TextWhitespace.NoWrap}>
          {skill}
        </TitleSemibold>
        <Inline>
          <Frame className="w-1/3">
            <BodySmall color={Color.Neutral600}>Capabilities</BodySmall>
          </Frame>
        </Inline>
      </Stack>
    </Frame>
  );
}
