// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Constants
import AppTypes from "core-redux/constants/AppTypes";
// Components
import Milestones from "budget/components/Milestones";
import Sheet from "budget/components/Sheet";
// Styles
import "./styles/App.scss";
import { Heading } from "../../../components/design-system/typography-components";
import { Color, Stack } from "@ableco/baseline";

class App extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    project: AppTypes.project,
  };

  render() {
    const { isLoading, project } = this.props;

    if (isLoading || !project) {
      return "Loading...";
    }

    return (
      <Stack space={1}>
        <Heading as="h2" color={Color.Neutral800}>
          {project.productName} &mdash; {project.name} Budget
        </Heading>
        <Stack>
          <Milestones project={project} />
          <Sheet project={project} />
        </Stack>
      </Stack>
    );
  }
}

export default App;
