import {
  Center,
  Frame,
  Inline,
  Inset,
  Portal,
  Skeleton,
  Stack,
  Text,
  Touchable,
  useLockBodyScroll,
  useOnClickOutside,
  useOnEscapePress,
} from "@ableco/baseline";
import { Close } from "@baseline/icons";
import { SSRSuspense, useResource } from "coreql";
import { capitalize, noop } from "lodash";
import React, { useCallback, useState } from "react";
import { CoreBackdrop } from "../../backdrop/backdrop";
import { PrimaryButton, SecondaryButton } from "../../buttons/buttons";
import { CoreDatePicker } from "../../data-entry/month-picker/core-date-picker";
import { BodySmall, Heading } from "../../design-system/typography-components";
import Tag from "../../tag";

function AwardFormFallback() {
  return (
    <Stack as="form" space={6}>
      <Stack space={4}>
        <Stack alignment="start" space={2}>
          <Skeleton height={32} width={220} color="neutral-300" animated />
          <Skeleton
            height={24}
            corners="small"
            width={100}
            color="neutral-200"
            animated
          />
        </Stack>
        <Skeleton height={80} width="100%" color="neutral-200" animated />
      </Stack>

      <Skeleton
        height={42}
        corners="small"
        width="100%"
        color="neutral-200"
        animated
      />
      <Skeleton height={50} width={650} color="neutral-200" animated />

      <Inline distribution="between">
        <Skeleton
          height={42}
          corners="small"
          width={100}
          color="neutral-200"
          animated
        />
        <Skeleton
          height={42}
          corners="small"
          width={100}
          color="neutral-400"
          animated
        />
      </Inline>
    </Stack>
  );
}

function AwardForm({ badgeId, action, onSubmit, onCancel }) {
  const { data: badge } = useResource(
    "badges",
    badgeId,
    {
      fields: { badges: ["name", "kind", "description"] },
    },
    "denormalized",
  );

  const [date, setDate] = useState(new Date());

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit({ success: true, date });
  }
  return (
    <Stack as="form" onSubmit={handleSubmit} space={6}>
      <Stack space={4}>
        <Stack alignment="start" space={2}>
          <Heading color="neutral-800">
            {capitalize(action)}: {badge.name}
          </Heading>
          <Tag type="general" text={capitalize(badge.kind)} />
        </Stack>
        {badge.description && (
          <BodySmall color="neutral-700">{badge.description}</BodySmall>
        )}
      </Stack>
      <Frame style={{ maxWidth: 284 }}>
        <CoreDatePicker
          maxDate={Date.now()}
          selected={date}
          onChange={(date) => setDate(date)}
          required
        >
          {({ toggleCalendar }) => (
            <Inline p={[2, 3]} className="border-t border-neutral-200">
              <Touchable
                type="button"
                className="w-full"
                onClick={() => {
                  setDate(Date.now());
                  toggleCalendar(false);
                }}
              >
                <BodySmall
                  color={["primary", "primary-light"]}
                  className="transition-all duration-300 ease-in-out"
                >
                  Today
                </BodySmall>
              </Touchable>
            </Inline>
          )}
        </CoreDatePicker>
      </Frame>
      <BodySmall color="neutral-700">
        Warning: Once a badge has been{" "}
        {action == "award" ? "awarded" : "waived"}, its status cannot be
        changed.
      </BodySmall>
      <Inline distribution="between">
        <SecondaryButton text="Cancel" type="button" onClick={onCancel} />
        <PrimaryButton
          type="submit"
          text={capitalize(action)}
          onClick={handleSubmit}
          disabled={!date}
        />
      </Inline>
    </Stack>
  );
}

/**
 * @param {object} props
 * @param {string} props.badgeId
 * @param {"waived" | "awarded"} props.newStatus
 * @param {({success: boolean, data: any})=> void} [props.onClose]
 */
export function AwardModal({ badgeId, newStatus, onClose = noop }) {
  const closeModal = useCallback(() => onClose({ success: false }), [onClose]);
  const action = newStatus == "awarded" ? "award" : "waive";

  const $modal = React.useRef();
  useOnClickOutside($modal, closeModal);
  useOnEscapePress(closeModal);
  useLockBodyScroll();

  return (
    <Portal>
      <CoreBackdrop>
        <Center style={{ height: "100%" }}>
          <Frame
            p={12}
            bg="white"
            shadow="lg"
            className="max-w-3xl relative"
            innerRef={$modal}
          >
            <Inset variant="absolute" position="top right">
              <Touchable
                type="button"
                aria-label="Close modal"
                onClick={closeModal}
                p={2}
                className="focus:outline-none border-none"
              >
                <Text color="dark-700">
                  <Close className=" w-6 h-6" />
                </Text>
              </Touchable>
            </Inset>
            <SSRSuspense fallback={<AwardFormFallback />}>
              <AwardForm
                badgeId={badgeId}
                action={action}
                onSubmit={onClose}
                onCancel={closeModal}
              />
            </SSRSuspense>
          </Frame>
        </Center>
      </CoreBackdrop>
    </Portal>
  );
}
