import { useCollection } from "coreql";

function useProjects() {
  return useCollection("projects", {
    filters: {
      active: true,
    },
    included: ["responsible", "product"],
    fields: {
      projects: [
        "product",
        "responsible",
        "name",
        "discoveryStart",
        "developmentEnd",
        "billable",
        "projectStatus",
        "userId",
        "hasAllocations",
      ],
      products: ["name"],
      users: ["id"],
    },
  });
}

export default useProjects;
