/* eslint react/no-danger:0 */
import React, { useState } from "react";
import { api, useCollection } from "coreql";
import { Text, Frame, Stack, Color, Corners, Inline } from "@ableco/baseline";
import { AlertButton } from "../buttons/buttons";
import AutosaveTextInput, {
  LoadingStateIcon,
} from "../data-entry/autosave-text-input";
import Select from "../data-entry/select";
import { useCoreToast } from "../toast/toast";
import { uniqueId, last } from "lodash";
import { wrap } from "./utils";
import useCurrentUser from "../../hooks/use-current-user";
import { TextArea } from "../text-input";

export function useFeedbacksToSend(currentUserId, weeklyReportId) {
  const senderId = currentUserId;
  return useCollection(
    "feedback-messages",
    {
      fields: {
        feedbackMessages: ["id", "receiverId", "body", "updatedAt"],
      },
      filters: {
        weeklyReport: weeklyReportId,
        senderId: senderId,
        withNoRequest: true,
        draft: true,
        active: true,
      },
      sort: ["createdAt"],
    },
    "denormalized",
  );
}

function useUsers() {
  const { data, ...fetchData } = useCollection(
    "users",
    {
      filters: { active: true },
      fields: {
        users: ["id", "fullName"],
      },
      sort: ["preferredName"],
    },
    "denormalized",
  );
  return { data: wrap(data), ...fetchData };
}

function TeamMemberSelect(props) {
  const { data: users = [] } = useUsers();
  return (
    <Select
      options={users.map((user) => ({
        value: user.id.toString(),
        label: user.fullName,
      }))}
      testId="test-select-member"
      {...props}
    />
  );
}

function SendFeedbackForm({
  index,
  feedbackData,
  setFeedbacks,
  isLast,
  currentUserId,
  weeklyReportId,
  updateSavedAt,
  mutate,
}) {
  const [feedbackId, setFeedbackId] = useState(feedbackData.id);
  const [receiverId, setReceiverId] = useState(feedbackData.receiverId);
  const [body, setBody] = useState(feedbackData.body);
  const { successToast, errorToast } = useCoreToast();

  async function handleSelect(selectedId) {
    setReceiverId(selectedId);
    if (feedbackId) {
      await api.feedbackMessages.find(feedbackId).update({
        receiverId: selectedId,
      });
      mutate();
      updateSavedAt();
    }
  }

  function handleMessageChange(text) {
    setBody(text);
    if (isLast) {
      setFeedbacks((lastFeedbacks) => {
        const newFeedbacks = [...lastFeedbacks];
        newFeedbacks.push({
          id: "",
          tempId: uniqueId("new:"),
          receiverId: "",
          body: "",
        });
        return newFeedbacks;
      });
    }
  }

  async function handleMessageSave(feedbackMessageId, newFeedbackMessage) {
    const { data } = await api.feedbackMessages.find(feedbackMessageId).upsert(
      { body: newFeedbackMessage, source: "WEEKLY JOURNAL" },
      {
        receiverId,
        weeklyReportId,
        senderId: currentUserId,
      },
    );
    const newFeedbackMessageId = data.id;
    setFeedbackId(newFeedbackMessageId);
    mutate();
    updateSavedAt();
    return newFeedbackMessageId;
  }

  async function handleDeleteFeedback() {
    if (window.confirm("Are you sure you want to delete the feedback?")) {
      try {
        await api.feedbackMessages.find(feedbackId).destroy();
        mutate();
        updateSavedAt();
        setFeedbacks((lastFeedbacks) => {
          const newFeedbacks = [...lastFeedbacks];
          return [
            ...newFeedbacks.slice(0, index),
            ...newFeedbacks.slice(index + 1),
          ];
        });
        successToast("Your feedback message has been deleted!");
      } catch {
        errorToast(
          "We couldn't delete your feedback message. Contact the team.",
        );
      }
    }
  }

  return (
    <Stack className="mb-4" p={8} space={4} bg={Color.Neutral100}>
      <Text color={Color.Neutral800}>Select the feedback recipient</Text>
      <TeamMemberSelect value={receiverId.toString()} onChange={handleSelect} />
      <AutosaveTextInput
        resourceId={feedbackId}
        value={body}
        onChange={handleMessageChange}
        onSave={handleMessageSave}
        lastSavedAt={feedbackData.updatedAt}
        getSaveStatus={LoadingStateIcon}
      >
        {(text, autosaveProps, statusText) => (
          <Stack space={4}>
            <Stack className="relative">
              <TextArea
                value={text}
                className="pointer-events-auto"
                border={Color.Neutral400}
                placeholderColor={Color.Neutral500}
                corners={Corners.MediumRounded}
                type="multiline"
                autosize={88}
                p={[2, 4]}
                bg={Color.White}
                text={Color.Neutral700}
                placeholder="Please describe the situation (where/when), the observable behavior (don't assume or be subjective), and the impact (how it affected you or others)."
                disabled={!receiverId}
                {...autosaveProps}
              />
              <Frame className="absolute left-100 bottom-0 mb-2 ml-1">
                {statusText}
              </Frame>
            </Stack>
            <Inline distribution="end">
              <AlertButton
                disabled={!feedbackId}
                onClick={handleDeleteFeedback}
                text="Delete"
              />
            </Inline>
          </Stack>
        )}
      </AutosaveTextInput>
    </Stack>
  );
}

export function SendFeedback({ weeklyReportId, updateSavedAt }) {
  const currentUserId = useCurrentUser().data.id;
  const { mutate: feedbacksToSendMutate, data: feedbacksToSend } =
    useFeedbacksToSend(currentUserId, weeklyReportId);
  let feedbacksWithExtraForm = [...feedbacksToSend];
  if (last(feedbacksToSend)?.id !== "") {
    feedbacksWithExtraForm.push({
      id: "",
      tempId: uniqueId("new:"),
      receiverId: "",
      body: "",
    });
  }
  const [feedbacks, setFeedbacks] = useState(feedbacksWithExtraForm);

  return (
    <Frame p={[4, 0, 0, 0]}>
      <Text color={Color.Neutral800} weight="semibold">
        Send Feedback
      </Text>
      {feedbacks.map((feedbackData, index) => (
        <SendFeedbackForm
          index={index}
          key={feedbackData.tempId || feedbackData.id}
          feedbackData={feedbackData}
          weeklyReportId={weeklyReportId}
          currentUserId={currentUserId}
          mutate={feedbacksToSendMutate}
          updateSavedAt={updateSavedAt}
          setFeedbacks={setFeedbacks}
          isLast={index === feedbacks.length - 1}
        />
      ))}
    </Frame>
  );
}

export default SendFeedback;
