import React from "react";
import { MarkdownViewer } from "../markdown/markdown-viewer";
import { useCollection, useResource } from "coreql";
import TaskGroup from "./task-group";
import useCurrentUser from "../../hooks/use-current-user";
import SectionHeader from "./section-header";
import { Stack, Text, Flex } from "@ableco/baseline";
import { Main } from "../layout/layout";
import { PrimaryButton } from "../buttons/buttons";
import getOneOnOneHistoryPath from "../../utils/1-on-1/get-1-on-1-history-path";
import { format, parseISO } from "date-fns";
import { BodySmall } from "../design-system/typography-components";
import BackButton from "../layout/back-button";
import FullPageHeader from "../layout/full-page-header";

function OneOnOneGroup() {
  const urlParams = window.location.pathname.split("/").splice(2);

  const { data: pastOneOnOne } = useResource(
    "past-one-on-ones",
    urlParams[0],
    {
      fields: {
        pastOneOnOnes: ["endedBy", "endedOn", "participants"],
        users: ["preferredName", "avatarUrl"],
      },
      included: ["endedBy", "participants"],
    },
    "denormalized",
  );

  const { data: currentUser } = useCurrentUser();

  const requesterId = currentUser.id;

  const requestedUser = pastOneOnOne.participants.find(
    (participant) => participant.id !== currentUser.id,
  );

  const requestedId = requestedUser.id;

  const oneOnOneScope = [requesterId, requestedId]
    .sort((a, b) => a - b)
    .join("-");

  const historyTimestamp = Math.round(
    new Date(pastOneOnOne.endedOn).getTime() / 1000,
  );

  const { data: actionItems } = useCollection(
    "one-on-one-items",
    {
      filters: {
        oneOnOneScope: oneOnOneScope,
        kind: "action_item",
        endedOnTimestamp: historyTimestamp,
      },
      included: ["assignee", "requester"],
      sort: ["position"],
    },
    "denormalized",
  );

  const { data: talkingPoints } = useCollection(
    "one-on-one-items",
    {
      filters: {
        endedOnTimestamp: historyTimestamp,
        oneOnOneScope: oneOnOneScope,
        kind: "talking_point",
      },
      sort: ["position"],
    },
    "denormalized",
  );

  const { data: notes } = useCollection(
    "one-on-one-items",
    {
      filters: {
        endedOnTimestamp: historyTimestamp,
        oneOnOneScope: oneOnOneScope,
        kind: "note",
      },
    },
    "denormalized",
  );

  const { data: privateNotes } = useCollection(
    "one-on-one-items",
    {
      filters: {
        endedOnTimestamp: historyTimestamp,
        requester: requesterId,
        requested: requestedId,
        kind: "private_note",
      },
    },
    "denormalized",
  );

  const endedBy = pastOneOnOne.endedBy.preferredName;
  const endedOn = pastOneOnOne.endedOn;
  const dateLong = format(parseISO(endedOn), "MMMM dd, yyyy");
  const dateShort = format(parseISO(endedOn), "MMM dd, yyyy");

  function noteAuthor(note) {
    if (Number.parseInt(note.requesterId) !== Number.parseInt(currentUser.id))
      return `${requestedUser.preferredName}'s Notes:`;
    return "Your Notes:";
  }

  return (
    <Main>
      <Stack
        alignment="start"
        bg="white"
        corners="small"
        p={[4, 4, 5, 4]}
        className="w-full mb-6"
      >
        <BackButton
          text={"1-on-1 History"}
          href={getOneOnOneHistoryPath(currentUser.id, requestedId)}
          p={[1, 0, 2, 4]}
        />
        <FullPageHeader
          user={requestedUser}
          title={`1-on-1 with ${requestedUser.preferredName}`}
          subtitle={dateLong}
        />
      </Stack>
      <Stack p={[0, 10]} className="max-w-5xl">
        <Stack space={6}>
          <Stack space={4}>
            <SectionHeader title={"Talking Points"} />
            {talkingPoints.length > 0 ? (
              <TaskGroup
                disabled
                items={talkingPoints}
                currentUser={currentUser}
                requesterId={requesterId}
                requestedUser={requestedUser}
                label="talking point"
                kind="talking_point"
              />
            ) : (
              <Flex
                border="neutral-300"
                alignment="center"
                corners="medium"
                className="justify-center"
                style={{ height: 126 }}
              >
                <BodySmall color="neutral-600">
                  There are no talking points for this 1-on-1
                </BodySmall>
              </Flex>
            )}
          </Stack>
          <Stack space={4}>
            <SectionHeader title={"Action Items"} />
            {actionItems.length > 0 ? (
              <TaskGroup
                disabled
                items={actionItems}
                currentUser={currentUser}
                requesterId={requesterId}
                requestedUser={requestedUser}
                label="action item"
                kind="action_item"
              />
            ) : (
              <Flex
                border="neutral-300"
                alignment="center"
                corners="medium"
                className="justify-center"
                style={{ height: 126 }}
              >
                <BodySmall color="neutral-600">
                  There are no action items for this 1-on-1
                </BodySmall>
              </Flex>
            )}
          </Stack>
          {(notes.length > 0 || privateNotes.length > 0) && (
            <Stack space={6}>
              <SectionHeader title={"Notes"} />
              <Stack space={3} className="ml-8">
                {notes.length > 0 &&
                  notes
                    // eslint-disable-next-line no-unused-vars
                    .sort((a, b) =>
                      Number.parseInt(a.requesterId) !==
                      Number.parseInt(currentUser.id)
                        ? -1
                        : 1,
                    )
                    .map((note) => (
                      <Stack
                        key={note.id}
                        className="border rounded p-4 border-neutral-300"
                        space={1}
                      >
                        <Text color="neutral-700" weight="bold">
                          {noteAuthor(note)}
                        </Text>
                        <MarkdownViewer
                          className="text-neutral-600"
                          value={note.text}
                        />
                      </Stack>
                    ))}

                {privateNotes.length > 0 && (
                  <Stack
                    key={privateNotes[0].id}
                    className="border rounded p-4 border-neutral-300"
                    space={1}
                  >
                    <Text color="neutral-700" weight="bold">
                      Your Private Notes:
                    </Text>
                    <MarkdownViewer
                      className="text-neutral-600"
                      value={privateNotes[0].text}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
        <Flex
          className="w-full mt-5"
          p={[3, 0]}
          direction="horizontal"
          distribution="between"
        >
          <BodySmall color="neutral-600">
            Ended by {endedBy} on {dateShort}
          </BodySmall>
          <PrimaryButton disabled={true} text="Ended" />
        </Flex>
      </Stack>
    </Main>
  );
}

export default OneOnOneGroup;
