import * as React from "react";
import useSelect from "use-select";
import {
  Frame,
  Inline,
  Stack,
  TextInput,
  Color,
  Corners,
  Text,
  Touchable,
  TextWeight,
  Shadow,
} from "@ableco/baseline";
import { Close, CaretDownOutline } from "@baseline/icons";
import { useId } from "@reach/auto-id";
import cn from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import removeAccents from "../../utils/remove_accents";

function getOptionBG({ option, selectedOption, index, highlightedIndex }) {
  if (option === selectedOption) return Color.Neutral200;
  if (index === highlightedIndex) return Color.PrimaryLighter;
  return [Color.White, Color.PrimaryLighter];
}

function getOptionWeight({ option, selectedOption }) {
  if (option === selectedOption) return TextWeight.SemiBold;
  return TextWeight.Normal;
}

function filterByLabel(option, searchValue) {
  return removeAccents(option.label?.toLowerCase()).includes(
    removeAccents(searchValue.toString().toLowerCase()),
  );
}

function filterByValue(option, searchValue) {
  return option.value
    ?.toLowerCase()
    .includes(searchValue.toString().toLowerCase());
}

function sortByLabel(option, searchValue) {
  return option.label
    ?.toLowerCase()
    .indexOf(searchValue.toString().toLowerCase());
}

function defaultFilterFn(options, searchValue) {
  return options
    .filter(
      (option) =>
        filterByLabel(option, searchValue) ||
        filterByValue(option, searchValue),
    )
    .sort(sortByLabel);
}

function getHighlightedLabel(value, label) {
  if (value === "") return [label, "", ""];
  const startPosition = removeAccents(label.toLowerCase()).indexOf(
    removeAccents(value.toLowerCase()),
  );
  const endPosition = startPosition + value.length;
  const before = label.slice(0, startPosition);
  const mark = label.slice(startPosition, endPosition);
  const after = label.slice(endPosition);
  return [before, mark, after];
}

function RenderIcon({ icon, className }) {
  if (icon) {
    return <Frame className={cn(className, "mr-2")}>{icon}</Frame>;
  }
  return null;
}

export default function Select({
  value,
  options,
  onChange,
  placeholder,
  multi = false,
  testId = "test:select",
  disabled,
  className,
  hasIcons = false,
  ...props
}) {
  const listId = useId(props.id ?? "");
  const selectedId = useId(props.id ?? "");
  const $optionsRef = React.useRef();

  const {
    visibleOptions,
    selectedOption,
    getInputProps,
    getOptionProps,
    isOpen,
    setOpen,
    selectIndex,
    highlightedIndex,
    searchValue,
  } = useSelect({
    multi,
    options,
    value,
    onChange,
    optionsRef: $optionsRef,
    filterFn: defaultFilterFn,
  });

  const inputProps = getInputProps();
  if (hasIcons) {
    inputProps["icon"] = options.find((option) => option.value === value)?.icon;
  }
  inputProps.innerRef = inputProps.ref;
  delete inputProps.ref;

  return (
    <Frame
      bg={disabled ? Color.Neutral200 : Color.White}
      border={Color.Neutral400}
      corners={Corners.MediumRounded}
      p={[1, 2]}
      className={cn(
        disabled && "cursor-not-allowed",
        "outline-none shadow-none focus-within:shadow-outline relative focus-within:border-primary-base transition-all duration-300 ease-in-out",
        className,
      )}
      role="combobox"
      aria-expanded={isOpen.toString()}
      aria-owns={listId}
      aria-haspopup="listbox"
    >
      {/* This select is here only for testing purposes */}
      <select
        className="hidden"
        data-testid={testId}
        aria-hidden
        multiple={multi}
        onBlur={(event) =>
          selectIndex(
            options.findIndex((option) => option.value === event.target.value),
          )
        }
        onChange={(event) =>
          selectIndex(
            options.findIndex((option) => option.value === event.target.value),
          )
        }
      >
        <option>{placeholder}</option>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      {multi ? (
        <Inline {...inputProps} wrap className="w-full">
          {selectedOption.map((option) => (
            <Inline
              bg={Color.Neutral200}
              corners={Corners.MediumRounded}
              p={[1, 2]}
              key={option.value}
              space={1}
              className="mr-1 mb-1"
            >
              <Text
                className="select-none cursor-default"
                color={Color.Neutral700}
              >
                {option.label}
              </Text>
              <Touchable
                data-testid="touchable"
                onClick={() =>
                  onChange(value.filter((d) => d !== option.value))
                }
              >
                <Close className="w-4 h-4" />
              </Touchable>
            </Inline>
          ))}
        </Inline>
      ) : null}
      <Inline>
        {!isOpen && <RenderIcon icon={inputProps?.icon} />}
        <TextInput
          {...inputProps}
          text={disabled ? Color.Neutral500 : Color.Neutral700}
          placeholderColor={Color.Neutral600}
          placeholder={selectedOption.length > 0 ? null : placeholder}
          className={cn(
            disabled && "cursor-not-allowed",
            "w-full bg-transparent cursor-pointer-events-auto outline-none focus:outline-none",
          )}
          role="textbox"
          aria-controls={listId}
          aria-activedescendant={selectedId}
          autoComplete="off"
          disabled={disabled}
          p={0}
          shadow={Shadow.None}
          {...props}
        />
        {!multi && (
          <CaretDownOutline
            className="w-4 h-4 absolute text-neutral-600"
            style={{ right: "0.5rem", zIndex: "0" }}
            onClick={() => setOpen(!isOpen)}
          />
        )}
        {isOpen && <RenderIcon icon={inputProps?.icon} className="opacity-0" />}
      </Inline>
      <AnimatePresence>
        {isOpen && !disabled && (
          <Stack
            as={motion.div}
            key="modal"
            initial={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            innerRef={$optionsRef}
            bg={Color.White}
            style={{ maxHeight: 250 }}
            corners={Corners.MediumRounded}
            shadow={Shadow.Medium}
            className="overflow-y-auto z-10 absolute top-100 mt-1 left-0 right-0 w-full"
            role="listbox"
            id={listId}
          >
            {visibleOptions.length === 0 ? (
              <Frame
                bg={[Color.White, Color.PrimaryLighter]}
                className="cursor-pointer text-left"
                role="option"
              >
                <Text
                  color={Color.Neutral700}
                  className="block w-full py-2 px-4 select-none"
                >
                  No options were found...
                </Text>
              </Frame>
            ) : null}
            {visibleOptions.map((option, index) => {
              const [before, mark, after] = getHighlightedLabel(
                searchValue,
                option.label,
              );
              return (
                <Frame
                  {...getOptionProps({
                    index,
                    option,
                  })}
                  bg={getOptionBG({
                    option,
                    selectedOption,
                    index,
                    highlightedIndex,
                  })}
                  className="cursor-pointer text-left transition-all duration-300 ease-in-out"
                  role="option"
                  aria-label={`Select ${option.label}`}
                  aria-selected={option === selectedOption ? "true" : "false"}
                  id={option === selectedOption ? selectedId : undefined}
                  data-testid="touchable-visible-option"
                >
                  <Inline className="w-full py-2 px-4">
                    <RenderIcon icon={option.icon} />
                    <Text
                      color={Color.Neutral700}
                      weight={getOptionWeight({
                        option,
                        selectedOption,
                        index,
                        highlightedIndex,
                      })}
                      className="block w-full select-none"
                    >
                      {before}
                      <Text
                        as="strong"
                        color="primary-light"
                        data-testid="strong-visible-option"
                      >
                        {mark}
                      </Text>
                      {after}
                    </Text>
                  </Inline>
                </Frame>
              );
            })}
          </Stack>
        )}
      </AnimatePresence>
    </Frame>
  );
}
