import React from "react";
import { Check, CheckCircleOutline, CircleOutline } from "@baseline/icons";

import { useLocation } from "react-router-dom";

import Tag from "../tag";
import CoreLink from "../core-link";

const userBadgeStatus = {
  in_progress: {
    tagType: "opportunity",
    LeftIcon: CircleOutline,
  },
  waived: {
    tagType: "default",
    LeftIcon: Check,
  },
  awarded: {
    tagType: "success",
    LeftIcon: CheckCircleOutline,
  },
  pending: {
    tagType: "default",
    LeftIcon: null,
  },
};

export default function BadgeTag({ badge, userId }) {
  const { pathname } = useLocation();

  return (
    <CoreLink
      href={{
        pathname: `/users/${userId}/badges/${badge.id}`,
        state: { title: "Capabilities", href: pathname },
      }}
      className="mr-2 mb-2 hover:no-underline"
      data-testid={`badgeTagLink-${badge.id}`}
    >
      <Tag
        text={badge.name}
        type={
          badge.userBadge
            ? userBadgeStatus[badge.userBadge.status].tagType
            : userBadgeStatus["pending"].tagType
        }
        LeftIcon={
          badge.userBadge
            ? userBadgeStatus[badge.userBadge.status].LeftIcon
            : userBadgeStatus["pending"].LeftIcon
        }
        classNameText="break-all cursor-pointer"
      />
    </CoreLink>
  );
}
