import { useCollection } from "coreql";

function useProjectRoles() {
  return useCollection(
    "project-roles",
    {
      fields: {
        projectRoles: ["name"],
      },
    },
    "denormalized",
  );
}

export default useProjectRoles;
