import {
  addMonths,
  endOfQuarter,
  format,
  parseISO,
  startOfQuarter,
  subDays,
} from "date-fns";

const CORE_QUARTERS_START = [
  1, // Feb
  4, // May
  7, // Aug
  10, // Nov
];

const Q4_2020_START = new Date(2020, 9, 1);
const Q4_2020_END = new Date(2021, 1, 0);

export function quarterFirstDate(date) {
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();
  if (currentMonth === 0) return new Date(currentYear - 1, 10);

  let quarterStart = CORE_QUARTERS_START[0];

  for (const quarter of CORE_QUARTERS_START)
    if (currentMonth >= quarter) quarterStart = quarter;

  return new Date(currentYear, quarterStart);
}

export function quarterLastDate(date) {
  const nextQuarterStart = quarterFirstDate(addMonths(date, 3));
  return subDays(nextQuarterStart, 1);
}

export function getQuarterRange(date) {
  switch (true) {
    /* legacy quarters */
    case date < Q4_2020_START:
      return {
        start: startOfQuarter(new Date(date)),
        end: endOfQuarter(new Date(date)),
      };
    /* first core quarter */
    case date <= Q4_2020_END:
      return {
        start: Q4_2020_START,
        end: Q4_2020_END,
      };
    /* core quarters */
    default:
      return {
        start: quarterFirstDate(date),
        end: quarterLastDate(date),
      };
  }
}

export function getCurrentQuarter() {
  const firstDate = quarterFirstDate(new Date());
  return format(firstDate, "yyyy-MM-dd");
}

function getQuarterNumber(date) {
  return CORE_QUARTERS_START.indexOf(quarterFirstDate(date).getMonth()) + 1;
}

export function formatCoreQuarter(date) {
  switch (true) {
    /* legacy */
    case date < Q4_2020_START:
      return format(date, "'Q'Q yyyy");
    /* first core quarter */
    case date <= Q4_2020_END:
      return `Q4 2020`;
    /* new quarters */
    default:
      const quarterStart = quarterFirstDate(date);
      return format(quarterStart, `'Q'${getQuarterNumber(quarterStart)} yyyy`);
  }
}

export function getQuarterTitle(dateStr) {
  const date = parseISO(dateStr);

  return formatCoreQuarter(date);
}
