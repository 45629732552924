import React from "react";

export default function useToggle(defaultValue = false) {
  const [isActive, setIsActive] = React.useState(defaultValue);
  const toggle = React.useCallback(
    () => setIsActive((current) => !current),
    [setIsActive],
  );
  return [isActive, toggle];
}
