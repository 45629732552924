import { api } from "coreql";

export function filterAnswersByCriteria(answers, key, criteria) {
  return answers.filter((answer) => answer[key] == criteria);
}

export function canAddOneOnOne(userId, managerId) {
  return userId == managerId;
}

export function getPrivateCommentReceiverName(weeklyReportOwner, currentUser) {
  if (currentUser.id == weeklyReportOwner?.id) {
    return weeklyReportOwner.manager?.preferredName || "manager";
  }
  if (currentUser.id == weeklyReportOwner.manager?.id) {
    return weeklyReportOwner.preferredName;
  }
  return null;
}

export async function addOneOnOneItem(
  resource,
  userId,
  weeklyUserId,
  setText = null,
) {
  await api.oneOnOneItems.create(
    {
      relatedResourceId: resource.id,
      relatedResourceType: "WeeklyReportAnswer",
      completed: false,
      text: `${setText || resource.data.text}`,
      kind: "talking_point",
      requestedId: weeklyUserId,
      requesterId: userId,
    },
    [
      { resource: "user", name: "requester" },
      { resource: "user", name: "requested" },
    ],
  );
}
