import React, { useContext } from "react";
import {
  addWeeks,
  isEqual,
  areIntervalsOverlapping,
  compareAsc,
  setDay,
  isSameDay,
  startOfDay,
  isWithinInterval,
  max,
  isBefore,
  addDays,
  isAfter,
  parseISO,
  isValid,
} from "date-fns";
import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";

import { sortCriteriaGenerator } from "../../utils/sort-criteria";
import TimelineContext from "./timeline-context";
import GenericTimeline from "./generic-timeline";
import { getRoleAcronym } from "../../utils/string-initials";
import { api } from "coreql";

const TOTAL_HOURS = 40;

export function buildAssignmentsByRow(assignments) {
  const assignmentsByRow = assignments.reduce((acc, assignment) => {
    const key = assignment.attributes.rowId;
    if (!acc[key]) acc[key] = [];
    acc[key].push(assignment);
    return acc;
  }, {});

  return mapValues(assignmentsByRow, (assignments) =>
    assignments.sort((a, b) =>
      compareAsc(
        parseISO(a.attributes.periodStart),
        parseISO(b.attributes.periodStart),
      ),
    ),
  );
}

export function getProjectStart(project, projectsProductAssignments) {
  if (project.attributes.discoveryStart) {
    return parseISO(project.attributes.discoveryStart);
  }

  const assignmentsStartDates = projectsProductAssignments
    .map((assignment) => parseISO(assignment.attributes.periodStart))
    .sort(compareAsc);
  return assignmentsStartDates[0];
}

export function getProjectEnd(project, projectsProductAssignments) {
  if (project.attributes.developmentEnd) {
    return parseISO(project.attributes.developmentEnd);
  }

  const assignmentsStartDates = projectsProductAssignments
    .map((assignment) => parseISO(assignment.attributes.periodStart))
    .sort(compareAsc);
  return assignmentsStartDates[assignmentsStartDates.length - 1];
}

function sortAssignments(data) {
  return data.sort(
    sortCriteriaGenerator(
      ({ deparment, projectRole, userName }) =>
        `${deparment || ""} ${projectRole || ""} ${userName || ""}`,
    ),
  );
}

function sortUsersWithAssignments(usersWithAssignments) {
  return [
    ...sortAssignments(usersWithAssignments.filter((user) => user.userName)),
    ...sortAssignments(usersWithAssignments.filter((user) => !user.userName)),
  ];
}

function buildUsersWithAssignments(assignmentsByRow, data, projectRolesById) {
  const usersWithAssignments = Object.entries(assignmentsByRow).map(
    ([rowId, [firstAssignment]]) => {
      const { assigneeId, projectId, projectRoleId } =
        firstAssignment.attributes;
      const hasUser =
        data.users[assigneeId] &&
        firstAssignment.attributes.assigneeType === "User";

      const userName = hasUser
        ? data.users[assigneeId].attributes.fullName
        : undefined;

      const deparment = hasUser
        ? data.users[assigneeId].attributes.department
        : undefined;

      const projectRole = getRoleAcronym(projectRolesById[projectRoleId].name);

      let roleExtensions = data.roleExtensions?.[assigneeId]?.[projectId] ?? [];
      roleExtensions = roleExtensions.map((roleExtension) =>
        getRoleAcronym(roleExtension.projectRole.name),
      );

      return {
        rowId,
        userName,
        projectRole,
        deparment,
        assignments: assignmentsByRow[rowId],
        roleExtensions,
      };
    },
  );

  return sortUsersWithAssignments(usersWithAssignments);
}

async function handleExtend(
  project,
  rowsInTimeline,
  revalidateProductsAssignments,
  revalidateProjects,
) {
  const assignments = Object.values(rowsInTimeline);

  const lastAssignmentDate = max(
    assignments.map((rowAssignments) =>
      max(rowAssignments.map((el) => parseISO(el.attributes.periodStart))),
    ),
  );

  const assignmentsToExtend = assignments.reduce((accum, rowAssignments) => {
    const lastRowAssignment = rowAssignments.find((el) =>
      isSameDay(parseISO(el.attributes.periodStart), lastAssignmentDate),
    );
    if (lastRowAssignment) accum.push(lastRowAssignment);
    return accum;
  }, []);

  const nextAssignmentDate = addWeeks(lastAssignmentDate, 1);

  const promises = [];
  if (
    isBefore(parseISO(project.attributes.developmentEnd), nextAssignmentDate)
  ) {
    promises.push(
      api.projects
        .find(project.id)
        .update({ developmentEnd: nextAssignmentDate }),
    );
  }

  assignmentsToExtend.reduce((accum, assignment) => {
    accum.push(
      api.productAssignments.create({
        periodStart: nextAssignmentDate,
        timeAllocation: (assignment.attributes.hours * 100) / TOTAL_HOURS,
        projectRoleId: assignment.attributes.projectRoleId,
        assigneeId: assignment.attributes.assigneeId,
        assigneeType: assignment.attributes.assigneeType,
        projectId: assignment.attributes.projectId,
        productId: assignment.attributes.productId,
        rowId: assignment.attributes.rowId,
      }),
    );
    return accum;
  }, promises);
  await Promise.all(promises);
  await revalidateProductsAssignments();
  await revalidateProjects();
}

function ProjectTimeline({
  project,
  type,
  product,
  responsible,
  projectSeats,
  projectRolesById,
}) {
  const {
    data,
    weeks,
    editPermission,
    revalidateProductsAssignments,
    revalidateProjects,
  } = useContext(TimelineContext);
  const timelineStart = weeks[0];
  const timelineEnd = addDays(weeks[weeks.length - 1], 6);

  const productAssignmentsForProject = project.relationships.projectAssignments
    ? project.relationships.projectAssignments.data
        .map((assignment) => data.productAssignments[assignment.id])
        .filter((assignment) => assignment)
    : [];

  const projectStart = getProjectStart(project, productAssignmentsForProject);
  const projectEnd = getProjectEnd(project, productAssignmentsForProject);
  const hasValidProjectDates = isValid(projectStart) && isValid(projectEnd);

  const firstMondayInProject = startOfDay(setDay(new Date(projectStart), 1));
  const lastMondayInProject = startOfDay(setDay(new Date(projectEnd), 1));

  const startDateIndex = weeks.findIndex((monday) =>
    isSameDay(monday, firstMondayInProject),
  );

  const endDateIndex = weeks.findIndex((monday) =>
    isSameDay(monday, lastMondayInProject),
  );

  const assignmentsByRow = buildAssignmentsByRow(productAssignmentsForProject);

  const rowsInTimeline = pickBy(assignmentsByRow, (assignments) =>
    assignments.some((assignment) =>
      isWithinInterval(parseISO(assignment.attributes.periodStart), {
        start: timelineStart,
        end: timelineEnd,
      }),
    ),
  );

  const usersWithAssignments = buildUsersWithAssignments(
    rowsInTimeline,
    data,
    projectRolesById,
  );

  const hasAllocationInTimelineView =
    hasValidProjectDates &&
    (areIntervalsOverlapping(
      { start: timelineStart, end: timelineEnd },
      { start: firstMondayInProject, end: lastMondayInProject },
    ) ||
      isEqual(timelineStart, lastMondayInProject));

  const members = usersWithAssignments.map(
    ({ rowId, userName, projectRole, roleExtensions }) => ({
      id: rowId,
      isReal: !!userName,
      userName,
      projectRole,
      roleExtensions,
    }),
  );

  const assignmentGroups = usersWithAssignments.map(
    ({ rowId, assignments }) => ({
      assignments,
      rowId,
    }),
  );

  function isExtendable() {
    const assignments = Object.values(rowsInTimeline);
    const lastWeekInTimeFrame = weeks[weeks.length - 1];
    for (const rowAssignments of assignments) {
      const rowHasAssignmentOnOrAfterTimeframeEnd = rowAssignments.some(
        (assignment) => {
          const periodStart = parseISO(assignment.attributes.periodStart);
          return (
            isSameDay(periodStart, lastWeekInTimeFrame) ||
            isAfter(periodStart, lastWeekInTimeFrame)
          );
        },
      );
      if (rowHasAssignmentOnOrAfterTimeframeEnd) {
        return false;
      }
    }
    return true;
  }

  return (
    <GenericTimeline
      assignmentGroups={assignmentGroups}
      members={members}
      project={project}
      product={product}
      responsible={responsible}
      type={type}
      isBench={false}
      hasAllocationInTimelineView={hasAllocationInTimelineView}
      startDateIndex={startDateIndex}
      endDateIndex={endDateIndex}
      editPermission={editPermission}
      projectStart={firstMondayInProject}
      projectEnd={lastMondayInProject}
      projectSeats={projectSeats}
      onExtend={
        isExtendable() &&
        (() =>
          handleExtend(
            project,
            rowsInTimeline,
            revalidateProductsAssignments,
            revalidateProjects,
          ))
      }
    />
  );
}

export default ProjectTimeline;
