import React, { useMemo } from "react";
import { Color, Frame, Inline, Stack } from "@ableco/baseline";
import { Heading, BodyBaseline } from "./design-system/typography-components";
import { Redirect, useParams } from "react-router";
import { NavItem as Tab } from "./tab";
import { currentPathMatches } from "./layout/user-layout";
import { useResource, useCollection, SSRSuspense } from "coreql";
import { ResponsibilityAccordion } from "./users/responsibilities";
import { wrap } from "./weekly-report/utils";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import BackButton from "./layout/back-button";
import PageLayout from "./layout/page-layout";
import CapabilitiesTable from "./capabilities-table/capabilities-table";
import { buildTableData } from "./capabilities-table/capabilities-table-utils";
import useCurrentUser from "../hooks/use-current-user";

function getNavItems({ baseUrl }) {
  const items = [];

  items.push(
    {
      href: `${baseUrl}/responsibilities`,
      label: "Responsibilities",
      prefetch: false,
      isActive: (location) =>
        currentPathMatches(location, {
          path: `${baseUrl}/responsibilities`,
        }),
    },
    {
      href: `${baseUrl}/capabilities`,
      label: "Capabilities",
      prefetch: false,
      isActive: (location) =>
        currentPathMatches(location, {
          path: `${baseUrl}/capabilities`,
        }),
    },
  );

  return items;
}

function RoleCapabilities({ roleId, isManagerRole, role, resourceType }) {
  let requestFilters = {};

  if (isManagerRole) {
    requestFilters =
      role.title == "Software Engineering Manager"
        ? { kind: "role", roles: roleId }
        : { roles: [roleId, null] }; // ccapabilities without role are the "manager" capabilities. We want to include the manager capabilities when the user has a manager role
  } else {
    requestFilters = {
      roles: roleId,
    };
  }

  const { data: responseCapabilities } = useCollection("capabilities", {
    fields: {
      roles: ["capabilities"],
      capabilities: ["name", "skill", "observableBehaviours"],
      observableBehaviours: ["level", "description", "badges"],
    },
    filters: {
      ...requestFilters,
      active: true,
    },
    included: [
      "skill",
      "observableBehaviours",
      "observableBehaviours.level",
      "observableBehaviours.badges",
    ],
  });

  const { data: currentUser } = useCurrentUser({
    included: ["userBadges", "metObservableBehaviours"],
  });

  const renderMetRequirement =
    resourceType === "project-roles"
      ? wrap(currentUser.projectRoleResponsibilities)
          .map((projectRole) => projectRole.role)
          .includes(Number.parseInt(roleId))
      : currentUser.role.id == roleId;

  const userBadges = wrap(currentUser.userBadges);
  const metObservableBehaviours = wrap(currentUser.metObservableBehaviours);

  const { skills, levels } = useMemo(
    () => buildTableData(responseCapabilities),
    [responseCapabilities],
  );

  return (
    <Stack className="-mx-8">
      <Heading as="h2">Capabilities</Heading>
      {skills.length > 0 ? (
        <Frame p={[2, 0, 0, 0]}>
          <CapabilitiesTable
            skills={skills}
            levels={levels}
            fromRoles={true}
            role={role}
            userBadges={userBadges}
            metObservableBehaviours={metObservableBehaviours}
            userId={currentUser.id}
            renderMetRequirement={renderMetRequirement}
          />
        </Frame>
      ) : (
        <Frame p={[8, 0]}>
          <BodyBaseline color={Color.Neutral700}>
            There are no capabilities created for this role
          </BodyBaseline>
        </Frame>
      )}
    </Stack>
  );
}

function RoleResponsibilities({ roleId, type }) {
  const { data: responseRole } = useResource(
    type,
    roleId,
    {
      fields: {
        roles: ["responsibilities"],
        expectations: ["id", "title", "description", "rank"],
      },
      included: ["responsibilities", "responsibilities.expectations"],
    },
    "denormalized",
  );

  const responsibilities = wrap(responseRole.responsibilities);
  return (
    <Stack space={8} className="-mx-8">
      <Heading as="h2">Responsibilities</Heading>
      <Frame>
        {responsibilities.length > 0 ? (
          <ResponsibilityAccordion responsibilities={responsibilities} />
        ) : (
          <BodyBaseline color={Color.Neutral700}>
            There are no responsibilities created for this role
          </BodyBaseline>
        )}
      </Frame>
    </Stack>
  );
}

export default function Role() {
  let { id, type } = useParams();
  const resourceType = type === "project" ? "project-roles" : "roles";
  const numberRegex = /\d+/;
  id = id.match(numberRegex)[0];
  const match = useRouteMatch();

  const { data: responseRole } = useResource(
    resourceType,
    id,
    {},
    "denormalized",
  );

  const navItems = getNavItems({ baseUrl: match.url });

  const backButton = <BackButton text={"Roles at Able"} href="/roles" />;

  const subTitle = type === "department" && (
    <Inline space={5}>
      <Frame>{`Department: ${responseRole.departmentTitle}`}</Frame>
      <Frame>{`Discipline: ${responseRole.disciplineTitle}`}</Frame>
    </Inline>
  );

  return (
    <PageLayout
      title={responseRole.title || responseRole.name}
      backButton={backButton}
      subTitle={subTitle}
    >
      <Inline
        as="nav"
        alignment="start"
        className="border-b border-neutral-300 -mx-8"
        p={[0, 0, 0, 8]}
      >
        {navItems.map((item) => (
          <Tab key={item.href} {...item} />
        ))}
      </Inline>
      <Frame p={[0, 8]}>
        <SSRSuspense>
          <Switch>
            <Route path={`${match.url}/responsibilities`}>
              <RoleResponsibilities roleId={id} type={resourceType} />
            </Route>
            <Route path={`${match.url}/capabilities`}>
              {resourceType === "roles" ? (
                <RoleCapabilities
                  roleId={id}
                  isManagerRole={responseRole.kind === "manager"}
                  role={responseRole}
                  resourceType={resourceType}
                />
              ) : (
                <Frame>
                  <BodyBaseline color={Color.Neutral700}>
                    There are no capabilities for this project role
                  </BodyBaseline>
                </Frame>
              )}
            </Route>
            <Route>
              <Redirect to={`${match.url}/responsibilities`} />
            </Route>
          </Switch>
        </SSRSuspense>
      </Frame>
    </PageLayout>
  );
}
