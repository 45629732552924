import { buildURL } from "coreql";
import useSWR from "swr";
import { network } from "../../../../services/network";

export default function useProjectAllocations(projectId) {
  const url = buildURL({
    resource: "project-allocations",
    filters: { projectId: projectId },
    included: ["projectRole", "projectRoles"],
    fields: {
      "project-roles": ["name"],
      "project-allocations": [
        "assigneeId",
        "projectRoleIds",
        "assigneeType",
        "periodStart",
        "hours",
      ],
    },
  });

  return useSWR(url, network, {
    suspense: true,
  });
}
