import React from "react";
import {
  Frame,
  Inline,
  Inset,
  Modal,
  Portal,
  Text,
  Touchable,
  useLockBodyScroll,
  useOnClickOutside,
  useOnEscapePress,
} from "@ableco/baseline";
import { Close } from "@baseline/icons";
import { motion } from "framer-motion";
import { CoreBackdrop } from "./backdrop/backdrop";

export default function Drawer(props) {
  const $modal = React.useRef();
  useOnClickOutside($modal, props.onClose);
  useOnEscapePress(props.onClose);
  useLockBodyScroll();

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Portal>
      <CoreBackdrop>
        <Inset
          as={motion.div}
          initial={{ x: "100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ type: "tween" }}
          variant="absolute"
          position="right full"
        >
          <Modal
            as="aside"
            p={0}
            innerRef={$modal}
            className="h-screen"
            style={{ width: "480px" }}
          >
            <Inset
              as="header"
              variant="fixed"
              position="top right"
              p={[6, 4, 4, 4]}
              className="border-b border-neutral-300 bg-primary-base"
              style={{ width: "480px", zIndex: 10 }}
            >
              <Inset variant="absolute" position="top right">
                <Touchable
                  onClick={props.onClose}
                  p={2}
                  className="border-0 focus:outline-none"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <Text color={isHovered ? "white" : "primary-lighter"}>
                    <Close className="w-6 h-6" />
                  </Text>
                  <Text srOnly>Close drawer</Text>
                </Touchable>
              </Inset>
              <Inline as="h1" space={2}>
                {props.icon}
                <Text size="2xl" color="white" leading="snug" weight="semibold">
                  {props.title}
                </Text>
              </Inline>
              <Frame p={[1, 0, 0, 8]}>
                <Text as="h2" color="white" leading="snug">
                  {props.subtitle}
                </Text>
              </Frame>
            </Inset>

            <Frame as="section" p={[32, 6, 16, 6]}>
              {props.children}
            </Frame>
          </Modal>
        </Inset>
      </CoreBackdrop>
    </Portal>
  );
}
