import { Close } from "@baseline/icons";
import { Item, Link } from "../dropdown";
import React from "react";
import { Frame, Inline, Text } from "@ableco/baseline";
import { AvatarXS } from "../design-system/avatar";

function UserListItem({
  userData,
  handleAssignActionItem,
  isAssigned = false,
  isCurrentUser = false,
  isLink = true,
}) {
  return (
    <Item style={{ background: isAssigned && "rgba(0, 0, 0, 0.04)" }}>
      <Link
        onClick={() => handleAssignActionItem(userData)}
        style={{ textDecoration: isLink ? "underline" : "none" }}
      >
        <Frame className="flex justify-between items-center flex-row">
          <Inline space={2}>
            <AvatarXS
              url={userData.avatarUrl}
              name={userData.preferredName}
              userId={`menu:${userData.preferredName}`}
            />
            <Text>{isCurrentUser ? "You" : userData.preferredName}</Text>
          </Inline>
          {isAssigned && (
            <Close className="w-3 h-3" testid="unassign-user-icon" />
          )}
        </Frame>
      </Link>
    </Item>
  );
}
export default UserListItem;
