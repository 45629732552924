// Modules
import { createSelector } from "reselect";
import { flatten, get, sumBy } from "lodash";

export const all = (productIds, periods, entities) =>
  periods.map((period) =>
    sumBy(
      flatten(
        period.periodStarts.map((periodStart) =>
          flatten(
            productIds.map((productId) => {
              const entityIds = get(
                entities.byProduct,
                [productId, periodStart],
                [],
              );

              return entityIds.map((entityId) => entities.byId[entityId]);
            }),
          ),
        ),
      ),
      "hours",
    ),
  );

export const byRole = (roles, productIds, periods, entities) =>
  roles.ids.map((roleId) => ({
    id: roleId,
    name: roles.byId[roleId].title,
    values: getDetailValues(
      roleId,
      periods,
      entities.byProductAndRole,
      productIds,
      entities.byId,
    ),
  }));

export const byUser = (users, productIds, periods, entities) =>
  users.ids.map((userId) => ({
    id: userId,
    name: `${users.byId[userId].fullName}`,
    values: getDetailValues(
      userId,
      periods,
      entities.byProductAndUser,
      productIds,
      entities.byId,
    ),
  }));

export const byAssignee = (
  users,
  placeholders,
  productIds,
  periods,
  entities,
  uniqComparator,
) => {
  const userMap = users.ids.map((userId) => ({
    id: userId,
    name: `${users.byId[userId].fullName}`,
    values: getDetailValues(
      `User.${userId}`,
      periods,
      entities.byProductAndAssignee,
      productIds,
      entities.byId,
      uniqComparator,
    ),
  }));

  const placeholderMap = placeholders.ids.map((placeholderId) => ({
    id: placeholderId,
    name: placeholders.byId[placeholderId].name,
    values: getDetailValues(
      `Placeholder.${placeholderId}`,
      periods,
      entities.byProductAndAssignee,
      productIds,
      entities.byId,
      uniqComparator,
    ),
  }));

  return userMap.concat(placeholderMap);
};

const mapEntities = (
  objectId,
  periods,
  entitiesByDetail,
  productIds,
  entitiesById,
) =>
  periods.map((period) =>
    flatten(
      period.periodStarts.map((periodStart) =>
        flatten(
          productIds.map((productId) => {
            const entityIds = get(
              entitiesByDetail,
              [productId, objectId, periodStart],
              [],
            );

            return entityIds.map((entityId) => entitiesById[entityId]);
          }),
        ),
      ),
    ),
  );

const getDetailValues = createSelector([mapEntities], (periods) =>
  periods.map((period) => sumBy(period, "hours")),
);
