import React from "react";
import { Field } from "formik";
import FormDropdown from "../../components/form-dropdown";
import keyBy from "lodash/keyBy";

export const options = [
  { value: "partner_evaluation", content: "Partner Evaluation" },
  { value: "opportunities_identified", content: "Opportunities Identified" },
  { value: "shaped", content: "Shaped" },
  { value: "active", content: "Active" },
  { value: "complete", content: "Complete" },
  { value: "on_hold", content: "On Hold" },
];

export const optionsByKey = keyBy(options, "value");

function StatusDropdown({ label }) {
  return (
    <Field
      name="attributes.projectStatus"
      component={FormDropdown}
      options={options}
      searchable={false}
      label={label}
    />
  );
}

export default StatusDropdown;
