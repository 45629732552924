import React from "react";

export default ({ fill = "#8B98AE", style }) => (
  <svg
    width="100%"
    viewBox={`0 0 32 32`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    transform="rotate(90)"
    style={style}
  >
    <defs>
      <path
        d="M29.4806269,8.51937311 C29.1349375,8.17368374 28.7250607,8 28.2497378,8 L3.7502622,8 C3.27661742,8 2.86506248,8.17368374 2.51937311,8.51937311 C2.17368374,8.86674058 2,9.27661742 2,9.7502622 C2,10.223907 2.17368374,10.6333643 2.51937311,10.9807318 L14.7695304,23.2308891 C15.1168979,23.5765785 15.5263552,23.7502622 16,23.7502622 C16.4736448,23.7502622 16.8847802,23.5765785 17.2308891,23.2308891 L29.4806269,10.9807318 C29.8263163,10.6333643 30,10.223907 30,9.7502622 C30,9.27661742 29.8263163,8.86674058 29.4806269,8.51937311 Z"
        id="path-1x"
      />
    </defs>
    <g
      id="SymbolsX"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Foundations/Icons/Utility/arrow-left">
        <mask id="mask-2x" fill="white">
          <use xlinkHref="#path-1x" />
        </mask>
        <use id="Fill-1x" xlinkHref="#path-1x" />
        <g
          id="Foundations/Colors/@bluegrey-90x"
          mask="url(#mask-2x)"
          fill={fill}
        >
          <rect id="#8C99B0x" x="0" y="0" width="32" height="32" />
        </g>
      </g>
    </g>
  </svg>
);
