import React from "react";
import { Color, Corners, Frame, Inline, Text } from "@ableco/baseline";
import { BodySmall } from "./design-system/typography-components";
import clsx from "clsx";

const types = {
  success: {
    backgroundColor: Color.SuccessLighter,
    color: Color.SuccessDarker,
  },
  opportunity: {
    backgroundColor: Color.WarningLighter,
    color: Color.WarningDarker,
  },
  general: {
    backgroundColor: Color.PrimaryLighter,
    color: Color.PrimaryDarker,
  },
  default: {
    backgroundColor: Color.Neutral200,
    color: Color.Neutral700,
    colorIcon: Color.Neutral800,
  },
  defaultLighter: {
    backgroundColor: Color.Neutral200,
    color: Color.SuccessDarker,
  },
};

export default function Tag({
  text,
  className = "",
  type = "default",
  LeftIcon,
  RightIcon,
  classNameText = "",
}) {
  return (
    <Frame
      p={[1, 2]}
      bg={types[type].backgroundColor}
      corners={Corners.MediumRounded}
      className={`text-center ${className}`}
      data-testid={`tag-${type}`}
    >
      <Inline space={2}>
        {LeftIcon && (
          <Text
            color={
              types[type].colorIcon ? types[type].colorIcon : types[type].color
            }
          >
            <LeftIcon className="w-4 h-4" />
          </Text>
        )}
        <BodySmall
          className={clsx("select-none cursor-default", classNameText)}
          color={types[type].color}
        >
          {text}
        </BodySmall>
        {RightIcon && (
          <Text
            color={
              types[type].colorIcon ? types[type].colorIcon : types[type].color
            }
          >
            <RightIcon className="w-4 h-4" />
          </Text>
        )}
      </Inline>
    </Frame>
  );
}
