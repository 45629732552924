import { Color, TextWhitespace } from "@ableco/baseline";
import React from "react";
import { BodySmallSemibold } from "../../../components/design-system/typography-components";

export function LabelText({ children, ...props }) {
  return (
    <BodySmallSemibold
      color={Color.Neutral800}
      whitespace={TextWhitespace.NoWrap}
      {...props}
    >
      {children}
    </BodySmallSemibold>
  );
}
