import React from "react";
import { Inline, Stack, Text } from "@ableco/baseline";
import { Heading } from "../design-system/typography-components";

export default function SectionHeader({ title, icon, subtitle }) {
  return (
    <Stack
      alignment="start"
      bg="white"
      corners="small"
      className="w-full border-b border-neutral-300 pb-3"
    >
      <Inline space={2}>
        {icon}
        <Heading leading="relaxed" color="neutral-800">
          {title}
        </Heading>
      </Inline>

      {subtitle && (
        <Text aria-label="note" color="neutral-600" leading="normal">
          {subtitle}
        </Text>
      )}
    </Stack>
  );
}
