import React, { useState, useContext, useMemo } from "react";
import styled from "styled-components";
import partition from "lodash/partition";
import api from "../../services/api";
import { colorPalette } from "./../../components/style-guide/colors";
import {
  fontWeights,
  fonts,
  fontSizes,
} from "./../../components/style-guide/typography";
import TimelineContext from "./timeline-context";
import useTooltip from "../../hooks/use-tooltip";
import {
  getAcronym,
  matchInitials,
  getRoleAcronym,
} from "../../utils/string-initials";
import { sortCriteriaGenerator } from "../../utils/sort-criteria";

const Button = styled.button`
  flex: 0 0 160px;
  color: ${colorPalette.pink500};
  font-weight: ${fontWeights.bold};
  position: relative;
  &:focus {
    outline: none;
  }
`;

const SectionTitle = styled.h4`
  color: ${colorPalette.bluegrey90};
  font-size: ${fontSizes.small}px;
  font-family: ${fonts.sansSerif};
  font-weight: ${fontWeights.semiBold};
  line-height: 18px;
`;

const ResultGroupContainer = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const Results = styled.div`
  overflow: auto;
  height: 171px;
  margin-top: 20px;
`;

const UserList = styled.ul`
  margin-top: 7px;
`;

const User = styled.li`
  padding: 0;
  font-size: 14px;
  cursor: pointer;
`;

const SearchInput = styled.input`
  margin-top: 10px;
`;

const NoResultsText = styled(SectionTitle)`
  text-align: center;
  margin-top: 30px;
`;

function filterUsers(users, inputValue) {
  return users.filter(
    (user) =>
      !inputValue ||
      user.attributes.fullName
        .toLowerCase()
        .includes(inputValue.toLowerCase()) ||
      matchInitials(user.attributes.fullName, inputValue),
  );
}

function isBenchForAssignments(assignments) {
  return (user) => {
    const {
      attributes: { ocupation },
    } = user;

    return assignments.every(
      ({ attributes: { periodStart, hours } }) =>
        !ocupation[periodStart] || hours < 40 - ocupation[periodStart].hours,
    );
  };
}

function assignUser(user, assignments) {
  return Promise.all(
    assignments.map((assignment) =>
      api.productAssignments.find(assignment.id).update({
        assigneeType: "User",
        assigneeId: user.id,
      }),
    ),
  );
}

function ResultGroup({ title, users, onUserClick }) {
  return (
    <ResultGroupContainer data-testid={`assign-link-group-${title}`}>
      <SectionTitle>{title}</SectionTitle>
      <UserList>
        {users.map((user) => (
          <User
            key={user.id}
            onClick={() => onUserClick(user)}
            data-testid="assign-link-user"
          >
            {[
              user.attributes.fullName,
              getRoleAcronym(user.attributes.role),
              user.attributes.location && user.attributes.location.length > 5
                ? getAcronym(user.attributes.location)
                : user.attributes.location,
            ]
              .filter((str) => !!str)
              .join(" | ")}
          </User>
        ))}
      </UserList>
    </ResultGroupContainer>
  );
}

function AssignLink({ assignments }) {
  const [Tooltip, triggerProps, { setVisibility: setTooltipVisibility }] =
    useTooltip();
  const [query, setQuery] = useState("");
  const { data, revalidateProductsAssignments } = useContext(TimelineContext);
  const [benchUsers, assignedUsers] = useMemo(
    () =>
      partition(
        Object.values(data.users)
          .filter(
            (user) => user.attributes.active && user.attributes.assignable,
          )
          .sort(
            sortCriteriaGenerator(
              (assignment) => assignment.attributes.fullName,
            ),
          ),
        isBenchForAssignments(assignments),
      ),
    [data.users, assignments],
  );

  const [[filteredBenchUsers, filteredAssignedUsers], setResults] = useState([
    benchUsers,
    assignedUsers,
  ]);

  function handleChange({ target: { value } }) {
    setQuery(value);
    setResults([
      filterUsers(benchUsers, value),
      filterUsers(assignedUsers, value),
    ]);
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  async function handleUserClick(user) {
    try {
      await assignUser(user, assignments);
      setTooltipVisibility(false);
      revalidateProductsAssignments();
    } catch {
      alert("Something went wrong!");
    }
  }

  const noAssignedUsers = filteredAssignedUsers.length === 0;
  const noBenchUsers = filteredBenchUsers.length === 0;

  return (
    <>
      <Button {...triggerProps}>assign</Button>
      <Tooltip>
        <SectionTitle>Assign seat to:</SectionTitle>
        <SearchInput
          aria-label="tooltip"
          value={query}
          type="text"
          onChange={handleChange}
          placeholder="Search for team member"
        />

        <Results>
          {noAssignedUsers && noBenchUsers ? (
            <NoResultsText>No Results Found</NoResultsText>
          ) : (
            <>
              {!noBenchUsers && (
                <ResultGroup
                  title="Bench"
                  users={filteredBenchUsers}
                  onUserClick={handleUserClick}
                />
              )}
              {!noAssignedUsers && (
                <ResultGroup
                  title="Assigned"
                  users={filteredAssignedUsers}
                  onUserClick={handleUserClick}
                />
              )}
            </>
          )}
        </Results>
      </Tooltip>
    </>
  );
}

export default AssignLink;
