import React from "react";
import OneOnOneForm from "./one-on-one-form";
import useCurrentUser from "../../hooks/use-current-user";
import { Inline } from "@ableco/baseline";
import getOneOnOneHistoryPath from "../../utils/1-on-1/get-1-on-1-history-path";
import { useResource } from "coreql";
import { Title } from "../design-system/typography-components";
import { TipsButton } from "../buttons/buttons";
import Tag from "../tag";
import { AnimatePresence } from "framer-motion";
import { OneOnOneTipsDrawer } from "../../content/tips/tips-1-on-1";
import BackButton from "../layout/back-button";
import PageLayout from "../layout/page-layout";
import PresenceGroup from "./one-on-one-presence-group";
import { ActionCableProvider } from "../../providers/action-cable";
import { OneOnOneChannelProvider } from "../../channels/one_on_one";
import { useParams } from "react-router";

function OneOnOne() {
  const { data: currentUser } = useCurrentUser();
  const { user1, user2 } = useParams();
  const requestedId = user1 === currentUser.id ? user2 : user1;
  const [cacheKeys] = React.useState(() => new Set());

  const [showModal, setShowModal] = React.useState(false);
  const [connected, setConnected] = React.useState(false);
  const handleClose = React.useCallback(
    () => setShowModal(false),
    [setShowModal],
  );

  const connectionStatus = connected
    ? { text: "Online", type: "success" }
    : { text: "Offline", type: "general" };

  const { data: requestedUser } = useResource(
    "users",
    requestedId,
    {
      fields: {
        users: ["id", "preferredName", "avatarUrl"],
      },
    },
    "denormalized",
  );

  const backButton = (
    <BackButton
      text={"1-on-1 History"}
      href={getOneOnOneHistoryPath(currentUser.id, requestedId)}
    />
  );

  return (
    <ActionCableProvider>
      <OneOnOneChannelProvider
        requestedId={requestedId}
        onConnectionChange={setConnected}
      >
        <PageLayout
          user={requestedUser}
          title={`1-on-1 with ${requestedUser.preferredName}`}
          backButton={backButton}
          subTitle={
            <Inline space={2}>
              <Title color="neutral-700">Current 1-on-1</Title>
              <TipsButton
                onClick={(event) => {
                  event.preventDefault();
                  setShowModal(true);
                }}
              />
              <PresenceGroup
                requestedId={requestedId}
                currentUser={currentUser}
              />
              <Tag type={connectionStatus.type} text={connectionStatus.text} />
              <AnimatePresence>
                {showModal && <OneOnOneTipsDrawer onClose={handleClose} />}
              </AnimatePresence>
            </Inline>
          }
        >
          <OneOnOneForm
            currentUser={currentUser}
            requestedUser={requestedUser}
            cacheKeys={cacheKeys}
          />
        </PageLayout>
      </OneOnOneChannelProvider>
    </ActionCableProvider>
  );
}

export default OneOnOne;
