import React, { useState } from "react";
import { HeartOutline, Heart } from "@baseline/icons";
import { useQueuedCallback } from "../../../hooks/use-queued-callback";
import { Touchable, Color, Inline, Frame } from "@ableco/baseline";
import { api } from "coreql";
import { BodyBaseline } from "../../design-system/typography-components";
import { motion } from "framer-motion";
import clsx from "clsx";

export function LikedIndicator({
  weeklyAnswerLikeId,
  likeUser,
  isDirectReport,
  hasCheckBox = false,
}) {
  if (!weeklyAnswerLikeId) return null;

  return (
    <Inline p={[0, 0, 2, hasCheckBox ? 10 : 5]} space={1}>
      <Heart className="w-5 h-5 text-alert-light" />
      <BodyBaseline color={Color.Neutral600}>
        {isDirectReport
          ? "You liked this answer"
          : `${likeUser?.preferredName} liked this answer`}
      </BodyBaseline>
    </Inline>
  );
}

export default function Like({
  weeklyAnswerId,
  currentUser,
  weeklyAnswerLikeId,
  setWeeklyAnswerLikeId,
}) {
  const [disabled, setDisabled] = useState(false);

  const handleLike = useQueuedCallback(async (weeklyAnswerLikeId) => {
    setDisabled(true);
    if (!weeklyAnswerLikeId) {
      try {
        const createdLike = await api.likes.create({
          likeableType: "WeeklyReportAnswer",
          likeableId: weeklyAnswerId,
          userId: currentUser.id,
        });
        setWeeklyAnswerLikeId(createdLike.data.id);
      } catch {
        setWeeklyAnswerLikeId(null);
      }
      setDisabled(false);
    } else {
      await api.likes.find(weeklyAnswerLikeId).destroy();
      setWeeklyAnswerLikeId(null);
      setDisabled(false);
    }
  });

  return (
    <Touchable
      onClick={() => handleLike(weeklyAnswerLikeId)}
      className={clsx(
        "opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out",
        disabled && "hover:cursor-not-allowed",
      )}
      disabled={disabled}
    >
      <Frame
        as={motion.div}
        whileHover={!disabled && { scale: 1.2 }}
        whileTap={!disabled && { scale: 1.4 }}
        transition={
          !disabled && {
            type: "spring",
            damping: 4,
          }
        }
      >
        {weeklyAnswerLikeId ? (
          <Heart className="w-6 h-6 text-alert-light transition-colors duration-300 ease-in-out" />
        ) : (
          <HeartOutline className="w-6 h-6 text-neutral-600 text-opacity-75 hover:text-alert-light transition-colors duration-300 ease-in-out" />
        )}
      </Frame>
    </Touchable>
  );
}
