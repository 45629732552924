import { Inline, Skeleton, Stack, Color } from "@ableco/baseline";
import { denormalize, ErrorBoundary, SSRSuspense, useResource } from "coreql";
import { format } from "date-fns";
import { capitalize, groupBy, isEmpty, property, random } from "lodash";
import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { useTable } from "react-table";
import useCurrentUser from "../../../hooks/use-current-user";
import { LEVEL_FIELDS } from "../../capabilities-table/capabilities-table-utils";
import { PrimaryButton } from "../../buttons/buttons";
import CoreLink from "../../core-link";
import {
  BodyBaseline,
  BodySmall,
  Caption,
  Heading,
  TitleSemibold,
} from "../../design-system/typography-components";
import ErrorFallback from "../../error-fallback";
import BackButton from "../../layout/back-button";
import PageLayout from "../../layout/page-layout";
import { MarkdownViewer } from "../../markdown/markdown-viewer";
import Tag from "../../tag";
import { wrap } from "../../weekly-report/utils";
import { BadgeStatus } from "./badge-status";
import {
  EvaluationRubricsTable,
  PrerequisitesTable,
  RelatedCapabilitiesTable,
  RequiredActivitiesTable,
} from "./badges-detail-tables";
import Table, { TableFallback } from "./table";
import { possessive } from "../../../utils/possesive";

function useUserPermissions(user) {
  return useMemo(
    () =>
      new Set([...wrap(user.permissions), ...wrap(user?.role?.permissions)]),
    [user],
  );
}

/* exported for testing */
export function BadgeEvaluators({ data, owner }) {
  const evaluators = useMemo(() => {
    /** display owner as default lead evaluator */
    const {
      member = [],
      lead = [{ user: owner }],
      alternate = [],
    } = groupBy(wrap(data), property("role"));

    const members = member.concat(alternate).flatMap(({ user }) => [
      <CoreLink
        key={user.id}
        href={`/users/${user.id}`}
        className="hover:no-underline"
      >
        <BodyBaseline color={[Color.Primary, Color.PrimaryLight]}>
          {user.fullName}
        </BodyBaseline>
      </CoreLink>,
      ", ",
    ]);
    members.pop();

    return {
      members,
      lead,
    };
  }, [data, owner]);

  const leadEvaluator = evaluators.lead[0].user;
  return (
    <Stack space={2}>
      <Inline space={12}>
        <BodyBaseline color={Color.Neutral700}>Lead</BodyBaseline>
        <CoreLink
          href={`/users/${leadEvaluator.id}`}
          className="hover:no-underline"
        >
          <BodyBaseline
            aria-label="Lead evaluator for this badge"
            color={[Color.Primary, Color.PrimaryLight]}
            className="transition-colors ease-in-out duration-300"
          >
            {leadEvaluator.fullName}
          </BodyBaseline>
        </CoreLink>
      </Inline>
      {!isEmpty(evaluators.members) && (
        <Inline space={4}>
          <BodyBaseline color={Color.Neutral700}>Members</BodyBaseline>
          <BodyBaseline color={[Color.Neutral700, Color.Neutral800]}>
            {evaluators.members}
          </BodyBaseline>
        </Inline>
      )}
    </Stack>
  );
}

function CommonBadgeDetails({ userId, badgeId }) {
  const { data: user } = useCurrentUser();
  const permissions = useUserPermissions(user);
  const { data } = useResource("badges", badgeId, {
    included: [
      "evaluationRubrics",
      "evaluators",
      "evaluators.user",
      "requiredActivities",
      "prerequisites",
      "capabilities",
      "capabilities.skill",
      "owner",
    ],
    fields: {
      users: ["fullName"],
    },
  });

  const badge = useMemo(() => {
    /** Requested badge & prerequisites */
    const badges = denormalize(data, "badges", true);

    return badges.find(({ id }) => id == badgeId);
  }, [data, badgeId]);

  return (
    <>
      <Stack as="section" space={8}>
        <Stack space={2}>
          <TitleSemibold as="h3" color={Color.Neutral700}>
            Description
          </TitleSemibold>
          <MarkdownViewer
            value={badge.description}
            className="text-neutral-700"
          />
        </Stack>
        {badge.evaluationCriteria && (
          <Stack space={2}>
            <TitleSemibold as="h3" color={Color.Neutral700}>
              Evaluation Criteria
            </TitleSemibold>
            <MarkdownViewer
              value={badge.evaluationCriteria}
              className="text-neutral-700"
            />
          </Stack>
        )}
        <Stack space={2}>
          <TitleSemibold as="h3" color={Color.Neutral700}>
            Application Process
          </TitleSemibold>
          <MarkdownViewer
            value={badge.applicationProcess}
            className="text-neutral-700"
          />
        </Stack>
        {!isEmpty(badge.requiredActivities) && (
          <Stack space={2}>
            <TitleSemibold as="h3" color={Color.Neutral700}>
              Required Activities
            </TitleSemibold>
            <RequiredActivitiesTable data={badge.requiredActivities} />
          </Stack>
        )}
        <Stack space={2}>
          <TitleSemibold as="h3" color={Color.Neutral700}>
            Evaluation Rubric
          </TitleSemibold>
          <EvaluationRubricsTable data={badge.evaluationRubrics} />
        </Stack>
        <Stack space={2}>
          <TitleSemibold as="h3" color={Color.Neutral700}>
            Related Capabilities
          </TitleSemibold>
          <RelatedCapabilitiesTable data={badge.capabilities} />
        </Stack>
        {!isEmpty(badge.prerequisites) && (
          <Stack space={2}>
            <TitleSemibold as="h3" color={Color.Neutral700}>
              Prerequisites
            </TitleSemibold>
            <PrerequisitesTable data={badge.prerequisites} userId={userId} />
          </Stack>
        )}
        <Stack space={2}>
          <TitleSemibold as="h3" color={Color.Neutral700}>
            Evaluation Committee
          </TitleSemibold>
          <BadgeEvaluators data={badge.evaluators} owner={badge.owner} />
        </Stack>
        <Inline distribution="between">
          <Caption aria-label="Badge Owner" color={Color.Neutral700}>
            Owner:{" "}
            <CoreLink
              to={`/users/${badge.owner.id}`}
              className="text-primary-base hover:text-primary-light transition-colors duration-300 hover:no-underline"
            >
              {badge.owner.fullName}
            </CoreLink>
            <br />
            Last updated: {format(new Date(badge.updatedAt), "d-MMM-yyyy")}
          </Caption>
          {permissions.has("manage_badges") && (
            <LinkToEditBadges href={`/admin/badges/${badgeId}/edit`} />
          )}
        </Inline>
      </Stack>
    </>
  );
}

function LinkToEditBadges({ href }) {
  return (
    <PrimaryButton
      text="Edit"
      role="link"
      onClick={() => window.location.assign(href)}
    />
  );
}

function ObservationBadgeDetails({ badgeId }) {
  const { data: user } = useCurrentUser();
  const permissions = useUserPermissions(user);
  const { data: badge } = useResource(
    "badges",
    badgeId,
    {
      included: [
        "observableBehaviours",
        "observableBehaviours.level",
        "capabilities",
        "capabilities.skill",
      ],
      fields: {
        users: ["fullName"],
      },
    },
    "denormalized",
  );
  const { level } = wrap(badge.observableBehaviours)[0];

  const categories = useMemo(() => {
    function filterEmptyCategories({ key }) {
      return !isEmpty(level[key].trim());
    }

    return LEVEL_FIELDS.filter(filterEmptyCategories).map(({ title, key }) => ({
      title,
      description: level[key],
    }));
  }, [level]);

  const capabilitiesTable = useTable({
    data: useMemo(
      () => [
        {
          capability: wrap(badge.capabilities)[0],
          observableBehaviour: wrap(badge.observableBehaviours)[0],
        },
      ],
      [badge],
    ),
    columns: useMemo(
      () => [
        {
          Header: "Skill",
          accessor: "capability.skill.name",
          thProps: { className: "w-1/5" },
          Cell: ({ value: skillName }) => (
            <BodySmall color={Color.Neutral700}>{skillName}</BodySmall>
          ),
        },
        {
          Header: "Observable Behaviour",
          accessor: "observableBehaviour.description",
          Cell: ({ value: description }) => (
            <BodyBaseline color={Color.Neutral700}>{description}</BodyBaseline>
          ),
        },
      ],
      [],
    ),
  });

  const criteriaTable = useTable({
    data: categories,
    columns: useMemo(
      () => [
        {
          Header: "Category",
          accessor: "title",
          thProps: { className: "w-1/5" },
          Cell: ({ value: title }) => (
            <BodyBaseline color="neutral-700">{title}</BodyBaseline>
          ),
        },
        {
          Header: "Description",
          accessor: "description",
          Cell: ({ value: description }) => (
            <BodyBaseline color={Color.Neutral700}>{description}</BodyBaseline>
          ),
        },
      ],
      [],
    ),
  });
  return (
    <>
      <Stack space={2}>
        <TitleSemibold as="h2" color={Color.Neutral700}>
          Related Capabilities
        </TitleSemibold>
        <Table data={capabilitiesTable} />
      </Stack>
      <Stack space={2}>
        <TitleSemibold as="h2" color={Color.Neutral700}>
          Evaluation Criteria
        </TitleSemibold>
        <Table data={criteriaTable} />
      </Stack>
      <Stack space={2}>
        <TitleSemibold as="h2" color={Color.Neutral700}>
          Application Process
        </TitleSemibold>
        <BodyBaseline color={Color.Neutral700}>
          Ask your manager about the application process and the type of
          evidence required for this badge.
        </BodyBaseline>
      </Stack>
      <Inline distribution="end">
        {permissions.has("manage_capabilities") && (
          <LinkToEditBadges
            href={`/admin/capabilities/${badge?.capabilities?.id}/edit`}
          />
        )}
      </Inline>
    </>
  );
}

function getTagsForBadge({ archivedAt, kind, observableBehaviours }) {
  const tags = new Set();

  for (const behaviour of observableBehaviours) {
    const { name: levelName } = behaviour.level;
    const { capability } = behaviour;

    if (capability.kind === "manager") {
      tags.add(`Manager ${levelName}`);
    } else {
      for (const role of wrap(capability.roles))
        tags.add(`${role.title} ${levelName}`);
    }
  }

  const isArchived = !!archivedAt;
  const allTags = [`Type: ${capitalize(kind)}`, ...tags];

  if (isArchived) allTags.push("Archived");

  return allTags;
}

function BadgesBackButton({ isCurrentUser, preferredName }) {
  const { state = {} } = useLocation();
  const {
    title = isCurrentUser ? "my badges" : `${possessive(preferredName)} badges`,
    href = "../badges",
  } = state;
  return <BackButton text={`Back to ${title}`} href={href} />;
}

function BadgeDetailView() {
  const { data: currentUser } = useCurrentUser();
  const { id: userId = currentUser.id, badgeId } = useParams();

  const { data: user } = useResource(
    "users",
    userId,
    {
      included: ["role"],
      fields: {
        roles: [
          "title",
          "pluralTitle",
          "disciplineTitle",
          "disciplineId",
          "departmentTitle",
          "departmentId",
        ],
      },
    },
    "denormalized",
  );

  const { data: response } = useResource("badges", badgeId, {
    included: [
      "observableBehaviours",
      "observableBehaviours.level",
      "observableBehaviours.capability",
      "observableBehaviours.capability.roles",
    ],
    fields: {
      users: ["fullName"],
      badges: ["name", "kind", "archivedAt"],
      observableBehaviours: ["level", "capability"],
      capabilities: ["roles", "kind"],
      roles: ["title"],
    },
  });

  const badge = useMemo(() => {
    const data = denormalize(response, "badges");
    return {
      ...data,
      observableBehaviours: denormalize(response, "observableBehaviours", true),
    };
  }, [response]);

  const badgeTags = useMemo(
    () => (
      <Inline space={2}>
        {getTagsForBadge(badge).map((text) => (
          <Tag key={text} type="general" text={text} />
        ))}
      </Inline>
    ),
    [badge],
  );

  const isDirectReport = currentUser.id == user.managerId;
  const isCurrentUser = currentUser.id == user.id;
  const isDirectReportOrSelf = isDirectReport || isCurrentUser;

  const layoutProps = user.reportsToMe
    ? {
        title: user.fullName,
        avatarUser: user,
        subTitle: user?.role?.title,
      }
    : {
        title: badge.name,
        subTitle: badgeTags,
      };

  return (
    <PageLayout
      backButton={
        <BadgesBackButton
          preferredName={user.preferredName}
          isCurrentUser={isCurrentUser}
        />
      }
      {...layoutProps}
    >
      <Stack as="section" space={8}>
        {user.reportsToMe && (
          <Stack space={2}>
            <Heading as="h2">{badge.name}</Heading>
            {badgeTags}
          </Stack>
        )}
        <BadgeStatus
          badgeId={badge.id}
          userId={userId}
          editable={isDirectReportOrSelf}
          showManagerOptions={isDirectReport}
        />
        {badge.kind === "observation" ? (
          <ObservationBadgeDetails badgeId={badgeId} />
        ) : (
          <CommonBadgeDetails badgeId={badgeId} userId={userId} />
        )}
      </Stack>
    </PageLayout>
  );
}

function ContentSkeleton({ height }) {
  return (
    <Skeleton
      corners="medium"
      width="100%"
      color={Color.Neutral200}
      height={height}
      animated
    />
  );
}

function SectionSkeleton({ titleWidth, children }) {
  return (
    <Stack space={4}>
      <Skeleton
        corners="medium"
        width={titleWidth}
        color={Color.Neutral200}
        height={24}
        animated
      />
      {children}
    </Stack>
  );
}

function Fallback() {
  const tags = useMemo(
    () => [...new Array(random(3, 5))].map(() => random(10, 14)),
    [],
  );
  return (
    <PageLayout
      backButton={<BackButton text="Back to badges" href="../badges" />}
      title={
        <Skeleton
          width={180}
          height={32}
          color="neutral-300"
          className="my-2"
          animated
        />
      }
      subTitle={
        <Inline space={2}>
          {tags.map((width, i) => (
            <Skeleton
              corners="medium"
              key={i}
              width={width * 10}
              color={Color.Neutral200}
              height={16}
              animated
            />
          ))}
        </Inline>
      }
    >
      <Stack space={8}>
        <Skeleton
          corners="medium"
          width={140}
          color={Color.Neutral200}
          height={24}
          animated
        />
        <SectionSkeleton titleWidth={160}>
          <ContentSkeleton height={140} />
        </SectionSkeleton>
        <SectionSkeleton titleWidth={180}>
          <ContentSkeleton height={120} />
        </SectionSkeleton>
        <SectionSkeleton titleWidth={160}>
          <TableFallback rows={4} cols={2} />
        </SectionSkeleton>
        <SectionSkeleton titleWidth={180}>
          <TableFallback rows={3} cols={2} />
        </SectionSkeleton>
      </Stack>
    </PageLayout>
  );
}

function UserBadgeDetailPage() {
  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <SSRSuspense fallback={<Fallback />}>
        <BadgeDetailView />
      </SSRSuspense>
    </ErrorBoundary>
  );
}

export default UserBadgeDetailPage;
