import React from "react";
import { Inline, Text, Color, Corners } from "@ableco/baseline";
import cn from "clsx";
import { BodySmall } from "../design-system/typography-components";

export default function Checkbox({
  label,
  textComponent,
  border = Color.Neutral400,
  padding = 2,
  className,
  ...props
}) {
  return (
    <Inline
      className="cursor-pointer"
      as="label"
      p={padding}
      border={border}
      corners={Corners.MediumRounded}
    >
      <Text
        className={cn("mr-2 cursor-pointer", className)}
        {...props}
        as="input"
        type="checkbox"
      />
      {textComponent ? (
        textComponent
      ) : (
        <BodySmall color={Color.Neutral700}>{label}</BodySmall>
      )}
    </Inline>
  );
}
