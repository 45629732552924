import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import { capitalize, noop } from "lodash";
import { Inline, Color } from "@ableco/baseline";
import { api } from "coreql";

import CoreLink from "../../core-link";
import { BodyBaseline } from "../../design-system/typography-components";
import Tag from "../../tag";
import Table from "./table";
import { useQueuedCallback } from "../../../hooks/use-queued-callback";
import {
  AwardBadgeStatus,
  BadgeStatusSelect,
  getTogglerOptions,
} from "./badge-status";

export const BADGE_KIND_TAGS = {
  Observation: "default",
  Evaluation: "general",
  Achievement: "success",
};

function BadgeItemStatus({
  badgeId,
  assessment,
  userId,
  editable,
  showManagerOptions,
  removable = false,
  onRemove,
}) {
  const [userBadgeStatusId, setUserBadgeStatusId] = useState(assessment?.id);

  const [assessmentStatus, setAssessmentStatus] = useState(
    assessment?.status ?? "pending",
  );

  const [assessmentDate, setAssessmentDate] = useState(assessment?.awardedAt);

  useEffect(() => {
    setUserBadgeStatusId(assessment?.id);
    setAssessmentStatus(assessment?.status ?? "pending");
    setAssessmentDate(assessment?.awardedAt);
  }, [assessment]);

  const updateBadgeStatus = useQueuedCallback(async (statusUpdate) => {
    const { data } = await api.userBadges
      .find(userBadgeStatusId)
      .upsert(statusUpdate, { badgeId, userId });

    setUserBadgeStatusId(data?.id);
  });

  function handleStatusUpdate({ status: newStatus, date }) {
    if (newStatus === "remove") {
      api.userBadges.find(userBadgeStatusId).destroy();
      onRemove(badgeId);
    } else {
      setAssessmentStatus(newStatus);
      setAssessmentDate(date);
      updateBadgeStatus({ status: newStatus, awardedAt: date });
    }
  }

  const options = useMemo(
    () => getTogglerOptions({ manager: showManagerOptions, remove: removable }),
    [showManagerOptions],
  );

  return assessmentStatus === "waived" || assessmentStatus === "awarded" ? (
    <Inline distribution="start">
      <AwardBadgeStatus
        status={assessmentStatus}
        date={assessmentDate}
        fromBadgeList={true}
      />
    </Inline>
  ) : (
    <BadgeStatusSelect
      badgeId={badgeId}
      options={options}
      value={assessmentStatus}
      disabled={!editable}
      onChange={handleStatusUpdate}
    />
  );
}

export default function LevelBadgesTable({
  badges,
  badgeAssessments,
  userId,
  isDirectReport,
  isDirectReportOrSelf,
  dataTestId,
  onRemove = noop,
  allowRemove = false,
}) {
  const table = useTable({
    data: useMemo(
      () =>
        badges.map((badge) => ({
          ...badge,
          kind: capitalize(badge.kind),
          assessment: badgeAssessments[badge.id] ?? { status: "pending" },
        })),
      [badges, badgeAssessments],
    ),
    columns: useMemo(
      () => [
        {
          Header: "Name",
          accessor: "name",
          Cell: ({
            row: {
              original: { id, kind },
            },
            value: name,
          }) => (
            <Inline space={4}>
              <CoreLink to={`./badges/${id}`} className="hover:no-underline">
                <BodyBaseline
                  color={[Color.Primary, Color.PrimaryLight]}
                  className="transition-color duration-300 ease-in-out no-underline"
                >
                  {name}
                </BodyBaseline>
              </CoreLink>
              <Tag type={BADGE_KIND_TAGS[kind]} text={kind} />
            </Inline>
          ),
        },
        {
          Header: "Status",
          accessor: "assessment.status",
          thProps: { className: "w-1/4" },
          Cell: ({
            row: {
              original: { assessment, id },
            },
          }) => (
            <BadgeItemStatus
              badgeId={id}
              assessment={assessment}
              userId={userId}
              editable={isDirectReportOrSelf}
              showManagerOptions={isDirectReport}
              removable={allowRemove}
              onRemove={onRemove}
            />
          ),
        },
      ],
      [userId, isDirectReport, isDirectReportOrSelf],
    ),
  });
  return <Table data={table} dataTestId={dataTestId} />;
}
