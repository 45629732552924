// Modules
import { connect } from "react-redux";
// Components
import SummaryValueRows from "./SummaryValueRows";

const mapStateToProps = (state) => {
  const { periods } = state;

  return {
    periods,
  };
};

export default connect(mapStateToProps, {})(SummaryValueRows);
