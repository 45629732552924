import { format } from "date-fns";
import { api, useCollection } from "coreql";

function formatDateToParam(date) {
  return format(date, "yyyy-MM-dd");
}
const resource = "project-seats";

const included = ["project"];

const fields = {
  projectSeats: ["firstWeek", "lastWeek", "projectId"],
  projects: ["id"],
};

function getFilters(rangeStart, rangeEnd) {
  const rangeStartParam = formatDateToParam(rangeStart);
  const rangeEndParam = formatDateToParam(rangeEnd);
  return {
    inRange: [rangeStartParam, rangeEndParam],
  };
}

function useProjectSeats(rangeStart, rangeEnd) {
  return useCollection(resource, {
    filters: getFilters(rangeStart, rangeEnd),
    included,
    fields,
  });
}

export function readProjectSeats(rangeStart, rangeEnd) {
  return api.projectSeats.read({
    filters: getFilters(rangeStart, rangeEnd),
    included,
    fields,
  });
}
export default useProjectSeats;
