import React from "react";

import Accordion from "../../accordion";
import LevelBadgesTable from "./level-badges-table";

export default function BadgesGroup({
  roleTitle,
  badges,
  level,
  assessmentsByBadgeId,
  userId,
  isDirectReport,
  isDirectReportOrSelf,
  isFirst,
  isCurrentLevel,
}) {
  return (
    <Accordion
      state="none"
      variant="small"
      title={`${roleTitle} ${level.name} - ${level.code}`}
      label={
        isCurrentLevel
          ? "Badges for current level"
          : `Badges for level ${level.code}`
      }
      isOpen={isFirst}
    >
      <LevelBadgesTable
        badges={badges}
        badgeAssessments={assessmentsByBadgeId}
        userId={userId}
        isDirectReport={isDirectReport}
        isDirectReportOrSelf={isDirectReportOrSelf}
        dataTestId={`${level.code}-badges`}
      />
    </Accordion>
  );
}
