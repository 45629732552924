import React from "react";
import { Color, Stack } from "@ableco/baseline";
import { Nav } from "../layout/layout";

/**
 * @param {object} props
 * @param {React.ReactChild} props.children The content of the navigation
 */
export default function Navigation({ children }) {
  return (
    <Nav bg={Color.PrimaryDarker} className="relative">
      <Stack
        style={{
          height: "100%",
          width: "240px",
        }}
      >
        {children}
      </Stack>
    </Nav>
  );
}
