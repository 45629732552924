import React from "react";
import cn from "clsx";
import {
  Text,
  Stack,
  Inline,
  noop,
  FlexAlignment,
  FlexDistribution,
  Color,
  Frame,
} from "@ableco/baseline";
import Menu from "../layout/menu";
import {
  BodyBaseline,
  BodyBaselineSemibold,
} from "../design-system/typography-components";
import { useLocation } from "react-router-dom";
import { isFunction } from "lodash";
import CoreNavlink from "../core-navlink";

export default function NavItem({
  className = "",
  href,
  icon,
  label,
  isActive,
  activeColor = "white",
  activeClassName = "text-white bg-black bg-opacity-25",
  prepare = noop,
  menuPosition = "right",
  iconPosition = "left",
  prefetch,
  disabled,
  children,
  testId,
  isExternalLink,
}) {
  const [focused, setFocused] = React.useState(false);
  const location = useLocation();
  let active = false;
  if (isFunction(isActive)) active = !!isActive(location);
  const inLineTextIcon = (
    <Inline as="figure" distribution="start" className="h-full ml-6" space={3}>
      {iconPosition === "left" && (
        <Text color={active ? activeColor : ["primary-lighter", "white"]}>
          {icon}
        </Text>
      )}
      {active ? (
        <BodyBaselineSemibold color={Color.White} data-testid={testId}>
          {label}
        </BodyBaselineSemibold>
      ) : (
        <BodyBaseline color={Color.PrimaryLighter} data-testid={testId}>
          {label}
        </BodyBaseline>
      )}
    </Inline>
  );

  return (
    <Stack
      as="li"
      onMouseEnter={() => children && setFocused(true)}
      onMouseLeave={() => children && setFocused(false)}
      alignment={FlexAlignment.Center}
      distribution={FlexDistribution.Center}
      className={cn(
        `transition-colors duration-300 ease-in-out h-12 relative select-none `,
        { "hover:bg-white hover:bg-opacity-15": !active },

        className,
      )}
    >
      {href ? (
        <CoreNavlink
          isActive={() => active}
          to={href}
          href={href}
          activeClassName={activeClassName}
          disabled={disabled}
          prefetch={prefetch}
          prepare={prepare}
          className="w-full h-full cursor-pointer hover:no-underline"
          isExternalLink={isExternalLink}
        >
          {inLineTextIcon}
        </CoreNavlink>
      ) : (
        <Frame
          className={
            "w-full h-full cursor-pointer hover:no-underline" +
            (active ? " bg-black bg-opacity-25" : "")
          }
        >
          {inLineTextIcon}
        </Frame>
      )}

      {children && focused && (
        <Menu
          className="absolute"
          style={{
            left: menuPosition === "right" ? "100%" : -4,
            top: menuPosition === "right" ? -4 : "100%",
          }}
        >
          {children}
        </Menu>
      )}
    </Stack>
  );
}
