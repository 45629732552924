import React from "react";

import { useCollection, useResource } from "coreql";
import { Color, Stack } from "@ableco/baseline";

import { wrap } from "../utils";
import { filterAnswersByCriteria } from "./utils";
import useCurrentUser from "../../../hooks/use-current-user";
import AchievementSummary from "./achievement-summary";
import TemperatureSummary from "./temperature-summary";
import QuestionsSummary from "./questions-summary";
import { TitleSmall } from "../../design-system/typography-components";
import { useProductAssignments } from "../weekly-report-form";
import TimeEntriesSection from "../time-entries-section";

function HowThisWeekWent({
  weeklyReportId,
  weeklyReportOwner,
  weeklyReportDateStart,
}) {
  const { data: userDataFromRequest } = useCurrentUser();
  const userData = wrap(userDataFromRequest);

  const { data: timeEntries } = useCollection(
    "time-entries",
    {
      included: ["project", "project.product", "projectRole"],
      filters: { weeklyReportId: weeklyReportId },
      fields: {
        timeEntries: [
          "date",
          "hours",
          "projectId",
          "project",
          "projectRole",
          "projectRoleId",
        ],
        projects: ["name", "product"],
        products: ["name", "logoUrl"],
        projectRoles: ["name"],
      },
    },
    "denormalized",
  );

  const { data: weeklyReport } = useResource(
    "weekly-reports",
    weeklyReportId,
    {
      fields: {
        weeklyReports: ["achievedPlans", "questions"],
      },
    },

    "denormalized",
  );

  const weeklyUser = weeklyReportOwner;

  const { data: productsAssignments } = useProductAssignments(
    weeklyUser.id,
    weeklyReportDateStart,
  );

  const { data: weeklyReportAnswers, mutate: refetchWeeklyAnswers } =
    useCollection(
      "weekly-report-answers",

      {
        included: ["oneOnOneItem", "comments", "like", "like.user"],
        filters: { weeklyReport: weeklyReportId },
        fields: {
          weeklyReportAnswers: [
            "answerType",
            "answerableId",
            "data",
            "oneOnOneItem",
            "comments",
            "locked",
            "like",
          ],
          comments: ["id"],
          oneOnOneItems: ["text"],
          likes: ["id", "userId", "user"],
          users: ["preferredName"],
        },
      },
      "denormalized",
    );

  const questionAnswers = filterAnswersByCriteria(
    weeklyReportAnswers,
    "answerType",
    "question",
  );

  return (
    <React.Suspense fallback={null}>
      <Stack className="w-full" space={8}>
        <TemperatureSummary
          weeklyReport={weeklyReport}
          currentUser={userData[0]}
          weeklyUser={weeklyUser}
          weeklyReportAnswers={weeklyReportAnswers}
          refetchWeeklyAnswers={refetchWeeklyAnswers}
        />

        {timeEntries.length > 0 && (
          <Stack className="px-6" stack={2}>
            <TimeEntriesSection
              weeklyReportId={weeklyReportId}
              weeklyReportPeriodStart={weeklyReportDateStart}
              productsAssignments={productsAssignments}
              timeEntries={timeEntries}
              readOnly={true}
            />
          </Stack>
        )}

        <Stack className="w-full" space={4}>
          <TitleSmall as="h3" className="w-full px-6" color={Color.Neutral600}>
            ACCOMPLISHMENTS
          </TitleSmall>

          <AchievementSummary
            currentUser={userData[0]}
            weeklyUser={weeklyUser}
            weeklyReport={weeklyReport}
            weeklyReportAnswers={weeklyReportAnswers}
            refetchWeeklyAnswers={refetchWeeklyAnswers}
          />
        </Stack>

        <Stack className="w-full" space={4}>
          <TitleSmall as="h3" className="w-full px-6" color={Color.Neutral600}>
            QUESTIONS
          </TitleSmall>

          <QuestionsSummary
            weeklyReport={weeklyReport}
            currentUser={userData[0]}
            weeklyUser={weeklyUser}
            questionAnswers={questionAnswers}
            refetchWeeklyAnswers={refetchWeeklyAnswers}
          />
        </Stack>
      </Stack>
    </React.Suspense>
  );
}

export default HowThisWeekWent;
