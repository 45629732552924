/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from "react";
import { Frame, Stack } from "@ableco/baseline";
import clsx from "clsx";
import { api as apiCoreQl, denormalize } from "coreql";
import { isEmpty } from "lodash";
import { wrap } from "./utils";
import { useAccomplishments, useAnswersGroup, Answer } from "./accomplishments";
import { useCoreToast } from "../toast/toast";

function Achievements({ weeklyReportId, onUpdate }) {
  const { data: weeklyReportAccomplishments, mutate } =
    useAccomplishments(weeklyReportId);
  const achievements = wrap(weeklyReportAccomplishments.achievements);

  const [showAnswers, setShowAnswers] = useState(achievements.length > 0);

  const { answers, addAnswer, updateAnswer, removeAnswer } =
    useAnswersGroup(achievements);

  const { errorToast } = useCoreToast();

  function handleAddAchievements(event) {
    event.preventDefault();
    setShowAnswers(true);
  }

  return (
    <Frame>
      <Frame
        as="button"
        type="button"
        onClick={handleAddAchievements}
        className={clsx(
          "text-primary-darker",
          !showAnswers && "underline cursor-pointer",
          "mb-2",
        )}
      >
        Anything else to add?
      </Frame>
      {showAnswers && (
        <Stack
          role="group"
          aria-label="Additional achievements this week"
          space={2}
        >
          {answers.map((answer, i) => {
            const isLast = i === answers.length - 1;
            const currentKey = answer.__key;

            async function onSave(id, text) {
              // if text is empty
              if (isEmpty(text.trim())) {
                try {
                  if (id) {
                    await apiCoreQl.weeklyReportAnswers.find(id).destroy();
                    // set as blank to untrack the answer before mutate
                    updateAnswer(answer, () => ({ __key: currentKey }));
                    await mutate(async (weeklyReport) => {
                      const achievements = wrap(
                        weeklyReport.achievements,
                      ).filter((plan) => String(plan.id) !== String(id));
                      return { ...weeklyReport, achievements };
                    }, false);
                  }

                  onUpdate();
                } catch {
                  errorToast(
                    "We couldn't delete your answer, contact the team.",
                  );
                }
                // unset id on autosave hook
                return;
              }

              // update or create
              try {
                const response = await apiCoreQl.weeklyReportAnswers
                  .find(id)
                  .upsert(
                    {
                      data: { text },
                    },
                    {
                      answerType: "achievement",
                      weeklyReportId,
                    },
                    [{ name: "weeklyReport", resource: "weekly-report" }],
                  );

                const { id: newId } = response.data;

                updateAnswer(answer, (answer) => ({
                  ...answer,
                  data: { text },
                  id: newId,
                }));

                await mutate(async (weeklyReport) => {
                  const achievements = wrap(weeklyReport.achievements);

                  function updateAchievedPlan(plan) {
                    if (String(plan.id) !== String(newId)) return plan;

                    return {
                      ...plan,
                      ...denormalize(response, "weeklyReportAnswers"),
                    };
                  }
                  return {
                    ...weeklyReport,
                    achievements: id
                      ? achievements.map(updateAchievedPlan)
                      : [
                          ...achievements,
                          denormalize(response, "weeklyReportAnswers"),
                        ],
                  };
                }, false);

                onUpdate();

                return newId;
              } catch {
                errorToast("We couldn't update your answer, contact the team.");
              }
            }

            function onChange(text) {
              if (!isEmpty(text) && isLast) addAnswer({});
            }
            return (
              <Answer
                key={answer.__key}
                id={answer.id}
                answerText={answer.id && answer.data.text}
                autoFocus={i === 0}
                lastSavedAt={answer.updatedAt}
                autoSaveProps={{
                  onSave,
                  onChange,
                }}
                handleBlur={({ target }) => {
                  if (isEmpty(target.value.trim()) && !isLast)
                    removeAnswer(answer);
                }}
              />
            );
          })}
        </Stack>
      )}
    </Frame>
  );
}

export default Achievements;
