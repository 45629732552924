const omit = (filtered, obj) =>
  Object.keys(obj).reduce((acc, key) => {
    if (!filtered.includes(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});

const buildRequest = (entity, data, options = {}) => {
  const request = {};
  request.type = entity;
  if (options.relationships) {
    request.attributes = omit(
      options.relationships.map((x) => (x.name || x) + "Id"),
      data,
    );
    request.relationships = {};
    options.relationships.map((rel) => {
      const relatedId = data[(rel.name || rel) + "Id"];
      if (relatedId) {
        request.relationships[rel.name || rel] = {
          data: {
            id: relatedId,
            type: (rel.resource || rel) + "s",
          },
        };
      }
    });
  } else {
    request.attributes = data;
  }
  return { data: request };
};

export default buildRequest;
