import { useCollection, api } from "coreql";
import { format } from "date-fns";

function formatDateToParam(date) {
  return format(date, "yyyy-MM-dd");
}

const resource = "product-assignments";

function getFilters(projects, rangeStart, rangeEnd) {
  const rangeStartParam = formatDateToParam(rangeStart);
  const rangeEndParam = formatDateToParam(rangeEnd);
  return {
    includeEmpty: 1,
    projectId: projects.map((project) => project.id),
    inRange: [rangeStartParam, rangeEndParam],
  };
}

const included = ["assignmentsNote"];
const fields = {
  productAssignments: [
    "assigneeType",
    "assigneeId",
    "periodStart",
    "hours",
    "assignmentsNote",
    "rowId",
    "projectId",
    "projectRoleId",
    "timeAllocation",
  ],
  assignmentsNotes: ["note"],
};

function useProductAssignments(projects, rangeStart, rangeEnd) {
  return useCollection(resource, {
    filters: getFilters(projects, rangeStart, rangeEnd),
    included,
    fields,
  });
}

export function readProductAssignments(projects, rangeStart, rangeEnd) {
  return api.productAssignments.read({
    filters: getFilters(projects, rangeStart, rangeEnd),
    included,
    fields,
  });
}

export function hydrateProductAssignments(
  projects,
  rangeStart,
  rangeEnd,
  data,
) {
  return api.productAssignments.hydrate(data, {
    filters: getFilters(projects, rangeStart, rangeEnd),
    included,
    fields,
  });
}

export default useProductAssignments;
