import React, { useContext, useState } from "react";
import styled from "styled-components";
import { format, isSameDay, parseISO } from "date-fns";
import { Formik } from "formik";
import { v4 as uuid } from "uuid";
import TimelineContext from "./timeline-context";
import { colorPalette } from "../style-guide/colors";
import {
  fonts,
  fontSizes,
  fontWeights,
} from "./../../components/style-guide/typography";
import { TextArea } from "../text-input";
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../buttons/buttons";

const LinkButton = styled.button`
  color: ${colorPalette.pink500};
  font-size: ${fontSizes.smallX}px;
  font-weight: ${fontWeights.bold};
  line-height: 18px;
`;

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h4`
  color: ${colorPalette.bluegrey90};
  font-size: ${fontSizes.small}px;
  font-family: ${fonts.sansSerif};
  font-weight: ${fontWeights.semiBold};
  line-height: 18px;
`;

const PopoverText = styled.p`
  margin-top: 10px;
  width: 292px;
  white-space: pre-wrap;
`;

const WrappButtons = styled.div`
  margin-left: auto;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  button:first-child:not(:last-child) {
    margin-right: 1em !important;
  }
`;

const Form = styled.form`
  margin-top: 10px;
`;

function smallFormatDate(date) {
  return format(date, "MM/dd");
}

function NoteForm({ initialNotes, onSubmit, onCancel }) {
  async function handleSubmit(values, setSubmitting) {
    const success = await onSubmit(values);
    setSubmitting(false);
    if (success) onCancel();
  }

  return (
    <Formik
      initialValues={{ note: initialNotes }}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(values, setSubmitting)
      }
    >
      {({ values, isSubmitting, handleChange, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <TextArea
            name="note"
            placeholder="Add Note"
            className="text-sm"
            value={values.note}
            onChange={handleChange}
          />
          <WrappButtons>
            <TertiaryButton
              text="Cancel"
              disabled={isSubmitting}
              onClick={onCancel}
              small
            />
            <PrimaryButton text="Save Note" disabled={isSubmitting} small />
          </WrappButtons>
        </Form>
      )}
    </Formik>
  );
}

function createSummaries(userAssignments, products, projects) {
  let sumaries = [];
  for (const assignment of userAssignments) {
    const productName =
      products[assignment.attributes.productId].attributes.name;
    const projectName =
      projects[assignment.attributes.projectId].attributes.name;
    const sumary = {
      id: assignment.id,
      productName: productName,
      hours: assignment.attributes.hours,
      projectName: projectName,
    };
    sumaries.push(sumary);
  }
  return sumaries;
}

function OverallocationText({
  allocationLoad,
  assignedUser,
  data,
  headerWeek,
}) {
  if (allocationLoad === "overallocated" && assignedUser !== null) {
    const userAssignments = Object.values(data.productAssignments).filter(
      (assignment) =>
        assignment.attributes.assigneeId !== null &&
        assignment.attributes.assigneeId.toString() === assignedUser.id &&
        isSameDay(parseISO(assignment.attributes.periodStart), headerWeek),
    );

    const assignmentSummaries = createSummaries(
      userAssignments,
      data.products,
      data.projects,
    );

    return (
      <PopoverText as="div">
        {assignedUser.attributes.preferredName} is overallocated this week:
        <ul>
          {assignmentSummaries.map((assignationResume) => (
            <li key={assignationResume.id}>
              - {assignationResume.projectName} |{" "}
              {assignationResume.productName} - {assignationResume.hours} hours
            </li>
          ))}
        </ul>
      </PopoverText>
    );
  }
  return null;
}

function UnassignedPopover({
  assignments,
  assignedUser,
  handleAddNote,
  handleUnassignAssignments,
  handlePartiallyUnassignButtonHover,
  onPartiallyUnassignAssignments,
  editPermission,
  allocationLoad,
  headerWeek,
  projectSeat,
  notes,
}) {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const firstWeek = parseISO(projectSeat.attributes.firstWeek);
  const lastWeek = parseISO(projectSeat.attributes.lastWeek);

  const firstDate = smallFormatDate(firstWeek);
  const lastDate = smallFormatDate(lastWeek);
  const formattedHeaderWeek = smallFormatDate(headerWeek);
  const { data } = useContext(TimelineContext);
  const isFirstAssignment = isSameDay(firstWeek, headerWeek);
  const title = assignedUser ? "Seat Assigned" : "Unassigned Seat";
  const text = assignedUser
    ? `${assignedUser.attributes.preferredName} is assigned from the week of ${firstDate} to the week of ${lastDate}`
    : `This seat is open from the week of ${firstDate} to the week of ${lastDate}`;

  function handleToggleEditMode() {
    setIsInEditMode(!isInEditMode);
  }

  function handleButtonHover() {
    handlePartiallyUnassignButtonHover();
  }

  function handlePartiallyUnassignAssignments() {
    const message = `Are you sure? This will set the hours to 0 for this seat starting on the week of ${formattedHeaderWeek} and create an empty seat with the previous allocation.`;
    const result = confirm(message);
    if (result) {
      const oldAssignments = assignments.filter(
        (el) => el.attributes.periodStart.getTime() >= headerWeek.getTime(),
      );
      const deletedAssignmentsIds = oldAssignments.map((el) => el.id);
      const rowId = uuid();
      const newAssignments = oldAssignments.map((el) => ({
        rowId,
        periodStart: el.attributes.periodStart,
        timeAllocation: el.attributes.timeAllocation,
        projectRoleId: el.attributes.projectRoleId,
        assigneeId: null,
        assigneeType: null,
        projectId: el.attributes.projectId,
        productId: el.attributes.productId,
      }));
      onPartiallyUnassignAssignments(newAssignments, deletedAssignmentsIds);
    }
  }

  return (
    <>
      <SectionTitle>
        <Title>{title}</Title>
        {editPermission && assignedUser && !isFirstAssignment && (
          <LinkButton
            onClick={handlePartiallyUnassignAssignments}
            onMouseEnter={handleButtonHover}
            onMouseLeave={handleButtonHover}
          >
            UNASSIGN FROM {formattedHeaderWeek}
          </LinkButton>
        )}
      </SectionTitle>
      <PopoverText>{text}</PopoverText>
      <OverallocationText
        headerWeek={headerWeek}
        allocationLoad={allocationLoad}
        assignedUser={assignedUser}
        data={data}
      />
      {editPermission &&
        (isInEditMode ? (
          <NoteForm
            initialNotes={notes}
            onCancel={handleToggleEditMode}
            onSubmit={async (values) => {
              setIsInEditMode(false);
              const result = await handleAddNote(values);
              if (!result) {
                setIsInEditMode(true);
              }
              return result;
            }}
          />
        ) : (
          <>
            {notes && <PopoverText>{notes}</PopoverText>}
            <WrappButtons>
              <SecondaryButton
                text={notes ? "Edit Note" : "Add Note"}
                onClick={handleToggleEditMode}
                small
              />
              {assignedUser && (
                <PrimaryButton
                  text="Unassign"
                  onClick={handleUnassignAssignments}
                  small
                />
              )}
            </WrappButtons>
          </>
        ))}
    </>
  );
}

export default UnassignedPopover;
