import React from "react";
import FeedbackRequestForm from "../../feedback/feedback-request-form";
import { useCollection } from "coreql";
import { Stack, Frame, Color, Inline, TextTracking } from "@ableco/baseline";
import {
  TitleSmall,
  BodyBaselineSemibold,
} from "../../design-system/typography-components";
import { FeedbackTipsDrawer } from "../../../content/tips/tips-journal-feedback.js";
import { AnimatePresence } from "framer-motion";
import { TipsButton } from "../../buttons/buttons";
import { wrap } from "../../weekly-report/utils";

export function useFeedbackRequests(weeklyReportUserId, weeklyReportSubmitted) {
  const { data, ...fetchData } = useCollection(
    "feedback-requests",
    {
      filters: {
        withResponse: false,
        receiverId: weeklyReportUserId,
        non_dismissed: true,
      },
      included: [
        "response",
        "response.feedbackPrompt",
        "feedbackPrompt",
        "author",
        "feedbackPrompt.responsibility",
        "sender",
        "sender.role",
        "feedbackRequestDeferral",
      ],
      options: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      },
      fields: {
        users: ["id", "fullName", "preferredName", "avatarUrl", "role"],
        feedbackRequests: [
          "id",
          "body",
          "senderId",
          "response",
          "receiverId",
          "author",
          "sender",
          "feedbackPrompt",
          "feedbackRequestDeferral",
        ],
        feedbackMessages: ["id", "body", "updatedAt", "promptAnswer"],
        feedbackPrompts: [
          "id",
          "promptType",
          "scaleType",
          "prompt",
          "responsibility",
        ],
        responsibilities: ["title"],
        roles: ["title"],
        feedbackRequestDeferrals: ["id", "feedbackRequestId", "deferredAt"],
      },
    },
    "denormalized",
  );
  if (weeklyReportSubmitted) {
    return { data: [] };
  }
  return { data: wrap(data), ...fetchData };
}

function FeedbackRequests({
  dismissedRequestsIds,
  setDismissedRequestsIds,
  weeklyReportUserId,
  weeklyReportDateStart,
}) {
  const { data: feedbackRequests, mutate: refreshFeedbackRequests } =
    useFeedbackRequests(weeklyReportUserId);

  const [showModal, setShowModal] = React.useState(false);
  const handleClose = React.useCallback(
    () => setShowModal(false),
    [setShowModal],
  );

  function handleDismiss(requestId, isDismissed) {
    if (isDismissed) {
      if (dismissedRequestsIds.includes(requestId)) return;
      setDismissedRequestsIds((dismissedRequestIds) => [
        ...dismissedRequestIds,
        requestId,
      ]);
    } else {
      setDismissedRequestsIds((dismissedRequestIds) =>
        dismissedRequestIds.filter((id) => id != requestId),
      );
    }
  }
  return (
    <Frame>
      {feedbackRequests.length > 0 && (
        <Stack space={3}>
          <Inline space={2}>
            <TitleSmall color={Color.Neutral600} tracking={TextTracking.Widest}>
              Help Someone Grow
            </TitleSmall>
            <TipsButton onClick={() => setShowModal(true)} />
            <AnimatePresence>
              {showModal && <FeedbackTipsDrawer onClose={handleClose} />}
            </AnimatePresence>
          </Inline>
          <BodyBaselineSemibold id="requestfeedback">
            Pending Feedback Requests
          </BodyBaselineSemibold>
          <Stack space={6}>
            {feedbackRequests.map((feedbackRequest) => (
              <FeedbackRequestForm
                key={feedbackRequest.id}
                onDismiss={handleDismiss}
                feedbackRequest={feedbackRequest}
                feedbackResponse={feedbackRequest.response}
                refreshData={refreshFeedbackRequests}
                feedbackDismissed={dismissedRequestsIds.includes(
                  feedbackRequest.id,
                )}
                weeklyReportDateStart={weeklyReportDateStart}
                source="WEEKLY JOURNAL"
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Frame>
  );
}

export default FeedbackRequests;
