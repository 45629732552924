import React from "react";
import { sortCriteriaGenerator } from "../utils/sort-criteria";
import CoreLink from "./core-link";
import { reduce } from "lodash";
import {
  Heading,
  BodyBaseline,
  TitleSemibold,
} from "./design-system/typography-components";
import { Color, Stack, Frame, Skeleton, Corners } from "@ableco/baseline";
import { SSRSuspense, useCollection } from "coreql";
import PageLayout from "./layout/page-layout";

const departmentCriteria = sortCriteriaGenerator(
  (item) => item[0].attributes.departmentTitle,
);
const titleCriteria = sortCriteriaGenerator(
  (item) => item.attributes.title || item.attributes.name,
);

function List({ roles, withoutGroups = false }) {
  const listGroup = withoutGroups ? [roles] : roles;
  return listGroup.sort(departmentCriteria).map((group) => (
    <Frame>
      <React.Fragment
        key={group[0].attributes.departmentTitle || group[0].attributes.name}
      >
        {!withoutGroups && (
          <TitleSemibold color={Color.Neutral800} className="pb-1">
            {group[0].attributes.departmentTitle}
          </TitleSemibold>
        )}
        <Stack as="ul" p={[0, 0, 0, 10]}>
          {group.sort(titleCriteria).map((item) => (
            <Frame
              className="list-disc list-inside"
              key={item.id}
              className="list-disc py-1 last:pb-4"
              as="li"
            >
              <CoreLink
                href={`${item.attributes.pageUrl}/responsibilities`}
                className="hover:no-underline"
              >
                <BodyBaseline
                  color={[Color.Primary, Color.PrimaryLight]}
                  className="transition-color duration-300 ease-in-out"
                >
                  {item.attributes.title || item.attributes.name}
                </BodyBaseline>
              </CoreLink>
            </Frame>
          ))}
        </Stack>
      </React.Fragment>
    </Frame>
  ));
}

function Section({ title, description, roles, withoutGroups }) {
  return (
    <Stack space={4}>
      <Stack space={2}>
        <Heading color={Color.Neutral800} as="h2">
          {title}
        </Heading>
        <BodyBaseline color={Color.Neutral600}>{description}</BodyBaseline>
      </Stack>
      <List roles={roles} withoutGroups={withoutGroups} />
    </Stack>
  );
}

function groupRoles(roles) {
  return reduce(
    roles,
    (accumulator, item) => {
      accumulator[item.attributes.departmentTitle] =
        accumulator[item.attributes.departmentTitle] || [];
      accumulator[item.attributes.departmentTitle].push(item);
      return accumulator;
    },
    {},
  );
}

function DepartmentRoles() {
  const {
    data: { data: responseRoles },
  } = useCollection("roles", {
    fields: { roles: ["id", "title", "departmentTitle", "pageUrl"] },
    filters: { active: true },
  });

  return (
    <Section
      title="Department roles"
      description="A role is a collection of responsibilities and corresponding skills
    that is assigned to each team member at Able. We use roles to set
    clear expectations for contribution and performance."
      roles={Object.values(groupRoles(responseRoles))}
    />
  );
}

function ProjectRoles() {
  const {
    data: { data: responseProjectRoles },
  } = useCollection("project-roles", {
    filters: { withResponsibilities: true, active: true },
    fields: { projectRoles: ["id", "name", "pageUrl"] },
  });

  return (
    <Section
      title="Project roles"
      description="Project roles are a set of responsibilities that must be filled on a
  product development project in order for that project to be
  successful. We use project roles to assign project-specific
  responsibilities that extend beyond the responsibilities that exist in
  our standard roles."
      roles={responseProjectRoles}
      withoutGroups={true}
    />
  );
}

function BaseSkeleton(props) {
  return (
    <Skeleton
      color={Color.Neutral400}
      corners={Corners.LargeRounded}
      animated
      {...props}
    />
  );
}

function RolesFallback() {
  return (
    <Stack space={4}>
      <Stack space={3}>
        <BaseSkeleton width="200px" height="25px" />
        <BaseSkeleton width="90%" height="20px" />
        <BaseSkeleton width="50%" height="20px" />
      </Stack>
      <Frame>
        <>
          <BaseSkeleton width="180px" height="25px" />
          <Stack as="ul" p={[4, 0, 0, 10]}>
            {Array.from({ length: 3 })
              .fill(0)
              .map((_, index) => (
                <Frame
                  className="list-disc list-inside"
                  key={index}
                  className="list-disc py-1 last:pb-4"
                  as="li"
                >
                  <BaseSkeleton width="300px" height="20px" />
                </Frame>
              ))}
          </Stack>
        </>
      </Frame>
    </Stack>
  );
}

function Roles() {
  return (
    <PageLayout title="Roles at Able">
      <SSRSuspense fallback={<RolesFallback />}>
        <Stack space={6}>
          <DepartmentRoles />
          <ProjectRoles />
        </Stack>
      </SSRSuspense>
    </PageLayout>
  );
}

export default Roles;
