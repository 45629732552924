import React from "react";
import { ClientError } from "coreql";
import { Frame, Text } from "@ableco/baseline";
import { raise } from "../utils/sentry";
import useCurrentUser from "../hooks/use-current-user";

export default function ErrorFallback({ error }) {
  // get current user for sentry tracking
  const { data: currentUser } = useCurrentUser();

  React.useEffect(() => {
    raise(error, currentUser); // notify sentry
    console.error(error);
    // eslint-disable-next-line no-console
    if (error.data) console.debug(error.data);
  }, [error, currentUser]);

  return (
    <Frame bg="white" corners="medium" p={5} className="w-full mb-4">
      <Text as="p" leading="normal">
        Something unusual happened, please contact the Core team in{" "}
        <Text
          as="a"
          color="primary"
          href="https://app.slack.com/client/T027P7MLU/CGS04KJ82/thread/C04S58U71-1584396024.285300"
        >
          #core
        </Text>{" "}
        to let us know and share the following data:
      </Text>

      {error instanceof ClientError ? (
        error.data.errors.map((err) => (
          <Frame
            border="neutral-600"
            bg="alert"
            p={3}
            className="mt-4"
            key={err.title + err.detail}
          >
            <Text as="h4" size="xl" color="white" leading="normal">
              {err.title} ({err.code})
            </Text>
            <Text as="p" color="white" leading="normal">
              {err.detail}
            </Text>
          </Frame>
        ))
      ) : (
        <Frame border="neutral-600" bg="alert" p={3} className="mt-4">
          <Text as="h4" size="xl" color="white" leading="normal">
            {error.message}
          </Text>
          {process.env.NODE_ENV !== "production" && (
            <Text
              as="p"
              color="white"
              leading="normal"
              wordBreak="all"
              whitespace="pre-wrap"
            >
              {error.stack}
            </Text>
          )}
        </Frame>
      )}
    </Frame>
  );
}
