import React from "react";
import {
  Inline,
  Stack,
  Text,
  Frame,
  Skeleton,
  Touchable,
  useOnClickOutside,
} from "@ableco/baseline";
import { CaretDownOutline, CheckCircleOutline } from "@baseline/icons";
import Menu, { MenuItem } from "../layout/menu";
import { useSortBy, useTable } from "react-table";
import { useCollection, denormalize, SSRSuspense, ErrorBoundary } from "coreql";
import { KeyResultContent } from "../key-result-status-indicator";
import SortHeader from "../sort-header";
import produce from "immer";
import { wrap } from "../weekly-report/utils";
import ObjectiveLayout from "./objective-layout";
import isKeyResultComplete from "../../utils/is-key-result-complete";
import { useHistory } from "react-router";
import { getDepartmentTitle } from "./utils.js";
import { useQuery } from "../../hooks/use-query";
import { AvatarXS } from "../design-system/avatar";

const DEFAULT_FILTER_TITLE = "All Departments";

function buildFilterOptions(departments) {
  return [
    { id: null, title: DEFAULT_FILTER_TITLE },
    ...departments.map((department) => ({
      id: department.id,
      title: department.title,
    })),
  ];
}

function KeyResultsFilter({ departments, onFilter }) {
  const $ref = React.useRef(null);
  const query = useQuery();
  const departmentValue = query.get("departmentId");

  const [open, setOpen] = React.useState(false);
  const title = getDepartmentTitle(departmentValue, departments);

  const closeModal = React.useCallback(() => setOpen(false), [setOpen]);
  const filterOptions = buildFilterOptions(departments);

  useOnClickOutside($ref, closeModal);

  return (
    <Frame className="relative" innerRef={$ref}>
      <Inline>
        <Frame p={[0, 2, 0, 0]}>
          <Text color="neutral-800">Showing:</Text>
        </Frame>
        <Touchable
          className="focus:outline-none"
          onClick={() => setOpen((current) => !current)}
        >
          <Text color="primary" className="hover:text-primary-light">
            {title}
            <CaretDownOutline color="primary" className="ml-2 w-4 h-4 inline" />
          </Text>
        </Touchable>
      </Inline>

      {open && (
        <Menu
          className="absolute right-0 select-none rounded"
          style={{ top: "28px", zIndex: 2, width: 200 }}
        >
          {filterOptions.map((option) => (
            <MenuItem
              key={option.title}
              onClick={() => {
                onFilter(option.id);
                closeModal();
              }}
              textWeight={option.title == title ? "medium" : "normal"}
            >
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Frame>
  );
}

function Table({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
}) {
  return (
    <Frame corners="medium" border="neutral-400" className="w-full">
      <Frame as="table" {...getTableProps()} className="w-full">
        <Frame as="thead">
          {headerGroups.map((headerGroup) => (
            <Frame
              as="tr"
              {...headerGroup.getHeaderGroupProps()}
              bg="neutral-100"
            >
              {headerGroup.headers.map((column) => (
                <Frame
                  as="th"
                  p={[3, 4]}
                  size="sm"
                  className="text-left"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                </Frame>
              ))}
            </Frame>
          ))}
        </Frame>
        <Frame as="tbody" {...getTableBodyProps()}>
          {rows.map((row, index, rows) => {
            prepareRow(row);
            return (
              <Frame as="tr" className="group" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Frame
                    as="td"
                    {...cell.getCellProps()}
                    p={[4, 4]}
                    className="group-hover:text-neutral-800"
                    style={{
                      verticalAlign: "top",
                      borderBottom:
                        rows.length - 1 > index
                          ? "solid 1px rgba(0, 0, 0, 0.15)"
                          : "",
                    }}
                  >
                    {cell.render("Cell")}
                  </Frame>
                ))}
              </Frame>
            );
          })}
        </Frame>
      </Frame>
    </Frame>
  );
}

function keyResultUrl(departmentId) {
  return departmentId && `?departmentId=${departmentId}`;
}

function useKeyResults(departmentId = null) {
  const { data: rawData } = useCollection("key-results", {
    fields: {
      objectives: ["title", "owner", "department"],
      users: ["fullName", "avatarUrl"],
      departments: ["title"],
      keyResultStatusUpdates: ["status"],
    },
    included: [
      "objective",
      "objective.owner",
      "objective.department",
      "currentStatusUpdate",
    ],
    filters: departmentId
      ? { department: departmentId, active: true }
      : { active: true },
  });
  const results = wrap(denormalize(rawData, "keyResults"));
  return produce(results, (draft) => {
    for (const item of draft) {
      item.status = item.currentStatusUpdate?.status || item.start;
      item.completed = isKeyResultComplete(
        item.decreasing,
        item.status,
        item.target,
      );
    }
  });
}

function useDepartments() {
  const { data: departmentsRawData } = useCollection("objectives", {
    filters: { active: true },
    fields: { objectives: ["id"] },
    included: ["department"],
  });
  const departments = denormalize(departmentsRawData, "departments");

  return [...wrap(departments)].sort((a, b) => a.title.localeCompare(b.title));
}

function RenderTable({ keyResults }) {
  const keyResultsIds = keyResults.map((keyResult) => keyResult.id).join("");
  const data = React.useMemo(
    () =>
      keyResults.map((kr) => ({
        dueDate: kr.dueDate,
        title: (
          <Stack>
            <Text color="neutral-800" leading="normal">
              {kr.title}
            </Text>
            <Frame as="div" p={[1, 0, 0, 0]}>
              <Text as="p" size="xs" color="neutral-600" leading="normal">
                {kr.objective.title}
              </Text>
            </Frame>
          </Stack>
        ),
        owner: {
          avatarUrl: kr.objective.owner.avatarUrl,
          name: kr.objective.owner.fullName,
        },
        department: kr.objective.department.title,
        completion: (
          <Text
            size="sm"
            color="neutral-600"
            whitespace="no-wrap"
            className="group-hover:text-neutral-800"
          >
            <KeyResultContent
              kind={kr.kind}
              status={kr.status}
              target={kr.target}
            />
          </Text>
        ),
        status: kr.completed ? "Completed" : "Not Complete",
      })),
    // using 'keyResultsIds' as dependency for useMemo, so 'keyResults' is not needed for now
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keyResultsIds],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: (props) => (
          <Frame p={[0, 0, 0, 2]}>
            <SortHeader column={props.column} header={"Due date"} />
          </Frame>
        ),
        id: "dueDate",
        accessor: "dueDate",
        Cell: (props) => (
          <Frame p={[0, 0, 0, 2]}>
            <Text
              color="neutral-600"
              size="sm"
              whitespace="no-wrap"
              className="group-hover:text-neutral-800"
            >
              {new Intl.DateTimeFormat("en-US", {
                month: "short",
                day: "2-digit",
                timeZone: "UTC",
              }).format(new Date(props.value))}
            </Text>
          </Frame>
        ),
      },
      {
        Header: (
          <Text size="sm" color="neutral-600" leading="normal">
            Key Result
          </Text>
        ),
        accessor: "title",
        id: "title",
        disableSortBy: true,
      },
      {
        Header: (props) => (
          <SortHeader column={props.column} header={"Owner"} />
        ),
        accessor: (d) => d.owner.name,
        id: "owner",
        Cell: (props) => (
          <Stack alignment="center">
            <AvatarXS
              name={props.value}
              url={props.row.original.owner.avatarUrl}
              withTooltip
            />
          </Stack>
        ),
      },
      {
        Header: (props) => (
          <SortHeader column={props.column} header={"Department"} />
        ),
        accessor: "department",
        id: "department",
        Cell: (props) => (
          <Text
            color="neutral-600"
            size="sm"
            whitespace="no-wrap"
            className="group-hover:text-neutral-800"
          >
            {props.value}
          </Text>
        ),
      },
      {
        Header: (
          <Text size="sm" color="neutral-600" leading="normal">
            Completion
          </Text>
        ),
        accessor: "completion",
        id: "completion",
        disableSortBy: true,
      },
      {
        Header: (props) => (
          <Frame p={[0, 2, 0, 0]}>
            <SortHeader column={props.column} header={"Status"} />
          </Frame>
        ),
        accessor: "status",
        id: "status",
        Cell: (props) =>
          props.value === "Completed" ? (
            <Frame p={[0, 2, 0, 0]}>
              <Text color="success" className="block text-center">
                <CheckCircleOutline className="h-4 w-4 inline-block" />
              </Text>
            </Frame>
          ) : (
            <Frame bg="transparent" border="transparent" className="h-4 w-4" />
          ),
      },
    ],
    [],
  );

  const tableProps = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: "dueDate", desc: false }] },
      disableSortRemove: true,
    },
    useSortBy,
  );

  return <Table {...tableProps} />;
}

export default function KeyResults() {
  return (
    <ObjectiveLayout refSelected="/objectives/key-results">
      <ErrorBoundary fallback={ErrorFallback}>
        <SSRSuspense fallback={<Fallback />}>
          <KeyResultsTableContent />
        </SSRSuspense>
      </ErrorBoundary>
    </ObjectiveLayout>
  );
}

function KeyResultsTableContent() {
  const history = useHistory();
  const query = useQuery();

  const departmentId = query.get("departmentId");
  const results = useKeyResults(departmentId);

  const departments = useDepartments();

  return (
    <Frame p={[0, 10, 0, 10]}>
      <Inline distribution="between">
        <Inline space={4}>
          <Text color="neutral-800">{results.length} key results:</Text>
          <Inline space={6}>
            <Inline space={2}>
              <Text color="success">
                <CheckCircleOutline className="h-4 w-4" />
              </Text>
              <Text color="neutral-800">
                {results.filter((objective) => objective.completed).length}{" "}
                completed
              </Text>
            </Inline>
            <Text color="neutral-800">
              {results.filter((objective) => !objective.completed).length}{" "}
              active
            </Text>
          </Inline>
        </Inline>
        <KeyResultsFilter
          onFilter={(departmentId) => {
            history.push({ search: keyResultUrl(departmentId) });
          }}
          departments={departments}
        />
      </Inline>
      <Frame p={[4, 0, 0, 0]}>
        <RenderTable keyResults={results} />
      </Frame>
    </Frame>
  );
}

export function Fallback() {
  return (
    <Stack bg="white" corners="small" className="w-full" p={[0, 0, 0, 10]}>
      <Inline as="nav">
        {Array.from({ length: 4 }, (_, index) => (
          <Skeleton
            key={index}
            height={20}
            width={100}
            className="mr-2"
            alt="Profile link"
            color="neutral-200"
          />
        ))}
      </Inline>
    </Stack>
  );
}

function Legend({ children }) {
  return (
    <Frame p={[8, 3, 6, 3]}>
      <Text className="text-neutral-800" size="sm">
        {children}
      </Text>
    </Frame>
  );
}

function ErrorFallback({ error }) {
  return <Legend>{error.message}</Legend>;
}
