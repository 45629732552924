// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { values } from "lodash";
import { format, isBefore, isEqual, parseISO } from "date-fns";
// Constants
import AppTypes from "../../../core-redux/constants/AppTypes";
// Actions
import * as API from "../../../core-redux/actions/api";
// Components
import Table from "./Table";

class TableContainer extends PureComponent {
  static propTypes = {
    fetchPlaceholders: PropTypes.func.isRequired,
    fetchProductAllocations: PropTypes.func.isRequired,
    fetchProductAssignments: PropTypes.func.isRequired,
    fetchProductInvestments: PropTypes.func.isRequired,
    fetchProductRevenues: PropTypes.func.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    fetchRoles: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    periods: PropTypes.arrayOf(AppTypes.period).isRequired,
    products: PropTypes.arrayOf(AppTypes.product).isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.bootstrapData().then(() => this.fetchData());
  }

  componentDidUpdate(prevProps) {
    const dateFilterChanged =
      !isEqual(prevProps.startDate, this.props.startDate) ||
      !isEqual(prevProps.endDate, this.props.endDate);

    if (dateFilterChanged) {
      this.fetchData();
    }
  }

  bootstrapData() {
    const { fetchPlaceholders, fetchProducts, fetchRoles, fetchUsers } =
      this.props;

    return Promise.all([
      fetchPlaceholders(),
      fetchProducts(),
      fetchRoles(),
      fetchUsers(),
    ]);
  }

  fetchData() {
    const {
      fetchProductAllocations,
      fetchProductAssignments,
      fetchProductInvestments,
      fetchProductRevenues,
    } = this.props;

    this.setState({ isLoading: true });

    Promise.all([
      fetchProductAllocations(),
      fetchProductAssignments(),
      fetchProductInvestments(),
      fetchProductRevenues(),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => {});
  }

  render() {
    const { periods, products } = this.props;
    const { isLoading } = this.state;

    return (
      <Table isLoading={isLoading} periods={periods} products={products} />
    );
  }
}

function mapStateToProps(state) {
  const { periods } = state;
  const { endDate, startDate } = state.ui.filters;
  const products = values(state.entities.products.byId).filter(
    (product) =>
      isBefore(parseISO(product.activatedAt), endDate) &&
      (!product.archivedAt ||
        isBefore(startDate, parseISO(product.archivedAt))),
  );

  return {
    endDate,
    periods,
    products,
    startDate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPlaceholders: async () =>
      await dispatch(API.list("placeholders", { includeArchived: true })),

    fetchProductAllocations: async () =>
      await dispatch((dispatch, getState) => {
        const { endDate, startDate } = getState().ui.filters;

        return dispatch(
          API.list("productAllocations", {
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
          }),
        );
      }),

    fetchProductAssignments: async () =>
      await dispatch((dispatch, getState) => {
        const { endDate, startDate } = getState().ui.filters;

        return dispatch(
          API.list("productAssignments", {
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
          }),
        );
      }),

    fetchProductInvestments: async () =>
      await dispatch((dispatch, getState) => {
        const { endDate, startDate } = getState().ui.filters;

        return dispatch(
          API.list("productInvestments", {
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
          }),
        );
      }),

    fetchProductRevenues: async () =>
      await dispatch((dispatch, getState) => {
        const { endDate, startDate } = getState().ui.filters;

        return dispatch(
          API.list("productRevenues", {
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
          }),
        );
      }),

    fetchProducts: async () =>
      await dispatch(API.list("products", { includeArchived: true })),

    fetchRoles: async () => await dispatch(API.list("roles")),

    fetchUsers: async () =>
      await dispatch(API.list("users", { includeArchived: true })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableContainer);
