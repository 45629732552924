/* eslint-disable jsx-a11y/no-autofocus */
import React from "react";
import { isEmpty, noop } from "lodash";
import { wrap } from "./utils";
import { api as apiCoreQl, denormalize, useResource } from "coreql";
import { MarkdownEditor } from "../markdown/markdown-editor";
import { Frame, Color, Inline, Stack, Touchable } from "@ableco/baseline";
import { BodySmall, Title } from "../design-system/typography-components";
import { useAnswersGroup } from "./accomplishments";
import AutosaveTextInput, {
  LoadingStateIcon,
} from "../data-entry/autosave-text-input";
import { Locked, Unlocked } from "@baseline/icons";
import RatingScaleQuestion from "../rating-scale-question";
import clsx from "clsx";
import { useCoreToast } from "../toast/toast";
import { MarkdownViewer } from "../markdown/markdown-viewer";
import { CoreTooltip } from "../core-tooltip";

export function useWeeklyReportQuestions(weeklyReportId) {
  return useResource(
    "weekly-reports",
    weeklyReportId,
    {
      included: ["user", "user.manager"],
      fields: {
        users: ["id", "fullName", "avatarUrl", "managerId", "manager"],
        "weekly-reports": ["user", "questions"],
      },
    },
    "denormalized",
  );
}

function QuestionsSection({ weeklyReportId, setSavedAt }) {
  const { data: weeklyReportQuestions, mutate } =
    useWeeklyReportQuestions(weeklyReportId);
  const questions = wrap(weeklyReportQuestions.questions);
  const reporteeManager = weeklyReportQuestions.user.manager;
  if (isEmpty(questions)) return null;
  function handleUpdate() {
    setSavedAt(new Date());
  }
  return (
    <Stack as="section" className="mt-12" space={6}>
      <Title as="h2">Questions</Title>
      {questions.map(
        ({
          text,
          answers,
          additionalInformation,
          optional,
          questionId: id,
          ...question
        }) =>
          question.isRatingScale ? (
            <RatingScaleQuestion
              key={id}
              id={id}
              answers={answers}
              text={text}
              ratingScale={question.ratingScale}
              additionalInformation={additionalInformation}
              optional={optional}
              mutate={mutate}
              weeklyReportId={weeklyReportId}
              onUpdate={handleUpdate}
            />
          ) : (
            <SurveyQuestion
              key={id}
              id={id}
              answers={answers}
              text={text}
              reporteeManager={reporteeManager}
              optional={optional}
              mutate={mutate}
              weeklyReportId={weeklyReportId}
              onUpdate={handleUpdate}
            />
          ),
      )}
    </Stack>
  );
}

function SurveyQuestion({
  id: questionId,
  text,
  answers,
  optional,
  mutate,
  reporteeManager,
  onUpdate = noop,
  weeklyReportId,
}) {
  const hasManager = !isEmpty(reporteeManager);
  const {
    answers: savedAnswers,
    updateAnswer,
    removeAnswer,
    addAnswer,
  } = useAnswersGroup(answers);

  const { errorToast } = useCoreToast();

  // this is just a shortcut since we're only interested in mutating
  // the answers from the response
  function mutateAnswersData(
    updateFn = (answers) => answers,
    revalidate = false,
  ) {
    return mutate(async (weeklyReport) => {
      const questions = wrap(weeklyReport.questions).map((question) => {
        if (question.questionId !== questionId) return question;

        return { ...question, answers: updateFn(question.answers) };
      });

      return { ...weeklyReport, questions };
    }, revalidate);
  }

  return (
    <Stack role="group" aria-label={text}>
      <Inline className="mb-2 text-neutral-800 core-break-words">
        <MarkdownViewer value={`### ` + text} />
        {optional && (
          <BodySmall className="ml-1" color={Color.Neutral600}>
            (Optional)
          </BodySmall>
        )}
      </Inline>
      <Stack space={2}>
        {savedAnswers.map((answer, i) => {
          const isLast = i === savedAnswers.length - 1;
          const currentKey = answer.__key;

          async function onSave(id, text) {
            // if text is empty
            if (isEmpty(text.trim())) {
              try {
                if (id) {
                  await apiCoreQl.weeklyReportAnswers.find(id).destroy();
                  updateAnswer(answer, () => ({ __key: currentKey }));
                  await mutateAnswersData(
                    (answers) =>
                      answers.filter(
                        (answer) => String(answer.id) !== String(id),
                      ),
                    false,
                  );
                }

                onUpdate();
              } catch (error) {
                errorToast(
                  "We could not delete your answer, contact the team.",
                );
                throw error; // pass the error back to autosave to update save status
              }
              // unset id on autosave hook
              return;
            }

            // update or create
            try {
              const response = await apiCoreQl.weeklyReportAnswers
                .find(id)
                .upsert(
                  { data: { text }, locked: !!answer.locked },
                  {
                    answerableId: questionId,
                    answerableType: "WeeklyReportQuestion",
                    answerType: "question",
                    weeklyReportId,
                  },
                  [{ name: "weeklyReport", resource: "weekly-report" }],
                );

              const { id: newId } = response.data;

              updateAnswer(answer, (answer) => ({
                ...answer,
                data: { text },
                id: newId,
              }));

              await mutateAnswersData((answers) => {
                if (id) {
                  return answers.map((answer) => {
                    if (String(answer.id) !== String(id)) return answer;
                    return {
                      ...answer,
                      ...denormalize(response, "weeklyReportAnswers"),
                    };
                  });
                }

                return [
                  ...answers,
                  denormalize(response, "weeklyReportAnswers"),
                ];
              }, false);

              onUpdate();
              return newId;
            } catch (error) {
              errorToast("We couldn't update your answer, contact the team.");
              throw error; // pass the error back to autosave to update save status
            }
          }

          function onChange(text) {
            if (!isEmpty(text) && isLast) addAnswer({});
          }
          return (
            <SurveyAnswer
              key={answer.__key}
              id={answer.id}
              answerText={answer.id && answer.data.text}
              autoFocus={false}
              lastSavedAt={answer.updatedAt}
              autoSaveProps={{
                onSave,
                onChange,
              }}
              isPrivate={answer.locked}
              onSetAsPrivate={async (locked) => {
                await apiCoreQl.weeklyReportAnswers
                  .find(answer.id)
                  .update({ locked });
                updateAnswer(answer, (answer) => ({ ...answer, locked }));
              }}
              handleBlur={({ target }) => {
                if (isEmpty(target.value.trim()) && !isLast)
                  removeAnswer(answer);
              }}
              managerName={hasManager && reporteeManager.fullName}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

export function SurveyAnswer({
  id,
  answerText,
  autoFocus,
  autoSaveProps,
  handleBlur = noop,
  managerName,
  onSetAsPrivate = noop,
  isPrivate,
}) {
  const publicLabel = "Make visible to everyone who can read this journal";
  const privateLabel = `Make visible to ${managerName} only`;
  const canBePrivate = !isEmpty(managerName);

  function handleToggle(event) {
    event.stopPropagation();
    onSetAsPrivate(!isPrivate);
  }

  return (
    <AutosaveTextInput
      value={answerText}
      resourceId={id}
      getSaveStatus={LoadingStateIcon}
      {...autoSaveProps}
    >
      {(text, { onChange }, statusText) => (
        <Stack
          role="group"
          data-testid={`answer-${id}`}
          data-isprivate={isPrivate}
        >
          <Inline distribution="center" className="relative">
            <MarkdownEditor
              placeholder="Add answer"
              text={text}
              aria-label="Add answer"
              onChange={onChange}
              iconContainerCls="absolute bottom-0 right-0 mr-2 my-3"
              autoFocus={autoFocus}
              onBlur={handleBlur}
              icons={
                <Lock
                  open={!isPrivate}
                  label={isPrivate ? publicLabel : privateLabel}
                  onToggle={handleToggle}
                  visible={canBePrivate && id}
                />
              }
            />
            <Frame className="absolute left-100 bottom-0 mb-2 ml-2">
              {statusText}
            </Frame>
          </Inline>
        </Stack>
      )}
    </AutosaveTextInput>
  );
}

function Lock({ open, label, onToggle, visible }) {
  // this is here as a temporary fix to a bug with the tooltips
  function LockTooltip() {
    return (
      <CoreTooltip label={label}>
        <Touchable
          data-testid="toggle-privacy-btn"
          aria-label={open ? "Set as private" : "Set as public"}
          className="opacity-45 hover:opacity-100 focus:opacity-100 transition-opacity duration-300"
          disabled={!visible}
          onClick={onToggle}
        >
          <Stack>
            {open ? (
              <Unlocked
                className="h-4 w-4"
                data-testid="answer-unlocked-icon"
              />
            ) : (
              <Locked className="h-4 w-4" data-testid="answer-locked-icon" />
            )}
          </Stack>
        </Touchable>
      </CoreTooltip>
    );
  }

  if (!visible) return null;
  return (
    <Stack
      className={clsx(
        "transition-opacity duration-300 ease-in-out leading-none",
        {
          "opacity-0": !visible,
        },
      )}
    >
      <LockTooltip />
    </Stack>
  );
}

export default QuestionsSection;
