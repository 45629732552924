/* eslint-disable jsx-a11y/no-autofocus */
import React, { useMemo, useState } from "react";
import { api, denormalize } from "coreql";
import { MarkdownEditor } from "./markdown/markdown-editor";
import { noop } from "lodash";
import { wrap } from "./weekly-report/utils";
import { SegmentedControl } from "./data-entry/segmented-control";
import { Stack, Inline, Color, Frame } from "@ableco/baseline";
import {
  BodySmall,
  BodySmallSemibold,
} from "./design-system/typography-components";
import { useCoreToast } from "./toast/toast";
import { MarkdownViewer } from "./markdown/markdown-viewer";
import { useQueuedCallback } from "../hooks/use-queued-callback";
import AutosaveTextInput, {
  LoadingStateIcon,
} from "./data-entry/autosave-text-input";

function RatingScaleQuestion({
  id: questionId,
  answers,
  text,
  mutate,
  ratingScale,
  optional,
  weeklyReportId,
  additionalInformation,
  onUpdate = noop,
  onSave = noop,
}) {
  const hasAnswer = answers.length > 0;
  const enterAdditionalInformation =
    additionalInformation === "no_additional_information";
  const [answerId, setAnswerId] = useState(answers[0]?.id);
  const [additionalInformationText, setAdditionalInformationText] =
    React.useState(answers[0]?.data.additionalInformationText || "");
  const [answerRate, setAnswerRate] = React.useState(
    answers[0]?.data.text || "",
  );
  const { errorToast } = useCoreToast();

  async function handleSave(data) {
    try {
      const inputText = data.text;
      if (inputText.trim()) {
        const response = await api.weeklyReportAnswers.find(answerId).upsert(
          {
            data: {
              additionalInformationText: inputText.trim(),
              text: answerRate,
            },
          },
          {
            answerType: "question",
            answerableType: "WeeklyReportQuestion",
            answerableId: questionId,
            weeklyReportId,
          },
          [{ name: "weeklyReport", resource: "weekly-report" }],
        );

        await mutate(async (weeklyReport) => {
          const questions = wrap(weeklyReport.questions).map((question) => {
            if (question.questionId !== questionId) return question;

            return {
              ...question,
              answers: [denormalize(response, "weeklyReportAnswers")],
            };
          });
          return { ...weeklyReport, questions };
        }, false);
        onSave();
      } else {
        const response = await api.weeklyReportAnswers.find(answerId).upsert(
          {
            data: {
              text: answerRate,
            },
          },
          {
            answerType: "question",
            answerableType: "WeeklyReportQuestion",
            answerableId: questionId,
            weeklyReportId,
          },
          [{ name: "weeklyReport", resource: "weekly-report" }],
        );

        await mutate(async (weeklyReport) => {
          const questions = wrap(weeklyReport.questions).map((question) => {
            if (question.questionId !== questionId) return question;

            return {
              ...question,
              answers: [denormalize(response, "weeklyReportAnswers")],
            };
          });
          return { ...weeklyReport, questions };
        }, false);
        onSave();
      }
    } catch {
      errorToast(
        "We couldn't add the additional information, contact the team.",
      );
    }
  }

  const upsertRating = useQueuedCallback(async (value) => {
    try {
      const response = await api.weeklyReportAnswers.find(answerId).upsert(
        {
          data: {
            text: String(value),
            additionalInformationText: additionalInformationText,
          },
        },
        {
          answerType: "question",
          answerableType: "WeeklyReportQuestion",
          answerableId: questionId,
          weeklyReportId,
        },
        [{ name: "weeklyReport", resource: "weekly-report" }],
      );
      setAnswerRate(String(value));
      setAnswerId(response.data.id);

      await mutate(async (weeklyReport) => {
        const questions = wrap(weeklyReport.questions).map((question) => {
          if (question.questionId !== questionId) return question;
          return {
            ...question,
            answers: [denormalize(response, "weeklyReportAnswers")],
          };
        });
        return { ...weeklyReport, questions };
      }, false);

      onUpdate();
    } catch {
      errorToast("We couldn't update your answer, contact the team.");
    }
  });

  function handleRatingChange(value) {
    upsertRating(value);
  }

  const segmentedCtrlValues = useMemo(
    () => [
      { extLabel: ratingScale[1], value: 1 },
      { extLabel: null, value: 2 },
      { extLabel: null, value: 3 },
      { extLabel: null, value: 4 },
      { extLabel: ratingScale[5], value: 5 },
    ],
    [ratingScale],
  );

  return (
    <Stack role="group" aria-label={text} space={2}>
      <Inline className="mb-2 text-neutral-800 core-break-words">
        <MarkdownViewer value={`### ` + text} />
        {optional && (
          <BodySmall className="ml-1" color="neutral-700">
            (Optional)
          </BodySmall>
        )}
      </Inline>
      <SegmentedControl
        name={`question-${questionId}`}
        defaultValue={hasAnswer && Number(answerRate)}
        values={segmentedCtrlValues}
        onChange={handleRatingChange}
      />
      {!enterAdditionalInformation && (
        <Stack>
          <Inline className="mb-2 text-neutral-800 core-break-words">
            <BodySmallSemibold color={Color.Neutral800}>
              Anything to add?
            </BodySmallSemibold>
            {additionalInformation === "optional" && (
              <BodySmall className="ml-1" color={Color.Neutral600}>
                (Optional)
              </BodySmall>
            )}
          </Inline>
          <AutosaveTextInput
            value={additionalInformationText}
            onSave={(_, text) => handleSave({ text })}
            onChange={(text) => {
              setAdditionalInformationText(text), onUpdate();
            }}
            getSaveStatus={LoadingStateIcon}
          >
            {(text, autoSaveProps, status) => (
              <Frame className="relative">
                <MarkdownEditor
                  aria-label="Additional information"
                  {...autoSaveProps}
                  text={text}
                />
                <Frame className="absolute left-100 bottom-0 mb-2 ml-2">
                  {status}
                </Frame>
              </Frame>
            )}
          </AutosaveTextInput>
        </Stack>
      )}
    </Stack>
  );
}

export default RatingScaleQuestion;
