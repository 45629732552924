import React from "react";
import {
  Color,
  Corners,
  Flex,
  FlexAlignment,
  Frame,
  Inline,
  Touchable,
  Stack,
} from "@ableco/baseline";
import { PrimaryButton, SecondaryButton } from "../buttons/buttons";
import { Add } from "@baseline/icons";
import {
  BodyBaseline,
  TitleSemibold,
} from "../design-system/typography-components";
import Select from "../data-entry/select";
import { motion } from "framer-motion";
import { useCollection, api } from "coreql";
import { addDays, eachDayOfInterval, parseISO } from "date-fns";
import { v4 as uuid } from "uuid";
import { useProductAssignments, useTimeEntries } from "./weekly-report-form";

const timeAllocationOptions = Array.from({ length: 20 })
  .fill()
  .map((_, i) => {
    const value = (i + 1) * 5;
    return { label: value + "%", value: value.toString() };
  });

function SelectField({ id, label, placeholder = "Select...", ...props }) {
  return (
    <Stack className="w-2/4 ml-4 first:m-0">
      <BodyBaseline
        color={Color.Neutral800}
        as="label"
        className="mb-2 capitalize"
      >
        {label}
        <Select
          testId={`select-${id}`}
          id={id}
          placeholder={placeholder}
          {...props}
        />
      </BodyBaseline>
    </Stack>
  );
}

function AddProjectForm({ assigneeId, weeklyReportId, weeklyReportDateStart }) {
  const [showFormState, setShowFormState] = React.useState(false);
  const [product, setProduct] = React.useState("");
  const [projectRole, setProjectRole] = React.useState("");
  const [allocation, setAllocation] = React.useState("");
  const [project, setProject] = React.useState("");
  const { mutate: weeklyTimeEntriesMutate } = useTimeEntries(weeklyReportId);
  const { mutate: productsAssignmentsMutate } = useProductAssignments(
    assigneeId,
    weeklyReportDateStart,
  );

  const { data: projectsData } = useCollection(
    "projects",
    { filters: { active: true }, sort: ["name"] },
    "denormalized",
  );
  const projects = React.useMemo(
    () =>
      projectsData.map((project) => ({
        productId: project.productId,
        value: project.id,
        label: project.name,
      })),
    [projectsData],
  );

  const { data: productsData } = useCollection(
    "products",
    { filters: { active: true }, sort: ["name"] },
    "denormalized",
  );

  const products = React.useMemo(
    () =>
      productsData.map((product) => ({
        value: product.id,
        label: product.name,
        projects: projects.filter(
          (project) => String(project.productId) === product.id,
        ),
      })),
    [productsData, projects],
  );

  const { data: projectRolesData } = useCollection(
    "project-roles",
    { filters: { active: true }, sort: ["name"] },
    "denormalized",
  );

  const projectRoles = React.useMemo(
    () =>
      projectRolesData.map((projectRole) => ({
        value: projectRole.id,
        label: projectRole.name,
      })),
    [projectRolesData],
  );

  function showForm() {
    setShowFormState(true);
  }

  function hideForm(event) {
    event.preventDefault();
    setShowFormState(false);
  }

  function onChangeProduct(value) {
    setProduct(value);
    setProject("");
  }

  function onChangeProject(value) {
    setProject(value);
  }

  function onChangeProjectRole(value) {
    setProjectRole(value);
  }

  function onChangeAllocation(value) {
    setAllocation(value);
  }

  function getProjectOptionsForProduct(product) {
    return (
      products.find((el) => String(el.value) === String(product))?.projects ||
      []
    );
  }

  function addProject(event) {
    event.preventDefault();
    onSubmit({
      projectId: project,
      productId: product,
      projectRoleId: projectRole,
      timeAllocation: allocation,
    });
    hideForm(event);
  }

  async function createEntriesForProductAssignment({
    projectId,
    weeklyReportId,
    projectRoleId,
    periodStart,
  }) {
    const start = parseISO(periodStart);

    const datesInPeriod = eachDayOfInterval({
      start: start,
      end: addDays(start, 6),
    });

    await Promise.all(
      datesInPeriod.map((day) =>
        api.timeEntries.create({
          projectId,
          projectRoleId,
          weeklyReportId,
          date: day,
        }),
      ),
    );
    await weeklyTimeEntriesMutate();
  }

  async function onSubmit(data) {
    const { projectId, productId, projectRoleId, timeAllocation } = data;

    await createEntriesForProductAssignment({
      projectId,
      weeklyReportId,
      projectRoleId,
      periodStart: weeklyReportDateStart,
    });

    await api.productAssignments.create({
      assigneeId: assigneeId,
      assigneeType: "User",
      projectId,
      productId,
      projectRoleId,
      timeAllocation,
      periodStart: weeklyReportDateStart,
      rowId: uuid(),
    });

    await productsAssignmentsMutate();
  }

  return (
    <div>
      {!showFormState && (
        <Touchable onClick={showForm} p={[2, 4]} className="group">
          <Inline space={2}>
            <Add className="text-primary-base group-hover:text-primary-light transition-colors duration-300 ease-in-out" />
            <BodyBaseline
              as="h3"
              color={Color.Primary}
              className="group-hover:text-primary-light transition-colors duration-300 ease-in-out"
            >
              Add another project
            </BodyBaseline>
          </Inline>
        </Touchable>
      )}
      {showFormState && (
        <Frame
          as={motion.div}
          aria-label="Add product assignment form"
          bg={Color.Neutral100}
          border={Color.Neutral300}
          p={[2, 6]}
          corners={Corners.MediumRounded}
          initial="closed"
          animate={"open"}
          variants={{
            open: { opacity: 1, height: "auto" },
            closed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <Flex alignment={FlexAlignment.Center} style={{ minHeight: "56px" }}>
            <TitleSemibold
              className="text-left m-0 p-0"
              color={Color.Neutral800}
            >
              Add a missing product
            </TitleSemibold>
          </Flex>
          <Frame p={[0, 4, 4]}>
            <Flex className="mb-5">
              <SelectField
                id="product_assignment-product_id"
                label="Which product?"
                options={products}
                value={product}
                onChange={onChangeProduct}
              />
              <SelectField
                id="product_assignment-project_id"
                label="Which project?"
                options={getProjectOptionsForProduct(product)}
                value={project}
                onChange={onChangeProject}
                disabled={!product}
              />
            </Flex>
            <Flex className="mb-5">
              <SelectField
                id="product_assignment-project_role_id"
                label="What's your role on the product?"
                options={projectRoles}
                value={projectRole}
                onChange={onChangeProjectRole}
              />
              <SelectField
                id="product_assignment-time_allocation"
                label="How much of your week?"
                options={timeAllocationOptions}
                value={allocation}
                onChange={onChangeAllocation}
              />
            </Flex>
            <Flex className="mt-5 justify-end">
              <Inline space={4}>
                <SecondaryButton onClick={hideForm} text="Cancel" />
                <PrimaryButton
                  aria-label="Add product button"
                  disabled={!(product && project && projectRole && allocation)}
                  onClick={addProject}
                  text="Add Product"
                />
              </Inline>
            </Flex>
          </Frame>
        </Frame>
      )}
    </div>
  );
}

export default AddProjectForm;
