import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { addWeeks, format, startOfWeek, isSameDay } from "date-fns";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";

import ProjectTimeline from "./project-timeline";
import BenchTimeline from "./bench-timeline";
import ProjectSectionTitle from "./project-section-title";
import TimelineContext from "./timeline-context";

import getMondays from "./../../utils/get-mondays";

import { colorPalette } from "./../../components/style-guide/colors";
import {
  fontSizes,
  fontWeights,
} from "./../../components/style-guide/typography";
import ArrowRight from "../icons/arrow-right";
import ArrowLeft from "../icons/arrow-left";

import useProductAssignments, {
  hydrateProductAssignments,
  readProductAssignments,
} from "./data-hooks/use-product-assignments";
import useProjectSeats, {
  readProjectSeats,
} from "./data-hooks/use-project-seats";
import CoreLink from "../core-link";
import { PrimaryButton } from "../buttons/buttons";
import useRoleExtensions from "./data-hooks/use-role-extensions";
import useUsers from "./data-hooks/use-users";
import useProjects from "./data-hooks/use-projects";
import useProjectRoles from "./data-hooks/use-project-roles";

const TOTAL_WEEKS = 12;

const Header = styled.div`
  color: ${colorPalette.deepBlue500};
  font-size: ${fontSizes.small}px;
  font-weight: ${fontWeights.bold};
  display: flex;
  align-items: flex-end;
  min-width: 900px;
  padding: 19px 0;
  position: sticky;
  top: 0;
  background-color: ${colorPalette.bluegrey10};
  border-bottom: 1px solid #eceef2;
  z-index: 5;
`;

const HeaderButtonsWrapper = styled.div`
  display: flex;
  flex: 0 0 420px;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Dates = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
  flex: 1 1 0;
`;

const Week = styled.span`
  flex: 1 0 40px;
  margin-left: 4px;
  line-height: 24px;
  text-align: center;
  font-weight: 400;
`;

const WeekBackground = styled.span`
  ${({ currentWeek }) =>
    currentWeek &&
    `
      padding: 4px;
      background-color: ${colorPalette.pink900};
      border-radius: 4px;
      font-weight: 700;
      color: ${colorPalette.white};
    `}
`;

const WrapperPage = styled.div`
  background-color: ${colorPalette.bluegrey10};
  padding: 0 100px;
  display: flex;
  justify-content: center;
`;
const Container = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-right: auto;
`;

const MoveOneWeekForwardButton = styled.button.attrs(() => ({
  title: "Move One Week Forward",
}))`
  border: none;
  width: 16px;
  background: none;
  display: flex;
`;

const MoveOneWeekBackwardButton = styled.button.attrs(() => ({
  title: "Move One Week Backward",
}))`
  border: none;
  font-size: 1em;
  width: 16px;
  background: none;
  display: flex;
`;

const ResetToCurrentWeekButton = styled.button`
  border: none;
  color: ${colorPalette.pink500};
  font-weight: ${fontWeights.bold};
  &:hover {
    color: ${colorPalette.pink200};
  }
  &:active {
    color: ${colorPalette.pink900};
  }
`;

const ResetButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
`;

function getWeeks(start = new Date()) {
  const end = addWeeks(start, TOTAL_WEEKS);
  return getMondays(start, end).splice(0, TOTAL_WEEKS);
}

function defineType(member, list) {
  if (member.id === list[list.length - 1].id) return "last";
  return "middle";
}

function getAvailabilityName(hours) {
  if (hours < 40) return "bench";
  if (hours === 40) return "full";
  return "overallocated";
}

function formatData(
  productAssignmentData,
  projectsData,
  usersData,
  roleExtensionsData,
) {
  const roleExtensionsByUserId = Object.entries(
    groupBy(roleExtensionsData, "userId"),
  );
  const roleExtensions = {};
  for (const [userId, userRoleExtensions] of roleExtensionsByUserId) {
    roleExtensions[userId] = groupBy(userRoleExtensions, "projectId");
  }

  const projects = keyBy(projectsData.data, "id");
  const products = keyBy(
    (projectsData.included || []).filter((elem) => elem.type === "products"),
    "id",
  );
  for (const user of usersData.data) user.attributes.ocupation = {};

  const users = keyBy(usersData.data, "id");

  const assignmentNotes = productAssignmentData.included
    ? keyBy(
        productAssignmentData.included.filter(
          (el) => el.type === "assignmentsNotes",
        ),
        "id",
      )
    : {};

  for (const assignment of productAssignmentData.data) {
    assignment.attributes.assignmentNote = assignment.relationships
      .assignmentsNote.data
      ? assignmentNotes[assignment.relationships.assignmentsNote.data.id]
          .attributes.note
      : null;

    assignment.attributes.productId =
      projects[assignment.attributes.projectId].relationships.product.data.id;
  }

  // creating users' ocupation object based on their assignments
  for (const assignment of productAssignmentData.data.filter(
    (assignment) => assignment.attributes.assigneeType === "User",
  )) {
    const user = users[assignment.attributes.assigneeId];
    if (user) {
      const { ocupation } = user.attributes;
      const { periodStart } = assignment.attributes;

      if (!ocupation[periodStart]) ocupation[periodStart] = { hours: 0 };

      ocupation[periodStart].hours += Number.parseInt(
        assignment.attributes.hours,
      );
      ocupation[periodStart].type = getAvailabilityName(
        ocupation[periodStart].hours,
      );
    }
  }

  const roles = keyBy(
    usersData.included.filter((resource) => resource.type === "roles"),
    "id",
  );

  const locations = keyBy(
    usersData.included.filter((resource) => resource.type === "locations"),
    "id",
  );

  for (const user of Object.values(users)) {
    users[user.id].attributes.role =
      user.relationships.role.data &&
      roles[user.relationships.role.data.id].attributes.title;

    users[user.id].attributes.department =
      user.relationships.role.data &&
      roles[user.relationships.role.data.id].attributes.departmentTitle;

    users[user.id].attributes.assignable =
      user.relationships.role.data &&
      roles[user.relationships.role.data.id].attributes.assignable;

    users[user.id].attributes.location =
      user.relationships.location.data &&
      locations[user.relationships.location.data.id].attributes.name;
  }

  const projectAssignments = groupBy(
    productAssignmentData.data,
    "attributes.projectId",
  );

  for (const projectId of Object.keys(projectAssignments)) {
    if (projects[projectId]) {
      projects[projectId].relationships.projectAssignments = {
        data: projectAssignments[projectId],
      };
    }
  }

  return {
    productAssignments: keyBy(productAssignmentData.data, "id"),
    projects,
    products,
    users,
    roleExtensions,
  };
}

function getSeatsForProject(projectSeats, project) {
  return projectSeats.data.filter(
    (seat) => seat.attributes.projectId === Number(project.id),
  );
}

function handleMoveForward(
  timelineStart,
  setTimelineStart,
  setTimelineNavDisabled,
) {
  setTimelineNavDisabled(true);
  const nextWeek = addWeeks(timelineStart, 1);
  setTimelineStart(nextWeek);
}

function handleMoveBackwards(
  timelineStart,
  setTimelineStart,
  setTimelineNavDisabled,
) {
  setTimelineNavDisabled(true);
  const previousWeek = addWeeks(timelineStart, -1);
  setTimelineStart(previousWeek);
}

function TimelineHeader({
  timelineWeeks,
  mondayOfCurrentWeek,
  timelineStart,
  setTimelineStart,
  editPermission,
  timelineNavDisabled,
  setTimelineNavDisabled,
}) {
  const timelineIsAtCurrentWeek = isSameDay(timelineStart, mondayOfCurrentWeek);
  return (
    <Header>
      <HeaderButtonsWrapper>
        <ButtonWrapper>
          {editPermission && (
            <CoreLink href="/staffing/new" className="hover:no-underline">
              <PrimaryButton text="Add Project" />
            </CoreLink>
          )}
        </ButtonWrapper>
        <ResetButtonContainer>
          {timelineIsAtCurrentWeek ? null : (
            <ResetToCurrentWeekButton
              title="Reset timeline to current week"
              onClick={() => setTimelineStart(mondayOfCurrentWeek)}
            >
              to current
            </ResetToCurrentWeekButton>
          )}
        </ResetButtonContainer>

        <MoveOneWeekBackwardButton
          onClick={() => {
            handleMoveBackwards(
              timelineStart,
              setTimelineStart,
              setTimelineNavDisabled,
            );
          }}
          disabled={timelineNavDisabled}
        >
          <ArrowLeft
            fill={
              timelineNavDisabled
                ? colorPalette.bluegrey50
                : colorPalette.deepBlue500
            }
          />
        </MoveOneWeekBackwardButton>
        <MoveOneWeekForwardButton
          disabled={timelineNavDisabled}
          onClick={() => {
            handleMoveForward(
              timelineStart,
              setTimelineStart,
              setTimelineNavDisabled,
            );
          }}
        >
          <ArrowRight
            fill={
              timelineNavDisabled
                ? colorPalette.bluegrey50
                : colorPalette.deepBlue500
            }
          />
        </MoveOneWeekForwardButton>
      </HeaderButtonsWrapper>
      <Dates>
        {timelineWeeks.map((week) => (
          <Week key={week}>
            <WeekBackground
              currentWeek={week.getTime() === mondayOfCurrentWeek.getTime()}
            >
              {format(week, "MM/dd")}
            </WeekBackground>
          </Week>
        ))}
      </Dates>
    </Header>
  );
}

async function prefetchFollowingWeek(
  currentTimelineStart,
  lastDateOfPreviouslyRequestedAssignments,
  projects,
  setTimelineNavDisabled,
  completeProductAssignmentData,
) {
  const nextTimeframeWeeks = getWeeks(addWeeks(currentTimelineStart, 1));
  const weekToPrefetch = addWeeks(lastDateOfPreviouslyRequestedAssignments, 1);
  await Promise.all([
    prefetchProjectSeats(nextTimeframeWeeks),
    prefetchProductAssignments(
      projects,
      weekToPrefetch,
      nextTimeframeWeeks,
      completeProductAssignmentData,
    ),
  ]);

  setTimelineNavDisabled(false);
}

async function prefetchPreviousWeek(
  currentTimelineStart,
  projects,
  setTimelineNavDisabled,
  completeProductAssignmentData,
) {
  const nextTimeframeWeeks = getWeeks(addWeeks(currentTimelineStart, -1));
  const weekToPrefetch = addWeeks(currentTimelineStart, -1);
  await Promise.all([
    prefetchProjectSeats(nextTimeframeWeeks),
    prefetchProductAssignments(
      projects,
      weekToPrefetch,
      nextTimeframeWeeks,
      completeProductAssignmentData,
    ),
  ]);

  setTimelineNavDisabled(false);
}

async function prefetchProductAssignments(
  projects,
  weekToPrefetch,
  nextTimeframeWeeks,
  completeProductAssignmentData,
) {
  const additionalAssignments = await readProductAssignments(
    projects,
    weekToPrefetch,
    weekToPrefetch,
  );
  const [assignmentsStart, assignmentsEnd] =
    getProductAssignmentRequestRange(nextTimeframeWeeks);

  await hydrateProductAssignments(projects, assignmentsStart, assignmentsEnd, {
    ...completeProductAssignmentData,
    data: [
      ...completeProductAssignmentData.data,
      ...additionalAssignments.data,
    ],
  });
}

async function prefetchProjectSeats(nextTimeframeWeeks) {
  const [seatsStart, seatsEnd] =
    getProjectSeastsRequestRange(nextTimeframeWeeks);

  readProjectSeats(seatsStart, seatsEnd);
}

function getProjectSeastsRequestRange(timelineWeeks) {
  const rangeStart = timelineWeeks[0];
  const rangeEnd = timelineWeeks[timelineWeeks.length - 1];
  return [rangeStart, rangeEnd];
}

function getProductAssignmentRequestRange(timelineWeeks) {
  // Requesting 1 week before and 1 week after the weeks in the timeline in order to
  // style the timeline slots at the "edges" appropriately

  const rangeStart = addWeeks(timelineWeeks[0], -1);
  const rangeEnd = addWeeks(timelineWeeks[timelineWeeks.length - 1], 1);
  return [rangeStart, rangeEnd];
}

function AllocationsTimeline({ currentDate, permission: editPermission }) {
  const mondayOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });

  const [timelineStart, setTimelineStart] = useState(mondayOfCurrentWeek);
  const [timelineNavDisabled, setTimelineNavDisabled] = useState(true);

  const timelineWeeks = getWeeks(timelineStart);

  const { data: usersData } = useUsers();
  const { data: projectRoles } = useProjectRoles();

  const { data: projectsData, revalidate: revalidateProjects } = useProjects();

  const { data: roleExtensionsData } = useRoleExtensions();

  const [seatsStart, seatsEnd] = getProjectSeastsRequestRange(timelineWeeks);
  const { data: projectSeats } = useProjectSeats(seatsStart, seatsEnd);

  const [assignmentsStart, assignmentsEnd] =
    getProductAssignmentRequestRange(timelineWeeks);
  const {
    data: completeProductAssignmentData,
    revalidate: revalidateProductsAssignments,
  } = useProductAssignments(
    projectsData.data,
    assignmentsStart,
    assignmentsEnd,
  );

  // Prefetching next "page's" request to avoid displaying Suspense Fallback
  useEffect(() => {
    prefetchFollowingWeek(
      timelineStart,
      assignmentsEnd,
      projectsData.data,
      setTimelineNavDisabled,
      completeProductAssignmentData,
    );
    // We only need to cache when the timeline changes "page"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineStart]);

  // Prefetching previous "page's" request to avoid displaying Suspense Fallback
  useEffect(() => {
    prefetchPreviousWeek(
      timelineStart,
      projectsData.data,
      setTimelineNavDisabled,
      completeProductAssignmentData,
    );
    // We only need to cache when the timeline changes "page"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineStart]);

  const data = formatData(
    completeProductAssignmentData,
    projectsData,
    usersData,
    roleExtensionsData,
  );
  const projectRolesById = keyBy(projectRoles, "id");

  return (
    <TimelineContext.Provider
      value={{
        data,
        weeks: timelineWeeks,
        editPermission,
        revalidateProductsAssignments,
        revalidateProjects,
      }}
    >
      <WrapperPage>
        <Container>
          <TimelineHeader
            timelineWeeks={timelineWeeks}
            mondayOfCurrentWeek={mondayOfCurrentWeek}
            timelineStart={timelineStart}
            setTimelineStart={setTimelineStart}
            editPermission={editPermission}
            timelineNavDisabled={timelineNavDisabled}
            setTimelineNavDisabled={setTimelineNavDisabled}
          />
          <BenchTimeline />
          <ProjectSectionTitle />
          {sortBy(Object.values(data.projects), [
            (project) =>
              data.products[project.relationships.product.data.id].attributes
                .name,
            "attributes.name",
          ]).map((project, _, projects) => (
            <ProjectTimeline
              key={project.id}
              project={project}
              product={data.products[project.relationships.product.data.id]}
              responsible={
                project.relationships.responsible.data &&
                data.users[project.relationships.responsible.data.id]
              }
              projectSeats={keyBy(
                getSeatsForProject(projectSeats, project),
                "id",
              )}
              type={defineType(project, projects)}
              projectRolesById={projectRolesById}
            />
          ))}
        </Container>
      </WrapperPage>
    </TimelineContext.Provider>
  );
}
export default AllocationsTimeline;
