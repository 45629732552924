import MarkdownIt from "markdown-it";

const md = new MarkdownIt({
  html: false,
  breaks: true,
  linkify: true,
  xhtmlOut: false,
  typographer: false,
  langPrefix: "language-",
});

export default function checkEmptyMDText(text) {
  if (text === undefined || !text) return "";
  const result = md.render(text);
  const div = document.createElement("div");
  div.innerHTML = result;
  return (div.textContent || div.textContent || "").trim() !== "";
}
