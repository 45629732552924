import { denormalize, useResource, ClientError } from "coreql";

export default function useCurrentUser(options = {}) {
  const { included = [] } = options;

  const { data, ...props } = useResource("users", "current", {
    included: ["role"].concat(included),
    options: {
      refreshWhenOffline: true,
      shouldRetryOnError: false,
    },
  });

  if (data.error) throw new ClientError("Unauthorized");
  return { data: denormalize(data, "users"), ...props };
}

export function FallbackLogout({ error }) {
  if (error?.data.error !== "Unauthorized") throw error;

  // redirect to login page
  window.location.href = "/device/new";
  return null;
}
