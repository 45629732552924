import React, { useMemo } from "react";
import {
  Stack,
  Inline,
  Text,
  Center,
  Grid,
  Modal,
  Portal,
  Button,
  Touchable,
  Frame,
  AccordionContent,
  AccordionGroup,
  AccordionTitle,
  Skeleton,
  Color,
} from "@ableco/baseline";
import {
  CaretRight,
  CaretDownOutline,
  CaretUpOutline,
  Close,
  CaretDown,
  Trash,
} from "@baseline/icons";
import cn from "clsx";
import Tag from "../tag";
import HighlightTypeTag from "./highlight-type-tag";
import { capitalize, groupBy, keyBy, mapValues, pick } from "lodash";
import { api, useCollection, SSRSuspense, denormalize } from "coreql";
import { useUserProfile } from "../layout/user-layout";
import useCurrentUser from "../../hooks/use-current-user";
import { wrap } from "../weekly-report/utils";
import useToggle from "../../hooks/use-toggle";
import Checkbox from "../data-entry/checkbox";
import FeedbackRequestPrompt from "../feedback-request-prompt";
import useHighlightsFilters, { FiltersContext } from "./useHighlightsFilters";
import { format, compareAsc } from "date-fns";
import { combineResponsibilities } from "../data-entry/select/responsibility-select";
import { CoreBackdrop } from "../backdrop/backdrop";
import { AnimatePresence } from "framer-motion";
import Select from "../data-entry/select";
import HighlightsPopover from "../feedback/highlights-popover";
import HighlightUserLayout from "./highlight-user-layout";
import { SecondaryButton } from "../buttons/buttons";
import CoreLink from "../core-link";
import MonthPicker from "../data-entry/month-picker/daterange-picker";
import {
  getQuarterRange,
  quarterFirstDate,
  quarterLastDate,
} from "../../utils/core-quarters";
import { Heading } from "../design-system/typography-components";

function buildGroupsByIdentifier(responsibilitiesById, groupIds, keySelector) {
  const groupResponsibilities = pick(responsibilitiesById, groupIds);

  const groups = groupBy(Object.values(groupResponsibilities), keySelector);

  return groups;
}

function buildMultipleGroups(
  profileResponsibilities,
  extractArchivedResponsibilityIds,
  responsibilitiesById,
  responsibilityType,
  keySelector,
) {
  const projectRoleResponsibilitiesIds = [
    ...profileResponsibilities.flatMap(
      ({ responsibilities }) => responsibilities,
    ),
    ...extractArchivedResponsibilityIds(responsibilityType),
  ];

  return buildGroupsByIdentifier(
    responsibilitiesById,
    projectRoleResponsibilitiesIds,
    keySelector,
  );
}

function buildRoleGroup(
  roleResponsibilities,
  extractArchivedResponsibilityIds,
  responsibilitiesById,
) {
  const roleResponsibilitiesIds = [
    ...roleResponsibilities,
    ...extractArchivedResponsibilityIds("Responsibility::Role"),
  ];

  return buildGroupsByIdentifier(
    responsibilitiesById,
    roleResponsibilitiesIds,
    "role",
  );
}

function useUserResponsibilityGroups(
  userProfile,
  responsibilities,
  archivedResponsibilites = [],
) {
  const responsibilitiesGroups = useMemo(() => {
    const {
      projectRoleResponsibilities,
      roleResponsibilities,
      disciplineLeadersResponsibilities,
      disciplineResponsibilities,
      departmentLeadersResponsibilities,
      departmentResponsibilities,
      individualContributorsResponsibilities,
      managersResponsibilities,
      commonResponsibilities,
    } = mapValues(userProfile, wrap);
    const groups = [];
    const responsibilitiesById = keyBy(responsibilities, "id");

    function hasArchivedResponsbility(type) {
      return archivedResponsibilites.some(
        (responsibility) => responsibility.responsibilityType === type,
      );
    }

    function extractArchivedResponsibilityIds(type) {
      return archivedResponsibilites
        .filter((responsibility) => responsibility.responsibilityType === type)
        .map((responsibility) => responsibility.id);
    }

    const projectRoleResponsibilityGroups = buildMultipleGroups(
      projectRoleResponsibilities,
      extractArchivedResponsibilityIds,
      responsibilitiesById,
      "Responsibility::ProjectRole",
      "projectRole",
    );

    groups.push(
      ...Object.entries(projectRoleResponsibilityGroups).map(
        ([projectRole, responsibilities]) => ({
          groupTitle: `Responsibilities unique for a ${projectRole}`,
          responsibilities,
        }),
      ),
    );

    const roleResponsibilityGroups = buildRoleGroup(
      roleResponsibilities,
      extractArchivedResponsibilityIds,
      responsibilitiesById,
    );

    groups.push(
      ...Object.entries(roleResponsibilityGroups).map(
        ([role, responsibilities]) => ({
          groupTitle: `Responsibilities unique to a ${role}`,
          responsibilities,
        }),
      ),
    );

    const disciplineLeaderResponsibilityGroups = buildMultipleGroups(
      disciplineLeadersResponsibilities,
      extractArchivedResponsibilityIds,
      responsibilitiesById,
      "Responsibility::DisciplineLeader",
      "discipline",
    );

    groups.push(
      ...Object.entries(disciplineLeaderResponsibilityGroups).map(
        ([discipline, responsibilities]) => ({
          groupTitle: `Responsibilities unique for the ${discipline} discipline leader`,
          responsibilities,
        }),
      ),
    );

    const disciplineResponsibilityGroups = buildMultipleGroups(
      disciplineResponsibilities,
      extractArchivedResponsibilityIds,
      responsibilitiesById,
      "Responsibility::Discipline",
      "discipline",
    );

    groups.push(
      ...Object.entries(disciplineResponsibilityGroups).map(
        ([discipline, responsibilities]) => ({
          groupTitle: `Responsibilities for all members of ${discipline} discipline`,
          responsibilities,
        }),
      ),
    );

    const departmentLeaderResponsibilityGroups = buildMultipleGroups(
      departmentLeadersResponsibilities,
      extractArchivedResponsibilityIds,
      responsibilitiesById,
      "Responsibility::DepartmentLeader",
      "department",
    );

    groups.push(
      ...Object.entries(departmentLeaderResponsibilityGroups).map(
        ([department, responsibilities]) => ({
          groupTitle: `Responsibilities unique for the ${department} department leader`,
          responsibilities,
        }),
      ),
    );

    const departmentResponsibilityGroups = buildMultipleGroups(
      departmentResponsibilities,
      extractArchivedResponsibilityIds,
      responsibilitiesById,
      "Responsibility::Department",
      "department",
    );

    groups.push(
      ...Object.entries(departmentResponsibilityGroups).map(
        ([department, responsibilities]) => ({
          groupTitle: `Responsibilities for all members of the ${department} department`,
          responsibilities,
        }),
      ),
    );

    if (
      !userProfile.isManager ||
      hasArchivedResponsbility("Responsibility::IndividualContributor")
    ) {
      const individualContributorResponsibilitiesIds = [
        ...individualContributorsResponsibilities,
        ...extractArchivedResponsibilityIds(
          "Responsibility::IndividualContributor",
        ),
      ];

      groups.push({
        groupTitle: "Responsibilities for all Individual Contributors",
        responsibilities: individualContributorResponsibilitiesIds.map(
          (id) => responsibilitiesById[id],
        ),
      });
    }
    if (
      userProfile.isManager ||
      hasArchivedResponsbility("Responsibility::Manager")
    ) {
      const managerResponsibilitiesIds = [
        ...managersResponsibilities,
        ...extractArchivedResponsibilityIds("Responsibility::Manager"),
      ];

      groups.push({
        groupTitle: "Responsibilities for all Managers",
        responsibilities: managerResponsibilitiesIds.map(
          (id) => responsibilitiesById[id],
        ),
      });
    }

    const commonResponsibilitiesIds = [
      ...commonResponsibilities,
      ...extractArchivedResponsibilityIds("Responsibility::Common"),
    ];

    groups.push({
      groupTitle: "Responsibilities common to all Able employees",
      responsibilities: commonResponsibilitiesIds.map(
        (id) => responsibilitiesById[id],
      ),
    });

    function selectNonEmptyGroups({ responsibilities }) {
      return responsibilities.length > 0;
    }

    return groups.filter(selectNonEmptyGroups);
  }, [userProfile, responsibilities, archivedResponsibilites]);

  return responsibilitiesGroups;
}

function ResponsibilityAccordion({
  title,
  amount,
  isLast,
  children,
  isArchived,
}) {
  const isEmpty = amount === 0;

  return (
    <>
      <AccordionGroup>
        <Frame
          p={4}
          className={cn("w-full border-neutral-400", !isLast && "border-b")}
        >
          <AccordionTitle
            isDisabled={isEmpty}
            direction="horizontal"
            alignment="start"
            className="w-full focus:outline-none relative"
          >
            {({ isOpen }) => (
              <Inline className="w-full" distribution="between">
                <Inline>
                  <Text
                    color={isEmpty ? "neutral-500" : "neutral-800"}
                    className="mt-1"
                  >
                    <Text srOnly>
                      {isOpen ? "Close Accordion" : "Open Accordion"}
                    </Text>
                    {isOpen ? (
                      <CaretDown
                        className="w-4 h-4"
                        color={isEmpty ? "neutral-500" : "neutral-800"}
                      />
                    ) : (
                      <CaretRight
                        className="w-4 h-4"
                        color={isEmpty ? "neutral-500" : "neutral-800"}
                      />
                    )}
                  </Text>
                  {isArchived && (
                    <Frame
                      p={["px", 2]}
                      bg="neutral-200"
                      corners="medium"
                      className="ml-2"
                    >
                      <Text size="sm" color="neutral-800" leading="normal">
                        Archived
                      </Text>
                    </Frame>
                  )}
                  <Frame p={[0, 0, 0, 3]} className="text-left">
                    <Text
                      leading="normal"
                      color={isEmpty ? "neutral-500" : "neutral-800"}
                    >
                      {title}
                    </Text>
                    <Text
                      leading="normal"
                      color={isEmpty ? "neutral-500" : "neutral-600"}
                      className="ml-2"
                    >
                      ({amount})
                    </Text>
                  </Frame>
                </Inline>
              </Inline>
            )}
          </AccordionTitle>
          <AccordionContent>
            <Frame>{children}</Frame>
          </AccordionContent>
        </Frame>
      </AccordionGroup>
    </>
  );
}

function HighlightCard({
  user,
  receiver,
  currentUser,
  highlightedText,
  note,
  highlight,
  feedbackType,
  mutate,
}) {
  const [showPopup, setShowPopup] = React.useState(false);
  function handleClick() {
    setShowPopup(showPopup ? false : true);
  }

  let isManager = receiver.managerId?.toString() === currentUser.id.toString();

  const handleClickOutside = React.useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);

  async function deleteMe() {
    await api.feedbackHighlights.find(highlight.id).destroy();
    mutate();
  }
  const mention = wrap(highlight.relatedObject.cheerMentions).find(
    (item) => item.userId.toString() === receiver.id.toString(),
  );
  const relatedUrl =
    highlight.source === "cheer"
      ? `/v2_weekly_reports/${mention?.weeklyReportId}`
      : `/users/${receiver.id}/feedback_messages/${highlight.relatedObject.id}`;

  return (
    <>
      <Stack
        role="button"
        corners="medium"
        p={4}
        border="neutral-400"
        className="text-left group"
        onClick={handleClick}
      >
        <Inline distribution="between" alignment="top" className="group">
          <HighlightUserLayout
            user={user}
            date={
              highlight.relatedObject.submittedAt ||
              highlight.relatedObject.createdAt
            }
          />
          <Inline alignment="start">
            <Touchable
              onClick={(event) => {
                event.stopPropagation();
                deleteMe();
              }}
              className="hidden bg-transparent group-focus-within:block group-hover:block"
              data-testid="delete-highlight"
              aria-label="Delete"
            >
              <Trash
                style={{ marginLeft: "-1px" }}
                id={highlight.id}
                className="w-4 h-4 text-neutral-600 hover:text-neutral-800 transition-all duration-300 ease-in-out"
              />
            </Touchable>
          </Inline>
        </Inline>
        <Text
          as="p"
          color="neutral-800"
          leading="normal"
          className="flex-1 mb-4"
          style={{ hyphens: "auto" }}
        >
          {highlightedText}
        </Text>
        <Inline distribution="between">
          <Inline alignment="baseline" wrap>
            <HighlightTypeTag selectedFeedbackType={feedbackType} />
            <Tag
              text={capitalize(highlight.source)}
              color="neutral-700"
              bg="primary-lighter"
              className="mb-1 mr-1"
            />
            {highlight.skills.map((skill) => (
              <Tag
                key={skill.id}
                text={skill.name}
                bg="neutral-200"
                color="neutral-700"
                className="mb-1 mr-1"
              />
            ))}
          </Inline>
          <CoreLink
            href={relatedUrl}
            className="hidden cursor-pointer hover:no-underline group-focus-within:block group-hover:block"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Text color="primary" size="xs">
              See original message
            </Text>
          </CoreLink>
        </Inline>
      </Stack>

      <SSRSuspense
        fallback={
          <Portal id="fallback">
            <CoreBackdrop>
              <React.Fragment />
            </CoreBackdrop>
          </Portal>
        }
      >
        {showPopup && (
          <HighlightModal
            highlightId={highlight.id}
            feedbackMessage={highlight.relatedObject}
            user={user}
            feedbackType={feedbackType}
            receiverId={receiver.id}
            responsibilityId={highlight.responsibilityId}
            responsibility={highlight.responsibility}
            skills={wrap(highlight.skills)}
            note={note}
            close={handleClickOutside}
            refetch={mutate}
            relatedId={highlight.relatedObject.id}
            highlightedText={highlightedText}
            highlightStart={highlight.start}
            highlightEnd={highlight.end}
            readOnly={!isManager}
            source={highlight.source}
            showDeleteButton={false}
            userLayout={true}
            invertOrder={true}
          />
        )}
      </SSRSuspense>
    </>
  );
}

function HighlightModal({ ...props }) {
  const $modal = React.createRef(null);
  return (
    <Portal>
      <CoreBackdrop>
        <Center className="h-full w-full">
          <Modal
            innerRef={$modal}
            className="w-2/4 h-auto relative"
            corners="medium"
            p={[3, 2, 2, 2]}
            style={{ background: "white" }}
          >
            <Inline distribution="end" className="absolute top-0 right-0" p={2}>
              <Touchable onClick={props.close}>
                <Close className="w-3 h-3" style={{ opacity: 0.45 }} />
                <Text srOnly>Close highlight modal</Text>
              </Touchable>
            </Inline>
            <HighlightsPopover {...props} />
          </Modal>
        </Center>
      </CoreBackdrop>
    </Portal>
  );
}

export function makeTitlesDictionaryFrom(data) {
  const titles = {};
  for (const item of data) {
    titles[item.id] = item.title ?? item.name;
  }
  return titles;
}

function HighlightSet({ title, data, filter, receiver, currentUser, mutate }) {
  const highlightsByFeedbackType = data.filter(
    (item) => item.feedbackType == filter.feedbackType,
  );

  if (highlightsByFeedbackType.length === 0) return null;

  return (
    <Frame>
      <Text
        as="h3"
        color="neutral-800"
        leading="normal"
        size="sm"
        className="mb-2"
        style={{ opacity: 0.65 }}
      >
        {title}
      </Text>
      <Grid col={1} gap={2}>
        {highlightsByFeedbackType.map((highlight) => (
          <HighlightCard
            key={highlight.id}
            {...highlight}
            receiver={receiver}
            currentUser={currentUser}
            user={{
              avatarUrl: highlight.feedbackSenderAvatarUrl,
              fullName: highlight.feedbackSenderName,
              senderRole: highlight.feedbackSenderRole,
            }}
            highlight={{
              id: highlight.id,
              start: highlight.start,
              end: highlight.end,
              type: highlight.feedbackType,
              messageId: highlight.feedbackMessageId,
              responsibility: highlight.responsibility,
              responsibilityId: highlight.responsibilityId,
              skills: wrap(highlight.skills),
              relatedObject: highlight.feedbackMessage || highlight.cheer,
              source: highlight.source,
            }}
            mutate={mutate}
          />
        ))}
      </Grid>
    </Frame>
  );
}

function ResponsibilityGroup({
  groupTitle,
  responsibilities,
  feedbackHighlights,
  receiver,
  currentUser,
  setShowRequestPrompt,
  mutate,
}) {
  if (responsibilities.length === 0) return null;
  return (
    <Stack alignment="start" bg="white" corners="small" className="w-full mb-6">
      <Inline p={4} className="w-full" distribution="between">
        <Text as="h4" color="neutral-600">
          {groupTitle}
        </Text>
        <SecondaryButton
          onClick={() => setShowRequestPrompt(true)}
          small
          text="Request Feedback"
        />
      </Inline>

      {responsibilities.map((responsibility, index) => {
        const highlightsByResponsibility = feedbackHighlights.filter(
          (item) => item.responsibilityId == responsibility.id,
        );

        return (
          <ResponsibilityAccordion
            key={responsibility.id}
            title={responsibility.title}
            isArchived={responsibility.archivedAt}
            amount={highlightsByResponsibility.length}
            isLast={index === responsibilities.length - 1}
          >
            {highlightsByResponsibility.length > 0 && (
              <Grid gap={6} p={[4, 0]}>
                <HighlightSet
                  title="Wins"
                  data={highlightsByResponsibility}
                  receiver={receiver}
                  currentUser={currentUser}
                  filter={{ feedbackType: "win" }}
                  mutate={mutate}
                />
                <HighlightSet
                  title="Opportunities"
                  data={highlightsByResponsibility}
                  receiver={receiver}
                  currentUser={currentUser}
                  filter={{ feedbackType: "opportunity" }}
                  mutate={mutate}
                />
                <HighlightSet
                  title="General"
                  data={highlightsByResponsibility}
                  receiver={receiver}
                  currentUser={currentUser}
                  filter={{ feedbackType: "general" }}
                  mutate={mutate}
                />
              </Grid>
            )}
          </ResponsibilityAccordion>
        );
      })}
    </Stack>
  );
}

export async function readHighlights(
  id,
  { filters = { types: ["win", "opportunity", "general"] } } = {},
) {
  // this fetch could be started immediately
  api.feedbackHighlights.read({
    included: ["user"],
    filters: {
      receiver: id,
      feedbackType: filters?.types,
      source: filters.sources,
      since: filters.dateRange[0],
      until: filters.dateRange[1],
    },
  });

  // we need to await this to use it below
  const user = denormalize(await api.users.find(id).read(), "users");

  // those fetchs are independant and could be fetch at the same time
  api.responsibilities.read({
    filters: {
      id: combineResponsibilities(user),
    },
  });

  api.projectRoles.read({
    filters: {
      id: wrap(user.projectRoleResponsibilities ?? []).flatMap(
        (item) => item?.role,
      ),
    },
  });

  api.departments.read({
    filters: {
      id: wrap(user.departmentLeadersResponsibilities ?? []).flatMap(
        (item) => item.department,
      ),
    },
  });

  api.disciplines.read({
    filters: {
      id: wrap(user.disciplineLeadersResponsibilities ?? []).flatMap(
        (item) => item.discipline,
      ),
    },
  });
}

function useHighlightsResponsibilites(userProfile, archivedResponsibilities) {
  return useCollection(
    "responsibilities",
    {
      filters: {
        id: [
          ...combineResponsibilities(userProfile),
          ...archivedResponsibilities.map(
            (responsibility) => responsibility.id,
          ),
        ],
      },
      fields: {
        responsibilities: [
          "title",
          "responsibilityType",
          "archivedAt",
          "associatedId",
          "discipline",
          "role",
          "department",
          "projectRole",
        ],
      },
    },
    "denormalized",
  );
}

function useHighlights(userId, filters) {
  const { start: firstDate, end: lastDate } = getQuarterRange(new Date());
  let filtersCriteria = {
    receiver: userId,
    feedbackType: filters.types,
    source: filters.sources,
    since: format(firstDate, "yyyy-MM-dd"),
    until: format(lastDate, "yyyy-MM-dd"),
  };
  if (filters.tags.length > 0) {
    filtersCriteria["skills"] = filters.tags;
  }
  if (!!filters.startMonth) {
    filtersCriteria["since"] = format(filters.startMonth, "yyyy-MM-dd");
  }
  if (!!filters.endMonth) {
    filtersCriteria["until"] = format(filters.endMonth, "yyyy-MM-dd");
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCollection("feedback-highlights", {
    included: [
      "user",
      "skills",
      "responsibility",
      "feedbackMessage",
      "cheer",
      "cheer.cheerMentions",
    ],
    fields: {
      responsibilities: [
        "title",
        "archivedAt",
        "responsibilityType",
        "associatedId",
      ],
      feedbackMessages: ["submittedAt", "body"],
      cheers: ["createdAt", "content", "cheerMentions"],
      cheerMentions: ["userId", "weeklyReportId"],
    },
    filters: filtersCriteria,
  });
}

export default function HighlightGrid() {
  const { data: currentUser } = useCurrentUser();
  const userProfile = useUserProfile();
  const filters = useHighlightsFilters();

  const { data: feedbackHighlightsResponse, mutate } = useHighlights(
    userProfile.id,
    filters,
  );

  const feedbackHighlights = wrap(
    denormalize(feedbackHighlightsResponse, "feedbackHighlights"),
  );

  const archivedResponsibilities = useMemo(
    () =>
      wrap(denormalize(feedbackHighlightsResponse, "responsibilities")).filter(
        (item) => item.archivedAt,
      ),
    [feedbackHighlightsResponse],
  );

  const { data: responsibilities } = useHighlightsResponsibilites(
    userProfile,
    archivedResponsibilities,
  );

  const userResponsibilityGroups = useUserResponsibilityGroups(
    userProfile,
    responsibilities,
    archivedResponsibilities,
  );

  const emptyResponsibilityId = null;
  const areThereUnassignedHighlights = feedbackHighlights.some(
    (highlight) => !highlight.responsibility,
  );

  const [showRequestPrompt, setShowRequestPrompt] = React.useState(false);
  return (
    <Frame>
      <AnimatePresence>
        {showRequestPrompt && (
          <React.Suspense key={showRequestPrompt} fallback={<CoreBackdrop />}>
            <FeedbackRequestPrompt
              ownerId={userProfile.id}
              onClose={() => setShowRequestPrompt(false)}
            />
          </React.Suspense>
        )}
      </AnimatePresence>
      {userResponsibilityGroups.map((group) => (
        <ResponsibilityGroup
          key={group.groupTitle}
          groupTitle={group.groupTitle}
          responsibilities={group.responsibilities}
          feedbackHighlights={feedbackHighlights}
          receiver={userProfile}
          currentUser={currentUser}
          setShowRequestPrompt={setShowRequestPrompt}
          mutate={mutate}
        />
      ))}
      {areThereUnassignedHighlights && (
        <ResponsibilityGroup
          key="General"
          groupTitle="General"
          responsibilities={[
            { id: emptyResponsibilityId, title: "Unassigned highlights" },
          ]}
          feedbackHighlights={feedbackHighlights}
          receiver={userProfile}
          currentUser={currentUser}
          mutate={mutate}
        />
      )}
    </Frame>
  );
}

export function Fallback() {
  return (
    <Stack alignment="start">
      <Inline bg="white" p={6} className="mt-4 mb-6 w-full">
        <Text color="neutral-400">
          <CaretRight className="w-4 h-4 mr-3" />
        </Text>
        <Skeleton
          width={334}
          height={8}
          color="neutral-400"
          alt="Cheers section"
        />
      </Inline>

      <Grid row={5} gap={6} className="w-full">
        {Array.from({ length: 4 }, (_, index) => (
          <Grid
            row={3}
            gap={8}
            bg="white"
            corners="medium"
            className="w-full"
            key={index}
            p={4}
          >
            <Skeleton
              width={334}
              height={16}
              color="neutral-400"
              alt="Responsibility group"
            />

            <Inline bg="white" alignment="start">
              <CaretRight className="w-4 h-4 text-neutral-400 mr-3" />
              <Skeleton
                width={334}
                height={8}
                color="neutral-400"
                alt="Responsibility group"
              />
            </Inline>

            <Inline bg="white" alignment="start">
              <CaretRight className="w-4 h-4 text-neutral-400 mr-3" />
              <Skeleton
                width={334}
                height={8}
                color="neutral-400"
                alt="Responsibility group"
              />
            </Inline>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

function formatTypes(types) {
  return Object.entries(types)
    .filter(([_, enabled]) => enabled)
    .map(([type]) => type);
}

function useMonths(userProfileId) {
  const { data: quartersRawData } = useCollection("feedback-highlights", {
    filters: { receiver: userProfileId },
    fields: {
      cheers: ["createdAt"],
      feedbackHighlights: ["id", "feedbackMessage", "cheer"],
      feedbackMessages: ["submittedAt"],
    },
    included: ["feedbackMessage", "cheer"],
  });
  let quartersFeedbackData = wrap(
    denormalize(quartersRawData, "feedbackHighlights"),
  );
  let sortedDates = [
    ...new Set(
      quartersFeedbackData.map(
        (date) =>
          new Date(date.feedbackMessage?.submittedAt || date.cheer?.createdAt),
      ),
    ),
  ].sort(compareAsc);

  if (sortedDates.length > 0) {
    return {
      minMonth: quarterFirstDate(sortedDates[0]),
      maxMonth: quarterLastDate(sortedDates[sortedDates.length - 1]),
    };
  }
  const { start: minMonth, end: maxMonth } = getQuarterRange(new Date());
  return { minMonth, maxMonth };
}

function isDefaultTypes(types) {
  if (types.win !== true) return false;
  if (types.opportunity !== true) return false;
  return types.general === true;
}

function isDefaultSources(sources) {
  if (sources.feedback !== true) return false;
  return sources.cheer === true;
}

function isCurrentTypes(types, current) {
  const savedTypes = formatTypes(types);
  const currentTypes = current.types;
  if (savedTypes[0] !== currentTypes[0]) return false;
  if (savedTypes[1] !== currentTypes[1]) return false;
  return savedTypes[2] === currentTypes[2];
}

function isCurrentSources(sources, current) {
  const savedSources = formatTypes(sources);
  const currentSources = current.sources;
  if (savedSources[0] !== currentSources[0]) return false;
  return savedSources[1] === currentSources[1];
}

function isDefaultMonth(defaultState, dateRange) {
  const [defaultStart, defaultEnd] = defaultState.map((defaultDate) =>
    !!defaultDate ? format(defaultDate, "yyyy-MM") : defaultDate,
  );
  const [start, end] = dateRange.map((rangeDate) =>
    !!rangeDate ? format(rangeDate, "yyyy-MM") : rangeDate,
  );
  return start === defaultStart && end === defaultEnd;
}

function isCurrentMonth(dateRange, current) {
  const { startMonth, endMonth } = current;
  const [currentStart, currentEnd] = [startMonth, endMonth].map((currentDate) =>
    !!currentDate ? format(currentDate, "yyyy-MM") : currentDate,
  );
  const [start, end] = dateRange.map((dateRange) =>
    !!dateRange ? format(dateRange, "yyyy-MM") : dateRange,
  );
  const currentDateIsNull = !(!!dateRange[0] && !!dateRange[1]);
  const currentDatesEqualToDefaults =
    start === currentStart && end === currentEnd;
  return currentDateIsNull || currentDatesEqualToDefaults;
}

function isDefaultSkillsTags(selectedTags) {
  return selectedTags.length === 0;
}

function isCurrentSkillTags(selectedTags, current) {
  const currentSkillsTags = current.tags.length;
  return selectedTags.length === currentSkillsTags;
}

function useSkillsTags(userProfileId) {
  const { data: skills } = useCollection("feedback-highlights", {
    filters: { receiver: userProfileId },
    fields: {
      feedbackHighlights: ["skills"],
      skills: ["id", "name"],
    },
    included: ["skills"],
  });
  const denormalizeSkills = wrap(denormalize(skills, "skills"));
  return new Set(denormalizeSkills.map(({ id, name }) => ({ id, name })));
}

function EmptySkillTagFilter() {
  return (
    <Select
      multi
      disabled={true}
      options={[{ value: "", label: "" }]}
      testId="skills"
      placeholder={"No tags found for the highlights"}
      className="w-full"
    />
  );
}

function SkillsTagsFilter({ skillsTags, ...props }) {
  if (skillsTags.size > 0) {
    return (
      <Select
        multi
        options={[...skillsTags].map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
        testId="skills"
        className="w-full"
        {...props}
      />
    );
  }
  return EmptySkillTagFilter();
}

export function HighlightFilters({ children, id }) {
  const [filtersVisibility, toggleFiltersVisibility] = useToggle(false);
  const { minMonth, maxMonth } = useMonths(id);
  const skillsTagsData = useSkillsTags(id);
  const { start: quarterStart, end: quarterEnd } = getQuarterRange(new Date());
  const defaultState = {
    startMonth: quarterStart,
    endMonth: quarterEnd,
    type: { win: true, opportunity: true, general: true },
    tags: [],
    source: { cheer: true, feedback: true },
  };
  const [startMonth, changeStartMonth] = React.useState(
    defaultState.startMonth,
  );
  const [endMonth, changeEndMonth] = React.useState(defaultState.endMonth);
  const [types, changeTypes] = React.useState(defaultState.type);
  const [skillsTags, changeSkillsTags] = React.useState(defaultState.tags);
  const [sources, changeSources] = React.useState(defaultState.source);
  const [reload, changeReload] = React.useState();
  const filtersContextValue = React.useRef({
    startMonth: startMonth,
    endMonth: endMonth,
    types: formatTypes(types),
    tags: skillsTags,
    sources: formatTypes(sources),
  });
  function resetFilters() {
    changeStartMonth(defaultState.startMonth);
    changeEndMonth(defaultState.endMonth);
    changeTypes(defaultState.type);
    changeSkillsTags([]);
    changeSources(defaultState.source);
  }

  function updateContext() {
    filtersContextValue.current = {
      startMonth: startMonth,
      endMonth: endMonth,
      types: formatTypes(types),
      tags: skillsTags,
      sources: formatTypes(sources),
    };
    changeReload(!reload);
  }

  const isResetEnabled =
    !isDefaultTypes(types) ||
    !isDefaultSources(sources) ||
    !isDefaultMonth(
      [defaultState.startMonth, defaultState.endMonth],
      [startMonth, endMonth],
    ) ||
    !isDefaultSkillsTags(skillsTags);
  const isApplyEnabled =
    !isCurrentTypes(types, filtersContextValue.current) ||
    !isCurrentMonth([startMonth, endMonth], filtersContextValue.current) ||
    !isCurrentSkillTags(skillsTags, filtersContextValue.current) ||
    !isCurrentSources(sources, filtersContextValue.current);

  return (
    <FiltersContext.Provider value={filtersContextValue.current}>
      <Inline className="mb-4" distribution="between" alignment="baseline">
        <Heading color={Color.Neutral800}>Highlights</Heading>
        <Touchable
          onClick={() => toggleFiltersVisibility()}
          className="focus:outline-none"
        >
          <Text color="primary">
            Filters{" "}
            {filtersVisibility ? (
              <CaretUpOutline className="w-4 h-4 inline" />
            ) : (
              <CaretDownOutline className="w-4 h-4 inline" />
            )}
          </Text>
        </Touchable>
      </Inline>

      {filtersVisibility && (
        <Frame p={4} bg="white" corners="small" className="mb-6">
          <Grid col={2} row={1} gap={4}>
            <Frame>
              <Stack>
                <Text
                  as="h5"
                  color="neutral-800"
                  leading="normal"
                  size="sm"
                  className="mb-2"
                >
                  Date range
                </Text>

                <Frame>
                  <MonthPicker
                    startDate={startMonth}
                    setStartDate={changeStartMonth}
                    endDate={endMonth}
                    setEndDate={changeEndMonth}
                    defaultMinDate={minMonth}
                    defaultMaxDate={maxMonth}
                  />
                </Frame>
              </Stack>
            </Frame>
            <Frame>
              <Text
                as="h5"
                color="neutral-800"
                leading="normal"
                size="sm"
                className="mb-2"
              >
                Highlight type
              </Text>
              <Inline>
                {["Win", "Opportunity", "General"].map((label) => (
                  <Frame key={label} className="mr-2">
                    <Checkbox
                      id={label.toLowerCase()}
                      label={label}
                      checked={types[label.toLowerCase()]}
                      onChange={() => {
                        let labelLower = label.toLowerCase();
                        changeTypes({
                          ...types,
                          [labelLower]: !types[labelLower],
                        });
                      }}
                    />
                  </Frame>
                ))}
              </Inline>
            </Frame>
            <Frame>
              <Text
                as="h5"
                color="neutral-800"
                leading="normal"
                size="sm"
                className="mb-2"
              >
                Source
              </Text>
              <Inline>
                {["Cheer", "Feedback"].map((label) => (
                  <Frame key={label} className="mr-2">
                    <Checkbox
                      id={label.toLowerCase()}
                      label={label}
                      checked={sources[label.toLowerCase()]}
                      onChange={() => {
                        let labelLower = label.toLowerCase();
                        changeSources({
                          ...sources,
                          [labelLower]: !sources[labelLower],
                        });
                      }}
                    />
                  </Frame>
                ))}
              </Inline>
            </Frame>
            <Frame>
              <Text
                as="h5"
                color="neutral-800"
                leading="normal"
                size="sm"
                className="mb-2"
              >
                Tags
              </Text>
              <Inline className="w-full">
                <SkillsTagsFilter
                  id="skills-filter"
                  placeholder={
                    skillsTags.length > 0 ? null : "No tags selected"
                  }
                  value={skillsTags}
                  skillsTags={skillsTagsData}
                  onChange={changeSkillsTags}
                />
              </Inline>
            </Frame>
          </Grid>
          <Inline className="mt-4" distribution="between">
            <Button
              color={isResetEnabled ? "primary" : "neutral-200"}
              border={isResetEnabled ? "primary" : "neutral-400"}
              variant="solid"
              size="xs"
              state={isResetEnabled ? "normal" : "disabled"}
              onClick={() => resetFilters()}
            >
              <Text color={isResetEnabled ? "white" : "neutral-500"}>
                Reset filters
              </Text>
            </Button>
            <Button
              color={isApplyEnabled ? "primary" : "neutral-200"}
              border={isApplyEnabled ? "primary" : "neutral-400"}
              variant="solid"
              state={isApplyEnabled ? "normal" : "disabled"}
              size="xs"
              onClick={updateContext}
            >
              <Text color={isApplyEnabled ? "white" : "neutral-500"}>
                Apply filters
              </Text>
            </Button>
          </Inline>
        </Frame>
      )}
      {children}
    </FiltersContext.Provider>
  );
}
