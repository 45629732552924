import React from "react";

import { useCollection } from "coreql";
import { Edit } from "@baseline/icons";
import {
  Center,
  Color,
  Corners,
  FlexDistribution,
  Grid,
  Image,
  Inline,
  Skeleton,
  Stack,
} from "@ableco/baseline";

import {
  TitleSmall,
  TitleSemibold,
  BodySmall,
} from "../../components/design-system/typography-components";
import { Main } from "../layout/layout";
import CoreLink from "./../../components/core-link";
import useCurrentUser from "../../hooks/use-current-user";
import PageLayout from "../layout/page-layout";
import { CoreTooltip } from "../core-tooltip";

function useProducts() {
  return useCollection("products", { sort: ["name"] }, "denormalized").data;
}

function ProductCard({
  id,
  name,
  tagline,
  logoUrl,
  archivedAt,
  isEditLinkVisible,
}) {
  return (
    <Stack
      as="article"
      bg={Color.White}
      border={Color.Neutral400}
      className={`group h-56 ${archivedAt ? "opacity-50" : ""}`}
      corners={Corners.MediumRounded}
      space={5}
      p={8}
    >
      <Inline distribution={FlexDistribution.Between}>
        <Image
          alt={name}
          className="w-6 h-6"
          data-testid={`product:logo:${id}`}
          source={logoUrl}
        />
        {isEditLinkVisible && (
          <CoreTooltip label={`Edit ${name} Logo`}>
            <CoreLink
              aria-label="Edit product"
              className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out"
              data-testid={`product:link:${id}`}
              href={`/products/${id}/edit`}
              isExternalLink={true}
            >
              <Edit className="text-neutral-600" />
            </CoreLink>
          </CoreTooltip>
        )}
      </Inline>

      <Stack space={2}>
        <TitleSemibold color={Color.Neutral800} data-testid="product:name">
          {name}
        </TitleSemibold>
        <BodySmall color={Color.Neutral700} className="truncate-3-lines">
          {tagline}
        </BodySmall>
      </Stack>
    </Stack>
  );
}

function ProductCardGrid({ products, isEditLinkVisible }) {
  return (
    <Grid gap={10} col={3} className="px-10">
      {products.map((product) => (
        <ProductCard
          key={product.id}
          id={product.id}
          name={product.name}
          tagline={product.tagline}
          logoUrl={product.logoUrl}
          archivedAt={product.archivedAt}
          isEditLinkVisible={isEditLinkVisible}
        />
      ))}
    </Grid>
  );
}

function ProductFallback() {
  return (
    <Skeleton
      color={Color.Neutral100}
      corners={Corners.MediumRounded}
      height="auto"
      width="100%"
      p={8}
    >
      <Skeleton
        className="mb-5"
        color={Color.Neutral400}
        corners={Corners.MediumRounded}
        size={24}
      />
      <Skeleton
        className="mb-2"
        color={Color.Neutral400}
        corners={Corners.SmallRounded}
        height={25}
        width={160}
      />
      <Skeleton
        color={Color.Neutral400}
        corners={Corners.SmallRounded}
        height={72}
        width="100%"
      />
    </Skeleton>
  );
}

export function ProductListFallback() {
  return (
    <Center width="100%">
      <Stack p={10} space={20} style={{ maxWidth: 860 }}>
        <Stack space={3}>
          <TitleSmall as="h3" color={Color.Neutral600}>
            Active Products
          </TitleSmall>
          <Grid gap={10} col={3}>
            {Array.from({ length: 12 }, (_, index) => (
              <ProductFallback key={index} />
            ))}
          </Grid>
        </Stack>

        <Stack space={3}>
          <TitleSmall as="h3" color={Color.Neutral600}>
            Archived Products
          </TitleSmall>
          <Grid gap={10} col={3}>
            {Array.from({ length: 12 }, (_, index) => (
              <ProductFallback key={index} />
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Center>
  );
}

function Products() {
  const { data: currentUser } = useCurrentUser();
  const canCurrentUserManageProducts =
    currentUser?.role?.permissions.includes("manage_products");

  const products = useProducts();
  const activeProducts = products.filter((product) => !product.archivedAt);
  const archivedProducts = products.filter((product) => product.archivedAt);

  return (
    <Stack>
      <PageLayout title="Products">
        <Main bg={Color.White} className="h-full">
          <Center>
            <Stack space={20} style={{ maxWidth: 860 }}>
              <Stack space={3} data-testid={`product:section:active`}>
                <TitleSmall as="h3" color={Color.Neutral600} className="px-10">
                  Active Products
                </TitleSmall>
                <ProductCardGrid
                  products={activeProducts}
                  isEditLinkVisible={canCurrentUserManageProducts}
                />
              </Stack>

              <Stack space={3} data-testid={`product:section:archived`}>
                <TitleSmall as="h3" color={Color.Neutral600} className="px-10">
                  Archived Products
                </TitleSmall>
                <ProductCardGrid
                  products={archivedProducts}
                  isEditLinkVisible={canCurrentUserManageProducts}
                />
              </Stack>
            </Stack>
          </Center>
        </Main>
      </PageLayout>
    </Stack>
  );
}

export default Products;
