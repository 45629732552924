import React from "react";

export default ({ fill = "#808494" }) => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 1600 1600"
    height="16"
    width="16"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          d="M984.52-1184.6a1.42 1.42 0 00-2 0l-5.87 7.39-3.29-3a1.42 1.42 0 00-2 0 1.36 1.36 0 000 1.94l4.59 4a1.48 1.48 0 001 .35 1.19 1.19 0 00.91-.35l6.65-8.41a1.42 1.42 0 00-.08-2"
          fill="none"
          clipRule="evenodd"
        />
      </clipPath>
    </defs>
    <path
      d="M1452 413.94c66.41-83.25 61.91-164 0-226.26-73.42-66.63-173.3-66.63-235.12 10L669.39 916.4 371.65 626.93c-61.91-62.22-163.22-62.22-225.13 0s-61.91 164 0 226.26l562.83 525.71"
      fill={fill}
    />
  </svg>
);
