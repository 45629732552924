import React, { useState } from "react";
import {
  ArrowRight,
  Calendar,
  DoubleTrackPreviousOutline,
  DoubleTrackNextOutline,
  CloseCircleFilled,
} from "@baseline/icons";
import { Flex } from "@ableco/baseline";
import ReactDatePicker from "react-datepicker";
import "./daterange-picker.css";
import {
  format,
  isWithinInterval,
  lastDayOfMonth,
  subYears,
  addYears,
} from "date-fns";
import { BodySmall } from "../../design-system/typography-components";

function resetMonthPicker(
  setStartDate,
  setEndDate,
  setResetMonthPickerAble,
  setStartMonthPickerMaxDate,
  setEndMonthPickerMinDate,
  defaultMaxDate,
  defaultMinDate,
) {
  setStartDate(null);
  setEndDate(null);
  setResetMonthPickerAble(false);
  setEndMonthPickerMinDate(defaultMinDate);
  setStartMonthPickerMaxDate(defaultMaxDate);
}

function changeMonthPickerValue(
  date,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setResetMonthPickerAble,
  monthPickerType,
  setStartMonthPickerMaxDate,
  setEndMonthPickerMinDate,
  defaultMaxDate,
  defaultMinDate,
) {
  let resetMonthPickerVal;

  const thereAreDefaults = !!defaultMinDate && !!defaultMaxDate;
  const dateIsOnRange = thereAreDefaults
    ? isWithinInterval(date, { start: defaultMinDate, end: defaultMaxDate })
    : false;

  if (dateIsOnRange) {
    if (monthPickerType === "start") {
      setStartDate(date);
      setEndMonthPickerMinDate(date);
      resetMonthPickerVal = !!date && !!endDate;
    } else if (monthPickerType === "end") {
      const monthDate = !!date ? lastDayOfMonth(date) : null;
      setEndDate(monthDate);
      setStartMonthPickerMaxDate(monthDate);
      resetMonthPickerVal = !!startDate && !!date;
    }
  }
  setResetMonthPickerAble(!!resetMonthPickerVal);
}

function CalendarHeader({
  date,
  decreaseYear,
  increaseYear,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
}) {
  return (
    <>
      <button
        type="button"
        onClick={decreaseYear}
        disabled={prevYearButtonDisabled}
        className="bg-transparent ml-4"
      >
        <DoubleTrackPreviousOutline className="w-4 h-4 opacity-50" />
      </button>
      <BodySmall className="font-sans">{format(date, "yyyy")}</BodySmall>
      <button
        type="button"
        onClick={increaseYear}
        disabled={nextYearButtonDisabled}
        className="bg-transparent mr-4"
      >
        <DoubleTrackNextOutline className="w-4 h-4 opacity-50" />
      </button>
    </>
  );
}

function MonthPickerComponent(props) {
  return (
    <ReactDatePicker
      dateFormat="yyyy-MMM"
      showMonthYearPicker
      renderCustomHeader={({
        date,
        decreaseYear,
        increaseYear,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
      }) =>
        CalendarHeader({
          date,
          decreaseYear,
          increaseYear,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
        })
      }
      popperClassName="month_picker__popper"
      popperPlacement="top-end"
      popperModifiers={{
        offset: {
          enabled: true,
          offset: "5px, 5px",
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: true,
          boundariesElement: "viewport",
        },
      }}
      disabledKeyboardNavigation
      {...props}
    />
  );
}

export default function MonthPicker({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  defaultMinDate = null,
  defaultMaxDate = null,
}) {
  const resetMonthPickerAbleInitState = !!(startDate && endDate);
  const [resetMonthPickerAble, setResetMonthPickerAble] = useState(
    resetMonthPickerAbleInitState,
  );
  const minDate = !!defaultMinDate ? defaultMinDate : subYears(new Date(), 100);
  const maxDate = !!defaultMaxDate ? defaultMaxDate : addYears(new Date(), 100);
  const [endMonthPickerMinDate, setEndMonthPickerMinDate] = useState(minDate);
  const [startMonthPickerMaxDate, setStartMonthPickerMaxDate] =
    useState(maxDate);
  return (
    <Flex
      data-testid="month-picker"
      className="w-full border rounded text-neutral-500 border-neutral-400 hover:border-primary-light focus-within:shadow-outline focus-within:border-primary-base transition-all duration-300 ease-in-out core-month-picker"
      direction="horizontal"
      alignment="center"
      distribution="between"
    >
      <MonthPickerComponent
        startDate={startDate}
        endDate={endDate}
        selected={startDate}
        selectsStart
        placeholderText="Start month"
        setDateStatus={setStartDate}
        setResetMonthPickerAble={setResetMonthPickerAble}
        minDate={minDate}
        maxDate={startMonthPickerMaxDate}
        className="month-picker-start"
        onChange={(date) =>
          changeMonthPickerValue(
            date,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            setResetMonthPickerAble,
            "start",
            setStartMonthPickerMaxDate,
            setEndMonthPickerMinDate,
            maxDate,
            minDate,
          )
        }
      />
      <ArrowRight className="w-8 h-8 mx-4" />
      <MonthPickerComponent
        startDate={startDate}
        endDate={endDate}
        selected={endDate}
        selectsEnd
        placeholderText="End month"
        setDateStatus={setEndDate}
        setResetMonthPickerAble={setResetMonthPickerAble}
        typeMonth="end"
        minDate={endMonthPickerMinDate}
        maxDate={maxDate}
        className="month-picker-end"
        onChange={(date) =>
          changeMonthPickerValue(
            date,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            setResetMonthPickerAble,
            "end",
            setStartMonthPickerMaxDate,
            setEndMonthPickerMinDate,
            maxDate,
            minDate,
          )
        }
      />
      {resetMonthPickerAble ? (
        <CloseCircleFilled
          className="w-8 h-8 cursor-pointer mr-4 text-neutral-600"
          data-testid="clean-picker"
          onClick={() =>
            resetMonthPicker(
              setStartDate,
              setEndDate,
              setResetMonthPickerAble,
              setStartMonthPickerMaxDate,
              setEndMonthPickerMinDate,
              maxDate,
              minDate,
            )
          }
        />
      ) : (
        <Calendar className="w-8 h-8 mr-4" />
      )}
    </Flex>
  );
}
