import React from "react";
import { Color, Inline, Text } from "@ableco/baseline";
import cn from "clsx";

export default function Radio({
  value,
  label = value,
  border = Color.Neutral400,
  distribution = "start",
  className,
  classNameContainer,
  ...props
}) {
  return (
    <Inline
      as="label"
      p={2}
      className={classNameContainer}
      border={border}
      distribution={distribution}
      corners="medium"
    >
      <Text
        className={cn(label && "mr-2", className)}
        value={value}
        size="xs"
        {...props}
        as="input"
        type="radio"
      />
      <Text as="span" size="sm" color="neutral-800">
        {label}
      </Text>
    </Inline>
  );
}
