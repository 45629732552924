import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useParams } from "react-router";
import { useResource } from "coreql";

import useCurrentUser from "../../hooks/use-current-user";
import FeedbackMessagesLayout from "./feedback-messages-layout";
import FeedbackMessagesReceived from "./feedback-messages-received";
import FeedbackMessagesSent from "./feedback-messages-sent";
import FeedbackMessage from "./feedback-message";

export default function FeedbackMessagesInbox() {
  const { userId } = useParams();

  const { data: requestedUser } = useResource(
    "users",
    userId,
    {
      fields: {
        users: ["id", "managerId"],
      },
    },
    "denormalized",
  );

  const { data: currentUser } = useCurrentUser();

  const [activeLabelTab, setActiveLabelTab] = useState(null);

  const isCurrentUserDirectManager =
    Number.parseInt(currentUser.id) === requestedUser.managerId;

  const isCurrentUserInboxOwner = currentUser.id === userId;

  return (
    <FeedbackMessagesLayout userId={userId} activeLabelTab={activeLabelTab}>
      <Switch>
        <Route path="/users/:userId/feedback_messages/received">
          <FeedbackMessagesReceived
            setActiveLabelTab={setActiveLabelTab}
            isCurrentUserDirectManager={isCurrentUserDirectManager}
            isCurrentUserInboxOwner={isCurrentUserInboxOwner}
          />
        </Route>
        <Route path="/users/:userId/feedback_messages/sent">
          <FeedbackMessagesSent
            setActiveLabelTab={setActiveLabelTab}
            isCurrentUserDirectManager={isCurrentUserDirectManager}
            isCurrentUserInboxOwner={isCurrentUserInboxOwner}
          />
        </Route>
        <Route path="/users/:userId/feedback_messages/:messageId">
          <FeedbackMessage
            setActiveLabelTab={setActiveLabelTab}
            isCurrentUserDirectManager={isCurrentUserDirectManager}
            isCurrentUserInboxOwner={isCurrentUserInboxOwner}
          />
        </Route>
      </Switch>
    </FeedbackMessagesLayout>
  );
}
