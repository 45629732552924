/* eslint react/no-danger:0 */
/* eslint react-hooks/rules-of-hooks:0 */
/* eslint react-hooks/exhaustive-deps:0 */
import React, { Suspense, useEffect } from "react";
import * as _ from "lodash";

import {
  Frame,
  Skeleton,
  Corners,
  Color,
  Stack,
  Flex,
  FlexDistribution,
  Inline,
} from "@ableco/baseline";
import formatDateRange from "../../utils/formatDateRange";

import TemperatureSection, {
  useWeeklyReportTemperature,
} from "./temperature-section";
import TimeEntriesSection from "./time-entries-section";
import AddProjectForm from "./add-project-form";
import QuestionsSection, {
  useWeeklyReportQuestions,
} from "./questions-section";
import CompanyFeedbackSection from "./company-feedback-section";
import FeedbackRequests, {
  useFeedbackRequests,
} from "./feedback/feedback-requests";
import SendFeedback, { useFeedbacksToSend } from "./send-feedback";
import CheersReceived, { useAllCheersReceived } from "./../cheers-received";
import CheersSent, { useAllCheersSent } from "./../cheers-sent";
import {
  Objectives,
  useObjectives,
  filterObjectivesThatCanReceiveAnUpdateInThisReport,
} from "./objectives";

import { SectionContainer, wrap, areMandatoryQuestionsAnswered } from "./utils";
import { ErrorBoundary, api, useCollection, useResource } from "coreql";
import Accordion from "../accordion";
import FeedbackReceived, {
  useReceivedFeedbackMessages,
} from "./feedback/feedback-received";
import { useWeeklyReportData } from "./weekly-report-hooks";
import ErrorFallback from "../error-fallback";
import Accomplishments, { useAccomplishments } from "./accomplishments";

import FeedbackSent, {
  useDismissedFeedbackRequest,
  useSentFeedbackMessages,
} from "./feedback/feedback-sent";
import { useHistory, useParams } from "react-router";
import { useCoreToast } from "../toast/toast";
import { BodyBaseline } from "../design-system/typography-components";
import useCurrentUser from "../../hooks/use-current-user";
import { PrimaryButton } from "../buttons/buttons";
import PageLayout from "../layout/page-layout";
import BackButton from "../layout/back-button";
import { useSWRSuspense } from "swr";
import { format, parseJSON } from "date-fns";
import UnsubmittedJournalNotice from "./unsubmitted-journal-notice";

async function timeEntryUpdate(entry) {
  await api.timeEntries.find(entry.id).update({ hours: entry.hours });
}

// This queue saves the last API calls for a key (unique identifier of a record)
// to allow the operations to be used for syncing the form data with the backend
// before doing submitting the form.
// The key is used to only save the last operation for a "item" (or answer)
// If isImmediate is true the queue will just call the provided callback
// without any queueing
function createOperationsQueue(isImmediate) {
  const queue = [];

  return {
    push(key, callback) {
      if (isImmediate) {
        return callback();
      } else {
        // Removing all the operations for the key since we want to only do
        // the last one.
        _.remove(queue, ([queuedKey]) => queuedKey === key);
        queue.push([key, callback]);
      }
    },
    async processAll() {
      for (let [_, callback] of queue) {
        // Processing all the async operations in order
        await callback();
      }
    },
    get length() {
      return queue.length;
    },
    get keys() {
      return queue.map(([key]) => key);
    },
    remove(key) {
      _.remove(queue, ([queuedKey]) => queuedKey === key);
    },
  };
}

export function SectionFallback() {
  return (
    <Stack space={4}>
      <Flex distribution={FlexDistribution.Between}>
        <Stack space={2} className="w-full">
          <Skeleton
            animated
            color={Color.Neutral300}
            corners={Corners.MediumRounded}
            height={20}
            width="20%"
          />
          <Skeleton
            animated
            color={Color.Neutral300}
            corners={Corners.MediumRounded}
            height={20}
            width="50%"
            alt="User full name"
          />
        </Stack>
        <Skeleton
          animated
          color={Color.Neutral300}
          corners={Corners.MediumRounded}
          height={40}
          width="30%"
        />
      </Flex>
      <Skeleton
        animated
        color={Color.Neutral300}
        corners={Corners.MediumRounded}
        height={20}
        width="30%"
      />
      <Skeleton
        animated
        color={Color.Neutral300}
        corners={Corners.MediumRounded}
        height={20}
        width="40%"
      />
      <Skeleton
        animated
        color={Color.Neutral300}
        corners={Corners.MediumRounded}
        height={20}
        width="60%"
      />
    </Stack>
  );
}

export function useWeeklyReport() {
  const weeklyId = useWeeklyReportId();
  return useResource("weekly-reports", weeklyId, {}, "denormalized");
}

export function useWeeklyReportId() {
  const { weeklyReportId } = useParams();
  return Number(weeklyReportId);
}

export function useProductAssignments(assigneeId, periodStart) {
  return useCollection(
    "product-assignments",
    {
      filters: {
        periodStart,
        assigneeId,
      },
      included: ["project", "product", "projectRole"],
    },
    "denormalized",
  );
}

export function useTimeEntries(weeklyReportId) {
  return useCollection(
    "time-entries",
    { filters: { weeklyReportId } },
    "denormalized",
  );
}

function WeeklyReportForm() {
  const weeklyReportId = useWeeklyReportId();
  const { data: weeklyReport } = useWeeklyReportData(weeklyReportId);
  const {
    periodStart: weeklyReportDateStart,
    periodEnd: weeklyReportDateEnd,
    submitted: weeklyReportSubmitted,
    user: weeklyReportOwner,
  } = weeklyReport;

  const { data: currentUser } = useCurrentUser();
  const [
    feedbackReceived,
    allCheersReceived,
    allObjectives,
    weeklyTimeEntries,
    productsAssignments,
    weeklyReportTemperature,
    weeklyReportAccomplishments,
    weeklyReportQuestions,
    feedbacksToSend,
    feedbackRequests,
    feedbackSent,
    allCheersSent,
    weeklyTimeEntriesMutate,
    dismissedRequestsMutate,
  ] = useSWRSuspense(() => {
    const { data: feedbacksToSend } = useFeedbacksToSend(
      currentUser.id,
      weeklyReportId,
    );
    const { data: weeklyTimeEntries, mutate: weeklyTimeEntriesMutate } =
      useTimeEntries(weeklyReportId);

    const { data: weeklyReportTemperature } =
      useWeeklyReportTemperature(weeklyReportId);
    const { data: weeklyReportQuestions } =
      useWeeklyReportQuestions(weeklyReportId);
    const { data: productsAssignments } = useProductAssignments(
      weeklyReportOwner.id,
      weeklyReportDateStart,
    );
    const { data: weeklyReportAccomplishments } =
      useAccomplishments(weeklyReportId);

    const { data: feedbackReceived } = useReceivedFeedbackMessages(
      weeklyReportId,
      weeklyReportSubmitted,
      weeklyReportDateEnd,
      weeklyReportOwner,
    );

    const { data: feedbackSent } = useSentFeedbackMessages(
      weeklyReportId,
      weeklyReportSubmitted,
      weeklyReportDateEnd,
      weeklyReportOwner,
    );
    const { data: allCheersReceived } = useAllCheersReceived(
      weeklyReportId,
      weeklyReportOwner?.id,
      weeklyReportSubmitted,
    );
    const allCheersSent = useAllCheersSent(
      weeklyReportId,
      weeklyReportOwner?.id,
      weeklyReportSubmitted,
    );

    const { data: allObjectives } = useObjectives(currentUser.id);

    const { data: feedbackRequests } = useFeedbackRequests(
      weeklyReportOwner.id,
      weeklyReportSubmitted,
    );

    const { mutate: dismissedRequestsMutate } = useDismissedFeedbackRequest(
      weeklyReportOwner.id,
      weeklyReportDateStart,
      weeklyReportDateEnd,
    );
    return [
      feedbackReceived,
      allCheersReceived,
      allObjectives,
      weeklyTimeEntries,
      productsAssignments,
      weeklyReportTemperature,
      weeklyReportAccomplishments,
      weeklyReportQuestions,
      feedbacksToSend,
      feedbackRequests,
      feedbackSent,
      allCheersSent,
      weeklyTimeEntriesMutate,
      dismissedRequestsMutate,
    ];
  });

  const temperature = weeklyReportTemperature.temperature;
  const questions = wrap(weeklyReportQuestions.questions);
  const achievedPlans = wrap(weeklyReportAccomplishments.achievedPlans);
  const achievements = wrap(weeklyReportAccomplishments.achievements);
  const plans = wrap(weeklyReportAccomplishments.plans);

  const { successToast, errorToast } = useCoreToast();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [isCompanyFeedbackGiven, setIsCompanyFeedbackGiven] =
    React.useState(false);

  const isAnyPlanAnswered = plans.length > 0;

  const [timeEntries, setTimeEntries] = React.useState(weeklyTimeEntries || []);

  const [savedAt, setSavedAt] = React.useState(null);
  const [feedbackRead, setFeedbackRead] = React.useState(
    !!weeklyReportSubmitted,
  );
  const [helpUsGrowRead, setHelpUsGrowRead] = React.useState(
    !!weeklyReportSubmitted,
  );
  const [thisWeekState, setThisWeekState] = React.useState("empty");
  const [growState, setGrowState] = React.useState("empty");
  const [dismissedRequestsIds, setDismissedRequestsIds] = React.useState([]);
  const [feedbackRequestsResponded, setFeedbackRequestsResponded] =
    React.useState(false);

  const operationsQueue = React.useRef(createOperationsQueue(true)).current;

  async function submitFeedbacks() {
    return await Promise.all(
      wrap(feedbacksToSend).map((feedback) =>
        feedback.body.trim() === ""
          ? api.feedbackMessages.find(feedback.id).destroy()
          : api.feedbackMessages
              .find(feedback.id)
              .update({ submittedAt: new Date() }),
      ),
    );
  }

  async function submitFeedbackReceived() {
    return await Promise.all(
      wrap(feedbackReceived)
        .filter((feedback) => !feedback.receiverWeeklyReportId)
        .map((feedback) =>
          api.feedbackMessages.find(feedback.id).update({
            receiverWeeklyReportId: weeklyReportId,
            readByReceiver: new Date(),
          }),
        ),
    );
  }

  async function submitFeedbackRequests() {
    const publishableFeedbackRequests = feedbackRequests.filter(
      ({ id }) =>
        !dismissedRequestsIds.includes(id) && !deferedRequestIds.has(id),
    );
    return await Promise.all(
      publishableFeedbackRequests.map((feedback) =>
        api.feedbackMessages.find(feedback.response.id).update({
          submittedAt: new Date(),
          weeklyReportId: weeklyReportId,
        }),
      ),
    );
  }

  async function dismissFeedbackRequests() {
    return await Promise.all(
      dismissedRequestsIds.map((requestId) =>
        api.feedbackRequests
          .find(requestId)
          .update({ dismissedAt: new Date() }),
      ),
    );
  }

  async function submitFeedbackSent() {
    return await Promise.all(
      wrap(feedbackSent)
        .filter((feedback) => !feedback.weeklyReportId)
        .map((feedback) =>
          api.feedbackMessages.find(feedback.id).update({
            weeklyReportId: weeklyReportId,
          }),
        ),
    );
  }

  async function submitCheersReceived() {
    return await Promise.all(
      wrap(allCheersReceived)
        .map((cheer) => {
          const { cheerMentions } = cheer;
          return wrap(cheerMentions).map((cheerMention) => {
            const {
              id: cheerMentionId,
              weeklyReportId: cheerWeeklyReportId,
              userId,
            } = cheerMention;
            if (
              cheerWeeklyReportId === null &&
              userId == weeklyReportOwner.id
            ) {
              return api.cheerMentions.find(cheerMentionId).update({
                weeklyReportId: weeklyReportId,
              });
            }
          });
        })
        .flat(),
    );
  }

  async function submitCheersSent() {
    return await Promise.all(
      wrap(allCheersSent).map((cheer) => {
        if (
          cheer.weeklyReportId === null && // Cheers without weekly
          cheer.author.id == weeklyReportOwner.id // cheers authored by current user
        ) {
          return api.cheers.find(cheer.id).update({
            weeklyReportId: weeklyReportId,
          });
        }
      }),
    );
  }

  async function submitWeeklyReport(weeklyReportId) {
    return api.weeklyReports.find(weeklyReportId).update({
      submitted: new Date(),
    });
  }

  // Syncs the form data from the operations queue with the backend
  // if the form has been previously submitted
  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);
    try {
      await submitFeedbacks();
      await submitFeedbackReceived();
      if (!weeklyReportSubmitted) {
        await submitFeedbackRequests();
      }
      await dismissFeedbackRequests();
      await submitFeedbackSent();
      await submitCheersReceived();
      await submitCheersSent();
      if (weeklyReportSubmitted) {
        // Sync mode is off
        // Processing all the pending operations
        await operationsQueue.processAll();
        // Submitting the form again
      }
      await submitWeeklyReport(weeklyReportId);
      dismissedRequestsMutate();
      successToast("Weekly report submitted successfully!");
      history.push("/weekly_reports/my_journal");
    } catch {
      errorToast(
        "We had a problem submitting your journal, please contact the team.",
      );
      setIsSubmitting(false);
    }
  }

  function toggleFeedback(event) {
    event.preventDefault();
    setFeedbackRead(true);
  }

  function toggleHelpUsGrow(event) {
    event.preventDefault();
    setHelpUsGrowRead(true);
  }

  function updateTimeEntry(entry, hours) {
    const index = timeEntries.indexOf(entry);
    if (index === -1) return;
    entry.hours = hours;
    setTimeEntries([
      ...timeEntries.slice(0, index),
      entry,
      ...timeEntries.slice(index + 1),
    ]);
    operationsQueue.push(`entry:${entry.id}`, () => timeEntryUpdate(entry));
    setSavedAt(new Date());
  }

  const isTemperatureAnswered = !!temperature?.data.value;

  const isAnyAchievementAnswered = achievements.length > 0;

  function areHoursReported() {
    if (timeEntries.length === 0) return true;
    return _.reduce(timeEntries, (acc, entry) => acc || entry.hours > 0, false);
  }

  function isAnyHourReported() {
    if (timeEntries.length === 0) return false;
    return areHoursReported();
  }

  function isAnyQuestionAnswered() {
    return (
      !_.isEmpty(questions) &&
      questions.some((question) => !_.isEmpty(question.answers))
    );
  }

  React.useEffect(() => {
    if (
      isAnyPlanAnswered &&
      isTemperatureAnswered &&
      areHoursReported() &&
      areMandatoryQuestionsAnswered(questions)
    ) {
      setThisWeekState("ready");
    } else if (
      isTemperatureAnswered ||
      isAnyAchievementAnswered ||
      isAnyPlanAnswered ||
      isAnyHourReported() ||
      isAnyQuestionAnswered()
    ) {
      setThisWeekState("progress");
    } else {
      setThisWeekState("empty");
    }

    if (feedbackRequestsResponded) {
      setGrowState("ready");
    } else if (isCompanyFeedbackGiven) {
      setGrowState("progress");
    } else {
      setGrowState("empty");
    }
  }, [
    temperature,
    achievedPlans,
    isAnyAchievementAnswered,
    timeEntries,
    isAnyPlanAnswered,
    isCompanyFeedbackGiven,
    feedbackRequestsResponded,
    questions,
  ]);

  const objectives = filterObjectivesThatCanReceiveAnUpdateInThisReport(
    allObjectives,
    weeklyReportDateStart,
    weeklyReportId,
  );

  const [objectivesSectionState, setObjectivesSectionState] =
    React.useState("empty");

  useEffect(() => {
    const areFeedbackRequestsAnswered = feedbackRequests.every(
      (request) =>
        deferedRequestIds.has(request.id) ||
        (request.response &&
          request.response.body &&
          request.response.body.trim() &&
          (!request.feedbackPrompt ||
            request.feedbackPrompt.promptType == "text" ||
            request.response.promptAnswer != null)) ||
        dismissedRequestsIds.includes(request.id),
    );

    setFeedbackRequestsResponded(areFeedbackRequestsAnswered);
  }, [dismissedRequestsIds, setFeedbackRequestsResponded, feedbackRequests]);

  function isFeedbackDeferred(feedbackRequest) {
    return (
      feedbackRequest.feedbackRequestDeferral &&
      new Date(feedbackRequest.feedbackRequestDeferral.deferredAt) >=
        new Date(weeklyReportDateStart)
    );
  }
  const filterFeedbackRequest = feedbackRequests
    .filter((feedbackRequest) => isFeedbackDeferred(feedbackRequest))
    .map((feedbackRequest) => feedbackRequest.id);
  const deferedRequestIds = new Set(filterFeedbackRequest);

  function getObjectivesSectionState() {
    if (objectives.length === 0) return "ready";

    const reportUpdates = _.reduce(
      objectives,
      (updates, objective) => [
        ...updates,
        ...wrap(objective.objectiveStatusUpdates || []).filter(
          (status) => status.weeklyReportId == weeklyReportId,
        ),
      ],
      [],
    );

    if (reportUpdates.length === 0) return "empty";

    const isReady =
      reportUpdates.every((status) => status.status !== "default") &&
      reportUpdates.length === objectives.length;

    if (isReady) return "ready";

    const isNotStarted =
      reportUpdates.every((status) => status.status === "default") &&
      reportUpdates.length === objectives.length;

    if (isNotStarted) return "empty";

    return "progress";
  }

  React.useEffect(() => {
    setObjectivesSectionState(getObjectivesSectionState());
  }, [objectives]);

  const submitEnabled =
    feedbackRead &&
    thisWeekState === "ready" &&
    growState === "ready" &&
    objectivesSectionState === "ready";

  React.useEffect(() => {
    setTimeEntries(weeklyTimeEntries);
    setSavedAt(new Date());
  }, [weeklyTimeEntries]);

  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <PageLayout
        title="Weekly Journal"
        subTitle={formatDateRange(weeklyReportDateStart, weeklyReportDateEnd)}
        backButton={
          <BackButton
            text="Back to Journal History"
            href="/weekly_reports/my_journal"
          />
        }
      >
        <Frame style={{ maxWidth: "1040px" }}>
          <UnsubmittedJournalNotice
            weeklyReportDateStart={weeklyReportDateStart}
            weeklyReportOwnerId={weeklyReportOwner.id}
          />

          <Frame>
            <Accordion
              title="Feedback and cheers"
              state={feedbackRead ? "ready" : "empty"}
              onActivate={toggleFeedback}
            >
              <Suspense fallback={<SectionFallback />}>
                <FeedbackReceived
                  weeklyReportId={weeklyReportId}
                  weeklyReportDateStart={weeklyReportDateStart}
                  weeklyReportDateEnd={weeklyReportDateEnd}
                  weeklyReportSubmitted={weeklyReportSubmitted}
                  weeklyReportOwner={weeklyReportOwner}
                  currentUser={currentUser}
                />
                <CheersReceived
                  weeklyReportId={weeklyReportId}
                  weeklyReportUser={weeklyReportOwner}
                  currentUserId={currentUser.id}
                  weeklySubmitted={!!weeklyReportSubmitted}
                />
              </Suspense>
            </Accordion>
            {objectives.length > 0 && (
              <Accordion title="Objectives" state={objectivesSectionState}>
                <Suspense fallback={<SectionFallback />}>
                  <Objectives
                    userId={currentUser.id}
                    journalId={weeklyReportId}
                    weeklyReportPeriodStart={weeklyReportDateStart}
                    managerId={weeklyReportOwner.managerId}
                    weeklyUser={weeklyReportOwner}
                  />
                </Suspense>
              </Accordion>
            )}
            <Accordion title="How this week went" state={thisWeekState}>
              <Suspense fallback={<SectionFallback />}>
                <TemperatureSection
                  weeklyReportId={weeklyReportId}
                  setSavedAt={setSavedAt}
                />

                <SectionContainer>
                  <TimeEntriesSection
                    weeklyReportId={weeklyReportId}
                    weeklyReportPeriodStart={weeklyReportDateStart}
                    updateTimeEntry={updateTimeEntry}
                    weeklyTimeEntriesMutate={weeklyTimeEntriesMutate}
                    productsAssignments={productsAssignments}
                    timeEntries={timeEntries}
                  />
                  <AddProjectForm
                    assigneeId={weeklyReportOwner.id}
                    weeklyReportId={weeklyReportId}
                    weeklyReportDateStart={weeklyReportDateStart}
                  />
                </SectionContainer>
                <Accomplishments
                  weeklyReportId={weeklyReportId}
                  setSavedAt={setSavedAt}
                />
                <QuestionsSection
                  weeklyReportId={weeklyReportId}
                  setSavedAt={setSavedAt}
                />
              </Suspense>
            </Accordion>
            <Accordion
              title="Help us grow"
              onActivate={toggleHelpUsGrow}
              state={helpUsGrowRead ? growState : "empty"}
            >
              <Suspense fallback={<SectionFallback />}>
                <Frame className="my-4">
                  <FeedbackSent
                    weeklyReportId={weeklyReportId}
                    weeklyReportDateStart={weeklyReportDateStart}
                    weeklyReportDateEnd={weeklyReportDateEnd}
                    weeklyReportSubmitted={weeklyReportSubmitted}
                    weeklyReportOwner={weeklyReportOwner}
                    currentUser={currentUser}
                  />
                </Frame>

                {!weeklyReportSubmitted && (
                  <FeedbackRequests
                    weeklyReportUserId={weeklyReportOwner.id}
                    dismissedRequestsIds={dismissedRequestsIds}
                    setDismissedRequestsIds={setDismissedRequestsIds}
                    weeklyReportDateStart={weeklyReportDateStart}
                  />
                )}

                <SendFeedback
                  weeklyReportId={weeklyReportId}
                  updateSavedAt={() => setSavedAt(new Date())}
                />
                <CheersSent
                  weeklyReportId={weeklyReportId}
                  weeklyReportUser={weeklyReportOwner}
                  currentUserId={currentUser.id}
                  weeklySubmitted={!!weeklyReportSubmitted}
                />

                <CompanyFeedbackSection
                  weeklyReportId={weeklyReportId}
                  setIsCompanyFeedbackGiven={setIsCompanyFeedbackGiven}
                />
              </Suspense>
            </Accordion>
          </Frame>
        </Frame>
      </PageLayout>
      <Frame
        p={[0, 10, 2, 10]}
        className="border-t border-neutral-300 sticky bottom-0"
        bg={Color.White}
      >
        <Footer
          submitText={isSubmitting ? "Submitting..." : "Submit Journal"}
          submittedAt={weeklyReportSubmitted}
          savedAt={!weeklyReportSubmitted ? savedAt : null}
          disabled={!submitEnabled || isSubmitting}
          handleSubmit={handleSubmit}
        />
      </Frame>
    </ErrorBoundary>
  );
}

export function Footer({
  submitText,
  submittedAt,
  savedAt,
  disabled,
  handleSubmit,
}) {
  let formattedSavedAt = "Never";
  if (savedAt) {
    const date = savedAt.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    const time = savedAt.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    formattedSavedAt = `${date} - ${time}`;
  }

  return (
    <Inline className="w-full mt-5" p={[0, 0, 3, 0]} distribution="between">
      {submittedAt ? (
        <BodyBaseline color="neutral-600">
          Date Submitted: {format(parseJSON(submittedAt), "MMMM d, yyy")}
        </BodyBaseline>
      ) : (
        <BodyBaseline color="neutral-600">
          Draft Saved: {formattedSavedAt}
        </BodyBaseline>
      )}
      <PrimaryButton
        disabled={!!disabled}
        text={submitText}
        onClick={handleSubmit}
      />
    </Inline>
  );
}

export function WeeklyReportFromFallback() {
  return (
    <PageLayout
      title="Weekly Journal"
      subTitle={
        <Skeleton
          animated
          color={Color.Neutral300}
          corners={Corners.MediumRounded}
          height={24.75}
          width="20%"
          className="mt-3"
        />
      }
      backButton={
        <BackButton
          text="Back to Journal History"
          href="/weekly_reports/my_journal"
        />
      }
    >
      <Stack space={6} style={{ maxWidth: "1040px" }}>
        <Inline distribution={FlexDistribution.Between}>
          <Skeleton
            animated
            color={Color.Neutral300}
            corners={Corners.MediumRounded}
            height={33}
            width="25%"
          />
          <Skeleton
            animated
            color={Color.Neutral300}
            corners={Corners.FullRounded}
            height={24}
            width={24}
          />
        </Inline>
        <Inline distribution={FlexDistribution.Between}>
          <Skeleton
            animated
            color={Color.Neutral300}
            corners={Corners.MediumRounded}
            height={33}
            width="40%"
          />
          <Skeleton
            animated
            color={Color.Neutral300}
            corners={Corners.FullRounded}
            height={24}
            width={24}
          />
        </Inline>
        <Inline distribution={FlexDistribution.Between}>
          <Skeleton
            animated
            color={Color.Neutral300}
            corners={Corners.MediumRounded}
            height={33}
            width="30%"
          />
          <Skeleton
            animated
            color={Color.Neutral300}
            corners={Corners.FullRounded}
            height={24}
            width={24}
          />
        </Inline>
      </Stack>
    </PageLayout>
  );
}

export default WeeklyReportForm;
