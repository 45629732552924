import React, { useEffect, useState } from "react";
import noop from "lodash/noop";
import {
  Color,
  Corners,
  Frame,
  Inline,
  Stack,
  Table,
  Text,
  Image,
  Center,
  Touchable,
} from "@ableco/baseline";
import { Add, Subtract } from "@baseline/icons";
import Radio from "../data-entry/radio";
import {
  BodyBaselineSemibold,
  BodySmall,
  TitleSemibold,
} from "../design-system/typography-components";
import { api } from "coreql";
import { addDays, eachDayOfInterval, isSameDay, parseISO } from "date-fns";

const columnsBusinessDays = [
  { label: <LabelHeader label="Time" />, name: "time" },
  { label: <LabelHeader label="Monday" />, name: "monday" },
  { label: <LabelHeader label="Tuesday" />, name: "tuesday" },
  { label: <LabelHeader label="Wednesday" />, name: "wednesday" },
  { label: <LabelHeader label="Thursday" />, name: "thursday" },
  { label: <LabelHeader label="Friday" />, name: "friday" },
];

const columnsWeekendDays = [
  { label: <LabelHeader label="Saturday" />, name: "saturday" },
  { label: <LabelHeader label="Sunday" />, name: "sunday" },
];

const daysOfTheWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const timeValues = [
  {
    label: <BodySmall>An hour or less</BodySmall>,
    hours: 1,
  },
  {
    label: <BodySmall>A few hours</BodySmall>,
    hours: 3,
  },
  {
    label: <BodySmall>Most of the day</BodySmall>,
    hours: 8,
  },
];

function LabelHeader({ label }) {
  return <BodySmall color={Color.Neutral600}>{label}</BodySmall>;
}

function TimeTable({ id, timeEntries, updateTimeEntry, readOnly }) {
  const columnsWithoutWeekend = [
    ...columnsBusinessDays,
    {
      label: (
        <Center className="w-full h-full">
          <Touchable
            onClick={toggleShowWeekend}
            data-testid={`product:table:button:plus`}
            aria-label="Show weekend"
          >
            <Text color={Color.Neutral600}>
              <Add className="w-4 h-4" />
            </Text>
          </Touchable>
        </Center>
      ),
    },
  ];

  const columnsWithWeekend = [
    ...columnsBusinessDays,
    ...columnsWeekendDays,
    {
      label: (
        <Center className="w-full h-full">
          <Touchable
            onClick={toggleShowWeekend}
            data-testid={`product:table:button:subtract`}
            aria-label="Hide weekend"
          >
            <Text color={Color.Neutral600}>
              <Subtract className="w-4 h-4" />
            </Text>
          </Touchable>
        </Center>
      ),
    },
  ];
  const [showWeekend, setShowWeekend] = useState(false);

  function toggleShowWeekend() {
    setShowWeekend(!showWeekend);
  }

  function toggleValue(item, value) {
    const newValue = item.hours === value ? 0 : value;
    updateTimeEntry(item, newValue);
  }

  useEffect(() => {
    setShowWeekend(
      showWeekend || timeEntries[5]?.hours > 0 || timeEntries[6]?.hours > 0,
    );
  }, [timeEntries]);

  function buildRowData(timeEntries) {
    const rows = [];
    for (const [_, timeValue] of timeValues.entries()) {
      const row = {
        time: timeValue.label,
      };

      for (const [i, day] of daysOfTheWeek.entries()) {
        row[day] = (
          <Radio
            key={day}
            checked={timeEntries[i]?.hours === timeValue.hours}
            onClick={() => toggleValue(timeEntries[i], timeValue.hours)}
            onChange={() => {}}
            border={Color.Transparent}
            distribution="center"
            className="justify-self-center mr-0"
            classNameContainer="m-0 p-3"
            data-testid={`product:radio:${timeEntries[i]?.id}:${timeValue.label.props.children}`}
            aria-label="Hours dedicated"
            disabled={readOnly}
          />
        );
      }
      rows.push(row);
    }
    return rows;
  }

  return (
    <Inline distribution="around">
      <Table
        className="w-full table-fixed border-none mt-0"
        columns={showWeekend ? columnsWithWeekend : columnsWithoutWeekend}
        data={buildRowData(timeEntries)}
        th={{
          p: 2,
          bg: Color.Neutral100,
          border: Color.Neutral300,
          className:
            "first:text-left first:w-40 last:w-8 first:border-l-0 last:border-r-0 text-center",
        }}
        td={{
          border: Color.Neutral300,
          className: "first:px-2 first:border-l-0 last:border-r-0 border-b-0",
        }}
        data-testid={`product:table:${id}`}
      />
    </Inline>
  );
}

function ProductCardHeader({
  id,
  logo,
  productName,
  projectName,
  projectRole,
}) {
  return (
    <Inline p={3} space={2}>
      <Frame corners="medium" as="figure" className="self-start">
        <Image
          source={logo}
          className="w-6 h-6"
          data-testid={`product:logo:${id}`}
          alt="logo"
        />
      </Frame>
      <Stack>
        <Inline space={2}>
          <BodyBaselineSemibold>{productName}</BodyBaselineSemibold>
          <BodySmall color={Color.Neutral700}>{projectName}</BodySmall>
        </Inline>
        <BodySmall className="mt-0" color={Color.Neutral600}>
          {projectRole}
        </BodySmall>
      </Stack>
    </Inline>
  );
}

function ProductCard({
  weeklyReportId,
  weeklyReportPeriodStart,
  productAssignment,
  timeEntries,
  updateTimeEntry,
  weeklyTimeEntriesMutate,
  readOnly,
}) {
  useEffect(() => {
    async function createTimeEntries() {
      const start = parseISO(weeklyReportPeriodStart);

      const datesInPeriod = eachDayOfInterval({
        start: start,
        end: addDays(start, 6),
      });
      const missingDates = datesInPeriod.filter(
        (day) =>
          !timeEntries.find((entry) => isSameDay(parseISO(entry.date), day)),
      );

      if (missingDates.length > 0) {
        await Promise.all(
          missingDates.map((day) =>
            api.timeEntries.create({
              date: day,
              weeklyReportId,
              projectRoleId: productAssignment.projectRoleId,
              projectId: productAssignment.projectId,
            }),
          ),
        );
        weeklyTimeEntriesMutate();
      }
    }
    if (!readOnly) {
      createTimeEntries();
    }
  }, []);

  if (timeEntries.length === 0) return <p>Loading...</p>;

  return (
    <Frame
      bg={Color.White}
      border={Color.Neutral300}
      corners={Corners.MediumRounded}
      data-testid="product:card"
    >
      <ProductCardHeader
        id={productAssignment.id}
        logo={productAssignment.product.logoUrl}
        name={productAssignment.product.name}
        productName={productAssignment.product.name}
        projectName={productAssignment.project.name}
        projectRole={productAssignment.projectRole.name}
      />
      <TimeTable
        id={productAssignment.id}
        timeEntries={timeEntries}
        updateTimeEntry={updateTimeEntry}
        readOnly={readOnly}
      />
    </Frame>
  );
}

function filterTimeEntriesByAssignment(productAssignment, timeEntries) {
  return timeEntries.filter(
    (entry) =>
      entry.projectId == productAssignment.projectId &&
      entry.projectRoleId == productAssignment.projectRoleId,
  );
}
function TimeEntriesSection({
  weeklyReportId,
  weeklyReportPeriodStart,
  weeklyTimeEntriesMutate,
  updateTimeEntry = noop,
  productsAssignments = [],
  timeEntries = [],
  readOnly = false,
}) {
  return (
    <Stack space={3} className="mb-3">
      {readOnly ? (
        <TitleSemibold as="h3" className="w-full" color={Color.Neutral800}>
          Time investment
        </TitleSemibold>
      ) : (
        <TitleSemibold>What products did you work on?</TitleSemibold>
      )}

      {productsAssignments.map((productAssignment) => (
        <ProductCard
          key={productAssignment.id}
          weeklyReportId={weeklyReportId}
          weeklyReportPeriodStart={weeklyReportPeriodStart}
          productAssignment={productAssignment}
          timeEntries={filterTimeEntriesByAssignment(
            productAssignment,
            timeEntries,
          )}
          updateTimeEntry={updateTimeEntry}
          weeklyTimeEntriesMutate={weeklyTimeEntriesMutate}
          readOnly={readOnly}
        />
      ))}
    </Stack>
  );
}

export default TimeEntriesSection;
