import { useCollection } from "coreql";

function useUsers() {
  return useCollection("users", {
    included: ["role", "location"],
    fields: {
      users: ["active", "fullName", "preferredName", "role", "location"],
      roles: ["title", "departmentTitle", "assignable"],
      locations: ["name"],
    },
  });
}

export default useUsers;
