// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Constants
import AppTypes from "core-redux/constants/AppTypes";
// Actions
import * as API from "core-redux/actions/api";
// Components
import App from "./App";

class AppContainer extends PureComponent {
  static propTypes = {
    fetchProject: PropTypes.func.isRequired,
    project: AppTypes.project,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.bootstrapData();
  }

  bootstrapData() {
    const { fetchProject } = this.props;

    Promise.all([fetchProject()]).then(() =>
      this.setState({ isLoading: false }),
    );
  }

  render() {
    const { project } = this.props;
    const { isLoading } = this.state;

    return <App isLoading={isLoading} project={project} />;
  }
}

const mapStateToProps = (state) => {
  const { projectId } = state;
  const project = state.entities.projects.byId[projectId];

  return {
    project,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProject: async () =>
    await dispatch((dispatch, getState) => {
      const { projectId } = getState();
      return dispatch(API.retrieve("projects", projectId));
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
