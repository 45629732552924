import React from "react";
import { Grid } from "@ableco/baseline";
import { format, parseISO } from "date-fns";

import {
  BodySmall,
  BodyBaselineSemibold,
} from "../design-system/typography-components";
import { MarkdownViewer } from "../markdown/markdown-viewer";

import WeeklyQuestionCell from "./weekly-question-cell";

export function WeeklyTextQuestionTable({ answers, roles, locations }) {
  const answersCount = answers.length;

  return (
    <Grid col={7} className="mt-4">
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Role
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Department
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Location
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Submitted
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-3" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Answer
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      {answers.map((answer, index) => (
        <AnswerRow
          key={answer.id}
          index={index}
          answer={answer}
          answersCount={answersCount}
          role={roles.find(
            (role) =>
              Number.parseInt(role.id) === answer.weeklyReport.user.roleId,
          )}
          location={locations.find(
            (location) =>
              Number.parseInt(location.id) ===
              answer.weeklyReport.user.locationId,
          )}
          questionType="text"
        ></AnswerRow>
      ))}
    </Grid>
  );
}

export function WeeklyScaleQuestionTable({ answers, roles, locations }) {
  const answersCount = answers.length;

  return (
    <Grid col={7} className="mt-4">
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Role
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Department
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Location
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Submitted
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-1" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Answer
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell className="col-span-2" p={4}>
        <BodyBaselineSemibold className="text-neutral-700">
          Additional Information
        </BodyBaselineSemibold>
      </WeeklyQuestionCell>
      {answers.map((answer, index) => (
        <AnswerRow
          key={answer.id}
          index={index}
          answer={answer}
          answersCount={answersCount}
          role={roles.find(
            (role) =>
              Number.parseInt(role.id) === answer.weeklyReport.user.roleId,
          )}
          location={locations.find(
            (location) =>
              Number.parseInt(location.id) ===
              answer.weeklyReport.user.locationId,
          )}
          questionType="rating_scale"
        />
      ))}
    </Grid>
  );
}

function AnswerRow({
  answer,
  index,
  answersCount,
  role,
  location,
  questionType,
}) {
  function getSubmittedDateFormated(answer) {
    return format(parseISO(answer.weeklyReport.submitted), "d-MMM");
  }

  const firstRow = index == 0;
  const lastRow = index + 1 == answersCount;
  const evenRow = index % 2 == 1;

  return (
    <>
      <WeeklyQuestionCell
        firstRow={firstRow}
        lastRow={lastRow}
        firstColumn={true}
        evenRow={evenRow}
        className="col-span-1"
        data-testid={`answer-${answer.id}-role`}
      >
        <BodySmall color="neutral-700">
          {role === undefined ? "N/A" : role.disciplineTitle}
        </BodySmall>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell
        firstRow={firstRow}
        lastRow={lastRow}
        evenRow={evenRow}
        className="col-span-1"
        data-testid={`answer-${answer.id}-department`}
      >
        <BodySmall color="neutral-700">
          {role === undefined ? "N/A" : role.departmentTitle}
        </BodySmall>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell
        firstRow={firstRow}
        lastRow={lastRow}
        evenRow={evenRow}
        className="col-span-1"
        data-testid={`answer-${answer.id}-location`}
      >
        <BodySmall color="neutral-700">
          {location === undefined ? "N/A" : location.name}
        </BodySmall>
      </WeeklyQuestionCell>
      <WeeklyQuestionCell
        firstRow={firstRow}
        lastRow={lastRow}
        evenRow={evenRow}
        className="col-span-1"
        data-testid={`answer-${answer.id}-submitted`}
      >
        <BodySmall color="neutral-700">
          {getSubmittedDateFormated(answer)}
        </BodySmall>
      </WeeklyQuestionCell>
      {questionType === "text" ? (
        <WeeklyQuestionCell
          firstRow={firstRow}
          lastRow={lastRow}
          lastColumn={true}
          evenRow={evenRow}
          className="col-span-3"
          data-testid={`answer-${answer.id}-textAnswer`}
        >
          <BodySmall color="neutral-700">
            <MarkdownViewer value={answer.data.text} />
          </BodySmall>
        </WeeklyQuestionCell>
      ) : null}
      {questionType === "rating_scale" ? (
        <>
          <WeeklyQuestionCell
            firstRow={firstRow}
            lastRow={lastRow}
            evenRow={evenRow}
            className="col-span-1"
            data-testid={`answer-${answer.id}-scaleAnswer`}
          >
            <BodySmall color="neutral-700">{answer.data.text}</BodySmall>
          </WeeklyQuestionCell>
          <WeeklyQuestionCell
            firstRow={firstRow}
            lastRow={lastRow}
            lastColumn={true}
            evenRow={evenRow}
            className="col-span-2"
            data-testid={`answer-${answer.id}-additional`}
          >
            <BodySmall color="neutral-700">
              <MarkdownViewer value={answer.data.additionalInformationText} />
            </BodySmall>
          </WeeklyQuestionCell>
        </>
      ) : null}
    </>
  );
}
