import React, { useEffect, useState } from "react";
import { useCollection, denormalize, SSRSuspense, ErrorBoundary } from "coreql";
import {
  Inline,
  Stack,
  Text,
  Frame,
  Flex,
  Color,
  FlexDistribution,
  Skeleton,
  FlexAlignment,
} from "@ableco/baseline";
import { CompanyObjective } from "@baseline/icons";
import { wrap } from "../weekly-report/utils";
import produce from "immer";
import ObjectiveLayout from "./objective-layout";
import { getCurrentQuarter, getQuarterTitle } from "../../utils/core-quarters";
import Accordion from "../../components/accordion";
import CoreLink from "../core-link";
import {
  BodyBaseline,
  BodySmall,
} from "../design-system/typography-components";
import { StatusIcon, STATUS, getObjectiveStatus } from "./objectives-utils";
import Select from "../data-entry/select";
import useCurrentUser from "../../hooks/use-current-user";
import { capitalize } from "lodash";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../hooks/use-query";
import { AvatarMedium } from "../design-system/avatar";
import { CoreTooltip } from "../core-tooltip";

const defaultFilterState = {
  quarter: () => getCurrentQuarter(),
  department: { id: null, title: "All Departments" },
  userObjective: { id: null, fullName: "Everyone" },
  objectiveStatus: { id: null, value: "disabled" },
};

function getObjectivesOptions(
  quarter,
  departmentId = null,
  ownerId = null,
  currentStatusUpdate = null,
) {
  let objectiveResource = {
    included: [
      "owner",
      "department",
      "keyResults",
      "currentStatusUpdate",
      "keyResults.currentStatusUpdate",
      "childObjectives",
    ],
    sort: ["id"],
    fields: {
      users: ["fullName", "avatarUrl"],
      departments: ["title"],
      objectives: [
        "title",
        "department",
        "owner",
        "keyResults",
        "currentStatusUpdate",
        "closedAt",
        "score",
        "companyObjective",
        "childObjectives",
        "parentObjectiveId",
      ],
      keyResults: [
        "title",
        "dueDate",
        "objectiveId",
        "kind",
        "target",
        "start",
        "currentStatusUpdate",
        "decreasing",
      ],
      objectiveStatusUpdates: ["status"],
      keyResultStatusUpdates: ["status"],
    },
    filters: { quarter: quarter, active: true },
  };
  if (departmentId) {
    objectiveResource.filters["departmentId"] = departmentId;
  }
  if (ownerId) {
    objectiveResource.filters["ownerId"] = ownerId;
  }
  if (currentStatusUpdate) {
    objectiveResource.filters["currentStatusUpdate.status"] =
      currentStatusUpdate;
  }
  return objectiveResource;
}

function useObjectives({
  quarter,
  departmentId = null,
  ownerId = null,
  currentStatusUpdate = null,
}) {
  return useCollection(
    "objectives",
    getObjectivesOptions(quarter, departmentId, ownerId, currentStatusUpdate),
    "denormalized",
  ).data;
}

function useDepartments(quarter) {
  const { data: departmentsRawData } = useCollection("objectives", {
    filters: { quarter: quarter, active: true },
    fields: { objectives: ["id"] },
    included: ["department"],
  });
  const departments = wrap(denormalize(departmentsRawData, "departments"));

  return [...departments].sort((a, b) => a.title.localeCompare(b.title));
}

function useUserObjectives(quarter) {
  const {
    data: { id: currentUserId },
  } = useCurrentUser();
  const { data: userObjectivesRawData } = useCollection("objectives", {
    filters: { quarter: quarter, active: true },
    fields: { users: ["fullName"] },
    included: ["owner"],
  });
  const users = [...wrap(denormalize(userObjectivesRawData, "users"))].sort(
    (a, b) => a.fullName.localeCompare(b.fullName),
  );

  return users.map((user) =>
    user.id === currentUserId
      ? { ...user, fullName: `${user.fullName} (me)` }
      : user,
  );
}

function useQuarters() {
  const { data: quartersRawData } = useCollection("objectives", {
    filters: { active: true },
    fields: { objectives: ["id", "quarter"] },
    included: ["department"],
  });
  const quarters = [
    ...new Set(quartersRawData.data.map((a) => a.attributes.quarter)),
  ];

  return [...quarters].sort((a, b) => b.localeCompare(a));
}

function isDefaultQuarter(current) {
  return defaultFilterState.quarter() === current;
}
function isDefaultDepartment(current) {
  return defaultFilterState.department.id === current;
}
function isDefaultUserObjective(current) {
  return defaultFilterState.userObjective.id === current;
}
function isDefaultObjectiveStatus(current) {
  return defaultFilterState.objectiveStatus.id === current;
}

function Status({ status, amount = null }) {
  const STATUS = {
    at_risk: { color: "alert", title: "at risk" },
    behind: { color: "warning", title: "behind" },
    on_track: { color: "success", title: "on track" },
  };

  if (!status || status == "default") return null;

  return (
    <Inline space={2}>
      <StatusIcon status={status} className="h-4 w-4" />
      {status.includes("score") ? (
        <Inline space={1}>
          <Text
            size="sm"
            color="neutral-600"
            transform="capitalize"
            className="group-hover:text-neutral-800"
            leading="normal"
            whitespace="no-wrap"
            weight="normal"
          >
            {status.split(" ")[0]}
          </Text>
          <Text
            size="sm"
            color="neutral-600"
            transform="capitalize"
            className="group-hover:text-neutral-800"
            leading="normal"
            whitespace="no-wrap"
            weight="semibold"
          >
            {status.split(" ")[1]}
          </Text>
        </Inline>
      ) : (
        <Text
          size="sm"
          color="neutral-600"
          transform="capitalize"
          className="group-hover:text-neutral-800"
          leading="normal"
          whitespace="no-wrap"
          weight="normal"
        >
          {amount} {STATUS[status].title}
        </Text>
      )}
    </Inline>
  );
}

function ObjectiveItem({ objective }) {
  return (
    <CoreLink
      className="hover:no-underline w-full"
      href={`/objectives/${objective.id}`}
      data-testid={`link-to-objective-${objective.id}`}
    >
      <Flex
        className="py-4 w-full pl-10 overflow-hidden group hover:bg-neutral-100 rounded transition-colors duration-300 ease-in-out"
        distribution={FlexDistribution.Between}
        alignment={FlexAlignment.Center}
      >
        <Inline space={2}>
          <AvatarMedium
            url={objective.owner?.avatarUrl}
            name={objective.owner?.fullName}
            withTooltip
          />

          <Stack>
            <BodyBaseline className="text-left" color={Color.Neutral700}>
              {objective?.title}
            </BodyBaseline>
            <Inline>
              {objective?.companyObjective && (
                <CoreTooltip label="Company Objective">
                  <Frame>
                    <CompanyObjective
                      className="h-3 w-3 mr-1 hover:opacity-100 focus:opacity-100 transition-all duration-300 ease-in-out"
                      data-testid="company-objective-icon"
                    />
                  </Frame>
                </CoreTooltip>
              )}
              <BodySmall color={Color.Neutral600}>
                {objective.department?.title}
              </BodySmall>
            </Inline>
          </Stack>
        </Inline>
        <Frame className="h-6 w-6 mr-4 ml-16">
          <StatusIcon
            status={getObjectiveStatus(objective)}
            className="h-6 w-6"
            tooltip={true}
          />
        </Frame>
      </Flex>
    </CoreLink>
  );
}

function ObjectiveItemAccordionContent({ childObjectives, isOpen }) {
  return (
    <Frame>
      {childObjectives.map((objective, index) => (
        <ObjectiveItemAccordion
          key={index}
          objective={objective}
          isOpen={isOpen}
        />
      ))}
    </Frame>
  );
}

function ObjectiveItemAccordion({ objective, isOpen }) {
  const childObjectives = wrap(objective.childObjectives);

  if (childObjectives.length > 0)
    return (
      <Accordion
        isOpen={isOpen}
        title={objective.title}
        variant="objectiveAccordion"
        state="none"
        classname="pl-4 py-4 w-full outline-none overflow-hidden group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
        userIcon={objective.owner.avatarUrl}
        userName={objective.owner.fullName}
        department={objective.department.title}
        companyObjective={objective.companyObjective}
        objectiveStatus={
          <Frame className="h-6 w-6 mr-4 ml-16">
            <StatusIcon
              status={getObjectiveStatus(objective)}
              className="h-6 w-6"
              tooltip={true}
            />
          </Frame>
        }
        href={`/objectives/${objective.id}`}
        key={objective.id}
      >
        <ObjectiveItemAccordionContent
          childObjectives={childObjectives}
          isOpen={isOpen}
        />
      </Accordion>
    );

  return <ObjectiveItem key={objective.id} objective={objective} />;
}

export default function ObjectiveTable() {
  return (
    <ObjectiveLayout refSelected="/objectives">
      <ErrorBoundary fallback={ErrorFallback}>
        <SSRSuspense fallback={<Fallback />}>
          <ObjectiveTableContent />
        </SSRSuspense>
      </ErrorBoundary>
    </ObjectiveLayout>
  );
}

function filterObjectives(objectives) {
  let filteredObjectives = [];
  for (const objective of objectives) {
    if (!!objective.owner) {
      let childObjectives = objective.childObjectives;
      if (!!objective.childObjectives) {
        childObjectives = [];
      }
      filteredObjectives.push({
        ...objective,
        childObjectives: childObjectives,
      });
    }
  }
  return filteredObjectives;
}

function orderObjectivesForObjectivePage(
  objectives,
  isJustFilterByQuarter = false,
) {
  if (!isJustFilterByQuarter) {
    return filterObjectives(objectives);
  }
  const filterCompanyObjectives = objectives.filter(
    (objective) => objective.companyObjective,
  );
  const filterObjectivesWithNoParentButWithChilds = objectives.filter(
    (objective) =>
      objective.companyObjective === false &&
      objective.parentObjectiveId === null &&
      wrap(objective.childObjectives).length > 0,
  );

  const filterObjectivesWithNoParentAndNoChilds = objectives.filter(
    (objective) =>
      objective.companyObjective === false &&
      objective.parentObjectiveId === null &&
      wrap(objective.childObjectives).length === 0,
  );

  return [
    ...filterCompanyObjectives,
    ...filterObjectivesWithNoParentButWithChilds,
    ...filterObjectivesWithNoParentAndNoChilds,
  ];
}

// Sorting objectives by department title on the frontend for now. It should be sorted by backend in the future.
function formatObjectivesData(objectives) {
  return produce(objectives, (draft) => {
    draft.sort((a, b) =>
      a.department?.title.localeCompare(b.department?.title),
    );
    for (const objective of draft) {
      objective.status = objective.closedAt
        ? `score: ${objective.score}`
        : objective.currentStatusUpdate?.status;
      for (const kr of wrap(objective.keyResults)) {
        kr.status = kr.currentStatusUpdate
          ? kr.currentStatusUpdate.status
          : kr.start;
      }
    }
  });
}

function resetFilterForNotValidOptions(
  options,
  currentOptionValue,
  defaultValue,
  setValue,
  resetFilterStatus,
) {
  const currentOptionIsInArray = options.filter(({ id: optionId }) => {
    if (Number(optionId) === Number(currentOptionValue)) {
      return true;
    }
  }).length;
  if (!currentOptionIsInArray) {
    setValue(defaultValue);
    resetFilterStatus();
  }
}

function ObjectiveTableContent() {
  const history = useHistory();

  const params = useQuery();

  const [quarter, setQuarter] = React.useState(
    params.get("quarter") || defaultFilterState.quarter(),
  );
  const [departmentId, setDepartmentId] = React.useState(
    params.get("departmentId") || defaultFilterState.department.id,
  );
  const [userObjective, setUserObjective] = React.useState(
    params.get("userObjectiveId") || defaultFilterState.userObjective.id,
  );
  const [objectiveStatus, setObjectiveStatus] = React.useState(
    params.get("objectiveStatusId") || defaultFilterState.objectiveStatus.id,
  );
  const departments = [
    defaultFilterState.department,
    ...useDepartments(quarter),
  ];
  const quarters = useQuarters();
  const userObjectives = [
    defaultFilterState.userObjective,
    ...useUserObjectives(quarter),
  ];
  const objectivesStatus = [
    defaultFilterState.objectiveStatus,
    { id: "1", value: "on_track" },
    { id: "2", value: "behind" },
    { id: "3", value: "at_risk" },
  ];

  const isJustFilterByQuarter =
    isDefaultDepartment(departmentId) &&
    isDefaultUserObjective(userObjective) &&
    isDefaultObjectiveStatus(objectiveStatus);

  const resetFilterEnabled =
    !isDefaultQuarter(quarter) ||
    !isDefaultDepartment(departmentId) ||
    !isDefaultUserObjective(userObjective) ||
    !isDefaultObjectiveStatus(objectiveStatus);

  function resetFilters() {
    setQuarter(defaultFilterState.quarter());
    setDepartmentId(defaultFilterState.department.id);
    setUserObjective(defaultFilterState.userObjective.id);
    setObjectiveStatus(defaultFilterState.objectiveStatus.id);
    history.push("objectives");
  }

  useEffect(() => {
    function changeUrl() {
      function getFilterUrl() {
        const quarterURL = `quarter=${quarter}`;
        const departmentURL = `departmentId=${departmentId}`;
        const userObjectiveURL = `userObjectiveId=${userObjective}`;
        const objectiveStatusURL = `objectiveStatusId=${objectiveStatus}`;
        return [quarterURL, departmentURL, userObjectiveURL, objectiveStatusURL]
          .filter((e) => !(e.split("=")[1] === "null"))
          .join("&");
      }
      history.push({ search: getFilterUrl() });
    }
    changeUrl();
  }, [quarter, departmentId, userObjective, objectiveStatus, history]);

  function resetFilterStatus() {
    setObjectiveStatus(defaultFilterState.objectiveStatus.id);
  }

  resetFilterForNotValidOptions(
    departments,
    departmentId,
    defaultFilterState.department.id,
    setDepartmentId,
    resetFilterStatus,
  );
  resetFilterForNotValidOptions(
    userObjectives,
    userObjective,
    defaultFilterState.userObjective.id,
    setUserObjective,
    resetFilterStatus,
  );

  return (
    <Frame p={[0, 10, 0, 10]}>
      {resetFilterEnabled ? (
        <Inline distribution="end" p={[0, 2, 0, 0]} className="mb-1">
          <BodySmall
            className="outline-none cursor-pointer text-primary-base hover:text-primary-light transition-colors duration-300"
            onClick={() => resetFilters()}
          >
            Reset filters
          </BodySmall>
        </Inline>
      ) : (
        <Frame className="w-10 mb-1" style={{ height: "19px" }} />
      )}
      <Inline
        alignment="start"
        distribution="between"
        data-testid="filters-section"
      >
        <Inline space={4} p={[0, 2, 0, 0]}>
          <Text color="neutral-800" className="pr-2">
            Quarter:
          </Text>
          <Select
            className="w-full"
            options={quarters.map((quarter) => ({
              value: quarter,
              label: getQuarterTitle(quarter),
            }))}
            value={quarter}
            onChange={setQuarter}
            testId="quarter-select"
          />
        </Inline>
        <Inline space={4} p={[0, 2, 0, 0]}>
          <Text color="neutral-800" className="pr-2">
            Showing:
          </Text>
          <Select
            options={departments.map((department) => ({
              value: department.id,
              label: department.title,
            }))}
            value={departmentId}
            onChange={setDepartmentId}
            testId="department-select"
          />
          <Select
            options={userObjectives.map((text) => ({
              value: text.id,
              label: text.fullName,
            }))}
            value={userObjective}
            onChange={setUserObjective}
            testId="user-select"
          />
          <Select
            options={objectivesStatus.map((status) => ({
              value: status.id,
              label: capitalize(STATUS[status.value]?.title),
              icon: <StatusIcon status={status.value} />,
            }))}
            value={objectiveStatus}
            onChange={setObjectiveStatus}
            hasIcons={true}
            testId="objective-status-select"
          />
        </Inline>
      </Inline>
      <SSRSuspense fallback={<ObjectiveDataFallBack />}>
        <ObjectiveData
          isJustFilterByQuarter={isJustFilterByQuarter}
          quarter={quarter}
          departmentId={departmentId}
          userObjective={userObjective}
          objectiveStatus={objectiveStatus}
        />
      </SSRSuspense>
    </Frame>
  );
}

function ObjectiveData({
  isJustFilterByQuarter,
  quarter,
  departmentId,
  userObjective,
  objectiveStatus,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [sortedObjectives, setSortedObjectives] = React.useState([]);
  const objectives = useObjectives({
    quarter: quarter,
    departmentId: departmentId,
    ownerId: userObjective,
    currentStatusUpdate: objectiveStatus,
  });

  useEffect(() => {
    setSortedObjectives(formatObjectivesData(objectives));
  }, [objectives]);

  const orderedObjectives = orderObjectivesForObjectivePage(
    sortedObjectives,
    isJustFilterByQuarter,
  );

  const listObjectives = isJustFilterByQuarter ? objectives : orderedObjectives;

  return (
    <Frame data-testid="objectives-section">
      <Inline space={4} className="mt-8" distribution="between">
        <Inline>
          <Text color={Color.Neutral800} className="mr-6">
            {listObjectives.length} objectives:
          </Text>
          <Inline space={6}>
            {["on_track", "behind", "at_risk"].map((status) => (
              <Status
                key={status}
                status={status}
                amount={
                  listObjectives.filter(
                    (objective) => getObjectiveStatus(objective) === status,
                  ).length
                }
              />
            ))}
          </Inline>
        </Inline>
        <Inline distribution="end">
          <BodySmall
            className="outline-none cursor-pointer text-primary-base hover:text-primary-light mr-1 transition-colors duration-300"
            onClick={() => {
              //we add new Date() to have a true value that change over time
              //if not, everytime the Expand All button clicked nothing will change
              //since the value will be always the same and React won't do any actions
              setIsOpen(new Date());
            }}
          >
            Expand All
          </BodySmall>
          <BodySmall color={Color.Neutral500}>|</BodySmall>
          <BodySmall
            className="outline-none cursor-pointer text-primary-base hover:text-primary-light ml-1 transition-colors duration-300"
            onClick={() => {
              const newValue = [0, false].find(
                (value) => !(typeof isOpen === typeof value),
              );
              setIsOpen(newValue);
            }}
          >
            Collapse All
          </BodySmall>
        </Inline>
      </Inline>
      <Frame p={[4, 0, 0, 0]}>
        {orderedObjectives.map((objective, i) => (
          <ObjectiveItemAccordion
            key={i}
            objective={objective}
            isOpen={isOpen}
          />
        ))}
      </Frame>
    </Frame>
  );
}

function EmptySelect({ className, placeholder = "loading ..." }) {
  return (
    <Select
      className={className}
      disabled={true}
      options={[{ value: "", label: "" }]}
      placeholder={placeholder}
    />
  );
}

function ObjectiveDataFallBack() {
  return (
    <Frame>
      <Inline space={4} className="mt-8" distribution="between">
        <Inline>
          <Text color={Color.Neutral800} className="mr-6">
            loading... objectives:
          </Text>
          <Inline space={6}>
            {["on_track", "behind", "at_risk"].map((status) => (
              <Status key={status} status={status} amount="loading ..." />
            ))}
          </Inline>
        </Inline>
        <Inline distribution="end">
          <BodySmall className="disabled outline-none text-primary-base mr-1">
            Expand All
          </BodySmall>
          <BodySmall color={Color.Neutral500}>|</BodySmall>
          <BodySmall className="disabled outline-none text-primary-base ml-1">
            Collapse All
          </BodySmall>
        </Inline>
      </Inline>
      <Frame p={[4, 0, 0, 0]}>
        <Stack
          alignment="start"
          corners="small"
          className="w-full"
          p={[0, 0, 0, 10]}
        >
          {Array.from({ length: 2 }, (_, index) => (
            <Flex distribution="center" key={index} className="mb-2">
              <Skeleton
                height={30}
                width={100}
                className="mr-4 rounded"
                alt="Objective item"
                color="neutral-200"
              />
              <Skeleton
                height={30}
                width={600}
                className="rounded"
                alt="Objective item"
                color="neutral-200"
              />
            </Flex>
          ))}
        </Stack>
      </Frame>
    </Frame>
  );
}

export function Fallback() {
  return (
    <Frame p={[0, 10, 0, 10]}>
      <Frame className="w-10 mb-1" style={{ height: "19px" }} />
      <Inline alignment="start" distribution="between">
        <Inline space={4} p={[0, 2, 0, 0]}>
          <Text color="neutral-800" className="pr-2">
            Quarter:
          </Text>
          <EmptySelect className="w-full" />
        </Inline>
        <Inline space={4} p={[0, 2, 0, 0]}>
          <Text color="neutral-800" className="pr-2">
            Showing:
          </Text>
          <EmptySelect />
          <EmptySelect />
          <EmptySelect />
        </Inline>
      </Inline>
    </Frame>
  );
}

function Legend({ children }) {
  return (
    <Frame p={[8, 3, 6, 3]}>
      <Text className="text-neutral-800" size="sm">
        {children}
      </Text>
    </Frame>
  );
}

function ErrorFallback({ error }) {
  return <Legend>{error.message}</Legend>;
}
