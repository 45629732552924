import React from "react";
import { ErrorBoundary, SSRSuspense, useResource } from "coreql";
import { useParams } from "react-router";

import { FeedbackMessageContainerLayout } from "./feedback-message-content";
import { FeedbackMessagesFallback } from "./feedback-message-fallback";
import { useReceivedFeedbackMessages } from "./feedback-messages-received";
import { useSentFeedbackMessages } from "./feedback-messages-sent";

function ReceivedFeedbackMessageContainer({
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
}) {
  const { userId, messageId } = useParams();

  const { data: messages, mutate: mutateAllMessage } =
    useReceivedFeedbackMessages(userId);

  const { data: message, mutate: mutateSelectedMessage } = useResource(
    "feedback-messages",
    messageId,
    {
      filters: {
        active: true,
        draft: false,
      },
      fields: {
        feedbackMessages: [
          "id",
          "body",
          "submittedAt",
          "promptAnswer",
          "receiverId",
          "receiver",
          "sender",
          "liked",
          "reviewedByManager",
          "readByReceiver",
          "readByManager",
          "coachingTip",
          "feedbackRequest",
          "feedbackHighlights",
        ],
        users: ["fullName", "avatarUrl", "id", "preferredName"],
        feedbackRequests: [
          "id",
          "requestDate",
          "body",
          "sender",
          "author",
          "feedbackPrompt",
        ],
        feedbackPrompts: [
          "prompt",
          "cadence",
          "promptType",
          "scaleType",
          "responsibility",
        ],
        responsibilities: ["title"],
        roles: ["title"],
        feedbackHighlights: [
          "start",
          "end",
          "feedbackType",
          "responsibility",
          "skills",
          "note",
        ],
        skills: ["name"],
      },
      included: [
        "receiver",
        "sender",
        "feedbackRequest",
        "feedbackRequest.author",
        "feedbackRequest.sender",
        "feedbackRequest.feedbackPrompt",
        "feedbackRequest.feedbackPrompt.responsibility",
        "feedbackHighlights",
        "feedbackHighlights.skills",
        "feedbackHighlights.responsibility",
      ],
    },
    "denormalized",
  );

  return (
    <FeedbackMessageContainerLayout
      textNoFeedback={"You don't have feedback yet."}
      typeMessages="received"
      feedbackMessages={messages}
      isCurrentUserDirectManager={isCurrentUserDirectManager}
      isCurrentUserInboxOwner={isCurrentUserInboxOwner}
      messageSelected={message}
      mutateSelectedMessage={mutateSelectedMessage}
      mutateAllMessage={mutateAllMessage}
    />
  );
}

function SentFeedbackMessageContainer({
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
}) {
  const { userId, messageId } = useParams();

  const { data: messages } = useSentFeedbackMessages(userId);

  const { data: message, mutate: mutateSelectedMessage } = useResource(
    "feedback-messages",
    messageId,
    {
      filters: {
        active: true,
        draft: false,
      },
      fields: {
        feedbackMessages: [
          "id",
          "body",
          "submittedAt",
          "promptAnswer",
          "receiverId",
          "receiver",
          "sender",
          "liked",
          "coachingTip",
          "feedbackRequest",
          "readByReceiver",
        ],
        users: ["fullName", "avatarUrl", "id", "preferredName"],
        feedbackRequests: [
          "id",
          "requestDate",
          "body",
          "sender",
          "author",
          "feedbackPrompt",
        ],
        feedbackPrompts: [
          "prompt",
          "cadence",
          "promptType",
          "scaleType",
          "responsibility",
        ],
        responsibilities: ["title"],
        roles: ["title"],
      },
      included: [
        "receiver",
        "sender",
        "feedbackRequest",
        "feedbackRequest.author",
        "feedbackRequest.sender",
        "feedbackRequest.feedbackPrompt",
        "feedbackRequest.feedbackPrompt.responsibility",
      ],
    },
    "denormalized",
  );

  return (
    <FeedbackMessageContainerLayout
      textNoFeedback={"You don't have feedback yet."}
      typeMessages="sent"
      feedbackMessages={messages}
      isCurrentUserDirectManager={isCurrentUserDirectManager}
      isCurrentUserInboxOwner={isCurrentUserInboxOwner}
      messageSelected={message}
      mutateSelectedMessage={mutateSelectedMessage}
    />
  );
}

function GetFeedbackMessage({
  setActiveLabelTab,
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
}) {
  const { userId, messageId } = useParams();

  const { data: message } = useResource(
    "feedback-messages",
    messageId,
    {
      filters: {
        active: true,
        draft: false,
      },
      fields: {
        feedbackMessages: ["id", "receiverId"],
      },
    },
    "denormalized",
  );

  let messageType =
    message.receiverId === Number.parseInt(userId) ? "received" : "sent";

  setActiveLabelTab(messageType);

  return (
    <>
      {messageType === "received" ? (
        <ReceivedFeedbackMessageContainer
          isCurrentUserDirectManager={isCurrentUserDirectManager}
          isCurrentUserInboxOwner={isCurrentUserInboxOwner}
        />
      ) : (
        <SentFeedbackMessageContainer
          isCurrentUserDirectManager={isCurrentUserDirectManager}
          isCurrentUserInboxOwner={isCurrentUserInboxOwner}
        />
      )}
    </>
  );
}

export default function FeedbackMessage({
  setActiveLabelTab,
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
}) {
  return (
    <ErrorBoundary fallback={FeedbackMessagesFallback}>
      <SSRSuspense fallback={<FeedbackMessagesFallback />}>
        <GetFeedbackMessage
          setActiveLabelTab={setActiveLabelTab}
          isCurrentUserDirectManager={isCurrentUserDirectManager}
          isCurrentUserInboxOwner={isCurrentUserInboxOwner}
        />
      </SSRSuspense>
    </ErrorBoundary>
  );
}
