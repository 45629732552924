// Modules
import { connect } from "react-redux";
// Components
import ProductValueRows from "./ProductValueRows";

const mapStateToProps = (state) => {
  const { periods } = state;

  return {
    periods,
  };
};

export default connect(mapStateToProps, {})(ProductValueRows);
