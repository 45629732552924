import React from "react";
import { Frame, Inline } from "@ableco/baseline";
import { NavItem as Tab } from "../tab";
import { currentPathMatches } from "../layout/user-layout";
import PageLayout from "../layout/page-layout";

function getNavItems() {
  const items = [];

  items.push(
    {
      href: `/objectives`,
      label: "Objectives",
      prefetch: false,
      isActive: (location) =>
        currentPathMatches(location, { path: "/objectives", exact: true }),
    },
    {
      href: `/objectives/key-results`,
      label: "Key Results",
      prefetch: false,
      isActive: (location) =>
        currentPathMatches(location, { path: "/objectives/key-results" }),
    },
  );

  return items;
}

export default function ObjectiveLayout({ children }) {
  const navItems = getNavItems();

  return (
    <Frame className="h-full">
      <PageLayout title="Objectives">
        <Inline
          as="nav"
          alignment="start"
          className="w-full mb-10 border-b border-neutral-300 -mx-8"
          p={[0, 0, 0, 8]}
        >
          {navItems.map((item) => (
            <Tab key={item.href} {...item} />
          ))}
        </Inline>
        {children}
      </PageLayout>
    </Frame>
  );
}
