import React from "react";
import {
  Frame,
  Text,
  TextSize,
  TextLeading,
  Color,
  Corners,
  Shadow,
} from "@ableco/baseline";

export function List({ children, isVisible, className, ...props }) {
  return (
    <Frame
      as="ul"
      p={[1, 0]}
      bg={Color.White}
      border={Color.Neutral300}
      corners={Corners.MediumRounded}
      shadow={Shadow.Base}
      className={`z-10 ${isVisible ? "block" : "hidden"} ${className}`}
      style={{ maxWidth: 400, minWidth: 172 }}
      {...props}
    >
      {children}
    </Frame>
  );
}

export function StyledGroup({ children, className, ...props }) {
  return (
    <Frame
      as="ul"
      className={`border-b border-neutral-200 pb-1 mb-1 ${className}`}
      {...props}
    >
      {children}
    </Frame>
  );
}

export function Group(props) {
  return (
    <li>
      <StyledGroup {...props} />
    </li>
  );
}

export function Item({ children, className, ...props }) {
  return (
    <Frame
      as="li"
      p={[1, 0]}
      className={`hover:bg-primary-lighter transition-all duration-300 ease-in-out ${className}`}
      {...props}
    >
      {children}
    </Frame>
  );
}

export function Button({ children, className, ...props }) {
  return (
    <Text
      as="button"
      color={Color.Neutral700}
      leading={TextLeading.Relaxed}
      className={`border-0 bg-transparent px-4 block cursor-pointer w-full truncate text-left ${className}`}
      {...props}
    >
      {children}
    </Text>
  );
}

export function Link({ children, className, ...props }) {
  return (
    <Text
      as="a"
      color={Color.Neutral700}
      size={TextSize.SM}
      leading={TextLeading.Snug}
      className={`border-0 bg-transparent px-4 block cursor-pointer w-full truncate no-underline ${className}`}
      {...props}
    >
      {children}
    </Text>
  );
}
