import { Color, Frame } from "@ableco/baseline";
import { api, denormalize, ErrorBoundary } from "coreql";
import React from "react";
import { useHistory, useParams } from "react-router";
import { useSWRSuspense } from "swr";
import useCurrentUser from "../../hooks/use-current-user";
import formatDateRange from "../../utils/formatDateRange";
import { possessive } from "../../utils/possesive";
import Accordion from "../accordion";
import CheersReceived from "../cheers-received";
import CheersSent from "../cheers-sent";
import ErrorFallback from "../error-fallback";
import BackButton from "../layout/back-button";
import PageLayout from "../layout/page-layout";
import { useCoreToast } from "../toast/toast";
import FeedbackReceived, {
  useReceivedFeedbackMessages,
} from "./feedback/feedback-received";
import { useWeeklyReportWithGeneralFeedbackData } from "./weekly-report-hooks";
import FeedbackSent from "./feedback/feedback-sent";
import HowThisWeekWent from "./how-this-week-went/how-this-week-went";
import ReadOnlyObjectives, {
  useObjectiveStatusUpdates,
} from "./read-only-objectives";
import WeeklyReportGeneralFeedback from "./weekly-report-general-feedback";
import { wrap } from "./utils";
import { Footer } from "./weekly-report-form";
import { useReferrer } from "./history/utils";

function getJournalTitle(currentUserIsWeeklyReportOwner, weeklyReportOwner) {
  if (!currentUserIsWeeklyReportOwner) {
    return `${possessive(weeklyReportOwner.preferredName)} Weekly Journal`;
  }
  return "Weekly Journal";
}

function EditFooter({ weeklyReportId, weeklyReportSubmitted }) {
  const history = useHistory();
  function navigateToEdit(event) {
    event.preventDefault();
    history.push(`/v2_weekly_reports/${weeklyReportId}/edit`);
  }

  return (
    <Footer
      submitText="Edit"
      handleSubmit={navigateToEdit}
      submittedAt={weeklyReportSubmitted}
    />
  );
}

function ReviewFooter({
  weeklyReportId,
  weeklyReportReviewed,
  weeklyReportSubmitted,
  disabled,
  weeklyReportMutate,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const { successToast, errorToast } = useCoreToast();

  async function markAsReviewed(event) {
    event.preventDefault();
    try {
      setIsLoading(true);
      await api.weeklyReports
        .find(weeklyReportId)
        .update({ reviewed: new Date() });
      successToast("Weekly report reviewed successfully!");
      history.push("/weekly_reports/teams_journal");
      weeklyReportMutate(
        (weeklyReport) => ({
          ...weeklyReport,
          reviewed: new Date(),
        }),
        false,
      );
    } catch {
      errorToast(
        "We had a problem reviewing the journal, please contact the team.",
      );
      setIsLoading(false);
    }
  }

  function reviewButtonText() {
    if (isLoading) return "Reviewing...";
    if (weeklyReportReviewed) return "Reviewed";
    return "Mark as Reviewed";
  }

  return (
    <Footer
      submitText={reviewButtonText()}
      handleSubmit={markAsReviewed}
      submittedAt={weeklyReportSubmitted}
      disabled={disabled || isLoading}
    />
  );
}

function reviewedMessagesCount(messages) {
  return messages.filter((message) => message.reviewedByManager).length;
}

function feedbackReadyState(messages) {
  if (allMessagesReviewed(messages)) return "ready";
  if (reviewedMessagesCount(messages) === 0) return "empty";

  return "progress";
}

function feedbackReviewedCountLabel(messages) {
  if (messages.length === 0) return null;
  const reviewedCount = reviewedMessagesCount(messages);
  return `Completed ${reviewedCount} / ${messages.length}`;
}

function allMessagesReviewed(messages) {
  return messages.every(({ reviewedByManager }) => reviewedByManager);
}

function isReviewJournalEnabled(
  weeklyReportReviewed,
  currentUserIsSuperManager,
  messages,
) {
  if (weeklyReportReviewed) return false;
  if (currentUserIsSuperManager) return false;
  if (!allMessagesReviewed(messages)) return false;

  return true;
}

function WeeklyReportReadOnly() {
  const { weeklyReportId } = useParams();
  const { data: weeklyReport, mutate: weeklyReportMutate } =
    useWeeklyReportWithGeneralFeedbackData(weeklyReportId);
  const {
    periodStart: weeklyReportDateStart,
    periodEnd: weeklyReportDateEnd,
    submitted: weeklyReportSubmitted,
    reviewed: weeklyReportReviewed,
    user: weeklyReportOwner,
  } = weeklyReport;
  const { data: currentUser } = useCurrentUser();
  const [objectiveStatusUpdatesResponse, messages] = useSWRSuspense(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data: objectiveStatusUpdatesResponse } =
      useObjectiveStatusUpdates(weeklyReportId);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data: messages } = useReceivedFeedbackMessages(
      weeklyReportId,
      weeklyReportSubmitted,
      weeklyReportDateEnd,
      weeklyReportOwner,
    );
    return [objectiveStatusUpdatesResponse, messages];
  });

  const currentUserIsManager = weeklyReportOwner.managerId == currentUser.id;

  const objectives = wrap(
    denormalize(objectiveStatusUpdatesResponse, "objectives"),
  );

  const currentUserIsWeeklyReportOwner = weeklyReportOwner.id == currentUser.id;
  const currentUserIsSuperManager =
    !currentUserIsWeeklyReportOwner && !currentUserIsManager;
  const baseUrl = currentUserIsWeeklyReportOwner
    ? "/weekly_reports/my_journal"
    : "/weekly_reports/teams_journal";

  const referrerData = useReferrer();
  const referrer = referrerData ? referrerData : baseUrl;

  //: setParamsToJournalUrl(baseUrl, weekStart(Date.now()), 11);

  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <PageLayout
        title={getJournalTitle(
          currentUserIsWeeklyReportOwner,
          weeklyReportOwner,
        )}
        subTitle={formatDateRange(weeklyReportDateStart, weeklyReportDateEnd)}
        avatarUser={!currentUserIsWeeklyReportOwner && weeklyReportOwner}
        backButton={
          <BackButton
            text={
              currentUserIsWeeklyReportOwner
                ? "Back to Journal History"
                : "Back to Team Journals"
            }
            href={referrer}
          />
        }
      >
        <Frame style={{ maxWidth: "1040px" }}>
          <Accordion
            title="Feedback and cheers"
            state={currentUserIsManager ? feedbackReadyState(messages) : "none"}
            stateLabel={
              currentUserIsManager && feedbackReviewedCountLabel(messages)
            }
            isOpen
          >
            <FeedbackReceived
              weeklyReportId={weeklyReportId}
              weeklyReportDateStart={weeklyReportDateStart}
              weeklyReportDateEnd={weeklyReportDateEnd}
              weeklyReportSubmitted={weeklyReportSubmitted}
              weeklyReportOwner={weeklyReportOwner}
              currentUser={currentUser}
            />
            <CheersReceived
              weeklyReportId={weeklyReportId}
              weeklyReportUser={weeklyReportOwner}
              currentUserId={currentUser.id}
              weeklySubmitted={!!weeklyReportSubmitted}
            />
          </Accordion>
          {objectives.length > 0 && (
            <Accordion title="Objectives" isOpen state="none">
              <ReadOnlyObjectives
                weeklyId={weeklyReportId}
                currentUserId={currentUser.id}
                managerId={weeklyReportOwner.managerId}
                weeklyUser={weeklyReportOwner}
              />
            </Accordion>
          )}
          <Accordion title="How this week went" isOpen state="none">
            <HowThisWeekWent
              weeklyReportId={weeklyReportId}
              weeklyReportOwner={weeklyReportOwner}
              weeklyReportDateStart={weeklyReportDateStart}
            />
          </Accordion>
          <Accordion title="Help us grow" isOpen state="none">
            <FeedbackSent
              weeklyReportId={weeklyReportId}
              weeklyReportDateStart={weeklyReportDateStart}
              weeklyReportDateEnd={weeklyReportDateEnd}
              weeklyReportSubmitted={weeklyReportSubmitted}
              weeklyReportOwner={weeklyReportOwner}
              currentUser={currentUser}
            />
            <CheersSent
              weeklyReportId={weeklyReportId}
              weeklyReportUser={weeklyReportOwner}
              currentUserId={currentUser.id}
              weeklySubmitted={!!weeklyReportSubmitted}
            />
          </Accordion>
          <WeeklyReportGeneralFeedback
            weeklyReportGeneralFeedback={weeklyReport}
            weeklyReport={weeklyReport}
            currentUserIsDirectManager={currentUserIsManager}
            weeklyReportMutate={weeklyReportMutate}
          />
        </Frame>
      </PageLayout>
      <Frame
        p={[0, 10, 2, 10]}
        className="border-t border-neutral-300 sticky bottom-0"
        bg={Color.White}
      >
        {currentUserIsWeeklyReportOwner && !weeklyReportReviewed ? (
          <EditFooter
            weeklyReportId={weeklyReportId}
            weeklyReportSubmitted={weeklyReportSubmitted}
          />
        ) : (
          <ReviewFooter
            weeklyReportId={weeklyReportId}
            weeklyReportReviewed={weeklyReportReviewed}
            weeklyReportSubmitted={weeklyReportSubmitted}
            disabled={
              !isReviewJournalEnabled(
                weeklyReportReviewed,
                currentUserIsSuperManager,
                messages,
              )
            }
            weeklyReportMutate={weeklyReportMutate}
          />
        )}
      </Frame>
    </ErrorBoundary>
  );
}

export default WeeklyReportReadOnly;
