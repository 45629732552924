const APOSTROPHE_CHAR = "'";

export function possessive(string) {
  if (string == "") {
    return string;
  }
  let lastChar = string.slice(-1);
  let endOfWord =
    lastChar.toLowerCase() == "s" ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`;
  return `${string}${endOfWord}`;
}
