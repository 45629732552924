import React from "react";
import { Box } from "@ableco/baseline";
import { ErrorBoundary, SSRSuspense, useCollection } from "coreql";
import CoreLink from "../core-link";
import WeeklyQuestionLayout from "./weekly-question-layout";
import { BodySmall } from "../design-system/typography-components";
import { WeeklyQuestionsFallback } from "./weekly-question-fallback";
import { Check } from "@baseline/icons";
import {
  formatWeekPeriod,
  groupQuestionsByWeeks,
  nextWeekRange,
  weeksInGroup,
} from "./weekly-questions-utils";
import { formatWithDashes, weekStart } from "../weekly-report/history/utils";
import { range } from "lodash";

function QuestionTableHeader() {
  return (
    <Box as="div" className="grid grid-cols-12 gap-2">
      <Box as="div" className="col-span-3 p-4">
        <Box
          as="span"
          className="text-base text-black text-opacity-75 font-semibold"
        >
          Audience
        </Box>
      </Box>
      <Box as="div" className="col-span-7 p-4">
        <Box
          as="span"
          className="text-base text-black text-opacity-75 font-semibold"
        >
          Question
        </Box>
      </Box>
      <Box as="div" className="col-span-2 p-4 text-center">
        <Box
          as="span"
          className="text-base text-black text-opacity-75 font-semibold"
        >
          Ongoing?
        </Box>
      </Box>
    </Box>
  );
}

function audienceCell(questionable) {
  let text = "Everyone";
  if (questionable) {
    text =
      questionable.type != "departments"
        ? questionable.name
        : `Department: ${questionable.title}`;
  }
  return text;
}
function QuestionsScheduleCells({ question, index }) {
  return (
    <Box
      as="div"
      key={index}
      className={`grid grid-cols-12 gap-2 ${
        index % 2 !== 0 ? "bg-neutral-200" : ""
      }`}
    >
      <Box as="div" className="col-span-3 p-4">
        <Box
          as="span"
          className="text-base text-black text-opacity-75 font-normal break-words"
          data-testid={`question-${question.id}-audience`}
        >
          {audienceCell(question.questionable)}
        </Box>
      </Box>
      <Box as="div" className="col-span-7 p-4 flex flex-col">
        <Box
          as="span"
          className="text-base text-black text-opacity-75 font-normal break-words"
          data-testid={`question-${question.id}-text`}
        >
          {question.text}
        </Box>
        <Box
          as="span"
          className="text-sm text-black text-opacity-45 font-normal"
          data-testid={`question-${question.id}-optional-type`}
        >
          {`${question.optional ? "Optional" : "Required"} • ${
            question.questionType === "text" ? "Text" : "Rating Scale"
          }`}
        </Box>
      </Box>
      <Box as="div" className="col-span-2 p-4 flex justify-center">
        <Box
          as="span"
          className="text-base text-black text-opacity-75 font-normal"
        >
          {question.schedule == "ongoing" ? <Check /> : null}
        </Box>
      </Box>
    </Box>
  );
}
function addOngingQuestionstoGroupWeeks(
  questionGrouped,
  ongoingActiveQuestions,
) {
  // eslint-disable-next-line unicorn/no-array-for-each
  Object.keys(questionGrouped).forEach((key) => {
    questionGrouped[key].push(...ongoingActiveQuestions);
    questionGrouped[key].sort((a, b) => a.rank - b.rank || b.id - a.id);
  });
}
function WeeklyQuestionsGroup({
  index,
  ongoingActiveQuestions,
  addGroup,
  last,
}) {
  const currentWeeklyStart = weekStart(new Date());
  const { scheduledSince, scheduledUntil } = nextWeekRange(
    currentWeeklyStart,
    index,
  );

  const { data: questions } = useCollection(
    "weekly-report-questions",
    {
      fields: {
        weeklyReportQuestions: [
          "text",
          "optional",
          "questionType",
          "scheduledWeeks",
          "questionable",
          "rank",
        ],
        weeklyReportQuestionScheduledWeeks: ["scheduledDate"],
        departments: ["title"],
        customGroups: ["name"],
      },
      filters: {
        scheduledSince: formatWithDashes(scheduledSince),
        scheduledUntil: formatWithDashes(scheduledUntil),
        active: true,
      },
      included: ["scheduledWeeks", "questionable"],
    },
    "denormalized",
  );
  const weeks = weeksInGroup(scheduledUntil).reverse();
  const questionGrouped = groupQuestionsByWeeks(questions, weeks);
  addOngingQuestionstoGroupWeeks(questionGrouped, ongoingActiveQuestions);
  return (
    <Box as="div" className="flex flex-col gap-8">
      {weeks.map((week, index) => (
        <Box
          as="div"
          key={index}
          data-testid={`week-${formatWithDashes(week)}`}
        >
          <Box
            as="span"
            className="text-lg text-black text-opacity-75 font-semibold mb-8"
          >
            {formatWeekPeriod(week)}
          </Box>
          <Box as="div" className="ml-8">
            <QuestionTableHeader />
            <Box as="div" className="border border-solid border-neutral-400">
              {questionGrouped[formatWithDashes(week)].map(
                (question, index) => (
                  <QuestionsScheduleCells
                    question={question}
                    index={index}
                    key={index}
                  />
                ),
              )}
              {ongoingActiveQuestions.length === 0 &&
                questionGrouped.length === 0 && (
                  <Box as="span">There are no questions for this week</Box>
                )}
            </Box>
          </Box>
        </Box>
      ))}
      {last ? (
        <BodySmall
          as="button"
          onClick={addGroup}
          color={["primary", "primary-light"]}
          className="transition-color duration-300 ease-in-out no-underline self-start"
        >
          See more weeks
        </BodySmall>
      ) : null}
    </Box>
  );
}
function WeeklyQuestionContainer() {
  const [groupCount, setGroupCount] = React.useState(1);
  function addGroup() {
    setGroupCount((value) => value + 1);
  }

  const { data: ongoingQuestions } = useCollection(
    "weekly-report-questions",
    {
      fields: {
        weeklyReportQuestions: [
          "text",
          "optional",
          "questionType",
          "questionable",
          "rank",
          "schedule",
        ],
        departments: ["title"],
        customGroups: ["name"],
      },
      filters: {
        schedule: "ongoing",
        active: true,
      },
      included: ["questionable"],
    },
    "denormalized",
  );
  return (
    <Box as="div" className="flex flex-col gap-8">
      {range(groupCount).map((index) => (
        <ErrorBoundary key={index} fallback={WeeklyQuestionsFallback}>
          <SSRSuspense fallback={<WeeklyQuestionsFallback />}>
            <WeeklyQuestionsGroup
              index={index}
              last={index + 1 == groupCount}
              ongoingActiveQuestions={ongoingQuestions}
              addGroup={addGroup}
            />
          </SSRSuspense>
        </ErrorBoundary>
      ))}
    </Box>
  );
}
export default function WeeklyQuestionsSchedule() {
  return (
    <WeeklyQuestionLayout activeLabelTab="schedule">
      <Box as="h4" className="text-2xl font-semibold mb-8">
        Schedule
      </Box>
      <Box as="span" className="mb-4 text-black text-opacity-75">
        Use this information to balance the number of questions and effort
        required to submit the weekly journal.
      </Box>
      <CoreLink href="/admin/weekly_report_questions" isExternalLink={true}>
        <BodySmall
          color={["primary", "primary-light"]}
          className="transition-color duration-300 ease-in-out no-underline"
        >
          Edit questions
        </BodySmall>
      </CoreLink>
      <Box className="my-10">
        <ErrorBoundary fallback={WeeklyQuestionsFallback}>
          <SSRSuspense fallback={<WeeklyQuestionsFallback />}>
            <WeeklyQuestionContainer />
          </SSRSuspense>
        </ErrorBoundary>
      </Box>
    </WeeklyQuestionLayout>
  );
}
