import React, { useState } from "react";
import { format, parseISO } from "date-fns";
import styled from "styled-components";
import { colorPalette } from "../style-guide/colors";
import { optionsByKey as statusOptions } from "./status-dropdown";
import { optionsByKey as billabeOptions } from "./billable-dropdown";
import { AnchorButton } from "../style-guide/anchor";
import { EllipsisSpinner } from "../style-guide/ellipsis-spinner";
import CoreLink from "../core-link";
import { fontWeights } from "../style-guide/typography";

const Box = styled.div`
  flex: 0 0 215px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-left: 1px solid ${colorPalette.bluegrey30};
  border-top: 1px solid ${colorPalette.bluegrey30};
  border-bottom: 1px solid ${colorPalette.bluegrey30};
  background-color: ${colorPalette.white};
  border-radius: 5px 0 0 5px;
  padding: 18px 0px 8px 16px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;
`;

const CardProject = styled.div`
  margin-bottom: 15px;
`;

const ProductTitle = styled.p`
  color: ${colorPalette.deepBlue500};
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
  font-family: "ProximaNova Bold", sans-serif;
`;

const Subtitle = styled.p`
  color: ${colorPalette.deepBlue500};
  font-size: 14px;
  line-height: 21px;
`;

const Paragraph = styled.p`
  color: ${colorPalette.bluegrey90};
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
`;

const EditLink = styled.p`
  color: ${colorPalette.pink500};
  font-weight: ${fontWeights.bold};
  line-height: 28px;
  font-weight: bold;
  transition: all 0.3s;
  text-decoration: none;

  &:focus,
  &:hover {
    color: ${colorPalette.pink300};
    text-decoration: underline;
  }

  &:active {
    color: ${colorPalette.pink900};
    text-decoration: underline;
  }
`;

const ExtendLink = styled(AnchorButton)`
  text-transform: uppercase;
  display: flex;
  align-items: center;

  &::before {
    color: ${colorPalette.bluegrey90};
    content: "|";
    padding: 0 5px;
    font-weight: normal;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

async function handleExtend(onExtend, setExtendLoading) {
  setExtendLoading(true);
  try {
    await onExtend();
  } catch {
    alert("Something went wrong!");
  } finally {
    setExtendLoading(false);
  }
}

const ExtendSpinner = styled(EllipsisSpinner)`
  margin: 0 0 8px 10px;
`;
function ProjectInfoTimeline({
  project,
  editPermission,
  product,
  responsible,
  onExtend,
}) {
  const hasProjectDates =
    project.attributes.discoveryStart && project.attributes.developmentEnd;

  const status = statusOptions[project.attributes.projectStatus];
  const billable = billabeOptions[project.attributes.billable];
  const [extendLoading, setExtendLoading] = useState(false);

  return (
    <Box>
      <CardContent>
        <CardProject>
          <ProductTitle data-testid="project-info-timeline-product-name">
            {product.attributes.name}
          </ProductTitle>
          <Subtitle data-testid="project-info-timeline-project-name">
            {project.attributes.name}
          </Subtitle>
        </CardProject>
        <Paragraph>{responsible && responsible.attributes.fullName}</Paragraph>
        {hasProjectDates && (
          <Paragraph>
            {`${format(
              parseISO(project.attributes.discoveryStart),
              "M/dd/yyyy",
            )} - ${format(
              parseISO(project.attributes.developmentEnd),
              "M/dd/yyyy",
            )}`}
          </Paragraph>
        )}
        <Paragraph>
          {status && status.content} | {billable && billable.content}
        </Paragraph>
      </CardContent>
      {editPermission && (
        <Actions>
          <CoreLink href={`/staffing/${project.id}/edit`}>
            <EditLink>EDIT</EditLink>
          </CoreLink>

          {onExtend && (
            <ExtendLink
              disabled={extendLoading}
              onClick={() => handleExtend(onExtend, setExtendLoading)}
            >
              {extendLoading ? (
                <>
                  Loading
                  <ExtendSpinner fontSize="2px" />
                </>
              ) : (
                "Extend 1 week"
              )}
            </ExtendLink>
          )}
        </Actions>
      )}
    </Box>
  );
}

export default React.memo(ProjectInfoTimeline);
