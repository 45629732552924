// Modules
import { combineReducers } from "redux";
// Reducers
import entities from "core-redux/reducers/entities";
import grid from "./grid";
import projectId from "./projectId";

export default () =>
  combineReducers({
    entities,
    grid,
    projectId,
  });
