import React from "react";
import styled from "styled-components";
import { colorPalette } from "../style-guide/colors";

export const Container = styled.div`
  display: flex;
  min-width: 900px;
`;

export const FakeGrid = styled.div`
  width: 100%;
  background-size: calc(100% / 12) 100%;
  border-right: 1px solid ${colorPalette.bluegrey30};
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    ${colorPalette.bluegrey30} 1px,
    transparent 1px,
    transparent
  );
  background-position: left 0 top;
`;

export const WrapInfo = styled.div`
  flex: 0 0 420px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: ${colorPalette.bluegrey90};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
`;

const HideButton = styled.button`
  border: none;
  background: none;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  color: ${colorPalette.deepBlue500};
  text-decoration: underline;
  margin-right: 10px;
`;

function BenchTimelineInfo({ toggleShow, showBench }) {
  return (
    <Container>
      <WrapInfo>
        <Title>The bench</Title>
        <HideButton onClick={toggleShow}>
          {showBench ? "Hide bench" : "Show bench"}
        </HideButton>
      </WrapInfo>
      <FakeGrid />
    </Container>
  );
}

export default BenchTimelineInfo;
