import React from "react";
import cn from "clsx";
import { Frame, Text, Inline, noop } from "@ableco/baseline";
import { CaretRightOutline } from "@baseline/icons";
import CoreLink from "../core-link";

/**
 * @param {object} props
 * @param {React.ReactChild} props.children - The content of the menu
 */
const Menu = React.forwardRef(
  ({ children, className = "", style, width = "w-full", ...props }, ref) => (
    <Frame
      innerRef={ref}
      as="ul"
      bg="white"
      shadow="md"
      className={cn(width, className)}
      corners="small"
      p={[1, 0]}
      style={{ ...style, minWidth: 140, width: 208, zIndex: 2 }}
      {...props}
    >
      {children}
    </Frame>
  ),
);
export default Menu;

export function MenuItem({
  children,
  menu,
  href,
  prefetch,
  p,
  as = href,
  textWeight = "normal",
  textColor = "neutral-800",
  prepare = noop,
  onClick = noop,
  disabled,
  isExternalLink,
}) {
  const [focused, setFocused] = React.useState(false);
  if (!href) {
    return (
      <Frame
        as="li"
        p={p || [3, 4]}
        role="menuitem"
        bg={["white", "primary-lighter"]}
        className="transition-colors duration-300 ease-in-out block h-full"
        onClick={onClick}
      >
        <Text weight={textWeight} color={textColor}>
          {children}
        </Text>
      </Frame>
    );
  }
  const menuItemBody = (
    <Inline
      distribution="between"
      p={p || [4, 6, 4, 4]}
      className="block h-full cursor-pointer text-sm text-black w-full"
    >
      <Text color={textColor}>{children}</Text>
      {menu && <CaretRightOutline className="w-4 h-4" />}
    </Inline>
  );

  return (
    <Frame
      as="li"
      className="transition-colors duration-300 ease-in-out cursor-pointer relative z-10"
      role="menuitem"
      onClick={onClick}
      onMouseEnter={() => children && setFocused(true)}
      onMouseLeave={() => children && setFocused(false)}
      bg={["white", "primary-lighter"]}
    >
      {disabled ? (
        menuItemBody
      ) : (
        <CoreLink
          href={disabled ? undefined : href}
          as={disabled ? undefined : as}
          prepare={prepare}
          prefetch={prefetch}
          className="hover:no-underline"
          isExternalLink={isExternalLink}
        >
          {menuItemBody}
        </CoreLink>
      )}

      {menu && focused ? (
        <Menu className="absolute top-0" style={{ left: "100%", top: -4 }}>
          {menu}
        </Menu>
      ) : null}
    </Frame>
  );
}

export function MenuLine({ className = "" }) {
  return (
    <Frame as="li" className={cn("text-neutral-300 py-2", className)}>
      <Frame as="hr" />
    </Frame>
  );
}
