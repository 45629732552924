import React from "react";
import { wrap } from "./utils";
import { useCollection, api } from "coreql";
import {
  Stack,
  Inline,
  Frame,
  noop,
  Touchable,
  Flex,
  AccordionGroup,
  AccordionTitle,
  Color,
  Corners,
  FlexDistribution,
  useAccordionValue,
  Button,
  ButtonVariant,
  Shadow,
} from "@ableco/baseline";
import RenameOneOnOne from "./rename-one-on-one";
import {
  AddCircleOutline,
  CaretDownOutline,
  CaretUpOutline,
  CommentOutline,
  Info,
} from "@baseline/icons";
import { KeyResults } from "./key-results";
import LinkButton from "../link-button";
import CommentForm from "../comment-form";
import {
  TitleSemibold,
  BodyBaseline,
} from "../design-system/typography-components";
import { AnimatePresence, motion } from "framer-motion";
import { getPrivateCommentReceiverName } from "./how-this-week-went/utils";
import { CoreTooltip } from "../core-tooltip";

const ON_TRACK = "on_track";
const BEHIND = "behind";
const AT_RISK = "at_risk";

function OKRStatus({ onSelect = noop, status = ON_TRACK, checked = false }) {
  const statusAttributes = {
    [ON_TRACK]: { title: "On track", color: "success" },
    [BEHIND]: { title: "Behind", color: "warning" },
    [AT_RISK]: { title: "At risk", color: "alert" },
  };

  function onClickHandler() {
    onSelect(status);
  }

  return (
    <Button
      corners={Corners.MediumRounded}
      border={Color.Neutral400}
      p={[3, 3]}
      onClick={onClickHandler}
      variant={ButtonVariant.Outline}
      bg={Color.White}
      shadow={Shadow.None}
      className="group hover:border-neutral-600 focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out"
      data-testid={`okr-status-${status}`}
    >
      <Inline className="cursor-pointer">
        <Frame p={[0, 2, 0, 0]}>
          <Frame
            bg={checked ? statusAttributes[status].color : "white"}
            border={statusAttributes[status].color}
            corners={Corners.FullRounded}
            className="h-4 w-4"
          />
        </Frame>
        <BodyBaseline color={Color.Neutral800}>
          {statusAttributes[status].title}
        </BodyBaseline>
      </Inline>
    </Button>
  );
}

function KeyResultsAccordionContent({ keyResults, journalId }) {
  const isOpen = useAccordionValue();
  return (
    <AnimatePresence>
      {isOpen && (
        <Frame
          as={motion.div}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.4 }}
        >
          <KeyResults keyResults={keyResults} journalId={journalId} />
        </Frame>
      )}
    </AnimatePresence>
  );
}

function Objective({
  objective,
  user,
  journalId,
  refetch,
  userId,
  managerId,
  weeklyUser,
}) {
  function getStatusForWeeklyReport() {
    return wrap(objective.objectiveStatusUpdates || []).find(
      (status) =>
        String(status.objectiveId) === String(objective.id) &&
        String(status.weeklyReportId) === String(journalId),
    );
  }

  const objectiveStatus = getStatusForWeeklyReport() || {};

  const NOTHING_SELECTED = "default";
  const [selectedStatus, setSelectedStatus] = React.useState(
    objectiveStatus.status ?? NOTHING_SELECTED,
  );

  const {
    data: { data: comments },
    revalidate,
  } = useCollection("comments", {
    filters: {
      commentable_type: ["ObjectiveStatusUpdate"],
      commentable_id: [objectiveStatus.id],
    },
    fields: {
      comments: ["private", "avatarUser", "userFullname", "body", "userId"],
    },
  });

  const [showCommentsForm, setShowCommentsForm] = React.useState(
    comments.length > 0,
  );

  React.useEffect(() => {
    if (objectiveStatus.status === selectedStatus) {
      return;
    }

    api.objectiveStatusUpdates
      .find(objectiveStatus.id)
      .upsert(
        { status: selectedStatus },
        {
          objectiveId: objective.id,
          weeklyReportId: journalId,
        },
      )
      .then(() => refetch());
  }, [
    objectiveStatus.id,
    objectiveStatus.status,
    journalId,
    objective.id,
    refetch,
    selectedStatus,
  ]);

  const [keyResultsOpen, setKeyResultsOpen] = React.useState(false);

  function handleSelect(status) {
    setKeyResultsOpen(true);
    setSelectedStatus(status);
  }

  function canAddOneOnOne() {
    return !(weeklyUser.id === userId && !managerId);
  }

  async function addOneOnOneItem() {
    await api.oneOnOneItems.create(
      {
        relatedResourceId: objectiveStatus.id,
        relatedResourceType: "ObjectiveStatusUpdate",
        completed: false,
        text: `Objective: ${objective.title}`,
        kind: "talking_point",
        requestedId: weeklyUser.id === userId ? managerId : weeklyUser.id,
        requesterId: userId,
      },
      [
        { resource: "user", name: "requester" },
        { resource: "user", name: "requested" },
      ],
    );
  }
  return (
    <Stack space={4}>
      <Frame>
        <Stack space={4}>
          <Flex
            className="w-full group"
            direction="horizontal"
            distribution={FlexDistribution.Between}
          >
            <TitleSemibold className="pt-1 pb-1 max-w-xl">
              {objective.title}
              {objective.ancestorsName && (
                <Frame className="ml-2 mt-1 inline-block absolute">
                  <CoreTooltip
                    title="Parent OKR:"
                    label={objective.ancestorsName}
                  >
                    <Frame data-testid="parent-objective-info">
                      <Info className="w-4 h-4 opacity-45 hover:opacity-100 focus:opacity-100 transition-opacity duration-300 ease-in-out" />
                    </Frame>
                  </CoreTooltip>
                </Frame>
              )}
            </TitleSemibold>
            <Inline space={4}>
              {!showCommentsForm && (
                <CoreTooltip label="Add Comment">
                  <Touchable
                    data-testid="add-comment"
                    onClick={() => setShowCommentsForm(true)}
                    className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out"
                  >
                    <CommentOutline
                      className="w-6 h-6 opacity-45 hover:opacity-100 focus:opacity-100"
                      id={objectiveStatus.id}
                    />
                  </Touchable>
                </CoreTooltip>
              )}
              {!objectiveStatus.oneOnOneItem && canAddOneOnOne() && (
                <CoreTooltip label="Add to 1-on-1">
                  <Touchable
                    data-testid="add-one-on-one"
                    onClick={() => {
                      addOneOnOneItem().then(() => refetch());
                    }}
                    className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out"
                  >
                    <AddCircleOutline className="w-6 h-6 opacity-45 hover:opacity-100 focus:opacity-100 transition-all duration-300 ease-in-out" />
                  </Touchable>
                </CoreTooltip>
              )}
            </Inline>
          </Flex>
          <Inline
            space={2}
            data-testid={`objective:${objective.id}:status:${objectiveStatus.id}`}
          >
            {[ON_TRACK, BEHIND, AT_RISK].map((status) => (
              <Frame key={status}>
                <OKRStatus
                  onSelect={handleSelect}
                  status={status}
                  checked={selectedStatus == status}
                />
              </Frame>
            ))}
          </Inline>
        </Stack>
      </Frame>
      {objectiveStatus.oneOnOneItem && (
        <RenameOneOnOne
          oneOnOneItemId={objectiveStatus.oneOnOneItem?.id}
          oneOnOneItemText={objectiveStatus.oneOnOneItem?.text}
          refetch={refetch}
        />
      )}
      {/*TODO: Fallback is gonna be implemented here https://fino.able.co/products/13/items/7153*/}
      <React.Suspense fallback={null}>
        <CommentForm
          weeklyReportId={journalId}
          senderId={user.id}
          senderAvatar={user.avatarUrl}
          receiverName={getPrivateCommentReceiverName(weeklyUser, user)}
          currentUserId={user.id}
          answerId={objectiveStatus.id}
          commentableType="ObjectiveStatusUpdate"
          showAddComment={showCommentsForm}
          comments={comments}
          revalidate={revalidate}
          setShowAddComment={setShowCommentsForm}
        />
      </React.Suspense>
      {objective.keyResults && (
        <AccordionGroup isOpen={keyResultsOpen}>
          <Frame>
            <AccordionTitle as={LinkButton} border="transparent">
              {({ isOpen }) => (
                <Inline>
                  {isOpen ? (
                    <>
                      Hide key results{" "}
                      <CaretUpOutline className="w-4 h-4 ml-2" />
                    </>
                  ) : (
                    <>
                      Show key results{" "}
                      <CaretDownOutline className="w-4 h-4 ml-2" />
                    </>
                  )}
                </Inline>
              )}
            </AccordionTitle>
          </Frame>
          <KeyResultsAccordionContent
            keyResults={wrap(objective.keyResults)}
            journalId={journalId}
          />
        </AccordionGroup>
      )}
    </Stack>
  );
}

export function useObjectives(userId) {
  return useCollection(
    "objectives",
    {
      fields: {
        objectives: [
          "title",
          "department",
          "owner",
          "objectiveStatusUpdates",
          "keyResults",
          "ancestorsName",
        ],
        keyResults: [
          "title",
          "dueDate",
          "start",
          "target",
          "kind",
          "keyResultStatusUpdates",
          "draftKeyResultStatusUpdates",
          "decreasing",
        ],
        keyResultStatusUpdates: ["status", "weeklyReportId", "periodStart"],
        comments: ["id"],
      },
      filters: { ownerId: userId, active: true, open: true },
      included: [
        "objectiveStatusUpdates",
        "objectiveStatusUpdates.comments",
        "keyResults",
        "keyResults.keyResultStatusUpdates",
        "keyResults.draftKeyResultStatusUpdates",
        "objectiveStatusUpdates.OneOnOneItem",
      ],
    },
    "denormalized",
  );
}

export function filterObjectivesThatCanReceiveAnUpdateInThisReport(
  objectives,
  periodStart,
  weeklyReportId,
) {
  return objectives.filter(
    (objective) =>
      !wrap(objective.objectiveStatusUpdates || []).some(
        (status) =>
          status.periodStart === periodStart &&
          status.weeklyReportId.toString() != weeklyReportId?.toString() &&
          status.submittedAt,
      ),
  );
}

export function useUser(userId) {
  return useCollection(
    "users",
    {
      fields: {
        users: ["preferredName", "avatarUrl", "manager"],
      },
      filters: { id: userId, active: true },
      included: ["manager"],
    },
    "denormalized",
  );
}

export function Objectives({
  userId,
  journalId,
  weeklyReportPeriodStart,
  managerId,
  weeklyUser,
}) {
  const { data: allObjectives, mutate: refetchObjectives } =
    useObjectives(userId);
  const objectives = filterObjectivesThatCanReceiveAnUpdateInThisReport(
    allObjectives,
    weeklyReportPeriodStart,
    journalId,
  );

  const { data: users } = useUser(userId);
  const user = users[0];
  return (
    <Stack space={10}>
      {objectives.map((objective) => (
        <Objective
          key={objective.id.toString()}
          user={user}
          objective={objective}
          journalId={journalId}
          refetch={refetchObjectives}
          userId={userId}
          managerId={managerId}
          weeklyUser={weeklyUser}
        />
      ))}
    </Stack>
  );
}
