import React, { useState, useMemo } from "react";
import {
  Color,
  Corners,
  Frame,
  Grid,
  Inline,
  Skeleton,
  Stack,
} from "@ableco/baseline";

import CapabilitiesTableBody from "./capabilities-table-body";
import CapabilitiesTableHeader from "./capabilities-table-header";
import CapabilitiesTableMainHeader from "./capabilities-table-main-header";

function BaseSkeleton(props) {
  return (
    <Skeleton
      color={Color.Neutral400}
      corners={Corners.LargeRounded}
      animated
      {...props}
    />
  );
}

export function CapabilitiesTableFallback() {
  return (
    <>
      <Frame bg={Color.White} p={[0, 8]}>
        <Stack space={4} p={[4, 0]}>
          <BaseSkeleton width="180px" height="20px" />
          <Inline>
            <Frame className="w-1/3">
              <BaseSkeleton width="120px" height="15px" />
            </Frame>
            {Array.from({ length: 2 })
              .fill(0)
              .map((_, index) => (
                <Frame key={index} className="w-1/3" p={[0, 4]}>
                  <BaseSkeleton width="180px" height="15px" />
                </Frame>
              ))}
          </Inline>
        </Stack>
      </Frame>
      <Frame p={[0, 8]}>
        <Frame className={`border border-neutral-300 rounded`}>
          {Array.from({ length: 3 })
            .fill(0)
            .map((_, index) => (
              <Grid
                key={index}
                col={3}
                bg={index % 2 === 0 ? Color.Transparent : Color.Neutral100}
                key={index}
              >
                <Frame className="w-full" p={4}>
                  <Stack space={2}>
                    <BaseSkeleton width="250px" height="15px" />
                    <BaseSkeleton width="100px" height="15px" />
                  </Stack>
                </Frame>
                {Array.from({ length: 2 })
                  .fill(0)
                  .map((_, index) => (
                    <Frame key={index} className="w-full" p={4}>
                      <Stack space={2}>
                        <BaseSkeleton width="250px" height="15px" />
                        <BaseSkeleton width="250px" height="15px" />
                        <BaseSkeleton width="150px" height="15px" />
                      </Stack>
                    </Frame>
                  ))}
              </Grid>
            ))}
        </Frame>
      </Frame>
    </>
  );
}

export default function CapabilitiesTable({
  skills,
  levels,
  role,
  startOffset = 0,
  showControls = true,
  fromRoles = false,
  userBadges = [],
  isCurrentUserDirectManager = false,
  metObservableBehaviours = [],
  userId,
  mutateUser,
  renderMetRequirement = false,
}) {
  const [indexValues, setCurrentIndex] = useState([
    startOffset,
    startOffset + 1,
  ]);
  const [skillName, setSkillName] = useState("");
  const skillsByName = useMemo(
    () =>
      skills.sort(({ name: firstSkillName }, { name: secondSkillName }) => {
        if (firstSkillName === "Experience" || secondSkillName === "Experience")
          return firstSkillName === "Experience" ? 1 : -1;

        return firstSkillName.localeCompare(secondSkillName);
      }),
    [skills],
  );

  const oneColumn = levels.length === 1 || levels.length - 1 === startOffset;
  return (
    <Frame p={[0, 0, fromRoles ? 0 : 8, 0]}>
      <CapabilitiesTableMainHeader
        skill={skillName}
        levels={levels}
        indexValues={indexValues}
        setCurrentIndex={setCurrentIndex}
        showControls={showControls}
        role={role}
      />
      {skillsByName.map((skill, index, { length }) => (
        <Frame key={`capability-table-${skill.id}`}>
          <CapabilitiesTableHeader
            isFirst={index === 0}
            skill={skill.name}
            setSkillName={setSkillName}
            role={role}
          />
          <CapabilitiesTableBody
            skill={skill}
            levels={levels}
            indexValues={indexValues}
            showControls={showControls}
            oneColumn={oneColumn}
            showControls={showControls}
            isLast={index + 1 === length}
            fromRoles={fromRoles}
            userBadges={userBadges}
            isCurrentUserDirectManager={isCurrentUserDirectManager}
            metObservableBehaviours={metObservableBehaviours}
            userId={userId}
            mutateUser={mutateUser}
            renderMetRequirement={renderMetRequirement}
          />
        </Frame>
      ))}
    </Frame>
  );
}
