import { subWeeks, endOfWeek, format, parseISO, addWeeks } from "date-fns";
import { range, reduce, sum, max } from "lodash";

import { formatWithDashes } from "../weekly-report/history/utils";
import { wrap } from "../weekly-report/utils";

const WEEKS_GROUP = 12;

export function weeksRange(currentWeeklyStart, weekGroupIndex) {
  const scheduledUntil = subWeeks(
    currentWeeklyStart,
    WEEKS_GROUP * weekGroupIndex,
  );
  const scheduledSince = subWeeks(scheduledUntil, WEEKS_GROUP - 1);
  return { scheduledSince, scheduledUntil };
}

export function nextWeekRange(currentWeeklyStart, weekGroupIndex) {
  const scheduledSince = addWeeks(
    currentWeeklyStart,
    WEEKS_GROUP * weekGroupIndex,
  );
  const scheduledUntil = addWeeks(scheduledSince, WEEKS_GROUP - 1);
  return { scheduledSince, scheduledUntil };
}

export function weeksInGroup(scheduledUntil) {
  return range(WEEKS_GROUP).map((weekIndex) =>
    subWeeks(scheduledUntil, weekIndex),
  );
}

export function groupQuestionsByWeeks(questions, weeks) {
  return reduce(
    weeks,
    (acc, week) => {
      const formatedWeek = formatWithDashes(week);

      acc[formatedWeek] = questions.filter((question) =>
        filterQuestionByWeek(question, formatedWeek),
      );
      return acc;
    },
    {},
  );
}

function filterQuestionByWeek(question, formatedWeek) {
  return wrap(question.scheduledWeeks).some(
    (scheduledWeek) => scheduledWeek.scheduledDate == formatedWeek,
  );
}

export function formatWeekPeriod(periodStart) {
  const periodEnd = endOfWeek(periodStart, {
    weekStartsOn: 1,
  });
  return `${formatDateShort(periodStart)} - ${formatLong(periodEnd)}`;
}

function formatLong(date) {
  return format(date, "MMMM d, yyyy");
}

function formatDateShort(date) {
  return format(date, "MMMM d");
}

export function audienceText(questionable) {
  if (questionable === null) {
    return "Everyone";
  }

  return questionable.type == "departments"
    ? questionable.title
    : questionable.name;
}

export function countWeeklyReportAnswers(weeklyReportAnswers, weeksMonday) {
  const answers = wrap(weeklyReportAnswers).filter((answer) =>
    filterAnswerByWeeklyReportData(answer.weeklyReport, weeksMonday),
  );
  return answers.length;
}

function filterAnswerByWeeklyReportData(weeklyReport, weeksMonday) {
  return (
    weeklyReport.submitted !== null &&
    weeklyReport.periodStart == formatWithDashes(weeksMonday)
  );
}

export function validDate(text) {
  return new Date(text);
}

export function dateOrDefault(scheduledWeeks, date) {
  if (date !== null) {
    return parseISO(date);
  }
  const validWeeks = scheduledWeeks
    .map((scheduledWeek) => parseISO(scheduledWeek.scheduledDate))
    .filter((scheduledWeek) => scheduledWeek <= new Date());

  return max(validWeeks);
}

export function scheduledWeeksIncludeDate(scheduledWeeks, date) {
  return scheduledWeeks
    .map((scheduledWeek) => scheduledWeek.scheduledDate)
    .includes(formatWithDashes(date));
}
export function answersOfDate(question, date) {
  return wrap(question.weeklyReportAnswers).filter((answer) =>
    filterAnswerByWeeklyReportData(answer.weeklyReport, date),
  );
}

export function averageScore(answers) {
  const values = answers.map((answer) => Number.parseInt(answer.data.text));
  return (sum(values) / answers.length).toFixed(1);
}
