import React from "react";
import { wrap } from "./utils";
import produce from "immer";
import { api, useCollection } from "coreql";
import {
  Stack,
  Text,
  Touchable,
  Flex,
  Frame,
  Inline,
  Grid,
  Cell,
} from "@ableco/baseline";
import {
  AddCircleOutline,
  ArrowRight,
  CommentOutline,
  Info,
} from "@baseline/icons";
import RenameOneOnOne from "./rename-one-on-one";
import { CompleteIcon } from "./key-results";
import CommentForm from "../comment-form";
import { useUser } from "./objectives";
import isKeyResultComplete from "../../utils/is-key-result-complete";
import { CoreTooltip } from "../core-tooltip";

const ON_TRACK = "on_track";
const BEHIND = "behind";
const AT_RISK = "at_risk";
const DEFAULT = "default";

export function ObjectiveStatusDot({ status = DEFAULT, showTitle = true }) {
  const ObjStatus = {
    [ON_TRACK]: { title: "On track", color: "success" },
    [BEHIND]: { title: "Behind", color: "warning" },
    [AT_RISK]: { title: "At risk", color: "alert" },
    [DEFAULT]: { title: "Default", color: "neutral-400" },
  };

  return (
    <Frame data-testid={`objective-status-${status}`}>
      <Inline space={2}>
        <Frame
          bg={ObjStatus[status].color}
          border={ObjStatus[status].color}
          corners="full"
          className="h-4 w-4"
        />
        {showTitle && (
          <Text color="neutral-800">{ObjStatus[status].title}</Text>
        )}
      </Inline>
    </Frame>
  );
}

/**
 * @param {number} props.target
 */
function formatDecimal(number) {
  const splitNumber = number.toString().split(".");
  return Number.parseInt(splitNumber[1]) === 0 ? splitNumber[0] : number;
}

/**
 * @param {("BooleanKeyResult"|"PercentageKeyResult"|"NumericKeyResult")} props.kind
 * @param {number} props.status
 * @param {number} props.target
 */
export function KeyResultContent({ kind, status, target, isComplete }) {
  switch (kind) {
    case "BooleanKeyResult": {
      return isComplete ? <>Completed</> : <>Not complete</>;
    }
    case "NumericKeyResult": {
      return (
        <>
          {formatDecimal(status)} / {formatDecimal(target)}
        </>
      );
    }
    case "PercentageKeyResult": {
      return (
        <>
          {formatDecimal(status)}% / {formatDecimal(target)}%
        </>
      );
    }
    default: {
      return null;
    }
  }
}

function getPreviousKeyResultStatus(keyResult, currentStatus) {
  const updates = keyResult.keyResultStatusUpdates
    ? wrap(keyResult.keyResultStatusUpdates)
    : [];
  if (updates.length <= 1) return keyResult.start;

  const sortedUpdates = produce(updates, (draft) => {
    draft.sort((a, b) => new Date(b.periodStart) - new Date(a.periodStart));
  });

  const currentStatusIndex = sortedUpdates.findIndex(
    (update) => String(update.id) === String(currentStatus.id),
  );

  const previousUpdate = sortedUpdates[currentStatusIndex + 1];
  if (!previousUpdate) return keyResult.start;

  return previousUpdate.status;
}

function KeyResultPreviousValue({ keyResult, currentStatus }) {
  const value = getPreviousKeyResultStatus(keyResult, currentStatus);

  switch (keyResult.kind) {
    case "NumericKeyResult": {
      return <>Previous: {formatDecimal(value)}</>;
    }
    case "PercentageKeyResult": {
      return <>Previous: {formatDecimal(value)}%</>;
    }
    default: {
      return null;
    }
  }
}

function ReadOnlyKeyResult({ keyResult, journalId }) {
  const formattedDueDate = new Date(keyResult.dueDate).toLocaleDateString(
    "en-US",
    {
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    },
  );

  const currentStatus =
    wrap(keyResult.keyResultStatusUpdates || []).find(
      (statusUpdate) =>
        String(statusUpdate.weeklyReportId) === String(journalId),
    ) || {};

  const isComplete = isKeyResultComplete(
    keyResult.decreasing,
    currentStatus.status,
    keyResult.target,
  );
  return (
    <Grid col={10} key={keyResult.id}>
      <Cell start={1} end={6}>
        <Text color="neutral-800">{keyResult.title}</Text>
      </Cell>

      <Cell start={6} end={8} className="text-center">
        <Text color="neutral-600">{formattedDueDate}</Text>
      </Cell>
      <Cell start={8} end={11}>
        <Stack space={1} alignment="end">
          <Inline space={2}>
            {isComplete && <CompleteIcon id={keyResult.id} />}
            <Text color="neutral-800">
              <KeyResultContent
                kind={keyResult.kind}
                status={currentStatus.status}
                target={keyResult.target}
                isComplete={isComplete}
              />
            </Text>
          </Inline>
          <Text size="xs" color="neutral-600">
            <KeyResultPreviousValue
              keyResult={keyResult}
              currentStatus={currentStatus}
            />
          </Text>
        </Stack>
      </Cell>
    </Grid>
  );
}

function ReadOnlyObjective({
  objective,
  journalId,
  refetch,
  userId,
  managerId,
  weeklyUser,
}) {
  const { data: users } = useUser(userId);
  const user = users[0];
  function getLastObjectiveStatus(currentObjectiveStatus) {
    const updates = objective.objectiveStatusUpdates
      ? wrap(objective.objectiveStatusUpdates)
      : [];
    if (updates.length <= 1) return "default";

    const sortedObjectiveUpdates = produce(updates, (draft) => {
      draft.sort((a, b) => new Date(b.periodStart) - new Date(a.periodStart));
    });

    const currentStatusIndex = sortedObjectiveUpdates.findIndex(
      (update) => String(update.id) === String(currentObjectiveStatus.id),
    );

    const previousUpdate = sortedObjectiveUpdates[currentStatusIndex + 1];
    if (!previousUpdate) return "default";

    return previousUpdate.status;
  }

  function canAddOneOnOne() {
    return !(weeklyUser.id === userId && !managerId);
  }

  async function addOneOnOneItem() {
    await api.oneOnOneItems.create(
      {
        relatedResourceId: objStatus.id,
        relatedResourceType: "ObjectiveStatusUpdate",
        completed: false,
        text: `Objective: ${objective.title}`,
        kind: "talking_point",
        requestedId: weeklyUser.id === userId ? managerId : weeklyUser.id,
        requesterId: userId,
      },
      [
        { resource: "user", name: "requester" },
        { resource: "user", name: "requested" },
      ],
    );
  }

  const objStatus =
    wrap(objective.objectiveStatusUpdates).find(
      (status) =>
        String(status.objectiveId) === String(objective.id) &&
        String(status.weeklyReportId) === String(journalId),
    ) || {};

  const lastObjectiveStatus = getLastObjectiveStatus(objStatus);
  const {
    data: { data: comments },
    revalidate,
  } = useCollection("comments", {
    filters: {
      commentable_type: ["ObjectiveStatusUpdate"],
      commentable_id: [objStatus.id],
    },
    fields: {
      comments: ["private", "avatarUser", "userFullname", "body", "userId"],
    },
  });
  const [showCommentsForm, setShowCommentsForm] = React.useState(
    comments.length > 0,
  );

  const sortedKeyResults = produce(
    wrap(objective.keyResults || []),
    (draft) => {
      draft.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
    },
  );

  React.useEffect(() => {
    (async () => {
      const { data } = await api.comments.read({
        filters: {
          commentable_type: ["ObjectiveStatusUpdate"],
          commentable_id: [objStatus.id],
        },
        fields: {
          comments: ["private", "avatarUser", "userFullname", "body", "userId"],
        },
      });
      if (data.length > 0) return setShowCommentsForm(true);
    })();
  }, [objStatus.id]);

  return (
    <Stack space={6}>
      <Flex
        className="w-full group"
        direction="horizontal"
        distribution="between"
      >
        <Stack space={3}>
          <Text
            color="neutral-800"
            size="lg"
            weight="semibold"
            className="pt-1 pb-1 max-w-xl"
          >
            {objective.title}
            {objective.ancestorsName && (
              <Frame className="ml-2 inline-block absolute">
                <CoreTooltip
                  title="Parent OKR:"
                  label={objective.ancestorsName}
                >
                  <Frame data-testid="parent-objective-info">
                    <Info className="w-4 h-4 opacity-45 hover:opacity-100 focus:opacity-100 transition-opacity duration-300 ease-in-out" />
                  </Frame>
                </CoreTooltip>
              </Frame>
            )}
          </Text>
          <Inline space={1}>
            <ObjectiveStatusDot
              status={lastObjectiveStatus}
              showTitle={false}
            />
            <ArrowRight
              color="gray"
              className="w-4 h-4 align-middle ml-1 mr-1"
            />
            <ObjectiveStatusDot status={objStatus.status} />
          </Inline>
        </Stack>
        <Inline space={4}>
          {!showCommentsForm && (
            <CoreTooltip label="Add Comment">
              <Touchable
                data-testid="add-comment"
                onClick={() => setShowCommentsForm(true)}
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
              >
                <CommentOutline
                  id={objStatus.id}
                  className="w-7 h-7 opacity-45 hover:opacity-100 focus:opacity-100 transition-opacity duration-300 ease-in-out"
                />
              </Touchable>
            </CoreTooltip>
          )}
          {!objStatus.oneOnOneItem && canAddOneOnOne() && (
            <CoreTooltip label="Add to 1-on-1">
              <Touchable
                data-testid={`add-one-on-one:${objective.id}`}
                onClick={() => {
                  addOneOnOneItem().then(() => refetch());
                }}
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
              >
                <AddCircleOutline className="w-7 h-7 opacity-45 hover:opacity-100 focus:opacity-100 transition-opacity duration-300 ease-in-out" />
              </Touchable>
            </CoreTooltip>
          )}
        </Inline>
      </Flex>
      {/*TODO: Fallback is gonna be implemented here https://fino.able.co/products/13/items/7153*/}
      <React.Suspense fallback={null}>
        <CommentForm
          weeklyReportId={journalId}
          senderId={user.id}
          senderAvatar={user.avatarUrl}
          receiverName={
            weeklyUser.id === userId
              ? user.manager.preferredName
              : weeklyUser.preferredName
          }
          currentUserId={user.id}
          answerId={objStatus.id}
          commentableType="ObjectiveStatusUpdate"
          showAddComment={showCommentsForm}
          comments={comments}
          revalidate={revalidate}
          setShowAddComment={setShowCommentsForm}
        />
      </React.Suspense>
      {objStatus && objStatus.oneOnOneItem && (
        <RenameOneOnOne
          oneOnOneItemId={objStatus.oneOnOneItem?.id}
          oneOnOneItemText={objStatus.oneOnOneItem?.text}
          refetch={refetch}
        />
      )}
      <Stack space={8}>
        {sortedKeyResults.map((keyResult) => (
          <ReadOnlyKeyResult
            key={keyResult.id}
            keyResult={keyResult}
            journalId={journalId}
          />
        ))}
      </Stack>
    </Stack>
  );
}

export default ReadOnlyObjective;
