export default function replaceEmojis({ slack, normal }, text) {
  function replacer(code) {
    const codeWithRemovedColons = code.slice(1, -1);

    const emojiNormal = normal[codeWithRemovedColons];
    if (emojiNormal) {
      return `<span role="img" aria-label="${codeWithRemovedColons}">${emojiNormal}</span>`;
    }

    const emojiSlack = slack[codeWithRemovedColons];
    if (!emojiSlack) return code;

    if (emojiSlack.startsWith("alias:")) {
      return replacer(`:${emojiSlack.slice(6)}:`);
    }

    return `<img src=${emojiSlack} aria-label="${codeWithRemovedColons}" class="inline-block align-text-bottom w-4"  alt="${codeWithRemovedColons}"/>`;
  }

  return text.replace(/:(.*?):/g, replacer);
}
