import React, { useState, useCallback } from "react";
import usePortal from "react-useportal";
import useClickOutside from "./use-click-outside";
import { Frame, noop } from "@ableco/baseline";
import { motion } from "framer-motion";

const motionProps = {
  initial: { opacity: 0, scale: 0.85, y: 20 }, // animate from
  animate: { opacity: 1, scale: 1, x: 0, y: 0 }, // animate to
  exit: { opacity: 0, scale: 0.85, y: 20 }, // animate exit
  transition: {
    type: "spring",
    stiffness: 800,
    damping: 35,
  },
};

function TooltipCard({ style, children, p = [4, 5], withArrow, ...props }) {
  const defaultStyle = {
    top: props.top + props.height + 8,
    left: props.left,
    minWidth: 292,
  };

  return (
    <Frame
      as={motion.div}
      {...motionProps}
      className="absolute z-20"
      {...props}
      style={Object.assign({}, defaultStyle, style)}
    >
      <Frame
        bg="white"
        corners="medium"
        shadow="md"
        p={p}
        className={`${
          withArrow ? "dialog-arrow-top" : false
        } text-neutral-700 font-normal text-xs`}
      >
        {children}
      </Frame>
    </Frame>
  );
}

function useTooltip(options = {}) {
  const defaultOptions = {
    onClose: noop,
  };
  const { onClose } = Object.assign({}, defaultOptions, options);

  const { Portal, portalRef } = usePortal();
  const [isVisible, setIsVisible] = useState(false);
  const [triggerInfo, setTriggerInfo] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  function open({ top, left, width, height }) {
    setTriggerInfo({
      top,
      left,
      width,
      height,
    });
    !isVisible && setIsVisible(true);
  }

  function close() {
    isVisible && setIsVisible(false);
    onClose();
  }

  useClickOutside(portalRef, close);

  const onClick = useCallback(({ target }) => {
    const rect = target.getBoundingClientRect();
    setTriggerInfo({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
      width: rect.width,
      height: rect.height,
    });
    setIsVisible((oldIsVisible) => !oldIsVisible);
  }, []);

  const Tooltip = useCallback(
    ({ children, style, p, withArrow = true }) =>
      isVisible ? (
        <Portal>
          <TooltipCard
            data-testid="tooltip-card"
            {...triggerInfo}
            children={children}
            p={p}
            style={style}
            withArrow={withArrow}
          />
        </Portal>
      ) : null,
    [triggerInfo, isVisible],
  );

  return [
    Tooltip,
    {
      onClick,
    },
    { setVisibility: setIsVisible, open, close, isVisible },
  ];
}

export default useTooltip;
