import React from "react";

// I'm still checking if this is a good pattern or if we could
// use xstate instead
export default function useMachine(events, initialState) {
  return React.useReducer((state, event) => {
    const handler = events[event.type];
    if (!handler) throw new Error(`Invalid event of type ${event.type}`);
    return handler(event, state);
  }, initialState);
}
