// Modules
import { createSelector } from "reselect";
// Selectors
import * as CostSelectors from "utilization/selectors/costSelectors";
import * as HeadcountSelectors from "utilization/selectors/headcountSelectors";
import * as HoursSelectors from "utilization/selectors/hoursSelectors";

const getProductIds = (_state, products) =>
  products.map((product) => product.id);

const getPeriods = (state) => state.periods;

const getRoles = (state) => state.entities.roles;

const getUsers = (state) => state.entities.users;

const getPlaceholders = (state) => state.entities.placeholders;

const getProductAllocations = (state) => state.entities.productAllocations;

const getAssigneeComparator = () => (arrVal, othVal) =>
  arrVal.assigneeId === othVal.assigneeId &&
  arrVal.assigneeType === othVal.assigneeType;

export const getBudgetedCost = createSelector(
  [getProductIds, getPeriods, getProductAllocations],
  CostSelectors.all,
);

export const getBudgetedCostByRole = createSelector(
  [getRoles, getProductIds, getPeriods, getProductAllocations],
  CostSelectors.byRole,
);

export const getBudgetedCostByUser = createSelector(
  [getUsers, getPlaceholders, getProductIds, getPeriods, getProductAllocations],
  CostSelectors.byAssignee,
);

export const getBudgetedHeadcount = createSelector(
  [getProductIds, getPeriods, getProductAllocations, getAssigneeComparator],
  HeadcountSelectors.all,
);

export const getBudgetedHeadcountByRole = createSelector(
  [
    getRoles,
    getProductIds,
    getPeriods,
    getProductAllocations,
    getAssigneeComparator,
  ],
  HeadcountSelectors.byRole,
);

export const getBudgetedHeadcountByUser = createSelector(
  [
    getUsers,
    getPlaceholders,
    getProductIds,
    getPeriods,
    getProductAllocations,
    getAssigneeComparator,
  ],
  HeadcountSelectors.byAssignee,
);

export const getBudgetedHours = createSelector(
  [getProductIds, getPeriods, getProductAllocations],
  HoursSelectors.all,
);

export const getBudgetedHoursByRole = createSelector(
  [getRoles, getProductIds, getPeriods, getProductAllocations],
  HoursSelectors.byRole,
);

export const getBudgetedHoursByUser = createSelector(
  [getUsers, getPlaceholders, getProductIds, getPeriods, getProductAllocations],
  HoursSelectors.byAssignee,
);
