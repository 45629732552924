import React from "react";
import { SSRSuspense } from "coreql";
import {
  Frame,
  Stack,
  Center,
  Inline,
  Text,
  Touchable,
} from "@ableco/baseline";
import { BulletUnread } from "../feedback-messages/feedback-message-content";
import { HelpBubble } from "@baseline/icons";
import { Header as HeaderLayout } from "../layout/layout";
import useCurrentUser from "../../hooks/use-current-user";
import Menu, { MenuItem, MenuLine } from "../layout/menu";
import { AnimatePresence, motion } from "framer-motion";
import { AvatarFallbackMedium, AvatarXS } from "../design-system/avatar";

function UserInfo() {
  const { data: currentUser } = useCurrentUser();
  const $ref = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const closeModal = React.useCallback(() => setOpen(false), [setOpen]);

  return (
    <Frame
      className="relative"
      innerRef={$ref}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => {
        closeModal();
      }}
    >
      <Touchable data-testid="touchable-user-info">
        <Stack alignment="start" distribution="center" p={[5, 3]}>
          <AvatarXS url={currentUser.avatarUrl} name={currentUser.fullName} />
          {currentUser.hasUnreadFeedback && (
            <BulletUnread
              style={{ top: "20px", left: "10px" }}
              className="self-start absolute"
              data-testid="unread-bubble"
            />
          )}
        </Stack>
      </Touchable>
      <AnimatePresence>
        {open && (
          <Menu
            className="absolute right-0 border border-neutral-500"
            style={{ top: "100%", zIndex: 2, width: 208 }}
            as={motion.ul}
            key="accordion"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0.3, height: 0 },
            }}
            transition={{ duration: 0.2 }}
          >
            <MenuItem
              href={`/users/${currentUser.id}`}
              onClick={closeModal}
              prefetch={false}
              p={[3, 4]}
            >
              <Inline>
                <Text className="mr-2">My Profile</Text>
                {currentUser.hasUnreadFeedback && (
                  <BulletUnread data-testid="unread-feedback" />
                )}
              </Inline>
            </MenuItem>
            <MenuItem
              href={
                currentUser.hasPendingJournal
                  ? "/weekly_reports/new"
                  : "/weekly_reports/my_journal"
              }
              onClick={closeModal}
              prefetch={false}
              p={[3, 4]}
            >
              Submit Weekly Journal
            </MenuItem>
            <MenuLine />
            <MenuItem
              href="/logout"
              onClick={closeModal}
              prefetch={false}
              p={[3, 4]}
              isExternalLink={true}
            >
              Sign Out
            </MenuItem>
          </Menu>
        )}
      </AnimatePresence>
    </Frame>
  );
}

export default function NavigationHeader() {
  return (
    <HeaderLayout className="absolute right-0 z-10">
      <Inline alignment="start">
        <Center
          as="a"
          href="/help"
          className="h-16 w-8 text-neutral-600 focus:outline-none"
          p={[5, 3]}
          title="Ops Request"
          target="blank"
        >
          <Frame>
            <HelpBubble className="w-4 h-4 transition-colors duration-300 ease-in-out" />
            <Text srOnly>Ops Request</Text>
          </Frame>
        </Center>
        <SSRSuspense
          fallback={
            <Center className="h-16 w-16">
              <AvatarFallbackMedium name="Loading user..." />
            </Center>
          }
        >
          <UserInfo />
        </SSRSuspense>
      </Inline>
    </HeaderLayout>
  );
}
