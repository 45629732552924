// Modules
import { connect } from "react-redux";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isBefore,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import queryString from "query-string";
// Actions
import { push } from "connected-react-router";
// Components
import Controls from "./Controls";

const mapStateToProps = (state) => {
  const { filters } = state.ui;

  return {
    ...filters,
  };
};

const updateFilter = (updatedProp, search) => {
  const redirect = {
    search: `?${queryString.stringify({
      ...queryString.parse(search),
      ...updatedProp,
    })}`,
  };

  return push(redirect);
};

const mapDispatchToProps = (dispatch) => ({
  updateDetailBy: (event) => {
    dispatch((dispatch, getState) => {
      const { search } = getState().router.location;
      const detailBy = event.target.value;

      dispatch(updateFilter({ detailBy }, search));
    });
  },

  updateEndDate: (endDate) =>
    dispatch((dispatch, getState) => {
      const { search } = getState().router.location;
      let { startDate, periodIncrement } = getState().ui.filters;

      if (periodIncrement === "week") {
        endDate = endOfWeek(endDate, { weekStartsOn: 1 });
      } else if (periodIncrement === "month") {
        endDate = endOfMonth(endDate);
      } else if (periodIncrement === "year") {
        endDate = endOfYear(endDate);
      }

      if (isBefore(endDate, startDate)) {
        if (periodIncrement === "week") {
          startDate = startOfWeek(endDate, { weekStartsOn: 1 });
        } else if (periodIncrement === "month") {
          startDate = startOfMonth(endDate);
        } else if (periodIncrement === "year") {
          startDate = startOfYear(endDate);
        }
      }

      dispatch(
        updateFilter(
          {
            endDate: format(endDate, "yyyy-MM-dd"),
            startDate: format(startDate, "yyyy-MM-dd"),
          },
          search,
        ),
      );
    }),

  updatePeriodIncrement: (event) => {
    dispatch((dispatch, getState) => {
      const { search } = getState().router.location;
      const periodIncrement = event.target.value;
      const { endDate, startDate } = getState().ui.filters;
      let startDateDay, endDateDay;

      if (periodIncrement === "week") {
        startDateDay = startOfWeek(startDate, { weekStartsOn: 1 });
        endDateDay = endOfWeek(endDate, { weekStartsOn: 1 });
      } else if (periodIncrement === "month") {
        startDateDay = startOfMonth(startDate);
        endDateDay = endOfMonth(endDate);
      } else if (periodIncrement === "year") {
        startDateDay = startOfYear(startDate);
        endDateDay = endOfYear(endDate);
      }

      dispatch(
        updateFilter(
          {
            periodIncrement,
            endDate: format(endDateDay, "yyyy-MM-dd"),
            startDate: format(startDateDay, "yyyy-MM-dd"),
          },
          search,
        ),
      );
    });
  },

  updateStartDate: (startDate) =>
    dispatch((dispatch, getState) => {
      const { search } = getState().router.location;
      let { endDate, periodIncrement } = getState().ui.filters;

      if (periodIncrement === "week") {
        startDate = startOfWeek(startDate, { weekStartsOn: 1 });
      } else if (periodIncrement === "month") {
        startDate = startOfMonth(startDate);
      } else if (periodIncrement === "year") {
        startDate = startOfYear(startDate);
      }

      if (isBefore(endDate, startDate)) {
        if (periodIncrement === "week") {
          endDate = endOfWeek(startDate, { weekStartsOn: 1 });
        } else if (periodIncrement === "month") {
          endDate = endOfMonth(startDate);
        } else if (periodIncrement === "year") {
          endDate = endOfYear(startDate);
        }
      }

      dispatch(
        updateFilter(
          {
            endDate: format(endDate, "yyyy-MM-dd"),
            startDate: format(startDate, "yyyy-MM-dd"),
          },
          search,
        ),
      );
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
