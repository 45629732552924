import React, { Suspense, useMemo, useState } from "react";
import styled from "styled-components";
import { format, parseISO } from "date-fns";
import ProjectInfo from "./project-info";
import SectionTitle from "../section-title";
import EditProjectEstimations from "./edit-project-estimations";
import getMondays from "./../../utils/get-mondays";
import { useResource } from "coreql";
import {
  buildAssignmentsByRow,
  getProjectStart,
  getProjectEnd,
} from "./project-timeline";

import { colorPalette } from "../style-guide/colors";
import { fontSizes, fontWeights } from "../style-guide/typography";
import BudgetForm from "./edit-budget-form/edit-budget-form";
import { BaseAnchor } from "../style-guide/anchor";
import CoreLink from "../core-link";

const Container = styled.div`
  box-sizing: border-box;
  max-width: 800px;
  margin-top: 15px;
  border: 1px solid #eceef2;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 30px;
`;

const SeparatorLine = styled.hr`
  border-top: 1px solid ${colorPalette.bluegrey40};
  margin: 13px 0 28px 0;
`;

const BudgetSectionWrapper = styled.div`
  margin-top: 43px;
`;

const BudgetSectionTitle = styled(SectionTitle)`
  font-size: 16px;
`;

const EstimatesSectionTitle = styled(SectionTitle)`
  font-size: 16px;
`;

const PageTitle = styled(SectionTitle)`
  margin-top: 15px;
  font-size: 24px;
`;

const EditButton = styled.button`
  font-size: ${fontSizes.smallX}px;
  color: ${colorPalette.pink500};
  font-weight: ${fontWeights.bold};

  text-transform: uppercase;
  margin-left: 20px;
  display: table-cell;
  vertical-align: middle;

  &:hover {
    color: ${colorPalette.pink200};
  }
  &:active {
    color: ${colorPalette.pink900};
  }
`;

const BudgetHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

function defaultFormValues() {
  return {
    type: "allocations",
    attributes: {
      userId: "",
      estimateStartedAt: new Date(),
      estimateEndedAt: new Date(),
      staff: [],
    },
  };
}

function getWeeks(allocation) {
  const { estimateStartedAt, estimateEndedAt } = allocation.attributes;
  return getMondays(estimateStartedAt, estimateEndedAt);
}

function parseStaffWeeks(assignments, weeks) {
  return weeks.map((headerWeek) => {
    const currentAssignment = assignments.find(
      (assignment) =>
        format(parseISO(assignment.attributes.periodStart), "MM/dd") ===
        format(headerWeek, "MM/dd"),
    );
    return {
      id: currentAssignment ? currentAssignment.id : null,
      date: headerWeek,
      hours: currentAssignment ? currentAssignment.attributes.hours : 0,
    };
  });
}

function buildAllocation(data, productsAssignments) {
  const allocation = defaultFormValues();
  const assignmentsByRow = buildAssignmentsByRow(productsAssignments);

  allocation.id = data.id;
  allocation.attributes.productId = data.relationships.product.data.id;
  allocation.attributes.estimateStartedAt = getProjectStart(
    data,
    productsAssignments,
  );
  allocation.attributes.estimateEndedAt = getProjectEnd(
    data,
    productsAssignments,
  );

  const weeks = getWeeks(allocation);

  allocation.attributes.staff = Object.entries(assignmentsByRow).map(
    ([rowId, assignments]) => ({
      projectRoleId: String(assignments[0].attributes.projectRoleId),
      userId: String(assignments[0].attributes.assigneeId || ""),
      weeks: parseStaffWeeks(assignments, weeks),
      rowId,
      rowKey: rowId,
    }),
  );

  return allocation;
}

function formatData({ data, included }) {
  const productsAssignments = included.filter(
    (relationship) => relationship.type === "productAssignments",
  );

  const allocation = buildAllocation(data, productsAssignments);
  const assignmentIds = productsAssignments.map((assignment) => assignment.id);

  return { allocation, assignmentIds };
}

function useProject(id) {
  return useResource("projects", id, {
    included: ["product", "responsible", "projectAssignments"],
    fields: {
      projects: [
        "name",
        "discoveryStart",
        "developmentEnd",
        "billable",
        "projectStatus",
        "userId",
        "hasAllocations",
        "product",
      ],
      products: ["name"],
      users: ["fullName"],
      productAssignments: [
        "assigneeId",
        "projectId",
        "productId",
        "projectRoleId",
        "assigneeType",
        "periodStart",
        "hours",
        "timeAllocation",
        "rowId",
      ],
    },
  });
}

function BudgetSection({
  inEditMode,
  setInEditMode,
  allocationId,
  projectData,
}) {
  return (
    <BudgetSectionWrapper>
      <BudgetHeader>
        <BudgetSectionTitle>Project Budget</BudgetSectionTitle>
        {!inEditMode && (
          <EditButton
            onClick={() => setInEditMode(!inEditMode)}
            data-testid="edit-budget-button"
          >
            Edit
          </EditButton>
        )}
      </BudgetHeader>
      <BudgetForm
        allocationId={allocationId}
        project={projectData.data}
        inEditMode={inEditMode}
        setInEditMode={setInEditMode}
      />
    </BudgetSectionWrapper>
  );
}

const BackButton = styled(BaseAnchor)``;

function EditAllocation({ allocationId }) {
  const [inEditMode, setInEditMode] = useState(false);
  const { data: projectData, revalidate } = useProject(allocationId);
  const { allocation, assignmentIds } = useMemo(
    () => formatData(projectData),
    [projectData],
  );

  return (
    <Container>
      <Suspense fallback={null}>
        <CoreLink href="/staffing">
          <BackButton aria-label="Back to Timeline">
            &lt; Back to Timeline
          </BackButton>
        </CoreLink>
        <PageTitle>Update project estimate</PageTitle>
        <ProjectInfo project={projectData} revalidate={revalidate} />
        <SeparatorLine />
        <EstimatesSectionTitle>Latest Project Estimate</EstimatesSectionTitle>
        <EditProjectEstimations
          allocation={allocation}
          assignmentIds={assignmentIds}
        />
        <BudgetSection
          inEditMode={inEditMode}
          setInEditMode={setInEditMode}
          allocationId={allocationId}
          projectData={projectData}
        />
      </Suspense>
    </Container>
  );
}

export default EditAllocation;
