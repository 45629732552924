// Modules
import { createSelector } from "reselect";
// Selectors
import * as CostSelectors from "utilization/selectors/costSelectors";
import * as HeadcountSelectors from "utilization/selectors/headcountSelectors";
import * as HoursSelectors from "utilization/selectors/hoursSelectors";

const getProductIds = (_state, products) =>
  products.map((product) => product.id);

const getPeriods = (state) => state.periods;

const getRoles = (state) => state.entities.roles;

const getUsers = (state) => state.entities.users;

const getPlaceholders = (state) => state.entities.placeholders;

const getProductAssignments = (state) => state.entities.productAssignments;

const getUserComparator = () => (arrVal, othVal) =>
  arrVal.assigneeId === othVal.assigneeId;

export const getEstimatedCost = createSelector(
  [getProductIds, getPeriods, getProductAssignments],
  CostSelectors.all,
);

export const getEstimatedCostByRole = createSelector(
  [getRoles, getProductIds, getPeriods, getProductAssignments],
  CostSelectors.byRole,
);

export const getEstimatedCostByUser = createSelector(
  [getUsers, getPlaceholders, getProductIds, getPeriods, getProductAssignments],
  CostSelectors.byAssignee,
);

export const getEstimatedHeadcount = createSelector(
  [getProductIds, getPeriods, getProductAssignments, getUserComparator],
  HeadcountSelectors.all,
);

export const getEstimatedHeadcountByRole = createSelector(
  [
    getRoles,
    getProductIds,
    getPeriods,
    getProductAssignments,
    getUserComparator,
  ],
  HeadcountSelectors.byRole,
);

export const getEstimatedHeadcountByUser = createSelector(
  [
    getUsers,
    getPlaceholders,
    getProductIds,
    getPeriods,
    getProductAssignments,
    getUserComparator,
  ],
  HeadcountSelectors.byAssignee,
);

export const getEstimatedHours = createSelector(
  [getProductIds, getPeriods, getProductAssignments],
  HoursSelectors.all,
);

export const getEstimatedHoursByRole = createSelector(
  [getRoles, getProductIds, getPeriods, getProductAssignments],
  HoursSelectors.byRole,
);

export const getEstimatedHoursByUser = createSelector(
  [getUsers, getPlaceholders, getProductIds, getPeriods, getProductAssignments],
  HoursSelectors.byAssignee,
);
