const path = (name) => (obj) =>
  name
    .split(".")
    .reduce((acc, prop) => (acc && acc[prop] ? acc[prop] : undefined), obj);

const hasFieldErrors = (name, touched, errors) => {
  const get = path(name);
  return get(touched) ? get(errors) : undefined;
};

export default hasFieldErrors;
