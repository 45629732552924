export default {
  API: {
    BATCH_CREATE_REQUEST: "API_BATCH_CREATE_REQUEST",
    BATCH_CREATE_SUCCESS: "API_BATCH_CREATE_SUCCESS",
    BATCH_CREATE_FAILURE: "API_BATCH_CREATE_FAILURE",

    BATCH_DESTROY_REQUEST: "API_BATCH_DESTROY_REQUEST",
    BATCH_DESTROY_SUCCESS: "API_BATCH_DESTROY_SUCCESS",
    BATCH_DESTROY_FAILURE: "API_BATCH_DESTROY_FAILURE",

    BATCH_UPDATE_REQUEST: "API_BATCH_UPDATE_REQUEST",
    BATCH_UPDATE_SUCCESS: "API_BATCH_UPDATE_SUCCESS",
    BATCH_UPDATE_FAILURE: "API_BATCH_UPDATE_FAILURE",

    CREATE_REQUEST: "API_CREATE_REQUEST",
    CREATE_SUCCESS: "API_CREATE_SUCCESS",
    CREATE_FAILURE: "API_CREATE_FAILURE",

    DESTROY_REQUEST: "API_DESTROY_REQUEST",
    DESTROY_SUCCESS: "API_DESTROY_SUCCESS",
    DESTROY_FAILURE: "API_DESTROY_FAILURE",

    LIST_REQUEST: "API_LIST_REQUEST",
    LIST_SUCCESS: "API_LIST_SUCCESS",
    LIST_FAILURE: "API_LIST_FAILURE",

    RETRIEVE_REQUEST: "API_RETRIEVE_REQUEST",
    RETRIEVE_SUCCESS: "API_RETRIEVE_SUCCESS",
    RETRIEVE_FAILURE: "API_RETRIEVE_FAILURE",

    UPDATE_REQUEST: "API_UPDATE_REQUEST",
    UPDATE_SUCCESS: "API_UPDATE_SUCCESS",
    UPDATE_FAILURE: "API_UPDATE_FAILURE",
  },

  ENTITIES: {
    PLACEHOLDERS_REQUEST: "ENTITIES_PLACEHOLDERS_REQUEST",
    PLACEHOLDERS_SUCCESS: "ENTITIES_PLACEHOLDERS_SUCCESS",
    PLACEHOLDERS_FAILURE: "ENTITIES_PLACEHOLDERS_FAILURE",

    PRODUCT_ALLOCATIONS_REQUEST: "ENTITIES_PRODUCT_ALLOCATIONS_REQUEST",
    PRODUCT_ALLOCATIONS_SUCCESS: "ENTITIES_PRODUCT_ALLOCATIONS_SUCCESS",
    PRODUCT_ALLOCATIONS_FAILURE: "ENTITIES_PRODUCT_ALLOCATIONS_FAILURE",

    PRODUCT_ASSIGNMENTS_REQUEST: "ENTITIES_PRODUCT_ASSIGNMENTS_REQUEST",
    PRODUCT_ASSIGNMENTS_SUCCESS: "ENTITIES_PRODUCT_ASSIGNMENTS_SUCCESS",
    PRODUCT_ASSIGNMENTS_FAILURE: "ENTITIES_PRODUCT_ASSIGNMENTS_FAILURE",

    PRODUCT_INVESTMENTS_REQUEST: "ENTITIES_PRODUCT_INVESTMENTS_REQUEST",
    PRODUCT_INVESTMENTS_SUCCESS: "ENTITIES_PRODUCT_INVESTMENTS_SUCCESS",
    PRODUCT_INVESTMENTS_FAILURE: "ENTITIES_PRODUCT_INVESTMENTS_FAILURE",

    PRODUCT_REVENUES_REQUEST: "ENTITIES_PRODUCT_REVENUES_REQUEST",
    PRODUCT_REVENUES_SUCCESS: "ENTITIES_PRODUCT_REVENUES_SUCCESS",
    PRODUCT_REVENUES_FAILURE: "ENTITIES_PRODUCT_REVENUES_FAILURE",

    PRODUCTS_REQUEST: "ENTITIES_PRODUCTS_REQUEST",
    PRODUCTS_SUCCESS: "ENTITIES_PRODUCTS_SUCCESS",
    PRODUCTS_FAILURE: "ENTITIES_PRODUCTS_FAILURE",

    PROJECT_ALLOCATIONS_REQUEST: "ENTITIES_PROJECT_ALLOCATIONS_REQUEST",
    PROJECT_ALLOCATIONS_SUCCESS: "ENTITIES_PROJECT_ALLOCATIONS_SUCCESS",
    PROJECT_ALLOCATIONS_FAILURE: "ENTITIES_PROJECT_ALLOCATIONS_FAILURE",

    PROJECT_ROLES_REQUEST: "ENTITIES_PROJECT_ROLES_REQUEST",
    PROJECT_ROLES_SUCCESS: "ENTITIES_PROJECT_ROLES_SUCCESS",
    PROJECT_ROLES_FAILURE: "ENTITIES_PROJECT_ROLES_FAILURE",

    PROJECTS_REQUEST: "ENTITIES_PROJECTS_REQUEST",
    PROJECTS_SUCCESS: "ENTITIES_PROJECTS_SUCCESS",
    PROJECTS_FAILURE: "ENTITIES_PROJECTS_FAILURE",

    ROLES_REQUEST: "ENTITIES_ROLES_REQUEST",
    ROLES_SUCCESS: "ENTITIES_ROLES_SUCCESS",
    ROLES_FAILURE: "ENTITIES_ROLES_FAILURE",

    USERS_REQUEST: "ENTITIES_USERS_REQUEST",
    USERS_SUCCESS: "ENTITIES_USERS_SUCCESS",
    USERS_FAILURE: "ENTITIES_USERS_FAILURE",
  },
};
