import React from "react";
import { Text, noop } from "@ableco/baseline";
import { Link } from "react-router-dom";

// This was copied from the core-client repo to facilitate the migration of core-client components.
// It was a link wrapper would implement link prefetching with Next.js.
// We removed the next js prefetching and kept the rest.
export default function CoreLink({
  href,
  as = href,
  prefetch,
  prepare = noop,
  onMouseEnter = noop,
  isExternalLink,
  disabled,
  ...props
}) {
  function handleMouseEnter(event) {
    const persistedEvent = event.persist();
    prepare(persistedEvent);
    onMouseEnter(persistedEvent);
  }

  return isExternalLink ? (
    <Text as="a" href={as} onMouseEnter={handleMouseEnter} {...props} />
  ) : (
    <Link
      to={href}
      href={as}
      onMouseEnter={handleMouseEnter}
      onClick={(e) => disabled && e.preventDefault()}
      {...props}
    />
  );
}
