import React from "react";
import { wrap } from "./utils";
import { api, denormalize } from "coreql";
import {
  Stack,
  Inline,
  Frame,
  Text,
  Color,
  Corners,
  Flex,
  FlexAlignment,
} from "@ableco/baseline";
import { CheckCircleOutline } from "@baseline/icons";
import produce from "immer";
import {
  BodySmall,
  BodyBaseline,
} from "../design-system/typography-components";
import Checkbox from "../data-entry/checkbox";
import isKeyResultComplete from "../../utils/is-key-result-complete";
import TextInput from "../text-input";

const BOOLEAN_KEY_RESULT = "BooleanKeyResult";
const NUMERIC_KEY_RESULT = "NumericKeyResult";
const PERCENTAGE_KEY_RESULT = "PercentageKeyResult";

export function KeyResults({ keyResults, journalId }) {
  const sortedKeyResults = produce(wrap(keyResults || []), (draft) => {
    draft.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  });

  return (
    <Stack
      space={8}
      p={6}
      border={Color.Neutral300}
      corners={Corners.MediumRounded}
    >
      {sortedKeyResults.map((keyResult) => (
        <KeyResult
          key={keyResult.id}
          keyResult={keyResult}
          journalId={journalId}
        />
      ))}
    </Stack>
  );
}

function KeyResult({ keyResult, journalId }) {
  const formattedDueDate = new Date(keyResult.dueDate).toLocaleDateString(
    "en-US",
    {
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    },
  );

  return (
    <Stack space={4} className="transition-all duration-300 ease-in-out">
      <Stack space={1}>
        <BodyBaseline color={Color.Neutral800}>{keyResult.title}</BodyBaseline>
        <BodySmall color={Color.Neutral600}>by {formattedDueDate}</BodySmall>
      </Stack>
      <KeyResultStatusInput keyResult={keyResult} journalId={journalId} />
    </Stack>
  );
}

function getPreviousKeyResultStatus(keyResult) {
  const updates = keyResult.keyResultStatusUpdates
    ? wrap(keyResult.keyResultStatusUpdates)
    : [];
  if (updates.length === 0) return keyResult.start;

  const sortedUpdates = produce(updates, (draft) => {
    draft.sort((a, b) => new Date(b.periodStart) - new Date(a.periodStart));
  });

  return sortedUpdates[0].status;
}
function getStatusUpdateForCurrentWeeklyJournal(keyResult, journalId) {
  if (keyResult.draftKeyResultStatusUpdates) {
    const currentJournalStatus = wrap(
      keyResult.draftKeyResultStatusUpdates,
    ).find((update) => update.weeklyReportId == journalId);
    if (currentJournalStatus) return currentJournalStatus;
  }

  if (keyResult.keyResultStatusUpdates) {
    const currentJournalStatus = wrap(keyResult.keyResultStatusUpdates).find(
      (update) => update.weeklyReportId == journalId,
    );
    if (currentJournalStatus) return currentJournalStatus;
  }

  return null;
}

function KeyResultStatusInput({ keyResult, journalId }) {
  const previousStatus = getPreviousKeyResultStatus(keyResult);

  const [currentStatusUpdate, setCurrentStatusUpdate] = React.useState(
    getStatusUpdateForCurrentWeeklyJournal(keyResult, journalId) || {},
  );

  const inputId = `kr-status-input:${keyResult.id}`;
  const [inputValue, setInputValue] = React.useState(
    currentStatusUpdate.status || previousStatus,
  );

  const isComplete = isKeyResultComplete(
    keyResult.decreasing,
    inputValue,
    keyResult.target,
  );

  React.useEffect(() => {
    if (currentStatusUpdate.status === inputValue) return;
    const previousInputValue = inputValue;

    async function validateKeyResultStatusUpdates() {
      const {
        data: [currentKeyResultStatusUpdate],
      } = await api.keyResultStatusUpdates.where({
        keyResultId: keyResult.id,
        weeklyReportId: journalId,
      });

      api.keyResultStatusUpdates
        .find(currentKeyResultStatusUpdate?.id)
        .upsert(
          { status: Number.parseFloat(inputValue) },
          {
            keyResultId: keyResult.id,
            weeklyReportId: journalId,
          },
        )
        .then((response) =>
          setCurrentStatusUpdate(
            denormalize(response, "keyResultStatusUpdates"),
          ),
        )
        .catch((error) => {
          console.error(error);
          alert("Something went wrong");
          setInputValue(previousInputValue);
        });
    }
    validateKeyResultStatusUpdates();
  }, [
    currentStatusUpdate.id,
    currentStatusUpdate.status,
    inputValue,
    journalId,
    keyResult.id,
  ]);

  const onChange = React.useCallback(
    (value) => {
      setInputValue(value);
    },
    [setInputValue],
  );

  const statusInputProps = {
    id: keyResult.id,
    inputId: inputId,
    inputValue: inputValue === "0.0" ? "0" : inputValue,
    isComplete,
    onChange,
    target: keyResult.target,
    previousStatus,
  };

  switch (keyResult.kind) {
    case BOOLEAN_KEY_RESULT:
      return <BooleanKeyResultStatusInput {...statusInputProps} />;
    case NUMERIC_KEY_RESULT:
      return <NumericInputKeyResultStatusInput {...statusInputProps} />;
    case PERCENTAGE_KEY_RESULT:
      return <PercentageKeyResultStatusInput {...statusInputProps} />;
    default:
      return null;
  }
}

export function CompleteIcon({ id }) {
  return (
    <CheckCircleOutline
      data-testid={`kr-complete-icon:${id}`}
      className="fill-current text-success-base"
    />
  );
}

const numberInputStyle = {
  width: "200px",
};

function formatDecimal(number) {
  const splitNumber = number.toString().split(".");
  return Number.parseInt(splitNumber[1]) === 0 ? splitNumber[0] : number;
}

function PercentageKeyResultStatusInput({
  id,
  inputValue,
  inputId,
  target,
  isComplete,
  onChange,
  previousStatus,
}) {
  function changeHandler(event) {
    const isValid = event.target.checkValidity();
    const { value } = event.target;
    if (!isValid) return;
    if (value.charAt(value.length - 1) === ".") onChange(value);
    else onChange(value === "" ? "0" : Number.parseFloat(value).toString());
  }
  return (
    <Stack space={2}>
      <Inline space={2}>
        <Frame className="relative" style={numberInputStyle}>
          <TextInput
            pattern="^\s*(?=.*\d)\d{0,13}(?:\.\d{0,2})?\s*$"
            className="text-right pr-16"
            data-testid={inputId}
            value={inputValue}
            onChange={changeHandler}
          />
          <Flex
            p={[0, 4]}
            className="absolute inset-y-0 right-0 border-0 border-solid border-l border-neutral-400 my-px"
            alignment={FlexAlignment.Center}
          >
            <Text>%</Text>
          </Flex>
        </Frame>
        {isComplete ? <CompleteIcon id={id} /> : null}
      </Inline>

      <BodySmall color={Color.Neutral600}>
        {`Previous: ${formatDecimal(previousStatus)}% | Target: ${formatDecimal(
          target,
        )}%`}
      </BodySmall>
    </Stack>
  );
}
function NumericInputKeyResultStatusInput({
  id,
  inputId,
  inputValue,
  target,
  isComplete,
  onChange,
  previousStatus,
}) {
  function changeHandler(event) {
    const isValid = event.target.checkValidity();
    const { value } = event.target;
    if (!isValid) return;
    if (value.charAt(value.length - 1) === ".") onChange(value);
    else onChange(value === "" ? "0" : Number.parseFloat(value).toString());
  }
  return (
    <Stack space={2}>
      <Inline space={2}>
        <Frame className="relative" style={numberInputStyle}>
          <TextInput
            pattern="^\s*(?=.*\d)\d{0,13}(?:\.\d{0,2})?\s*$"
            className="text-right"
            data-testid={inputId}
            value={inputValue}
            onChange={changeHandler}
          />
        </Frame>
        {isComplete && <CompleteIcon id={id} />}
      </Inline>
      <BodySmall color={Color.Neutral600}>
        Previous: {formatDecimal(previousStatus)} | Target:{" "}
        {formatDecimal(target)}
      </BodySmall>
    </Stack>
  );
}

function BooleanKeyResultStatusInput({ inputId, isComplete, id, onChange }) {
  function changeHandler(event) {
    const status = event.target.checked ? 1 : 0;
    onChange(status);
  }
  return (
    <Inline space={2}>
      <Checkbox
        id={inputId}
        padding={[3, 4, 3, 4]}
        checked={isComplete}
        data-testid={inputId}
        onChange={changeHandler}
        textComponent={
          <BodyBaseline color={Color.Neutral700}>Completed</BodyBaseline>
        }
      />
      {isComplete && <CompleteIcon id={id} />}
    </Inline>
  );
}
