// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Components
import Dashboard from "utilization/components/Dashboard";
import DefaultRedirect from "./DefaultRedirect";

class App extends PureComponent {
  static propTypes = {
    resourceNotFound: PropTypes.bool.isRequired,
    triggerDefaultRedirect: PropTypes.bool.isRequired,
  };

  render() {
    const { resourceNotFound, triggerDefaultRedirect } = this.props;

    if (triggerDefaultRedirect) {
      return <DefaultRedirect />;
    }

    if (resourceNotFound) {
      return <Error type="404" />;
    }

    return <Dashboard />;
  }
}

export default App;
