import React from "react";

import {
  Center,
  Color,
  Frame,
  Grid,
  Skeleton,
  Stack,
  Inline,
  Cell,
} from "@ableco/baseline";

function MessageContentFallBack() {
  return (
    <Center className="h-full">
      <Skeleton
        className="my-2"
        color={Color.Neutral300}
        width={150}
        height={10}
        animated={true}
      />
    </Center>
  );
}

function ListOfMessagesFallback() {
  return (
    <Stack>
      {[1, 2, 3, 4, 5, 6, 7].map((i) => (
        <Frame key={i} className="p-5 text-left border-b border-neutral-300">
          <Inline space={3}>
            <AvatarFallback />
            <Skeleton
              width={120}
              height={20}
              color={Color.Neutral300}
              animated={true}
            />
          </Inline>
        </Frame>
      ))}
    </Stack>
  );
}

function AvatarFallback() {
  return (
    <Skeleton color="neutral-300" corners="full" size={40} alt="User avatar" />
  );
}

export function FeedbackMessagesFallback() {
  return (
    <Frame className="pb-8">
      <Grid
        border={Color.Neutral300}
        className="divide-x divide-neutral-300 mt-10"
        col={12}
      >
        <Cell start={1} end={4}>
          <ListOfMessagesFallback />
        </Cell>
        <Cell start={4} end={13}>
          <MessageContentFallBack />
        </Cell>
      </Grid>
    </Frame>
  );
}
