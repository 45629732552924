import { Stack, Tooltip } from "@ableco/baseline";
import clsx from "clsx";
import React from "react";
import {
  BodySmall,
  BodySmallSemibold,
} from "./design-system/typography-components";

export function CoreTooltip({
  title,
  label,
  maxWidth = "320px",
  children,
  "data-testid": testid,
}) {
  const hasCustomContent = typeof label !== "string";
  return (
    <Tooltip
      bg="neutral-800"
      maxSize={{ maxWidth }}
      p={[1, 2]}
      label={
        <Stack
          data-testid={testid}
          className={clsx("relative", {
            "text-left": !!title && !hasCustomContent,
          })}
        >
          {hasCustomContent ? (
            label
          ) : (
            <>
              {title && (
                <BodySmallSemibold color="neutral-300">
                  {title}
                </BodySmallSemibold>
              )}
              <BodySmall color="neutral-300">{label}</BodySmall>
            </>
          )}
        </Stack>
      }
    >
      {children}
    </Tooltip>
  );
}
