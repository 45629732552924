import { Copy, Frown, Slack } from "@baseline/icons";
import { useCollection } from "coreql";
import React from "react";
import { FeedbackMessageCard } from "../../feedback/feedback-message-card";
import { wrap } from "../utils";
const {
  Stack,
  Text,
  TextSize,
  TextWeight,
  TextTransform,
  Color,
  TextLeading,
  TextTracking,
  Center,
  Button,
  Inline,
  Shadow,
  Touchable,
  Corners,
  TextWhitespace,
} = require("@ableco/baseline");
const { useCoreToast } = require("../../toast/toast");

export function useDismissedFeedbackRequest(
  receiverId,
  weeklyReportDateStart,
  weeklyReportDateEnd,
) {
  return useCollection(
    "feedback-requests",
    {
      filters: {
        receiverId: receiverId,
        feedbackDismissedSince: weeklyReportDateStart,
        feedbackDismissedUntil: weeklyReportDateEnd,
      },
      included: [
        "receiver",
        "receiver.role",
        "feedbackPrompt",
        "feedbackPrompt.responsibility",
        "sender",
        "author",
      ],
      fields: {
        feedbackRequests: [
          "id",
          "requestDate",
          "body",
          "receiver",
          "sender",
          "author",
          "feedbackPrompt",
          "dismissedAt",
        ],
        users: ["fullName", "avatarUrl", "role", "id", "preferredName"],
        feedbackPrompts: [
          "prompt",
          "cadence",
          "promptType",
          "scaleType",
          "responsibility",
        ],
        responsibilities: ["title"],
        roles: ["title"],
      },
    },
    "denormalized",
  );
}

export function useSentFeedbackMessages(
  weeklyReportId,
  weeklyReportSubmitted,
  weeklyReportDateEnd,
  weeklyReportOwner,
) {
  const filters = weeklyReportSubmitted
    ? {
        active: true,
        weeklyReport: weeklyReportId,
        draft: false,
      }
    : {
        active: true,
        weeklyReport: null,
        senderId: weeklyReportOwner.id,
        draft: false,
        submittedUntil: weeklyReportDateEnd,
      };

  return useCollection(
    "feedback-messages",
    {
      filters,
      included: [
        "sender",
        "receiver",
        "feedbackRequest",
        "feedbackRequest.author",
        "feedbackRequest.sender",
        "feedbackRequest.feedbackPrompt",
        "feedbackRequest.feedbackPrompt.responsibility",
        "senderOneOnOneItem",
      ],
      fields: {
        feedbackMessages: [
          "id",
          "body",
          "submittedAt",
          "promptAnswer",
          "feedbackRequest",
          "sender",
          "receiver",
          "liked",
          "senderOneOnOneItem",
          "coachingTip",
          "weeklyReportId",
        ],
        feedbackRequests: [
          "id",
          "requestDate",
          "body",
          "sender",
          "author",
          "feedbackPrompt",
        ],
        users: ["fullName", "avatarUrl", "id", "preferredName", "managerId"],
        feedbackPrompts: [
          "prompt",
          "cadence",
          "promptType",
          "scaleType",
          "responsibility",
        ],
        responsibilities: ["title"],
      },
    },
    "denormalized",
  );
}

function EmptyState({ forOwner, senderName }) {
  const slackCommand = "/core feedback @";
  const { successToast, errorToast } = useCoreToast();

  async function copySlackCommandToClipboard() {
    let permission;
    // Clipboard writting permissions is only supported by Chromium browsers
    if (window.chrome) {
      const result = await navigator.permissions.query({
        name: "clipboard-write",
      });
      permission = result.state == "granted" || result.state == "prompt";
    } else {
      permission = true;
    }

    if (!permission) return;

    try {
      await navigator.clipboard.writeText(slackCommand);
      successToast("Command copied to the clipboard!");
    } catch {
      errorToast("Could not copy to the clipboard!");
    }
  }
  return (
    <Center
      p={[10, 10]}
      bg={Color.Neutral200}
      corners={Corners.MediumRounded}
      className="w-full"
    >
      <Stack space={3}>
        <Center>
          <Text color={Color.Neutral500}>
            <Frown className="w-12 h-12" />
          </Text>
        </Center>

        {forOwner ? (
          <>
            <Text
              leading={TextLeading.Normal}
              color={Color.Neutral600}
              whitespace={TextWhitespace.PreWrap}
              className="text-center"
            >
              You didn't send any feedback this week.
              {"\n"}Send feedback in Slack by typing
            </Text>
            <Inline
              p={[2, 4]}
              corners={Corners.MediumRounded}
              bg={Color.White}
              border={Color.Neutral400}
              space={4}
            >
              <Text color={Color.Neutral700}>
                {slackCommand}
                {"[teammate]"}
              </Text>
              <Touchable
                onClick={copySlackCommandToClipboard}
                className="group"
              >
                <Inline space={1}>
                  <Text
                    color={Color.Primary}
                    className="group-hover:text-primary-light transition-all duration-300 ease-in-out"
                  >
                    <Copy className="w-6 h-6" />
                  </Text>
                  <Text
                    color={Color.Primary}
                    className="group-hover:text-primary-light transition-all duration-300 ease-in-out"
                  >
                    Copy
                  </Text>
                </Inline>
              </Touchable>
            </Inline>
            <Center>
              <Button
                color={[Color.Primary, Color.PrimaryLight]}
                onClick={() => window.location.assign("slack://open")}
                className="transition-all duration-300 ease-in-out"
                shadow={Shadow.None}
              >
                <Inline space={2}>
                  <Text color={Color.White}>
                    <Slack className="w-6 h-6" />
                  </Text>
                  <Text color={Color.White}>Go to Slack</Text>
                </Inline>
              </Button>
            </Center>
          </>
        ) : (
          <Text
            leading={TextLeading.Normal}
            color={Color.Neutral600}
            whitespace={TextWhitespace.PreWrap}
            className="text-center"
          >
            {senderName} didn't send any feedback this week.
            {"\n"}Remind your reports that it's essential to send and receive
            feedback regularly. Let's focus on doing well in things we excel at
            and take action in overcoming our challenges.
          </Text>
        )}
      </Stack>
    </Center>
  );
}

function SectionTitle({ forOwner, senderName }) {
  const title = forOwner ? "Feedback you sent" : `Feedback ${senderName} sent`;
  return (
    <Text
      size={TextSize.SM}
      weight={TextWeight.SemiBold}
      transform={TextTransform.Upper}
      color={Color.Neutral600}
      leading={TextLeading.Tight}
      tracking={TextTracking.Widest}
    >
      {title}
    </Text>
  );
}

function FeedbackSent({
  weeklyReportId,
  weeklyReportOwner,
  weeklyReportDateStart,
  weeklyReportDateEnd,
  weeklyReportSubmitted,
  currentUser,
}) {
  const { data: messages, mutate } = useSentFeedbackMessages(
    weeklyReportId,
    weeklyReportSubmitted,
    weeklyReportDateEnd,
    weeklyReportOwner,
  );

  const { data: dismissedRequests } = useDismissedFeedbackRequest(
    weeklyReportOwner.id,
    weeklyReportDateStart,
    weeklyReportDateEnd,
  );

  const currentUserIsWeeklyReportOwner = weeklyReportOwner.id == currentUser.id;
  const currentUserIsManager = weeklyReportOwner.managerId == currentUser.id;

  return (
    <Stack space={4}>
      <SectionTitle
        senderName={weeklyReportOwner.preferredName}
        forOwner={currentUserIsWeeklyReportOwner}
      />
      {wrap(messages).length > 0 || wrap(dismissedRequests).length > 0 ? (
        <Stack space={6}>
          {wrap(messages).map((message) => (
            <FeedbackMessageCard
              key={message.id}
              request={message.feedbackRequest}
              feedbackPrompt={message.feedbackRequest?.feedbackPrompt}
              headerUser={message.sender}
              response={message}
              oneOnOneItem={message.senderOneOnOneItem}
              isLikeable={message.receiver.id === currentUser.id}
              addToOneOnOneEnabled={currentUserIsManager}
              addToOneOnOneReceiver={weeklyReportOwner}
              currentUser={currentUser}
              refetch={mutate}
              displaySubmittedTime={true}
              showResponsibilityInfoTooltip={true}
              displayRequestBodyAuthorLabel={true}
              displayCoachingTip={true}
              addCoachingTipEnabled={currentUserIsManager}
            />
          ))}
          {wrap(dismissedRequests).map((request) => (
            <FeedbackMessageCard
              key={request.id}
              request={request}
              feedbackPrompt={request?.feedbackPrompt}
              headerUser={request.receiver}
              displayRole={true}
              response={{}}
              isLikeable={false}
              isReviewable={false}
              showResponsibilityInfoTooltip={true}
              displayRequestBodyAuthorLabel={true}
              currentUser={currentUser}
            />
          ))}
        </Stack>
      ) : (
        <EmptyState
          senderName={weeklyReportOwner.preferredName}
          forOwner={currentUserIsWeeklyReportOwner}
        />
      )}
    </Stack>
  );
}

export default FeedbackSent;
