import React from "react";
import { useCollection, denormalize } from "coreql";
import { reduce } from "lodash";

import { wrap } from "../weekly-report/utils";

import * as Dropdown from "../dropdown";

import { toUSD } from "../../utils/currency";

import { Lock, CaretDownOutline } from "@baseline/icons";
import StackedBarChart from "./stacked-bar-chart";

import {
  Frame,
  Flex,
  Text,
  TextSize,
  TextTransform,
  TextLeading,
  Color,
  TextWeight,
  TextTracking,
  Corners,
  Touchable,
  Stack,
  Skeleton,
} from "@ableco/baseline";

function Stat({ description, amount, textColorClass = "text-neutral-700" }) {
  const moneyTextColorClass = amount != 0 ? textColorClass : "text-neutral-700";
  const formattedAmount = amount !== 0 ? toUSD(Math.abs(amount)) : "N/A";
  return (
    <Stack
      as="li"
      alignment="center"
      distribution="center"
      className="flex-grow border-r border-neutral-400 last:border-0 w-1/3"
    >
      <Text
        as="p"
        size={TextSize.SM}
        leading={TextLeading.Normal}
        tracking={TextTracking.Normal}
        color={Color.Neutral700}
      >
        Estimated
      </Text>
      <Text
        as="p"
        size={TextSize.SM}
        leading={TextLeading.Normal}
        tracking={TextTracking.Normal}
        color={Color.Neutral700}
      >
        ANNUAL {description}
      </Text>
      <Text
        as="p"
        size={TextSize.XL}
        leading={TextLeading.Snug}
        weight={TextWeight.Bold}
        className={`mt-2 ${moneyTextColorClass}`}
      >
        {formattedAmount}
      </Text>
    </Stack>
  );
}

const currentDateYear = new Date().getFullYear().toString();
const initialMonthObject = reduce(
  [...Array.from({ length: 13 }).keys()].slice(1),
  (acc, month) => ({ ...acc, [month]: 0 }),
  {},
);

const initialProductAnnualMetrics = {
  costByMonth: initialMonthObject,
  revenueByMonth: initialMonthObject,
  totalEstimates: { revenue: 0, cost: 0 },
};

function reduceProducts(products, metrics) {
  return reduce(
    products,
    (accumulator, product) => {
      if (!metrics[product.id]) {
        return accumulator;
      }

      return [
        ...accumulator,
        { name: product.name, metrics: metrics[product.id] },
      ];
    },
    [],
  ).sort((a, b) => a.name.localeCompare(b.name));
}

function addMetricAmount(accumulator, item, type) {
  const { productId, month, year } = item;
  const metricAmount = item[type];
  const productMetrics = accumulator[productId] ? accumulator[productId] : {};
  const productAnnualMetrics = productMetrics[year]
    ? accumulator[productId][year]
    : initialProductAnnualMetrics;

  return {
    ...accumulator,
    [productId]: {
      ...productMetrics,
      [year]: {
        ...productAnnualMetrics,
        totalEstimates: {
          ...productAnnualMetrics.totalEstimates,
          [type]: productAnnualMetrics.totalEstimates[type] + metricAmount,
        },
        [`${type}ByMonth`]: {
          ...productAnnualMetrics[`${type}ByMonth`],
          [month]: productAnnualMetrics[`${type}ByMonth`][month] + metricAmount,
        },
      },
    },
  };
}

function reduceMetrics(metrics) {
  return reduce(
    metrics,
    (accumulator, item) => {
      if (item.type === "productRevenues") {
        return addMetricAmount(accumulator, item, "revenue");
      }
      if (item.type === "productEstimatedWeeklyCosts") {
        return addMetricAmount(accumulator, item, "cost");
      }
      return accumulator;
    },
    {},
  );
}

function useData() {
  const { data: dataProducts } = useCollection("products");
  const products = wrap(denormalize(dataProducts, "products"));

  const { data: dataProductEstimate } = useCollection(
    "product-estimated-weekly-costs",
  );
  const productEstimatedWeeklyCosts = wrap(
    denormalize(dataProductEstimate, "productEstimatedWeeklyCosts"),
  );

  const { data: dataProductRevenues } = useCollection("product-revenues");
  const productRevenues = wrap(
    denormalize(dataProductRevenues, "productRevenues"),
  );

  return reduceProducts(
    products,
    reduceMetrics([...productEstimatedWeeklyCosts, ...productRevenues]),
  );
}

function validateCurrentYear(years, currentYear) {
  return years.includes(currentYear.toString()) ? currentYear : years.pop();
}

function ProductProfitability({ initialCurrentDateYear = currentDateYear }) {
  const products = useData();

  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  const [currentProduct, setCurrentProduct] = React.useState(products[0]);

  const updateYear = React.useCallback((product) => {
    const currentMetricYears = Object.keys(product.metrics);
    setCurrentYear((currentValue) =>
      currentMetricYears.includes(currentValue)
        ? currentValue
        : currentMetricYears[currentMetricYears.length - 1],
    );
  }, []);

  const handleProductClick = React.useCallback(
    (index) => () => {
      setCurrentProduct(products[index]);
      updateYear(products[index]);
      setDropdownVisible(false);
    },
    [products, updateYear],
  );

  const [currentYear, setCurrentYear] = React.useState(() =>
    validateCurrentYear(
      Object.keys(currentProduct.metrics),
      initialCurrentDateYear,
    ),
  );

  const { revenue: totalRevenue, cost: totalCost } =
    currentProduct.metrics[currentYear].totalEstimates;
  const totalIncome = totalRevenue - totalCost;

  return (
    <Frame>
      <Flex direction="horizontal" alignment="center" className="full mb-4">
        <Text
          as="h4"
          leading={TextLeading.Normal}
          transform={TextTransform.Upper}
          size={TextSize.XS}
          color={Color.Neutral600}
          weight={TextWeight.Bold}
        >
          Product Profitability
        </Text>
        <Lock width="18" height="18" className="text-neutral-600 ml-1" />
      </Flex>
      <Flex
        border={Color.Neutral400}
        alignment="stretch"
        bg={Color.White}
        corners={Corners.MediumRounded}
      >
        <Frame className="max-h-full relative overflow-x-hidden overflow-y-auto w-56 border-r border-neutral-400">
          <Flex
            as="ul"
            direction="vertical"
            alignment="stretch"
            className="absolute w-full cursor-pointer"
          >
            {products.map((product, index) => (
              <Frame
                key={product.name}
                p={4}
                as="li"
                onClick={handleProductClick(index)}
                bg={
                  currentProduct.name === product.name
                    ? Color.Neutral200
                    : Color.White
                }
                className="border-b border-neutral-400 last:border-0 hover:bg-primary-lighter transition-all duration-300 ease-in-out"
              >
                <Text
                  color={Color.Neutral700}
                  size={TextSize.Base}
                  leading={TextLeading.Relaxed}
                  weight={
                    currentProduct.name === product.name
                      ? TextWeight.SemiBold
                      : TextWeight.Normal
                  }
                >
                  {product.name}
                </Text>
              </Frame>
            ))}
          </Flex>
        </Frame>
        <Flex direction="vertical" alignment="stretch" className="w-full">
          <Frame className="border-b border-neutral-400 pt-4 pb-3 pl-5">
            <Text
              as="h3"
              weight={TextWeight.Bold}
              color={Color.Neutral800}
              size={TextSize.XL}
              leading={TextLeading.Snug}
            >
              {currentProduct.name}
            </Text>
            <Frame>
              <Touchable
                color={Color.Neutral700}
                size={TextSize.SM}
                leading={TextLeading.Normal}
                className="mt-1"
                onClick={() => {
                  setDropdownVisible(!dropdownVisible);
                }}
              >
                All projects for{" "}
                <Text
                  weight={TextWeight.Bold}
                  color={dropdownVisible ? Color.PrimaryLight : Color.Primary}
                  size={TextSize.SM}
                  leading={TextLeading.Normal}
                  className="inline-flex items-center hover:text-primary-light transition-all duration-300 ease-in-out"
                  data-testid="current_year_selected"
                >
                  {currentYear}{" "}
                  <CaretDownOutline
                    style={{ margin: "-1px 0 0 3px" }}
                    width="13"
                    height="13"
                  />
                </Text>
              </Touchable>
            </Frame>
            <Dropdown.List className="absolute" isVisible={dropdownVisible}>
              {Object.keys(currentProduct.metrics).map((year) => (
                <Dropdown.Item
                  key={`year-${year}`}
                  className={year === currentYear ? "bg-neutral-200" : ""}
                  onClick={() => {
                    setCurrentYear(year);
                    setDropdownVisible(false);
                  }}
                >
                  <Dropdown.Button
                    weight={
                      year === currentYear
                        ? TextWeight.SemiBold
                        : TextWeight.Normal
                    }
                  >
                    {year}
                  </Dropdown.Button>
                </Dropdown.Item>
              ))}
            </Dropdown.List>
          </Frame>
          <Frame className="py-6">
            <StackedBarChart metrics={currentProduct.metrics[currentYear]} />
          </Frame>
          <Flex
            as="ul"
            direction="horizontal"
            alignment="stretch"
            className="h-32 border-t border-neutral-400"
          >
            <Stat key="revenue" description="REVENUE" amount={totalRevenue} />
            <Stat key="cost" description="COST" amount={totalCost} />
            <Stat
              key=""
              description={totalIncome >= 0 ? "PROFIT" : "LOSS"}
              amount={totalIncome}
              textColorClass={
                totalIncome > 0 ? "text-success-base" : "text-alert-base"
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </Frame>
  );
}

export function Fallback() {
  return (
    <Frame>
      <Skeleton
        animated
        width={173}
        height={18}
        color="neutral-300"
        alt="Title section"
        className="mb-4"
      />
      <Skeleton
        animated
        width={800}
        height={561}
        color="neutral-300"
        alt="Panel section"
        className="mb-4"
      >
        <Flex alignment="stretch">
          <Flex direction="vertical" alignment="stretch">
            {[...Array.from({ length: 9 }).keys()].map((index) => (
              <Frame p={4} key={index}>
                <Skeleton
                  animated
                  width={144}
                  height={26}
                  color="neutral-100"
                  alt="Product section"
                />
              </Frame>
            ))}
          </Flex>
          <Flex direction="vertical" alignment="stretch">
            <Frame className="pt-4 pb-3 pl-5">
              <Skeleton
                animated
                width={200}
                height={54}
                color="neutral-100"
                alt="Current Options Section"
              />
            </Frame>
            <Frame className="py-6 pl-5">
              <Skeleton
                animated
                width={590}
                height={300}
                color="neutral-100"
                alt="Chart Section"
              />
            </Frame>
            <Flex direction="horizontal" alignment="stretch" className="h-32">
              {[...Array.from({ length: 3 }).keys()].map((index) => (
                <Frame p={4} key={index}>
                  <Skeleton
                    animated
                    width={175}
                    height={80}
                    color="neutral-100"
                    alt="Product section"
                  />
                </Frame>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Skeleton>
    </Frame>
  );
}

export default ProductProfitability;
