import React from "react";

import { Color, Frame, Stack, Skeleton } from "@ableco/baseline";
import { range } from "lodash";

export function WeeklyQuestionFallback() {
  return (
    <Stack>
      <Skeleton
        width={270}
        height={24}
        color={Color.Neutral300}
        animated={true}
        className="mb-8"
      />
      <Skeleton
        width={270}
        height={24}
        color={Color.Neutral300}
        animated={true}
        className="mb-2"
      />
      <Skeleton
        width={240}
        height={48}
        color={Color.Neutral300}
        animated={true}
        className="mb-4"
      />
      <Skeleton
        width="auto"
        height={138}
        color={Color.Neutral300}
        animated={true}
      />
    </Stack>
  );
}

export function WeeklyQuestionsFallback() {
  return (
    <Frame>
      {range(12).map((index) => (
        <Stack key={index}>
          <Skeleton
            width={270}
            height={24}
            color={Color.Neutral300}
            animated={true}
            className="mb-6"
          />
          <Skeleton
            width="auto"
            height={24}
            color={Color.Neutral300}
            animated={true}
            className="ml-6 mb-8"
          />
        </Stack>
      ))}
    </Frame>
  );
}
