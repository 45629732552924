import React from "react";
import { last, uniqueId, findIndex, matches, isEmpty } from "lodash";
import { api, cache, useCollection } from "coreql";
import Hint from "../hint";
import TaskGroup from "./task-group";
import OneOnOneFormFooter from "./one-on-one-form-footer";
import { create } from "../../services/storage";
import Note from "./notes";
import SectionHeader from "./section-header";
import { Inline, Stack } from "@ableco/baseline";
import { Lock, Users } from "@baseline/icons";
import { NavItem } from "../tab";
import { BodySmall } from "../design-system/typography-components";
import { useLocation } from "react-router";
import { useCoreToast } from "../toast/toast";
import { AvatarXS } from "../design-system/avatar";

function itemIsNotNew(item) {
  return !item.id.startsWith("newtask:");
}

function getNewItemOrder(list, startIndex, endIndex) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return [result, removed];
}

function SharedNotes(props) {
  return (
    <Note placeholder="Shared notes for this 1-on-1" kind="note" {...props} />
  );
}

function PrivateNotes(props) {
  return (
    <Note
      placeholder="Private notes for this 1-on-1"
      kind="private_note"
      {...props}
    />
  );
}

function OneOnOneForm({ currentUser, requestedUser, cacheKeys = new Set() }) {
  const requesterId = currentUser.id;
  const requestedId = requestedUser.id;

  const oneOnOneScope = [requesterId, requestedId]
    .sort((a, b) => a - b)
    .join("-");

  const { data: initialPrivateNotes, mutate: privateNotesMutate } =
    useCollection(
      "one-on-one-items",
      {
        filters: {
          requester: requesterId,
          requested: requestedId,
          kind: "private_note",
          ongoing: true,
        },
      },
      "denormalized",
    );

  const { data: initialNotes, mutate: notesMutate } = useCollection(
    "one-on-one-items",
    {
      filters: {
        requester: requesterId,
        requested: requestedId,
        kind: "note",
        ongoing: true,
      },
    },
    "denormalized",
  );

  const { data: initialTalkingPoints, mutate: talkingPointsMutate } =
    useCollection(
      "one-on-one-items",
      {
        filters: {
          oneOnOneScope: oneOnOneScope,
          kind: "talking_point",
          ongoing: true,
        },
        sort: ["position"],
        options: {
          onSuccess: (_, key) => cacheKeys.add(key),
        },
      },
      "denormalized",
    );

  const { data: initialActionItems, mutate: actionItemsMutate } = useCollection(
    "one-on-one-items",
    {
      filters: {
        oneOnOneScope: oneOnOneScope,
        kind: "action_item",
        ongoing: true,
      },
      sort: ["position"],
      included: ["assignee"],
      options: {
        onSuccess: (_, key) => cacheKeys.add(key),
      },
    },
    "denormalized",
  );

  function refreshCacheAfterEnd() {
    talkingPointsMutate();
    actionItemsMutate();
    notesMutate();
    privateNotesMutate();
  }

  const [talkingPoints, setTalkingPoints] = React.useState(
    initialTalkingPoints || [],
  );
  const [actionItems, setActionItems] = React.useState(
    initialActionItems || [],
  );

  const [savedAt, setUpdatedAt] = React.useState(null);

  const [hintIsVisible, setHintIsVisible] = React.useState(
    !(talkingPoints.length > 0 || actionItems.length > 0),
  );

  /* eslint-disable-next-line arrow-body-style */
  React.useEffect(() => {
    return () => {
      for (const key of cacheKeys) cache.delete(key);
    };
  }, []);

  const { errorToast } = useCoreToast();
  function onUpdate() {
    setUpdatedAt(new Date());
  }

  function updateItemState(setItems, id, newData) {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, ...newData } : item,
      ),
    );
    onUpdate();
  }

  function deleteItemFromStack(setItems, id) {
    setItems((previousItems) => previousItems.filter((item) => item.id !== id));
  }

  async function itemDelete(setItems, id) {
    deleteItemFromStack(setItems, id);
    onUpdate();
    if (id.startsWith("newtask:")) return;

    await api.oneOnOneItems.find(id).destroy();
  }

  function upsertItemInCacheRequest(mutate, id, newData) {
    mutate(async (cachedRequest) => {
      if (id.includes("newtask:")) {
        return [...cachedRequest, newData];
      } else {
        const index = findIndex(cachedRequest, ["id", id]);
        const item = cachedRequest[index];
        Object.assign(item, newData);

        return [
          ...cachedRequest.slice(0, index),
          item,
          ...cachedRequest.slice(index + 1),
        ];
      }
    }, true);
  }

  function deleteItemInCacheRequest(mutate, id) {
    mutate(
      async (cachedRequest) => cachedRequest.filter((item) => item.id !== id),
      true,
    );
  }

  function updateCacheRequest(mutate, newState) {
    mutate(
      newState.filter((item) => !item.id.includes("newtask:")),
      true,
    );
  }

  function buildHandlers(state, setState, mutate) {
    return {
      addItem(id, newData) {
        upsertItemInCacheRequest(mutate, id, newData);
        setState((prevState) => {
          const unsavedItem = last(prevState);
          return [...prevState.slice(0, -1), newData, unsavedItem];
        });
        onUpdate();
      },
      deleteFromStack(id) {
        deleteItemFromStack(setState, id);
        deleteItemInCacheRequest(mutate, id);
      },
      update(id, newData) {
        updateItemState(setState, id, newData);
        upsertItemInCacheRequest(mutate, id, newData);
      },
      updateOrder({ destination, id }) {
        setState((prevState) => {
          const itemIndex = prevState.findIndex(matches({ id }));
          const [newState] = getNewItemOrder(prevState, itemIndex, destination);

          updateCacheRequest(mutate, newState);
          return newState;
        });
      },
      async delete(id) {
        await itemDelete(setState, id);
        deleteItemInCacheRequest(mutate, id);
      },
      handleDragEnd(setDragDisableFunction) {
        return async function onDragEnd(result) {
          if (!result.destination) {
            return;
          }

          if (result.destination.index === result.source.index) {
            return;
          }

          const [newState, item] = getNewItemOrder(
            state,
            result.source.index,
            result.destination.index,
          );

          setState(newState);
          updateCacheRequest(mutate, newState);
          setDragDisableFunction(true);
          try {
            await updateItemOrder(item, result.destination.index + 1);
          } catch {
            errorToast(
              "We couldn't reorder the elements at the moment. Contact the team.",
            );
            setState(state);
            updateCacheRequest(mutate, state);
          }
          setDragDisableFunction(false);
        };
      },
    };
  }

  const {
    update: updateTalkingPointState,
    deleteFromStack: handleTalkingPointDeleteFromStack,
    delete: handleTalkingPointDelete,
    handleDragEnd: handleTalkingPointDrag,
    ...talkingPointHandlers
  } = buildHandlers(talkingPoints, setTalkingPoints, talkingPointsMutate);

  const {
    update: updateActionItemState,
    deleteFromStack: handleActionItemDeleteFromStack,
    delete: handleActionItemDelete,
    handleDragEnd: handleActionItemDrag,
    ...actionItemHandlers
  } = buildHandlers(actionItems, setActionItems, actionItemsMutate);

  function hideHint() {
    setHintIsVisible(false);
    create("1-1:hint-state", false);
  }

  async function updateItemOrder({ id }, newPosition) {
    await api.oneOnOneItems.find(id).update({ position: newPosition });
    onUpdate();
  }

  const submitEnabled = React.useMemo(
    () =>
      talkingPoints.filter(itemIsNotNew).length > 0 ||
      actionItems.filter(itemIsNotNew).length > 0 ||
      (initialNotes.length > 0 && initialNotes[0].text) ||
      (initialPrivateNotes.length > 0 && initialPrivateNotes[0].text),
    [talkingPoints, actionItems, initialNotes, initialPrivateNotes],
  );

  React.useEffect(() => {
    function addExtraItem(items) {
      const lastItem = last(items);
      if (isEmpty(items) || lastItem?.text !== "") {
        return [...items, { id: uniqueId("newtask:"), text: "" }];
      }
      return items;
    }

    setTalkingPoints(addExtraItem);
    setActionItems(addExtraItem);
  }, [talkingPoints, actionItems]);

  const [TAB_SHARED_NOTES, TAB_PRIVATE_NOTES] = [
    "shared_notes",
    "private_notes",
  ];

  const { state = { currentTab: TAB_SHARED_NOTES } } = useLocation();

  return (
    <Stack className="max-w-5xl pb-16">
      {hintIsVisible && (
        <Hint
          text={`This is your 1-on-1 with ${requestedUser.preferredName}, to start just add talking points or action items.`}
          action="Got it!"
          onAction={hideHint}
        />
      )}
      <Stack space={6}>
        <Stack space={4}>
          <SectionHeader title={"Talking Points"} />
          <TaskGroup
            id="talking-points"
            label="Talking Point"
            placeholder="Add a Talking Point"
            kind="talking_point"
            items={talkingPoints}
            requestedUser={requestedUser}
            currentUser={currentUser}
            onUpdate={updateTalkingPointState}
            onDelete={handleTalkingPointDelete}
            deleteItemFromInternalState={handleTalkingPointDeleteFromStack}
            buildOnDragEnd={handleTalkingPointDrag}
            handlers={{
              ...talkingPointHandlers,
              update: updateTalkingPointState,
              delete: handleTalkingPointDeleteFromStack,
            }}
          />
        </Stack>
        <Stack space={4}>
          <SectionHeader title={"Action Items"} />
          <TaskGroup
            id="action-items"
            label="Action Item"
            placeholder="Add an Action Item"
            kind="action_item"
            items={actionItems}
            requestedUser={requestedUser}
            currentUser={currentUser}
            onUpdate={updateActionItemState}
            onDelete={handleActionItemDelete}
            deleteItemFromInternalState={handleActionItemDeleteFromStack}
            buildOnDragEnd={handleActionItemDrag}
            handlers={{
              ...actionItemHandlers,
              update: updateActionItemState,
              delete: handleActionItemDeleteFromStack,
            }}
          />
        </Stack>
        <Stack space={4}>
          <SectionHeader title="Notes" />
          <Stack border="neutral-400" corners="medium">
            <Inline as="ul" p={[0, 2]} className="border-b border-neutral-400">
              <NavItem
                icon={<Users />}
                label="Shared notes"
                isActive={state.currentTab === TAB_SHARED_NOTES}
                href={{ state: { currentTab: TAB_SHARED_NOTES } }}
              />
              <NavItem
                icon={<Lock />}
                isActive={state.currentTab === TAB_PRIVATE_NOTES}
                label="Private notes"
                href={{ state: { currentTab: TAB_PRIVATE_NOTES } }}
              />
            </Inline>
            <Stack p={[0, 4, 0, 0]}>
              {state.currentTab === TAB_SHARED_NOTES ? (
                <SharedNotes
                  data={initialNotes[0]}
                  onUpdate={onUpdate}
                  requestedId={requestedId}
                  requesterId={requesterId}
                  mutate={notesMutate}
                />
              ) : (
                <PrivateNotes
                  data={initialPrivateNotes[0]}
                  onUpdate={onUpdate}
                  requestedId={requestedId}
                  requesterId={requesterId}
                  mutate={privateNotesMutate}
                />
              )}
            </Stack>
            <Inline p={[0, 6]} space={1} className="h-6 mb-6">
              {state.currentTab === TAB_SHARED_NOTES && (
                <AvatarXS
                  url={requestedUser.avatarUrl}
                  name={requestedUser.fullName}
                  userId={requestedUser.fullName}
                />
              )}
              <BodySmall color="neutral-600">
                {state.currentTab === TAB_SHARED_NOTES
                  ? `${requestedUser.preferredName} can see these notes`
                  : `These notes are only seen by you`}
              </BodySmall>
            </Inline>
          </Stack>
        </Stack>
      </Stack>
      <OneOnOneFormFooter
        savedAt={savedAt}
        disabled={!submitEnabled}
        requestedId={requestedId}
        requesterId={requesterId}
        refreshCacheAfterEnd={refreshCacheAfterEnd}
      />
    </Stack>
  );
}

export default OneOnOneForm;
