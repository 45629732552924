import { Frame, Inline, Skeleton, Stack, Text } from "@ableco/baseline";
import { SSRSuspense } from "coreql";
import React from "react";
import useCurrentUser from "../../../hooks/use-current-user";
import { Main } from "../../layout/layout";
import UserLayout, {
  currentPathMatches,
  Fallback as UserLayoutFallback,
} from "../../layout/user-layout";
import { User, Users } from "@baseline/icons";
import { NavItem as Tab } from "../../tab.js";

function getNavItems() {
  const items = [];

  items.push(
    {
      href: `/weekly_reports/my_journal`,
      label: " My Journals",
      isActive: (location) =>
        currentPathMatches(location, { path: "/weekly_reports/my_journal" }),
      prefetch: false,
      icon: <User className="w-4 h-4" />,
    },
    {
      href: `/weekly_reports/teams_journal`,
      label: "Team Journals",
      isActive: (location) =>
        currentPathMatches(location, { path: "/weekly_reports/teams_journal" }),
      prefetch: false,
      icon: <Users className="w-4 h-4" />,
    },
  );

  return items;
}

export function WeeklyReportsLayout({ children }) {
  const { data: currentUser } = useCurrentUser();
  const navItems = getNavItems();
  return (
    <Main>
      <SSRSuspense
        fallback={
          <>
            <UserLayoutFallback />
            <Frame className="mx-32">
              <Skeleton
                height={32}
                width={200}
                color="neutral-400"
                className="mb-2"
                alt="Weekly Journals"
              />
              <Frame className="border-b border-neutral-300	">
                <Inline as="nav" alignment="start" className="mt-10">
                  {[...Array.from({ length: 2 })].map((_, i) => (
                    <Skeleton
                      key={i}
                      height={24}
                      width={120}
                      className="mx-4 mb-2"
                      alt="Journals Link"
                      color="neutral-400"
                    />
                  ))}
                </Inline>
              </Frame>
            </Frame>
          </>
        }
      >
        <UserLayout id={currentUser.id}>
          <Stack className="mx-32">
            <Text as="h4" size="2xl" weight="semibold">
              Weekly Journals
            </Text>
            {currentUser.isManager && (
              <Inline
                as="nav"
                alignment="start"
                className="w-full border-b border-neutral-300 mt-6"
              >
                {navItems.map((item) => (
                  <Tab key={item.href} {...item} />
                ))}
              </Inline>
            )}
            {children}
          </Stack>
        </UserLayout>
      </SSRSuspense>
    </Main>
  );
}
