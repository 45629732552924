import React from "react";
import { Inline, Stack, Text } from "@ableco/baseline";
import { AvatarMedium } from "../design-system/avatar";

export default function HighlightUserLayout({ user, date }) {
  return (
    <Inline alignment="start" className="mb-4" space={4}>
      <AvatarMedium
        url={user.avatarUrl}
        name={user.fullName}
        userId={user.id}
      />
      <Stack>
        <Text leading="snug" color="neutral-800" weight="semibold">
          {user.fullName}
        </Text>
        <Inline style={{ opacity: 0.45 }}>
          <Text color="black" size="sm" leading="normal">
            {user.senderRole}
            {" - "}
            {new Date(date).toLocaleDateString("en-US")}
          </Text>
        </Inline>
      </Stack>
    </Inline>
  );
}
