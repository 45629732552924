import React from "react";
import { isEmpty } from "lodash";

import Accordion from "../../accordion";
import LevelBadgesTable from "./level-badges-table";

export default function GeneralManagerBadges({
  userId,
  managerBadgeStatus,
  managerBadges,
  isDirectReport,
  isDirectReportOrSelf,
}) {
  if (isEmpty(managerBadges)) return null;

  return (
    <Accordion state="none" variant="small" title="Manager Badges">
      <LevelBadgesTable
        badges={managerBadges}
        badgeAssessments={managerBadgeStatus}
        userId={userId}
        isDirectReport={isDirectReport}
        isDirectReportOrSelf={isDirectReportOrSelf}
      />
    </Accordion>
  );
}
