import React from "react";
import useUniqueId from "../hooks/use-unique-id";
import {
  Color,
  Corners,
  Shadow,
  TextInput as BaselineTextInput,
} from "@ableco/baseline";
import ResizeObserver from "resize-observer-polyfill";
import { noop } from "lodash";

export function TextArea(props) {
  return <TextInput type="multiline" rows={null} autosize={88} {...props} />;
}

function TextInput({
  labelledby,
  autofocus,
  value = "",
  placeholder = "",
  onFocus = noop,
  onChange = noop,
  onBlur = noop,
  onRef = noop,
  className = "",
  readOnly = false,
  disabled = false,
  style = {},
  rows = 1,
  autosize = false,
  dataTestId,
  label,
  ...props
}) {
  const $textarea = React.useRef(null);
  const [innerValue, setInnerValue] = React.useState(value);

  /* suspense autosize fix */
  React.useEffect(() => {
    if (props.type !== "multiline") return;
    const editor = $textarea.current;

    const editorObserver = new ResizeObserver(([entry], observer) => {
      const { height: boxContentHeight } = entry.contentRect;
      const boundingRect = editor.getBoundingClientRect();

      if (boxContentHeight > 0 && editor.scrollHeight > boundingRect.height) {
        editor.style.height = editor.scrollHeight + "px";
        observer.unobserve(editor);
      }
    });

    editorObserver.observe($textarea.current);
    return () => editorObserver.disconnect();
  }, [props.type]);

  React.useEffect(() => {
    onRef($textarea);
  }, [onRef, $textarea.current]);

  React.useEffect(() => {
    if (value !== innerValue) {
      setInnerValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(event) {
    setInnerValue(event.target.value);
    onChange(event);
  }

  const domId = useUniqueId("textInput:");

  return (
    <BaselineTextInput
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autofocus}
      id={domId}
      name={props.name}
      innerRef={$textarea}
      rows={rows}
      aria-label={label}
      aria-labelledby={labelledby}
      placeholder={placeholder}
      placeholderColor={Color.Neutral600}
      corners={Corners.MediumRounded}
      bg={disabled ? Color.Neutral200 : Color.White}
      text={disabled ? Color.Neutral500 : Color.Neutral700}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={innerValue}
      readOnly={readOnly}
      style={style}
      spellCheck="true"
      data-testid={props["data-testid"] || `textarea:${dataTestId}`}
      disabled={disabled}
      border={Color.Neutral400}
      p={[2, 4]}
      shadow={Shadow.None}
      className={`w-full box-border resize-none outline-none focus:outline-none focus:shadow-outline focus:border-primary-base ${className} transition duration-300 ease-in-out pointer-events-auto disabled:cursor-not-allowed`}
      autosize={autosize}
      {...props}
    />
  );
}

export default TextInput;
