import { mutate, trigger } from "swr";
import { network } from "./network";
import camelCase from "camelcase";
import { paramCase } from "param-case";
import buildRequest from "../utils/build-request";

function formatIncludes(includes) {
  return includes.map((name) => camelCase(name)).toString();
}

function formatFilters(filters) {
  return Object.entries(filters)
    .map(([name, value]) => `filter[${name}]=${value}`)
    .join("&");
}

export default new Proxy(
  {},
  {
    get(_, resourceName) {
      const resource = paramCase(resourceName);

      async function findAll(includes = []) {
        if (includes.length > 0) {
          const results = await network(
            `${resource}?include=${formatIncludes(includes)}`,
          );
          hydrate(results, { includes });
          return results;
        }
        const results = await network(resource);
        hydrate(results, { includes });
        return results;
      }

      async function where(filters, includes = []) {
        const filterQuery = formatFilters(filters);

        if (includes.length > 0) {
          const results = await network(
            `${resource}?${filterQuery}&include=${formatIncludes(includes)}`,
          );
          hydrate(results, { includes, filters });
          return results;
        }

        const results = await network(`${resource}?${filterQuery}`);
        hydrate(results, { includes, filters });
        return results;
      }

      async function findBy(filterName, value, includes = []) {
        return where({ [filterName]: value }, includes);
      }

      function hydrate(
        data,
        { includes = [], filters } = {},
        shouldRevalidate = true,
      ) {
        if (includes.length === 0 && !filters) {
          return mutate(resource, data, shouldRevalidate);
        }

        const query = [];

        if (includes.length > 0) {
          query.push(`include=${formatIncludes(includes)}`);
        }

        if (filters) {
          query.push(formatFilters(filters));
        }

        return mutate(`${resource}?${query.join("&")}`, data, shouldRevalidate);
      }

      async function create(attributes, relationships = []) {
        const results = await network(resource, {
          method: "POST",
          body: JSON.stringify(
            buildRequest(resource, attributes, { relationships }),
          ),
        });
        find(results.data.id).hydrate(results); // hydrate individual resource
        return results;
      }

      function find(id) {
        async function read(includes = []) {
          if (includes.length > 0) {
            const results = await network(
              `${resource}/${id}?include=${formatIncludes(includes)}`,
            );
            return results;
          }

          const results = await network(`${resource}/${id}`);
          hydrate(results, includes);
          return results;
        }

        function hydrate(data, includes = [], shouldRevalidate = true) {
          if (includes.length > 0) {
            mutate(
              `${resource}/${id}?include=${formatIncludes(includes)}`,
              data,
              shouldRevalidate,
            );
          } else {
            mutate(`${resource}/${id}`, data, shouldRevalidate);
          }
        }

        async function update(attributes) {
          const results = await network(`${resource}/${id}`, {
            method: "PATCH",
            body: JSON.stringify({
              data: { type: resource, id, attributes },
            }),
          });
          hydrate(results);
          return results;
        }

        update.relation = async function updateRelation(name, data) {
          const results = await network(
            `${resource}/${id}/relationships/${paramCase(name)}`,
            {
              method: "PATCH",
              body: JSON.stringify({ data }),
            },
          );
          trigger(`${resource}/${id}`);
          return results;
        };

        async function destroy() {
          await network(`${resource}/${id}`, { method: "DELETE" });
          hydrate({}); // empty data
        }

        return { read, update, destroy, hydrate };
      }

      return { find, findAll, findBy, where, create, hydrate };
    },
  },
);
