/**
 * @param {boolean} decreasing
 * @param {(string|number)} status
 * @param {(string|number)} target
 */
function isKeyResultComplete(decreasing, status, target) {
  return decreasing
    ? Number.parseFloat(status) <= Number.parseFloat(target)
    : Number.parseFloat(status) >= Number.parseFloat(target);
}

export default isKeyResultComplete;
