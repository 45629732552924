import React, { Suspense } from "react";
import styled from "styled-components";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { subDays, addDays } from "date-fns";
import { v4 as uuid } from "uuid";
import api from "../../services/api";
import AllocationsGrid from "./allocations-grid/allocations-grid";
import DateField from "./date-field";
import CoreLink from "../core-link";
import { PrimaryButton, TertiaryButton } from "../buttons/buttons";

const TOTAL_HOURS = 40;

const FormContainer = styled.form`
  margin-top: 20px;
`;

const Paragraph = styled.label`
  color: #8b98ae;
  font-family: "ProximaNova Semibold", sans-serif;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
`;

const FormSection = styled.section`
  margin-top: 20px;
`;

const DateContainer = styled.div`
  display: flex;
`;

const FormSectionButtons = styled(FormSection)`
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  & > * {
    margin-left: 20px;
  }
`;

const AllocationSchema = yup.object().shape({
  type: yup.string().required(),
  attributes: yup.object().shape({
    estimateStartedAt: yup
      .date()
      .required("Select a start date")
      .max(yup.ref("estimateEndedAt"), "Must be before end date"),
    estimateEndedAt: yup
      .date()
      .required("Select an end date")
      .min(yup.ref("estimateStartedAt"), "Must be before start date"),
    staff: yup
      .array()
      .min(1)
      .of(
        yup.object().shape({
          projectRoleId: yup.string().required("Select a role"),
          userId: yup.string(),
          weeks: yup
            .array()
            .compact((week) => week.hours === 0)
            .min(1, "At least one week with more than 0 hours"),
        }),
      ),
  }),
});

function updateProject(values) {
  const { id, attributes } = values;
  const { estimateStartedAt, estimateEndedAt } = attributes;

  return api.projects.find(id).update({
    discoveryStart: estimateStartedAt,
    developmentEnd: estimateEndedAt,
  });
}

function deleteAssignments(staff, assignmentIds) {
  const actualAssignments = _.reduce(
    staff,
    (acc, member) => {
      // get assignments with a valid id and hours greater than 0
      const currentIds = member.weeks
        .filter((week) => week.id && week.hours > 0)
        .map((week) => week.id);
      acc.push(...currentIds);
      return acc;
    },
    [],
  );

  const deleteAssignmentsIds = assignmentIds.filter(
    (assignmentId) => !actualAssignments.includes(assignmentId),
  );

  return Promise.all(
    deleteAssignmentsIds.map((assignmentId) =>
      api.productAssignments.find(assignmentId).destroy(),
    ),
  );
}

function deleteAssignmentsNotes(rowIds) {
  const response = rowIds.map((id) =>
    api.assignmentsNotes.where({ rowId: id }),
  );

  return Promise.all(response).then((values) =>
    values.map((el) =>
      el.data.map((note) => api.assignmentsNotes.find(note.id).destroy()),
    ),
  );
}

function isAssignmentUpdate(allocation, member, assignment) {
  const originalMember = allocation.attributes.staff.find((member) =>
    member.weeks.some((week) => week.id == assignment.id),
  );

  if (!originalMember) return false;

  const originalAssignment = originalMember.weeks.find(
    (week) => week.id == assignment.id,
  );

  return (
    member.userId != originalMember.userId ||
    member.projectRoleId != originalMember.projectRoleId ||
    assignment.hours != originalAssignment.hours
  );
}

async function updateProjectEstimations(values, assignmentIds, allocation) {
  const { id: projectId, attributes } = values;
  const { staff, productId } = attributes;

  // delete  productAssignments
  await deleteAssignments(staff, assignmentIds);

  const promiseArray = [];

  for (const member of staff) {
    const validWeeks = member.weeks.filter((week) => week.hours !== 0);
    const rowId = member.rowId || uuid();

    const assigneeType = member.userId ? "User" : null;
    const assigneeId = member.userId || null;

    for (const week of validWeeks) {
      const assignment = {
        periodStart: week.date,
        timeAllocation: (week.hours * 100) / TOTAL_HOURS,
        projectRoleId: member.projectRoleId,
        assigneeId,
        assigneeType,
        projectId,
        productId,
        rowId,
      };

      if (!week.id) {
        promiseArray.push(api.productAssignments.create(assignment));
      } else if (
        isAssignmentUpdate(allocation, member, { ...assignment, id: week.id })
      ) {
        promiseArray.push(
          api.productAssignments.find(week.id).update(assignment),
        );
      }
    }
  }

  await Promise.all(promiseArray);
}

async function handleSubmit(
  values,
  setSubmitting,
  assignmentIds,
  allocation,
  deletedRows,
) {
  try {
    await updateProject(values);
    await updateProjectEstimations(values, assignmentIds, allocation);
    await deleteAssignmentsNotes(deletedRows);
    window.location.href = "/staffing";
  } catch {
    alert("Ops! There was an error");
  } finally {
    setSubmitting(false);
  }
}

// Memoizing the buttons to avoid having to click them twice.
// Otherwise the inputs' onBlur handlers trigger a validation and a form rerender
// which eliminate the original button and the first click event is lost.
// https://github.com/jaredpalmer/formik/issues/1332#issuecomment-463938746
// https://github.com/facebook/react/issues/4210
const SaveButton = React.memo(({ isValid, isSubmitting }) => (
  <PrimaryButton text="Update Estimate" disabled={isSubmitting || !isValid} />
));

// Memoizing the buttons to avoid having to click them twice.
// Otherwise the inputs' onBlur handlers trigger a validation and a form rerender
// which eliminate the original button and the first click event is lost.
// https://github.com/jaredpalmer/formik/issues/1332#issuecomment-463938746
// https://github.com/facebook/react/issues/4210
const CancelButton = React.memo(() => <TertiaryButton text="Cancel" />);

function EditProjectEstimations({ allocation, assignmentIds }) {
  const [deletedRows, setDeletedRows] = React.useState([]);

  function handleDeletedRow(rowId) {
    if (rowId) setDeletedRows([...deletedRows, rowId]);
  }

  return (
    <Formik
      initialValues={allocation}
      validationSchema={AllocationSchema}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(
          values,
          setSubmitting,
          assignmentIds,
          allocation,
          deletedRows,
        )
      }
    >
      {(properties) => {
        const {
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
          values,
          isValid,
          errors,
          touched,
        } = properties;

        return (
          <FormContainer onSubmit={handleSubmit}>
            <FormSection>
              <Paragraph>Project start and end date:</Paragraph>
              <DateContainer>
                <Field
                  maxDate={subDays(values.attributes.estimateEndedAt, 1)}
                  component={DateField}
                  name="attributes.estimateStartedAt"
                  placeholder="Select project start date"
                />
                <Field
                  minDate={addDays(values.attributes.estimateStartedAt, 7)}
                  component={DateField}
                  name="attributes.estimateEndedAt"
                  placeholder="Select project end date"
                />
              </DateContainer>
            </FormSection>
            <FormSection>
              <Suspense fallback={null}>
                <AllocationsGrid
                  start={values.attributes.estimateStartedAt}
                  end={values.attributes.estimateEndedAt}
                  staff={values.attributes.staff}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onDeletedRow={handleDeletedRow}
                  inEditMode={true}
                  type={"estimates"}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                />
              </Suspense>
            </FormSection>
            <FormSectionButtons>
              <CoreLink href="/staffing" className="hover:no-underline">
                <CancelButton />
              </CoreLink>
              <SaveButton isValid={isValid} isSubmitting={isSubmitting} />
            </FormSectionButtons>
          </FormContainer>
        );
      }}
    </Formik>
  );
}

export default EditProjectEstimations;
