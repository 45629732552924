// Modules
import { parseISO } from "date-fns";
import { isEmpty, isMatch, keys } from "lodash";
import queryString from "query-string";
// Constants
import { LOCATION_CHANGE } from "connected-react-router";

const REQUIRED_PARAMS = [
  "detailBy",
  "endDate",
  "groupBy",
  "periodIncrement",
  "startDate",
];

const initFromSearchParams = () => {
  const searchParams = queryString.parse(location.search);

  return {
    valid: isMatch(keys(searchParams), REQUIRED_PARAMS),
    ...formatedSearchParams(searchParams),
  };
};

const handleLocationChange = (state, action) => {
  const searchParams = queryString.parse(action.payload.location.search);

  return {
    valid: isMatch(keys(searchParams), REQUIRED_PARAMS),
    ...formatedSearchParams(searchParams),
  };
};

const handlers = {
  [LOCATION_CHANGE]: handleLocationChange,
};

export default (state = {}, action) => {
  const handler = handlers[action.type];
  if (handler) {
    state = handler(state, action);
  } else if (isEmpty(state)) {
    state = initFromSearchParams();
  }

  return state;
};

const formatedSearchParams = (searchParams) => {
  let { endDate, startDate, ...rest } = searchParams;

  endDate = parseISO(endDate);
  startDate = parseISO(startDate);

  return {
    endDate,
    startDate,
    ...rest,
  };
};
