import React from "react";
import {
  AccordionContent,
  AccordionGroup,
  AccordionTitle,
  Frame,
  Inline,
  Text,
} from "@ableco/baseline";
import { CheckBold, CloseBold, Lightbulb } from "@baseline/icons";
import CaretToggle from "../../components/caret-toggle";
import Drawer from "../../components/drawer";

function H3(props) {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <AccordionTitle
      p={[2, 0]}
      className="border-0 focus:outline-none"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {({ isOpen }) => (
        <Inline space={2}>
          <CaretToggle isOpen={isOpen} isHovered={isHovered} />
          <Text as="h3" leading="normal" weight="semibold" {...props} />
        </Inline>
      )}
    </AccordionTitle>
  );
}

function OL(props) {
  return (
    <Frame as="ol" p={[0, 0, 4, 6]}>
      <Text {...props} />
    </Frame>
  );
}

function UL(props) {
  return <Text as="ul" {...props} />;
}

function LI(props) {
  return (
    <Inline as="li" alignment="start" p={[2, 0]}>
      <Frame p={[1, 0, 0, 0]}></Frame>
      <Text color="neutral-800" leading="normal" {...props}></Text>
    </Inline>
  );
}

function LiCheck(props) {
  return (
    <Inline as="li" alignment="start" p={[2, 0]} space={2}>
      <Frame p={[1, 0, 0, 0]}>
        <CheckBold className="w-4 h-4 flex-none text-success-base" />
      </Frame>
      <Text color="neutral-800" leading="normal" {...props}></Text>
    </Inline>
  );
}

function LiClose(props) {
  return (
    <Inline as="li" alignment="start" p={[2, 0]} space={2}>
      <Frame p={[1, 0, 0, 0]}>
        <CloseBold className="w-4 h-4 flex-none text-alert-base" />
      </Frame>
      <Text color="neutral-800" leading="normal" {...props}></Text>
    </Inline>
  );
}

function LiDash(props) {
  return (
    <Inline as="li" alignment="start" p={[2, 0]} space={2}>
      <Frame p={[1, 0, 0, 0]}>
        <Text>-</Text>
      </Frame>
      <Text color="neutral-800" leading="normal" {...props}></Text>
    </Inline>
  );
}
export function FeedbackTipsDrawer({ onClose, ...props }) {
  return (
    <Drawer
      title="Feedback"
      subtitle="Tips and Best Practices"
      onClose={onClose}
      icon={<Lightbulb color="white" className="w-6 h-6" />}
      {...props}
    >
      <ReflectionTips />
    </Drawer>
  );
}

export default function ReflectionTips() {
  return (
    <>
      <AccordionGroup>
        <H3>Why is Feedback Important?</H3>
        <AccordionContent>
          <OL>
            <LI>
              We believe in a continuous feedback model that:
              <UL>
                <LiDash>Drives change and fuels growth</LiDash>
                <LiDash>Improves working relationships</LiDash>
                <LiDash>Gives us a sense of purpose</LiDash>
                <LiDash>Improves engagement</LiDash>
              </UL>
            </LI>
            <LI>
              The continuous feedback model allows us to collect, analyze, and
              synthesize feedback on an ongoing basis. This model gets us on the
              path to:
              <UL>
                <LiDash>Celebrate successes and wins in a timely manner</LiDash>
                <LiDash>
                  Address and follow up on constructive feedback immediately
                </LiDash>
                <LiDash>
                  Reduce the barrier to providing feedback through timely and
                  meaningful prompts based on each individual’s role and goals
                </LiDash>
                <LiDash>
                  Provide “feedback on the feedback” to improve the quality of
                  the feedback we’re providing each other with coaching tips
                </LiDash>
              </UL>
            </LI>
            <LI>
              By sharing and addressing feedback continuously, we build a
              feedback-rich culture, where everyone is comfortable asking for
              and receiving feedback from their colleagues and managers.
            </LI>
          </OL>
        </AccordionContent>
      </AccordionGroup>

      <AccordionGroup>
        <H3>Giving Feedback</H3>
        <AccordionContent>
          <OL>
            <LiCheck>
              Give feedback shortly after you observe the behavior, preferably
              during the same day
            </LiCheck>
            <LiCheck>
              Focus on the behavior and observable actions, not the person or
              their personality; examples of behaviors include: words you say,
              how you say them, facial expressions, body language, work products
              (presentation, code, design artifacts, pull requests)
            </LiCheck>
            <LiCheck>
              Be specific, include facts, evidence, and examples. Check your
              feedback against the Situation, Behavior, Impact (SBI) model: Did
              I describe the situation (what and when)? Did I mention observable
              behavior? Did I explain the impact on me, or my team, or our
              company?
            </LiCheck>
            <LiCheck>
              Check for unconscious biases by asking yourself these questions:
              <UL>
                <LiDash>
                  Would I give the same feedback to someone of a different
                  gender (or race, etc)?
                </LiDash>
                <LiDash>
                  Am I asking someone to be something they are not?
                </LiDash>
                <LiDash>
                  Have I made a statement about who someone is or who they tend
                  to be?
                </LiDash>
                <LiDash>What feedback should I be giving others, too?</LiDash>
              </UL>
            </LiCheck>
            <LiClose>
              Don’t only send constructive feedback; everyone needs positive and
              constructive feedback
            </LiClose>
            <LiClose>
              Don’t wait to send feedback as you may forget the details
            </LiClose>
            <LiClose>
              Don’t wait for the same behavior to occur multiple times, share
              feedback after the first time you have noticed the behavior or an
              opportunity for improvement
            </LiClose>
            <LiClose>
              Don’t send feedback when you are feeling angry or frustrated
            </LiClose>
            <LiClose>
              Don’t stockpile criticisms as it may overwhelm the person or
              result in defensiveness
            </LiClose>
            <LiClose>
              Don’t inflate your feedback by presenting it in a more positive
              light, this will not lead to an improvement and growth
            </LiClose>
            <LiClose>
              Don’t generalize by starting your feedback with you always, you
              tend to
            </LiClose>
            <LiClose>
              Don’t try to provide coaching through the feedback unless the
              feedback is for your direct report, let the managers coach
            </LiClose>
          </OL>
        </AccordionContent>
      </AccordionGroup>

      <AccordionGroup>
        <H3>Requesting Feedback</H3>
        <AccordionContent>
          <OL>
            <LiCheck>
              Be specific about the type of feedback you are looking for
            </LiCheck>
            <LiCheck>
              Consider capturing an event or a behavior or a work product that
              you are looking for feedback
            </LiCheck>
            <LiCheck>
              Consider using the SBI model for requesting feedback: Did I
              describe the situation (what and when)? Did I mention observable
              behavior or skill? Did I explain the impact I hoped to have on my
              team or our company?{" "}
            </LiCheck>
            <LiClose>
              Don’t justify a particular behavior with your own biases
            </LiClose>
            <LiClose>Don’t wait too long to ask for the feedback</LiClose>
          </OL>
        </AccordionContent>
      </AccordionGroup>
    </>
  );
}
