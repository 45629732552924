import React from "react";
import { Provider } from "react-redux";
import BudgetContainer from "./AppContainer";
import store from "budget/store";
import PageLayout from "../../../components/layout/page-layout";
import { Frame } from "@ableco/baseline";

export default BudgetContainer;

export function BudgetPage() {
  return (
    <PageLayout title="Budget">
      <Provider store={store}>
        <Frame style={{ maxWidth: "1040px" }}>
          <BudgetContainer />;
        </Frame>
      </Provider>
    </PageLayout>
  );
}
