import { useRef } from "react";
import { useAsyncQueue } from "./use-autosave-text-input";

/**
 * Returns a wrapped version of the passed function that
 * runs inline, only running the next call after the
 * previous ends. Will always run the latest callback
 * passed.
 *
 * Useful for upserts.
 * @param {T} callback
 * @returns {T}
 * @template {function} T
 */
export function useQueuedCallback(callback) {
  const { addToQueue } = useAsyncQueue();
  const callbackRef = useRef(callback);

  callbackRef.current = callback;

  return (...params) =>
    addToQueue({ params: [], callback: () => callbackRef.current(...params) });
}
