import React from "react";
import {
  Cell,
  Frame,
  Grid,
  Image,
  Inline,
  Skeleton,
  Stack,
  Text,
} from "@ableco/baseline";
import { ErrorBoundary, SSRSuspense, useResource } from "coreql";
import { useParams } from "react-router";
import CoreLink from "../core-link";
import { Main } from "../layout/layout";
import UserLayout, {
  Fallback as UserLayoutFallback,
  useUserProfile,
} from "../layout/user-layout";
import UserInfo from "./user-info";
import { castArray } from "lodash";

function ProductsView({ title, products }) {
  return (
    <Stack as="section">
      <Text
        as="h4"
        className="px-6"
        leading="normal"
        tracking="wider"
        size="sm"
        weight="semibold"
        color="neutral-600"
        transform="upper"
      >
        {title}
      </Text>
      <Grid as="ul" col={2}>
        {products.map((product) => (
          <Product key={product.id} {...product} />
        ))}
      </Grid>
    </Stack>
  );
}

function Product({ tagline, name, logoUrl, id }) {
  return (
    <Frame as="li" key={id} p={[0, 6]} className="pt-4">
      <CoreLink className="hover:no-underline" href={`/products/${id}`}>
        <Inline as="figure" space={4}>
          <Frame corners="medium" className="overflow-hidden">
            <Image
              alt={tagline ?? name}
              width="32"
              height="32"
              source={logoUrl}
            />
          </Frame>
          <Text as="figcaption" color={["neutral-800", "primary-light"]}>
            {name}
          </Text>
        </Inline>
      </CoreLink>
    </Frame>
  );
}

function UserDetailsFallback() {
  return (
    <>
      <UserLayoutFallback />
      <Stack className="px-8 max-w-4xl w-full" space={10}>
        <Stack space={3}>
          <Skeleton color="neutral-400" width={140} height={21} alt="Period" />
          <Grid col={2} border="neutral-300" corners="medium">
            {[...Array.from({ length: 4 })].map((_, i) => (
              <Cell key={i} p={[8, 6]}>
                <Skeleton
                  color="neutral-400"
                  width={200}
                  height={32}
                  alt="User information"
                  animated
                />
              </Cell>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
}

function UserDetails() {
  const userProfile = useUserProfile();
  const userInformation = useResource(
    "users",
    userProfile.id,
    {
      included: ["location", "currentProducts", "pastProducts"],
      fields: {
        users: ["location", "currentProducts", "pastProducts"],
      },
    },
    "denormalized",
  ).data;

  const { email, slackName, birthday, joinDate } = userProfile;
  const { location } = userInformation;
  const [currentProducts, pastProducts] = [
    userInformation.currentProducts,
    userInformation.pastProducts,
  ].map(castArray);

  const hasRecentProjects = currentProducts.length > 0;
  const hasPastProjects = pastProducts.length > 0;
  return (
    <Stack className="px-8 max-w-4xl w-full" space={10}>
      <Stack as="section" space={2}>
        <Text
          as="h3"
          size="lg"
          leading="normal"
          weight="bold"
          color="neutral-800"
        >
          Member Information
        </Text>
        <Grid
          as="ul"
          col={2}
          p={[2, 0, 4, 0]}
          border="neutral-300"
          corners="medium"
        >
          <UserInfo.Email email={email} />
          <UserInfo.Slack name={slackName} />
          <UserInfo.Location location={location} />
          <UserInfo.Birthday date={birthday} />
          <UserInfo.JoinDate date={joinDate} />
        </Grid>
      </Stack>
      {(hasRecentProjects || hasPastProjects) && (
        <Stack as="section" space={2}>
          <Text
            as="h3"
            size="lg"
            leading="normal"
            weight="bold"
            color="neutral-800"
          >
            Projects Information
          </Text>

          <Stack space={8} border="neutral-300" corners="medium" p={[6, 0]}>
            {hasRecentProjects && (
              <ProductsView
                title="Active Projects"
                products={currentProducts}
              />
            )}
            {hasPastProjects && (
              <ProductsView title="Past Projects" products={pastProducts} />
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default function UserInformation() {
  const { id: userId } = useParams();
  return (
    <Main>
      <ErrorBoundary fallback={UserDetailsFallback}>
        <SSRSuspense fallback={<UserDetailsFallback />}>
          <UserLayout id={userId}>
            <UserDetails />
          </UserLayout>
        </SSRSuspense>
      </ErrorBoundary>
    </Main>
  );
}
