import React from "react";
import UserLayout, {
  Fallback as UserLayoutFallback,
} from "../layout/user-layout";
import { Main } from "../layout/layout";
import { SSRSuspense, ErrorBoundary } from "coreql";
import { Grid, Cell, Frame, Inline, Skeleton } from "@ableco/baseline";
import HighlightGrid, {
  Fallback as HighlightsFallback,
  HighlightFilters,
} from "./highlights";
import Reflections, { Fallback as ReflectionsFallback } from "./reflections";
import ErrorFallback from "../error-fallback";
import { useParams } from "react-router";

export default function HighlightsPage() {
  const { id } = useParams();
  return (
    <Main bg="neutral-200" className="h-full">
      <SSRSuspense
        fallback={
          <Frame p={4}>
            <UserLayoutFallback />
            <Grid col={12} gap={6} p={[0, 5]}>
              <Cell start={1} end={8}>
                <Inline alignment="baseline" distribution="between">
                  <Skeleton
                    width={169}
                    height={27}
                    color="neutral-600"
                    alt="Cheers & Highlights"
                    className="mb-4"
                  />
                  <Skeleton
                    width={57}
                    height={24}
                    color="neutral-600"
                    alt="Filters"
                    className="mb-4"
                  />
                </Inline>
                <HighlightsFallback />
              </Cell>
              <Cell start={8} end={13}>
                <ReflectionsFallback />
              </Cell>
            </Grid>
          </Frame>
        }
      >
        <UserLayout id={id}>
          <Grid col={12} gap={6} p={[0, 5]}>
            <Cell start={1} end={8}>
              <ErrorBoundary fallback={ErrorFallback}>
                <HighlightFilters id={id}>
                  <SSRSuspense fallback={<HighlightsFallback />}>
                    <HighlightGrid />
                  </SSRSuspense>
                </HighlightFilters>
              </ErrorBoundary>
            </Cell>
            <Cell start={8} end={13}>
              <ErrorBoundary fallback={ErrorFallback}>
                <SSRSuspense fallback={<ReflectionsFallback />}>
                  <Reflections />
                </SSRSuspense>
              </ErrorBoundary>
            </Cell>
          </Grid>
        </UserLayout>
      </SSRSuspense>
    </Main>
  );
}
