import { api } from "coreql";

export function updateMessageReadByManager(feedbackMessageId) {
  return api.feedbackMessages
    .find(feedbackMessageId)
    .update({ readByManager: new Date() });
}

export function updateMessageReadByReceiver(feedbackMessageId) {
  return api.feedbackMessages
    .find(feedbackMessageId)
    .update({ readByReceiver: new Date() });
}

export function isMessageUnreadByCurrentUser(
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
  readByManager,
  readByReceiver,
) {
  return (
    (isCurrentUserDirectManager && !readByManager) ||
    (isCurrentUserInboxOwner && !readByReceiver)
  );
}
