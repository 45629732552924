import React from "react";
import { format, parseISO } from "date-fns";
import { useCollection } from "coreql";
import {
  Color,
  Corners,
  Inline,
  FlexDistribution,
  FlexAlignment,
  Frame,
  Shadow,
  Skeleton,
  Stack,
} from "@ableco/baseline";
import { CaretRightOutline, BoxChecked, TalkBubble } from "@baseline/icons";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import PageLayout from "../layout/page-layout";
import {
  TitleSemibold,
  BodyBaseline,
  BodySmall,
} from "../design-system/typography-components";
import { PrimaryButton } from "../buttons/buttons";
import UserLayout, {
  Fallback as UserLayoutFallback,
} from "../layout/user-layout";
import useCurrentUser from "../../hooks/use-current-user";
import CoreLink from "../core-link";

export default function OneOnOneHistory() {
  const { user1, user2 } = useParams();

  const requesterId = useCurrentUser().data.id;

  const requestedId = requesterId == user1 ? user2 : user1;

  return (
    <UserLayout id={requestedId}>
      <OneOnOneHistoryBody />
    </UserLayout>
  );
}

export function OneOnOneHistoryBody() {
  let history = useHistory();
  const { user1, user2 } = useParams();

  const oneOnOneScope = [user1, user2].sort((a, b) => a - b).join("-");
  const { data: currentOneOnOneItems } = useCollection(
    "one-on-one-items",
    {
      fields: {
        oneOnOneItems: ["kind", "text"],
      },
      filters: {
        oneOnOneScope: oneOnOneScope,
        ongoing: true,
      },
      sort: ["position"],
    },
    "denormalized",
  );

  const currentTalkingPoints = currentOneOnOneItems.filter(
    (oneOnOneItem) => oneOnOneItem.kind === "talking_point",
  );
  const currentActionItems = currentOneOnOneItems.filter(
    (oneOnOneItem) => oneOnOneItem.kind === "action_item",
  );

  const { data: pastOneOnOnes } = useCollection(
    "past-one-on-ones",
    {
      fields: {
        pastOneOnOnes: ["endedOn"],
      },
      filters: {
        participants: [user1, user2],
      },
      sort: ["DESC endedOn"],
    },
    "denormalized",
  );

  return (
    <PageLayout title="1-on-1">
      <Frame p={[0, 10]} className="mx-auto w-full" style={{ maxWidth: 1040 }}>
        <Frame
          as="section"
          corners={Corners.MediumRounded}
          border={Color.Neutral400}
          shadow={Shadow.Base}
          className="mt-4"
        >
          <Inline
            distribution={FlexDistribution.Between}
            alignment={FlexAlignment.Center}
            p={[5, 6]}
            className="border-neutral-400 border-b"
          >
            <TitleSemibold>Current 1-on-1</TitleSemibold>
            <PrimaryButton
              onClick={() => {
                history.push(`/1-on-1/${user1}/${user2}`);
              }}
              text="View 1-on-1"
            />
          </Inline>

          <Inline p={[4, 6]} space={6} alignment={FlexAlignment.Stretch}>
            <OneOnOneItems
              items={currentTalkingPoints}
              typeText="talking points"
              icon={<TalkBubble className="text-neutral-600 h-5" />}
            />

            <OneOnOneItems
              items={currentActionItems}
              typeText="action items"
              icon={<BoxChecked className="text-neutral-600 h-5" />}
            />
          </Inline>
        </Frame>

        {pastOneOnOnes.length > 0 && (
          <Frame
            as="section"
            corners={Corners.MediumRounded}
            border={Color.Neutral400}
            className="mt-6"
          >
            <Frame p={[5, 6]} className="border-neutral-400 border-b">
              <TitleSemibold>Past 1-on-1s</TitleSemibold>
            </Frame>
            {pastOneOnOnes.map((pastOneOnOne, index) => (
              <Inline
                key={index}
                as={CoreLink}
                href={`/1-on-1/${pastOneOnOne.id}`}
                distribution={FlexDistribution.Between}
                alignment={FlexAlignment.Center}
                className={`transition-colors duration-300 ease-in-out w-full text-left hover:bg-neutral-200 hover:no-underline border-neutral-400 border-b last:border-0 last:rounded-b`}
                p={[4, 6]}
              >
                <BodyBaseline data-testid="previous-one-on-one">
                  {format(parseISO(pastOneOnOne.endedOn), "MMM d, yyyy")}
                </BodyBaseline>
                <CaretRightOutline className="h-5 w-5" />
              </Inline>
            ))}
          </Frame>
        )}
      </Frame>
    </PageLayout>
  );
}

export function OneOnOneHistoryFallback() {
  return (
    <>
      <UserLayoutFallback />
      <Stack
        p={[0, 10]}
        space={6}
        className="mx-auto"
        style={{ maxWidth: 1040 }}
      >
        <Skeleton
          animated
          color={Color.Neutral200}
          corners={Corners.MediumRounded}
          height="auto"
          p={6}
          width="100%"
        >
          <TitleSemibold as="h3" className="mb-6" color={Color.Neutral800}>
            Current 1-on-1
          </TitleSemibold>
          <Inline space={6}>
            <Frame style={{ width: "50%" }}>
              {[...Array.from({ length: 4 })].map((_, index) => (
                <Skeleton
                  className="mb-2"
                  color={Color.Neutral400}
                  corners={Corners.SmallRounded}
                  height={20}
                  key={index}
                  width="100%"
                />
              ))}
            </Frame>

            <Frame style={{ width: "50%" }}>
              {[...Array.from({ length: 4 })].map((_, index) => (
                <Skeleton
                  className="mb-2"
                  color={Color.Neutral400}
                  corners={Corners.SmallRounded}
                  height={20}
                  key={index}
                  width="100%"
                />
              ))}
            </Frame>
          </Inline>
        </Skeleton>

        <Skeleton
          animated
          color={Color.Neutral200}
          corners={Corners.MediumRounded}
          height="auto"
          p={6}
          width="100%"
        >
          <TitleSemibold as="h3" className="mb-6" color={Color.Neutral800}>
            Past 1-on-1s
          </TitleSemibold>
          {[...Array.from({ length: 12 })].map((_, index) => (
            <Skeleton
              className="mb-2"
              color={Color.Neutral400}
              corners={Corners.SmallRounded}
              height={40}
              key={index}
              width="100%"
            />
          ))}
        </Skeleton>
      </Stack>
    </>
  );
}

function OneOnOneItems({ items, typeText, icon }) {
  return (
    <Frame
      className="flex-1 overflow-hidden relative"
      style={{ maxHeight: 136 }}
    >
      <Frame
        style={{
          content: "",
          width: "100%",
          height: "100%",
          position: "absolute",
          background: "linear-gradient(transparent 112px, white 100%)",
        }}
      />
      <Inline space={1} className="mb-4">
        {icon}
        <BodyBaseline color={Color.Neutral800}>
          {items.length} {typeText}
        </BodyBaseline>
      </Inline>
      <Frame as="ul" className="pl-6">
        {items.map((item) => (
          <BodySmall
            as="li"
            key={item.id}
            className="list-disc mb-2 last:mb-0"
            color={Color.Neutral600}
            data-testid={typeText}
          >
            {item.text}
          </BodySmall>
        ))}
      </Frame>
    </Frame>
  );
}
