// Modules
import { snakeCase } from "lodash";
// Constants
import ActionTypes from "./../constants/ActionTypes";

// A Redux middleware that spawns entity-related update actions following
// API-related actions.
export default (store) => (next) => (action) => {
  if (!action.type.startsWith("API")) {
    return next(action);
  }

  // Dispatch the API action first.
  next(action);

  const entitiesKey = snakeCase(action.responseResourceType).toUpperCase();
  const entitiesRequestType = ActionTypes.ENTITIES[`${entitiesKey}_REQUEST`];
  const entitiesSuccessType = ActionTypes.ENTITIES[`${entitiesKey}_SUCCESS`];
  const entitiesFailureType = ActionTypes.ENTITIES[`${entitiesKey}_FAILURE`];

  if (action.type.endsWith("_REQUEST")) {
    return next({
      type: entitiesRequestType,
      apiTransaction: action.apiTransaction,
      triggeringResourceType: action.responseResourceType,
      ...store.getState(),
    });
  } else if (action.type.endsWith("_SUCCESS")) {
    return next({
      type: entitiesSuccessType,
      apiTransaction: action.apiTransaction,
      triggeringResourceType: action.responseResourceType,
      ...store.getState(),
    });
  } else if (action.type.endsWith("_FAILURE")) {
    return next({
      type: entitiesFailureType,
      apiTransaction: action.apiTransaction,
      triggeringResourceType: action.responseResourceType,
      ...store.getState(),
    });
  } else {
    return next(action);
  }
};
