// Modules
import React from "react";
// Components
import Rows from "utilization/components/Rows";
// Styles
import Styles from "utilization/components/Table/Table.module.scss";
import { LabelText } from "../LabelRows/LabelText";

function SummaryLabelRows({ products }) {
  return (
    <>
      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Summary</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Revenue</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Estimated</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Actual</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Estimated & Actual</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Headcount</LabelText>
        </div>
      </div>
      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Total</LabelText>
        </div>
      </div>
      <Rows.Labels filter="headcount.budgeted" products={products} />
      <Rows.Labels filter="headcount.estimated" products={products} />
      <Rows.Labels filter="headcount.actual" products={products} />

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Total & Budgeted</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Total & Estimated</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Total & Actual</LabelText>
        </div>
      </div>

      <Rows.Labels filter="headcount.dev_bud_est" products={products} />
      <Rows.Labels filter="headcount.dev_bud_act" products={products} />
      <Rows.Labels filter="headcount.dev_est_act" products={products} />

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Hours</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Total</LabelText>
        </div>
      </div>

      <Rows.Labels filter="hours.budgeted" products={products} />
      <Rows.Labels filter="hours.estimated" products={products} />
      <Rows.Labels filter="hours.actual" products={products} />

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Total & Budgeted</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Total & Estimated</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Total & Actual</LabelText>
        </div>
      </div>

      <Rows.Labels filter="hours.dev_bud_est" products={products} />
      <Rows.Labels filter="hours.dev_bud_act" products={products} />
      <Rows.Labels filter="hours.dev_est_act" products={products} />

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Labor Cost</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Total</LabelText>
        </div>
      </div>

      <Rows.Labels filter="cost.budgeted" products={products} />
      <Rows.Labels filter="cost.estimated" products={products} />
      <Rows.Labels filter="cost.actual" products={products} />

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Total & Budgeted</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Total & Estimated</LabelText>
        </div>
      </div>

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Δ Total & Actual</LabelText>
        </div>
      </div>

      <Rows.Labels filter="cost.dev_bud_est" products={products} />
      <Rows.Labels filter="cost.dev_bud_act" products={products} />
      <Rows.Labels filter="cost.dev_est_act" products={products} />

      <div className={Styles.row}>
        <div className={Styles.spacer} />
        <div className={Styles.spacer} />
        <div className={Styles.column}>
          <LabelText>Gross Margin</LabelText>
        </div>
      </div>

      <Rows.Labels filter="gross.estimated" products={products} />
      <Rows.Labels filter="gross.actual" products={products} />
      <Rows.Labels filter="gross.dev_est_act" products={products} />
    </>
  );
}

export default SummaryLabelRows;
