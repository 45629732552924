import React, { useState } from "react";
import { useCollection } from "coreql";
import {
  Color,
  FlexAlignment,
  FlexDistribution,
  Frame,
  Inline,
  Stack,
  Text,
} from "@ableco/baseline";
import RenameOneOnOne from "../../../components/weekly-report/rename-one-on-one";
import { wrap } from "../utils";
import {
  filterAnswersByCriteria,
  canAddOneOnOne,
  addOneOnOneItem,
  getPrivateCommentReceiverName,
} from "./utils";
import CommentForm from "../../comment-form";
import { MarkdownViewer } from "../../markdown/markdown-viewer";
import { AddToOneOnOneIconButton, CommentIconButton } from "./icon-buttons";
import { TitleSemibold } from "../../design-system/typography-components";
import Like, { LikedIndicator } from "./like";

function AchievedPlans({
  weeklyReportAchievedPlans,
  weeklyReportId,
  weeklyUser,
  currentUser,
  isDirectReport,
}) {
  const { data: previousWeekPlanReferences, mutate: refetchAchievedPlans } =
    useCollection(
      "weekly-report-answers",
      {
        included: ["oneOnOneItem", "comments", "like", "like.user"],
        filters: {
          id: weeklyReportAchievedPlans.map((plan) => plan.answerableId),
        },
        fields: {
          weeklyReportAnswers: ["data", "oneOnOneItem", "comments", "like"],
          oneOnOneItems: ["text"],
          comments: ["id"],
          likes: ["id", "userId", "user"],
          users: ["preferredName"],
        },
      },
      "denormalized",
    );

  return (
    <Stack>
      {weeklyReportAchievedPlans.map((achievedPlan, index) => (
        <AchievedPlan
          key={achievedPlan.answerableId}
          plan={achievedPlan}
          previousWeekReference={
            filterAnswersByCriteria(
              previousWeekPlanReferences,
              "id",
              achievedPlan.planId,
            )[0]
          }
          index={index}
          weeklyReportId={weeklyReportId}
          weeklyUser={weeklyUser}
          currentUser={currentUser}
          refetch={refetchAchievedPlans}
          isDirectReport={isDirectReport}
        />
      ))}
    </Stack>
  );
}

function AchievedPlan({
  plan,
  previousWeekReference,
  index,
  weeklyReportId,
  weeklyUser,
  currentUser,
  refetch,
  isDirectReport,
}) {
  const {
    data: { data: comments },
    revalidate,
  } = useCollection("comments", {
    filters: {
      commentable_type: ["WeeklyReportAnswer"],
      commentable_id: [plan.planId],
    },
    fields: {
      comments: ["private", "avatarUser", "userFullname", "body", "userId"],
    },
  });

  const [showCommentsForm, setShowCommentsForm] = useState(comments.length > 0);

  const weeklyAnswerId = previousWeekReference.id;

  const [weeklyAnswerLikeId, setWeeklyAnswerLikeId] = useState(
    previousWeekReference.like?.id || null,
  );
  const likeUser = previousWeekReference.like?.user || null;

  return (
    <Stack className="w-full">
      <Inline
        alignment={FlexAlignment.Start}
        className="w-full group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
        distribution={FlexDistribution.Between}
        space={4}
        p={[2, 6]}
      >
        <Inline alignment={FlexAlignment.Start} className="w-full" space={2}>
          <Frame className="pt-px h-4 w-4">
            <input
              data-testid={`checkbox-achievedplans-${index}`}
              id={`checkbox-achievedplans-${index}`}
              type="checkbox"
              disabled="disabled"
              className="h-4 w-4"
              checked={plan.value ? "checked" : ""}
            />
          </Frame>
          <Text color="neutral-700">
            <MarkdownViewer value={plan.text} />
          </Text>
        </Inline>

        <Inline alignment={FlexAlignment.Center} space={4}>
          {!showCommentsForm && (
            <CommentIconButton onClick={() => setShowCommentsForm(true)} />
          )}

          {!previousWeekReference?.oneOnOneItem &&
            canAddOneOnOne(currentUser.id, weeklyUser.managerId) && (
              <AddToOneOnOneIconButton
                onClick={() => {
                  addOneOnOneItem(
                    previousWeekReference,
                    currentUser.id,
                    weeklyUser.id,
                  ).then(() => refetch());
                }}
              />
            )}
          {isDirectReport && (
            <Like
              weeklyAnswerId={weeklyAnswerId}
              currentUser={currentUser}
              weeklyAnswerLikeId={weeklyAnswerLikeId}
              setWeeklyAnswerLikeId={setWeeklyAnswerLikeId}
            />
          )}
        </Inline>
      </Inline>

      <LikedIndicator
        weeklyAnswerLikeId={weeklyAnswerLikeId}
        likeUser={likeUser}
        isDirectReport={isDirectReport}
        hasCheckBox={true}
      />

      {previousWeekReference?.oneOnOneItem && (
        <RenameOneOnOne
          oneOnOneItemId={previousWeekReference.oneOnOneItem.id}
          oneOnOneItemText={previousWeekReference.oneOnOneItem.text}
          refetch={refetch}
        />
      )}
      <React.Suspense fallback={null}>
        <CommentForm
          weeklyReportId={weeklyReportId}
          revalidate={revalidate}
          senderId={currentUser.id}
          senderAvatar={currentUser.avatarUrl}
          receiverName={getPrivateCommentReceiverName(weeklyUser, currentUser)}
          currentUserId={currentUser.id}
          comments={comments}
          answerId={plan.planId}
          showAddComment={showCommentsForm}
          setShowAddComment={setShowCommentsForm}
        />
      </React.Suspense>
    </Stack>
  );
}

function Achievement({
  achievement,
  index,
  weeklyReportId,
  weeklyUser,
  currentUser,
  refetch,
  isDirectReport,
}) {
  const {
    data: { data: comments },
    revalidate,
  } = useCollection("comments", {
    filters: {
      commentable_type: ["WeeklyReportAnswer"],
      commentable_id: [achievement.id],
    },
    fields: {
      comments: ["private", "avatarUser", "userFullname", "body", "userId"],
    },
  });
  const [showCommentsForm, setShowCommentsForm] = useState(comments.length > 0);

  const weeklyAnswerId = achievement.id;

  const [weeklyAnswerLikeId, setWeeklyAnswerLikeId] = useState(
    achievement.like?.id || null,
  );

  const likeUser = achievement.like?.user || null;

  return (
    <Stack className="w-full">
      <Inline
        alignment={FlexAlignment.Start}
        className="w-full group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
        distribution={FlexDistribution.Between}
        space={4}
        p={[2, 6]}
      >
        <Inline alignment={FlexAlignment.Start} className="w-full" space={2}>
          <Frame className="pt-px h-4 w-4">
            <input
              data-testid={`checkbox-achievements-${index}`}
              id={`checkbox-achievements-${index}`}
              type="checkbox"
              disabled="disabled"
              className="h-4 w-4"
              checked={"checked"}
            />
          </Frame>
          <Text color="neutral-700">
            <MarkdownViewer value={achievement.data.text} />
          </Text>
        </Inline>

        <Inline alignment={FlexAlignment.Center} space={4}>
          {!showCommentsForm && (
            <CommentIconButton onClick={() => setShowCommentsForm(true)} />
          )}

          {!achievement.oneOnOneItem &&
            canAddOneOnOne(currentUser.id, weeklyUser.managerId) && (
              <AddToOneOnOneIconButton
                onClick={() => {
                  addOneOnOneItem(
                    achievement,
                    currentUser.id,
                    weeklyUser.id,
                  ).then(() => refetch());
                }}
              />
            )}

          {isDirectReport && (
            <Like
              weeklyAnswerId={weeklyAnswerId}
              currentUser={currentUser}
              weeklyAnswerLikeId={weeklyAnswerLikeId}
              setWeeklyAnswerLikeId={setWeeklyAnswerLikeId}
              hasCheckBox={true}
            />
          )}
        </Inline>
      </Inline>

      <LikedIndicator
        weeklyAnswerLikeId={weeklyAnswerLikeId}
        likeUser={likeUser}
        isDirectReport={isDirectReport}
        hasCheckBox={true}
      />

      {achievement.oneOnOneItem && (
        <RenameOneOnOne
          oneOnOneItemId={achievement.oneOnOneItem.id}
          oneOnOneItemText={achievement.oneOnOneItem.text}
          refetch={refetch}
        />
      )}

      <React.Suspense fallback={null}>
        <CommentForm
          weeklyReportId={weeklyReportId}
          senderId={currentUser.id}
          senderAvatar={currentUser.avatarUrl}
          receiverName={getPrivateCommentReceiverName(weeklyUser, currentUser)}
          currentUserId={currentUser.id}
          answerId={achievement.id}
          comments={comments}
          revalidate={revalidate}
          showAddComment={showCommentsForm}
          setShowAddComment={setShowCommentsForm}
        />
      </React.Suspense>
    </Stack>
  );
}

function Plan({
  plan,
  index,
  weeklyReportId,
  weeklyUser,
  currentUser,
  refetch,
  isDirectReport,
}) {
  const {
    data: { data: comments },
    revalidate,
  } = useCollection("comments", {
    filters: {
      commentable_type: ["WeeklyReportAnswer"],
      commentable_id: [plan.id],
    },
    fields: {
      comments: ["private", "avatarUser", "userFullname", "body", "userId"],
    },
  });
  const [showCommentsForm, setShowCommentsForm] = useState(comments.length > 0);

  const weeklyAnswerId = plan.id;

  const [weeklyAnswerLikeId, setWeeklyAnswerLikeId] = useState(
    plan.like?.id || null,
  );

  const likeUser = plan.like?.user || null;

  return (
    <Stack className="w-full">
      <Inline
        alignment={FlexAlignment.Start}
        className="w-full group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
        distribution={FlexDistribution.Between}
        space={4}
        p={[2, 6]}
      >
        <Text color="neutral-700">
          <MarkdownViewer key={index} value={plan.data.text} />
        </Text>
        <Inline alignment={FlexAlignment.Center} space={4}>
          {!showCommentsForm && (
            <CommentIconButton onClick={() => setShowCommentsForm(true)} />
          )}

          {!plan.oneOnOneItem &&
            canAddOneOnOne(currentUser.id, weeklyUser.managerId) && (
              <AddToOneOnOneIconButton
                onClick={() => {
                  addOneOnOneItem(plan, currentUser.id, weeklyUser.id).then(
                    () => refetch(),
                  );
                }}
              />
            )}

          {isDirectReport && (
            <Like
              weeklyAnswerId={weeklyAnswerId}
              currentUser={currentUser}
              weeklyAnswerLikeId={weeklyAnswerLikeId}
              setWeeklyAnswerLikeId={setWeeklyAnswerLikeId}
            />
          )}
        </Inline>
      </Inline>

      <LikedIndicator
        weeklyAnswerLikeId={weeklyAnswerLikeId}
        likeUser={likeUser}
        isDirectReport={isDirectReport}
      />

      {plan.oneOnOneItem && (
        <RenameOneOnOne
          oneOnOneItemId={plan.oneOnOneItem.id}
          oneOnOneItemText={plan.oneOnOneItem.text}
          refetch={refetch}
        />
      )}

      <React.Suspense fallback={null}>
        <CommentForm
          weeklyReportId={weeklyReportId}
          senderId={currentUser.id}
          senderAvatar={currentUser.avatarUrl}
          receiverName={getPrivateCommentReceiverName(weeklyUser, currentUser)}
          currentUserId={currentUser.id}
          answerId={plan.id}
          comments={comments}
          revalidate={revalidate}
          showAddComment={showCommentsForm}
          setShowAddComment={setShowCommentsForm}
        />
      </React.Suspense>
    </Stack>
  );
}

export default function AchievementSummary({
  currentUser,
  weeklyUser,
  weeklyReport,
  weeklyReportAnswers,
  refetchWeeklyAnswers = null,
}) {
  const achievements = filterAnswersByCriteria(
    weeklyReportAnswers,
    "answerType",
    "achievement",
  );
  const plans = filterAnswersByCriteria(
    weeklyReportAnswers,
    "answerType",
    "plan",
  );

  const weeklyReportAchievedPlans = wrap(weeklyReport.achievedPlans);
  const hasAchievedPlans = weeklyReportAchievedPlans.length > 0;
  const hasAchievements = achievements.length > 0;
  const hasPlans = plans.length > 0;

  const isDirectReport = currentUser.id == weeklyUser.managerId;

  return (
    <Stack space={8} className="w-full">
      <Stack>
        {(hasAchievedPlans || hasAchievements) && (
          <TitleSemibold
            as="h4"
            className="w-full px-6"
            color={Color.Neutral800}
          >
            Mark things you accomplished this week
          </TitleSemibold>
        )}

        {hasAchievedPlans && (
          <AchievedPlans
            weeklyReportAchievedPlans={weeklyReportAchievedPlans}
            weeklyReportId={weeklyReport.id}
            weeklyUser={weeklyUser}
            currentUser={currentUser}
            isDirectReport={isDirectReport}
          />
        )}

        {hasAchievements && (
          <Stack>
            {achievements.map((achievement, index) => (
              <Achievement
                key={index}
                achievement={achievement}
                index={index}
                weeklyReportId={weeklyReport.id}
                weeklyUser={weeklyUser}
                currentUser={currentUser}
                refetch={refetchWeeklyAnswers}
                isDirectReport={isDirectReport}
              />
            ))}
          </Stack>
        )}
      </Stack>

      <Stack>
        <TitleSemibold as="h4" className="w-full px-6" color={Color.Neutral800}>
          Next week's plan
        </TitleSemibold>

        {hasPlans && (
          <Stack>
            {plans.map((plan, index) => (
              <Plan
                key={index}
                plan={plan}
                weeklyReportId={weeklyReport.id}
                weeklyUser={weeklyUser}
                currentUser={currentUser}
                index={index}
                refetch={refetchWeeklyAnswers}
                isDirectReport={isDirectReport}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
