import React from "react";
import { Button } from "@ableco/baseline";

function LinkButton(props) {
  return (
    <Button
      variant="ghost"
      shadow="none"
      p={0}
      {...props}
      className={`flex flex-row items-center text-base text-primary-base hover:text-primary-light active:text-primary-dark outline-none transition-all duration-300 ease-in-out ${props.className}`}
    />
  );
}

export default LinkButton;
