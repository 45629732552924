const ResourceTypes = [
  "placeholders",
  "productAllocations",
  "productAssignments",
  "productInvestments",
  "productRevenues",
  "products",
  "projectAllocations",
  "projectRoles",
  "projects",
  "roles",
  "users",
];
export default ResourceTypes;
