export const BINARY = "binary";
export const TEXT = "text";

export const SCALE = "scale";

export const REQUEST_DISMISSED_TEXT =
  "I do not have any data to respond to this feedback request.";

export const SCALE_LABELS = {
  performance: [
    { value: 0, label: "Needs Improvement" },
    { value: 1, label: "OK" },
    { value: 2, label: "Good" },
    { value: 3, label: "Very Good" },
    { value: 4, label: "Perfect" },
  ],
  agreement: [
    { value: 0, label: "Strongly Disagree" },
    { value: 1, label: "Disagree" },
    { value: 2, label: "Neutral" },
    { value: 3, label: "Agree" },
    { value: 4, label: "Strongly Agree" },
  ],
  frequency: [
    { value: 0, label: "Never" },
    { value: 1, label: "Rarely" },
    { value: 2, label: "Sometimes" },
    { value: 3, label: "Often" },
    { value: 4, label: "Always" },
  ],
};
