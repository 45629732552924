import React from "react";
import { formatDistanceToNow } from "date-fns";
import { Text } from "@ableco/baseline";

function SavedData({ children }) {
  return (
    <Text size="xs" color="neutral-600" aria-label="save-status" role="status">
      {children}
    </Text>
  );
}

export default function SavedState({
  loadError,
  isLoading,
  savedAt,
  hasUnsavedChanges,
  name,
}) {
  if (loadError) {
    return <SavedData>{name} not saved</SavedData>;
  }

  if (isLoading) {
    return <SavedData>Saving {name}...</SavedData>;
  }

  if (hasUnsavedChanges) {
    return <SavedData>{name} has unsaved changes</SavedData>;
  }

  return savedAt ? (
    <SavedData>
      {name} saved {formatDistanceToNow(savedAt)} ago
    </SavedData>
  ) : null;
}
