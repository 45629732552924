import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { useId } from "@reach/auto-id";
import CheckIcon from "./icons/check";

const Label = styled.label`
  box-sizing: border-box;
  height: 23px;
  width: 23px;
  display: block;
  border: none;
  border-radius: 3px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  ${({ checked, isRead }) =>
    checked
      ? `
        background: ${isRead ? "rgba(0, 0, 0, 0.15)" : "#096dd9"};
        border: none;
      `
      : `
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: ${isRead ? "rgba(0, 0, 0, 0.15)" : "#fff"};
      `}

  svg {
    margin: 3px;
  }

  svg path {
    fill: #ffffff;
  }
`;

function CheckboxDeprecated({
  checked,
  onChange = _.noop,
  isRead,
  onClick = _.noop,
  ...props
}) {
  const id = `checkbox:${useId()}`;
  return (
    <>
      <input
        type="checkbox"
        checked={checked || false}
        id={id}
        onChange={onChange}
        style={{ display: "none" }}
        readOnly={isRead}
        {...props}
      />
      <Label
        htmlFor={id}
        checked={checked}
        isRead={isRead}
        onClick={onClick}
        data-cy="custom-checkbox-label"
      >
        {checked && <CheckIcon arial-label="checked" />}
      </Label>
    </>
  );
}

export default CheckboxDeprecated;
