export function getAcronym(str) {
  return (str || "")
    .split(" ")
    .map((word) => word[0])
    .join("");
}

export function getRoleAcronym(str) {
  const acronym = getAcronym(str);
  switch (acronym) {
    case "SEM":
      return "EM";
    case "DE":
      return "DO";
    default:
      return acronym;
  }
}

export function matchInitials(string, value) {
  return getAcronym(string.toLowerCase()).includes(value.toLowerCase());
}
