import React, { useState } from "react";
import {
  Color,
  Corners,
  Flex,
  FlexDistribution,
  Frame,
  Inline,
  Skeleton,
  Stack,
  Text,
  Touchable,
  useOnClickOutside,
} from "@ableco/baseline";
import {
  Heart,
  HeartOutline,
  Check,
  PlusCircle,
  Info,
  DoubleCheck,
  Edit,
  Delete,
  EllipsisVertical,
} from "@baseline/icons";
import { api } from "coreql";
import {
  BINARY,
  TEXT,
  REQUEST_DISMISSED_TEXT,
  SCALE,
  SCALE_LABELS,
} from "./constants";
import RenameOneOnOne from "../weekly-report/rename-one-on-one";
import { wrap } from "../weekly-report/utils";
import { formatSubmittedDateWithTime } from "../../utils/format-submitted-date";
import { AnimatePresence, motion } from "framer-motion";
import Linkify from "../../utils/linkify";
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  TipsButton,
} from "../buttons/buttons";
import { MarkdownEditor } from "../markdown/markdown-editor";
import { MarkdownViewer } from "../markdown/markdown-viewer";
import { useCoreToast } from "../toast/toast";
import { FeedbackTipsDrawer } from "../../content/tips/tips-journal-feedback";
import {
  BodyBaseline,
  BodyBaselineSemibold,
  BodySmall,
  BodySmallSemibold,
  Title,
  TitleSemibold,
} from "../design-system/typography-components";
import BodyWithHighlights from "../highlights/body-with-highlights";
import { SegmentedControl } from "../data-entry/segmented-control";
import { CoreTooltip } from "../core-tooltip";
import { AvatarLarge, AvatarSmall } from "../design-system/avatar";
import Menu, { MenuItem } from "../layout/menu";

function replaceName(string, value) {
  return string.replace(/\[name]/gi, value);
}

export function FeedbackMessageCardFallback() {
  return (
    <Frame
      bg={Color.White}
      corners={Corners.MediumRounded}
      border={Color.Neutral300}
      className="w-full"
    >
      <Inline p={[4, 6]} className="border-b border-neutral-300 w-full">
        <Skeleton
          animated
          size={48}
          color="neutral-400"
          corners={Corners.FullRounded}
          className="mr-4"
        />
        <Stack space={1}>
          <Skeleton
            animated
            color={Color.Neutral400}
            height={24}
            width={300}
            corners={Corners.MediumRounded}
          />
          <Skeleton
            animated
            color={Color.Neutral400}
            height={20}
            width={150}
            corners={Corners.MediumRounded}
          />
        </Stack>
      </Inline>
      <Stack space={5} p={[4, 6]} className="w-full">
        <Skeleton
          animated
          color={Color.Neutral400}
          height={20}
          width="100%"
          corners={Corners.MediumRounded}
        />
        <Skeleton
          animated
          color={Color.Neutral400}
          height={20}
          width="100%"
          corners={Corners.MediumRounded}
        />
        <Skeleton
          animated
          color={Color.Neutral400}
          height={20}
          width="100%"
          corners={Corners.MediumRounded}
        />
        <Skeleton
          animated
          color={Color.Neutral400}
          height={20}
          width="50%"
          corners={Corners.MediumRounded}
        />
      </Stack>
    </Frame>
  );
}

export function FeedbackPromptText({
  request,
  feedbackPrompt,
  showResponsibilityInfoTooltip,
}) {
  return (
    <BodyBaselineSemibold color={Color.Neutral800}>
      {replaceName(feedbackPrompt.prompt, request.sender.preferredName)}
      {showResponsibilityInfoTooltip && (
        <>
          {"\u00A0"}
          <CoreTooltip
            title="Related responsibility:"
            label={feedbackPrompt.responsibility.title}
          >
            <Text
              color={Color.Neutral600}
              data-testid="responsibility-info"
              className="inline-block align-top"
            >
              <Info
                title={`Related responsibility: ${feedbackPrompt.responsibility.title}`}
                className="w-4 h-6 hover:text-neutral-800 transition-all duration-300 ease-in-out"
              />
            </Text>
          </CoreTooltip>
        </>
      )}
    </BodyBaselineSemibold>
  );
}

export function FeedbackCardPrompt({
  requestFeedback,
  feedbackPrompt,
  dispatch,
  promptValue,
  disabled = false,
  readOnly = false,
  showResponsibilityInfoTooltip,
}) {
  function handleChange(newValue) {
    dispatch({ type: "changePrompt", promptAnswer: newValue });
  }

  if (feedbackPrompt.promptType === BINARY) {
    return (
      <Stack space={2}>
        <FeedbackPromptText
          request={requestFeedback}
          feedbackPrompt={feedbackPrompt}
          showResponsibilityInfoTooltip={showResponsibilityInfoTooltip}
        />
        <Stack space={4}>
          <Inline space={4}>
            <Inline>
              <Text
                id={`feedback-prompt-true-${requestFeedback.id}`}
                checked={promptValue === 1}
                value={1}
                size="xs"
                onChange={() => handleChange(1)}
                as="input"
                type="radio"
                disabled={disabled || readOnly}
              />
              <label htmlFor={`feedback-prompt-true-${requestFeedback.id}`}>
                <Text
                  color={Color.Neutral700}
                  className="pl-2"
                  id={`feedback-prompt-label-true-${requestFeedback.id}`}
                >
                  Yes
                </Text>
              </label>
            </Inline>
            <Inline>
              <Text
                id={`feedback-prompt-false-${requestFeedback.id}`}
                checked={promptValue === 0}
                value={0}
                size="xs"
                onChange={() => handleChange(0)}
                as="input"
                type="radio"
                disabled={disabled || readOnly}
              />
              <label htmlFor={`feedback-prompt-false-${requestFeedback.id}`}>
                <Text
                  color={Color.Neutral700}
                  className="pl-2"
                  id={`feedback-prompt-label-false-${requestFeedback.id}`}
                >
                  No
                </Text>
              </label>
            </Inline>
          </Inline>
          {!Boolean(requestFeedback.dismissedAt) && (
            <BodyBaselineSemibold color={Color.Neutral800}>
              Please provide an example to support your answer:
            </BodyBaselineSemibold>
          )}
        </Stack>
      </Stack>
    );
  }
  if (feedbackPrompt.promptType === SCALE) {
    return (
      <Stack space={2}>
        <FeedbackPromptText
          request={requestFeedback}
          feedbackPrompt={feedbackPrompt}
          showResponsibilityInfoTooltip={showResponsibilityInfoTooltip}
        />
        <Stack space={4}>
          <SegmentedControl
            values={SCALE_LABELS[feedbackPrompt.scaleType]}
            disabled={disabled || readOnly}
            defaultValue={promptValue}
          />

          {!Boolean(requestFeedback.dismissedAt) && (
            <BodyBaselineSemibold color={Color.Neutral800}>
              Please provide an example to support your answer:
            </BodyBaselineSemibold>
          )}
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack space={2}>
      <FeedbackPromptText
        request={requestFeedback}
        feedbackPrompt={feedbackPrompt}
        showResponsibilityInfoTooltip={showResponsibilityInfoTooltip}
      />
    </Stack>
  );
}

function AddToOneOnOneButton({ feedbackMessageId, onClick, addedToOneOnOne }) {
  return (
    <TertiaryButton
      text={addedToOneOnOne ? "Added to 1-on-1 " : "Add to 1-on-1"}
      onClick={onClick}
      LeftIcon={addedToOneOnOne ? Check : PlusCircle}
      data-testid={`addToOneOnOne-btn:${feedbackMessageId}`}
      className="flex-shrink-0"
      disabled={addedToOneOnOne}
    />
  );
}

function MarkReviewedButton({ feedbackMessageId, onClick, reviewed }) {
  return (
    <SecondaryButton
      text={reviewed ? "Reviewed" : "Mark reviewed"}
      LeftIcon={reviewed ? Check : null}
      onClick={onClick}
      disabled={reviewed}
      data-testid={`review-btn:${feedbackMessageId}`}
      className=" flex-shrink-0"
    />
  );
}

export function FeedbackLike({ idFeedbackMessage, liked, refetch }) {
  const [likedAt, setLikedAt] = useState(liked);

  async function handleLike() {
    const newLikedAt = new Date();
    try {
      setLikedAt(newLikedAt);
      await api.feedbackMessages
        .find(idFeedbackMessage)
        .update({ liked: newLikedAt });
      refetch();
    } catch {
      setLikedAt(null);
    }
  }

  return (
    <Touchable
      onClick={handleLike}
      disabled={Boolean(likedAt)}
      data-testid={`feedbackMessageLikeButton:${idFeedbackMessage}`}
    >
      <Frame
        as={motion.div}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 1.4 }}
        transition={{
          type: "spring",
          damping: 4,
        }}
      >
        {likedAt ? (
          <Heart className="w-6 h-6 text-alert-light" />
        ) : (
          <HeartOutline className="w-6 h-6 text-neutral-600 hover:text-alert-light transition-all duration-300 ease-in-out" />
        )}
      </Frame>
    </Touchable>
  );
}

function ReceiverActions({ liked, name }) {
  return (
    <Inline space={2}>
      <Text color={liked ? "alert-light" : Color.PrimaryLight}>
        {liked ? (
          <Heart className="w-6 h-6" />
        ) : (
          <DoubleCheck className="w-6 h-6" />
        )}
      </Text>
      <BodyBaseline color={Color.Neutral600}>
        {liked ? "Seen and liked " : "Seen "}
        by {name}
      </BodyBaseline>
    </Inline>
  );
}

function CoachingTip({
  message,
  refetch,
  displayCoachingTip,
  addCoachingTipEnabled,
}) {
  const [formOpen, setFormOpen] = useState(false);
  const [coachingTipInputText, setCoachingTipInputText] = useState(
    message.coachingTip || "",
  );

  const [savedCoachingTip, setSavedCoachingTip] = useState(
    message.coachingTip || "",
  );

  const [showTipsDrawer, setShowTipsDrawer] = React.useState(false);
  const handleCloseDrawer = React.useCallback(
    () => setShowTipsDrawer(false),
    [setShowTipsDrawer],
  );

  const { successToast, errorToast } = useCoreToast();

  function handleCancel() {
    setFormOpen(false);
    setCoachingTipInputText(savedCoachingTip);
  }

  async function handleSave() {
    const originalValue = savedCoachingTip;
    try {
      setSavedCoachingTip(coachingTipInputText);
      setFormOpen(false);
      await api.feedbackMessages
        .find(message.id)
        .update({ coachingTip: coachingTipInputText });
      successToast("Your coaching tip has been saved!");
    } catch {
      setSavedCoachingTip(originalValue);
      setFormOpen(true);
      errorToast("We couldn't save your coaching tip. Contact the team.");
    } finally {
      refetch();
    }
  }

  async function handleDelete() {
    const originalSavedCoachingTip = savedCoachingTip;
    const originalInputText = coachingTipInputText;
    try {
      setFormOpen(false);
      setSavedCoachingTip("");
      setCoachingTipInputText("");
      await api.feedbackMessages.find(message.id).update({ coachingTip: "" });
      successToast("Your coaching tip has been deleted!");
    } catch {
      setSavedCoachingTip(originalSavedCoachingTip);
      setCoachingTipInputText(originalInputText);
      errorToast("We couldn't delete your coaching tip. Contact the team.");
    } finally {
      refetch();
    }
  }

  function handleChange(event) {
    const newText = event.target.value;
    setCoachingTipInputText(newText);
  }

  if (displayCoachingTip && savedCoachingTip && !formOpen) {
    return (
      <Stack p={[4, 6]} space={2} bg={Color.Neutral100} className="group">
        <Inline distribution={FlexDistribution.Between}>
          <BodyBaselineSemibold color={Color.Neutral700}>
            Coaching Tip
          </BodyBaselineSemibold>

          {addCoachingTipEnabled && (
            <Inline space={4}>
              <CoreTooltip label="Edit coaching tip">
                <Touchable
                  className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out"
                  aria-label="Edit coaching tip"
                  onClick={() => setFormOpen(true)}
                >
                  <Edit className="text-neutral-600" />
                </Touchable>
              </CoreTooltip>
              <CoreTooltip label="Delete coaching tip">
                <Touchable
                  className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out"
                  aria-label="Delete coaching tip"
                  onClick={handleDelete}
                >
                  <Delete className="text-neutral-600" />
                </Touchable>
              </CoreTooltip>
            </Inline>
          )}
        </Inline>
        <Frame className="text-neutral-700 leading-normal">
          <MarkdownViewer value={savedCoachingTip} />
        </Frame>
      </Stack>
    );
  }

  if (!formOpen && addCoachingTipEnabled && !savedCoachingTip) {
    return (
      <Stack p={[4, 6]}>
        <Frame>
          <Inline space={2}>
            <Touchable onClick={() => setFormOpen(true)}>
              <BodyBaseline
                color={Color.Primary}
                className="hover:text-primary-light transition-all duration-300 ease-in-out"
              >
                Leave a Coaching Tip
              </BodyBaseline>
            </Touchable>

            <TipsButton onClick={() => setShowTipsDrawer(true)} />
            <AnimatePresence>
              {showTipsDrawer && (
                <FeedbackTipsDrawer onClose={handleCloseDrawer} />
              )}
            </AnimatePresence>
          </Inline>
        </Frame>
      </Stack>
    );
  }

  if (formOpen && addCoachingTipEnabled) {
    const isSaveEnabled = Boolean(coachingTipInputText.trim());
    return (
      <Stack p={[4, 6]} space={4} bg={Color.Neutral100}>
        <Stack space={2}>
          <Inline space={2}>
            <BodyBaseline
              as="label"
              htmlFor="coaching-tip"
              color={Color.Neutral700}
            >
              Leave a Coaching Tip
            </BodyBaseline>
            <TipsButton onClick={() => setShowTipsDrawer(true)} />
            <AnimatePresence>
              {showTipsDrawer && (
                <FeedbackTipsDrawer onClose={handleCloseDrawer} />
              )}
            </AnimatePresence>
          </Inline>
          <MarkdownEditor
            id="coaching-tip"
            name="coaching-tip"
            data-testid="coaching-tip"
            text={coachingTipInputText}
            onChange={handleChange}
            type="multiline"
            autosize={88}
          />
        </Stack>
        <Inline space={4} distribution={FlexDistribution.End}>
          <TertiaryButton onClick={handleCancel} text="Cancel" />
          <PrimaryButton
            onClick={handleSave}
            text="Save coaching tip"
            disabled={!isSaveEnabled}
          />
        </Inline>
      </Stack>
    );
  }

  return null;
}

async function reviewMessageAsManager(id) {
  const now = new Date();
  await api.feedbackMessages
    .find(id)
    .update({ reviewedByManager: now, readByManager: now });
}

async function addOneOnOneItem(messageId, text, receiverId, requesterId) {
  await api.oneOnOneItems.create({
    relatedResourceId: messageId,
    relatedResourceType: "FeedbackMessage",
    text,
    kind: "talking_point",
    requestedId: receiverId,
    requesterId: requesterId,
  });
}

export function FeedbackCardHeader({
  headerUser,
  isReviewable,
  messageId,
  reviewedByManager,
  liked,
  isLikeable,
  receiver,
  sender,
  submittedAt,
  currentUser,
  addedToOneOnOne,
  setAddedToOneOnOne,
  refetch,
  displayRole,
  displaySubmittedTime,
  addToOneOnOneEnabled,
  addToOneOnOneReceiver,
  borderEnable,
  isDeferred,
  feedbackDeferral,
  toogleRequestDeferral,
  feedbackRequestId,
}) {
  const [reviewed, setReviewed] = React.useState(reviewedByManager);
  async function handleMessageReview() {
    try {
      setReviewed(true);
      await reviewMessageAsManager(messageId);
      refetch();
    } catch {
      setReviewed(false);
    }
  }

  async function handleAddToOneOnOne() {
    try {
      setAddedToOneOnOne(true);
      await addOneOnOneItem(
        messageId,
        receiver.id == addToOneOnOneReceiver.id
          ? `Feedback from: ${sender.preferredName}`
          : `Feedback to: ${receiver.preferredName}`,
        addToOneOnOneReceiver.id,
        currentUser.id,
      );
      refetch();
    } catch {
      setAddedToOneOnOne(false);
    }
  }

  function roleAndTime() {
    if (displayRole && displaySubmittedTime) {
      return `${headerUser.role?.title} - ${formatSubmittedDateWithTime(
        new Date(submittedAt),
      )}`;
    }
    if (displaySubmittedTime)
      return formatSubmittedDateWithTime(new Date(submittedAt));
    return headerUser.role?.title;
  }

  const [open, setOpen] = React.useState(false);
  let deferredText = "";
  let menuText = "";
  if (isDeferred) {
    deferredText = "Deferred to next week";
    menuText = "Remove defer";
  } else if (!isDeferred && feedbackDeferral) {
    deferredText = "Deferred last week";
  } else {
    menuText = "Defer to next week";
  }
  function DeferalMenu() {
    const $menu = React.createRef();
    useOnClickOutside(
      $menu,
      React.useCallback(() => setOpen(false), []),
    );
    return (
      <Menu
        ref={$menu}
        className="absolute top-8 right-2 z-10 w-32"
        shadow="md"
      >
        <Touchable
          aria-label="Defer feedback menu"
          className="w-full"
          onClick={() => {
            toogleRequestDeferral();
            setOpen(!open);
          }}
        >
          <MenuItem>
            <Inline>{menuText}</Inline>
          </MenuItem>
        </Touchable>
      </Menu>
    );
  }
  return (
    <Inline
      space={4}
      p={[4, 6]}
      className={`${borderEnable ? "border-b border-neutral-300" : ""} w-full`}
      data-testid={`feedback-header:${messageId}`}
    >
      <AvatarLarge
        url={headerUser.avatarUrl}
        userId={headerUser.id}
        name={headerUser.fullName}
      />
      <Flex className="w-full" alignment="center" distribution="between">
        <Stack space={1}>
          <TitleSemibold as="h2" color={Color.Neutral800}>
            {currentUser?.id == headerUser.id ? "You" : headerUser.fullName}
            {currentUser && receiver && (
              <Title as="span" className="ml-1" color={Color.Neutral800}>
                to{" "}
                {currentUser?.id != receiver?.id
                  ? receiver?.preferredName
                  : "you"}
              </Title>
            )}
          </TitleSemibold>
          {displayRole || displaySubmittedTime ? (
            <BodySmall color={Color.Neutral600}>{roleAndTime()}</BodySmall>
          ) : null}
        </Stack>
        <Frame className={"flex flex-wrap relative"}>
          {feedbackDeferral && <BodyBaseline>{deferredText}</BodyBaseline>}

          {addToOneOnOneEnabled || isReviewable || isLikeable ? (
            <Inline space={4}>
              {addToOneOnOneEnabled ? (
                <AddToOneOnOneButton
                  onClick={handleAddToOneOnOne}
                  addedToOneOnOne={addedToOneOnOne}
                  feedbackMessageId={messageId}
                />
              ) : null}
              {isReviewable ? (
                <MarkReviewedButton
                  onClick={handleMessageReview}
                  feedbackMessageId={messageId}
                  reviewed={reviewed}
                />
              ) : null}

              {isLikeable && (
                <FeedbackLike
                  idFeedbackMessage={messageId}
                  liked={liked}
                  refetch={refetch}
                />
              )}
            </Inline>
          ) : null}
          {feedbackDeferral && !isDeferred ? null : (
            <Touchable
              aria-label="More actions"
              data-testid={"feedback:header:menu" + feedbackRequestId}
              bg="info"
              onClick={() => {
                setOpen(true);
              }}
            >
              <Text color="neutral-800">
                <EllipsisVertical />
              </Text>
            </Touchable>
          )}
          {open && <DeferalMenu />}
        </Frame>
      </Flex>
    </Inline>
  );
}

function FeedbackMessageBody({
  id,
  body,
  isDismissed,
  highlights,
  receiverId,
  highlightingEnabled,
  refetch,
}) {
  if (isDismissed) {
    return (
      <BodyBaseline
        color={Color.Neutral700}
        id={id}
        data-testid={`feedback:body:${id}`}
      >
        {REQUEST_DISMISSED_TEXT}
      </BodyBaseline>
    );
  }

  return (
    <BodyWithHighlights
      id={id}
      body={body}
      highlights={highlights}
      receiverId={receiverId}
      highlightingEnabled={highlightingEnabled}
      refetch={refetch}
    />
  );
}

function possessive(string) {
  if (!Boolean(string)) return;
  const lastChar = string.slice(-1);
  const endOfWord = lastChar.toLowerCase() == "s" ? "'" : `'s`;
  return `${string}${endOfWord}`;
}

function RequestAuthorLabel({ currentUser, request }) {
  const currentUserIsRequestAuthor =
    request.author && currentUser.id == request.author.id;

  const currentUserIsRequestSender =
    !request.author && currentUser.id == request.sender.id;

  function authorLabel() {
    if (currentUserIsRequestAuthor || currentUserIsRequestSender)
      return "Your request:";
    if (request.author) {
      return `${possessive(request.author.preferredName)} request:`;
    }

    return `${possessive(request.sender.preferredName)} request:`;
  }

  function AuthorAvatar() {
    const user = request.author ? request.author : request.sender;
    return (
      <AvatarSmall url={user.avatarUrl} name={user.fullName} userId={user.id} />
    );
  }

  return (
    <Inline space={2}>
      <AuthorAvatar />
      <BodySmallSemibold color={Color.Neutral600}>
        {authorLabel()}
      </BodySmallSemibold>
    </Inline>
  );
}

export function FeedbackMessageCard({
  request,
  response,
  oneOnOneItem,
  feedbackPrompt,
  headerUser,
  isLikeable,
  isReviewable,
  currentUser,
  displayRole,
  displaySubmittedTime,
  displayCoachingTip,
  highlightingEnabled,
  addToOneOnOneEnabled,
  addToOneOnOneReceiver,
  addCoachingTipEnabled,
  showReceiverActions,
  showResponsibilityInfoTooltip,
  displayRequesterByline,
  displayRequestBodyAuthorLabel,
  bg = "white",
  borderEnable = true,
  refetch = () =>
    console.error(
      "FeedbackMessageCard's refetch prop was called after an api action but none was provided. The UI might not be in sync with the backend.",
    ),
}) {
  const [addedToOneOnOne, setAddedToOneOnOne] = React.useState(oneOnOneItem);

  return (
    <Stack space={2} alignment="start" className="w-full">
      {displayRequesterByline && request?.author && (
        <BodyBaseline color="neutral-700">
          Feedback requested by {request.author?.fullName}
        </BodyBaseline>
      )}
      <Frame
        bg={bg}
        corners="medium"
        className="w-full"
        {...(borderEnable ? { border: "neutral-300" } : {})}
      >
        <FeedbackCardHeader
          headerUser={headerUser}
          liked={response.liked}
          isLikeable={isLikeable}
          isReviewable={isReviewable}
          messageId={response.id}
          reviewedByManager={response.reviewedByManager}
          receiver={response.receiver}
          sender={response.sender}
          submittedAt={response.submittedAt}
          currentUser={currentUser}
          addedToOneOnOne={addedToOneOnOne}
          setAddedToOneOnOne={setAddedToOneOnOne}
          refetch={refetch}
          displayRole={displayRole}
          displaySubmittedTime={displaySubmittedTime}
          addToOneOnOneEnabled={addToOneOnOneEnabled}
          addToOneOnOneReceiver={addToOneOnOneReceiver}
          borderEnable={borderEnable}
        />
        <Stack space={4} p={[4, 6]} className="w-full">
          {request && (
            <Stack
              space={1}
              className="border-l-4 border-neutral-400"
              p={[0, 4]}
            >
              {displayRequestBodyAuthorLabel && (
                <RequestAuthorLabel
                  currentUser={currentUser}
                  request={request}
                />
              )}
              {request?.body?.trim() && (
                <Linkify
                  componentWrapper={(href, text, key) => (
                    <a
                      href={href}
                      key={key}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary-base hover:text-primary-light transition-all duration-300 ease-in-out break-all"
                    >
                      {text}
                    </a>
                  )}
                >
                  <BodyBaseline
                    as="blockquote"
                    color={Color.Neutral600}
                    data-testid="request-body-blockquote"
                    wordBreak="words"
                  >
                    {request.body}
                  </BodyBaseline>
                </Linkify>
              )}
            </Stack>
          )}
          <Stack space={feedbackPrompt?.promptType === TEXT ? 1 : 2}>
            {feedbackPrompt && (
              <FeedbackCardPrompt
                requestFeedback={request}
                promptValue={response?.promptAnswer}
                feedbackPrompt={feedbackPrompt}
                readOnly={true}
                disabled={Boolean(request?.dismissedAt)}
                showResponsibilityInfoTooltip={showResponsibilityInfoTooltip}
              />
            )}
            <FeedbackMessageBody
              id={response?.id}
              body={response?.body}
              isDismissed={Boolean(request?.dismissedAt)}
              highlights={wrap(response?.feedbackHighlights)}
              receiverId={response?.receiver?.id}
              highlightingEnabled={highlightingEnabled}
              refetch={refetch}
            />
          </Stack>
          {showReceiverActions &&
          (response.readByReceiver || response.liked) ? (
            <ReceiverActions
              liked={response.liked}
              name={response.receiver.preferredName}
            />
          ) : null}
        </Stack>
        {(displayCoachingTip || addCoachingTipEnabled) && (
          <CoachingTip
            message={response}
            currentUser={currentUser}
            refetch={refetch}
            displayCoachingTip={displayCoachingTip}
            addCoachingTipEnabled={addCoachingTipEnabled}
          />
        )}
        {addToOneOnOneEnabled && oneOnOneItem && (
          <RenameOneOnOne
            oneOnOneItemId={oneOnOneItem?.id}
            oneOnOneItemText={oneOnOneItem?.text}
            refetch={refetch}
          />
        )}
      </Frame>
    </Stack>
  );
}
