import React, { useState } from "react";

import {
  Color,
  FlexAlignment,
  FlexDistribution,
  Inline,
  Stack,
  Text,
} from "@ableco/baseline";

import CommentForm from "../../comment-form";
import { wrap } from "../utils";
import {
  canAddOneOnOne,
  addOneOnOneItem,
  getPrivateCommentReceiverName,
} from "./utils";
import RenameOneOnOne from "../rename-one-on-one";
import { MarkdownViewer } from "../../markdown/markdown-viewer";
import { AddToOneOnOneIconButton, CommentIconButton } from "./icon-buttons";
import {
  BodyBaseline,
  BodySmall,
} from "../../design-system/typography-components";
import { useCollection } from "coreql";
import { Lock } from "@baseline/icons";
import Like, { LikedIndicator } from "./like";

function ShowQuestionAnswer(
  answers,
  weeklyReport,
  currentUser,
  weeklyUser,
  refetchWeeklyAnswers,
) {
  if (answers.length === 0) {
    return (
      <Stack className="first:mt-0">
        <Inline
          space={4}
          p={[2, 6]}
          className="w-full group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
          distribution="between"
          alignment="center"
        >
          <BodyBaseline color={Color.Neutral700}>
            No answer was provided or you do not have access to view private
            responses.
          </BodyBaseline>
        </Inline>
      </Stack>
    );
  }

  return answers.map((answer) => (
    <QuestionsAnswer
      key={answer.id}
      answer={answer}
      weeklyReport={weeklyReport}
      currentUser={currentUser}
      weeklyUser={weeklyUser}
      refetchWeeklyAnswers={refetchWeeklyAnswers}
    />
  ));
}

function QuestionsAnswer({
  answer,
  weeklyReport,
  currentUser,
  weeklyUser,
  refetchWeeklyAnswers,
}) {
  const {
    data: { data: questionAnswerComments },
    revalidate,
  } = useCollection("comments", {
    filters: {
      commentable_type: ["WeeklyReportAnswer"],
      commentable_id: [answer.id],
    },
    fields: {
      comments: ["private", "avatarUser", "userFullname", "body", "userId"],
    },
  });
  const [showQuestionAnswersCommentsForm, setShowQuestionAnswersCommentsForm] =
    React.useState(questionAnswerComments.length > 0);

  const isDirectReport = currentUser.id == weeklyUser.managerId;

  const [weeklyAnswerLikeId, setWeeklyAnswerLikeId] = useState(
    answer.like?.id || null,
  );

  const likeUser = answer.like?.user || null;

  return (
    <Stack className="w-full">
      <Inline
        alignment={FlexAlignment.Start}
        className="w-full group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
        distribution={FlexDistribution.Between}
        space={4}
        p={[2, 6]}
      >
        <Text className="text-neutral-700 core-break-words">
          <MarkdownViewer value={answer.data.text} />
          {answer.data.additionalInformationText && (
            <MarkdownViewer value={answer.data.additionalInformationText} />
          )}
          {answer.locked && (
            <Inline space={1}>
              <Lock className="h-4 w-4 text-neutral-600" />
              <BodySmall color={Color.Neutral600}>
                Only you and{" "}
                {getPrivateCommentReceiverName(weeklyUser, currentUser)} can see
                this
              </BodySmall>
            </Inline>
          )}
        </Text>
        <Inline alignment={FlexAlignment.Center} space={4}>
          {!showQuestionAnswersCommentsForm && (
            <CommentIconButton
              onClick={() => setShowQuestionAnswersCommentsForm(true)}
            />
          )}
          {!answer.oneOnOneItem &&
            canAddOneOnOne(currentUser.id, weeklyUser.managerId) && (
              <AddToOneOnOneIconButton
                onClick={() => {
                  addOneOnOneItem(answer, currentUser.id, weeklyUser.id).then(
                    () => refetchWeeklyAnswers(),
                  );
                }}
              />
            )}
          {isDirectReport && (
            <Like
              weeklyAnswerId={answer.id}
              currentUser={currentUser}
              weeklyAnswerLikeId={weeklyAnswerLikeId}
              setWeeklyAnswerLikeId={setWeeklyAnswerLikeId}
            />
          )}
        </Inline>
      </Inline>

      <LikedIndicator
        weeklyAnswerLikeId={weeklyAnswerLikeId}
        likeUser={likeUser}
        isDirectReport={isDirectReport}
      />

      {answer.oneOnOneItem && (
        <RenameOneOnOne
          oneOnOneItemId={answer.oneOnOneItem.id}
          oneOnOneItemText={answer.oneOnOneItem.text}
          refetch={refetchWeeklyAnswers}
        />
      )}

      <React.Suspense fallback={null}>
        <CommentForm
          weeklyReportId={weeklyReport.id}
          senderId={currentUser.id}
          senderAvatar={currentUser.avatarUrl}
          receiverName={getPrivateCommentReceiverName(weeklyUser, currentUser)}
          currentUserId={currentUser.id}
          answerId={answer.id}
          showAddComment={showQuestionAnswersCommentsForm}
          comments={questionAnswerComments}
          revalidate={revalidate}
          setShowAddComment={setShowQuestionAnswersCommentsForm}
        />
      </React.Suspense>
    </Stack>
  );
}

export default function QuestionsSummary({
  weeklyReport,
  currentUser,
  weeklyUser,
  questionAnswers,
  refetchWeeklyAnswers,
}) {
  return (
    <Stack as="section" className="w-full" space={4}>
      {wrap(weeklyReport.questions).map((question) => (
        <Stack as="article" key={question.questionId}>
          <Inline className="px-6 text-neutral-800 core-break-words">
            <MarkdownViewer value={`### ` + question.text} />
          </Inline>

          {ShowQuestionAnswer(
            wrap(
              questionAnswers.filter(
                (answer) => answer.answerableId === question.questionId,
              ),
            ),
            weeklyReport,
            currentUser,
            weeklyUser,
            refetchWeeklyAnswers,
          )}
        </Stack>
      ))}
    </Stack>
  );
}
