import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as _ from "lodash";
import BaseDropdown from "./base-dropdown";

const focusStyles = `
  border: 1px solid #3d5186;
  box-shadow: 0px 0px 0px 1px #3d5186, 0px 0px 0px 3px rgba(121, 178, 225, 0.35);
  outline: none;
`;

const Item = styled.li`
  padding: 6px 16px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #f2f8fc;
  }
  ${({ highlighted }) =>
    highlighted &&
    `
      background-color: #f2f8fc;
    `}
`;

const Dropdown = styled.input`
  padding: 0 16px;
  height: 42px;
  width: 100%;
  color: #3d5186;
  font-size: 14px;
  font-family: inherit;
  z-index: 3;
  outline: none;
  border: 1px solid #e0e3e9;
  border-radius: 5px;
  &::placeholder {
    color: #c2c9d4;
  }
  &:focus {
    ${focusStyles}
  }
  ${({ hasErrors }) =>
    hasErrors &&
    `
      border-color: red;
    `}
  ${({ isOpen }) => isOpen && focusStyles}
  ${({ disabled }) =>
    disabled &&
    `
      background-color: #f5f7f8;
      color: #c2c9d4;
      cursor: default;
    `}
`;

const List = styled.ul`
  max-height: 252px;
  width: 256px;
  overflow: auto;
  z-index: 3;
  position: absolute;
  top: 75px;
  left: 0;
  background-color: white;
  border-radius: 5px;
  ${({ isOpen }) => isOpen && focusStyles};
`;

const Container = styled.div`
  position: relative;
`;

const WrapDropdown = styled.div`
  width: 100%;
  max-width: 256px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 20px;
    right: 16px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #8b98ae;
    pointer-events: none;
  }
  ${({ isOpen }) =>
    isOpen &&
    `
      &:before {
        border-top: 0;
        border-bottom: 5px solid #3d5186;
        }
    `}
  ${({ inputValue }) =>
    inputValue &&
    `
      &:before {
        display: none;
        }
    `}
`;

const Close = styled.span`
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8b98ae;
  cursor: pointer;
  transition: all 0.25s ease;
  background: #eceef2;
  &:hover {
    color: #3d5186;
  }
`;

function FormDropdown({
  placeholder = "Select...",
  options,
  filterItems,
  onSelect = _.noop,
  onReset = _.noop,
  disabled = false,
  searchable = true,
  field = {},
  form = {},
  label,
}) {
  return (
    <BaseDropdown
      {...{
        placeholder,
        options,
        filterItems,
        onSelect,
        onReset,
        disabled,
        searchable,
        field,
        form,
        label,
      }}
      styles={{
        item: Item,
        dropdown: Dropdown,
        list: List,
        container: Container,
        wrapDropdown: WrapDropdown,
        close: Close,
      }}
    />
  );
}

FormDropdown.propTypes = {
  placeholder: PropTypes.string,
  filterItems: PropTypes.func,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      content: PropTypes.node.isRequired,
    }),
  ),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.shape({
      attributes: PropTypes.object,
    }),
    errors: PropTypes.shape({
      attributes: PropTypes.object,
    }),
  }),
  label: PropTypes.element,
};

export default FormDropdown;
