import React from "react";
import { Cell, Frame, Inline, Text } from "@ableco/baseline";
import { Able, Cake, Location, Mail, Slack } from "@baseline/icons";
import { format, parseISO } from "date-fns";
import CoreLink from "../core-link";

function UserInfo({ icon, children }) {
  return (
    <Cell as="li" p={[2, 6, 0, 6]}>
      <Inline space={2}>
        <Frame className="text-neutral-600" corners="full" p={2}>
          {icon}
        </Frame>
        {children}
      </Inline>
    </Cell>
  );
}

function UserLocation({ location }) {
  if (!location) return null;
  return (
    <UserInfo icon={<Location width="24" height="24" />}>
      <Text color="neutral-800">
        <CoreLink
          color={["neutral-800", "primary-light"]}
          className="hover:no-underline"
          href={`https://www.google.com/maps/place/${location.address1} ${location.address2}`}
          isExternalLink
        >
          {location.name}
        </CoreLink>
        <Text color="neutral-600">
          {format(
            parseISO(location.currentLocalTime.slice(0, -6)),
            " (hh:mm a)",
          )}
        </Text>
      </Text>
    </UserInfo>
  );
}

function UserBirthday({ date }) {
  if (!date) return null;
  return (
    <UserInfo icon={<Cake width="24" height="24" />}>
      <Text color="neutral-800" as="time" dateTime={date}>
        {format(parseISO(date), "MMMM d")}
      </Text>
    </UserInfo>
  );
}

function UserSlack({ name }) {
  if (!name) return null;
  return (
    <UserInfo icon={<Slack width="24" height="24" />}>
      <Text color="neutral-800">{name}</Text>
    </UserInfo>
  );
}

function UserEmail({ email }) {
  if (!email) return null;
  return (
    <UserInfo icon={<Mail width="24" height="24" />}>
      <CoreLink
        className="hover:no-underline"
        color={["neutral-800", "primary-light"]}
        href={`mailto:${email}`}
        isExternalLink
      >
        {email}
      </CoreLink>
    </UserInfo>
  );
}

function UserJoinDate({ date }) {
  if (!date) return null;
  return (
    <UserInfo icon={<Able width="24" height="24" />}>
      <Text color="neutral-800">
        Joined {format(parseISO(date), "MMMM d, yyyy")}
      </Text>
    </UserInfo>
  );
}

const UserInformation = {
  JoinDate: UserJoinDate,
  Email: UserEmail,
  Slack: UserSlack,
  Birthday: UserBirthday,
  Location: UserLocation,
};

export default UserInformation;
