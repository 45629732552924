import Resource from "./../Resource";

export default class Project extends Resource {
  list(params = {}) {
    return this._get("projects", params);
  }

  retrieve(id, params = {}) {
    return this._get(`projects/${id}`, params);
  }

  update(id, data = {}) {
    return this._put(`projects/${id}`, data);
  }
}
