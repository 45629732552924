import { Color, Frame, Stack } from "@ableco/baseline";
import React from "react";
import { useResource } from "coreql";
import { BodyBaseline, Heading } from "../design-system/typography-components";
import PageLayout from "../layout/page-layout";
import {
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfYear,
  format,
  parseISO,
} from "date-fns";
import CoreLink from "../core-link";
import { useQuery } from "../../hooks/use-query";

function LinkText({ href, ...props }) {
  return (
    <CoreLink href={href} className="hover:no-underline">
      <BodyBaseline
        color={Color.Primary}
        className="hover:text-primary-light transition-colors duration-300 ease-in-out"
        {...props}
      />
    </CoreLink>
  );
}

function MonthlyReportLink({ year, month }) {
  const paddedMonthString = format(month, "MM");
  return (
    <LinkText href={`/reports/${year}${paddedMonthString}`}>
      {paddedMonthString} - {format(month, "MMMM")}
    </LinkText>
  );
}

function WeeklyReportLink({ year, week, index }) {
  const paddedWeekString = String(index + 1).padStart(2, "0");
  return (
    <LinkText href={`/reports/${year}W${paddedWeekString}`}>
      W{paddedWeekString} - {format(week, "yyyy/MM/dd")}
    </LinkText>
  );
}

function YearSection({ year, firstWeek, lastWeek }) {
  const today = new Date();
  const currentYear = today.getFullYear();
  const yearStart = new Date(year, 0, 1);
  const filter = useQuery().get("by");

  const periods =
    filter === "week"
      ? eachWeekOfInterval(
          {
            start: parseISO(firstWeek),
            end: year == currentYear ? today : parseISO(lastWeek),
          },
          { weekStartsOn: 1 },
        )
      : eachMonthOfInterval({
          start: yearStart,
          end: year == currentYear ? today : endOfYear(yearStart),
        });

  return (
    <Stack space={2}>
      <Heading>{year}</Heading>
      <Stack as="ul">
        {periods.map((period, index) => (
          <Frame as="li" key={period}>
            {filter === "week" ? (
              <WeeklyReportLink year={year} week={period} index={index} />
            ) : (
              <MonthlyReportLink year={year} month={period} />
            )}
          </Frame>
        ))}
      </Stack>
    </Stack>
  );
}

export default function ReportsPage() {
  const { data: years } = useResource("time-entries", "years");
  const orderedYears = Object.entries(years).sort((year) => year[0]);

  return (
    <PageLayout title="Reports">
      <Stack space={8}>
        {orderedYears.map(([year, { start, end }]) => (
          <YearSection
            key={year}
            year={year}
            firstWeek={start}
            lastWeek={end}
          />
        ))}
      </Stack>
    </PageLayout>
  );
}
