// Modules
import React from "react";
// Components
import Controls from "utilization/components/Controls";
import Table from "utilization/components/Table";
import PageLayout from "../../../components/layout/page-layout";
// Styles
import Styles from "./Dashboard.module.scss";

function Dashboard() {
  return (
    <PageLayout title="Utilization">
      <div className={Styles.container}>
        <div className={Styles.controls}>
          <Controls />
        </div>

        <div className={Styles.table}>
          <Table />
        </div>
      </div>
    </PageLayout>
  );
}

export default Dashboard;
