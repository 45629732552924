import React from "react";
import { Flex, Frame, Inline, Stack, Color } from "@ableco/baseline";
import { wrap } from "./weekly-report/utils";
import { useResource } from "coreql";
import { formatSubmittedDateWithTime } from "../utils/format-submitted-date";
import emojis from "markdown-it-emoji/lib/data/full.json";
import {
  BodyBaseline,
  BodySmall,
  Title,
  TitleSemibold,
} from "./design-system/typography-components";
import { TertiaryButton } from "./buttons/buttons";
import { Slack } from "@baseline/icons";
import BodyWithHighlights from "./highlights/body-with-highlights";
import { AvatarLarge } from "./design-system/avatar";

const emojisWithSkinTone = {
  ...emojis,
  "skin-tone-2": "🏻",
  "skin-tone-3": "🏼",
  "skin-tone-4": "🏽",
  "skin-tone-5": "🏾",
  "skin-tone-6": "🏿",
};

function toPeople(mentions) {
  if (Array.isArray(mentions)) {
    if (mentions.length === 0) return;
    return mentions.length > 1 ? `${mentions.length} people` : `${mentions[0]}`;
  }
  return mentions;
}

function CheerCard({
  id,
  sender,
  createdAt,
  senderAvatarUrl,
  cheerContent,
  slackUrl,
  cheerMentions,
  to,
  userId,
  refetch,
  highlightingEnabled = false,
}) {
  const { data } = useResource("cheers", "emojis");
  const slackEmojis = JSON.parse(data.emoji);
  const fullEmojiList = {
    normal: emojisWithSkinTone,
    slack: slackEmojis,
  };
  const cheerMention = cheerMentions.find((item) => item.userId == userId);
  const createdAtFormatted = new Date(createdAt);
  return (
    <Frame border="neutral-300" className="w-full rounded" id={`cheer-${id}`}>
      <Stack alignment="start" className="w-full">
        <Frame className="py-4 px-6 w-full border-b border-neutral-300">
          <Flex
            className="w-full h-full"
            distribution="between"
            alignment="stretch"
          >
            <Inline space={4}>
              <AvatarLarge url={senderAvatarUrl} name={sender} />
              <Stack alignment="start">
                <Inline space={0}>
                  <TitleSemibold color={Color.Neutral800}>
                    {sender}
                  </TitleSemibold>
                  <Title color="neutral-700">&nbsp;to {toPeople(to)}</Title>
                </Inline>
                <BodySmall color="neutral-600">
                  {formatSubmittedDateWithTime(createdAtFormatted)}
                </BodySmall>
              </Stack>
            </Inline>
            <TertiaryButton
              LeftIcon={Slack}
              text="View in Slack"
              onClick={() => window.open(slackUrl)}
            />
          </Flex>
        </Frame>
        <BodyBaseline
          className="flex-1 my-5 px-6 text-neutral-700"
          style={{ hyphens: "auto" }}
          data-testid="cheer-text"
          wordBreak="words"
        >
          <BodyWithHighlights
            highlightingEnabled={highlightingEnabled}
            highlights={wrap(cheerMention?.feedbackHighlights)}
            receiverId={userId}
            source={"cheer"}
            id={cheerMention?.id}
            body={cheerContent}
            emojis={fullEmojiList}
            refetch={refetch}
          />
        </BodyBaseline>
      </Stack>
    </Frame>
  );
}

export default CheerCard;
