import React, { useState } from "react";
import { AvatarGroup } from "@ableco/baseline";
import { AvatarSmall } from "../design-system/avatar";
import { useOneOnOneSubscription } from "../../channels/one_on_one";
import { omit } from "lodash";

function OneOnOnePresenceGroup({ requestedId, currentUser }) {
  const [onlineUsers, setOnlineUsers] = useState({});

  useOneOnOneSubscription((channel) => {
    channel.onConnected(() => channel.perform("who_online"));

    channel.on("who-online", () => channel.perform("present", currentUser));

    channel.on("present", ({ user }) =>
      setOnlineUsers((users) => ({ ...users, [user.id]: user })),
    );
    channel.on("absent", ({ user }) =>
      setOnlineUsers((users) => omit(users, user.id)),
    );
  });

  const requested = onlineUsers[requestedId];

  return (
    <AvatarGroup>
      <AvatarSmall
        url={currentUser.avatarUrl}
        name={currentUser.preferredName}
        userId={currentUser.id}
      />
      {requested && (
        <AvatarSmall
          url={requested.avatarUrl}
          name={requested.preferredName}
          userId={requested.id}
        />
      )}
    </AvatarGroup>
  );
}

export default OneOnOnePresenceGroup;
