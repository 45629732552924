// Modules
import { createSelector } from "reselect";

const getRowId = (state, rowId) => rowId;

const getVisibleDetails = (state) => state.ui.visibleDetails;

export const isDetailVisible = createSelector(
  [getVisibleDetails, getRowId],
  (visibleDetails = [], rowId) => visibleDetails.includes(rowId),
);
