import React, { useState, Suspense } from "react";
import styled from "styled-components";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { subDays, addDays } from "date-fns";
import useSWR from "swr";
import { network } from "../../services/network";
import api from "../../services/api";
import SectionTitle from "../section-title";
import ProductDropdown from "./product-dropdown";
import ProjectDropdown from "./project-dropdown";
import BillableDropdown from "./billable-dropdown";
import UserDropdown from "./user-dropdown";
import StatusDropdown from "./status-dropdown";
import DateField from "./date-field";
import AllocationsGrid from "./allocations-grid/allocations-grid";
import { v4 as uuid } from "uuid";
import { colorPalette } from "../style-guide/colors";
import CoreLink from "../core-link";
import { PrimaryButton, TertiaryButton } from "../buttons/buttons";

const TOTAL_HOURS = 40;

const GridTitle = styled.div`
  color: ${colorPalette.bluegrey90};
  justify-content: flex-start;
  font-size: 14px;
`;

const GridWrapper = styled.div`
  margin-top: -18px;
`;

const FormContainer = styled.form`
  box-sizing: border-box;
  max-width: 800px;
  margin-top: 30px;
  border: 1px solid #eceef2;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 30px;
`;

export const FormSection = styled.section`
  margin-top: 20px;
`;

export const FormSectionButtons = styled(FormSection)`
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  & > * {
    margin-left: 20px;
  }
`;

const DateContainer = styled.div`
  display: flex;
`;

export const Paragraph = styled.label`
  color: #8b98ae;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
`;

const AllocationSchema = yup.object().shape({
  type: yup.string().required(),
  attributes: yup.object().shape({
    productId: yup.string().required("Select a product"),
    projectId: yup.string().required("Select a project"),
    billable: yup.string().required("Select an option"),
    userId: yup.string(),
    projectStatus: yup.string().required("Select a status"),
    estimateStartedAt: yup
      .date()
      .required("Select a start date")
      .max(yup.ref("estimateEndedAt"), "Must be before end date"),
    estimateEndedAt: yup
      .date()
      .required("Select an end date")
      .min(yup.ref("estimateStartedAt"), "Must be before start date"),
    staff: yup
      .array()
      .min(1)
      .of(
        yup.object().shape({
          projectRoleId: yup.string().required("Select a role"),
          userId: yup.string(),
          weeks: yup
            .array()
            .compact((week) => week.hours === 0)
            .min(1, "At least one week with more than 0 hours"),
        }),
      ),
  }),
});

function updateProject(projectData) {
  return api.projects.find(projectData.projectId).update({
    discoveryStart: projectData.estimateStartedAt,
    developmentEnd: projectData.estimateEndedAt,
    billable: projectData.billable,
    projectStatus: projectData.projectStatus,
    userId: projectData.userId || null,
  });
}

async function createRequestList(
  staff,
  placeholders,
  { projectId, productId },
) {
  const promiseArray = [];

  for (const member of staff) {
    const validWeeks = member.weeks.filter((week) => week.hours !== 0);
    const rowId = uuid();

    const assigneeType = member.userId ? "User" : null;
    const assigneeId = member.userId || null;

    for (const week of validWeeks) {
      promiseArray.push(
        api.projectAllocations.create({
          periodStart: week.date,
          hours: week.hours,
          projectRoleId: member.projectRoleId,
          assigneeId,
          assigneeType,
          projectId,
        }),
      );
      promiseArray.push(
        api.productAssignments.create({
          periodStart: week.date,
          timeAllocation: (week.hours * 100) / TOTAL_HOURS,
          projectRoleId: member.projectRoleId,
          assigneeId,
          assigneeType,
          projectId,
          productId,
          rowId,
        }),
      );
    }
  }

  return Promise.all(promiseArray);
}

async function handleSubmit(values, setSubmitting, placeholders) {
  const { staff, ...projectData } = values.attributes;

  try {
    await Promise.all([
      updateProject(projectData),
      createRequestList(staff, placeholders, projectData),
    ]);

    window.location.href = "/staffing";
  } catch {
    alert("Ops! There was a error");
  } finally {
    setSubmitting(false);
  }
}
// Memoizing the buttons to avoid having to click them twice.
// Otherwise the inputs' onBlur handlers trigger a validation and a form rerender
// which eliminate the original button and the first click event is lost.
// https://github.com/jaredpalmer/formik/issues/1332#issuecomment-463938746
// https://github.com/facebook/react/issues/4210
const SubmitButton = React.memo(({ isSubmitting, isValid }) => (
  <PrimaryButton text="Submit" disabled={isSubmitting || !isValid} />
));

// Memoizing the buttons to avoid having to click them twice.
// Otherwise the inputs' onBlur handlers trigger a validation and a form rerender
// which eliminate the original button and the first click event is lost.
// https://github.com/jaredpalmer/formik/issues/1332#issuecomment-463938746
// https://github.com/facebook/react/issues/4210
const CancelButton = React.memo(() => <TertiaryButton text="Cancel" />);

function AllocationsForm() {
  const [product, setProduct] = useState("");
  const {
    data: { data: placeholders },
  } = useSWR("placeholders", network, { suspense: true });

  function handleProductSelect(selection, setFieldValue) {
    setProduct(selection.meta);
    setFieldValue("attributes.projectId", "");
  }
  return (
    <Formik
      initialValues={{
        type: "allocations",
        attributes: {
          productId: "",
          projectId: "",
          projectStatus: "",
          userId: "",
          billable: "",
          estimateStartedAt: new Date(),
          estimateEndedAt: addDays(new Date(), 7),
          staff: [
            {
              projectRoleId: "",
              userId: "",
              weeks: [],
            },
          ],
        },
      }}
      validationSchema={AllocationSchema}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(values, setSubmitting, placeholders)
      }
    >
      {(properties) => {
        const {
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
          values,
          isValid,
          errors,
          touched,
        } = properties;

        return (
          <FormContainer onSubmit={handleSubmit}>
            <SectionTitle>Create a new project and budget</SectionTitle>
            <FormSection>
              <Suspense fallback={null}>
                <ProductDropdown
                  onSelect={(selectedProduct) =>
                    handleProductSelect(selectedProduct, setFieldValue)
                  }
                  onReset={() => setFieldValue("attributes.projectId", "")}
                  label={<Paragraph>Select your product *</Paragraph>}
                />
              </Suspense>
            </FormSection>
            <FormSection>
              <Suspense fallback={null}>
                <ProjectDropdown
                  product={product}
                  label={<Paragraph>Name the project *</Paragraph>}
                />
              </Suspense>
            </FormSection>
            <FormSection>
              <BillableDropdown
                label={<Paragraph>Is this project billable? *</Paragraph>}
              />
            </FormSection>
            <FormSection>
              <Suspense fallback={null}>
                <UserDropdown
                  showOnlyProjectResponsibles={true}
                  fieldName="attributes.userId"
                  label={
                    <Paragraph>
                      Who is responsible for this project? (optional)
                    </Paragraph>
                  }
                />
              </Suspense>
            </FormSection>
            <FormSection>
              <StatusDropdown label={<Paragraph>Current status *</Paragraph>} />
            </FormSection>
            <FormSection>
              <Paragraph>Project start and end date *</Paragraph>
              <DateContainer>
                <Field
                  maxDate={subDays(values.attributes.estimateEndedAt, 1)}
                  component={DateField}
                  name="attributes.estimateStartedAt"
                  placeholder="Select project start date"
                />
                <Field
                  minDate={addDays(values.attributes.estimateStartedAt, 7)}
                  component={DateField}
                  name="attributes.estimateEndedAt"
                  placeholder="Select project end date"
                />
              </DateContainer>
            </FormSection>
            <FormSection>
              <GridTitle>Building the staffing plan</GridTitle>
              <Suspense fallback={null}>
                <GridWrapper>
                  <AllocationsGrid
                    start={values.attributes.estimateStartedAt}
                    end={values.attributes.estimateEndedAt}
                    staff={values.attributes.staff}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    touched={touched}
                  />
                </GridWrapper>
              </Suspense>
            </FormSection>
            <FormSectionButtons>
              <CoreLink href="/staffing" className="hover:no-underline">
                <CancelButton />
              </CoreLink>
              <SubmitButton isValid={isValid} isSubmitting={isSubmitting} />
            </FormSectionButtons>
          </FormContainer>
        );
      }}
    </Formik>
  );
}

export default AllocationsForm;
