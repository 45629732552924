import React from "react";
import { Field } from "formik";
import FormDropdown from "../../components/form-dropdown";
import keyBy from "lodash/keyBy";

export const options = [
  { value: "billable", content: "Billable" },
  { value: "non_billable", content: "Non-billable" },
];

export const optionsByKey = keyBy(options, "value");

function BillableDropdown({ label }) {
  return (
    <Field
      name="attributes.billable"
      component={FormDropdown}
      options={options}
      searchable={false}
      label={label}
    />
  );
}

export default BillableDropdown;
