import React from "react";
import {
  Center,
  Color,
  Corners,
  Inline,
  Skeleton,
  Stack,
  Text,
} from "@ableco/baseline";
import { ErrorBoundary, SSRSuspense, useCollection } from "coreql";
import {
  FeedbackMessageCard,
  FeedbackMessageCardFallback,
} from "../../feedback/feedback-message-card";
import { wrap } from "../utils";
import FeedbackRequestPrompt from "../../feedback-request-prompt";
import {
  FeedbackReceived as FeedbackReceivedIcon,
  Frown,
} from "@baseline/icons";
import { PrimaryButton, SecondaryButton } from "../../buttons/buttons";
import { CoreBackdrop } from "../../backdrop/backdrop";
import { AnimatePresence } from "framer-motion";
import {
  BodyBaseline,
  BodySmall,
  TitleSmall,
} from "../../design-system/typography-components";

export function FeedbackReceivedFallback() {
  return (
    <Stack space={4}>
      <Stack space={1} className="w-full">
        <Skeleton
          animated
          color={Color.Neutral400}
          height={19}
          width={100}
          corners={Corners.MediumRounded}
        />
        <Skeleton
          animated
          color={Color.Neutral400}
          height={19}
          width={300}
          corners={Corners.MediumRounded}
        />
      </Stack>
      <FeedbackMessageCardFallback />
    </Stack>
  );
}

function useDismissedFeedbackRequest(
  senderId,
  weeklyReportDateStart,
  weeklyReportDateEnd,
) {
  return useCollection(
    "feedback-requests",
    {
      filters: {
        senderId: senderId,
        feedbackDismissedSince: weeklyReportDateStart,
        feedbackDismissedUntil: weeklyReportDateEnd,
      },
      included: [
        "receiver",
        "receiver.role",
        "feedbackPrompt",
        "feedbackPrompt.responsibility",
        "sender",
        "author",
      ],
      fields: {
        feedbackRequests: [
          "id",
          "requestDate",
          "body",
          "receiver",
          "sender",
          "author",
          "feedbackPrompt",
          "dismissedAt",
        ],
        users: ["fullName", "avatarUrl", "role", "id", "preferredName"],
        feedbackPrompts: [
          "prompt",
          "cadence",
          "promptType",
          "scaleType",
          "responsibility",
        ],
        responsibilities: ["title"],
        roles: ["title"],
      },
    },
    "denormalized",
  );
}

export function useReceivedFeedbackMessages(
  weeklyReportId,
  weeklyReportSubmitted,
  weeklyReportDateEnd,
  weeklyReportOwner,
) {
  const filters = weeklyReportSubmitted
    ? {
        active: true,
        receiverWeeklyReportId: weeklyReportId,
        draft: false,
      }
    : {
        active: true,
        receiverWeeklyReportId: null,
        receiverId: weeklyReportOwner.id,
        draft: false,
        submittedUntil: weeklyReportDateEnd,
      };

  return useCollection(
    "feedback-messages",
    {
      filters,
      included: [
        "sender",
        "sender.role",
        "receiver",
        "feedbackRequest",
        "feedbackRequest.author",
        "feedbackRequest.sender",
        "feedbackRequest.feedbackPrompt",
        "feedbackRequest.feedbackPrompt.responsibility",
        "receiverOneOnOneItem",
        "feedbackHighlights",
        "feedbackHighlights.skills",
        "feedbackHighlights.responsibility",
      ],
      fields: {
        feedbackMessages: [
          "id",
          "body",
          "submittedAt",
          "promptAnswer",
          "feedbackRequest",
          "sender",
          "receiver",
          "liked",
          "reviewedByManager",
          "readByReceiver",
          "receiverOneOnOneItem",
          "feedbackHighlights",
          "receiverWeeklyReportId",
        ],
        feedbackRequests: [
          "id",
          "requestDate",
          "body",
          "sender",
          "author",
          "feedbackPrompt",
          "dismissedAt",
        ],
        users: ["fullName", "avatarUrl", "role", "id", "preferredName"],
        feedbackPrompts: [
          "prompt",
          "cadence",
          "promptType",
          "scaleType",
          "responsibility",
        ],
        responsibilities: ["title"],
        roles: ["title"],
        feedbackHighlights: [
          "start",
          "end",
          "feedbackType",
          "responsibility",
          "skills",
          "note",
        ],
        skills: ["name"],
      },
    },
    "denormalized",
  );
}

function EmptyState({ forOwner, receiverName, weeklyReportOwnerId }) {
  return (
    <Center
      p={[10, 0]}
      bg={Color.Neutral200}
      corners={Corners.MediumRounded}
      className="w-full"
    >
      <Stack space={3}>
        <Center>
          <Text color={Color.Neutral500}>
            <Frown className="w-12 h-12" />
          </Text>
        </Center>
        <BodyBaseline color={Color.Neutral600} className="text-center">
          {forOwner ? "You" : receiverName} didn't get any feedback this week.
        </BodyBaseline>
        <Center>
          <FeedbackRequestButton
            asPrimary={true}
            ownerId={weeklyReportOwnerId}
          />
        </Center>
      </Stack>
    </Center>
  );
}

export function FeedbackRequestButton({ ownerId, asPrimary }) {
  const [showRequestPrompt, setShowRequestPrompt] = React.useState(false);
  return (
    <>
      {asPrimary ? (
        <PrimaryButton
          onClick={() => setShowRequestPrompt(true)}
          LeftIcon={FeedbackReceivedIcon}
          text="Request Feedback"
        />
      ) : (
        <SecondaryButton
          onClick={() => setShowRequestPrompt(true)}
          LeftIcon={FeedbackReceivedIcon}
          text="Request Feedback"
        />
      )}
      <AnimatePresence>
        {showRequestPrompt && (
          <ErrorBoundary
            key={showRequestPrompt}
            onError={() => setShowRequestPrompt(false)}
            fallback={() => null}
          >
            <SSRSuspense fallback={<CoreBackdrop />}>
              <FeedbackRequestPrompt
                ownerId={ownerId.toString()}
                onClose={() => setShowRequestPrompt(false)}
              />
            </SSRSuspense>
          </ErrorBoundary>
        )}
      </AnimatePresence>
    </>
  );
}

function DismissedFeedbackRequests({ dismissedRequests, currentUser }) {
  return (
    <>
      {dismissedRequests.map((request) => (
        <FeedbackMessageCard
          key={request.id}
          request={request}
          feedbackPrompt={request.feedbackPrompt}
          headerUser={request.receiver}
          displayRole={true}
          response={{}}
          isLikeable={false}
          isReviewable={false}
          showResponsibilityInfoTooltip={true}
          displayRequestBodyAuthorLabel={true}
          currentUser={currentUser}
        />
      ))}
    </>
  );
}

function FeedbackReceived({
  weeklyReportId,
  currentUser,
  weeklyReportDateStart,
  weeklyReportDateEnd,
  weeklyReportSubmitted,
  weeklyReportOwner,
}) {
  const { data: messages, mutate } = useReceivedFeedbackMessages(
    weeklyReportId,
    weeklyReportSubmitted,
    weeklyReportDateEnd,
    weeklyReportOwner,
  );

  const { data: dismissedRequests } = useDismissedFeedbackRequest(
    weeklyReportOwner.id,
    weeklyReportDateStart,
    weeklyReportDateEnd,
  );

  const currentUserIsWeeklyReportOwner = weeklyReportOwner.id == currentUser.id;
  const currentUserIsManager = weeklyReportOwner.managerId == currentUser.id;

  return (
    <Stack space={4}>
      <Inline distribution="between">
        <Stack space={1}>
          <TitleSmall color={Color.Neutral600}>Feedback received</TitleSmall>
          {currentUserIsWeeklyReportOwner && (
            <BodySmall color={Color.Neutral600}>
              These messages are private. Only your manager and you can see
              them.
            </BodySmall>
          )}
        </Stack>
        {(currentUserIsWeeklyReportOwner || currentUserIsManager) && (
          <FeedbackRequestButton ownerId={weeklyReportOwner.id} />
        )}
      </Inline>
      {wrap(messages).length > 0 || wrap(dismissedRequests).length > 0 ? (
        <Stack space={6}>
          {wrap(messages).map((message) => (
            <FeedbackMessageCard
              key={message.id}
              request={message.feedbackRequest}
              feedbackPrompt={message.feedbackRequest?.feedbackPrompt}
              headerUser={message.sender}
              response={message}
              oneOnOneItem={message.receiverOneOnOneItem}
              isLikeable={message.receiver.id === currentUser.id}
              isReviewable={currentUserIsManager}
              addToOneOnOneEnabled={currentUserIsManager}
              addToOneOnOneReceiver={weeklyReportOwner}
              currentUser={currentUser}
              refetch={mutate}
              displaySubmittedTime={true}
              highlightingEnabled={currentUserIsManager}
              showReceiverActions={!currentUserIsWeeklyReportOwner}
              showResponsibilityInfoTooltip={true}
              displayRequestBodyAuthorLabel={true}
            />
          ))}
          <DismissedFeedbackRequests
            dismissedRequests={wrap(dismissedRequests)}
            currentUser={currentUser}
          />
        </Stack>
      ) : (
        <EmptyState
          receiverName={weeklyReportOwner.preferredName}
          forOwner={currentUserIsWeeklyReportOwner}
          weeklyReportOwnerId={weeklyReportOwner.id}
        />
      )}
    </Stack>
  );
}

export default FeedbackReceived;
