/**
 * LEADING (line-height): TextLeading
 * None = 1
 * Tight = 1.25
 * Snug = 1.375
 * Normal = 1.5
 * Relaxed = 1.625
 * Loose = 2
 *
 * SIZE (font-size): TextSize
 * XS = 0.75rem (12px)
 * SM = 0.875rem (14px)
 * Base = 1rem (16px)
 * LG = 1.125rem (18px)
 * XL = 1.25rem (20px)
 * XL2 = 1.5rem (24px)
 * XL3 = 1.875rem (30px)
 * XL4 = 2.25rem (36px)
 * XL5 = 3rem (48px)
 * XL6 = 4rem (64px)
 *
 * TRACKING (letter-spacing): TextTracking
 * Tighter = -0.05em
 * Tight = -0.025em
 * Normal = 0
 * Wide = 0.025em
 * Wider = 0.05em
 * Widest = 0.1em
 */

import React from "react";

import {
  Text,
  TextLeading,
  TextSize,
  TextTracking,
  TextTransform,
  TextWeight,
  TextWhitespace,
} from "@ableco/baseline";

function Display({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Tight}
      size={TextSize.XL3}
      tracking={TextTracking.Tight}
      weight={TextWeight.ExtraBold}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

function Heading({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Snug}
      size={TextSize.XL2}
      tracking={TextTracking.Tight}
      weight={TextWeight.SemiBold}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

function Title({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Snug}
      size={TextSize.LG}
      weight={TextWeight.Normal}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

function TitleSemibold({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Snug}
      size={TextSize.LG}
      weight={TextWeight.SemiBold}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

function TitleSmall({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Snug}
      size={TextSize.SM}
      tracking={TextTracking.Wider}
      transform={TextTransform.Upper}
      weight={TextWeight.SemiBold}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

function BodyBaseline({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Normal}
      size={TextSize.Base}
      weight={TextWeight.Normal}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

function BodyBaselineSemibold({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Normal}
      size={TextSize.Base}
      weight={TextWeight.SemiBold}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

function BodySmall({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Snug}
      size={TextSize.SM}
      weight={TextWeight.Normal}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

function BodySmallSemibold({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Snug}
      size={TextSize.SM}
      weight={TextWeight.SemiBold}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

function Caption({ children, ...props }) {
  return (
    <Text
      leading={TextLeading.Snug}
      size={TextSize.XS}
      tracking={TextTracking.Wide}
      weight={TextWeight.Normal}
      whitespace={TextWhitespace.PreWrap}
      {...props}
    >
      {children}
    </Text>
  );
}

export {
  Display,
  Heading,
  Title,
  TitleSemibold,
  TitleSmall,
  BodyBaseline,
  BodyBaselineSemibold,
  BodySmall,
  BodySmallSemibold,
  Caption,
};
