// Modules
import { combineReducers } from "redux";
// Reducers
import filters from "./filters";
import visibleDetails from "./visibleDetails";

export default combineReducers({
  filters,
  visibleDetails,
});
