// Modules
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// Styles
import Styles from "utilization/components/Table/Table.module.scss";
import { ValueText } from "./ValueText";

ValueRows.propTypes = {
  currency: PropTypes.bool,
  detailRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    }).isRequired,
  ).isRequired,
  summaryValues: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  toggleDetailVisibility: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};
function ValueRows({
  currency,
  detailRows,
  summaryValues,
  toggleDetailVisibility,
  dataTestId,
}) {
  const containerProps = {
    className: clsx(Styles.row, Styles.clickable),
    onClick: toggleDetailVisibility,
  };

  const formatOptions = currency
    ? {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }
    : {};

  return (
    <>
      <div {...containerProps} data-test-id={dataTestId}>
        {summaryValues.map((value, i) => (
          <div key={i} className={Styles.columnWithValue}>
            <ValueText>
              {value.toLocaleString("en-US", formatOptions || {})}
            </ValueText>
          </div>
        ))}
      </div>

      <DetailRows formatOptions={formatOptions} rows={detailRows} />
    </>
  );
}

function DetailRows({ formatOptions, rows }) {
  return rows.map((row) => (
    <div key={row.id} className={Styles.row}>
      <DetailValues formatOptions={formatOptions} values={row.values} />
    </div>
  ));
}

function DetailValues({ formatOptions, values }) {
  return values.map((value, i) => (
    <div key={i} className={Styles.columnWithValue}>
      <ValueText>{value.toLocaleString("en-US", formatOptions)}</ValueText>
    </div>
  ));
}

export default ValueRows;
