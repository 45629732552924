import React from "react";

import {
  Center,
  Color,
  Flex,
  FlexAlignment,
  FlexDistribution,
  Frame,
  Inline,
  Stack,
} from "@ableco/baseline";
import {
  CaretDownOutline,
  CaretRightOutline,
  CheckCircleOutline,
  Delete,
  Edit,
  FeedbackReceived,
  Note,
} from "@baseline/icons";

import {
  Display,
  Heading,
  Title,
  TitleSemibold,
  TitleSmall,
  BodyBaseline,
  BodyBaselineSemibold,
  BodySmall,
  BodySmallSemibold,
  Caption,
} from "./typography-components";
import Tag from "../tag";
import { useCoreToast } from "../toast/toast";
import {
  AlertButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../buttons/buttons";
import { NavItem } from "../tab";
import PageLayout from "../layout/page-layout";
import BackButton from "../layout/back-button";
import MonthPicker from "../data-entry/month-picker/daterange-picker";
import useCurrentUser from "../../hooks/use-current-user";
import Accordion from "../accordion";
import TextInput, { TextArea } from "../text-input";
import { MarkdownEditor } from "../markdown/markdown-editor";
import Select from "../data-entry/select";
import {
  AvatarXS,
  AvatarSmall,
  AvatarMedium,
  AvatarLarge,
  AvatarXL,
} from "./avatar";
import { SegmentedControl } from "../data-entry/segmented-control";
import { CoreTooltip } from "../core-tooltip";
import AutosaveTextInput, {
  LoadingStateIcon,
} from "../data-entry/autosave-text-input";
import { format } from "date-fns";
import { useAnswersGroup } from "../weekly-report/accomplishments";
import { isEmpty } from "lodash";

const scaleLabelsAnother = {
  default: [
    { value: 0, label: "Label" },
    { value: 1, label: "Label" },
    { value: 2, label: "Label" },
    { value: 3, label: "Label" },
    { value: 4, label: "Label" },
  ],
  scale: [
    { value: 0, label: "1" },
    { value: 1, label: "2" },
    { value: 2, label: "3" },
    { value: 3, label: "4" },
    { value: 4, label: "5" },
  ],
  temperature: [
    { value: 0, label: "Awful" },
    { value: 1, label: "Bad" },
    { value: 2, label: "Neutral" },
    { value: 3, label: "Good" },
    { value: 4, label: "Amazing" },
  ],
};

const data = [
  { id: 1, text: "item 1", data: { text: "item 1" } },
  { id: 2, text: "item 2", data: { text: "item 2" } },
];

function ToastButtonsSection() {
  const { successToast, warningToast, errorToast } = useCoreToast();

  function showSuccessToast() {
    successToast("This is an example of a success message");
  }
  function showWarningToast() {
    warningToast("This is an example of a warning message");
  }
  function showErrorToast() {
    errorToast("This is an example of an error message");
  }

  return (
    <Inline distribution={FlexDistribution.Between}>
      <PrimaryButton text="Success toast" onClick={showSuccessToast} />
      <PrimaryButton text="Warning toast" onClick={showWarningToast} />
      <PrimaryButton text="Alert toast" onClick={showErrorToast} />
    </Inline>
  );
}

function Circle({ color }) {
  return (
    <Frame
      bg={color || "transparent"}
      border={color || "transparent"}
      corners="full"
      className="h-4 w-4"
    />
  );
}

function TooltipsSection() {
  return (
    <Inline distribution="between">
      <Stack alignment="start" space={2}>
        <TitleSmall as="h3" color={Color.Neutral600}>
          With a title
        </TitleSmall>
        <CoreTooltip
          title="This tooltip has a title"
          label="The content is left-aligned"
        >
          <Frame>Content With Tooltip</Frame>
        </CoreTooltip>
      </Stack>
      <Stack alignment="start" space={2}>
        <TitleSmall as="h3" color={Color.Neutral600}>
          Content only
        </TitleSmall>
        <CoreTooltip label="This text is centered">
          <Frame>Content With Tooltip</Frame>
        </CoreTooltip>
      </Stack>
      <Stack alignment="start" space={2}>
        <TitleSmall as="h3" color={Color.Neutral600}>
          Custom Content
        </TitleSmall>
        <CoreTooltip
          label={
            <Stack className="text-left" space={2}>
              <Stack>
                <BodySmallSemibold color="neutral-300">
                  Heading
                </BodySmallSemibold>
                <BodySmall color="neutral-300">
                  This tooltip has custom content
                </BodySmall>
              </Stack>
              <Stack>
                <BodySmallSemibold color="neutral-300">
                  Another Heading
                </BodySmallSemibold>
                <BodySmall color="neutral-300">
                  This tooltip has custom content
                </BodySmall>
              </Stack>
            </Stack>
          }
        >
          <Frame>Content With Tooltip</Frame>
        </CoreTooltip>
      </Stack>
    </Inline>
  );
}

function AutosaveSection() {
  const { successToast } = useCoreToast();
  async function mockSave() {
    await new Promise((r) => setTimeout(() => r(1), 750));
    if (Math.random() > 0.5) {
      throw new Error("Random mock error");
    }
    successToast("Saved!");
  }

  return (
    <>
      <Stack space={2}>
        <TitleSmall as="h3" color={Color.Neutral600}>
          With default text loading state
        </TitleSmall>
        <AutosaveTextInput onSave={mockSave}></AutosaveTextInput>
      </Stack>
      <Stack space={2}>
        <TitleSmall as="h3" color={Color.Neutral600}>
          With icon displaying loading state
        </TitleSmall>
        <AutosaveTextInput onSave={mockSave} getSaveStatus={LoadingStateIcon}>
          {(_, props, status) => (
            <Frame className="relative">
              <TextArea {...props} />
              <Frame className="absolute left-100 bottom-0 mb-2 ml-2">
                {status}
              </Frame>
            </Frame>
          )}
        </AutosaveTextInput>
      </Stack>
      <Stack space={2}>
        <TitleSmall as="h3" color={Color.Neutral600}>
          With custom indicator and custom input
        </TitleSmall>
        <AutosaveTextInput
          onSave={mockSave}
          getSaveStatus={({ stale, loading, error, lastSave }) => {
            if (loading) return "Saving...";
            if (stale) return "Dirty";
            if (error) return "Error!";
            return lastSave
              ? `Saved at: ${format(lastSave, "dd-MM-yyyy hh:mm aa")}`
              : null;
          }}
        >
          {(_, props, status) => (
            <Stack space={2}>
              <TextInput {...props} />
              <BodySmall color="neutral-600">{status}</BodySmall>
            </Stack>
          )}
        </AutosaveTextInput>
      </Stack>
    </>
  );
}

export default function DesignSystem() {
  const backButton = <BackButton text="Back button" href="/dashboard" />;
  const [showBackButton, setShowBackButton] = React.useState(false);
  const [showBasicLayout, setShowBasicLayout] = React.useState(false);
  const [startMonth, setStartMonth] = React.useState(false);
  const [endMonth, setEndMonth] = React.useState(false);
  const [showAvatar, setShowAvatar] = React.useState(false);
  const [showCustomCircle, setShowCustomCircle] = React.useState(false);
  const [selectDefault, setSelectDefault] = React.useState("7");
  const [selectMultiple, setSelectMultiple] = React.useState(["5", "6"]);
  const [selectWithComponent, setSelectWithComponent] = React.useState("7");
  const { data: currentUser } = useCurrentUser();
  const { successToast } = useCoreToast();
  const { answers, addAnswer, removeAnswer } = useAnswersGroup(data);

  const customCircle = (
    <Flex
      direction="vertical"
      alignment="center"
      distribution="center"
      className="border rounded-full self-start h-16 w-16 flex-shrink-0"
    />
  );
  return (
    <PageLayout
      title="Page Title"
      subTitle={showBasicLayout ? null : "subtitle goes here"}
      backButton={showBackButton && backButton}
      avatarUser={showAvatar && currentUser}
      customCircle={showCustomCircle && customCircle}
    >
      <Center>
        <Stack space={20} className="p-8 max-w-screen-lg">
          <Stack space={8}>
            <Stack space={2}>
              <Display as="h2" color={Color.Neutral800}>
                Layout
              </Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Inline space={2}>
              <SecondaryButton
                text="Basic Layout"
                onClick={() => {
                  setShowBasicLayout(true);
                  setShowAvatar(false);
                  setShowCustomCircle(false);
                }}
              />
              <SecondaryButton
                text="Standard Layout"
                onClick={() => {
                  setShowBasicLayout(false);
                  setShowAvatar(false);
                  setShowCustomCircle(false);
                }}
              />

              <SecondaryButton
                text="Full Layout"
                onClick={() => {
                  setShowBasicLayout(false);
                  setShowAvatar(true);
                  setShowCustomCircle(false);
                }}
              />
              <SecondaryButton
                text="Layout with Custom circle"
                onClick={() => {
                  setShowBasicLayout(false);
                  setShowAvatar(false);
                  setShowCustomCircle(true);
                }}
              />
              <SecondaryButton
                text="Toggle Back Button"
                onClick={() => setShowBackButton((state) => !state)}
              />
            </Inline>

            <Stack space={2}>
              <Display as="h2" color={Color.Neutral800}>
                Typography
              </Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Display
              </TitleSmall>
              <Display>The quick brown fox jumps over the lazy dog</Display>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Heading
              </TitleSmall>
              <Heading>The quick brown fox jumps over the lazy dog</Heading>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Title
              </TitleSmall>
              <Title>The quick brown fox jumps over the lazy dog</Title>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Title Semibold
              </TitleSmall>
              <TitleSemibold>
                The quick brown fox jumps over the lazy dog
              </TitleSemibold>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Title Small
              </TitleSmall>
              <TitleSmall>
                The quick brown fox jumps over the lazy dog
              </TitleSmall>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Body Baseline
              </TitleSmall>
              <BodyBaseline>
                The quick brown fox jumps over the lazy dog
              </BodyBaseline>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Body Baseline Semibold
              </TitleSmall>
              <BodyBaselineSemibold>
                The quick brown fox jumps over the lazy dog
              </BodyBaselineSemibold>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Body Small
              </TitleSmall>
              <BodySmall>The quick brown fox jumps over the lazy dog</BodySmall>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Body Small Semibold
              </TitleSmall>
              <BodySmallSemibold>
                The quick brown fox jumps over the lazy dog
              </BodySmallSemibold>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Caption
              </TitleSmall>
              <Caption>The quick brown fox jumps over the lazy dog</Caption>
            </Stack>
          </Stack>

          <Stack space={8}>
            <Stack space={2}>
              <Display as="h2" color={Color.Neutral800}>
                Toast
              </Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <ToastButtonsSection />
          </Stack>

          <Stack space={8}>
            <Stack space={2}>
              <Display as="h2" color={Color.Neutral800}>
                Tooltips
              </Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <TooltipsSection />
          </Stack>

          <Stack space={8}>
            <Stack space={2}>
              <Display as="h2" color={Color.Neutral800}>
                Tabs
              </Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Default Tab
                </TitleSmall>
                <NavItem label="Tab" href="/" />
              </Stack>

              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Active Tab
                </TitleSmall>
                <NavItem isActive={true} label="Active Tab" href="/" />
              </Stack>
            </Inline>

            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Icon Left
                </TitleSmall>
                <NavItem icon={<Note />} label="Tab" href="/" />
              </Stack>

              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Icon Right
                </TitleSmall>
                <NavItem
                  icon={<Note />}
                  iconPosition="right"
                  label="Tab"
                  href="/"
                />
              </Stack>
            </Inline>

            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Default Dropdown
                </TitleSmall>
                <NavItem
                  icon={<CaretDownOutline />}
                  iconPosition="right"
                  label="Dropdown"
                  href="/"
                >
                  <Frame as="li" p={[2, 4]}>
                    <BodyBaseline color={Color.Neutral700}>
                      Submenu Item 1
                    </BodyBaseline>
                  </Frame>
                  <Frame as="li" p={[2, 4]}>
                    <BodyBaseline color={Color.Neutral700}>
                      Submenu Item 2
                    </BodyBaseline>
                  </Frame>
                </NavItem>
              </Stack>

              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Active Dropdown
                </TitleSmall>
                <NavItem
                  icon={<CaretDownOutline />}
                  iconPosition="right"
                  isActive={true}
                  label="Active Dropdown"
                  href="/"
                >
                  <Frame as="li" p={[2, 4]}>
                    <BodyBaseline color={Color.Neutral700}>
                      Submenu Item 1
                    </BodyBaseline>
                  </Frame>
                  <Frame as="li" p={[2, 4]}>
                    <BodyBaseline color={Color.Neutral700}>
                      Submenu Item 2
                    </BodyBaseline>
                  </Frame>
                </NavItem>
              </Stack>
            </Inline>

            <Stack space={2}>
              <TitleSmall as="h4" color={Color.Neutral600}>
                Example
              </TitleSmall>
              <Inline p={[0, 2]} style={{ borderBottom: "1px solid #d9d9d9" }}>
                <NavItem label="Defaut" href="/" />
                <NavItem isActive={true} label="Active" href="/" />
                <NavItem icon={<Note />} label="Left Icon" href="/" />
                <NavItem
                  icon={<Note />}
                  iconPosition="right"
                  isActive={true}
                  label="Active Right Icon"
                  href="/"
                />
                <NavItem
                  icon={<CaretDownOutline />}
                  iconPosition="right"
                  label="Dropdown"
                  href="/"
                >
                  <Frame as="li" p={[2, 4]}>
                    <BodyBaseline color={Color.Neutral700}>
                      Submenu Item 1
                    </BodyBaseline>
                  </Frame>
                  <Frame as="li" p={[2, 4]}>
                    <BodyBaseline color={Color.Neutral700}>
                      Submenu Item 2
                    </BodyBaseline>
                  </Frame>
                </NavItem>
                <NavItem
                  icon={<CaretDownOutline />}
                  iconPosition="right"
                  isActive={true}
                  label="Active Dropdown"
                  href="/"
                >
                  <Frame as="li" p={[2, 4]}>
                    <BodyBaseline color={Color.Neutral700}>
                      Submenu Item 1
                    </BodyBaseline>
                  </Frame>
                  <Frame as="li" p={[2, 4]}>
                    <BodyBaseline color={Color.Neutral700}>
                      Submenu Item 2
                    </BodyBaseline>
                  </Frame>
                </NavItem>
              </Inline>
            </Stack>
          </Stack>

          <Stack space={8}>
            <Stack space={2}>
              <Display color={Color.Neutral800}>Buttons</Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Primary
                </TitleSmall>
                <PrimaryButton text="Button Text" />
                <PrimaryButton
                  text="Button Text"
                  LeftIcon={CheckCircleOutline}
                />
                <PrimaryButton
                  text="Button Text"
                  RightIcon={CheckCircleOutline}
                />

                <PrimaryButton text="Primary Small" small />
                <PrimaryButton
                  text="Primary Small"
                  small
                  LeftIcon={CheckCircleOutline}
                />
                <PrimaryButton
                  text="Primary Small"
                  small
                  RightIcon={CheckCircleOutline}
                />
              </Stack>

              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Secondary
                </TitleSmall>
                <SecondaryButton text="Button Text" />
                <SecondaryButton
                  text="Button Text"
                  LeftIcon={CheckCircleOutline}
                />
                <SecondaryButton
                  text="Button Text"
                  RightIcon={CheckCircleOutline}
                />
                <SecondaryButton text="Secondary Small" small />
                <SecondaryButton
                  text="Secondary Small"
                  small
                  LeftIcon={CheckCircleOutline}
                />
                <SecondaryButton
                  text="Secondary Small"
                  small
                  RightIcon={CheckCircleOutline}
                />
              </Stack>
            </Inline>

            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Tertiary
                </TitleSmall>
                <TertiaryButton text="Button Text" />
                <TertiaryButton
                  text="Button Text"
                  LeftIcon={CheckCircleOutline}
                />
                <TertiaryButton
                  text="Button Text"
                  RightIcon={CheckCircleOutline}
                />
                <TertiaryButton text="Tertiary Small" small />
                <TertiaryButton
                  text="Tertiary Small"
                  small
                  LeftIcon={CheckCircleOutline}
                />
                <TertiaryButton
                  text="Tertiary Small"
                  small
                  RightIcon={CheckCircleOutline}
                />
              </Stack>

              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Disabled
                </TitleSmall>

                <PrimaryButton
                  disabled
                  text="Primary Button"
                  LeftIcon={CheckCircleOutline}
                />
                <SecondaryButton
                  disabled
                  text="Secondary Text"
                  LeftIcon={CheckCircleOutline}
                />
                <TertiaryButton
                  disabled
                  text="Tertiary Text"
                  LeftIcon={CheckCircleOutline}
                />
                <AlertButton disabled text="Alert Text" />
              </Stack>
            </Inline>

            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/3"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Left Icon
                </TitleSmall>
                <SecondaryButton
                  LeftIcon={FeedbackReceived}
                  text="Button Text"
                />
              </Stack>

              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/3"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Alert
                </TitleSmall>
                <AlertButton text="Button Text" />
              </Stack>

              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/3"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Right Icon
                </TitleSmall>
                <SecondaryButton
                  RightIcon={CaretRightOutline}
                  text="Button Text"
                />
              </Stack>
            </Inline>
          </Stack>

          <Stack space={8}>
            <Stack space={2}>
              <Display color={Color.Neutral800}>Segmented Control</Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Inline distribution={FlexDistribution.Between}>
              <Stack space={6} className="w-1/3">
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Default
                </TitleSmall>

                <SegmentedControl
                  values={scaleLabelsAnother["default"]}
                  disabled={false}
                />

                <TitleSmall as="h3" color={Color.Neutral600}>
                  Disable
                </TitleSmall>

                <SegmentedControl
                  values={scaleLabelsAnother["default"]}
                  disabled={true}
                />
              </Stack>

              <Stack space={6} className="w-1/3">
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Default Active
                </TitleSmall>

                <SegmentedControl
                  values={scaleLabelsAnother["default"]}
                  defaultValue={3}
                />

                <TitleSmall as="h3" color={Color.Neutral600}>
                  Disable Active
                </TitleSmall>

                <SegmentedControl
                  values={scaleLabelsAnother["default"]}
                  disabled={true}
                  defaultValue={3}
                />
              </Stack>
            </Inline>

            <Stack>
              <TitleSmall as="h3" color={Color.Neutral600} className="mb-4">
                Scale Question
              </TitleSmall>
              <TitleSemibold className="mb-4">
                How did you feel at work this week?
              </TitleSemibold>
              <SegmentedControl values={scaleLabelsAnother["scale"]} />
            </Stack>

            <Stack>
              <TitleSmall as="h3" color={Color.Neutral600} className="mb-4">
                Built-in labels
              </TitleSmall>
              <TitleSemibold className="mb-4">
                How did you feel at work this week?
              </TitleSemibold>
              <SegmentedControl values={scaleLabelsAnother["temperature"]} />
            </Stack>
          </Stack>

          <Stack space={8}>
            <Stack space={2}>
              <Display color={Color.Neutral800}>Tags</Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Inline alignment={FlexAlignment.Start}>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Color Variation
                </TitleSmall>
                <Tag text="Default" />
                <Tag text="Success" type="success" />
                <Tag text="Opportunity" type="opportunity" />
                <Tag text="General" type="general" />
              </Stack>

              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  With Icon
                </TitleSmall>
                <Tag
                  text="Success with left icon"
                  type="success"
                  LeftIcon={CheckCircleOutline}
                />
                <Tag
                  text="Opportunity with right icon"
                  type="opportunity"
                  RightIcon={CheckCircleOutline}
                />
              </Stack>
            </Inline>
          </Stack>

          <Stack space={8}>
            <Stack space={2}>
              <Display color={Color.Neutral800}>Month Picker</Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Default
                </TitleSmall>
                <MonthPicker
                  startDate={startMonth}
                  setStartDate={setStartMonth}
                  endDate={endMonth}
                  setEndDate={setEndMonth}
                />
              </Stack>
            </Inline>
          </Stack>
          <Stack space={8}>
            <Stack space={2}>
              <Display color={Color.Neutral800}>Accordion</Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Basic
              </TitleSmall>
              <Accordion title="Accordion title 1" state="none">
                Accordion content 1
              </Accordion>
              <Accordion title="Accordion title 2" state="none">
                Accordion content 2
              </Accordion>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Small Variant
              </TitleSmall>
              <Accordion title="Accordion title 1" state="none" variant="small">
                Accordion content 1
              </Accordion>
              <Accordion title="Accordion title 2" state="none" variant="small">
                Accordion content 2
              </Accordion>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Open by default
              </TitleSmall>
              <Accordion title="Open Accordion 1" state="none" isOpen>
                Open accordion content 1
              </Accordion>
              <Accordion title="Open Accordion 2" state="none" isOpen>
                Open accordion content 2
              </Accordion>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                With Progress Indicators
              </TitleSmall>
              <Accordion title="Ready" state="ready">
                Ready
              </Accordion>
              <Accordion title="In progress" state="progress">
                In progress
              </Accordion>
              <Accordion title="Not started" state="empty">
                Not started
              </Accordion>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                With a custom label
              </TitleSmall>
              <Accordion
                title="With a text label"
                stateLabel="Text Label"
                state="none"
              >
                content
              </Accordion>
              <Accordion
                title="With a component abel"
                stateLabel={
                  <PrimaryButton
                    text="Component Label"
                    onClick={(e) => {
                      // Prevents the accordion from opening/closing
                      e.stopPropagation();
                      successToast("Clicked on the custom label");
                    }}
                  />
                }
                state="none"
              >
                content
              </Accordion>
              <Accordion
                title="With a custom label and a progress indicator"
                state="ready"
                stateLabel={
                  <PrimaryButton
                    text="Component Label"
                    onClick={(e) => {
                      // Prevents the accordion from opening/closing
                      e.stopPropagation();
                      successToast("Clicked on the custom label");
                    }}
                  />
                }
              >
                content
              </Accordion>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                With a callback on expansion and collapse
              </TitleSmall>
              <Accordion
                title="With a callback"
                onActivate={() => successToast("Callback called")}
                state="none"
              >
                content
              </Accordion>
            </Stack>
          </Stack>

          <Stack space={8}>
            <Stack space={2}>
              <Display color={Color.Neutral800}>Autosave input pattern</Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>
            <BodyBaseline>
              For illustrative purposes, autosaving will fail 50% of the time in
              this page.
            </BodyBaseline>
            <AutosaveSection />
          </Stack>
          <Stack space={8}>
            <Stack space={2}>
              <Display color={Color.Neutral800}>Text Input</Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Basic
              </TitleSmall>
              <TextInput />
            </Stack>
            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                With Placeholder
              </TitleSmall>
              <TextInput placeholder="Write your text here" />
            </Stack>
            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Disabled
              </TitleSmall>
              <TextInput disabled placeholder="Disabled placeholder text" />
            </Stack>
            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                With Text
              </TitleSmall>
              <TextInput value="Lorem ipsum dolor sit amet." />
            </Stack>
            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                With Overflowing Text
              </TitleSmall>
              <TextInput value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate sapien nec sagittis aliquam. Faucibus nisl tincidunt eget nullam non nisi. Id faucibus nisl tincidunt eget nullam non nisi est si" />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                With Markdown
              </TitleSmall>
              <MarkdownEditor
                text={"Lorem ipsum dolor sit amet"}
                placeholder="Add a comment..."
              />
            </Stack>
            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                With Markdown and Icons
              </TitleSmall>
              <MarkdownEditor
                text={"Lorem ipsum dolor sit amet"}
                placeholder="Add a comment..."
                icons={
                  <>
                    <Edit className="h-4 w-4 text-neutral-600 hover:text-neutral-800 transition-colors duration-300 ease-in-out" />
                    <Delete className="h-4 w-4 text-neutral-600 hover:text-neutral-800 transition-colors duration-300 ease-in-out" />
                  </>
                }
              />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Multiline
              </TitleSmall>
              <TextArea placeholder="Placeholder text" />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Multiline Disabled
              </TitleSmall>
              <TextArea disabled placeholder="Disabled placeholder text" />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Multiline with autosizing
              </TitleSmall>
              <TextArea value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate sapien nec sagittis aliquam. Faucibus nisl tincidunt eget nullam non nisi. Id faucibus nisl tincidunt eget nullam non nisi est si" />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Multiline without autosizing
              </TitleSmall>
              <TextArea
                autosize={false}
                rows={3}
                value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate sapien nec sagittis aliquam. Faucibus nisl tincidunt eget nullam non nisi. Id faucibus nisl tincidunt eget nullam non nisi est si"
              />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Multiline with autosizing with a large initial value
              </TitleSmall>
              <TextArea
                value={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sagittis nisl rhoncus mattis rhoncus. Dictum fusce ut placerat orci nulla. Praesent elementum facilisis leo vel. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla. At augue eget arcu dictum varius duis at consectetur lorem. Sed augue lacus viverra vitae congue eu consequat. Id aliquet risus feugiat in ante. At consectetur lorem donec massa sapien faucibus et molestie ac. Nunc eget lorem dolor sed viverra ipsum. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Pharetra convallis posuere morbi leo urna. Velit aliquet sagittis id consectetur purus ut faucibus pulvinar. Euismod quis viverra nibh cras pulvinar mattis nunc sed. Habitant morbi tristique senectus et netus et malesuada fames ac. Etiam dignissim diam quis enim lobortis. Cras semper auctor neque vitae tempus quam pellentesque nec.\n\nLorem sed risus ultricies tristique nulla aliquet. Facilisis magna etiam tempor orci eu lobortis elementum. Porttitor massa id neque aliquam vestibulum morbi blandit cursus risus. Nec dui nunc mattis enim. Lectus nulla at volutpat diam ut. Cras fermentum odio eu feugiat pretium nibh. Libero nunc consequat interdum varius sit amet mattis vulputate enim. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam purus. Volutpat diam ut venenatis tellus in metus vulputate eu. Non sodales neque sodales ut."
                }
              />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Multiline with Markdown
              </TitleSmall>
              <MarkdownEditor
                type="multiline"
                autosize={88}
                text={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate sapien nec sagittis aliquam. Faucibus nisl tincidunt eget nullam non nisi. Id faucibus nisl tincidunt eget nullam non nisi est si"
                }
                placeholder="Add a comment..."
              />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Multiline with autosizing with a large initial value
              </TitleSmall>
              <MarkdownEditor
                type="multiline"
                autosize={88}
                text={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sagittis nisl rhoncus mattis rhoncus. Dictum fusce ut placerat orci nulla. Praesent elementum facilisis leo vel. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla. At augue eget arcu dictum varius duis at consectetur lorem. Sed augue lacus viverra vitae congue eu consequat. Id aliquet risus feugiat in ante. At consectetur lorem donec massa sapien faucibus et molestie ac. Nunc eget lorem dolor sed viverra ipsum. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Pharetra convallis posuere morbi leo urna. Velit aliquet sagittis id consectetur purus ut faucibus pulvinar. Euismod quis viverra nibh cras pulvinar mattis nunc sed. Habitant morbi tristique senectus et netus et malesuada fames ac. Etiam dignissim diam quis enim lobortis. Cras semper auctor neque vitae tempus quam pellentesque nec.\n\nLorem sed risus ultricies tristique nulla aliquet. Facilisis magna etiam tempor orci eu lobortis elementum. Porttitor massa id neque aliquam vestibulum morbi blandit cursus risus. Nec dui nunc mattis enim. Lectus nulla at volutpat diam ut. Cras fermentum odio eu feugiat pretium nibh. Libero nunc consequat interdum varius sit amet mattis vulputate enim. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam purus. Volutpat diam ut venenatis tellus in metus vulputate eu. Non sodales neque sodales ut."
                }
                placeholder="Add a comment..."
              />
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Input List
              </TitleSmall>
              {answers.map((answer, i) => {
                const isLast = i === answers.length - 1;
                function onChange(text) {
                  if (!isEmpty(text) && isLast) addAnswer({});
                }
                return (
                  <TextInput
                    key={answer.__key}
                    id={answer.id}
                    onChange={onChange}
                    onBlur={({ target }) => {
                      if (isEmpty(target.value.trim()) && !isLast)
                        removeAnswer(answer);
                    }}
                    value={answer.id && answer.data.text}
                  />
                );
              })}
            </Stack>
          </Stack>

          <Stack space={8}>
            <Stack space={2}>
              <Display color={Color.Neutral800}>Select</Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>
            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Default
                </TitleSmall>
                <Select
                  options={[
                    {
                      id: "5",
                      label: "text 1",
                    },
                    {
                      id: "6",
                      label: "text 2",
                    },
                    {
                      id: "7",
                      label: "text 3",
                    },
                  ].map((option) => ({
                    value: option.id,
                    label: option.label,
                  }))}
                  value={selectDefault}
                  onChange={setSelectDefault}
                />
              </Stack>
            </Inline>

            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Multiple
                </TitleSmall>
                <Select
                  multi
                  options={[
                    {
                      id: "5",
                      label: "text 1",
                    },
                    {
                      id: "6",
                      label: "text 2",
                    },
                    {
                      id: "7",
                      label: "text 3",
                    },
                  ].map(({ id, label }) => ({
                    value: id,
                    label: label,
                  }))}
                  value={selectMultiple}
                  onChange={setSelectMultiple}
                  placeholder={"Select your options"}
                />
              </Stack>
            </Inline>

            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  With Component
                </TitleSmall>
                <Select
                  options={[
                    {
                      id: "5",
                      label: "text 1",
                      icon: <Circle color="alert" />,
                    },
                    {
                      id: "6",
                      label: "text 2",
                      icon: <Circle color="warning" />,
                    },
                    {
                      id: "7",
                      label: "text 3",
                      icon: <Circle color="success" />,
                    },
                  ].map((option) => ({
                    value: option.id,
                    label: option.label,
                    icon: option.icon,
                  }))}
                  value={selectWithComponent}
                  onChange={setSelectWithComponent}
                  hasIcons={true}
                />
              </Stack>
            </Inline>
            <Inline>
              <Stack
                alignment={FlexAlignment.Start}
                className="w-1/2"
                space={2}
              >
                <TitleSmall as="h3" color={Color.Neutral600}>
                  disabled
                </TitleSmall>
                <Select
                  disabled={true}
                  options={[{ value: "", label: "" }]}
                  placeholder={"No values found"}
                />
              </Stack>
            </Inline>
          </Stack>
          <Stack space={8}>
            <Stack space={2}>
              <Display as="h2" color={Color.Neutral800}>
                Avatars
              </Display>
              <Frame as="hr" border={Color.Neutral400} />
            </Stack>

            <Stack space={4}>
              <Stack space={2}>
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Extra Small
                </TitleSmall>
                <AvatarXS
                  url={currentUser.avatarUrl}
                  name={currentUser.fullName}
                  userId={currentUser.id}
                />
              </Stack>
              <Stack space={2}>
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Small
                </TitleSmall>
                <AvatarSmall
                  url={currentUser.avatarUrl}
                  name={currentUser.fullName}
                  userId={currentUser.id}
                />
              </Stack>
              <Stack space={2}>
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Medium
                </TitleSmall>
                <AvatarMedium
                  url={currentUser.avatarUrl}
                  name={currentUser.fullName}
                  userId={currentUser.id}
                />
              </Stack>

              <Stack space={2}>
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Large
                </TitleSmall>
                <AvatarLarge
                  url={currentUser.avatarUrl}
                  name={currentUser.fullName}
                  userId={currentUser.id}
                />
              </Stack>
              <Stack space={2}>
                <TitleSmall as="h3" color={Color.Neutral600}>
                  Extra Large
                </TitleSmall>
                <AvatarXL
                  url={currentUser.avatarUrl}
                  name={currentUser.fullName}
                  userId={currentUser.id}
                />
              </Stack>

              <Stack space={2}>
                <TitleSmall as="h3" color={Color.Neutral600}>
                  With Tooltip
                </TitleSmall>
                <AvatarMedium
                  url={currentUser.avatarUrl}
                  name={currentUser.fullName}
                  userId={currentUser.id}
                  withTooltip
                />
              </Stack>
            </Stack>

            <Stack space={2}>
              <TitleSmall as="h3" color={Color.Neutral600}>
                Loading state/Fallback
              </TitleSmall>
              <AvatarMedium
                url={""}
                name={currentUser.fullName}
                userId={currentUser.id}
              />
            </Stack>
          </Stack>
        </Stack>
      </Center>
    </PageLayout>
  );
}
