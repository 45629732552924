// Modules
import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import createRootReducer from "budget/reducers";
import coreApiMiddleware from "core-redux/middleware/api";
import coreEntitiesMiddleware from "core-redux/middleware/entities";
import Core from "core";
import queryString from "query-string";
// Components
import Budget from "budget/components/App";
import { api, denormalize } from "coreql";
import { Inline, Stack } from "@ableco/baseline";
import { NavItem } from "../components/tab";
import PageLayout from "../components/layout/page-layout";
App.propTypes = {
  projectIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

function App({ projectIds }) {
  const { filter } = queryString.parse(location.search);
  const budgetContainers = projectIds.map((projectId) => (
    <BudgetReduxWrapperContainer
      key={projectId}
      apiToken={window.API_TOKEN}
      projectId={projectId}
    />
  ));

  return (
    <div style={{ maxWidth: "1040px" }}>
      <Stack space={8}>
        <Filters activeFilter={filter} />
        <>{budgetContainers}</>
      </Stack>
    </div>
  );
}

function Filters({ activeFilter }) {
  return (
    <Inline p={[0, 2]} className="border-b border-neutral-400">
      <NavItem
        label="All Active Projects"
        href="?"
        isActive={!activeFilter}
        isExternalLink
      />
      <NavItem
        label="Unbudgeted Active Projects"
        href="?filter=unbudgeted"
        isActive={activeFilter === "unbudgeted"}
        isExternalLink
      />
      <NavItem
        label="Budgeted Active Projects"
        href="?filter=budgeted"
        isActive={activeFilter === "budgeted"}
        isExternalLink
      />
    </Inline>
  );
}

Filters.propTypes = {
  activeFilter: PropTypes.string,
};

function BudgetReduxWrapperContainer({ apiToken, projectId }) {
  const initialState = {
    projectId,
  };

  const coreClient = new Core(apiToken);

  const middleware = [
    thunkMiddleware,
    coreApiMiddleware(coreClient),
    coreEntitiesMiddleware,
  ];

  const middlewareEnhancer = applyMiddleware(...middleware);

  const store = createStore(
    createRootReducer(history),
    initialState,
    middlewareEnhancer,
  );

  return <BudgetReduxWrapper store={store} />;
}

BudgetReduxWrapperContainer.propTypes = {
  apiToken: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
};

function BudgetReduxWrapper({ store }) {
  return (
    <>
      <Provider store={store}>
        <Budget />
      </Provider>
      <hr />
    </>
  );
}

BudgetReduxWrapper.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
};

function filterProjects(projects, filter) {
  if (filter === "budgeted") {
    return projects.filter((project) => !!project.hasAllocations);
  }

  if (filter === "unbudgeted") {
    return projects.filter((project) => !project.hasAllocations);
  }

  return projects;
}

async function fetchProjects(setProjectIds, filter) {
  const response = await api.projects.read({
    filters: { active: true },
    fields: { projects: ["id", "hasAllocations"] },
  });

  const projects = denormalize(response, "projects");

  const projectIds = filterProjects(projects, filter).map((project) =>
    Number(project.id),
  );

  setProjectIds(projectIds);
}

export default function AppWithProjectsIds() {
  const [projectIds, setProjectIds] = React.useState([]);
  const { filter } = queryString.parse(location.search);

  React.useEffect(() => {
    fetchProjects(setProjectIds, filter);
  }, [filter]);

  return (
    <PageLayout title="Budgets">
      <App projectIds={projectIds} />
    </PageLayout>
  );
}
