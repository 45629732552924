import React from "react";
import { useHistory } from "react-router";
import { format, isSameWeek, subWeeks, parseISO } from "date-fns";
import { useCollection } from "coreql";
import Hint from "../hint";

function isValidDayForNotice(weeklyReportDateStart) {
  const today = new Date();
  const weeklyReportDate = parseISO(weeklyReportDateStart);
  const validDay = [1, 2, 3].includes(today.getDay());
  const validWeek = isSameWeek(today, weeklyReportDate, {
    weekStartsOn: 1,
  });
  return validDay && validWeek;
}

function Notice({ weeklyReportDateStart, weeklyReportOwnerId }) {
  const history = useHistory();
  const previousReportPeriodStart = subWeeks(
    parseISO(weeklyReportDateStart),
    1,
  );

  const previousReportPeriodStartFormatted = format(
    previousReportPeriodStart,
    "yyyy-MM-dd",
  );

  const {
    data: [previousWeeklyReport],
  } = useCollection(
    "weekly-reports",
    {
      fields: {
        weeklyReports: ["submitted"],
      },
      filters: {
        user: weeklyReportOwnerId,
        periodStart: previousReportPeriodStartFormatted,
      },
    },
    "denormalized",
  );

  const previousWeekSubmitted = Boolean(previousWeeklyReport?.submitted);

  if (previousWeekSubmitted) return null;

  const previousWeeklyReportUrl = previousWeeklyReport?.id
    ? `/v2_weekly_reports/${previousWeeklyReport.id}/edit`
    : `/weekly_reports/new?period_start=${previousReportPeriodStartFormatted}`;

  function goToPreviousWeek() {
    history.push(previousWeeklyReportUrl);
  }

  return (
    <Hint
      text="Your weekly journal from last week hasn't yet been submitted"
      action="Go to last week's journal"
      onAction={goToPreviousWeek}
      className="transition-colors duration-300 ease-in-out"
    />
  );
}

export default function UnsubmittedJournalNotice({
  weeklyReportDateStart,
  weeklyReportOwnerId,
}) {
  if (!isValidDayForNotice(weeklyReportDateStart)) return null;

  return (
    <Notice
      weeklyReportDateStart={weeklyReportDateStart}
      weeklyReportOwnerId={weeklyReportOwnerId}
    />
  );
}
