import React, { Suspense } from "react";
import { BodyBaseline } from "../design-system/typography-components";
import { Info } from "@baseline/icons";
import { Color, Stack } from "@ableco/baseline";
import { isEmpty } from "lodash";

import Accordion from "../accordion";
import Drawer from "../drawer";
import { LEVEL_FIELDS } from "./capabilities-table-utils";

function AccordionContentFallback() {
  return <BodyBaseline>loading...</BodyBaseline>;
}

function LevelCapabilityAccordion({ field, level }) {
  return (
    <Accordion title={field.title} state="none" variant="small" isOpen>
      <Suspense fallback={<AccordionContentFallback />}>
        <BodyBaseline color={Color.Neutral700}>{level[field.key]}</BodyBaseline>
      </Suspense>
    </Accordion>
  );
}

export default function CapabilitiesDrawer({ level, onClose, ...props }) {
  return (
    <Drawer
      title={`Level ${level.code}`}
      subtitle="Evaluation Criteria"
      onClose={onClose}
      icon={<Info color="white" className="w-6 h-6" />}
      {...props}
    >
      <Stack>
        {LEVEL_FIELDS.map((field) => {
          if (isEmpty(level[field.key])) return;
          return (
            <LevelCapabilityAccordion
              key={`level-accordion-${level.id}-${field.key}`}
              field={field}
              level={level}
            />
          );
        })}
      </Stack>
    </Drawer>
  );
}
