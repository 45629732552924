import { denormalize } from "coreql";
import { keyBy, mapValues } from "lodash";

const LEVEL_FIELDS = [
  // individual contributor fields
  { key: "jobComplexity", title: "Job Complexity" },
  { key: "knowledge", title: "Knowledge" },
  // manager fields
  { key: "scope", title: "Scope" },
  { key: "complexity", title: "Complexity" },
  { key: "discretion", title: "Discretion" },
  { key: "interaction", title: "Interaction" },
  // common
  { key: "supervision", title: "Supervision" },
];

const transition = {
  x: { type: "spring", stiffness: 210, damping: 10 },
  opacity: { duration: 0.1 },
  display: { duration: 1 },
};

const variants = {
  enter: (direction) => ({
    display: "none",
    x: direction > 0 ? 150 : -150,
    opacity: 0,
    transition: transition,
  }),
  center: {
    display: "block",
    x: 0,
    opacity: 1,
  },
  exit: {
    display: "none",
  },
};

/**
 * Returns skills including capabilities and levels data using
 * the raw response from the capabilities api
 */
export function buildTableData(data) {
  const levels = denormalize(data, "levels", true);
  const capabilities = denormalize(data, "capabilities", true);
  const skills = denormalize(data, "skills", true);

  const capabilitiesBySkillId = mapValues(keyBy(skills, "id"), (skill) => ({
    ...skill,
    capabilities: [],
  }));

  for (const { skill, ...capability } of capabilities)
    capabilitiesBySkillId[skill.id].capabilities.push(capability);

  return {
    skills: Object.values(capabilitiesBySkillId),
    levels,
  };
}

export { LEVEL_FIELDS, variants };
