import React from "react";
import { Backdrop } from "@ableco/baseline";
import { motion } from "framer-motion";

export function CoreBackdrop(props) {
  return (
    <Backdrop
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        opacity: { duration: 0.3 },
      }}
      {...props}
    />
  );
}
