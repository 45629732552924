import React from "react";

import { SSRSuspense } from "coreql";
import { Frame, Inline } from "@ableco/baseline";
import Navigation from "./navigation";
import Logo from "./logo";
import { NavigationBottom, NavigationTop } from "./sidebar-navigation";
import { MenuLine } from "../layout/menu";

function Fallback() {
  return (
    <Inline as="figure" distribution="start" className="h-12" p={[0, 4]}>
      <Frame corners="full" className="bg-white bg-opacity-15 w-6 h-6" />
      <Frame corners="full" className="bg-white bg-opacity-15 w-8 h-2 mx-2" />
    </Inline>
  );
}

export function SidebarFallback() {
  return (
    <Frame>
      {[0, 1, 2, 3, 4, 5].map((item) => (
        <Fallback key={item} />
      ))}
    </Frame>
  );
}

export default function Sidebar() {
  return (
    <Navigation>
      <Frame p={4}>
        <Logo />
      </Frame>
      <SSRSuspense fallback={<SidebarFallback />}>
        <NavigationTop />
        <MenuLine className="text-white opacity-15 list-none" />
        <NavigationBottom />
      </SSRSuspense>
    </Navigation>
  );
}
