import { Inline, Stack, Text } from "@ableco/baseline";
import { noop } from "lodash";
import React from "react";
import { SegmentedControl } from "../data-entry/segmented-control";
import { BodyBaselineSemibold } from "../design-system/typography-components";

export default function PromptQuestionType({
  requestFeedback,
  handleChange = noop,
  promptAnswer,
  type,
  scale,
  disabled = false,
  readOnly = false,
}) {
  if (type === "binary") {
    return (
      <Stack space={6}>
        <Inline space={4}>
          <Inline>
            <Text
              id={`radio-yes:${requestFeedback.id}`}
              checked={promptAnswer === 1}
              value={1}
              size="xs"
              onChange={() => handleChange(1)}
              as="input"
              type="radio"
              disabled={disabled || readOnly}
            />
            <label htmlFor={`radio-yes:${requestFeedback.id}`}>
              <Text className="pl-2">Yes</Text>
            </label>
          </Inline>
          <Inline>
            <Text
              id={`radio-no:${requestFeedback.id}`}
              checked={promptAnswer === 0}
              value={0}
              size="xs"
              onChange={() => handleChange(0)}
              as="input"
              type="radio"
              disabled={disabled || readOnly}
            />
            <label htmlFor={`radio-no:${requestFeedback.id}`}>
              <Text className="pl-2">No</Text>
            </label>
          </Inline>
        </Inline>
        {!readOnly && (
          <BodyBaselineSemibold>
            Please provide an example to support your answer:
          </BodyBaselineSemibold>
        )}
      </Stack>
    );
  }
  if (type === "scale") {
    const scaleLabelsAnother = {
      performance: [
        { value: 0, label: "Needs Improvement" },
        { value: 1, label: "OK" },
        { value: 2, label: "Good" },
        { value: 3, label: "Very Good" },
        { value: 4, label: "Perfect" },
      ],
      agreement: [
        { value: 0, label: "Strongly Disagree" },
        { value: 1, label: "Disagree" },
        { value: 2, label: "Neutral" },
        { value: 3, label: "Agree" },
        { value: 4, label: "Strongly Agree" },
      ],
      frequency: [
        { value: 0, label: "Never" },
        { value: 1, label: "Rarely" },
        { value: 2, label: "Sometimes" },
        { value: 3, label: "Often" },
        { value: 4, label: "Always" },
      ],
    };
    return (
      <Stack space={6}>
        <SegmentedControl
          defaultValue={promptAnswer}
          values={scaleLabelsAnother[scale]}
          onChange={handleChange}
          disabled={disabled}
        />
        {!readOnly && (
          <BodyBaselineSemibold>
            Please provide an example to support your answer:
          </BodyBaselineSemibold>
        )}
      </Stack>
    );
  }
  return null;
}
