import { Frame, Inline, Stack, Text, Color } from "@ableco/baseline";
import { CheckCircleOutline, CircleOutline } from "@baseline/icons";
import { useCollection } from "coreql";
import { format, formatDuration, intervalToDuration } from "date-fns";
import { capitalize, keyBy, property, sortBy, sum } from "lodash";
import React, { useMemo } from "react";
import { useTable } from "react-table";
import CoreLink from "../../core-link";
import {
  BodyBaseline,
  BodySmall,
} from "../../design-system/typography-components";
import { MarkdownWrapper } from "../../markdown/markdown-viewer";
import { wrap } from "../../weekly-report/utils";
import Table from "./table";

/* exported for testing */
export function formatEffort(minutes) {
  return formatDuration(
    intervalToDuration({
      start: 0,
      end: minutes * 60 * 1000,
    }),
  );
}

function TextWithSource({ source, name }) {
  return !/^https?:\/\//i.test(source.trim()) ? (
    <Frame>
      <BodyBaseline color={Color.Neutral700}>{name}</BodyBaseline>
      <Stack space={2}>
        <MarkdownWrapper
          className="text-neutral-600 text-sm"
          content={source}
          beforeRender={(parser) => parser.disable("heading")}
        />
      </Stack>
    </Frame>
  ) : (
    <CoreLink
      className="text-primary-base hover:text-primary-light transition-colors duration-300 no-underline"
      href={source}
      isExternalLink
    >
      {name}
    </CoreLink>
  );
}

export function RequiredActivitiesTable({ data }) {
  const activitiesTable = useTable({
    data: useMemo(() => sortBy(wrap(data), ({ effort }) => -effort), [data]),
    columns: useMemo(
      () => [
        {
          Header: "Resources",
          accessor: (row) => [row.name, row.source],
          id: "name",
          Cell: ({ value: [name, source] }) => (
            <TextWithSource source={source} name={name} />
          ),
          Footer: () => (
            <BodyBaseline color={Color.Neutral700}>Overall Effort</BodyBaseline>
          ),
          footerTdProps: { align: "right" },
        },
        {
          Header: "Effort",
          accessor: "effort",
          thProps: { className: "w-1/5" },
          Cell: ({ value: effort }) => (
            <BodySmall color={Color.Neutral700}>
              {formatEffort(effort)}
            </BodySmall>
          ),
          Footer: ({ data }) => (
            <BodySmall color={Color.Neutral700}>
              {formatEffort(sum(data.map(property("effort"))))}
            </BodySmall>
          ),
        },
      ],
      [],
    ),
  });
  return <Table data={activitiesTable} hasFooter />;
}

export function EvaluationRubricsTable({ data }) {
  const rubricsTable = useTable({
    data: useMemo(() => sortBy(wrap(data), ({ weight }) => -weight), [data]),
    columns: useMemo(
      () => [
        {
          Header: "Name",
          accessor: (row) => [row.name, row.description],
          id: "name",
          Cell: ({ value: [name, description] }) => (
            <>
              <BodyBaseline color={Color.Neutral800}>{name}</BodyBaseline>
              {description && (
                <MarkdownWrapper
                  className="text-neutral-700 text-sm"
                  content={description}
                  beforeRender={(parser) => parser.disable("heading")}
                />
              )}
            </>
          ),
        },
        {
          Header: "Weight",
          accessor: "weight",
          thProps: { className: "w-1/5" },
          Cell: ({ value: weight }) => (
            <BodySmall color={Color.Neutral700}>{`${weight}%`}</BodySmall>
          ),
        },
      ],
      [],
    ),
  });
  return <Table data={rubricsTable} />;
}

export function RelatedCapabilitiesTable({ data }) {
  const table = useTable({
    data: useMemo(() => sortBy(wrap(data), ({ skill }) => skill.name), [data]),
    columns: useMemo(
      () => [
        {
          Header: "Capability",
          accessor: "name",
          Cell: ({ value: name }) => (
            <BodyBaseline color={Color.Neutral700}>{name}</BodyBaseline>
          ),
        },
        {
          Header: "Skill",
          accessor: "skill.name",
          thProps: { className: "w-1/5" },
          Cell: ({ value: skillName }) => (
            <BodySmall color={Color.Neutral700}>{skillName}</BodySmall>
          ),
        },
      ],
      [],
    ),
  });
  return <Table data={table} />;
}

export function PrerequisitesTable({ data: badges, userId }) {
  const { data: assessments } = useCollection(
    "user-badges",
    {
      filters: {
        badge: wrap(badges).map(property("id")),
        user: userId,
      },
    },
    "denormalized",
  );

  const table = useTable({
    data: useMemo(() => {
      const assessmentsByBadgeId = keyBy(assessments, "badgeId");
      const badgesByName = sortBy(wrap(badges), ({ name }) => name);

      return badgesByName.map(({ id, name, kind }) => ({
        id,
        name,
        kind: capitalize(kind),
        assessment: assessmentsByBadgeId[id],
      }));
    }, [badges, assessments]),
    columns: useMemo(
      () => [
        {
          Header: "Badge",
          accessor: (badge) => [badge.id, badge.name],
          id: "prereq_link",
          Cell: ({ value: [id, name] }) => (
            <CoreLink href={`./${id}`}>
              <BodyBaseline
                color={[Color.Primary, Color.PrimaryLight]}
                className="transition-colors duration-300"
              >
                {name}
              </BodyBaseline>
            </CoreLink>
          ),
        },
        {
          Header: "Type",
          accessor: "kind",
          Cell: ({ value: kind }) => (
            <BodySmall color={Color.Neutral700}>{kind}</BodySmall>
          ),
        },
        {
          Header: "Awarded",
          accessor: "assessment.awardedAt",
          thProps: { className: "w-1/5" },
          Cell: ({ value: awardedAt }) => (
            <Inline space={2}>
              {awardedAt ? (
                <>
                  <Text color="success">
                    <CheckCircleOutline />
                  </Text>
                  <BodySmall color={Color.Neutral700}>
                    {format(new Date(awardedAt), "dd/MM/yyyy")}
                  </BodySmall>
                </>
              ) : (
                <Text color={Color.Neutral600}>
                  <CircleOutline />
                </Text>
              )}
            </Inline>
          ),
        },
      ],
      [],
    ),
  });
  return <Table data={table} />;
}
