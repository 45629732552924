/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useState } from "react";

import { wrap } from "./utils";
import { Stack } from "@ableco/baseline";
import { TitleSemibold } from "../design-system/typography-components";
import { SurveyAnswer } from "./questions-section";
import { useResource, api as apiCoreQl, denormalize } from "coreql";
import { useCoreToast } from "../toast/toast";
import { useAnswersGroup } from "./accomplishments";
import { isEmpty } from "lodash";
import TemperatureRatePicker from "../rate-picker";

export function useWeeklyReportTemperature(weeklyReportId) {
  const { data, mutate } = useResource(
    "weekly-reports",
    weeklyReportId,
    {
      included: ["user", "user.manager"],
      fields: {
        users: ["id", "fullName", "avatarUrl", "managerId", "manager"],
        "weekly-reports": ["user", "temperature", "temperatureAnswers"],
      },
    },
    "denormalized",
  );

  return {
    data,
    mutate,
  };
}

function TemperatureSection({ weeklyReportId, setSavedAt }) {
  const {
    data: { temperatureAnswers: rawAnswers },
  } = useWeeklyReportTemperature(weeklyReportId);

  const temperatureAnswers = wrap(rawAnswers);

  const [showAnswers, setShowAnswers] = useState(!isEmpty(temperatureAnswers));

  const [autoFocus] = useState(!showAnswers);

  function handleUpdate() {
    setSavedAt(new Date());
  }

  return (
    <Stack as="section" space={4}>
      <TitleSemibold as="h2" className="mb-4">
        How did you feel at work this week?
      </TitleSemibold>

      <TemperatureRatePicker
        weeklyReportId={weeklyReportId}
        onChange={handleUpdate}
        onShowAnswers={() => setShowAnswers(true)}
      />

      <Stack className="max-w-md mx-auto w-full" space={2}>
        {showAnswers && (
          <TemperatureAnswers
            weeklyReportId={weeklyReportId}
            answersList={temperatureAnswers}
            shouldFocus={autoFocus}
            onUpdateAnswers={handleUpdate}
          />
        )}
      </Stack>
    </Stack>
  );
}

function TemperatureAnswers({
  weeklyReportId,
  answersList,
  shouldFocus,
  onUpdateAnswers,
}) {
  const {
    data: {
      user: { manager: reporteeManager },
    },
    mutate,
  } = useWeeklyReportTemperature(weeklyReportId);

  const mutateTemperatureAnswers = useCallback(
    (updateFn = (answers) => answers, revalidate = false) =>
      mutate(async (weeklyReport) => {
        const temperatureAnswers = updateFn(
          wrap(weeklyReport.temperatureAnswers),
        );

        return { ...weeklyReport, temperatureAnswers };
      }, revalidate),
    [mutate],
  );

  const hasManager = !isEmpty(reporteeManager);

  const { answers, updateAnswer, removeAnswer, addAnswer } =
    useAnswersGroup(answersList);

  const { errorToast } = useCoreToast();

  return answers.map((answer, i) => {
    const currentKey = answer.__key;
    const isLast = i === answers.length - 1;
    async function onSave(id, text) {
      // if text is empty
      if (isEmpty(text.trim())) {
        try {
          if (id) {
            await apiCoreQl.weeklyReportAnswers.find(id).destroy();
            updateAnswer(answer, () => ({ __key: currentKey }));

            function deleteCurrentFromList(answers) {
              return answers.filter(
                (answer) => String(answer.id) !== String(id),
              );
            }

            await mutateTemperatureAnswers(deleteCurrentFromList, false);
          }

          onUpdateAnswers();
        } catch {
          errorToast("We could not delete your answer, contact the team.");
        }
        // unset id on autosave hook
        return;
      }

      // update or create
      try {
        const response = await apiCoreQl.weeklyReportAnswers.find(id).upsert(
          { data: { text }, locked: !!answer.locked },
          {
            answerType: "temperature_answer",
            weeklyReportId,
          },
          [{ name: "weeklyReport", resource: "weekly-report" }],
        );

        const { id: newId } = response.data;

        updateAnswer(answer, (answer) => ({
          ...answer,
          data: { text },
          id: newId,
        }));

        await mutateTemperatureAnswers((answers) => {
          if (id) {
            //update matching
            return answers.map((answer) => {
              if (String(answer.id) !== String(id)) return answer;
              return {
                ...answer,
                ...denormalize(response, "weeklyReportAnswers"),
              };
            });
          }

          // new answer
          return answers.concat(denormalize(response, "weeklyReportAnswers"));
        }, false);

        onUpdateAnswers();
        return newId;
      } catch {
        errorToast("We couldn't update your answer, contact the team.");
      }
    }

    function onChange(text) {
      if (!isEmpty(text) && isLast) addAnswer({});
    }

    return (
      <SurveyAnswer
        key={currentKey}
        id={answer.id}
        answerText={answer.id && answer.data.text}
        autoFocus={i === 0 && shouldFocus}
        lastSavedAt={answer.updatedAt}
        isPrivate={answer.locked}
        onSetAsPrivate={async (locked) => {
          await apiCoreQl.weeklyReportAnswers
            .find(answer.id)
            .update({ locked });
          updateAnswer(answer, (answer) => ({ ...answer, locked }));
        }}
        autoSaveProps={{ onSave, onChange }}
        handleBlur={({ target }) => {
          if (isEmpty(target.value.trim()) && !isLast) removeAnswer(answer);
        }}
        managerName={hasManager && reporteeManager.fullName}
      />
    );
  });
}

export default TemperatureSection;
