import React from "react";
import { Field } from "formik";
import FormDropdown from "../form-dropdown";
import { network } from "../../services/network";
import useSWR from "swr";

function format(items, product) {
  return items.map((item) => ({
    value: item.id,
    content: `${product.attributes.name} ${item.attributes.name}`,
    meta: item,
  }));
}

function ProjectDropdown({
  product,
  label,
  onlyUnallocated = true,
  onlyActive = true,
  ...rest
}) {
  let key;
  if (onlyActive) {
    key = product ? product.relationships.projects.links.related : null;
  } else {
    key = product ? `projects?filter[product_id]=${product.id}` : "products";
  }

  const { data } = useSWR(key, network, { suspense: true });

  const projects = data ? data.data : [];
  const filteredProjects = onlyUnallocated
    ? projects.filter((item) => item.attributes.hasAllocations === false)
    : projects;

  return (
    <Field
      name="attributes.projectId"
      component={FormDropdown}
      options={format(filteredProjects, product)}
      label={label}
      searchable={false}
      {...rest}
    />
  );
}

export default ProjectDropdown;
