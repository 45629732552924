import React, { useEffect, useState } from "react";
import {
  Inline,
  Stack,
  Frame,
  Color,
  FlexDistribution,
} from "@ableco/baseline";
import { useResource, api } from "coreql";
import { FeedbackMessageCard } from "./feedback-message-card";
import FeedbackRequestForm from "./feedback-request-form";
import { PrimaryButton } from "../buttons/buttons";
import { format } from "date-fns";
import { BodyBaseline, Display } from "../design-system/typography-components";

function getFeedbackRequestId() {
  return window.location.pathname.match(/\d+/)[0];
}

function useFeedbackRequest() {
  const feedbackRequestId = getFeedbackRequestId();
  return useResource(
    "feedback-requests",
    feedbackRequestId,
    {
      included: [
        "response",
        "response.feedbackPrompt",
        "feedbackPrompt",
        "feedbackPrompt.responsibility",
        "author",
        "sender",
        "sender.role",
      ],
      fields: {
        feedbackRequests: [
          "id",
          "body",
          "sender",
          "response",
          "author",
          "feedbackPrompt",
          "dismissedAt",
          "createdAt",
          "receiverId",
          "senderId",
        ],
        feedbackMessages: [
          "id",
          "body",
          "updatedAt",
          "submittedAt",
          "promptAnswer",
        ],
        users: ["fullName", "avatarUrl", "role", "id", "preferredName"],
        feedbackPrompts: [
          "id",
          "promptType",
          "scaleType",
          "prompt",
          "responsibility",
        ],
        responsibilities: ["title"],
        roles: ["title"],
      },
    },
    "denormalized",
  );
}

function formatDate(date) {
  return format(date, "MMM d, yyyy, h:mm aaaa");
}

export function ReadOnly() {
  const { data: feedbackRequest } = useFeedbackRequest();

  return (
    <Stack alignment={FlexDistribution.Start} space={10} className="mx-6">
      <Stack space={2}>
        <Display>Feedback Message</Display>

        {!Boolean(feedbackRequest.dismissedAt) && (
          <BodyBaseline color={Color.Neutral700}>
            {formatDate(new Date(feedbackRequest.response.submittedAt))}
          </BodyBaseline>
        )}
      </Stack>
      <Frame style={{ width: 800 }}>
        <FeedbackMessageCard
          request={feedbackRequest}
          feedbackPrompt={feedbackRequest.feedbackPrompt}
          response={feedbackRequest.response || {}}
          headerUser={feedbackRequest.sender}
          displayRole={true}
          displayRequesterByline={true}
        />
      </Frame>
    </Stack>
  );
}

function Form() {
  const { data: feedbackRequest, mutate: refreshFeedbackRequest } =
    useFeedbackRequest();
  const [isAnswered, setIsAnswered] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  function handleDismiss(_, isDismissed) {
    setIsDismissed(isDismissed);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await (isDismissed
        ? api.feedbackRequests
            .find(feedbackRequest.id)
            .update({ dismissedAt: new Date() })
        : api.feedbackMessages.find(feedbackRequest.response.id).update({
            submittedAt: new Date(),
            source: "REPLY FORM",
          }));
      refreshFeedbackRequest();
    } catch {}
  }
  useEffect(() => {
    setIsAnswered(
      (feedbackRequest.response &&
        feedbackRequest.response.body &&
        feedbackRequest.response.body.trim() &&
        (!feedbackRequest.feedbackPrompt ||
          feedbackRequest.feedbackPrompt.promptType == "text" ||
          feedbackRequest.response.promptAnswer != null)) ||
        isDismissed,
    );
  }, [isDismissed, feedbackRequest.feedbackPrompt, feedbackRequest.response]);

  return (
    <Stack space={6} className="px-6">
      <Stack space={2}>
        <Display>Reply to Feedback Request</Display>
        <BodyBaseline color={Color.Neutral700}>
          {formatDate(new Date(feedbackRequest.createdAt))}
        </BodyBaseline>
      </Stack>
      <Stack style={{ width: 800 }}>
        <FeedbackRequestForm
          onDismiss={handleDismiss}
          feedbackRequest={feedbackRequest}
          feedbackResponse={feedbackRequest.response}
          refreshData={refreshFeedbackRequest}
          feedbackDismissed={isDismissed}
          source="REPLY FORM"
        />
        <Inline
          border={Color.Neutral300}
          distribution={FlexDistribution.Between}
          p={[5, 6]}
          className="w-full border-t-0"
        >
          <BodyBaseline color={Color.Neutral600}>
            Draft Saved:{" "}
            {feedbackRequest?.response?.updatedAt ? (
              formatDate(new Date(feedbackRequest.response.updatedAt))
            ) : (
              <>Never</>
            )}
          </BodyBaseline>
          <PrimaryButton
            onClick={handleSubmit}
            text="Send Feedback"
            disabled={!isAnswered}
          />
        </Inline>
      </Stack>
    </Stack>
  );
}

export default function FeedbackResponsePage() {
  const { data: feedbackRequest } = useFeedbackRequest();

  const isSubmitted = Boolean(feedbackRequest?.response?.submittedAt);
  const isRequestDismissed = Boolean(feedbackRequest.dismissedAt);

  return (
    <div className="bg-white">
      <Frame p={4}>
        {isSubmitted || isRequestDismissed ? <ReadOnly /> : <Form />}
      </Frame>
    </div>
  );
}
