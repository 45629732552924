import React, { useMemo } from "react";
import { WeeklyReportsLayout } from "./history-layout";
import { Button, Frame, Inline, Skeleton } from "@ableco/baseline";
import { CaretRightOutline } from "@baseline/icons";
import { ErrorBoundary, SSRSuspense, useCollection } from "coreql";
import {
  differenceInWeeks,
  isBefore,
  isEqual,
  subWeeks,
  parseISO,
} from "date-fns";
import { keyBy } from "lodash";
import { useTable } from "react-table";
import { useUserProfile } from "../../layout/user-layout";
import {
  formatJournalPeriod,
  formatLong,
  formatWithDashes,
  getPastWeekPeriods,
  weekStart,
  setParamsToJournalUrl,
} from "./utils";
import { useHistory } from "react-router";
import { PrimaryButton } from "../../buttons/buttons";
import { useQuery } from "../../../hooks/use-query";

const thisWeekStart = weekStart(Date.now());

function buildTableData(dateFrom, data, nWeeks) {
  const WEEKS_BEFORE_REPORT_IS_PAST_DUE = 10;
  const pastTenWeeksRanges = getPastWeekPeriods(dateFrom, nWeeks);

  const dataByPeriodStart = keyBy(data, "periodStart");

  const tableData = pastTenWeeksRanges.map((periodDates) => {
    const [periodStart, periodEnd] = periodDates;

    const record = dataByPeriodStart[formatWithDashes(periodStart)];
    let submitted = record?.submitted;

    if (submitted != null) submitted = formatLong(parseISO(submitted));

    return {
      current: isEqual(thisWeekStart, periodStart),
      period: formatJournalPeriod(periodStart),
      periodStart: formatWithDashes(periodStart),
      periodEnd: formatWithDashes(periodEnd),
      submitted: submitted || "---",
      submittedAt: record?.submitted,
      pastDue:
        differenceInWeeks(Date.now(), periodStart) >=
        WEEKS_BEFORE_REPORT_IS_PAST_DUE,
      id: record?.id,
    };
  });

  return tableData;
}

function MyJournalsTableFallback() {
  return (
    <Frame className="mt-10 mb-4 border rounded border-neutral-300">
      <table className="w-full">
        <thead className="bg-neutral-200">
          <tr>
            <th className="py-3 px-6 w-1/3">
              <Skeleton
                color="neutral-400"
                width={80}
                height={21}
                alt="Period"
              />
            </th>
            <th className="py-3 px-6 w-1/3">
              <Skeleton
                color="neutral-400"
                width={100}
                height={21}
                alt="Submitted"
              />
            </th>
            <th className="py-3 px-6"></th>
          </tr>
        </thead>
        <tbody>
          {[...Array.from({ length: 11 })].map((_, i) => (
            <tr key={i} className="border-b border-neutral-300">
              <td className="py-2 px-6">
                <Skeleton
                  color="neutral-400"
                  width={165}
                  height={24}
                  alt="Period"
                  animated
                />
              </td>
              <td className="py-2 px-6">
                <Skeleton
                  color="neutral-400"
                  width={145}
                  height={24}
                  alt="Submitted Date"
                  animated
                />
              </td>
              <td className="pr-6 py-2" align="right">
                <Skeleton
                  color="neutral-400"
                  width={120}
                  height={40}
                  alt="Actions"
                  animated
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Frame>
  );
}

function MyJournalsTable({
  untilDate,
  weeksShown,
  userId,
  firstDate,
  lastDate,
}) {
  const history = useHistory();
  const reportsSince = firstDate
    ? firstDate
    : formatWithDashes(subWeeks(untilDate, weeksShown - 1));
  const reportsUntil = lastDate ? lastDate : formatWithDashes(untilDate);
  const dataDate = lastDate ? parseISO(lastDate) : untilDate;
  const { data: records } = useCollection(
    "weekly-reports",
    {
      fields: {
        weeklyReports: ["id", "periodEnd", "periodStart", "submitted"],
      },
      filters: {
        user: userId,
        // Substract one because range is inclusive on both ends
        reportsSince: reportsSince,
        reportsUntil: reportsUntil,
      },
    },
    "denormalized",
  );

  const { headerGroups, prepareRow, rows, getTableBodyProps, getTableProps } =
    useTable({
      data: useMemo(
        () => buildTableData(dataDate, records, weeksShown),
        [records, dataDate, weeksShown],
      ),
      columns: useMemo(
        () => [
          { Header: "Period", accessor: "period" },
          { Header: "Submitted", accessor: "submitted" },
        ],
        [],
      ),
    });

  const referrerUrl = encodeURIComponent(
    `/weekly_reports/my_journal?reportsSince=${reportsSince}&reportsUntil=${reportsUntil}`,
  );

  return (
    <Frame className="mt-10 mb-4 border rounded border-neutral-300">
      <table className="w-full" {...getTableProps()}>
        <thead className="bg-neutral-200 leading-normal text-sm">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((col) => (
                <th
                  align="left"
                  className="py-3 px-6 font-normal text-neutral-700 w-1/3"
                  {...col.getHeaderProps()}
                >
                  {col.render("Header")}
                </th>
              ))}
              <th aria-label="Actions"></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { id, pastDue, periodStart, current, submittedAt } =
              row.original;
            const url = id
              ? `/v2_weekly_reports/${id}`
              : `new?period_start=${periodStart}`;
            const submittedRowProps = submittedAt
              ? {
                  className: "border-b border-neutral-300 cursor-pointer",
                  onClick: () => history.push(`${url}?referrer=${referrerUrl}`),
                  "aria-label": "Visit",
                  role: "link",
                }
              : {};
            return (
              <tr
                className="border-b border-neutral-300"
                {...row.getRowProps()}
                {...submittedRowProps}
              >
                {row.cells.map((cell) => (
                  <td
                    className={`${pastDue ? "py-4" : "py-2"} px-6`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
                <td className="pr-6 py-2" align="right">
                  {!pastDue &&
                    (submittedAt ? (
                      <CaretRightOutline className="my-1" />
                    ) : (
                      <PrimaryButton
                        role="link"
                        text={current ? "Fill Current Journal" : "Fill Journal"}
                        onClick={() => {
                          history.push(id ? `${url}/edit` : `${url}`);
                        }}
                      />
                    ))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Frame>
  );
}

const commonBtnStyles =
  "mx-2 transition-all duration-300 ease-in-out text-primary-base " +
  // hover styles
  "hover:text-primary-light hover:border-primary-light " +
  // disabled styles
  "disabled:pointer-events-auto disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-neutral-500 disabled:border-neutral-400";

function setParamsToUrl(history, date, nWeeks) {
  history.push(
    setParamsToJournalUrl("/weekly_reports/my_journal", date, nWeeks),
  );
}

function MyJournalHistory() {
  const history = useHistory();
  const query = useQuery();
  const firstDate = query.get("reportsSince");
  const lastDate = query.get("reportsUntil");
  const { joinDate, id: currentUserId } = useUserProfile();
  const dateLimit = useMemo(() => weekStart(parseISO(joinDate)), [joinDate]);
  const untilDate = lastDate ? parseISO(lastDate) : thisWeekStart;

  const showOlderBtnState = isBefore(subWeeks(untilDate, 11), dateLimit)
    ? "disabled"
    : "normal";

  const showNewerBtnState = isBefore(untilDate, thisWeekStart)
    ? "normal"
    : "disabled";

  function showOlder() {
    setParamsToUrl(history, subWeeks(untilDate, 11), 11);
  }

  function showNewer() {
    setParamsToUrl(history, subWeeks(untilDate, -11), 11);
  }

  return (
    <Frame className="pb-4">
      <ErrorBoundary fallback={MyJournalsTableFallback}>
        <SSRSuspense fallback={<MyJournalsTableFallback />}>
          <MyJournalsTable
            untilDate={untilDate}
            weeksShown={11}
            userId={currentUserId}
            firstDate={firstDate}
            lastDate={lastDate}
          />
        </SSRSuspense>
      </ErrorBoundary>
      <Inline className="justify-center">
        <Button
          onClick={showNewer}
          state={showNewerBtnState}
          type="button"
          size="medium"
          bg="white"
          border="primary"
          className={commonBtnStyles}
        >
          Newer
        </Button>
        <Button
          onClick={showOlder}
          state={showOlderBtnState}
          bg="white"
          type="button"
          size="medium"
          border="primary"
          className={commonBtnStyles}
        >
          Older
        </Button>
      </Inline>
    </Frame>
  );
}

export default function WeeklyReportsMyJournal() {
  return (
    <WeeklyReportsLayout>
      <MyJournalHistory />
    </WeeklyReportsLayout>
  );
}
