import { Frame, Skeleton } from "@ableco/baseline";
import clsx from "clsx";
import { omit } from "lodash";
import React, { useMemo } from "react";
import { useTable } from "react-table";
import { BodySmall } from "../../design-system/typography-components";

export function TableFallback({ rows, cols }) {
  const table = useTable({
    data: useMemo(() => [...new Array(rows)].map((_) => ({})), []),
    columns: useMemo(
      () =>
        [...new Array(cols)].map((_, i) => ({
          Header: () => (
            <Skeleton color="neutral-300" height={14} width={96} animated />
          ),
          thProps: {
            width: 120,
            align: i == cols - 1 ? "left" : "",
          },
          accessor: `col_${i}`,
          Cell: () => (
            <Skeleton color="neutral-200" height={20} width="80%" animated />
          ),
        })),
      [],
    ),
  });
  return <Table data={table} />;
}

/**
 *
 * @param {object} props
 * @param {import("react-table").TableInstance<T>} props.data
 * @template T
 */
function Table({ data, hasFooter = false, dataTestId }) {
  const {
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
    getTableBodyProps,
    getTableProps,
  } = data;
  return (
    <Frame
      as="table"
      corners="medium"
      className="w-full border-separate"
      border="neutral-400"
      style={{ borderSpacing: 0 }}
      data-testid={dataTestId}
      {...getTableProps()}
    >
      <Frame as="thead" bg="neutral-100">
        {headerGroups.map((headerGroup) => (
          <Frame as="tr" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((col) => (
              <Frame
                as="th"
                className={clsx(
                  "border-b border-neutral-400",
                  col.thProps?.className,
                )}
                p={[3, 6]}
                align="left"
                {...col.getHeaderProps(omit(col.thProps, "className"))}
              >
                <BodySmall color="neutral-600">
                  {col.render("Header")}
                </BodySmall>
              </Frame>
            ))}
          </Frame>
        ))}
      </Frame>
      <Frame as="tbody" {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Frame
              as="tr"
              className="even:bg-neutral-100"
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => (
                <Frame as="td" p={[4, 6]} {...cell.getCellProps(cell.tdProps)}>
                  {cell.render("Cell")}
                </Frame>
              ))}
            </Frame>
          );
        })}
      </Frame>
      {hasFooter && (
        <Frame as="tfoot">
          {footerGroups.map((footerGroup) => (
            <Frame
              as="tr"
              bg="neutral-100"
              {...footerGroup.getFooterGroupProps()}
            >
              {footerGroup.headers.map((col) => (
                <Frame
                  as="td"
                  p={[4, 6]}
                  {...col.getFooterProps(col.footerTdProps)}
                >
                  {col.render("Footer")}
                </Frame>
              ))}
            </Frame>
          ))}
        </Frame>
      )}
    </Frame>
  );
}
export default Table;
