import React from "react";
import { Text } from "@ableco/baseline";
import { CaretDown, CaretRight } from "@baseline/icons";

export default function CaretToggle(props) {
  return (
    <Text color={props.isHovered ? "neutral-800" : "neutral-900"}>
      {props.isOpen ? (
        <CaretDown className="w-4 h-4" />
      ) : (
        <CaretRight className="w-4 h-4" />
      )}
    </Text>
  );
}
