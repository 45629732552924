import { Touchable } from "@ableco/baseline";
import { AddCircleOutline, CommentOutline } from "@baseline/icons";
import React from "react";
import { CoreTooltip } from "../../core-tooltip";

export function CommentIconButton({ onClick }) {
  return (
    <CoreTooltip label="Add Comment">
      <Touchable
        data-testid="add-comment"
        onClick={onClick}
        className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out"
      >
        <CommentOutline className="w-5 h-5 text-neutral-600" />
      </Touchable>
    </CoreTooltip>
  );
}

export function AddToOneOnOneIconButton({ onClick }) {
  return (
    <CoreTooltip label="Add to 1-on-1">
      <Touchable
        data-testid="add-one-on-one"
        onClick={onClick}
        className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out"
      >
        <AddCircleOutline className="w-5 h-5 text-neutral-600" />
      </Touchable>
    </CoreTooltip>
  );
}
