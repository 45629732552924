import React from "react";
import { Frame, Stack } from "@ableco/baseline";
import { api as apiCoreQl, denormalize } from "coreql";
import { isEmpty } from "lodash";
import { TitleSemibold } from "../design-system/typography-components";
import { wrap } from "./utils";
import { useAccomplishments, Answer, useAnswersGroup } from "./accomplishments";
import { useCoreToast } from "../toast/toast";

function NextWeekPlans({ weeklyReportId, onUpdate }) {
  const { data: weeklyReportAccomplishments, mutate } =
    useAccomplishments(weeklyReportId);
  const plans = wrap(weeklyReportAccomplishments.plans);

  const { answers, addAnswer, updateAnswer, removeAnswer } =
    useAnswersGroup(plans);
  const { errorToast } = useCoreToast();
  return (
    <Frame as="section">
      <TitleSemibold as="h3" className="mb-2">
        What do you want to accomplish next week?
      </TitleSemibold>
      <Stack role="group" aria-label="Next Week Plans" space={2}>
        {answers.map((answer, i) => {
          const isLast = i === answers.length - 1;
          const currentKey = answer.__key;

          async function onSave(id, text) {
            // if text is empty
            if (isEmpty(text.trim())) {
              try {
                if (id) {
                  await apiCoreQl.weeklyReportAnswers.find(id).destroy();
                  // set as blank to untrack the answer before mutate
                  updateAnswer(answer, () => ({ __key: currentKey }));
                  await mutate(async (weeklyReport) => {
                    const plans = wrap(weeklyReport.plans).filter(
                      (plan) => String(plan.id) !== String(id),
                    );
                    return { ...weeklyReport, plans };
                  }, false);
                }

                onUpdate();
              } catch {
                errorToast("We couldn't delete your answer, contact the team.");
              }
              // unset id on autosave hook
              return;
            }

            // update or create
            try {
              const response = await apiCoreQl.weeklyReportAnswers
                .find(id)
                .upsert(
                  {
                    data: { text },
                  },
                  {
                    answerType: "plan",
                    weeklyReportId,
                  },
                  [{ name: "weeklyReport", resource: "weekly-report" }],
                );

              const { id: newId } = response.data;

              updateAnswer(answer, (answer) => ({
                ...answer,
                data: { text },
                id: newId,
              }));

              await mutate(async (weeklyReport) => {
                const plans = wrap(weeklyReport.plans);

                function updateAchievedPlan(plan) {
                  if (String(plan.id) !== String(newId)) return plan;

                  return {
                    ...plan,
                    ...denormalize(response, "weeklyReportAnswers"),
                  };
                }

                return {
                  ...weeklyReport,
                  plans: id
                    ? plans.map(updateAchievedPlan)
                    : plans.concat(
                        denormalize(response, "weeklyReportAnswers"),
                      ),
                };
              }, false);

              onUpdate();

              return newId;
            } catch {
              errorToast("We couldn't update your answer, contact the team.");
            }
          }

          function onChange(text) {
            if (!isEmpty(text) && isLast) addAnswer({});
          }
          return (
            <Answer
              key={answer.__key}
              id={answer.id}
              answerText={answer.id && answer.data.text}
              lastSavedAt={answer.updatedAt}
              autoSaveProps={{
                onSave,
                onChange,
              }}
              handleBlur={({ target }) => {
                if (isEmpty(target.value.trim()) && !isLast)
                  removeAnswer(answer);
              }}
            />
          );
        })}
      </Stack>
    </Frame>
  );
}

export default NextWeekPlans;
