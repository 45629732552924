import styled from "styled-components";
import * as _ from "lodash";

import { colorPalette } from "../style-guide/colors";
import { fontSizes, fontWeights } from "../style-guide/typography";

export const SectionContainer = styled.section`
  margin-top: 50px;
`;

export const ArticleContainer = styled.article`
  margin-top: 30px;
  &:first-of-type {
    margin-top: 20px;
  }
`;

export const SectionTitle = styled.h2`
  color: ${colorPalette.neutral800};
  font-weight: ${fontWeights.regular};
  font-size: 20px;
  line-height: 26px;
`;

export const QuestionTitle = styled.h3`
  color: ${colorPalette.neutral800};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.medium}px;
  line-height: 24px;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

export const HelpText = styled.p`
  color: ${colorPalette.neutral700};
  font-size: ${fontSizes.smallX}px;
  line-height: 16px;
`;

export const HelpLabel = styled.span`
  color: ${colorPalette.neutral700};
  font-size: ${fontSizes.small}px;
  line-height: 21px;
  font-weight: ${fontWeights.regular};
  margin-left: 5px;
  flex-shrink: 0;
`;

export function itemStartEditing(items, setItems, id, attrs = {}) {
  const lastItem = _.last(items);
  if (lastItem.id === id) {
    const newItem = {
      id: _.uniqueId("new:"),
      key: _.uniqueId("key"),
      data: {},
      ...attrs,
    };
    setItems(items.concat(newItem));
  }
}

/**
 * Wraps the value in an array if not already one,
 * returns an empty array if value is falsy
 *
 * ```jsx
 * const x = wrap(5); // [5]
 * const y = wrap([5]); // [5]
 * const z = wrap(); // []
 * ```
 * @template T
 * @param {Array<T> | T} [value]
 * @returns {Array<T>}
 */
export function wrap(value) {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
}

export function areMandatoryQuestionsAnswered(questions) {
  let additionalInformationGiven = true;
  const additionalInformationQuestionsRequired = questions.filter(
    (question) => question.additionalInformation === "required",
  );

  if (!_.isEmpty(additionalInformationQuestionsRequired)) {
    const additionalInformationAnswers =
      additionalInformationQuestionsRequired.filter(
        (additionalQuestion) =>
          additionalQuestion.answers[0]?.data.additionalInformationText,
      );

    additionalInformationGiven =
      additionalInformationQuestionsRequired.length ===
      additionalInformationAnswers.length;
  }

  return (
    additionalInformationGiven &&
    questions
      .filter(({ optional }) => !optional)
      .every(({ answers }) => !_.isEmpty(answers))
  );
}
