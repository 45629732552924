import { Stack, Center, Color } from "@ableco/baseline";
import { useParams } from "react-router";
import React from "react";
import { ErrorBoundary, SSRSuspense, useCollection } from "coreql";
import { FeedbackMessageContainerLayout } from "./feedback-message-content";
import { FeedbackMessagesFallback } from "./feedback-message-fallback";
import {
  BodyBaseline,
  BodyBaselineSemibold,
} from "../design-system/typography-components";

export function useSentFeedbackMessages(userId) {
  return useCollection(
    "feedback-messages",
    {
      filters: {
        active: true,
        draft: false,
        senderId: userId,
      },
      fields: {
        feedbackMessages: [
          "id",
          "submittedAt",
          "receiver",
          "readByReceiver",
          "readByManager",
          "feedbackRequest",
        ],
        users: ["fullName", "avatarUrl", "id", "preferredName"],
      },
      included: ["receiver", "feedbackRequest"],
    },
    "denormalized",
  );
}

function DefaultMessageContent({ numberSentMessages }) {
  return (
    <Center className="h-full">
      <Stack>
        {numberSentMessages ? (
          <BodyBaseline
            color={Color.Neutral700}
            className="text-center"
            data-testid="feedbacksent"
          >
            You've sent{" "}
            <BodyBaselineSemibold color={Color.Primary}>
              {numberSentMessages}{" "}
            </BodyBaselineSemibold>{" "}
            feedback messages. Thank you for helping us grow!
          </BodyBaseline>
        ) : null}
        <BodyBaseline color={Color.Neutral700} className="text-center mt-4">
          Tip: If you want to give feedback to your peers just go to{" "}
          <BodyBaselineSemibold color={Color.Alert}>Slack</BodyBaselineSemibold>{" "}
          and type{" "}
          <BodyBaselineSemibold color={Color.Neutral700}>
            /core feedback @peer-handle.
          </BodyBaselineSemibold>
        </BodyBaseline>
      </Stack>
    </Center>
  );
}

export function FeedbackMessagesSentContainer({
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
}) {
  const { userId } = useParams();

  const { data: sentMessages } = useSentFeedbackMessages(userId);

  const defaultMessageContent = (
    <DefaultMessageContent numberSentMessages={sentMessages.length} />
  );

  return (
    <FeedbackMessageContainerLayout
      textNoFeedback={"You haven't send any feedback yet."}
      typeMessages="sent"
      feedbackMessages={sentMessages}
      defaultMessageContent={defaultMessageContent}
      isCurrentUserDirectManager={isCurrentUserDirectManager}
      isCurrentUserInboxOwner={isCurrentUserInboxOwner}
    />
  );
}

export default function FeedbackSentMessages({
  setActiveLabelTab,
  isCurrentUserDirectManager,
  isCurrentUserInboxOwner,
}) {
  setActiveLabelTab("sent");
  return (
    <ErrorBoundary fallback={FeedbackMessagesFallback}>
      <SSRSuspense fallback={<FeedbackMessagesFallback />}>
        <FeedbackMessagesSentContainer
          isCurrentUserDirectManager={isCurrentUserDirectManager}
          isCurrentUserInboxOwner={isCurrentUserInboxOwner}
        />
      </SSRSuspense>
    </ErrorBoundary>
  );
}
