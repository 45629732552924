import React from "react";
import { Stack } from "@ableco/baseline";
import useCurrentUser from "../../hooks/use-current-user";
import checkPermission from "../../utils/check-permission";
import DashboardFeedback from "./dashboard-feedback";
import ProductProfitability from "./product-profitability";
import { ErrorBoundary } from "coreql";

export default function DashboardHome() {
  let { data: currentUser } = useCurrentUser();
  let hasPermission = checkPermission(currentUser, "view_profitability");
  return (
    <Stack className="h-full w-full pt-5" alignment="center">
      <Stack style={{ maxWidth: 860 }}>
        <ErrorBoundary fallback={() => <p>Error in DashboardFeedback</p>}>
          <DashboardFeedback />
        </ErrorBoundary>
        {hasPermission && (
          <ErrorBoundary fallback={() => <p>Error in ProductProfitability</p>}>
            <ProductProfitability />
          </ErrorBoundary>
        )}
      </Stack>
    </Stack>
  );
}
