import React from "react";
import {
  AccordionGroup,
  AccordionTitle,
  AccordionContent,
  Text,
  Inline,
  Frame,
} from "@ableco/baseline";
import { CloseBold, CheckBold, Lightbulb } from "@baseline/icons";
import CaretToggle from "../../components/caret-toggle";
import Drawer from "../drawer";

function H3(props) {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <AccordionTitle
      p={[2, 0]}
      className="focus:outline-none"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {({ isOpen }) => (
        <Inline space={2}>
          <CaretToggle isOpen={isOpen} isHovered={isHovered} />
          <Text as="h3" leading="normal" weight="semibold" {...props} />
        </Inline>
      )}
    </AccordionTitle>
  );
}

function OL(props) {
  return (
    <Frame as="ol" p={[0, 0, 4, 6]}>
      <Text {...props} />
    </Frame>
  );
}

function UL(props) {
  return <Text as="ul" {...props} />;
}

function LiCheck(props) {
  return (
    <Inline as="li" alignment="start" p={[2, 0]} space={2}>
      <Frame p={[1, 0, 0, 0]}>
        <CheckBold className="w-4 h-4 flex-none text-success-base" />
      </Frame>
      <Text color="neutral-800" leading="normal" {...props}></Text>
    </Inline>
  );
}

function LiClose(props) {
  return (
    <Inline as="li" alignment="start" p={[2, 0]} space={2}>
      <Frame p={[1, 0, 0, 0]}>
        <CloseBold className="w-4 h-4 flex-none text-alert-base" />
      </Frame>
      <Text color="neutral-800" leading="normal" {...props}></Text>
    </Inline>
  );
}

function LiDash(props) {
  return (
    <Inline as="li" alignment="start" p={[2, 0]} space={2}>
      <Frame p={[1, 0, 0, 0]}>
        <Text>-</Text>
      </Frame>
      <Text color="neutral-800" leading="normal" {...props}></Text>
    </Inline>
  );
}

export function ReflectionTipsDrawer({ onClose, ...props }) {
  return (
    <Drawer
      title="Reflections"
      subtitle="Tips and Best Practices"
      onClose={onClose}
      icon={<Lightbulb color="white" className="w-6 h-6" />}
      {...props}
    >
      <ReflectionTips />
    </Drawer>
  );
}

export default function ReflectionTips() {
  return (
    <>
      <AccordionGroup>
        <H3>Frequency and Length</H3>
        <AccordionContent>
          <OL>
            <LiCheck>
              As an individual contributor, reflect on your own feedback every 2
              weeks
            </LiCheck>
            <LiCheck>
              As a manager, reflect on your direct reports’ feedback every 4
              weeks
            </LiCheck>
            <LiCheck>
              Write short summaries of your observations and takeaways (1-2
              paragraphs)
            </LiCheck>
            <LiCheck> Focus on the most recent feedback</LiCheck>
            <LiClose>
              Spend more than 30 minutes on writing the reflections
            </LiClose>
          </OL>
        </AccordionContent>
      </AccordionGroup>

      <AccordionGroup>
        <H3>Reflection Topics</H3>
        <AccordionContent>
          <OL>
            <LiCheck>
              As an individual contributor, consider answering these questions:
              <UL>
                <LiDash>
                  What are some important accomplishments that you want to
                  capture and reflect on?
                </LiDash>
                <LiDash>
                  What patterns have you noticed in your own behavior,
                  interactions with your teammates, or in the recent feedback
                  that you have received?
                </LiDash>
                <LiDash>
                  What are some takeaways from the feedback you have recently
                  received?
                </LiDash>
                <LiDash>
                  What do you want to highlight as things that you want to
                  remember to do?
                </LiDash>
                <LiDash>
                  What actions would you like to take to address the
                  opportunities mentioned in the recent feedback?
                </LiDash>
                <LiDash>
                  Is there anything that’s unclear in the recent feedback that
                  you would like for your manager to follow up on?
                </LiDash>
                <LiDash>
                  How can your manager help you with addressing the
                  opportunities mentioned in the feedback?
                </LiDash>
              </UL>
            </LiCheck>
            <LiCheck>
              As a manager, consider answering these questions:
              <UL>
                <LiDash>
                  What are some important accomplishments that you want to
                  capture and reflect on?
                </LiDash>
                <LiDash>
                  What are some patterns that you’re noticing in the recent
                  feedback?
                </LiDash>
                <LiDash>
                  What action items or next steps would you like to recommend
                  and discuss with your direct report?
                </LiDash>
                <LiDash>
                  Did your direct report demonstrate any growth or improvement
                  in the particular areas or skills that you want to capture?
                </LiDash>
                <LiDash>
                  What are some future opportunities for growth or development?
                </LiDash>
                <LiDash>
                  What is important for you to keep in mind as you’re thinking
                  about your report’s career development at Able?
                </LiDash>
                <LiDash>
                  What did your report not notice as a pattern from the feedback
                  they have received?
                </LiDash>
              </UL>
            </LiCheck>
            <LiClose>
              Don’t use Reflections as a way to give feedback to your manager or
              your teammates
            </LiClose>
            <LiClose>
              Don’t write Twitter-style messages as Reflections, reflections
              should include explanations of your takeaways and insights
            </LiClose>
          </OL>
        </AccordionContent>
      </AccordionGroup>

      <AccordionGroup>
        <H3>Private Reflections</H3>
        <AccordionContent>
          <OL>
            <LiCheck>
              Use them as “drafts” for the reflections you are not ready to
              share with your manager
            </LiCheck>
            <LiCheck>
              Use them as “notes to self” for items you want to remember to keep
              an eye on, or to ask for more feedback on, or for feedback that
              you disagree with
            </LiCheck>
            <LiClose>
              As a manager, do not wait too long to share and discuss your
              reflections with your direct reports
            </LiClose>
            <LiClose>
              As a manager, do not use private reflections to “hide” your
              feedback or performance assessment from your direct reports
            </LiClose>
          </OL>
        </AccordionContent>
      </AccordionGroup>
    </>
  );
}
