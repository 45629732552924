import React, { useEffect, useMemo, useState } from "react";
import CoreLink from "./core-link";
import { BodyBaseline, BodySmall } from "./design-system/typography-components";
import { Stack, Frame, Skeleton, Inline, Text } from "@ableco/baseline";
import { denormalize, ErrorBoundary, SSRSuspense, useCollection } from "coreql";
import PageLayout from "./layout/page-layout";
import { capitalize, debounce, isEmpty, random } from "lodash";
import { wrap } from "./weekly-report/utils";
import TextInput from "./text-input";
import { matchSorter } from "match-sorter";
import Accordion from "./accordion";
import Tag from "./tag";
import { BADGE_KIND_TAGS } from "./users/badges/level-badges-table";
import { Search } from "@baseline/icons";
import { useLocation } from "react-router";

function filterBadgesByQuery(badges, query) {
  function filterSkillBadges(skill) {
    /* badges matched */
    const matched = matchSorter(skill.badges, query, { keys: ["name"] });
    return {
      ...skill,
      /* if no matches return unchanged in case of a skill match */
      badges: isEmpty(matched) ? skill.badges : matched,
    };
  }

  return matchSorter(
    /* filter unmatched badges before sorting skills */
    badges.map(filterSkillBadges),
    query,
    {
      keys: ["name", "badges.*.name"],
    },
  );
}

function BadgeList() {
  const { pathname: currentLocation } = useLocation();
  const { data: badges } = useCollection("badges", {
    fields: {
      badges: ["name", "kind", "observableBehaviours"],
      observableBehaviours: ["capability"],
      capabilities: ["skill"],
      skills: ["name"],
    },
    filters: {
      active: true,
      kind: ["evaluation", "achievement"],
    },
    included: ["observableBehaviours.capability.skill"],
  });

  const [filter, setFilter] = useState("");
  const [filteredBadges, setFiltered] = useState([]);

  const badgesBySkill = useMemo(() => {
    const skillsById = new Map();

    for (const badge of denormalize(badges, "badges", true)) {
      const behaviours = wrap(badge.observableBehaviours);
      for (const { capability } of behaviours) {
        const { skill } = capability;

        if (!skillsById.has(skill.id))
          skillsById.set(skill.id, { ...skill, badges: new Set() });

        skillsById.get(skill.id).badges.add(badge);
      }
    }

    return [...skillsById.values()].map((skill) => ({
      ...skill,
      badges: [...skill.badges],
    }));
  }, [badges]);

  const changeFilter = useMemo(
    () => debounce((text) => setFilter(text), 500, { trailing: true }),
    [],
  );

  /* update list on refetch / filter change */
  useEffect(() => {
    setFiltered(filterBadgesByQuery(badgesBySkill, filter));
  }, [badgesBySkill, filter]);

  function handleFilterChange(event) {
    changeFilter(event.target.value);
  }

  return (
    <Stack space={10} className="max-w-3xl">
      <Stack space={8}>
        <BodyBaseline color="neutral-600">
          Badges are discrete recognitions of an individual's skill. We use
          badges to compose teams, support professional learning and
          development, and assign responsibilities. This is the list of
          evaluation and achievement badges available for application.
        </BodyBaseline>
        <Frame as="form" className="relative">
          <Stack
            distribution="center"
            className="absolute left-0 h-full mx-2 pointer-events-none"
          >
            <Text as="i" color="neutral-500">
              <Search className="w-6 h-6" />
            </Text>
          </Stack>
          <TextInput
            className="pl-10"
            placeholder="Search for a badge or a skill"
            aria-label="Search for a badge or a skill"
            onChange={handleFilterChange}
          />
        </Frame>
      </Stack>

      <Frame>
        {isEmpty(filteredBadges) && (
          <BodyBaseline color="neutral-700">
            No badge matches your search
          </BodyBaseline>
        )}
        {filteredBadges.map(({ id, name, badges }) => (
          <Accordion
            state="none"
            variant="small"
            title={name}
            key={id}
            isOpen={filter.length > 0}
          >
            <Stack as="ul" className="ml-5" space={4}>
              {badges.map((badge) => (
                <Frame
                  as="li"
                  className="list-disc text-neutral-700"
                  key={badge.id}
                >
                  <Inline space={4}>
                    <CoreLink
                      href={{
                        pathname: `/badges/${badge.id}`,
                        state: { title: "badges", href: currentLocation },
                      }}
                    >
                      <BodySmall
                        color={["primary", "primary-light"]}
                        className="transition-color duration-300 ease-in-out no-underline"
                      >
                        {badge.name}
                      </BodySmall>
                    </CoreLink>
                    <Tag
                      type={BADGE_KIND_TAGS[capitalize(badge.kind)]}
                      text={capitalize(badge.kind)}
                    />
                  </Inline>
                </Frame>
              ))}
            </Stack>
          </Accordion>
        ))}
      </Frame>
    </Stack>
  );
}

function BadgeListFallback() {
  return (
    <Stack space={10} className="max-w-3xl">
      <Stack space={8}>
        <Skeleton
          width="100%"
          height={80}
          color="neutral-200"
          corners="small"
          animated
        />
        <Skeleton
          color="neutral-200"
          width="100%"
          height={32}
          corners="small"
          alt="Search box"
          animated
        />
      </Stack>
      {[...Array.from({ length: 4 })].map((_, i) => (
        <Stack key={`group${i}`} space={4}>
          <Skeleton
            color="neutral-400"
            width={200}
            height={21}
            alt="Skill Name"
            animated
          />
          <Stack as="ul" space={4} className="pl-4">
            {[...new Array(random(2, 4))].map((_, i) => (
              <Frame as="li" key={i}>
                <Inline space={4}>
                  <Skeleton
                    width={random(12, 17) * 10}
                    height={22}
                    color="neutral-200"
                    animated
                  />
                  <Skeleton
                    corners="medium"
                    width={100}
                    height={20}
                    color="neutral-200"
                    animated
                  />
                </Inline>
              </Frame>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

function BadgeListPage() {
  return (
    <PageLayout title="Badges">
      <ErrorBoundary fallback={BadgeListFallback}>
        <SSRSuspense fallback={<BadgeListFallback />}>
          <BadgeList />
        </SSRSuspense>
      </ErrorBoundary>
    </PageLayout>
  );
}

export default BadgeListPage;
