import { useCollection } from "coreql";
import React from "react";
import Select from "../select";

function useSkills() {
  return useCollection(
    "skills",
    {
      fields: { skills: ["id", "name"] },
      filters: { active: true },
    },
    "denormalized",
  );
}

export default function SkillSelect({ initialSkills, ...props }) {
  const { data: activeSkills } = useSkills();
  const activeSkillIds = new Set(activeSkills.map(({ id }) => id));
  const skills = activeSkills.concat(
    initialSkills.filter(({ id }) => !activeSkillIds.has(id)),
  );

  return (
    <Select
      options={skills.map(({ id, name }) => ({
        value: id,
        label: name,
      }))}
      testId="skills"
      {...props}
    />
  );
}
