import React from "react";
import { Text, Frame, Inline, TextSize, Color } from "@ableco/baseline";
import { CheckCircleOutline } from "@baseline/icons";
import isKeyResultComplete from "../utils/is-key-result-complete";

/**
 * @param {number} props.target
 */
function formatDecimal(number) {
  const splitNumber = number.toString().split(".");
  return Number.parseInt(splitNumber[1]) === 0 ? splitNumber[0] : number;
}

/**
 * @param {Object} props
 * @param {("BooleanKeyResult"|"PercentageKeyResult"|"NumericKeyResult")} props.kind
 * @param {number} props.status
 * @param {number} props.target
 */
export function KeyResultContent({ kind, status, target, decreasing = false }) {
  const completed = isKeyResultComplete(decreasing, status, target);
  switch (kind) {
    case "BooleanKeyResult": {
      return completed ? <>Completed</> : <>Not Complete</>;
    }
    case "NumericKeyResult": {
      return (
        <>
          {formatDecimal(status)} / {formatDecimal(target)}
        </>
      );
    }
    case "PercentageKeyResult": {
      return (
        <>
          {formatDecimal(status)}% / {formatDecimal(target)}%
        </>
      );
    }
    default: {
      return null;
    }
  }
}

/**
 * @param {Object} props
 * @param {("BooleanKeyResult"|"PercentageKeyResult"|"NumericKeyResult")} props.kind
 * @param {number} [props.status=0]
 * @param {number} props.target
 */
export function KeyResultStatus({
  kind,
  status = 0,
  target,
  decreasing = false,
}) {
  const completed = isKeyResultComplete(decreasing, status, target);
  const icon = completed ? (
    <Text color={Color.Success}>
      <CheckCircleOutline data-testid="completed-icon" className="h-4 w-4" />
    </Text>
  ) : (
    <Frame
      bg={Color.Transparent}
      border={Color.Transparent}
      className="h-4 w-4"
    />
  );

  return (
    <Inline space={2}>
      {icon}
      <Text
        size={TextSize.SM}
        color={Color.Neutral600}
        leading="normal"
        whitespace="no-wrap"
        className="group-hover:text-neutral-800"
      >
        <KeyResultContent
          kind={kind}
          status={status}
          target={target}
          decreasing={decreasing}
        />
      </Text>
    </Inline>
  );
}
