import React from "react";
import { useId } from "@reach/auto-id";

import {
  Color,
  FlexAlignment,
  FlexDistribution,
  Frame,
  Inline,
  Stack,
  Touchable,
} from "@ableco/baseline";

import api from "../services/api";
import { MarkdownViewer } from "./markdown/markdown-viewer";
import { MarkdownEditor } from "./markdown/markdown-editor";
import CheckboxDeprecated from "./checkbox-deprecated";
import { Lock, Delete } from "@baseline/icons";
import { BodyBaseline, BodySmall } from "./design-system/typography-components";
import { PrimaryButton, TertiaryButton } from "./buttons/buttons";
import { CoreTooltip } from "./core-tooltip";
import { AvatarSmall } from "./design-system/avatar";

function CommentList({ comments, revalidate, receiverName, currentUserId }) {
  async function deleteComment(commentId) {
    await api.comments.find(commentId).destroy();
    revalidate();
  }

  return (
    <>
      {comments.map((comment) => (
        <Inline
          alignment={FlexAlignment.Start}
          className="w-full group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
          distribution={FlexDistribution.Between}
          key={comment.id}
          space={4}
          p={[4, 6]}
        >
          <Inline alignment={FlexAlignment.Start} className="w-full" space={4}>
            <AvatarSmall name="" url={comment.attributes.avatarUser} />

            <Stack className="mt-1 w-full">
              <MarkdownViewer value={comment.attributes.body} />
              {comment.attributes.private && (
                <Inline space={1}>
                  <Lock className="h-4 w-4 text-neutral-600" />
                  <BodySmall color={Color.Neutral600}>
                    Only you and {receiverName} can see this
                  </BodySmall>
                </Inline>
              )}
            </Stack>
          </Inline>

          {currentUserId == comment.attributes.userId && (
            <CoreTooltip label="Delete comment">
              <Touchable
                className="opacity-0 text-neutral-800 group-hover:opacity-100 transition-all duration-300 ease-in-out"
                aria-label="Delete coaching tip"
                onClick={() => deleteComment(comment.id)}
                data-testid="trash-1"
              >
                <Delete className="h-5 w-5 mt-1 pt-px text-neutral-800" />
              </Touchable>
            </CoreTooltip>
          )}
        </Inline>
      ))}
    </>
  );
}

/* Component that can act as a independent form or save to an API as part
of a bigger component.
If an `actionUrl` is passed, it will be the first, if not, the later.
*/
function CommentForm({
  senderAvatar,
  receiverName,
  initialText = "",
  initialPrivate = false,
  currentUserId,
  answerId,
  showAddComment,
  setShowAddComment,
  weeklyReportId,
  comments,
  revalidate,
  commentableType = "WeeklyReportAnswer",
}) {
  const [showFooter, setShowFooter] = React.useState(false);
  const [text, setText] = React.useState(initialText);

  const [privateState, setPrivateState] = React.useState(initialPrivate);

  function onChange(event) {
    setText(event.target.value);
  }

  function onFocus() {
    setShowFooter(true);
  }

  function onPrivateChange(event) {
    setPrivateState(!!event.target.value);
  }

  function onPrivateClick(event) {
    event.preventDefault();
    event.stopPropagation();
    setPrivateState(!privateState);
  }

  function onCheckClick() {
    event.preventDefault();
    setPrivateState(!privateState);
  }

  function onCancel() {
    setText("");
    setShowFooter(false);
    setPrivateState(false);
    if (comments.length === 0) {
      setShowAddComment(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    await api.comments.create({
      body: text,
      commentableId: answerId,
      commentableType: commentableType,
      userId: currentUserId,
      updaterId: currentUserId,
      private: privateState,
      weeklyReportId: weeklyReportId,
    });
    setText("");
    setShowFooter(false);
    revalidate();
  }

  const domId = `label:${useId()}`;

  return (
    <Frame>
      {comments.length > 0 && (
        <CommentList
          comments={comments}
          revalidate={revalidate}
          receiverName={receiverName}
          currentUserId={currentUserId}
        />
      )}

      {showAddComment && (
        <Inline
          alignment={FlexAlignment.Start}
          className="w-full group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
          p={[4, 6]}
          space={4}
        >
          <AvatarSmall name="" url={senderAvatar} />
          <Stack
            as="form"
            className="flex-grow"
            onSubmit={handleSubmit}
            space={4}
            data-testid="add-comment-form"
          >
            <MarkdownEditor
              label={domId}
              text={text}
              placeholder="Add a comment..."
              onChange={onChange}
              onFocus={onFocus}
            />
            {showFooter && (
              <Inline
                className="w-full"
                distribution={FlexDistribution.Between}
              >
                {receiverName && (
                  <Inline as="label" onClick={onPrivateClick} space={2}>
                    <CheckboxDeprecated
                      checked={privateState}
                      onChange={onPrivateChange}
                      onClick={onCheckClick}
                    />
                    {privateState}
                    <BodyBaseline color={Color.Neutral700}>
                      Send privately to {receiverName}
                    </BodyBaseline>
                  </Inline>
                )}
                <Inline space={4}>
                  <TertiaryButton
                    onClick={onCancel}
                    text="Cancel"
                    type="button"
                  />
                  <PrimaryButton
                    type="submit"
                    disabled={!text}
                    text="Add comment"
                  />
                </Inline>
              </Inline>
            )}
          </Stack>
        </Inline>
      )}
    </Frame>
  );
}

export default CommentForm;
