import {
  Flex,
  Frame,
  Inline,
  Stack,
  Touchable,
  Text,
  Skeleton,
  AccordionGroup,
  AccordionTitle,
  AccordionContent,
  Cell,
  Grid,
} from "@ableco/baseline";
import {
  CaretLeftOutline,
  CaretRightOutline,
  CaretRight,
  CaretDown,
} from "@baseline/icons";
import React, { useState } from "react";
import { WeeklyReportsLayout } from "./history-layout";
import { ErrorBoundary, SSRSuspense, useCollection } from "coreql";
import {
  addWeeks,
  isWithinInterval,
  parseISO,
  startOfWeek,
  subWeeks,
} from "date-fns";
import {
  getArrayOfDates,
  formatDateShort,
  formatJournalPeriod,
  formatWithDashes,
  setParamsToJournalUrl,
  weekStart,
} from "./utils";
import { useUserProfile } from "../../layout/user-layout";
import CoreLink from "../../core-link";
import { useHistory } from "react-router";
import { useQuery } from "../../../hooks/use-query";
import { AvatarSmall, AvatarFallbackSmall } from "../../design-system/avatar";

function getUrlParameterValue(key) {
  const url = window.location.href;
  const match = url.match("[?&]" + key + "=([^&]+)");
  return match ? match[1] : null;
}

function journalValueForOrder(journal) {
  if (journal?.reviewed) return 3;
  if (journal?.submitted) return 1;
  return 2;
}

function isThereNoReviewedJournal(journals, periodStart) {
  return journals.some(
    (journal) =>
      journal.periodStart === formatWithDashes(periodStart) &&
      journal.submitted &&
      !journal.reviewed,
  );
}

function validateDate(date, newerJournal, olderJournal) {
  if (date) {
    const dateToValidate = new Date(date.split("-"));
    if (Number.isNaN(dateToValidate.getTime())) return null;
    if (
      isWithinInterval(dateToValidate, {
        start: olderJournal,
        end: newerJournal,
      })
    )
      return formatWithDashes(dateToValidate);
  }
  return null;
}

function UserAvatar({ url, name }) {
  return (
    <Frame as="figure" className="mr-4">
      <AvatarSmall url={url} name={name} />
    </Frame>
  );
}

function UserInformation({ fullName, coreRole }) {
  return (
    <Frame>
      <Text
        size="base"
        weight="extrabold"
        as="h2"
        leading="relaxed"
        color="neutral-800"
      >
        {fullName}
      </Text>
      <Text
        as="h3"
        size="sm"
        weight="hairline"
        leading="tight"
        color="neutral-600"
      >
        {coreRole}
      </Text>
    </Frame>
  );
}

function PaginationButton({ disabled, onClick, children, dataTestId }) {
  const state = {
    DISABLED: {
      color: "neutral-400",
      className: "cursor-not-allowed",
    },
    ENABLED: {
      color: "primary",
    },
  };
  return (
    <Touchable
      onClick={disabled ? null : onClick}
      data-testid={dataTestId}
      disabled={disabled}
      className="pointer-events-auto"
    >
      <Text {...state[disabled ? "DISABLED" : "ENABLED"]}>{children}</Text>
    </Touchable>
  );
}

function Bullet({ filled = false, ...props }) {
  return (
    <Frame
      className="w-2 h-2 rounded-full mx-1 flex-shrink-0 self-center"
      bg={filled ? "primary" : "transparent"}
      border="primary"
      {...props}
    />
  );
}

function ListTeamJournalsItemFallBack({ level = 0 }) {
  return (
    <Flex
      className="p-4 w-full border-b border-neutral-300"
      style={{ paddingLeft: `${level * 24 + 32}px` }}
      distribution="between"
    >
      <Inline>
        <AvatarFallbackSmall name="" />
        <Frame p={[0, 2]}>
          <Skeleton
            className="my-2"
            color="neutral-300"
            width="150px"
            height={10}
          />
          <Skeleton color="neutral-300" width="120px" height={10} />
        </Frame>
      </Inline>
      <Text color="primary" className="self-center">
        <Skeleton color="neutral-300" width="120px" height={20} />
      </Text>
    </Flex>
  );
}

function ListTeamJournalsFallBack() {
  return (
    <Stack className="px-4">
      <Flex
        className="py-2 pl-8 pr-4 w-full border-b border-neutral-300"
        distribution="between"
      >
        <Text color="neutral-600" size="sm" leading="tight">
          Name
        </Text>
        <Text color="neutral-600" size="sm" leading="tight">
          Submit Date
        </Text>
      </Flex>
      <ListTeamJournalsItemFallBack />
    </Stack>
  );
}

function getDirectReportsWithJournalSorted(directReports, teamJournals) {
  const directReportsWithJournal = directReports.map((directReport) => {
    const reportWithJournal = { ...directReport };
    reportWithJournal.journal = {
      ...teamJournals.find(
        (teamJournal) =>
          Number.parseInt(teamJournal.userId) ===
          Number.parseInt(directReport.id),
      ),
    };
    return reportWithJournal;
  });
  return directReportsWithJournal.sort((firstDirect, secondDirect) => {
    const diffCompare =
      journalValueForOrder(firstDirect.journal) -
      journalValueForOrder(secondDirect.journal);
    if (diffCompare === 0)
      return firstDirect.fullName.localeCompare(secondDirect.fullName);
    return diffCompare;
  });
}

function TeamsJournalItemContent({
  directReport,
  isSubmitted,
  isReviewed,
  reportOnPTO,
  isDirectReport,
}) {
  const notSubmitedStatus = reportOnPTO ? "PTO" : "Not Submitted";

  return (
    <Flex className="py-4 pr-4 w-full" distribution="between">
      <Inline>
        <UserAvatar url={directReport.avatarUrl} name={directReport.fullName} />
        <UserInformation
          fullName={directReport.fullName}
          coreRole={directReport.role?.title}
        />
      </Inline>
      <Inline className="self-center">
        {isSubmitted && !isReviewed && (
          <Bullet
            filled={isDirectReport}
            data-testid={`notification-bullet-${directReport.id}`}
          />
        )}
        <Text color="neutral-600">
          {isSubmitted
            ? formatDateShort(new Date(directReport.journal?.submitted))
            : notSubmitedStatus}
        </Text>
      </Inline>
    </Flex>
  );
}

function TeamsJournalItem({ directReport, isDirectReport }) {
  const query = useQuery();
  const lastDate = query.get("reportsUntil");
  const untilDate = lastDate ? parseISO(lastDate) : weekStart(Date.now());
  const sinceDate = formatWithDashes(subWeeks(untilDate, 10));

  const isSubmitted = Boolean(directReport.journal?.submitted);
  const userOnPto = Boolean(directReport.journal?.userOnPto);
  const isReviewed = Boolean(directReport.journal?.reviewed);

  const referrerUrl = encodeURIComponent(
    `/weekly_reports/teams_journal?reportsSince=${sinceDate}&reportsUntil=${formatWithDashes(
      untilDate,
    )}`,
  );

  const content = (
    <TeamsJournalItemContent
      directReport={directReport}
      isReviewed={isReviewed}
      isSubmitted={isSubmitted}
      isDirectReport={isDirectReport}
      reportOnPTO={userOnPto}
    />
  );

  if (isSubmitted)
    return (
      <CoreLink
        className="hover:no-underline w-full"
        href={`/v2_weekly_reports/${directReport.journal?.id}?referrer=${referrerUrl}`}
        data-testid={`link-to-weekly-report-${directReport.journal.id}`}
      >
        {content}
      </CoreLink>
    );

  return content;
}

function TeamsJournalItemAccordionContent({
  currentPeriod,
  directReport,
  level,
}) {
  const { data: directReports } = useCollection(
    "users",
    {
      fields: {
        users: ["avatarUrl", "fullName", "joinDate", "role", "isManager"],
        roles: ["title"],
      },
      filters: {
        active: true,
        managerId: directReport.id,
      },
      included: ["role"],
      sort: ["joinDate"],
    },
    "denormalized",
  );
  const { data: teamJournals } = useCollection(
    "weekly_reports",
    {
      fields: {
        weeklyReports: [
          "periodStart",
          "submitted",
          "userOnPto",
          "reviewed",
          "userId",
        ],
      },
      filters: {
        periodStart: currentPeriod,
        forManager: directReport.id,
      },
    },
    "denormalized",
  );
  const directReportsWithJournalSorted = getDirectReportsWithJournalSorted(
    directReports,
    teamJournals,
  );

  return (
    <AccordionContent>
      <Frame>
        {directReportsWithJournalSorted.map((directReport) => (
          <TeamsJournalItemAccordion
            directReport={directReport}
            currentPeriod={currentPeriod}
            level={level}
            key={directReport.id}
          />
        ))}
      </Frame>
    </AccordionContent>
  );
}

function TeamsJournalItemAccordion({ directReport, currentPeriod, level = 0 }) {
  const [isOpen, setIsOpen] = useState(false);
  if (directReport.isManager)
    return (
      <AccordionGroup>
        <Inline
          className="border-b border-neutral-300 hover:bg-primary-lighter transition-all duration-300 ease-in-out"
          style={{ paddingLeft: `${24 * level}px` }}
        >
          <AccordionTitle
            onClick={() => setIsOpen((prevState) => !prevState)}
            data-testid={`expand-direct-reports-${directReport.id}`}
          >
            {isOpen ? (
              <CaretDown className="w-4 h-4 mx-2" />
            ) : (
              <CaretRight className="w-4 h-4 mx-2" />
            )}
          </AccordionTitle>
          <TeamsJournalItem
            directReport={directReport}
            isDirectReport={level === 0}
          />
        </Inline>
        <ErrorBoundary fallback={ListTeamJournalsItemFallBack}>
          <SSRSuspense
            fallback={<ListTeamJournalsItemFallBack level={level + 1} />}
          >
            <TeamsJournalItemAccordionContent
              currentPeriod={currentPeriod}
              directReport={directReport}
              level={level + 1}
            />
          </SSRSuspense>
        </ErrorBoundary>
      </AccordionGroup>
    );
  return (
    <Frame
      className="border-b border-neutral-300 hover:bg-primary-lighter transition-all duration-300 ease-in-out"
      style={{
        paddingLeft: `${24 * level + 32}px`,
      }}
    >
      <TeamsJournalItem
        directReport={directReport}
        isDirectReport={level === 0}
      />
    </Frame>
  );
}

function ListTeamJournals({ currentPeriod, untilDate, directReports }) {
  const periodInCurrentTimeFrame = isWithinInterval(parseISO(currentPeriod), {
    start: subWeeks(untilDate, 10),
    end: untilDate,
  });

  const currentUser = useUserProfile();
  const { data: teamJournals } = useCollection(
    "weekly_reports",
    {
      fields: {
        weeklyReports: [
          "periodStart",
          "submitted",
          "userOnPto",
          "reviewed",
          "userId",
        ],
      },
      filters: {
        periodStart: periodInCurrentTimeFrame ? currentPeriod : untilDate,
        forManager: currentUser.id,
      },
    },
    "denormalized",
  );
  const directReportsWithJournalSorted = getDirectReportsWithJournalSorted(
    directReports,
    teamJournals,
  );
  return (
    <Stack className="px-4">
      <Flex
        className="py-2 pr-4 pl-8 w-full border-b border-neutral-300"
        distribution="between"
      >
        <Text color="neutral-600" size="sm" leading="tight">
          Name
        </Text>
        <Text color="neutral-600" size="sm" leading="tight">
          Submit Date
        </Text>
      </Flex>
      {directReportsWithJournalSorted.map((directReport) => (
        <TeamsJournalItemAccordion
          directReport={directReport}
          currentPeriod={currentPeriod}
          key={directReport.id}
        />
      ))}
    </Stack>
  );
}

function ListOfJournalPeriodsFallback() {
  return (
    <Stack>
      {[...Array.from({ length: 11 }).keys()].map((i) => (
        <Frame key={i} className="p-5 text-left border-b border-neutral-300">
          <Flex distribution="between">
            <Skeleton width="120px" height={20} color="neutral-300" />
          </Flex>
        </Frame>
      ))}
      <Flex className="p-4" distribution="between">
        <PaginationButton disabled={true}>
          <Inline>
            <CaretLeftOutline className="w-4 h-4" />
            Newer
          </Inline>
        </PaginationButton>
        <PaginationButton disabled={true}>
          <Inline>
            Older <CaretRightOutline className="w-4 h-4" />
          </Inline>
        </PaginationButton>
      </Flex>
    </Stack>
  );
}

function getLastPeriodWithData(teamJournals, arrayOfPeriods) {
  for (const period of arrayOfPeriods) {
    const periodWithFormat = formatWithDashes(period);
    const journal = teamJournals.find(
      (journal) =>
        journal.submitted && journal.periodStart === periodWithFormat,
    );
    if (journal) return periodWithFormat;
  }
  return null;
}

function getNewCurrentPeriod(
  dateParameter,
  lastPeriodWithData,
  newSinceDate,
  newUntilDate,
) {
  if (
    dateParameter &&
    isWithinInterval(new Date(dateParameter.split("-")), {
      start: newSinceDate,
      end: newUntilDate,
    })
  ) {
    return dateParameter;
  }
  if (
    lastPeriodWithData &&
    isWithinInterval(new Date(lastPeriodWithData.split("-")), {
      start: newSinceDate,
      end: newUntilDate,
    })
  ) {
    return lastPeriodWithData;
  }
  return formatWithDashes(newUntilDate);
}

function ListOfJournalPeriods({
  currentPeriod,
  setCurrentPeriod,
  lastPeriodWithData,
  allTeamJournals,
  dateParameter,
  newerJournal,
  olderJournal,
}) {
  const history = useHistory();
  const query = useQuery();
  const lastDate = query.get("reportsUntil");
  const untilDate = lastDate ? parseISO(lastDate) : newerJournal;
  const sinceDate = subWeeks(untilDate, 10);
  const [periodSelected, setPeriodSelected] = useState(currentPeriod);

  const arrayOfPeriods = getArrayOfDates(untilDate, sinceDate);
  const teamJournals = allTeamJournals.filter((journal) =>
    isWithinInterval(new Date(journal.periodStart.split("-")), {
      start: sinceDate,
      end: untilDate,
    }),
  );

  return (
    <Stack>
      {arrayOfPeriods.map((periodStart) => {
        const isThereNoReviewed = isThereNoReviewedJournal(
          teamJournals,
          periodStart,
        );
        const periodStartFormatted = formatWithDashes(periodStart);
        const isSelected = periodStartFormatted === periodSelected;
        return (
          <Touchable
            className="p-5 text-left border-b border-neutral-300 transition-all duration-300 ease-in-out"
            key={periodStartFormatted}
            bg={isSelected ? "primary-lighter" : "white"}
            onClick={(event) => {
              event.preventDefault();
              setPeriodSelected(periodStartFormatted);
              setCurrentPeriod(periodStartFormatted);
            }}
          >
            <Flex distribution="between">
              <Text
                className="transition-all duration-300 ease-in-out"
                color="neutral-700"
                weight={isSelected ? "semibold" : "normal"}
              >
                {formatJournalPeriod(periodStart)}
              </Text>
              {isThereNoReviewed && (
                <Bullet
                  filled={true}
                  data-testid={`notification-bullet-${periodStartFormatted}`}
                />
              )}
            </Flex>
          </Touchable>
        );
      })}
      <Flex className="p-4 h-full" distribution="between">
        <PaginationButton
          disabled={isWithinInterval(newerJournal, {
            start: sinceDate,
            end: untilDate,
          })}
          onClick={(event) => {
            event.preventDefault();
            const newCurrentPeriod = getNewCurrentPeriod(
              dateParameter,
              lastPeriodWithData,
              addWeeks(untilDate, 1),
              addWeeks(untilDate, 11),
            );
            setPeriodSelected(newCurrentPeriod);
            setCurrentPeriod(newCurrentPeriod);
            history.push(
              setParamsToJournalUrl(
                "/weekly_reports/teams_journal",
                addWeeks(untilDate, 11),
                11,
              ),
            );
          }}
          dataTestId="newer-button"
        >
          <Inline>
            <CaretLeftOutline className="w-4 h-4" />
            Newer
          </Inline>
        </PaginationButton>
        <PaginationButton
          disabled={isWithinInterval(olderJournal, {
            start: sinceDate,
            end: untilDate,
          })}
          onClick={(event) => {
            event.preventDefault();
            const newCurrentPeriod = getNewCurrentPeriod(
              dateParameter,
              lastPeriodWithData,
              subWeeks(sinceDate, 11),
              subWeeks(sinceDate, 1),
            );
            setPeriodSelected(newCurrentPeriod);
            setCurrentPeriod(newCurrentPeriod);
            history.push(
              setParamsToJournalUrl(
                "/weekly_reports/teams_journal",
                subWeeks(sinceDate, 1),
                11,
              ),
            );
          }}
          dataTestId="older-button"
        >
          <Inline>
            Older <CaretRightOutline className="w-4 h-4" />
          </Inline>
        </PaginationButton>
      </Flex>
    </Stack>
  );
}

function TeamsJournalContent() {
  const currentUser = useUserProfile();
  const { data: directReports } = useCollection(
    "users",
    {
      fields: {
        users: ["avatarUrl", "fullName", "joinDate", "role", "isManager"],
        roles: ["title"],
      },
      filters: {
        active: true,
        managerId: currentUser.id,
      },
      included: ["role"],
      sort: ["joinDate"],
    },
    "denormalized",
  );

  const { data: allTeamJournals } = useCollection(
    "weekly_reports",
    {
      fields: {
        weeklyReports: ["periodStart", "submitted", "reviewed", "userId"],
      },
      filters: {
        forManager: currentUser.id,
      },
    },
    "denormalized",
  );

  const olderDirectReport = directReports[0];
  const newerJournal = startOfWeek(new Date(), { weekStartsOn: 1 });
  const olderJournal = startOfWeek(
    olderDirectReport.joinDate
      ? new Date(olderDirectReport.joinDate.split("-"))
      : newerJournal,
    {
      weekStartsOn: 1,
    },
  );

  const query = useQuery();
  const lastDate = query.get("reportsUntil");
  const untilDate = lastDate ? parseISO(lastDate) : newerJournal;

  const dateParameter = validateDate(
    getUrlParameterValue("date"),
    untilDate,
    olderJournal,
  );

  const lastPeriodWithData = getLastPeriodWithData(
    allTeamJournals,
    getArrayOfDates(newerJournal, olderJournal),
  );

  const initialCurrentPeriod =
    dateParameter || lastPeriodWithData || formatWithDashes(untilDate);
  const [currentPeriod, setCurrentPeriod] = useState(initialCurrentPeriod);

  return (
    <Frame className="pb-8">
      <Grid
        border="neutral-300"
        className="divide-x divide-neutral-300"
        style={{ borderTop: "none" }}
        col={12}
      >
        <Cell start={1} end={4}>
          <ErrorBoundary fallback={ListOfJournalPeriodsFallback}>
            <SSRSuspense fallback={<ListOfJournalPeriodsFallback />}>
              <ListOfJournalPeriods
                setCurrentPeriod={setCurrentPeriod}
                olderDirectReport={directReports[0]}
                lastPeriodWithData={lastPeriodWithData}
                allTeamJournals={allTeamJournals}
                newerJournal={newerJournal}
                olderJournal={olderJournal}
                currentPeriod={currentPeriod}
                dateParameter={dateParameter}
              />
            </SSRSuspense>
          </ErrorBoundary>
        </Cell>
        <Cell start={4} end={13}>
          <ErrorBoundary fallback={ListTeamJournalsFallBack}>
            <SSRSuspense fallback={<ListTeamJournalsFallBack />}>
              <ListTeamJournals
                currentPeriod={currentPeriod}
                untilDate={untilDate}
                directReports={directReports}
              />
            </SSRSuspense>
          </ErrorBoundary>
        </Cell>
      </Grid>
    </Frame>
  );
}

export function Fallback() {
  return (
    <Frame className="pb-8">
      <Grid
        border="neutral-300"
        className="divide-x divide-neutral-300"
        col={12}
        style={{ borderTop: "none" }}
      >
        <Cell start={1} end={4}>
          <ListOfJournalPeriodsFallback />
        </Cell>
        <Cell start={4} end={13}>
          <ListTeamJournalsFallBack />
        </Cell>
      </Grid>
    </Frame>
  );
}

export default function TeamsJournalHistory() {
  return (
    <WeeklyReportsLayout>
      <ErrorBoundary fallback={Fallback}>
        <SSRSuspense fallback={<Fallback />}>
          <TeamsJournalContent />
        </SSRSuspense>
      </ErrorBoundary>
    </WeeklyReportsLayout>
  );
}
