// Methods
import { union } from "lodash";
// Constants
import ActionTypes from "utilization/constants/ActionTypes";

export default (state = [], action) => {
  const { rowId, type } = action;

  switch (type) {
    case ActionTypes.TOGGLE_DETAIL_VISIBILITY:
      return state.includes(rowId)
        ? state.filter((otherRowId) => otherRowId !== rowId)
        : union(state, [rowId]);

    default:
      return state;
  }
};
