import { resolve } from "url";

const BASE_URL = "/api/v2/";

export class APIError extends Error {
  constructor(message, response, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }
    this.message = message;
    this.response = response;
  }
}

export class ClientError extends Error {
  constructor(message, response, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ClientError);
    }
    this.message = message;
    this.response = response;
  }
}

export async function network(path, config = {}, format = "json") {
  const response = await fetch(resolve(BASE_URL, path), {
    ...config,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      ...(config.headers || {}),
    },
  });

  if (response.status >= 500 && response.status <= 599) {
    throw new APIError("API Server Error", await response.text());
  }

  if (response.status >= 400 && response.status <= 499) {
    throw new ClientError("Client Error", await response[format]());
  }

  if (response.status === 204) return;

  return await response[format]();
}
