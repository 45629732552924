import React, { useEffect, useState } from "react";
import { createConsumer } from "@rails/actioncable";

export const ActionCableContext = React.createContext();

export function ActionCableProvider({ children }) {
  const [consumer] = useState(() => createConsumer());

  useEffect(() => () => consumer.disconnect(), []);

  return (
    <ActionCableContext.Provider value={consumer}>
      {children}
    </ActionCableContext.Provider>
  );
}
