// Modules
import { format } from "date-fns";
import produce from "immer";
import { isEqual, values } from "lodash";
// Constants
import ActionTypes from "budget/constants/ActionTypes";
// Actions
import * as API from "core-redux/actions/api";

const getClassName = (col) =>
  col === 0 ? "project-roles" : col === 1 ? "assignee" : null;

export default (updatedCols, newCols) => (dispatch, getState) => {
  const { periods } = getState().grid;

  const data = produce(getState().grid.data, (draft) => {
    updatedCols.forEach(({ row, col, value }) => {
      draft[row][col] = { ...draft[row][col], value };
    });

    if (newCols) {
      newCols.forEach(({ row, col, value }) => {
        draft[row] = draft[row] ? draft[row] : [];
        draft[row][col] = {
          value,
          className: getClassName(col),
        };
      });
    }
  });

  dispatch({
    data,
    periods,
    type: ActionTypes.UPDATE_GRID,
  });

  dispatch(updateProjectAllocations(periods, data));
};

const updateProjectAllocations = (periods, data) => (dispatch, getState) => {
  const { projectId } = getState();
  const placeholders = values(getState().entities.placeholders.byId);
  const projectRoles = values(getState().entities.projectRoles.byId);
  const users = values(getState().entities.users.byId);

  const [batchCreate, batchDestroy, batchUpdate] = [[], [], []];

  for (const row of data) {
    let projectRoleIds = [];
    if (row[0].value) {
      const projectRoleNames = row[0].value.split(", ");
      projectRoleIds = projectRoles
        .filter((projectRole) => projectRoleNames.includes(projectRole.name))
        .map((projectRole) => projectRole.id);
    }

    let assignee, assigneeType;
    assignee = users.find((user) => user.fullName === row[1].value);
    if (assignee) {
      assigneeType = "User";
    } else {
      assignee = placeholders.find(
        (placeholder) => placeholder.name === row[1].value,
      );
      if (assignee) {
        assigneeType = "Placeholder";
      }
    }

    for (const [col, cell] of row.entries()) {
      if (col < 2) {
        return true;
      }

      const periodStart = format(periods[col - 2], "yyyy-MM-dd");

      if (projectRoleIds.length > 0 && assignee) {
        const attributes = {
          id: cell.id,
          assigneeId: assignee.id,
          assigneeType: assigneeType,
          hours: cell.value,
          periodStart,
          projectId,
          projectRoleIds,
        };

        if (
          cell.id &&
          isEqual(cell.projectRoleIds, projectRoleIds) &&
          cell.assigneeId === assignee.id &&
          cell.assigneeType === assigneeType &&
          cell.periodStart === periodStart &&
          cell.hours === Number.parseInt(cell.value)
        ) {
          return;
        }

        if (cell.id && cell.value !== "") {
          batchUpdate.push(attributes);
        } else if (cell.id && cell.value === "") {
          batchDestroy.push(cell.id);
        } else if (cell.value && cell.value !== "") {
          batchCreate.push(attributes);
        }
      } else {
        if (cell.id && cell.value && cell.value !== "") {
          // TODO This case silently fails.
          // dispatch(API.update("projectAllocations", cell.id, attributes));
        } else if (cell.id && cell.value === "") {
          batchDestroy.push(cell.id);
        }
      }
    }
  }

  if (batchCreate.length > 0) {
    dispatch(API.batchCreate("projectAllocations", batchCreate));
  }

  if (batchDestroy.length > 0) {
    dispatch(API.batchDestroy("projectAllocations", batchDestroy));
  }

  if (batchUpdate.length > 0) {
    dispatch(API.batchUpdate("projectAllocations", batchUpdate));
  }
};
