import React, { useMemo } from "react";
import { ErrorBoundary, SSRSuspense, useCollection, useResource } from "coreql";
import { useParams } from "react-router";
import UserLayout, {
  Fallback as UserLayoutFallback,
} from "../layout/user-layout";
import { Color, Corners, Frame, Skeleton, Stack, Text } from "@ableco/baseline";
import { Heading } from "../design-system/typography-components";
import CapabilitiesTable, {
  CapabilitiesTableFallback,
} from "../capabilities-table/capabilities-table";
import { buildTableData } from "../capabilities-table/capabilities-table-utils";
import { isEmpty } from "lodash";
import { wrap } from "../weekly-report/utils";
import useCurrentUser from "../../hooks/use-current-user";

function UserCapabilitiesFallback() {
  return (
    <>
      <UserLayoutFallback />
      <Stack p={[0, 0, 8, 0]} space={8} as="article">
        <Stack space={2}>
          <Frame p={[0, 8]}>
            <Skeleton
              color={Color.Neutral400}
              corners={Corners.LargeRounded}
              animated
              width="280px"
              height="25px"
            />
          </Frame>
          <CapabilitiesTableFallback />
        </Stack>
      </Stack>
    </>
  );
}

function UserRoleCapabilities({
  userLevel,
  levelKind,
  userRole,
  userBadges,
  isCurrentUserDirectManager,
  metObservableBehaviours,
  userId,
  mutateUser,
}) {
  const hasManagerLevel = levelKind === "manager";

  let requestFilters = {};

  if (hasManagerLevel) {
    requestFilters =
      userRole.title == "Software Engineering Manager"
        ? { kind: "role", roles: userRole.id }
        : { roles: [userRole.id, null] };
  } else {
    requestFilters = {
      "observableBehaviours.level.kind": levelKind,
      roles: userRole.id,
    };
  }

  const { data } = useCollection("capabilities", {
    fields: {
      roles: ["capabilities"],
      capabilities: ["name", "skill", "observableBehaviours"],
      observableBehaviours: ["level", "description", "badges"],
      badges: ["name", "archivedAt"],
    },
    filters: {
      ...requestFilters,
      "observableBehaviours.level.level": [userLevel, userLevel + 1],
      active: true,
    },
    included: [
      "skill",
      "observableBehaviours",
      "observableBehaviours.level",
      "observableBehaviours.badges",
    ],
  });

  const { skills, levels } = useMemo(() => buildTableData(data), [data]);
  const startOffset = levels.findIndex((level) => level.level === userLevel);

  if (isEmpty(levels) || isEmpty(skills)) {
    return (
      <Frame>
        <Text color="neutral-800" as="p">
          No capabilities available for this user
        </Text>
      </Frame>
    );
  }

  return (
    <Stack space={2}>
      <Frame>
        <Heading as="h1">Capabilities</Heading>
      </Frame>
      <CapabilitiesTable
        skills={skills}
        levels={levels}
        startOffset={startOffset}
        showControls={false}
        role={userRole}
        userBadges={userBadges}
        isCurrentUserDirectManager={isCurrentUserDirectManager}
        metObservableBehaviours={metObservableBehaviours}
        userId={userId}
        mutateUser={mutateUser}
        renderMetRequirement={true}
      />
    </Stack>
  );
}

function ManagerCapabilities({
  userBadges,
  isCurrentUserDirectManager,
  metObservableBehaviours,
  userId,
  mutateUser,
}) {
  const managerLevelM1 = useCollection(
    "levels",
    {
      fields: {
        levels: ["level", "kind", "code"],
      },
      filters: { code: "M1" },
    },
    "denormalized",
  ).data;

  const MAX_MANAGER_LEVEL_FOR_IC = managerLevelM1[0]?.level || 0;

  const { data } = useCollection("capabilities", {
    fields: {
      roles: ["capabilities"],
      capabilities: ["name", "skill", "observableBehaviours"],
      observableBehaviours: ["level", "description", "badges"],
      badges: ["name", "archivedAt"],
    },
    filters: {
      "observableBehaviours.level.level": MAX_MANAGER_LEVEL_FOR_IC,
      kind: "manager",
      active: true,
    },
    included: [
      "skill",
      "observableBehaviours",
      "observableBehaviours.level",
      "observableBehaviours.badges",
    ],
  });

  const { skills, levels } = useMemo(() => buildTableData(data), [data]);
  const startOffset = levels.findIndex(
    (level) => level.level === MAX_MANAGER_LEVEL_FOR_IC,
  );

  if (isEmpty(levels)) return null;

  return (
    <Stack space={2}>
      <Frame>
        <Heading as="h2">Capabilities for Managers</Heading>
      </Frame>
      <CapabilitiesTable
        skills={skills}
        levels={[levels[0]]}
        startOffset={startOffset}
        showControls={false}
        role={{ title: "Manager" }}
        userBadges={userBadges}
        isCurrentUserDirectManager={isCurrentUserDirectManager}
        metObservableBehaviours={metObservableBehaviours}
        userId={userId}
        mutateUser={mutateUser}
        renderMetRequirement={true}
      />
    </Stack>
  );
}

function ProfileCapabilities({ userId }) {
  const { data: user, mutate: mutateUser } = useResource(
    "users",
    userId,
    {
      fields: {
        users: [
          "isManager",
          "level",
          "role",
          "userBadges",
          "managerId",
          "metObservableBehaviours",
        ],
        levels: ["level", "kind"],
        roles: ["title", "kind"],
        userBadges: ["badgeId", "status"],
        metObservableBehaviours: ["observableBehaviourId", "requirementsMetAt"],
      },
      included: ["level", "role", "userBadges", "metObservableBehaviours"],
    },
    "denormalized",
  );

  const { data: currentUser } = useCurrentUser();

  const isCurrentUserDirectManager =
    Number.parseInt(currentUser.id) == user.managerId;

  const hasRole = !!user?.role?.id;
  const hasLevel = !!user?.level?.id;
  const hasICLevel = hasLevel && user.level.kind === "individual_contributor";

  const showRoleCapabilities = hasRole && hasLevel;
  const showManagerCapabilities = user.isManager && hasICLevel;
  const role = user?.role;

  const userBadges = wrap(user.userBadges);
  const metObservableBehaviours = wrap(user.metObservableBehaviours);

  return (
    <Stack p={[0, 0, 8, 0]} space={8} as="article">
      {showRoleCapabilities || showManagerCapabilities ? (
        <>
          {showRoleCapabilities && (
            <UserRoleCapabilities
              userLevel={user.level.level}
              levelKind={user.level.kind}
              userRole={role}
              userBadges={userBadges}
              isCurrentUserDirectManager={isCurrentUserDirectManager}
              metObservableBehaviours={metObservableBehaviours}
              userId={user.id}
              mutateUser={mutateUser}
            />
          )}
          {showManagerCapabilities && (
            <ManagerCapabilities
              userBadges={userBadges}
              isCurrentUserDirectManager={isCurrentUserDirectManager}
              metObservableBehaviours={metObservableBehaviours}
              userId={user.id}
              mutateUser={mutateUser}
            />
          )}
        </>
      ) : (
        <Frame p={[0, 8]}>
          <Text color="neutral-800" as="p">
            No capabilities available for this user
          </Text>
        </Frame>
      )}
    </Stack>
  );
}

export default function UserCapabilities() {
  const { id: userId } = useParams();

  return (
    <ErrorBoundary fallback={UserCapabilitiesFallback}>
      <SSRSuspense fallback={<UserCapabilitiesFallback />}>
        <UserLayout id={userId}>
          <ProfileCapabilities userId={userId} />
        </UserLayout>
      </SSRSuspense>
    </ErrorBoundary>
  );
}
