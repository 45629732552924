// Modules
import {
  addMonths,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from "date-fns";
import React from "react";
import queryString from "query-string";
// Components
import { Redirect } from "react-router-dom";

const DefaultRedirect = () => {
  const today = new Date();
  const defaultSearch = {
    detailBy: "role",
    endDate: format(endOfMonth(addMonths(today, 3)), "yyyy-MM-dd"),
    groupBy: "product",
    periodIncrement: "month",
    startDate: format(startOfMonth(subMonths(today, 3)), "yyyy-MM-dd"),
  };

  return <Redirect to={{ search: queryString.stringify(defaultSearch) }} />;
};

export default DefaultRedirect;
