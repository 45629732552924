import React from "react";
import {
  Button,
  ButtonState,
  ButtonVariant,
  Color,
  Inline,
  Shadow,
  Text,
  TextLeading,
  Touchable,
} from "@ableco/baseline";
import { Lightbulb, LightbulbOn } from "@baseline/icons";
import clsx from "clsx";
import { CoreTooltip } from "../core-tooltip";

export function TipsButton({ onClick }) {
  return (
    <CoreTooltip label="Tips and best practices">
      <Touchable onClick={onClick} className="group border-0 outline-none">
        <Text color="primary-light" className="hidden group-hover:block">
          <LightbulbOn className="w-4 h-4 " />
        </Text>
        <Text color={Color.Neutral600} className="group-hover:hidden">
          <Lightbulb className="w-4 h-4 " />
        </Text>
        <Text srOnly>Tips and best practices</Text>
      </Touchable>
    </CoreTooltip>
  );
}

export function TertiaryButton({
  onClick,
  text,
  LeftIcon,
  RightIcon,
  disabled,
  className,
  small,
  ...props
}) {
  const disabledProps = {
    variant: ButtonVariant.Solid,
    bg: Color.Neutral200,
    color: Color.Neutral200,
    border: Color.Neutral400,
    state: ButtonState.Disabled,
  };
  return (
    <Button
      onClick={onClick}
      variant={ButtonVariant.Outline}
      color={Color.Neutral400}
      p={small ? [1, 2] : [2, 4]}
      bg={Color.White}
      shadow={Shadow.None}
      className={clsx(
        "group outline-none focus:shadow-outline transition-all duration-300 ease-in-out pointer-events-auto",
        !disabled && "hover:border-neutral-600",
        className,
      )}
      aria-label={text}
      {...(disabled ? disabledProps : {})}
      {...props}
    >
      <Inline space={small ? 1 : 2}>
        {LeftIcon && (
          <Text color={disabled ? Color.Neutral500 : Color.Neutral600}>
            <LeftIcon className={small && "w-5 h-5"} />
          </Text>
        )}
        <Text
          color={disabled ? Color.Neutral500 : Color.Neutral700}
          leading={TextLeading.Normal}
          className="whitespace-no-wrap"
        >
          {text}
        </Text>

        {RightIcon && (
          <Text color={disabled ? Color.Neutral500 : Color.Neutral600}>
            <RightIcon className={small && "w-5 h-5"} />
          </Text>
        )}
      </Inline>
    </Button>
  );
}

export function AlertButton({ onClick, text, disabled = false, ...props }) {
  const disabledProps = {
    border: Color.Neutral400,
    bg: Color.Neutral200,
    variant: ButtonVariant.Solid,
    state: ButtonState.Disabled,
    className: "pointer-events-auto",
  };

  return (
    <Button
      variant={ButtonVariant.Outline}
      state={ButtonState.Normal}
      border={Color.Alert}
      bg={Color.White}
      onClick={onClick}
      shadow={Shadow.None}
      className="group hover:border-alert-light transition-all duration-300 ease-in-out pointer-events-auto"
      {...(disabled ? disabledProps : {})}
      {...props}
    >
      <Text
        color={disabled ? Color.Neutral500 : Color.Alert}
        leading={TextLeading.Normal}
        className="group-hover:text-alert-light transition-all duration-300 ease-in-out"
      >
        {text}
      </Text>
    </Button>
  );
}

export function SecondaryButton({
  onClick,
  text,
  LeftIcon,
  RightIcon,
  disabled,
  className,
  small,
  ...props
}) {
  const disabledProps = {
    variant: ButtonVariant.Solid,
    bg: Color.Neutral200,
    color: Color.Neutral200,
    border: Color.Neutral400,
    state: ButtonState.Disabled,
  };
  return (
    <Button
      onClick={onClick}
      variant={ButtonVariant.Outline}
      color={Color.Primary}
      p={small ? [1, 2] : [2, 4]}
      bg={Color.White}
      border={Color.Primary}
      shadow={Shadow.None}
      className={clsx(
        "group",
        "outline-none focus:shadow-outline transition-all duration-300 ease-in-out pointer-events-auto",
        !disabled && "hover:border-primary-light",
        className,
      )}
      aria-label={text}
      {...(disabled ? disabledProps : {})}
      {...props}
    >
      <Inline space={small ? 1 : 2}>
        {LeftIcon && (
          <Text
            color={disabled ? Color.Neutral500 : Color.Primary}
            className={clsx(
              "transition-all duration-300 ease-in-out",
              !disabled && "group-hover:text-primary-light",
            )}
          >
            <LeftIcon className={small && "w-5 h-5"} />
          </Text>
        )}

        <Text
          color={disabled ? Color.Neutral500 : Color.Primary}
          leading={TextLeading.Normal}
          className={clsx(
            "transition-all duration-300 ease-in-out whitespace-no-wrap",
            !disabled && "group-hover:text-primary-light",
          )}
        >
          {text}
        </Text>

        {RightIcon && (
          <Text
            color={disabled ? Color.Neutral500 : Color.Primary}
            className={clsx(
              "transition-all duration-300 ease-in-out",
              !disabled && "group-hover:text-primary-light",
            )}
          >
            <RightIcon className={small && "w-5 h-5"} />
          </Text>
        )}
      </Inline>
    </Button>
  );
}

export function PrimaryButton({
  onClick,
  text,
  LeftIcon,
  RightIcon,
  disabled,
  className,
  small,
  ...props
}) {
  const disabledProps = {
    border: Color.Neutral400,
    color: Color.Neutral200,
    state: ButtonState.Disabled,
  };

  return (
    <Button
      onClick={onClick}
      variant={ButtonVariant.Solid}
      color={[Color.Primary, Color.PrimaryLight]}
      p={small ? [1, 2] : [2, 4]}
      shadow={Shadow.None}
      className={`outline-none focus:shadow-outline transition-all duration-300 ease-in-out pointer-events-auto ${
        className || ""
      }`}
      state={ButtonState.Normal}
      aria-label={text}
      {...(disabled ? disabledProps : {})}
      {...props}
    >
      <Inline space={small ? 1 : 2}>
        {LeftIcon && (
          <Text color={disabled ? Color.Neutral500 : Color.White}>
            <LeftIcon className={small && "w-5 h-5"} />
          </Text>
        )}

        <Text
          color={disabled ? Color.Neutral500 : Color.White}
          leading={TextLeading.Normal}
          className="whitespace-no-wrap"
        >
          {text}
        </Text>

        {RightIcon && (
          <Text color={disabled ? Color.Neutral500 : Color.White}>
            <RightIcon className={small && "w-5 h-5"} />
          </Text>
        )}
      </Inline>
    </Button>
  );
}
