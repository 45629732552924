import React from "react";
import styled, { css } from "styled-components";
import MemberGraph from "./member-graph";
import ProjectInfoTimeline from "./project-info-timeline";
import { colorPalette } from "../style-guide/colors";
import { fontWeights, fonts, fontSizes } from "../style-guide/typography";
import AssignLink from "./assign-link";
import noop from "lodash/noop";
import compact from "lodash/compact";

const firstGraph = css`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const EmptyGraph = styled.div`
  border-radius: 5px 0px 0px 5px;
  background: ${(props) =>
    props.isBench ? colorPalette.bluegrey50 : colorPalette.babyBlue600};
  height: 32px;
  margin: 4px 0 4px 5px;
`;

const lastGraph = css`
  margin-bottom: 8px;
  padding-top: 8px;
`;

const ProjectTimelineWrap = styled.div`
  display: flex;
  min-width: 900px;
`;

const BenchList = css`
  border: none;
  background: transparent;
  margin-left: auto;
`;

const WhiteBoxBench = css`
  border: none;
  background: transparent;
`;

const MemberList = React.memo(styled.div`
  border-top: 1px solid ${colorPalette.bluegrey30};
  border-bottom: 1px solid ${colorPalette.bluegrey30};
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 205px;
  background-color: ${colorPalette.white};
  ${(props) => props.isBench && BenchList};
`);

const Name = styled.span`
  color: ${(props) =>
    props.hasUser ? colorPalette.deepBlue500 : colorPalette.pink500};
  font-weight: ${(props) =>
    props.hasUser ? fontWeights.regular : fontWeights.bold};
`;

const Role = styled.span`
  flex: 1 0 0;
  color: ${colorPalette.bluegrey90};
  font-weight: ${fontWeights.semiBold};
  font-family: ${fonts.sansSerif};
`;

const Member = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  font-size: ${fontSizes.smallX}px;
  line-height: 20px;
`;

const MemberInfo = styled.div`
  flex: 0 0 160px;
`;

const Graph = styled.div`
  width: 100%;
  background-size: calc(100% / 12) 100%;
  border-right: 1px solid ${colorPalette.bluegrey30};
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    ${colorPalette.bluegrey30} 1px,
    transparent 1px,
    transparent
  );
  background-position: left 0 top;
  ${(props) => (props.type === "first" ? firstGraph : null)};
  ${(props) => (props.type === "last" ? lastGraph : null)};
  ${(props) => (props.type === "middle" ? "padding: 8px 0;" : null)};
`;

const WhiteBox = styled.div`
  ${({ startDateIndex }) =>
    startDateIndex > 0 &&
    css`
      margin-left: ${({ startDateIndex }) => (startDateIndex / 12) * 100}%;
    `}
  ${({ endDateIndex }) =>
    endDateIndex >= 0 &&
    css`
      margin-right: ${({ endDateIndex }) => ((11 - endDateIndex) / 12) * 100}%;
    `}
  background-color: ${colorPalette.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;
  height: 100%;
  border: 1px solid #eceef2;
  border-right: 0;
  ${(props) => props.isBench && WhiteBoxBench};
  transition: all 0.3s;
`;

const Wrapper = styled.div`
  flex: 0 0 420px;
  padding: 8px 0;
  display: flex;
`;

function GenericTimeline({
  members,
  assignmentGroups,
  project,
  product,
  responsible,
  type,
  hasAllocationInTimelineView,
  startDateIndex,
  endDateIndex,
  projectStart,
  projectEnd,
  isBench = false,
  editPermission = false,
  projectSeats,
  onExtend = noop,
}) {
  return (
    <ProjectTimelineWrap>
      <Wrapper>
        {!isBench && (
          <ProjectInfoTimeline
            project={project}
            product={product}
            responsible={responsible}
            editPermission={editPermission}
            onExtend={onExtend}
          />
        )}
        <MemberList isBench={isBench}>
          {members.map((member, index) => {
            const roles = compact(
              [member.projectRole, member.roleExtensions].flat(),
            ).join(", ");
            return (
              <Member key={member.id} data-testid="timeline-member">
                <MemberInfo>
                  {member.userName ? (
                    <Name hasUser={member.isReal}>{member.userName}</Name>
                  ) : (
                    editPermission && (
                      <AssignLink
                        assignments={assignmentGroups[index].assignments}
                      />
                    )
                  )}
                </MemberInfo>
                <Role>{roles}</Role>
              </Member>
            );
          })}
        </MemberList>
      </Wrapper>
      <Graph type={type}>
        {hasAllocationInTimelineView || isBench ? (
          <WhiteBox
            startDateIndex={startDateIndex}
            endDateIndex={endDateIndex}
            data-testid="timeline-white-box"
            isBench={isBench}
          >
            {assignmentGroups.map(({ assignments, rowId, isFullRow }, idx) => {
              if (isBench && isFullRow) {
                return <EmptyGraph key={idx} isBench={isBench} />;
              }

              return (
                <MemberGraph
                  key={idx}
                  assignments={assignments}
                  isBench={isBench}
                  projectStart={projectStart}
                  projectEnd={projectEnd}
                  projectSeat={isBench ? null : projectSeats[rowId]}
                />
              );
            })}
          </WhiteBox>
        ) : null}
      </Graph>
    </ProjectTimelineWrap>
  );
}

export default GenericTimeline;
