import React from "react";
import {
  AccordionGroup,
  AccordionTitle,
  Frame,
  Inline,
  Skeleton,
  Stack,
  Text,
  useAccordionValue,
} from "@ableco/baseline";
import { CaretRight } from "@baseline/icons";
import { AnimatePresence, motion } from "framer-motion";
import { ErrorBoundary, SSRSuspense, useCollection } from "coreql";
import useUserResponsibilityGroups from "../../hooks/use-user-responsibility-groups";
import { combineResponsibilities } from "../data-entry/select/responsibility-select";
import ErrorFallback from "../error-fallback";
import UserLayout, {
  Fallback as UserLayoutFallback,
  useUserProfile,
} from "../layout/user-layout";
import { wrap } from "../weekly-report/utils";
import { useParams } from "react-router";

function SortByRank(firstRank, secondRank) {
  if (firstRank.rank === secondRank.rank) {
    return 0;
  } else if (firstRank.rank === null) {
    return 1;
  } else if (secondRank.rank === null) {
    return -1;
  }
  return firstRank.rank < secondRank.rank ? -1 : 1;
}

function UserResponsibilitiesFallback() {
  return (
    <Stack className="px-8 max-w-5xl pb-10">
      {[...Array.from({ length: 3 })].map((_, i) => (
        <Stack key={`group${i}`} space={2}>
          <Skeleton
            color="neutral-400"
            width={270}
            height={21}
            alt="Responsibility Group Title"
          />
          <Frame as="ul" aria-label="Responsibilities">
            {[...Array.from({ length: 2 })].map((_, i) => (
              <Frame key={`responsibility${i}`}>
                <Inline className="w-full text-left" p={4}>
                  <AccordionIcon isEmpty />
                  <Skeleton
                    color="neutral-400"
                    width={430}
                    height={24}
                    alt="User Responsibility Title"
                    animated
                  />
                </Inline>
              </Frame>
            ))}
          </Frame>
        </Stack>
      ))}
    </Stack>
  );
}

export function AccordionIcon({ isOpen, isEmpty, color = "neutral-800" }) {
  return (
    <Frame as="i" className="mr-2" corners="full">
      <Text color={isEmpty ? "neutral-500" : color} className="mt-1">
        <Text srOnly>{isOpen ? "Close Accordion" : "Open Accordion"}</Text>
        <Frame
          as={motion.div}
          initial={{ rotate: 0 }}
          animate={isOpen ? { rotate: 90 } : { rotate: 0 }}
          transition={{ duration: 0.25, type: "spring", ease: "easeIn" }}
        >
          <CaretRight
            className="w-4 h-4"
            color={isEmpty ? "neutral-500" : "base"}
          />
        </Frame>
      </Text>
    </Frame>
  );
}

function Expectation({ title, description }) {
  return (
    <Stack
      as="li"
      p={[6, 12]}
      space={2}
      className="bg-neutral-100 hover:bg-neutral-200 transition-all duration-300 ease-in-out"
    >
      <Text as="h4" size="lg" color="neutral-700">
        {title}
      </Text>
      {description && (
        <Text leading="normal" as="p" size="sm" color="neutral-600">
          {description}
        </Text>
      )}
    </Stack>
  );
}

function ResponsibilityExpectations({ title, expectations }) {
  const isOpen = useAccordionValue();
  const sortedExpectations = expectations.sort(SortByRank);
  return (
    <AnimatePresence>
      {isOpen && (
        <Frame
          as={motion.ul}
          aria-label={`${title} expectations`}
          className="overflow-hidden"
          key="accordion"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0.3, height: 0 },
          }}
          transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {sortedExpectations.map((props) => (
            <Expectation key={props.id} {...props} />
          ))}
        </Frame>
      )}
    </AnimatePresence>
  );
}

function ResponsibilityAccordionItem({ expectations, title }) {
  const isEmpty = expectations.length === 0;
  return (
    <Frame as="li">
      <AccordionGroup>
        <AccordionTitle
          as="button"
          isDisabled={isEmpty}
          direction="horizontal"
          alignment="start"
          className="w-full"
          aria-label={title}
        >
          {({ isOpen }) => (
            <Inline className="w-full text-left" p={[4, 4, 4, 4]}>
              <AccordionIcon isOpen={isOpen} isEmpty={isEmpty} />
              <Text
                as="h3"
                leading="snug"
                tracking="tight"
                size="xl"
                weight="semibold"
                color="neutral-800"
              >
                {title}
              </Text>
            </Inline>
          )}
        </AccordionTitle>
        <ResponsibilityExpectations title={title} expectations={expectations} />
      </AccordionGroup>
    </Frame>
  );
}
export function ResponsibilityAccordion({ groupTitle, responsibilities }) {
  const sortedResponsibilities = responsibilities.sort(SortByRank);

  return (
    <Frame as="ul" aria-label={groupTitle}>
      {sortedResponsibilities.map(({ id, title, expectations }) => (
        <ResponsibilityAccordionItem
          key={id}
          expectations={wrap(expectations)}
          title={title}
        />
      ))}
    </Frame>
  );
}

function ResponsibilityGroup({ responsibilities, groupTitle = null }) {
  return (
    <Stack as="section" space={2}>
      <Text
        as="h2"
        leading="normal"
        tracking="wider"
        size="sm"
        weight="semibold"
        color="neutral-600"
        transform="upper"
      >
        {groupTitle}
      </Text>
      <ResponsibilityAccordion
        groupTitle={groupTitle}
        responsibilities={responsibilities}
      />
    </Stack>
  );
}

function Responsibilities() {
  const userProfile = useUserProfile();
  const responsibilities = useCollection(
    "responsibilities",
    {
      filters: {
        id: combineResponsibilities(userProfile),
      },
      included: ["expectations"],
      fields: {
        responsibilities: ["title", "expectations", "rank"],
        expectations: ["title", "description", "rank"],
      },
    },
    "denormalized",
  ).data;
  const responsibilitiesGroups = useUserResponsibilityGroups({
    userProfile,
    responsibilities,
  });

  return (
    <Stack className="px-8 max-w-5xl w-full pb-10" space={8}>
      {responsibilitiesGroups.map((props, i) => (
        <ResponsibilityGroup key={props.groupTitle + i} {...props} />
      ))}
    </Stack>
  );
}

export default function UserResponsibilities() {
  const { id: userId } = useParams();
  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <SSRSuspense
        fallback={
          <UserLayoutFallback>
            <UserResponsibilitiesFallback />
          </UserLayoutFallback>
        }
      >
        <UserLayout id={userId}>
          <ErrorBoundary fallback={ErrorFallback}>
            <SSRSuspense fallback={<UserResponsibilitiesFallback />}>
              <Responsibilities />
            </SSRSuspense>
          </ErrorBoundary>
        </UserLayout>
      </SSRSuspense>
    </ErrorBoundary>
  );
}
