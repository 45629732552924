import React from "react";
import {
  Color,
  Corners,
  Frame,
  Inline,
  Skeleton,
  Stack,
} from "@ableco/baseline";
import { Display, Title } from "../design-system/typography-components";
import { AvatarXL } from "../design-system/avatar";

function UserAvatar({ url, name, userId }) {
  return (
    <Frame as="figure" data-testid={`avatar:${userId}`}>
      <AvatarXL url={url} name={name} />
    </Frame>
  );
}

export default function PageLayout({
  title,
  subTitle,
  backButton,
  children,
  avatarUser,
  customCircle,
}) {
  return (
    <Frame bg={Color.White} corners={Corners.SmallRounded} p={[6, 8, 20, 8]}>
      <Stack space={10}>
        <Stack space={2}>
          {backButton}
          <Inline space={4}>
            {avatarUser && (
              <UserAvatar
                url={avatarUser.avatarUrl}
                name={avatarUser.fullName}
                userId={avatarUser.id}
              />
            )}
            {customCircle}
            <Stack space={1}>
              <Display color={Color.Neutral800} as="h2">
                {title}
              </Display>
              {subTitle && (
                <Title as="h3" color={Color.Neutral600}>
                  {subTitle}
                </Title>
              )}
            </Stack>
          </Inline>
        </Stack>
        {children}
      </Stack>
    </Frame>
  );
}

export function Fallback() {
  return (
    <Stack
      alignment="start"
      bg="white"
      corners={Corners.SmallRounded}
      p={[4, 6, 0, 4]}
      className="w-full mb-4"
    >
      <Inline className="w-full ml-2">
        <Stack>
          <Skeleton
            color={Color.Neutral400}
            height={24}
            width={300}
            alt="Page Title"
            className="mb-1"
          />
          <Skeleton
            color={Color.Neutral400}
            height={20}
            width={150}
            alt="Page subtitle"
          />
        </Stack>
      </Inline>
    </Stack>
  );
}
