import Resource from "./../Resource";

export default class ProjectAllocation extends Resource {
  batchCreate(data = {}) {
    return this._post(`project_allocations/batch_create`, data);
  }

  batchDestroy({ ids }) {
    const idsParam = ids
      .map((id) => `ids[]=${encodeURIComponent(id)}`)
      .join("&");

    return this._delete(`project_allocations/batch_destroy?${idsParam}`);
  }

  batchUpdate(data = {}) {
    return this._put(`project_allocations/batch_update`, data);
  }

  create(data = {}) {
    return this._post("project_allocations", data);
  }

  destroy(id, data = {}) {
    return this._delete(`project_allocations/${id}`, data);
  }

  list(params = {}) {
    return this._get("project_allocations", params);
  }

  update(id, data = {}) {
    return this._put(`project_allocations/${id}`, data);
  }
}
