import React from "react";
import { Flex } from "@ableco/baseline";
import { AnimatePresence, motion } from "framer-motion";

import { variants } from "./capabilities-table-utils";

export default function AnimatedBodyCells({
  children,
  levels,
  direction,
  currentIndex,
  showControls,
  observableBehaviours,
  paddingBottom = 4,
}) {
  return (
    <AnimatePresence initial={false} custom={direction}>
      {levels.map((level, index) => {
        let paddingCell = [4, 4, paddingBottom, 4];
        if (index !== currentIndex && index !== currentIndex + 1) return;
        if (showControls) {
          if (index === currentIndex) paddingCell = [4, 4, paddingBottom, 8];
          if (index === currentIndex + 1)
            paddingCell = [4, 8, paddingBottom, 4];
        }

        const observableBehaviour = observableBehaviours.find(
          (observable) => observable.level.id === level.id,
        );

        return (
          <Flex
            key={`${level.id}-${currentIndex}`}
            role="cell"
            className="w-full"
            p={paddingCell}
            as={motion.div}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            positionTransition
            exit="exit"
            distribution="between"
          >
            {observableBehaviour ? children({ observableBehaviour }) : null}
          </Flex>
        );
      })}
    </AnimatePresence>
  );
}
