import React, { useRef, useState, useEffect, useCallback } from "react";
import { Color, Frame, Inline, Stack, TextWhitespace } from "@ableco/baseline";
import {
  BodySmall,
  BodySmallSemibold,
  TitleSemibold,
} from "../design-system/typography-components";
import { CaretLeftOutline, CaretRightOutline } from "@baseline/icons";
import { AnimatePresence, motion } from "framer-motion";

import LevelInfoButton from "./level-info-button";
import CapabilitiesDrawer from "./capabilities-drawer";
import { variants } from "./capabilities-table-utils";

function AnimatedHeaderTitle({ level, direction, role }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const handleClose = useCallback(() => setShowDrawer(false), [setShowDrawer]);

  return (
    <Frame
      className="flex-1 self-start"
      p={[0, 4]}
      as={motion.div}
      custom={direction}
      variants={variants}
      initial="enter"
      animate="center"
      positionTransition
      exit="exit"
    >
      <BodySmallSemibold>{`${role?.title} ${level.name}`}</BodySmallSemibold>
      <BodySmall color={Color.Neutral600}>
        {` - Level ${level.code} `}
      </BodySmall>
      <LevelInfoButton level={level} onClick={() => setShowDrawer(true)} />
      <AnimatePresence>
        {showDrawer && (
          <CapabilitiesDrawer level={level} onClose={handleClose} />
        )}
      </AnimatePresence>
    </Frame>
  );
}

export default function CapabilitiesTableMainHeader({
  skill,
  levels,
  indexValues,
  setCurrentIndex,
  showControls = true,
  role,
}) {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  function handleScroll() {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  }
  const levelsLength = levels.length;
  const [currentIndex, direction] = indexValues;
  const showLeftButton = levelsLength > 2 && currentIndex > 0;
  const showRightButton = levelsLength > 2 && currentIndex < levelsLength - 2;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);
  return (
    <Frame
      className="sticky top-0 -mx-8"
      style={
        isSticky
          ? {
              boxShadow:
                "0px 0px 10px rgba(0, 0, 0, 0.05), 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1)",
              zIndex: 1,
            }
          : { zIndex: 1 }
      }
      bg={Color.White}
      innerRef={ref}
      p={[0, 8]}
    >
      <Stack space={4} p={[4, 0]}>
        <TitleSemibold whitespace={TextWhitespace.NoWrap}>
          {skill}
        </TitleSemibold>
        <Inline>
          <Frame className="w-1/3 self-start">
            <BodySmall color={Color.Neutral600}>Capabilities</BodySmall>
          </Frame>
          <Frame className="w-2/3">
            <Inline>
              <>
                {showControls && (
                  <Frame className="w-4 h-4 z-10">
                    {showLeftButton && (
                      <CaretLeftOutline
                        data-testid="previous-level-button"
                        className="w-4 h-4 cursor-pointer"
                        onClick={() =>
                          setCurrentIndex((currentIndex) => [
                            currentIndex[0] - 1,
                            -1,
                          ])
                        }
                      />
                    )}
                  </Frame>
                )}
                <AnimatePresence initial={false} custom={direction}>
                  {levels.map((level, index) => {
                    if (index !== currentIndex && index !== currentIndex + 1)
                      return;
                    return (
                      <AnimatedHeaderTitle
                        key={`level-title-${level.id}-${currentIndex}`}
                        level={level}
                        direction={direction}
                        role={role}
                      />
                    );
                  })}
                </AnimatePresence>
                {showControls && (
                  <Frame className="w-4 h-4 z-10">
                    {showRightButton && (
                      <CaretRightOutline
                        data-testid="next-level-button"
                        className="w-4 h-4 cursor-pointer"
                        onClick={() =>
                          setCurrentIndex((currentIndex) => [
                            currentIndex[0] + 1,
                            1,
                          ])
                        }
                      />
                    )}
                  </Frame>
                )}
              </>
            </Inline>
          </Frame>
        </Inline>
      </Stack>
    </Frame>
  );
}
