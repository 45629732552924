import React, { useRef, useState } from "react";
import { Color, Frame } from "@ableco/baseline";
import useTooltip from "../../hooks/use-tooltip";
import serializeSelection from "serialize-selection";
import { BodyBaseline } from "../design-system/typography-components";
import { AnimatePresence } from "framer-motion";
import HighlightsPopover from "../feedback/highlights-popover";
import { wrap } from "../weekly-report/utils";
import { LinkifyEmojis } from "../../utils/linkify";

function HighlightedText({
  value,
  highlight,
  openPopover,
  setActiveHighlight,
  isActive,
  emojis,
  showEmojis,
}) {
  function handleClick(event) {
    setActiveHighlight(highlight);

    const rect = event.target.getBoundingClientRect();
    openPopover({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
      width: rect.width,
      height: rect.height,
    });
  }
  return (
    <Frame
      as="mark"
      className={`${
        isActive ? "bg-warning-light" : "bg-warning-lighter"
      } cursor-pointer text-neutral-700 hover:bg-warning-light transition-colors duration-300 ease-in-out focus:bg-warning-light break-word`}
      onClick={handleClick}
    >
      <LinkifyEmojis
        key={value}
        showEmojis={showEmojis}
        emojis={emojis}
        value={value}
        componentWrapper={LinkifyComponent}
      />
    </Frame>
  );
}

function LinkifyComponent(href, text) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="text-primary-base hover:text-primary-light transition-all duration-300 ease-in-out break-all"
    >
      {text}
    </a>
  );
}

function BodyWithHighlights({
  id,
  body,
  highlights = [],
  receiverId,
  highlightingEnabled,
  refetch,
  source = "feedback",
  emojis = { normal: [], slack: [] },
}) {
  const [showEmojis, setShowEmojis] = React.useState(true);
  const bodyRef = useRef(null);
  const [selectedText, setSelectedText] = useState("");
  const [selectionOffsets, setSelectionOffsets] = useState({});
  const [activeHighlight, setActiveHighlight] = useState({});

  function resetSelection() {
    setSelectedText("");
    setSelectionOffsets({});
    setActiveHighlight({});
  }

  const [Tooltip, , { open, close, isVisible }] = useTooltip({
    onClose: resetSelection,
  });

  function handleMouseUp(event) {
    if (!highlightingEnabled) return;
    const { isCollapsed, anchorNode, rangeCount } = window.getSelection();

    if (isCollapsed) return;
    if (rangeCount > 1) return;
    const selectionExtendsOutsideOfMessageBody =
      !bodyRef.current.contains(anchorNode);
    if (selectionExtendsOutsideOfMessageBody) return;

    const selectionWithRespectToBody = serializeSelection.save(bodyRef.current);
    const selectionIsWithinExistingHighlight = highlights.find(
      ({ start, end }) =>
        selectionWithRespectToBody.start >= start &&
        selectionWithRespectToBody.end <= end,
    );

    // The HighlightedText onClick handler will open the popover
    if (selectionIsWithinExistingHighlight) return;

    const partiallySelectedHighlightfromSelectionEnd = highlights.find(
      ({ start, end }) =>
        selectionWithRespectToBody.start < start &&
        selectionWithRespectToBody.end > start &&
        selectionWithRespectToBody.end < end,
    );

    const partiallyCoveredHighlighFromSelectionStart = highlights.find(
      ({ start, end }) =>
        selectionWithRespectToBody.end > end &&
        selectionWithRespectToBody.start < end &&
        selectionWithRespectToBody.start > start,
    );

    function triggerInfo() {
      const rect = event.target.getBoundingClientRect();
      return {
        top: window.scrollY + Math.ceil(event.nativeEvent.clientY / 24) * 24, //rounding to lineheight
        left: (rect.left + rect.width) / 2, // Center horizontally
        height: 0,
        width: rect.width,
      };
    }

    if (
      partiallySelectedHighlightfromSelectionEnd &&
      partiallyCoveredHighlighFromSelectionStart
    ) {
      setActiveHighlight(partiallyCoveredHighlighFromSelectionStart);
      setSelectedText(
        bodyRef.current.textContent.slice(
          partiallyCoveredHighlighFromSelectionStart.start,
          partiallySelectedHighlightfromSelectionEnd.end,
        ),
      );
      setSelectionOffsets({
        start: partiallyCoveredHighlighFromSelectionStart.start,
        end: partiallySelectedHighlightfromSelectionEnd.end,
      });

      open(triggerInfo());
      return;
    }

    if (partiallySelectedHighlightfromSelectionEnd) {
      setActiveHighlight(partiallySelectedHighlightfromSelectionEnd);
      setSelectedText(
        bodyRef.current.textContent.slice(
          selectionWithRespectToBody.start,
          partiallySelectedHighlightfromSelectionEnd.end,
        ),
      );
      setSelectionOffsets({
        start: selectionWithRespectToBody.start,
        end: partiallySelectedHighlightfromSelectionEnd.end,
      });
      open(triggerInfo());
      return;
    }

    if (partiallyCoveredHighlighFromSelectionStart) {
      setActiveHighlight(partiallyCoveredHighlighFromSelectionStart);
      setSelectedText(
        bodyRef.current.textContent.slice(
          partiallyCoveredHighlighFromSelectionStart.start,
          selectionWithRespectToBody.end,
        ),
      );
      setSelectionOffsets({
        start: partiallyCoveredHighlighFromSelectionStart.start,
        end: selectionWithRespectToBody.end,
      });
      open(triggerInfo());
      return;
    }

    const fullyCoveredHighlight = highlights.find(
      ({ start, end }) =>
        selectionWithRespectToBody.start <= start &&
        selectionWithRespectToBody.end >= end,
    );

    if (fullyCoveredHighlight) {
      setActiveHighlight(fullyCoveredHighlight);
      setSelectedText(selectionWithRespectToBody.content);
      setSelectionOffsets({
        start: selectionWithRespectToBody.start,
        end: selectionWithRespectToBody.end,
      });
      open(triggerInfo());
      return;
    }

    setActiveHighlight({});
    setSelectedText(selectionWithRespectToBody.content);
    setSelectionOffsets({
      start: selectionWithRespectToBody.start,
      end: selectionWithRespectToBody.end,
    });
    open(triggerInfo());
  }

  const newSelectionExists = selectionOffsets.start != null;

  const highlightsToDisplay = newSelectionExists
    ? [
        ...highlights.filter(
          ({ start, end }) =>
            start < selectionOffsets.start || end > selectionOffsets.end,
        ),
        Object.assign({}, selectionOffsets, { id: "new" }),
      ]
    : highlights;

  const sortedHighlights = [...highlightsToDisplay].sort(
    (a, b) => a.start - b.start,
  );

  let currentIndex = 0;
  const sections = [];
  for (const highlight of sortedHighlights) {
    const beforeHighlight = body.slice(currentIndex, highlight.start);
    if (beforeHighlight.length > 0)
      sections.push({ type: "text", value: beforeHighlight });
    const highlightedText = body.slice(highlight.start, highlight.end);
    sections.push({ highlight, type: "highlight", value: highlightedText });
    currentIndex = highlight.end;
  }

  const lastSection = body.slice(currentIndex);
  if (lastSection.length > 0)
    sections.push({ type: "text", value: lastSection });
  return (
    <Frame as="span">
      <BodyBaseline
        color={Color.Neutral700}
        as="p"
        data-testid={`feedback:body:${id}`}
        onMouseUp={handleMouseUp}
        onMouseEnter={(_) => !!highlightingEnabled && setShowEmojis(false)}
        onMouseLeave={(_) => !!highlightingEnabled && setShowEmojis(true)}
        innerRef={bodyRef}
        // Applying negative margins to extend boundaries of this component
        // to trigger highlights popovover when mouseup occurs closer to the edges
        className="-my-2 -mx-6 py-2 px-6"
        wordBreak="words"
        style={{ wordBreak: "break-word" }}
      >
        {sections.map(({ type, value, highlight }) =>
          type === "highlight" ? (
            <HighlightedText
              key={value}
              value={value}
              highlight={highlight}
              openPopover={open}
              setActiveHighlight={setActiveHighlight}
              isActive={
                activeHighlight.id == highlight.id || highlight.id === "new"
              }
              emojis={emojis}
              showEmojis={showEmojis}
            />
          ) : (
            <LinkifyEmojis
              key={value}
              showEmojis={showEmojis}
              emojis={emojis}
              value={value}
              componentWrapper={LinkifyComponent}
            />
          ),
        )}
      </BodyBaseline>
      <AnimatePresence>
        {/* Adding key to tooltip so that AnimatePresence knows when its child is unmounting to trigger exit transtions  */}
        {isVisible && (
          <Tooltip
            key={activeHighlight.id}
            p={0}
            style={{ width: 700 }}
            withArrow={false}
          >
            <HighlightsPopover
              highlightId={activeHighlight.id}
              feedbackType={
                source === "cheer"
                  ? activeHighlight.feedbackType || "win"
                  : activeHighlight.feedbackType
              }
              receiverId={receiverId}
              responsibilityId={activeHighlight?.responsibility?.id}
              responsibility={activeHighlight.responsibility}
              skills={wrap(activeHighlight.skills)}
              note={activeHighlight.note}
              close={close}
              refetch={refetch}
              relatedId={id}
              highlightedText={selectedText}
              highlightStart={selectionOffsets.start}
              highlightEnd={selectionOffsets.end}
              readOnly={!highlightingEnabled}
              source={source}
            />
          </Tooltip>
        )}
      </AnimatePresence>
    </Frame>
  );
}

export default BodyWithHighlights;
