import { setDay } from "date-fns";
import getMondays from "./get-mondays";

const emptyWeek = (week) => ({
  date: week,
  hours: 0,
});

const filterWeeks = (allocatedWeeks, start, end) => {
  if (!start || !end) {
    return [{ date: setDay(new Date().setHours(0, 0, 0, 0), 1), hours: 0 }];
  }
  const oldRange = allocatedWeeks.map((allocatedWeek) =>
    allocatedWeek.date.setHours(0, 0, 0, 0),
  );
  const newRange = getMondays(start, end).map((monday) =>
    monday.setHours(0, 0, 0, 0),
  );

  const result = newRange.map((date) => {
    const indexWeek = oldRange.indexOf(date);
    if (indexWeek >= 0) return allocatedWeeks[indexWeek];
    return emptyWeek(new Date(date));
  });

  return result;
};

export default filterWeeks;
