import { useCollection } from "coreql";

const resource = "role-extensions";

const included = ["projectRole"];
const fields = {
  roleExtensions: ["userId", "projectId", "projectRole"],
  projectRoles: ["name"],
};

function useRoleExtensions(options) {
  return useCollection(
    resource,
    {
      included,
      fields,
      options,
    },
    "denormalized",
  );
}

export default useRoleExtensions;
