import React, { useState } from "react";

import {
  Color,
  Frame,
  FlexAlignment,
  FlexDistribution,
  Inline,
  Stack,
  Text,
} from "@ableco/baseline";
import CommentForm from "../../comment-form";
import {
  filterAnswersByCriteria,
  canAddOneOnOne,
  addOneOnOneItem,
  getPrivateCommentReceiverName,
} from "./utils";
import RenameOneOnOne from "../rename-one-on-one";
import { MarkdownViewer } from "../../markdown/markdown-viewer";
import { AddToOneOnOneIconButton, CommentIconButton } from "./icon-buttons";
import {
  BodyBaseline,
  BodySmall,
  TitleSemibold,
} from "../../design-system/typography-components";
import { useCollection } from "coreql";
import { Lock } from "@baseline/icons";
import Like, { LikedIndicator } from "./like";

function TemperatureAnswer({
  answer,
  weeklyReport,
  currentUser,
  weeklyUser,
  refetchWeeklyAnswers,
  isDirectReport,
}) {
  const {
    data: { data: temperatureAnswerComments },
    revalidate,
  } = useCollection("comments", {
    filters: {
      commentable_type: ["WeeklyReportAnswer"],
      commentable_id: [answer.id],
    },
    fields: {
      comments: ["private", "avatarUser", "userFullname", "body", "userId"],
    },
  });
  const [showAnswersCommentsForm, setShowAnswersCommentsForm] = React.useState(
    temperatureAnswerComments.length > 0,
  );

  const [weeklyAnswerLikeId, setWeeklyAnswerLikeId] = useState(
    answer.like?.id || null,
  );

  const likeUser = answer.like?.user || null;

  return (
    <Stack as="li" className="w-full" alignment="stretch">
      <Inline
        alignment={FlexAlignment.Start}
        className="w-full group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
        distribution={FlexDistribution.Between}
        p={[2, 6]}
        space={4}
      >
        <Text className="text-neutral-700">
          <MarkdownViewer value={answer.data.text} />
          {answer.locked && (
            <Inline space={1}>
              <Lock className="h-4 w-4 text-neutral-600" />
              <BodySmall color={Color.Neutral600}>
                Only you and{" "}
                {getPrivateCommentReceiverName(weeklyUser, currentUser)} can see
                this
              </BodySmall>
            </Inline>
          )}
        </Text>
        <Inline space={4} alignment="center">
          {!showAnswersCommentsForm && (
            <CommentIconButton
              onClick={() => setShowAnswersCommentsForm(true)}
            />
          )}
          {!answer.oneOnOneItem &&
            canAddOneOnOne(currentUser.id, weeklyUser.managerId) && (
              <AddToOneOnOneIconButton
                onClick={() => {
                  addOneOnOneItem(answer, currentUser.id, weeklyUser.id).then(
                    () => refetchWeeklyAnswers(),
                  );
                }}
              />
            )}

          {isDirectReport && (
            <Like
              weeklyAnswerId={answer.id}
              currentUser={currentUser}
              weeklyAnswerLikeId={weeklyAnswerLikeId}
              setWeeklyAnswerLikeId={setWeeklyAnswerLikeId}
            />
          )}
        </Inline>
      </Inline>

      <LikedIndicator
        weeklyAnswerLikeId={weeklyAnswerLikeId}
        likeUser={likeUser}
        isDirectReport={isDirectReport}
      />

      {answer.oneOnOneItem && (
        <RenameOneOnOne
          oneOnOneItemId={answer.oneOnOneItem.id}
          oneOnOneItemText={answer.oneOnOneItem.text}
          refetch={refetchWeeklyAnswers}
        />
      )}

      <React.Suspense fallback={null}>
        <CommentForm
          weeklyReportId={weeklyReport.id}
          senderId={currentUser.id}
          senderAvatar={currentUser.avatarUrl}
          receiverName={getPrivateCommentReceiverName(weeklyUser, currentUser)}
          currentUserId={currentUser.id}
          answerId={answer.id}
          showAddComment={showAnswersCommentsForm}
          comments={temperatureAnswerComments}
          revalidate={revalidate}
          setShowAddComment={setShowAnswersCommentsForm}
        />
      </React.Suspense>
    </Stack>
  );
}

export default function TemperatureSummary({
  weeklyReport,
  currentUser,
  weeklyUser,
  weeklyReportAnswers,
  refetchWeeklyAnswers,
}) {
  const temperature = filterAnswersByCriteria(
    weeklyReportAnswers,
    "answerType",
    "temperature",
  );
  const temperatureAnswers = filterAnswersByCriteria(
    weeklyReportAnswers,
    "answerType",
    "temperature_answer",
  );
  const feeling = temperature[0]["data"]["value"];

  const {
    data: { data: temperatureComments },
    revalidate,
  } = useCollection("comments", {
    filters: {
      commentable_type: ["WeeklyReportAnswer"],
      commentable_id: [temperature[0].id],
    },
    fields: {
      comments: ["private", "avatarUser", "userFullname", "body", "userId"],
    },
  });

  const [showTemperatureCommentsForm, setShowTemperatureCommentsForm] =
    React.useState(temperatureComments.length > 0);

  const isDirectReport = currentUser.id == weeklyUser.managerId;

  const [weeklyAnswerLikeId, setWeeklyAnswerLikeId] = useState(
    temperature[0].like?.id || null,
  );

  const likeUser = temperature[0].like?.user || null;

  return (
    <Frame as="section">
      <TitleSemibold as="h3" color={Color.Neutral800} className="px-6">
        How did you feel at work this week?
      </TitleSemibold>

      <Stack>
        <Inline
          space={4}
          className="w-full group hover:bg-neutral-100 rounded transition-all duration-300 ease-in-out"
          distribution="between"
          alignment="center"
          p={[2, 6]}
        >
          <BodyBaseline color={Color.Neutral700}>
            Feeling {feeling} out of 5
          </BodyBaseline>
          <Inline space={4} alignment="center">
            {!showTemperatureCommentsForm && (
              <CommentIconButton
                onClick={() => setShowTemperatureCommentsForm(true)}
              />
            )}
            {!temperature[0].oneOnOneItem &&
              canAddOneOnOne(currentUser.id, weeklyUser.managerId) && (
                <AddToOneOnOneIconButton
                  onClick={() => {
                    addOneOnOneItem(
                      temperature[0],
                      currentUser.id,
                      weeklyUser.id,
                      `Feeling ${feeling} out of 5`,
                    ).then(() => refetchWeeklyAnswers());
                  }}
                />
              )}
            {isDirectReport && (
              <Like
                weeklyAnswerId={temperature[0].id}
                currentUser={currentUser}
                weeklyAnswerLikeId={weeklyAnswerLikeId}
                setWeeklyAnswerLikeId={setWeeklyAnswerLikeId}
              />
            )}
          </Inline>
        </Inline>

        <LikedIndicator
          weeklyAnswerLikeId={weeklyAnswerLikeId}
          likeUser={likeUser}
          isDirectReport={isDirectReport}
        />

        {temperature[0].oneOnOneItem && (
          <RenameOneOnOne
            oneOnOneItemId={temperature[0].oneOnOneItem.id}
            oneOnOneItemText={temperature[0].oneOnOneItem.text}
            refetch={refetchWeeklyAnswers}
          />
        )}

        <React.Suspense fallback={null}>
          <CommentForm
            weeklyReportId={weeklyReport.id}
            senderId={currentUser.id}
            senderAvatar={currentUser.avatarUrl}
            receiverName={getPrivateCommentReceiverName(
              weeklyUser,
              currentUser,
            )}
            currentUserId={currentUser.id}
            answerId={temperature[0].id}
            showAddComment={showTemperatureCommentsForm}
            comments={temperatureComments}
            revalidate={revalidate}
            setShowAddComment={setShowTemperatureCommentsForm}
          />
        </React.Suspense>

        <Stack as="ul">
          {temperatureAnswers?.map((answer) => (
            <TemperatureAnswer
              key={answer.id}
              answer={answer}
              weeklyReport={weeklyReport}
              currentUser={currentUser}
              weeklyUser={weeklyUser}
              refetchWeeklyAnswers={refetchWeeklyAnswers}
              isDirectReport={isDirectReport}
            />
          ))}
        </Stack>
      </Stack>
    </Frame>
  );
}
